import { AmenityItem } from '@/models/AmenityItem'
import { AmenityType } from '@/models/dto'
import { AmenityTypeId, AmenityTypeKey, VehicleTypeId, VehicleTypeKey } from './enum'
import deepClone from './deepClone'

export const AVAILABLE_AMENITIES = [
  {
    id: AmenityTypeId.Bathroom,
    key: AmenityTypeKey.Bathroom,
    icon: 'bathroom',
    title: 'Bathroom',
    color: 'primary',
    included: true,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  {
    id: AmenityTypeId.Luggage,
    key: AmenityTypeKey.Luggage,
    icon: 'luggage',
    title: 'Luggage Storage',
    color: 'primary',
    included: true,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  {
    id: AmenityTypeId.SeatBelts,
    key: AmenityTypeKey.SeatBelts,
    icon: 'bus',
    title: 'Seat Belts',
    color: 'primary',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  {
    id: AmenityTypeId.ADA,
    key: AmenityTypeKey.ADA,
    icon: 'bus',
    title: 'ADA',
    color: 'primary',
    included: false,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  {
    id: AmenityTypeId.Outlets,
    key: AmenityTypeKey.Outlets,
    icon: 'power',
    title: 'Outlets',
    color: 'primary',
    included: true,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  {
    id: AmenityTypeId.TVScreens,
    key: AmenityTypeKey.TVScreens,
    icon: 'tv',
    title: 'TV Screens',
    color: 'primary',
    included: true,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  {
    id: AmenityTypeId.Wifi,
    key: AmenityTypeKey.Wifi,
    icon: 'wifi',
    title: 'Wifi',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  {
    id: AmenityTypeId.LeatherSeats,
    key: AmenityTypeKey.LeatherSeats,
    icon: 'airline_seat_recline_extra',
    title: 'Leather Seats',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  {
    id: AmenityTypeId.SPAB,
    key: AmenityTypeKey.SPAB,
    icon: 'badge',
    title: 'SPAB',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  {
    id: AmenityTypeId.AlcoholConsumption,
    key: AmenityTypeKey.AlcoholConsumption,
    icon: 'liquor',
    title: 'Alcohol Allowed',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  {
    id: AmenityTypeId.Certification19A,
    key: AmenityTypeKey.Certification19A,
    icon: 'badge',
    title: '19A Certification',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  {
    id: AmenityTypeId.AuxBluetooth,
    key: AmenityTypeKey.AuxBluetooth,
    icon: 'bluetooth',
    title: 'Aux/Bluetooth',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  {
    id: AmenityTypeId.PASystem,
    key: AmenityTypeKey.PASystem,
    icon: 'mic',
    title: 'PA System',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  {
    id: AmenityTypeId.BottledWater,
    key: AmenityTypeKey.BottledWater,
    icon: 'water_drop',
    title: 'Bottled Water',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  {
    id: AmenityTypeId.MakeModelYear,
    key: AmenityTypeKey.MakeModelYear,
    icon: 'description',
    title: 'Make/Model/Year',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  {
    id: AmenityTypeId.BusColorInterior,
        key: AmenityTypeKey.BusColorInterior,
    icon: 'palette',
    title: 'Bus Color',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  {
    id: AmenityTypeId.BusLogos,
    key: AmenityTypeKey.BusLogos,
    icon: 'grid_view',
    title: 'Bus Logos',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  {
    id: AmenityTypeId.NonStandardBus,
    key: AmenityTypeKey.NonStandardBus,
    icon: 'bus',
    title: 'Non-Standard Bus',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  {
    id: AmenityTypeId.TripCoordinators,
    key: AmenityTypeKey.TripCoordinators,
    icon: 'groups',
    title: 'Trip Coordinators',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  {
    id: AmenityTypeId.BusWraps,
    key: AmenityTypeKey.BusWraps,
    icon: 'gradient',
    title: 'Bus Wraps',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
]

export const CHARTER_BUS_INCLUDED_AMENITY_IDS = [
  AmenityTypeId.Bathroom,
  AmenityTypeId.Luggage,
  AmenityTypeId.Outlets,
  AmenityTypeId.TVScreens,
]

export const AMENITY_CARD_SUPPORTED_VEHICLE_TYPE_KEYS = [
  VehicleTypeKey.CharterBus,
  VehicleTypeKey.MiniBus,
  VehicleTypeKey.Sprinter,
]

const getAvailableAmenitiesByVehicleTypeId = (
  amenities: AmenityType[],
  vehicleTypeId: number
): AmenityItem[] => {
  if (!amenities || !vehicleTypeId) {
    return []
  }
  return deepClone(AVAILABLE_AMENITIES).filter(
    (AMENITY) =>
      AMENITY.vehicleTypeIds.includes(vehicleTypeId) &&
      amenities.some((amenity) => amenity.id === AMENITY.id)
  )
}

export const getAvailableAmenityTypesByVehicleTypeId = (
  amenities: AmenityType[],
  vehicleTypeId: number
): AmenityType[] => {
  if (!amenities || !vehicleTypeId) {
    return []
  }
  const availableAmenityTypeIds = getAvailableAmenitiesByVehicleTypeId(
    amenities,
    vehicleTypeId
  ).map((AMENITY) => AMENITY.id)
  return amenities.filter((amenity) =>
    availableAmenityTypeIds.includes(amenity.id)
  )
}

export const getCharterBusAmenities = (
  amenities: AmenityType[]
): AmenityItem[] => {
  return getAvailableAmenitiesByVehicleTypeId(
    amenities,
    VehicleTypeId.CharterBus
  )
}

export const getMiniBusAmenities = (
  amenities: AmenityType[]
): AmenityItem[] => {
  return getAvailableAmenitiesByVehicleTypeId(amenities, VehicleTypeId.MiniBus)
}

export const getAmenityIcon = (amenityTypeId: number): string => {
  const amenityItem = AVAILABLE_AMENITIES.find(
    (AMENITY) => AMENITY.id === amenityTypeId
  )
  return amenityItem ? amenityItem.icon : ''
}

export const getAmenityIconByKey = (amenityTypeKey: string): string => {
  const amenityItem = AVAILABLE_AMENITIES.find(
    (amenity) => amenity.key === amenityTypeKey
  )
  return amenityItem ? amenityItem.icon : ''
}

/**
 * Combines incoming amenity props with display metadata from AVAILABLE_AMENITIES. When an
 * amenity is passed in, this getter looks up the full amenity details from AVAILABLE_AMENITIES
 * to add icons and standardized titles. It only includes amenities that are compatible with the
 * current vehicle type.
 *
 * @returns {AmenityItem[]} An array of complete amenity items, combining:
 *   - Display metadata (title, icon) from AVAILABLE_AMENITIES
 *   - Custom notes from the amenities prop
 *   - Only includes amenities compatible with the current vehicle type
 */
export const getAmenityItemsForVehicleType = (tripAmenities: AmenityItem[], vehicleTypeId: number): AmenityItem[] => {
  const amenityItems = []
  for (const amenity of tripAmenities) {
    const amenityItem = AVAILABLE_AMENITIES.find(
      (a) => a.id === amenity.id && a.vehicleTypeIds.includes(vehicleTypeId)
    )
    if (amenityItem) {
      amenityItems.push({
        ...amenityItem,
        note: amenity.note,
      })
    }
  }
  return amenityItems
}

/**
 * Filters the amenities to return only those that are included by default for charter buses.
 * Returns an empty array for non-charter bus vehicle types.
 *
 * @param tripAmenities - Array of amenities associated with the trip
 * @param vehicleTypeId - The vehicle type id of vehicle
 * @returns {AmenityItem[]} Array of included amenity items for charter buses, empty array otherwise
 */
export const getIncludedAmenities = (tripAmenities: AmenityItem[], vehicleTypeId: number): AmenityItem[] => {
  const amenityItems = getAmenityItemsForVehicleType(tripAmenities, vehicleTypeId)

  if (vehicleTypeId === VehicleTypeId.CharterBus) {
    return amenityItems.filter((amenity) => CHARTER_BUS_INCLUDED_AMENITY_IDS.includes(amenity.id))
  }
  return []
}

/**
 * Filters the amenities to return only those that are not included by default.
 * For charter buses, returns amenities not in the default included set.
 * For other vehicle types, returns all amenities.
*
 * @param tripAmenities - Array of amenities associated with the trip
 * @param vehicleTypeId - The vehicle type id of the vehicle being used
 * @returns {AmenityItem[]} Array of additional (non-included) amenity items
 */
export const getAdditionalAmenities = (tripAmenities: AmenityItem[], vehicleTypeId: number): AmenityItem[] => {
  const amenityItems = getAmenityItemsForVehicleType(tripAmenities, vehicleTypeId)

  if (vehicleTypeId === VehicleTypeId.CharterBus) {
    return amenityItems.filter((amenity) => !CHARTER_BUS_INCLUDED_AMENITY_IDS.includes(amenity.id))
  }

  return amenityItems
}

/**
 * Filters amenities to return only those that have an associated note.
 *
 * @param tripAmenities - Array of amenities associated with the trip
 * @param vehicleTypeId - The type of vehicle being used
 * @returns {AmenityItem[]} Array of amenity items that have notes attached
 */
export const getAmenitiesWithNotes = (tripAmenities: AmenityItem[], vehicleTypeId: number): AmenityItem[] => {
  const amenityItems = getAmenityItemsForVehicleType(tripAmenities, vehicleTypeId)
  return amenityItems.filter((amenity) => !!amenity.note?.note)
}
