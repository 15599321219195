<template>
  <CUModal
    :value="value"
    width="520px"
    max-width="520px"
    :closeable="false"
    @input="close"
  >
    <template #title>{{ $t('driverHotelRequirements.TITLE') }}</template>
    <template #text>
      <div class="d-flex w-full flex-column">
        <div
          class="d-flex border-primary border-1 border-solid border-radius-8 padding-a-4"
        >
          <CUIcon
            view-box="0 0 28 28"
            color="blue"
            width="28px"
            height="28px"
            class="cursor-default flex-shrink-0 margin-r-2"
          >
            info
          </CUIcon>
          <div>
            <p
              class="font-18  flex-shrink-1 margin-t-0 font-medium"
            >
              {{ $t('driverHotelRequirements.HOTEL_REQUIRED') }}
              <br v-if="$vuetify.breakpoint.xsOnly" />
              {{ driverHotelCheckIn }}
              {{ $t('common.TO') }}
              {{ driverHotelCheckOut }}
            </p>
            <p class="font-16  flex-shrink-1 margin-t-0">
              {{ $t('driverHotelRequirements.IT_APPEARS') }}
            </p>
            <p class="font-14  margin-t-4">
              {{ $t('driverHotelRequirements.DONT_THINK_HOTEL_REQUIRED') }}
              <span
                class="font-bold text-primary cursor-pointer"
                @click="handleContactSupport"
              >
                {{ $t('driverHotelRequirements.CONTACT_SUPPORT') }}
              </span>
            </p>
          </div>
        </div>
        <p class="font-16  font-bold margin-t-4">
          {{ $t('driverHotelRequirements.SELECTING_A_HOTEL') }}
        </p>
        <p class="font-14  margin-t-2">
          {{ $t('driverHotelRequirements.PLEASE_TAKE_NOTE') }}
        </p>
        <ul class="margin-l-6 margin-t-2">
          <li
            v-for="(requirement, requirementIndex) in requirements"
            :key="`hotel-requirement-${requirement.title}-${requirementIndex}`"
            class="margin-t-2 font-16 "
            :style="{ 'list-style': 'disc' }"
          >
            <span class="font-bold">{{ requirement.title }}:</span>
            {{ requirement.description }}
          </li>
        </ul>
        <v-btn class="primary margin-t-4" @click="close">
          Close
        </v-btn>
      </div>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { HotelRequirement } from '@/models/dto'
import support from '@/store/modules/support'
import { SupportTicketKey } from '@/utils/enum'
import { DateTime } from 'luxon'

@Component
export default class DriverHotelRequirementsDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: Number, default: null }) readonly reservationId: number
  @Prop({ required: false, type: Object, default: null })
  readonly hotelRequirement: HotelRequirement

  requirements = [
    {
      title: this.$t('driverHotelRequirements.requirements.STAR_RATING.TITLE'),
      description: this.$t(
        'driverHotelRequirements.requirements.STAR_RATING.DESCRIPTION'
      ),
    },
    {
      title: this.$t(
        'driverHotelRequirements.requirements.ROOM_SPECIFICATIONS.TITLE'
      ),
      description: this.$t(
        'driverHotelRequirements.requirements.ROOM_SPECIFICATIONS.DESCRIPTION'
      ),
    },
    {
      title: this.$t('driverHotelRequirements.requirements.BUS_PARKING.TITLE'),
      description: this.$t(
        'driverHotelRequirements.requirements.BUS_PARKING.DESCRIPTION'
      ),
    },
    {
      title: this.$t('driverHotelRequirements.requirements.PROXIMITY.TITLE'),
      description: this.$t(
        'driverHotelRequirements.requirements.PROXIMITY.DESCRIPTION'
      ),
    },
    {
      title: this.$t(
        'driverHotelRequirements.requirements.NAME_ON_RESERVATION.TITLE'
      ),
      description: this.$t(
        'driverHotelRequirements.requirements.NAME_ON_RESERVATION.DESCRIPTION'
      ),
    },
  ]

  get driverHotelCheckIn(): string {
    return this.hotelRequirement?.checkIn
      ? DateTime.fromISO(this.hotelRequirement?.checkIn).toFormat('LL/dd/yy')
      : ''
  }

  get driverHotelCheckOut(): string {
    return this.hotelRequirement?.checkOut
      ? DateTime.fromISO(this.hotelRequirement?.checkOut).toFormat('LL/dd/yy')
      : ''
  }

  handleContactSupport(): void {
    this.close()
    support.open({
      reservationId: this.reservationId,
      ticketTypeKey: SupportTicketKey.Other,
    })
  }

  close(): void {
    this.$emit('input', false)
  }
}
</script>
