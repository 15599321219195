<template>
  <CUModal
    :value="value"
    width="820px"
    max-width="820px"
    @input="$emit('input', false)"
  >
    <template #title>Amenities Notes</template>
    <template #text>
      <div class="d-flex flex-column align-start w-full">
        <div class="d-flex flex-row flex-wrap w-full">
          <div
            v-for="(amenity, amenityIndex) in amenities"
            :key="`amenity-${amenity.id}-${amenityIndex}`"
            class="d-flex flex-column padding-r-1"
            :class="{
              'w-full': amenities.length < 2,
              'w-one-half': amenities.length >= 2,
            }"
          >
            <h3 :class="{ 'margin-t-5': amenityIndex >= 2 }">{{ amenity.title }}</h3>
            <p class="font-14 margin-t-2">{{ amenity.note.note }}</p>
          </div>
        </div>
      </div>
    </template>
  </CUModal>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AmenityItem } from '@/models/AmenityItem'

@Component({})
export default class TripAmenityCardModal extends Vue {
  @Prop({ type: Array, required: true }) readonly amenities!: AmenityItem[]
  @Prop({ type: Boolean, required: true }) readonly value!: boolean
}
</script>
