<template>
  <div
    class="margin-t-2"
    :class="{ 'margin-x-3': stacked, 'margin-x-4': !stacked }"
  >
    <span
      v-for="(info, infoIndex) in soldOutInfo"
      :key="`${info.messageText}-${infoIndex}`"
    >
      <SoldOutDetail
        :message-text="info.messageText"
        :tooltip-text="info.tooltipText"
      />
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import SoldOutDetail from '@/components/SoldOutDetail.vue'

@Component({ name: 'BidCardSoldOut', components: { SoldOutDetail } })
export default class BidCardSoldOut extends Vue {
  @Prop({ type: Boolean, default: false }) stacked!: boolean

  soldOutInfo = [
    {
      messageText: this.$t('quoteDetail.bidCard.WHY_SOLD_OUT_MATTERS'),
      tooltipText: this.$t('quoteDetail.bidCard.MATTERS_EXPLANATION'),
    },
    {
      messageText: this.$t('quoteDetail.bidCard.HOW_DO_WE_KNOW_SOLD_OUT'),
      tooltipText: this.$t('quoteDetail.bidCard.HOW_WE_KNOW_EXPLANATION'),
    },
  ]
}
</script>
