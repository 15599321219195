<template>
  <div class="font-14">
    <v-menu offset-y>
      <template #activator="{ on }">
        <CUIcon
          id="info-actions"
          view-box="0 0 24 24"
          class="kebab-button"
          v-bind="$attrs"
          v-on="on"
        >
          more_vert
        </CUIcon>
      </template>
      <v-list class="dropdown-menu padding-a-0">
        <v-list-item id="edit-user" key="edit-user" @click="openUnshareModal">
          <v-list-item-title>
            Unshare All
          </v-list-item-title>
        </v-list-item>
        <v-list-item id="delete-user" key="delete-user" @click="openShareModal">
          <v-list-item-title>
            Reshare All
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <CUModal v-model="isModalOpen">
      <template #title>
        {{ isUnshare ? 'Unshare All' : 'Reshare All' }}
      </template>
      <template #text>
        <div class="d-flex flex-column">
          <span>
            Are you sure you would like to
            {{ isUnshare ? 'unshare' : 'reshare' }} all quotes and reservations
            for this contact?
          </span>
          <div class="d-flex justify-space-between margin-t-5">
            <v-btn
              id="shared-contacts-more-actions-modal-cancel-button"
              width="150px"
              outlined
              text
              small
              @click="isModalOpen = false"
            >
              Cancel
            </v-btn>
            <v-btn
              id="shared-contacts-more-actions-modal-confirm-button"
              :loading="loading"
              class="margin-l-2 text-white"
              :color="isUnshare ? 'red' : 'primary'"
              width="150px"
              small
              @click="confirm"
            >
              Confirm
            </v-btn>
          </div>
        </div>
      </template>
    </CUModal>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import alert from '@/store/modules/alert'
import SharedContact from '@/models/dto/SharedContact'
import quotes from '@/services/quotes'
import reservation from '@/services/reservation'
import { EventBus } from '@/utils/eventBus'

@Component({})
export default class SharedContactsMoreActions extends Vue {
  @Prop({ required: true }) readonly row: SharedContact

  isModalOpen = false
  isUnshare = false
  loading = false

  openUnshareModal(): void {
    this.isUnshare = true
    this.isModalOpen = true
  }

  openShareModal(): void {
    this.isUnshare = false
    this.isModalOpen = true
  }

  async confirm(): Promise<void> {
    this.loading = true
    try {
      if (this.isUnshare) {
        for (const quoteId of this.row.quoteIds) {
          await quotes.unshare(quoteId, this.row.userId)
        }
        for (const reservationId of this.row.reservationIds) {
          await reservation.unshare(reservationId, this.row.userId)
        }
      } else {
        await Promise.all([
          ...this.row.quoteIds.map(async (quoteId) => {
            await quotes.share(quoteId, this.row.userId)
          }),
          ...this.row.reservationIds.map(async (reservationId) => {
            await reservation.share(reservationId, this.row.userId)
          }),
        ])
      }
      this.loading = false
      this.isModalOpen = false
      alert.add({
        text: `Successfully ${
          this.isUnshare ? 'unshared' : 'shared'
        } all quotes and reservations.`,
        color: 'primary',
        title: 'Success',
        dismissible: true,
      })
      EventBus.$emit('update-shared-contacts')
    } catch (e) {
      this.loading = false
      console.error(e)
    }
  }
}
</script>
