<template>
  <div class="d-flex align-center text-gray-subtle-text">
    <CUIcon view-box="0 0 24 24" class="h-20 w-20 margin-r-1">
      groups
    </CUIcon>
    Up to
    <span class="margin-x-1 font-medium">{{ capacity }}</span>
    passengers
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { WizardVehicle } from '@/models/dto'
import { VehicleTypeCapacity } from '@/utils/enum'
import { vehicleTypeKeyToCapacity } from '@/utils/vehicle'

@Component
export default class VehicleOptionPassengers extends Vue {
  @Prop({ type: Array, required: true }) readonly vehicles: WizardVehicle[]

  get capacity(): number {
    return this.vehicles.reduce((totalCapacity, {quantity, vehicleTypeKey}) => {
      const capacity = vehicleTypeKeyToCapacity(vehicleTypeKey)
      const totalVehicleTypeCapacity = quantity * capacity
      return totalCapacity + totalVehicleTypeCapacity
    }, 0)
  }
}
</script>
