<template>
  <v-card class="w-500">
    <v-card-text class="overflow-y-scroll max-h-two-thirds-vh">
      <h2>Filter By</h2>
      <v-divider class="margin-y-2 margin-x-0" />
      <h3>Contract</h3>
      <v-chip-group
        v-model="selectedContracts"
        multiple
        column
        active-class="primary--text"
      >
        <v-chip
          v-for="(contract, contractIndex) in contracts"
          :key="`${contract}-${contractIndex}`"
          outlined
          small
        >
          {{ contract }}
        </v-chip>
      </v-chip-group>
      <h3>Route</h3>
      <v-chip-group
        v-model="selectedTripNames"
        multiple
        column
        active-class="primary--text"
      >
        <v-chip
          v-for="(tripName, tripNameIndex) in tripNames"
          :key="`${tripName}-${tripNameIndex}`"
          outlined
          small
        >
          {{ tripName }}
        </v-chip>
      </v-chip-group>
      <v-expand-transition>
        <div v-if="selectedContracts.length">
          <h3>Vehicle</h3>
          <v-chip-group
            v-model="selectedVehicleNames"
            multiple
            column
            active-class="primary--text"
          >
            <template v-for="(vehicle, vehicleIndex) in vehicles">
              <v-chip
                v-show="
                  filters.contractName.some((contractName) =>
                    vehicle.contractName.includes(contractName)
                  )
                "
                :key="`${vehicle}-${vehicleIndex}-chip`"
                outlined
                small
              >
                {{ vehicle.vehicleName }}
              </v-chip>
            </template>
          </v-chip-group>
        </div>
      </v-expand-transition>
      <template v-if="isEnterpriseAdmin">
        <h3>Operator</h3>
        <v-chip-group
          v-model="selectedOperators"
          multiple
          column
          active-class="primary--text"
        >
          <v-chip
            v-for="(operator, operatorIndex) in operators"
            :key="`${operator}-${operatorIndex}`"
            outlined
            small
          >
            {{ operator }}
          </v-chip>
        </v-chip-group>
        <h3>Reservation</h3>
        <v-chip-group
          v-model="selectedReservationIds"
          multiple
          column
          active-class="primary--text"
        >
          <v-chip
            v-for="(reservationId, reservationIdIndex) in reservationIds"
            :key="`${reservationId}-${reservationIdIndex}`"
            outlined
            small
          >
            {{ reservationId }}
          </v-chip>
        </v-chip-group>
      </template>
      <h3>Vehicle Type</h3>
      <v-chip-group
        v-model="selectedVehicleTypes"
        multiple
        column
        active-class="primary--text"
      >
        <v-chip
          v-for="(vehicleType, vehicleTypeIndex) in vehicleTypeValues"
          :key="`${vehicleType}-${vehicleTypeIndex}`"
          outlined
          small
        >
          {{ vehicleType }}
        </v-chip>
      </v-chip-group>
      <h3>ADA Compliant</h3>
      <v-chip-group
        v-model="selectedADAValues"
        multiple
        column
        active-class="primary--text"
      >
        <v-chip
          v-for="(booleanValue, booleanValueIndex) in booleanValues"
          :key="`${booleanValue}-${booleanValueIndex}`"
          outlined
          small
        >
          {{ booleanValue }}
        </v-chip>
      </v-chip-group>

      <template v-if="isEnterpriseAdmin">
        <h3>Active</h3>
        <v-chip-group
          v-model="selectedActiveValues"
          multiple
          column
          active-class="primary--text"
        >
          <v-chip
            v-for="(booleanValue, booleanValueIndex) in booleanValues"
            :key="`${booleanValue}-${booleanValueIndex}`"
            outlined
            small
          >
            {{ booleanValue }}
          </v-chip>
        </v-chip-group>
      </template>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class EnterpriseTrackingFiltering extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly lastVehiclePositions: any[]
  @Prop({ type: Boolean, required: true }) readonly isEnterpriseAdmin: boolean

  @Watch('filters')
  filtersChanged(): void {
    this.$emit('input', this.filters)
  }
  @Watch('selectedContracts')
  selectedContractsChanged(): void {
    this.selectedVehicleNames = []
  }

  selectedOperators = []
  selectedReservationIds = []
  selectedActiveValues = []
  selectedADAValues = []
  selectedContracts = []
  selectedTripNames = []
  selectedVehicleNames = []
  selectedVehicleTypes = []

  get operators(): any[] {
    return [
      ...new Set(
        this.lastVehiclePositions.map((vehicle) => vehicle.operatorName)
      ),
    ].sort()
  }
  get contracts(): any[] {
    const contractSet = new Set()
    for (const vehicle of this.lastVehiclePositions) {
      if (vehicle?.reservation?.contractName) {
        contractSet.add(vehicle.reservation.contractName)
      }
    }
    return [...contractSet].sort()
  }
  get vehicles(): any[] {
    const vehicleContractPair = this.lastVehiclePositions.map((vehicle) => {
      return {
        vehicleName: vehicle.vehicleName,
        contractName: vehicle.reservation.contractName,
      }
    })
    return [...new Set(vehicleContractPair)].sort()
  }
  get reservationIds(): any[] {
    const reservationIdSet = new Set()
    for (const vehicle of this.lastVehiclePositions) {
      if (vehicle?.reservation?.managedId) {
        reservationIdSet.add(vehicle.reservation.managedId)
      }
    }
    return [...reservationIdSet].sort()
  }
  get tripNames(): any[] {
    const tripNameSet = new Set()
    for (const vehicle of this.lastVehiclePositions) {
      if (vehicle?.reservation?.tripName) {
        tripNameSet.add(vehicle.reservation.tripName)
      }
    }
    return [...tripNameSet].sort()
  }
  get vehicleTypeValues(): any[] {
    return [
      ...new Set(
        this.lastVehiclePositions.map((vehicle) => vehicle.vehicleType)
      ),
    ].sort()
  }
  get booleanValues(): any[] {
    return [true, false]
  }
  get filters(): any {
    const operatorName = this.selectedOperators.map(
      (operatorIndex) => this.operators[operatorIndex]
    )
    const reservationId = this.selectedReservationIds.map(
      (reservationIndex) => this.reservationIds[reservationIndex]
    )
    const contractName = this.selectedContracts.map(
      (contractIndex) => this.contracts[contractIndex]
    )
    const tripName = this.selectedTripNames.map(
      (tripNameIndex) => this.tripNames[tripNameIndex]
    )
    const vehicleName = this.selectedVehicleNames.map(
      (vehicleIndex) => this.vehicles[vehicleIndex].vehicleName
    )
    const vehicleType = this.selectedVehicleTypes.map(
      (vehicleTypeIndex) => this.vehicleTypeValues[vehicleTypeIndex]
    )
    const active = this.selectedActiveValues.map(
      (activeValueIndex) => this.booleanValues[activeValueIndex]
    )
    const isADACompliant = this.selectedADAValues.map(
      (adaValueIndex) => this.booleanValues[adaValueIndex]
    )

    const filters = {}
    if (operatorName.length) {
      filters['operatorName'] = operatorName
    }
    if (contractName.length) {
      filters['contractName'] = contractName
    }
    if (tripName.length) {
      filters['tripName'] = tripName
    }
    if (vehicleName.length) {
      filters['vehicleName'] = vehicleName
    }
    if (reservationId.length) {
      filters['reservationId'] = reservationId
    }
    if (vehicleType.length) {
      filters['vehicleType'] = vehicleType
    }
    if (isADACompliant.length) {
      filters['isADACompliant'] = isADACompliant
    }
    if (active.length) {
      filters['active'] = active
    }
    return filters
  }
}
</script>
