<template>
  <CUModal
    :value="value"
    width="550"
    :content-class="$vuetify.breakpoint.xs ? 'fullscreen-dialog' : ''"
    :fullscreen="$vuetify.breakpoint.xs"
    @input="$emit('input', $event)"
  >
    <template #title>{{ `${''}` }}</template>
    <template #text>
      <div class="d-flex flex-column">
        <div class="text-center w-one-half margin-b-4">
          <img src="@/assets/images/tripContact.svg" />
        </div>
        <div class="text-center w-full">
          <h2>{{ $t('reservationDetail.shareDialog.TITLE') }}</h2>
          <p>{{ $t('reservationDetail.shareDialog.BODY_1') }}</p>
          <p class="margin-b-2">
            {{ $t('reservationDetail.shareDialog.BODY_2') }}
          </p>
        </div>
        <div v-if="sharedList" class="w-full">
          <CustomerInviteSearch
            ref="customerInviteSearch"
            :label="$t('reservationDetail.shareDialog.RIDER_EMAIL')"
            :placeholder="$t('reservationDetail.shareDialog.RIDER_PLACEHOLDER')"
            :shared-list="sharedList"
            :show-back="showInviteForm"
            :error-messages="inviteErrorMessages"
            @share="share($event)"
            @unshare="unshare($event)"
            @invite="handleInviteNewUser"
            @back="showInviteForm = false"
          />
        </div>
        <template v-if="showInviteForm">
          <div class="w-full">
            <CustomerInviteForm
              :reservation-id="reservationId"
              :trigger-submit="submitting"
              :initial-email="inviteEmail"
              @invalid="submitting = false"
              @refresh="$emit('refresh-shared-list')"
              @submit="handleSubmit($event)"
            />
          </div>
        </template>
        <div class="w-full margin-t-2">
          <v-btn
            id="reservation-detail-add-riders"
            color="primary"
            class="w-full"
            :loading="submitting"
            @click="startSubmit"
          >
            {{ $t('reservationDetail.shareDialog.SHARE_TRIP_DETAILS') }}
          </v-btn>
        </div>
      </div>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import CustomerInviteSearch from '@/components/CustomerInviteSearch.vue'
import CustomerInviteForm from '@/components/CustomerInviteForm.vue'
import { Customer, InviteCustomerPayload, SharedList } from '@/models/dto'
import reservation from '@/services/reservation'
import customer from '@/services/customer'
import { validateEmailAddress } from '@/utils/string'

@Component({
  components: {
    CustomerInviteSearch,
    CustomerInviteForm,
  },
})
export default class ReservationDetailShareDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: Number, required: true }) readonly reservationId: number
  @Prop({ type: Array, required: true })
  readonly sharedList: SharedList

  @Watch('value')
  valueChanged(value: boolean): void {
    if (!value) {
      this.showInviteForm = false
    }
  }

  showInviteForm = false
  submitting = false
  inviteEmail = ''
  inviteErrorMessages = []

  async share(customer: Customer): Promise<void> {
    await reservation.share(this.reservationId, customer.customerId)
    this.$emit('refresh-shared-list')
  }

  async unshare(customer: Customer): Promise<void> {
    await reservation.unshare(this.reservationId, customer.customerId)
    this.$emit('refresh-shared-list')
  }

  async handleInviteNewUser(email: string): Promise<void> {
    if (this.validateEmail(email)) {
      const customer: InviteCustomerPayload = {
        firstName: '',
        lastName: '',
        phone: '',
        email,
      }
      await this.handleSubmit(customer)
      return
    }
    this.showForm(email)
  }

  showForm(email: string): void {
    this.showInviteForm = true
    this.inviteEmail = email
  }

  validateEmail(email: string): boolean {
    this.inviteErrorMessages = []
    const valid = validateEmailAddress(email)
    if (!valid) {
      this.inviteErrorMessages.push('Please enter a valid email.')
      return false
    }
    return true
  }

  startSubmit(): void {
    const inviteSearch = this.$refs['customerInviteSearch'] as any
    const inviteEmail = inviteSearch.search

    this.submitting = true
    this.handleSubmit({
      email: inviteEmail,
      firstName: '',
      lastName: '',
      phone: '',
    })
  }

  async handleSubmit(payload: InviteCustomerPayload): Promise<void> {
    const valid = this.validateEmail(payload.email)
    if (!valid) {
      this.submitting = false
      return
    }

    try {
      await customer.inviteByReservationId(this.reservationId, payload, false)
      this.$emit('refresh-shared-list')
      this.showInviteForm = false
      const searchBar = this.$refs['customerInviteSearch'] as any
      if (searchBar) {
        searchBar.clearCustomer()
      }
      this.close()
    } catch (error) {
      console.error(error)
    }

    this.submitting = false
  }

  close(): void {
    this.$emit('input', false)
  }
}
</script>
