<template>
  <div>
    <h2 class="margin-b-4">{{ header }}</h2>
    <TwoColumnLayout :full="!$vuetify.breakpoint.md" plain-container>
      <template #left-column>
        <div class="d-flex flex-column">
          <div class="d-flex margin-b-2">
            <div>
              <CUIcon
                viewBox="0 0 42 42"
                :width="18"
                :height="18"
                color="primary"
                class="margin-r-4"
              >
                check
              </CUIcon>
            </div>
            <p class="margin-t-0">
              {{ $t('reservationDetail.cancellationComplete.SEND_EMAIL') }}
              <span class="font-bold">{{ customerEmail }}</span>
            </p>
          </div>
          <div class="d-flex margin-b-2">
            <div>
              <CUIcon
                viewBox="0 0 42 42"
                :width="18"
                :height="18"
                color="primary"
                class="margin-r-4"
              >
                check
              </CUIcon>
            </div>
            <p class="margin-t-0">
              <span class="font-bold">
                {{
                  $t('reservationDetail.cancellationComplete.BOOKING_CANCELLED')
                }}
              </span>
              {{
                $t(
                  'reservationDetail.cancellationComplete.NO_NEED_TO_DO_ANYTHING'
                )
              }}
            </p>
          </div>
          <div v-if="isRefundDue" class="d-flex margin-b-2">
            <CUIcon
              viewBox="0 0 42 42"
              :width="18"
              :height="18"
              color="primary"
              class="margin-r-4"
            >
              check
            </CUIcon>
            <p class="margin-t-0">
              {{ $t('reservationDetail.cancellationComplete.PLEASE_ALLOW') }}
              <span class="font-bold">
                {{ $t('reservationDetail.cancellationComplete.BUSINESS_DAYS') }}
              </span>
            </p>
          </div>
          <div class="d-flex">
            <div>
              <CUIcon
                viewBox="0 0 42 42"
                :width="18"
                :height="18"
                color="primary"
                class="margin-r-4"
              >
                check
              </CUIcon>
            </div>
            <p class="margin-t-0">
              {{ $t('reservationDetail.cancellationComplete.QUESTIONS') }}
              <span class="font-bold margin-l-1">
                {{ $t('reservationDetail.cancellationComplete.CALL_US') }}
                <a class="text-gray-dark" :href="`tel:${supportNumber}`">
                  {{ formattedSupportNumber }}
                </a>
                .
              </span>
            </p>
          </div>
          <div class="d-flex margin-t-6">
            <v-btn
              class="margin-r-2"
              color="primary"
              small
              @click="$router.push({ name: 'self-serve' })"
            >
              {{ $t('reservationDetail.cancellationComplete.BUILD_QUOTE') }}
            </v-btn>
            <v-btn
              class="margin-l-2"
              color="primary"
              text
              small
              @click="$emit('to-reservation-detail')"
            >
              {{ $t('reservationDetail.cancellationComplete.VIEW_BOOKING') }}
            </v-btn>
          </div>
        </div>
      </template>
      <template #right-column>
        <ReservationCancellationSummary
          :class="{
            'margin-t-3': $vuetify.breakpoint.mdAndDown,
          }"
          :reservation="reservation"
          :penalty-info="penaltyInfo"
          complete
        />
      </template>
    </TwoColumnLayout>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import TwoColumnLayout from '@/layouts/TwoColumnLayout.vue'
import { ReservationDetail, ClientCancellationPenaltyInfo } from '@/models/dto'
import ReservationCancellationSummary from './ReservationCancellationSummary.vue'
import auth from '@/store/modules/auth'
import { getSupportNumber, phoneFormatFilter } from '@/utils/phone'

@Component({ components: { TwoColumnLayout, ReservationCancellationSummary } })
export default class ReservationCancelComplete extends Vue {
  @Prop({ type: Number, required: true }) readonly reservation: ReservationDetail
  @Prop({ type: Object, required: true })
  readonly penaltyInfo!: ClientCancellationPenaltyInfo
  @Prop({ type: String, required: true })
  readonly header: string

  get customerEmail(): string {
    return this.reservation?.customer?.email
  }

  get supportNumber(): string {
    return getSupportNumber(auth.customer.isElite)
  }

  get formattedSupportNumber(): string {
    return phoneFormatFilter(this.supportNumber)
  }

  get isRefundDue(): boolean {
    return this.penaltyInfo?.balanceDue < 0
  }

}
</script>
