<template>
  <div class="margin-b-2">
    <div class="d-flex align-center">
      <CUIcon
        v-if="icon"
        :view-box="icon.viewBox"
        class="w-24 h-24 flex-shrink-0 margin-r-2"
        color="cup-500"
      >
        {{ icon.name }}
      </CUIcon>
      <p class="text-cup-500 font-bold margin-t-0">{{ label }}</p>
    </div>

    <div
      v-for="(textItem, textItemIndex) in modalText"
      :id="`info-modal-text-item-${textItem.subLabel}-${textItemIndex}`"
      :key="`info-modal-text-item-${textItem.subLabel}-${textItemIndex}`"
      class="margin-l-8 margin-b-6"
    >
      <p class="text-white font-14 font-bold">
        {{ textItem.subLabel }}
      </p>
      <p class="text-white font-14 margin-t-0">
        {{ textItem.body }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { ValuePropKey } from '@/utils/enum'
import { InfoModalDetail } from '@/models/InfoModalDetail'
import {
  bookingProtectionModalDetail,
  liveSupportModalDetail,
  qualifiedOperatorsModalDetail,
  vehicleLocationTrackingModalDetail
} from '@/utils/infoModals'

const props = defineProps({
  valuePropKey: {
    type: String,
    required: true
  }
})

const valueProp = computed((): InfoModalDetail | null => {
  switch(props.valuePropKey) {
    case ValuePropKey.BookingProtection:
      return bookingProtectionModalDetail
    case ValuePropKey.Tracking:
      return vehicleLocationTrackingModalDetail
    case ValuePropKey.LiveSupport:
      return liveSupportModalDetail
    case ValuePropKey.QualifiedOperators:
      return qualifiedOperatorsModalDetail
    default:
      return null
  }
})

const icon = computed(() => {
  return valueProp?.value.icon
})

const label = computed(() => {
  return valueProp?.value.label
})

const modalText = computed(() => {
  return valueProp?.value.modalText
})
</script>
