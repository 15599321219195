<template>
  <div>
    <GmapPolyline
      v-for="(route, i) in routes"
      :key="`route-${i}`"
      :options="getRoutePolylineOptions(route)"
      @mouseover="onMouseOver"
      @mouseout="infoWindowOpen = false"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'
import { gmapApi } from 'vue2-google-maps'
import { Directions, TrackingVehicle } from '../models/dto/TrackingVehicle'
import tracking from '@/store/modules/tracking'
import trackingVehicleStore from '@/store/modules/trackingVehicles'
import { Path } from '@/models/dto/TrackingVehicle'

@Component({
  components: {
    GmapPolyline: () => import('vue2-google-maps/dist/components/polyline'),
  },
})
export default class LiveTrackingNextStopPolyline extends Vue {
  @Prop({ type: Object, required: true }) readonly vehicle: TrackingVehicle
  @Prop({ type: Object, required: true }) readonly directions: Directions

  tracking = tracking
  trackingVehicleStore = trackingVehicleStore
  infoWindowPosition = null
  infoWindowOpen = false
  routes = []

  get google(): any {
    return gmapApi()
  }

  get directionRendererOptions(): object {
    return {
      polylineOptions: this.polylineOptions,
      preserveViewport: true,
      suppressMarkers: true,
      directions: this.directions,
    }
  }

  get polylineOptions(): {
    path?: Path[]
    visible: boolean
    strokeColor: string
    strokeOpacity: number
    fillOpacity: number
    strokeWeight: number
    zIndex: number
  } {
    return {
      visible: this.isActiveVehicle,
      strokeColor: this.vehicle.color,
      strokeOpacity: 1,
      fillOpacity: 1,
      strokeWeight: this.strokeWeight,
      zIndex: 4,
    }
  }

  get strokeWeight(): number {
    return tracking.isVehicleAtStop ? 0 : 5
  }

  get isActiveVehicle(): boolean {
    return (
      this.trackingVehicleStore.activeTrackingVehicle?.journeyId ===
      this.vehicle.journeyId
    )
  }

  mounted() {
    const directionsDisplay = new this.google.maps.DirectionsRenderer(
      this.directionRendererOptions
    )
    const { routes } = directionsDisplay.directions
    for (const route of routes) {
      this.routes.push(route)
    }
  }

  getRoutePolylineOptions(route): object {
    const polylineOptions = this.polylineOptions
    polylineOptions.path = route.overview_path
    return polylineOptions
  }

  onMouseOver(event) {
    this.infoWindowOpen = true
    this.infoWindowPosition = event.latLng
  }
}
</script>
