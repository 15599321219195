<template>
  <CUModal
    :value="value"
    width="550px"
    closeable
    @input="close"
    @keydown.stop="handleKeydown"
  >
    <template #title>
      {{ title }}
    </template>
    <template #text>
      <v-form ref="form" @submit.prevent="submitHotelInformation">
        <div class="w-full">
          <p class="font-18  font-medium margin-t-n4">
            {{
              $t('reservationDetail.hotelInformationDialog.HOTEL_START_LABEL')
            }}
            <span class="font-weight-600">
              {{ hotelCheckInDate }}
            </span>
            {{ $t('reservationDetail.hotelInformationDialog.HOTEL_END_LABEL') }}
            <span class="font-weight-600">
              {{ hotelCheckOutDate }}
            </span>
          </p>
          <div class="w-full margin-t-3">
            <label
              for="hotel-address-input"
              class="d-flex margin-t-1 font-weight-600"
            >
              {{
                $t(
                  'reservationDetail.hotelInformationDialog.HOTEL_ADDRESS_LABEL'
                )
              }}
            </label>
            <AutoCompleteAddress
              :id="`hotel-information-dialog-address`"
              clearable
              clear-icon="replay"
              :initial-search="addressName"
              :auto-focus="!address"
              :hint="
                $t(
                  'reservationDetail.hotelInformationDialog.HOTEL_ADDRESS_FOOTER'
                )
              "
              :placeholder="
                $t(
                  'reservationDetail.hotelInformationDialog.HOTEL_ADDRESS_PLACEHOLDER'
                )
              "
              limit-countries
              @place-selected="setHotelAddress($event.place)"
              @place-cleared="setHotelAddress(null)"
            />
          </div>
          <div class="d-inline-block w-one-half">
            <label for="hotel-name" class="font-weight-600">
              {{
                $t('reservationDetail.hotelInformationDialog.HOTEL_NAME_LABEL')
              }}
            </label>
            <v-text-field
              id="hotel-name-input"
              v-model="hotelName"
              name="hotel-name-input"
              outlined
              class="w-eleven-twelfths h-50"
              hide-details
              maxlength="50"
            />
          </div>
          <div class="d-inline-block w-one-half">
            <label for="hotel-confirmation-number" class="font-weight-600">
              {{
                $t(
                  'reservationDetail.hotelInformationDialog.CONFIRMATION_NUMBER_LABEL'
                )
              }}
            </label>
            <v-text-field
              id="hotel-confirmation-number-input"
              v-model="confirmationNumber"
              name="hotel-confirmation-number-input"
              outlined
              class="w-eleven-twelfths h-50"
              maxlength="50"
              hide-details
              placeholder="e.g. ASD821117"
            />
          </div>
          <div class="w-full margin-t-1">
            <label for="hotel-booking-name" class="font-weight-600">
              {{
                $t(
                  'reservationDetail.hotelInformationDialog.BOOKING_NAME_LABEL'
                )
              }}
            </label>
            <v-text-field
              id="hotel-booking-name-input"
              v-model="bookingName"
              name="hotel-booking-name-input"
              outlined
              class="w-full h-50"
              maxlength="50"
              :placeholder="
                $t(
                  'reservationDetail.hotelInformationDialog.BOOKING_NAME_PLACEHOLDER'
                )
              "
            />
          </div>
          <div class="w-full margin-t-n2">
            <span>
              {{
                $t(
                  'reservationDetail.hotelInformationDialog.CONTACT_SUPPORT_BODY'
                )
              }}
            </span>
            <a class="font-bold" @click.stop="handleContactSupport">
              {{
                $t(
                  'reservationDetail.hotelInformationDialog.CONTACT_SUPPORT_LINK'
                )
              }}
            </a>
          </div>
          <v-btn
            id="hotel-information-button-save"
            color="primary"
            class="w-full margin-t-6"
            :loading="loading"
            @click="submitHotelInformation"
          >
            {{ $t('common.SAVE') }}
          </v-btn>
        </div>
      </v-form>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import AutoCompleteAddress from '@/components/AutoCompleteAddress.vue'
import trip from '@/services/trip'
import support from '@/store/modules/support'
import { KeyCode, SupportTicketKey } from '@/utils/enum'
import {
  HotelInformation,
  HotelRequirement,
  HotelInformationPayload,
  Address,
  PlaceDetail,
} from '@/models/dto'
import { EventBus } from '@/utils/eventBus'
import { placeDetailToAddress } from '@/utils/address'
import { nullIfEmpty } from '@/utils/string'

@Component({
  components: {
    AutoCompleteAddress,
  },
})
export default class HotelInformationDialog extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value: boolean
  @Prop({ type: Number, default: null }) readonly tripId: number
  @Prop({ type: Object, default: null }) hotelInformation: HotelInformation
  @Prop({ type: Object, default: null }) hotelRequirement: HotelRequirement

  @Watch('value')
  onValueChange(): void {
    if (this.value) {
      this.hotelName = this.hotelInformation?.hotelName
      this.confirmationNumber = this.hotelInformation?.confirmationNumber
      this.bookingName = this.hotelInformation?.nameOnReservation
      this.address = this.hotelInformation?.address
    }
  }

  support = support
  SupportTicketKey = SupportTicketKey

  loading = false
  hotelName = ''
  confirmationNumber = ''
  bookingName = ''
  address: Address = null

  get title() {
    if (this.hotelInformation) {
      return this.$t('reservationDetail.hotelInformationDialog.TITLE_EDIT')
    }
    return this.$t('reservationDetail.hotelInformationDialog.TITLE_ADD')
  }

  get hotelCheckInDate(): string {
    return this.hotelRequirement?.checkIn
      ? DateTime.fromISO(this.hotelRequirement?.checkIn).toFormat('MM/dd/yy')
      : ''
  }

  get hotelCheckOutDate(): string {
    return this.hotelRequirement?.checkOut
      ? DateTime.fromISO(this.hotelRequirement?.checkOut).toFormat('MM/dd/yy')
      : ''
  }

  get addressName(): string {
    return this.address?.addressName || this.address?.name || ''
  }

  close(): void {
    this.$emit('input', false)
  }

  handleKeydown(event): void {
    if (event.keyCode === KeyCode.Escape) {
      this.close()
    }
  }

  handleContactSupport(): void {
    support.open({
      ticketTypeKey: SupportTicketKey.Other,
    })
  }

  setHotelAddress(place: PlaceDetail): void {
    this.address = place ? placeDetailToAddress(place) : null
    this.hotelName = this.hotelName || this.address?.title
  }

  async submitHotelInformation(): Promise<void> {
    const payload: HotelInformationPayload = {
      hotelName: nullIfEmpty(this.hotelName),
      confirmationNumber: nullIfEmpty(this.confirmationNumber),
      nameOnReservation: nullIfEmpty(this.bookingName),
      address: this.address,
    }
    this.loading = true
    try {
      await trip.editHotelInfo(this.tripId, payload)
      EventBus.$emit('refresh-reservation-detail')
      this.close()
    } catch (error) {
      console.error(error)
    }
    this.loading = false
  }
}
</script>
