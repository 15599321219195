<template>
  <div>
    <h1>
      We were not able to submit your trip modification request online.
    </h1>
    <p>
      To modify your booking, please call our customer support service at
      <a :href="`tel:${supportNumber}`">{{ formattedSupportNumber }}</a>
    </p>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import authStore from '@/store/modules/auth'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'

@Component({})
export default class TripModificationError extends Vue {

  get supportNumber(): string {
    return getSupportNumber(authStore.customer.isElite)
  }

  get formattedSupportNumber(): string {
    return `1-${phoneFormatFilterHyphens(this.supportNumber)}`
  }
}
</script>
