<template>
  <div>
    <div class="d-flex flex-column font-16 padding-a-5 margin-x-2 margin-b-2">
      <div class="d-flex justify-space-between">
        <p>{{ $t('reservationDetail.cancellationSummary.YOUR_BOOKING') }}</p>
        <p>{{ bookingAmount }}</p>
      </div>
      <div class="d-flex justify-space-between">
        <p>{{ $t('reservationDetail.cancellationSummary.PAID') }}</p>
        <p>{{ paidAmount }}</p>
      </div>
      <div class="d-flex justify-space-between font-18">
        <p class="font-medium">
          {{ $t('reservationDetail.cancellationSummary.CANCELLATION_FEE') }}
        </p>
        <p class="font-medium">{{ cancellationAmount }}</p>
      </div>
    </div>
    <v-divider />
    <div class="d-flex flex-column font-16 padding-a-5 margin-x-2 margin-b-2">
      <div class="d-flex justify-space-between">
        <p>{{ totalText }}</p>
        <p>{{ totalAmount }}</p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ClientCancellationPenaltyInfo } from '@/models/dto'
import { currencyFilter } from '../utils/string'
import { TranslateResult } from 'vue-i18n'
@Component({})
export default class ReservationCancellationSummaryCharges extends Vue {
  @Prop({ type: Object, required: true })
  readonly penaltyInfo!: ClientCancellationPenaltyInfo

  get bookingAmount(): string {
    return currencyFilter(this.penaltyInfo?.amount)
  }

  get paidAmount(): string {
    return currencyFilter(this.penaltyInfo?.paid)
  }

  get cancellationAmount(): string {
    return currencyFilter(this.penaltyInfo?.cancellationFee)
  }

  get totalText(): TranslateResult {
    return this.penaltyInfo?.balanceDue < 0
      ? this.$t('reservationDetail.cancellationSummary.REFUND')
      : this.$t('reservationDetail.cancellationSummary.BALANCE_DUE')
  }

  get totalAmount(): string {
    return this.penaltyInfo?.balanceDue < 0
      ? currencyFilter(-this.penaltyInfo?.balanceDue)
      : currencyFilter(this.penaltyInfo?.balanceDue)
  }
}
</script>
