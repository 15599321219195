<template>
  <v-container class="padding-a-0">
    <div v-if="!hasReviewDetails">
      <SkeletonBox
        type="header"
        class="margin-b-6"
        :style="{ height: '34px' }"
      />
      <v-row>
        <v-col
          v-for="loader in 4"
          :key="`loader-${loader}`"
          cols="12"
          class="padding-t-0"
          :style="{ 'padding-bottom': '10px' }"
        >
          <SkeletonBox type="header" />
          <v-rating
            value="5"
            color="borderGray"
            class="margin-t-1 margin-b-6"
            dense
            size="28"
          />
        </v-col>
      </v-row>
      <v-row class="padding-r-0 justify-end">
        <v-col cols="auto">
          <SkeletonBox type="button" :style="{ width: '105px' }" block small />
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-form ref="trip-review-form">
        <v-row>
          <template v-if="showPickupReviewFields">
            <v-col
              cols="12"
              sm="4"
              :class="{ 'padding-b-0 margin-b-0': $vuetify.breakpoint.xsOnly }"
            >
              <p class="font-medium margin-t-0">
                {{ $t('reservationDetail.review.form.pickup.LABEL') }}
              </p>
              <PickupSatisfactionSelector
                v-model="review.pickupPositive"
                :disabled="isReviewComplete"
              />
            </v-col>
            <v-col
              cols="12"
              sm="8"
              :class="{ 'padding-t-0 margin-t-0': $vuetify.breakpoint.xsOnly }"
            >
              <v-textarea
                v-model="review.pickupReview"
                autocomplete="off"
                outlined
                height="100"
                no-resize
                class="margin-t-1"
                hide-details
                :disabled="isReviewComplete || review.pickupPositive === null"
                :placeholder="
                  $t('reservationDetail.review.form.pickup.PLACEHOLDER')
                "
              />
            </v-col>
          </template>
          <template v-if="showDriverAndVehicleReviewFields">
            <v-col
              cols="12"
              sm="4"
              :class="{ 'padding-b-0 margin-b-0': $vuetify.breakpoint.xsOnly }"
            >
              <p class="font-medium margin-t-0">{{ vehicleQuestionText }}</p>
              <v-rating
                ref="vehicleRatingRef"
                v-model="review.vehicleRating"
                :readonly="isReviewComplete"
                color="yellow"
                :style="{ 'margin-top': '-1px' }"
                dense
                hover
                size="28"
              />
              <p class="margin-y-0 h-18 font-12  font-medium">
                {{ vehicleRatingText }}
              </p>
            </v-col>
            <v-col
              cols="12"
              sm="8"
              :class="{ 'padding-t-0 margin-t-0': $vuetify.breakpoint.xsOnly }"
            >
              <v-textarea
                v-model="review.vehicleReview"
                :disabled="isReviewComplete || !review.vehicleRating"
                autocomplete="off"
                outlined
                height="100"
                no-resize
                class="margin-t-1"
                hide-details
                :placeholder="
                  $t('reservationDetail.review.form.vehicle.PLACEHOLDER')
                "
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              :class="{ 'padding-b-0 margin-b-0': $vuetify.breakpoint.xsOnly }"
            >
              <p class="font-medium margin-t-0">{{ driverQuestionText }}</p>
              <v-rating
                ref="driverRatingRef"
                v-model="review.driverRating"
                :readonly="isReviewComplete"
                color="yellow"
                :style="{ 'margin-top': '-1px' }"
                dense
                hover
                size="28"
              />
              <p class="margin-y-0 h-18 font-12  font-medium">
                {{ driverRatingText }}
              </p>
            </v-col>
            <v-col
              cols="12"
              sm="8"
              :class="{ 'padding-t-0 margin-t-0': $vuetify.breakpoint.xsOnly }"
            >
              <v-textarea
                v-model="review.driverReview"
                :disabled="isReviewComplete || !review.driverRating"
                autocomplete="off"
                outlined
                height="100"
                no-resize
                class="margin-t-1"
                hide-details
                :placeholder="
                  $t('reservationDetail.review.form.driver.PLACEHOLDER')
                "
              />
            </v-col>
          </template>
          <template v-if="isFinished">
            <v-col
              cols="12"
              sm="4"
              :class="{ 'padding-b-0 margin-b-0': $vuetify.breakpoint.xsOnly }"
            >
              <p class="font-medium margin-t-0">
                {{ $t('reservationDetail.review.form.operator.LABEL') }}
              </p>
              <v-rating
                ref="reservationCompanyRatingRef"
                v-model="review.operatorRating"
                :readonly="isReviewComplete"
                color="yellow"
                :style="{ 'margin-top': '-1px' }"
                dense
                hover
                size="28"
              />
              <p class="margin-y-0 h-18 font-12  font-medium">
                {{ reservationCompanyText }}
              </p>
            </v-col>
            <v-col
              cols="12"
              sm="8"
              :class="{ 'padding-t-0 margin-t-0': $vuetify.breakpoint.xsOnly }"
            >
              <v-textarea
                v-model="review.operatorReview"
                :disabled="isReviewComplete || !review.operatorRating"
                autocomplete="off"
                outlined
                height="100"
                no-resize
                class="margin-t-1"
                hide-details
                :placeholder="
                  $t('reservationDetail.review.form.operator.PLACEHOLDER')
                "
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              :class="{ 'padding-b-0 margin-b-0': $vuetify.breakpoint.xsOnly }"
            >
              <p class="font-medium margin-t-0">
                {{ $t('reservationDetail.review.form.charterup.LABEL') }}
              </p>
              <v-rating
                ref="charterUPExperienceRatingRef"
                v-model="review.charterUpRating"
                :readonly="isReviewComplete"
                color="yellow"
                :style="{ 'margin-top': '-1px' }"
                dense
                hover
                size="28"
              />
              <p class="margin-y-0 h-18 font-12  font-medium">
                {{ charterUPExperienceText }}
              </p>
            </v-col>
            <v-col
              cols="12"
              sm="8"
              :class="{ 'padding-t-0 margin-t-0': $vuetify.breakpoint.xsOnly }"
            >
              <v-textarea
                v-model="review.charterUpReview"
                :disabled="isReviewComplete || !review.charterUpRating"
                autocomplete="off"
                outlined
                height="100"
                no-resize
                class="margin-t-1"
                hide-details
                :placeholder="
                  $t('reservationDetail.review.form.charterup.PLACEHOLDER')
                "
              />
            </v-col>
          </template>
        </v-row>
      </v-form>
      <TripReviewFormAutosaveIndicator
        v-if="!isFinished"
        class="margin-t-2"
        :submitting="submitting"
        :error="error"
        :success="success"
      />
      <div v-else class="d-flex margin-t-3">
        <v-spacer />
        <v-btn
          v-if="!isReviewComplete"
          color="primary"
          small
          :loading="submitting"
          :disabled="disabledSubmit"
          @click="submit"
        >
          {{ $t('common.SUBMIT') }}
        </v-btn>
      </div>
    </div>
    <PickupReviewModal
      v-if="!isReviewComplete && showPickupReviewFields"
      :open.sync="isPickupReviewModalOpen"
      :review.sync="review"
      :is-pickup-already-reviewed="isPickupAlreadyReviewed"
    />
  </v-container>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import review from '@/services/review'
import { getLinkingVerb } from '@/utils/string'
import auth from '@/store/modules/auth'
import { RequiredVehicle, ReservationDetail, Stop } from '@/models/dto'
import { ReservationStatusKey } from '@/utils/enum'
import TripReviewFormAutosaveIndicator from '@/components/TripReviewFormAutosaveIndicator.vue'
import PickupSatisfactionSelector from '@/components/PickupSatisfactionSelector.vue'
import PickupReviewModal from '@/components/PickupReviewModal.vue'
import { minutesSince } from '@/utils/datetime'
import alert from '@/store/modules/alert'
import { TranslateResult } from 'vue-i18n'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({
  components: {
    TripReviewFormAutosaveIndicator,
    PickupSatisfactionSelector,
    PickupReviewModal,
    SkeletonBox,
  },
})
export default class TripReviewForm extends Vue {
  @Prop({ type: Object, required: true }) reservation: ReservationDetail

  @Watch('review', { deep: true })
  reviewChanged(): void {
    if (!this.reviewInitalized) {
      return
    }
    this.isReviewEdited = true
    if (this.isFinished) {
      return
    }
    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }
    this.debounce = window.setTimeout(async () => {
      await this.submit()
    }, 1000)
  }

  review = {
    charterUpRating: null,
    charterUpReview: '',
    operatorRating: null,
    operatorReview: '',
    driverRating: null,
    driverReview: '',
    pickupPositive: null,
    pickupReview: '',
    vehicleRating: null,
    vehicleReview: '',
  }

  submitting = false
  success = true
  error = false
  debounce = null
  isReviewEdited = false
  reviewInitalized = false

  isPickupReviewModalOpen = false
  isPickupAlreadyReviewed = false

  mounted(): void {
    this.initializeReview()
    this.processQueryParams()
  }

  get disabledSubmit(): boolean {
    return (
      !this.review.charterUpRating ||
      !this.review.operatorRating ||
      !this.review.driverRating ||
      !this.review.vehicleRating ||
      this.review.pickupPositive === null
    )
  }

  get isReviewComplete(): boolean {
    return (
      !this.isReviewEdited &&
      !!this.review.charterUpRating &&
      !!this.review.operatorRating &&
      !!this.review.driverRating &&
      !!this.review.vehicleRating &&
      this.review.pickupPositive !== null
    )
  }

  get hasReviewDetails(): boolean {
    return !!this.reservation?.reservationReview
  }

  get isFinished(): boolean {
    return this.reservation?.reservationStatus === ReservationStatusKey.Finished
  }

  get tense(): 'past' | 'present' {
    return this.isFinished ? 'past' : 'present'
  }

  get showPickupReviewFields(): boolean {
    return (
      (this.is15MinutesAfterFirstPickup && this.isStarted) || this.isFinished
    )
  }

  get showDriverAndVehicleReviewFields(): boolean {
    return (
      (this.is30MinutesAfterFirstPickup && this.isStarted) || this.isFinished
    )
  }

  get isStarted(): boolean {
    return this.reservation?.reservationStatus === ReservationStatusKey.Started
  }

  get minutesAfterFirstPickup(): number {
    const stops = this.reservation?.stops
    if (!stops) {
      return 0
    }
    const firstPickup: Stop = stops.find((stop) => stop.orderIndex === 0)
    if (!firstPickup) {
      return 0
    }
    return minutesSince(this.$dayjs(firstPickup.pickupDatetime).toISOString())
  }

  get is15MinutesAfterFirstPickup(): boolean {
    return this.minutesAfterFirstPickup >= 15
  }

  get is30MinutesAfterFirstPickup(): boolean {
    return this.minutesAfterFirstPickup >= 30
  }

  get requiredVehicles(): RequiredVehicle[] {
    return this.reservation?.requiredVehicles || []
  }

  get requiredVehicleLabel(): string {
    if (this.requiredVehicles.length !== 1) {
      return 'Vehicle'
    }
    return this.requiredVehicles[0].vehicleType.label
  }

  get requiredVehicleQuantity(): number {
    if (this.requiredVehicles.length !== 1) {
      return this.requiredVehicles.reduce((acc, curr) => acc + curr.quantity, 0)
    }
    return this.requiredVehicles[0].quantity
  }

  get requiredDriverQuantity(): number {
    return this.reservation?.requiredDrivers || 0
  }

  get driverQuestionText(): TranslateResult {
    if (this.requiredVehicleQuantity === 1) {
      return this.$t('reservationDetail.review.form.driver.LABEL_SINGULAR')
    }
    return this.$t('reservationDetail.review.form.driver.LABEL_PLURAL')
  }

  get vehicleQuestionText(): TranslateResult {
    if (this.requiredVehicleQuantity === 1) {
      return this.$t('reservationDetail.review.form.vehicle.LABEL_SINGULAR')
    }
    return this.$t('reservationDetail.review.form.vehicle.LABEL_PLURAL')
  }

  get charterUPExperienceText(): string {
    if (!this.hasReviewDetails) {
      return ''
    }
    const charterUPExperienceRatingRef = this.$refs
      .charterUPExperienceRatingRef as any
    const hoverIndex = charterUPExperienceRatingRef?.hoverIndex
    const rating = hoverIndex > -1 ? hoverIndex : this.review.charterUpRating
    if (!rating) {
      return ''
    }
    const fieldName = `${rating}_STAR_DETAIL`
    return this.$t(
      `reservationDetail.review.form.charterup.${fieldName}`
    ).toString()
  }

  get reservationCompanyText(): string {
    if (!this.hasReviewDetails) {
      return ''
    }
    const reservationCompanyRatingRef = this.$refs
      .reservationCompanyRatingRef as any
    const hoverIndex = reservationCompanyRatingRef?.hoverIndex
    const rating = hoverIndex > -1 ? hoverIndex : this.review.operatorRating
    if (!rating) {
      return ''
    }
    const fieldName = `${rating}_STAR_DETAIL`
    return this.$t(
      `reservationDetail.review.form.operator.${fieldName}`
    ).toString()
  }

  get driverRatingText(): string {
    if (!this.hasReviewDetails) {
      return ''
    }
    const driverRatingRef = this.$refs.driverRatingRef as any
    const hoverIndex = driverRatingRef?.hoverIndex
    const rating = hoverIndex > -1 ? hoverIndex : this.review.driverRating

    if (!rating) {
      return ''
    }
    const pluralOrSingular =
      this.requiredDriverQuantity === 1 ? 'SINGULAR' : 'PLURAL'
    const fieldName = `${rating}_STAR_DETAIL_${pluralOrSingular}`
    return this.$t(
      `reservationDetail.review.form.driver.${fieldName}`
    ).toString()
  }

  get vehicleRatingText(): string {
    if (!this.hasReviewDetails) {
      return ''
    }

    const vehicleRatingRef = this.$refs.vehicleRatingRef as any
    const hoverIndex = vehicleRatingRef?.hoverIndex
    const rating = hoverIndex > -1 ? hoverIndex : this.review.vehicleRating

    if (!rating) {
      return ''
    }
    const pluralOrSingular =
      this.requiredDriverQuantity === 1 ? 'SINGULAR' : 'PLURAL'
    const fieldName = `${rating}_STAR_DETAIL_${pluralOrSingular}`
    return this.$t(
      `reservationDetail.review.form.vehicle.${fieldName}`
    ).toString()
  }

  initializeReview(): void {
    const review = this.reservation?.reservationReview
    this.review = {
      charterUpRating: review?.charterUpRating || null,
      charterUpReview: review?.charterUpReview || '',
      operatorRating: review?.operatorRating || null,
      operatorReview: review?.operatorReview || '',
      driverRating: review?.driverRating || null,
      driverReview: review?.driverReview || '',
      pickupPositive: review?.pickupPositive,
      pickupReview: review?.pickupReview || '',
      vehicleRating: review?.vehicleRating || null,
      vehicleReview: review?.vehicleReview || '',
    }
    this.$nextTick(() => {
      this.reviewInitalized = true
    })
  }

  processQueryParams(): void {
    const queryParams = this.$route.query
    if (queryParams.hasOwnProperty('pickupRating')) {
      if (!this.isReviewComplete) {
        this.isPickupReviewModalOpen = true
        this.isPickupAlreadyReviewed = this.review.pickupPositive !== null
        if (!this.isPickupAlreadyReviewed) {
          this.review.pickupPositive =
            queryParams.pickupRating === 'null'
              ? null
              : queryParams.pickupRating === 'true'
        }
      }
      const query = Object.assign({}, queryParams)
      delete query.pickupRating
      this.$router.replace({ query })
    }
  }

  ratingTextClarification(
    rating: number,
    label: string,
    singular: boolean
  ): string {
    if (!rating || rating < 1 || rating > 5) {
      return ''
    }

    let description = ''
    let punctuation = '.'
    if (rating === 1) {
      description = 'terrible'
    }
    if (rating === 2) {
      description = 'bad'
    }
    if (rating === 3) {
      description = 'ok'
    }
    if (rating === 4) {
      description = 'good, just some small issues'
    }
    if (rating === 5) {
      description = 'great'
      punctuation = '!'
    }

    const verb = getLinkingVerb(singular, this.tense)
    return `${label} ${verb} ${description}${punctuation}`
  }

  redirectToReservationDetail(): void {
    this.$router.push({
      name: 'reservation-detail',
      params: { id: this.reservation.reservationId.toString() },
    })
  }

  async submit(): Promise<void> {
    this.success = false
    this.submitting = true
    this.error = false

    const payload = {
      ...this.review,
      hash: this.reservation.reservationReview.hash,
      reservationId: this.reservation.reservationId,
      customerId: auth.userId || null,
    }

    let success = false
    try {
      await review.create(payload)
      success = true
    } catch (error) {
      console.warn(error)
    }

    window.setTimeout(() => {
      this.submitting = false
      this.success = success
      if (success) {
        this.isReviewEdited = false
      }
      this.error = !success

      if (this.isFinished) {
        alert.add({
          text: 'Thank you for your feedback!',
          color: 'success',
          title: 'Review Saved',
          dismissible: true,
          duration: 5000,
        })
      }
    }, 500)
  }
}
</script>
