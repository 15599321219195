<template>
  <CUModal :value="value" max-width="518px" @input="$emit('input', false)">
    <template #title>Edit Rider</template>
    <template #text>
      <v-form ref="form" v-model="formIsValid">
        <v-row no-gutters>
          <v-col cols="12">
            <label for="employeeId">Employee ID</label>
            <v-text-field
              id="invite-user-team-employeeId"
              ref="employeeId"
              v-model="formData.employeeId.value"
              v-bind="formData.employeeId"
              required
              validate-on-blur
              outlined
            />
          </v-col>
          <v-col cols="6">
            <label for="firstName">First name</label>
            <v-text-field
              id="invite-user-team-first-name"
              ref="firstName"
              v-model="formData.firstName.value"
              v-bind="formData.firstName"
              :class="{
                'padding-r-3': $vuetify.breakpoint.xs,
                'padding-r-4': $vuetify.breakpoint.smAndUp,
              }"
              validate-on-blur
              outlined
            />
          </v-col>
          <v-col cols="6">
            <div
              :class="{
                'padding-l-3': $vuetify.breakpoint.xs,
                'padding-l-4': $vuetify.breakpoint.smAndUp,
              }"
            >
              <label for="lastName">Last name</label>
              <v-text-field
                id="invite-user-team-last-name"
                ref="lastName"
                v-model="formData.lastName.value"
                v-bind="formData.lastName"
                validate-on-blur
                outlined
              />
            </div>
          </v-col>
          <v-col cols="6">
            <label for="email">Email</label>
            <v-text-field
              id="invite-user-team-email"
              ref="email"
              v-model="formData.email.value"
              v-bind="formData.email"
              :class="{
                'padding-r-3': $vuetify.breakpoint.xs,
                'padding-r-4': $vuetify.breakpoint.smAndUp,
              }"
              validate-on-blur
              outlined
            />
          </v-col>
          <v-col cols="6">
            <div
              :class="{
                'padding-l-3': $vuetify.breakpoint.xs,
                'padding-l-4': $vuetify.breakpoint.smAndUp,
              }"
            >
              <label for="phone">Phone</label>
              <v-text-field
                id="invite-user-team-phone"
                ref="phone"
                v-model="formData.phone.value"
                v-bind="formData.phone"
                validate-on-blur
                outlined
              />
            </div>
          </v-col>
          <v-col cols="12">
            <OrganizationEditRiderChildCustomerAccounts
              v-model="childCustomerAccountIds"
              :available-customer-accounts="customerAccount.teams"
            />
          </v-col>
        </v-row>
        <AlertBox :active="!!errorMessage" type="error" class="margin-b-3">
          {{ errorMessage }}
        </AlertBox>
        <v-row justify="end" :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="auto">
            <v-btn id="invite-user-team-cancel" small text @click="close">
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              id="invite-user-team-submit"
              class="primary"
              small
              @click="send"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import rider from '@/services/rider'
import auth from '@/store/modules/auth'
import { TableViewRider } from '@/models/dto/TableViewRider'
import { CustomerAccount, UpdateRiderPayload } from '@/models/dto'
import OrganizationEditRiderChildCustomerAccounts from './OrganizationEditRiderChildCustomerAccounts.vue'
import axios from 'axios'
import AlertBox from '@/components/AlertBox.vue'

@Component({
  components: {
    OrganizationEditRiderChildCustomerAccounts,
    AlertBox,
  },
})
export default class OrganizationEditRiderDialog extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value: boolean
  @Prop({ type: Object, default: () => {} }) readonly rider: TableViewRider
  @Prop({ type: Object, default: () => {} })
  readonly customerAccount: CustomerAccount

  @Watch('rider', { immediate: true })
  riderChanged(): void {
    if (this.value) {
      this.resetValues()
      this.formData.employeeId.value = this.rider.employeeId
      this.formData.firstName.value = this.rider.firstName
      this.formData.lastName.value = this.rider.lastName
      this.formData.email.value = this.rider.email
      this.formData.phone.value = this.rider.phone
      this.childCustomerAccountIds = this.rider.childCustomerAccountIds
    }
  }

  formIsValid = false
  success = false
  errorMessage = ''
  childCustomerAccountIds = null

  formData: Record<string, { value: string; rules: any[] }> = {
    employeeId: {
      value: '',
      rules: [(v) => !!v || 'Employee ID is required'],
    },
    firstName: {
      value: '',
      rules: [],
    },
    lastName: {
      value: '',
      rules: [],
    },
    email: {
      value: '',
      rules: [(v) => !v || /.+@.+/.test(v) || 'E-mail must be valid'],
    },
    phone: {
      value: '',
      rules: [
        (v) =>
          !v ||
          /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
            v
          ) ||
          'Phone must be valid',
      ],
    },
  }

  mounted(): void {
    this.setup()
  }

  setup(): void {
    this.success = false
  }

  resetValues(): void {
    const form = this.$refs.form as any
    if (form) {
      form.resetValidation()
    }
  }

  close(): void {
    this.$emit('input', false)
  }

  backToSite() {
    this.success = false
    this.setup()
    this.close()
  }

  async send() {
    const form = this.$refs.form as any
    if (!form.validate()) {
      return
    }
    this.errorMessage = ''
    const payload: UpdateRiderPayload = {
      firstName: this.formData.firstName.value,
      lastName: this.formData.lastName.value,
      employeeId: this.formData.employeeId.value,
      email: this.formData.email.value,
      phone: this.formData.phone.value,
      customerAccountId: auth.customerAccount.customerAccountId,
      riderId: this.rider.riderId,
      childCustomerAccountIds: this.childCustomerAccountIds,
    }
    try {
      await rider.update(payload)
      this.success = true
      this.close()
      this.$emit('success')
      // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-explicit-any
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errorMessage = e.response?.data?.message
        this.errorMessage = errorMessage
      }
    }
  }
}
</script>
