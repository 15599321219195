<template>
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 640.2 103"
    :style="{ 'enable-background': 'new 0 0 640.2 103' }"
    xml:space="preserve"
  >
    <path
      :fill="fill"
      d="M39.8,83.4c6.6,0,12.6-3.3,17.9-9.9l11.8,13.2C60.3,96.2,50.4,101,39.7,101c-10.7,0-19.7-3.4-27-10.1
      C5.3,84.1,1.6,75.3,1.6,64.4c0-10.9,3.7-19.7,11.2-26.6c7.2-6.7,16.7-10.4,26.5-10.2c5.3,0,10.6,1.1,15.5,3.2
      c5.2,2.1,9.9,5.3,13.8,9.4L58.3,53.7c-2.3-2.7-5.2-4.9-8.4-6.3c-3.1-1.4-6.4-2.2-9.9-2.2c-5-0.1-9.8,1.7-13.4,5.1
      c-3.7,3.4-5.6,8.1-5.6,14.1c0,6,1.9,10.7,5.6,14.1C30.2,81.7,34.9,83.5,39.8,83.4z"
    />
    <path
      :fill="fill"
      d="M98.1,61.6V100H78.3V1.8h19.8v34.9c6-6.1,12.7-9.1,20.2-9.1c7.1-0.1,14,2.7,18.8,7.9c5.1,5.3,7.7,12.4,7.7,21.2
      V100H125V61.1c0-10.9-4-16.4-12-16.4c-3.9,0-7.7,1.5-10.4,4.3C99.6,51.8,98.1,56,98.1,61.6z"
    />
    <path
      :fill="fill"
      d="M165,90.8c-6.8-6.8-10.2-15.7-10.2-26.8s3.5-19.9,10.4-26.5c6.4-6.4,15.2-10,24.2-9.9
      c6.9-0.1,13.6,2.5,18.6,7.1v-6h20V100h-20v-5.8c-5.4,4.5-12.3,6.9-19.3,6.9C179.7,101,171.8,97.6,165,90.8z M174.8,64.5
      c0,5.8,1.7,10.6,5.2,14.2c3,3.5,7.4,5.5,12,5.5c4.5,0,8.8-2,11.6-5.5c3.2-3.7,4.8-8.4,4.8-14.1c0.2-5.2-1.5-10.4-4.8-14.5
      c-2.8-3.7-7.1-5.8-11.8-5.8c-4.7,0-9.1,2.1-12,5.7C176.5,53.9,174.8,58.7,174.8,64.5z"
    />
    <path
      :fill="fill"
      d="M280.6,46.3c-5.9,0-10.3,2.1-13.2,6.3c-2.9,4.2-4.4,9.7-4.4,16.6V100h-19.8V28.8H263v9.4c2.7-3,6-5.5,9.6-7.4
      c3.6-2,7.6-3,11.7-3.2l0.1,18.6H280.6z"
    />
    <path
      :fill="fill"
      d="M313.1,7.5v21.3h17.2v15.5h-17.2V75c-0.1,2.3,0.6,4.6,2.1,6.4c1.2,1.5,3.1,2.3,5,2.3c3.7,0,6.8-1.7,9.4-5
      l7.4,13.9c-6.2,5.6-12.7,8.5-19.5,8.5c-6.4,0.2-12.7-2.3-17.3-6.7c-4.7-4.5-7.1-10.6-7.1-18.4V7.5H313.1z"
    />
    <path
      :fill="fill"
      d="M376.6,84.7c7.8-0.1,15.1-4,19.6-10.4l11.4,12.3c-9.1,9.6-19.3,14.4-30.7,14.4c-11.4,0-20.5-3.5-27.3-10.4
      c-6.8-7-10.2-15.8-10.2-26.6s3.7-19.5,11.2-26.3c7-6.5,16.2-10.1,25.7-10c9.7,0,17.8,2.7,24.3,8.2c6.4,5.2,10.3,12.9,10.6,21.1v2.5
      l-47.3,19.6C366.7,82.9,371,84.7,376.6,84.7z M377.2,44.8c-4.7,0-9.3,1.7-12.9,4.8c-3.7,3.2-5.6,7.6-5.6,13.2c0,0.8,0,1.6,0.1,2.4
      l30.9-12.6C388.6,47.4,384.4,44.8,377.2,44.8L377.2,44.8z"
    />
    <path
      :fill="fill"
      d="M458.3,46.3c-5.9,0-10.3,2.1-13.2,6.3c-2.9,4.2-4.4,9.7-4.4,16.6V100h-19.8V28.8h19.8v9.4c2.7-3,6-5.5,9.6-7.4
      c3.6-2,7.6-3,11.7-3.2l0.1,18.6H458.3z"
    />
    <path
      :fill="fill"
      d="M492,92.8c-6.5-5.6-9.7-13.8-9.7-24.5V14.8h14.4v53c0,7.1,1.9,12.4,5.8,15.9s9.5,5.4,16.8,5.4
      c7.2,0,12.8-1.8,16.7-5.4c3.9-3.6,5.8-8.9,5.8-15.9v-53h14.3v53.5c0,10.7-3.2,18.8-9.6,24.5c-6.4,5.6-15.4,8.5-27.2,8.5
      C507.6,101.3,498.5,98.4,492,92.8z"
    />
    <path
      :fill="fill"
      d="M572.2,14.8h38c9.4,0,16.5,2.6,21.3,7.8s7.2,12.1,7.2,20.6c0,8.5-2.6,15.2-7.9,20.4c-5.2,5.1-12.3,7.7-21.2,7.7
      h-23V100h-14.5V14.8z M606.9,59.9c5.6,0,9.8-1.5,12.8-4.4c3-3,4.5-7,4.5-12.2c0-5.5-1.4-9.7-4.2-12.7s-7-4.5-12.4-4.5h-21v33.8
      H606.9z"
    />
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'CharterUPLogo',
})
export default class CharterUPLogo extends Vue {
  @Prop({ type: String, required: false, default: 'blue' }) private readonly color!: 'white' | 'blue'

  get fill(): string {
    return this.color === 'white' ? this.$vuetify.theme.themes.light.white.toString() : this.$vuetify.theme.themes.light.blue.toString()
  }
}
</script>
