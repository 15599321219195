<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <div
        :class="{
          'd-flex align-center': $vuetify.breakpoint.smAndUp,
        }"
      >
        <h1 class="margin-y-0">
          Your request has been received.
        </h1>
        <p
          class="font-22 font-medium"
          :class="{
            'margin-l-4 margin-y-0': $vuetify.breakpoint.smAndUp,
            'margin-t-2': $vuetify.breakpoint.xsOnly,
          }"
        >
          {{ subHeader }}
        </p>
      </div>
    </div>
    <ul class="margin-t-8 margin-l-4">
      <li class="margin-b-4">
        An email with request details was sent to
        <span class="font-bold">{{ customerEmail }}.</span>
      </li>
      <li class="margin-b-4">
        <span class="font-bold">
          Changes may impact pricing and availability.
        </span>
        We'll contact you to approve any pricing changes and confirm
        changes.
      </li>
      <li class="margin-b-4">
        All changes require review. If confirmed, you will receive an
        update via e-mail and reservation page.
      </li>
    </ul>
    <v-divider v-if="$vuetify.breakpoint.xsOnly" />
    <div
      :class="{
        'margin-t-12': !$vuetify.breakpoint.xsOnly,
      }"
    >
      <TripModificationReview />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import TripModificationReview from '@/components/TripModificationReview.vue'
import TripModificationError from '@/components/TripModificationError.vue'
import tripModificationStore from '@/store/modules/tripModification'
import authStore from '@/store/modules/auth'

@Component({
  components: {
    TripModificationReview,
    TripModificationError
  },
})
export default class TripModificationConfirmation extends Vue {

  get subHeader(): string {
    const managedId = tripModificationStore.managedId
    const routeName = tripModificationStore.routeName
    const formattedId = `ID ${managedId}`
    return routeName ? `${routeName} – ${formattedId}` : formattedId
  }

  get customerEmail(): string {
    return authStore.customer.email
  }
}
</script>
<style lang="scss" scoped>
.theme--light.v-application li {
  list-style: disc;
}
.theme--light.v-application li::marker {
  color: $primary;
}
</style>
