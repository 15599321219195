<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewBox"
    :aria-labelledby="vehicleTypeKey"
    :width="width"
    :height="height"
    :style="{ margin: margin }"
    role="presentation"
  >
    <title :id="vehicleTypeKey" lang="en">{{ vehicleTypeKey }} icon</title>
    <component :is="icon" />
  </svg>
</template>

<script lang="ts">
import { toPascal, toSnake } from '@/utils/string'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class VehicleTypeIcon extends Vue {
  @Prop({ type: String, required: true }) readonly vehicleTypeLabel: string
  @Prop({ type: [Number, String], default: 24 }) readonly width: number | string
  @Prop({ type: [Number, String], default: 24 }) readonly height:
    | number
    | string
  @Prop({ type: String, default: '0' }) readonly margin: string
  @Prop({ type: String, default: '0 0 91 49' }) readonly viewBox: string

  get vehicleTypeKey(): string {
    if (
      !['Charter Bus', 'Mini Bus', 'Sprinter'].includes(this.vehicleTypeLabel)
    ) {
      return 'charter_bus'
    }
    return toSnake(this.vehicleTypeLabel)
  }

  get vehicleTypePascal(): string {
    return toPascal(this.vehicleTypeKey)
  }

  get icon(): any {
    if (this.vehicleTypeKey) {
      return () =>
        import(
          `@/components/vehicleTypeIcons/VehicleTypeIcon${this.vehicleTypePascal}.vue`
        )
    }
    return null
  }
}
</script>
