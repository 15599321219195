<template>
  <v-row>
    <v-col
      v-for="(tripType, tripTypeIndex) in tripTypes"
      :key="`trip-type-${tripType.label}-${tripTypeIndex}`"
      cols="12"
      md="4"
    >
      <div
        class="shadow-actionable-card border-radius-2x padding-a-4 border-2 border-solid font-medium text-center cursor-pointer transition-all transition-duration-300 transition-ease h-full d-flex"
        :class="{
          'border-border-gray': !isTripTypeSelected(tripType.id),
          'border-primary': isTripTypeSelected(tripType.id),
          'flex-column align-center justify-end': $vuetify.breakpoint.smAndUp,
        }"
        @click="selectTripType(tripType.id)"
      >
        <img
          v-if="$vuetify.breakpoint.smAndUp"
          :src="tripType.icon"
          class="min-h-76 max-h-100 margin-b-4"
        />
        <span class="order-1 order-sm-0">{{ tripType.label }}</span>
        <CURadioButton
          class="order-0 order-sm-1"
          x-large
          :selected="isTripTypeSelected(tripType.id)"
          :class="{
            'margin-r-4': $vuetify.breakpoint.xs,
            'margin-t-2': $vuetify.breakpoint.smAndUp,
          }"
        />
      </div>
    </v-col>
    <v-expand-transition>
      <v-col v-if="description" cols="12">
        <div class="align-center d-flex justify-center padding-x-4 text-body-1">
          <CUIcon view-box="0 0 24 24" color="primary" class="margin-r-2">
            info_outlined
          </CUIcon>
          <div>{{ description }}</div>
        </div>
      </v-col>
    </v-expand-transition>
    <v-col cols="12" class="d-flex justify-center">
      <v-btn
        color="primary"
        :disabled="!selectedTripType"
        class="w-full"
        :class="{
          'max-w-320': $vuetify.breakpoint.smAndUp,
        }"
        @click="nextStep"
      >
        {{ buttonText }}
        <CUIcon view-box="0 0 24 24" class="margin-l-2">
          arrow_right
        </CUIcon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import selfServe from '@/store/modules/selfServe'
import { TripTypeId } from '@/utils/enum'
import { Vue, Component } from 'vue-property-decorator'
import CURadioButton from '@/components/CURadioButton.vue'
import oneWayIcon from '@/assets/images/selfServe/oneWay.svg'
import roundTripIcon from '@/assets/images/selfServe/roundTrip.svg'
import largeEventIcon from '@/assets/images/selfServe/largeEvent.svg'
import { SalesBotTrip } from '@/classes/SalesBotTrip'
import auth from '@/store/modules/auth'

interface TripType {
  id: number
  label: string
  icon: any
  description: string
}

@Component({ components: { CURadioButton } })
export default class SalesBotTripType extends Vue {
  tripTypes: TripType[] = [
    {
      id: TripTypeId.OneWay,
      label: 'One Way',
      icon: oneWayIcon,
      description: 'A one way trip is pretty self-explanatory.',
    },
    {
      id: TripTypeId.RoundTrip,
      label: 'Round Trip',
      icon: roundTripIcon,
      description: 'A round trip always brings you back home.',
    },
    {
      id: TripTypeId.LargeEvent,
      label: 'Large Event',
      icon: largeEventIcon,
      description: 'A large event requires six or more vehicles.',
    },
  ]

  created() {
    if (!this.selectedTripType) {
      this.selectTripType(TripTypeId.RoundTrip)
    }
  }

  mounted() {
    this.trackViewSelfServeStart()
  }

  get selectedTripType(): TripType {
    const selectedTripType = this.tripTypes.find(
      (tripType) => selfServe?.quote?.trips?.[0]?.tripTypeId === tripType.id
    )
    return selectedTripType || null
  }

  get description(): string {
    return this.selectedTripType?.description || ''
  }

  isTripTypeSelected(tripTypeId: number): boolean {
    if (!this.selectedTripType) {
      return false
    }
    return this.selectedTripType.id === tripTypeId
  }

  selectTripType(tripTypeId: number, tripIndex = 0): void {
    selfServe.setTripType({ tripIndex, tripTypeId })
  }

  nextStep(): void {
    const currentTrip: SalesBotTrip =
      selfServe.quote?.trips?.[selfServe.currentTripIndex]
    const isRoundTrip = currentTrip.tripTypeId === TripTypeId.RoundTrip
    const isLessThan3Stops = currentTrip.stops.length < 3
    if (isRoundTrip && isLessThan3Stops) {
      currentTrip.addStop()
      currentTrip.copyAddress(0, currentTrip.stops.length - 1)
    }

    const isOneWay = currentTrip.tripTypeId === TripTypeId.OneWay
    const isMoreThan2Stops = currentTrip.stops.length > 2
    const isLastStopEmpty = !currentTrip.stops[currentTrip.stops.length - 1]
      .address
    if (isOneWay && isMoreThan2Stops && isLastStopEmpty) {
      currentTrip.removeStop(currentTrip.stops.length - 1)
    }
    this.trackTripTypeSubmission()
    selfServe.nextStep()
  }

  trackTripTypeSubmission(): void {
    if (auth.customer != null) {
      this.$ga4.trackEvent('sb_trip_type', {
        isElite: !!auth.customer.isElite,
        isAuth0: this.$auth0.isInitialized,
        hasBookedBefore: auth?.customer?.convertedQuoteCount > 0,
        isLoggedIn: true,
      })
    } else {
      this.$ga4.trackEvent('sb_trip_type', {
        isElite: false,
        isAuth0: this.$auth0.isInitialized,
        hasBookedBefore: false,
        isLoggedIn: false,
      })
    }
  }

  get buttonText(): string {
    return selfServe.isEditing ? 'Update' : 'Continue'
  }

  trackViewSelfServeStart(): void {
    if (auth.customer != null) {
      this.$ga4.trackEvent('sb_view_start', {
        isElite: !!auth.customer.isElite,
        isAuth0: this.$auth0.isInitialized,
        hasBookedBefore: auth?.customer?.convertedQuoteCount > 0,
        isLoggedIn: true,
      })
    } else {
      this.$ga4.trackEvent('sb_view_start', {
        isElite: false,
        isAuth0: this.$auth0.isInitialized,
        hasBookedBefore: false,
        isLoggedIn: false,
      })
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  touch-action: manipulation;
}
</style>
