<template>
  <CUModal :value="value" width="500px" @input="$emit('input', $event)">
    <template #title>Card Information</template>
    <template #text>
      <CreditCardFormV2
        ref="credit-card-form"
        v-model="cardInfo"
        :show-cancel="false"
        hide-save-for-later
      />
    </template>
    <template #actions>
      <div class="d-flex grow" />
      <v-btn class="margin-r-2" small text @click="close">
        Cancel
      </v-btn>
      <v-btn
        class="primary"
        small
        tabindex="6"
        :loading="loading"
        @click="submit"
      >
        Save
      </v-btn>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import paymentProfile from '@/services/paymentProfile'
import CreditCardFormV2 from '@/components/CreditCardFormV2.vue'
import auth from '@/store/modules/auth'
import alert from "@/store/modules/alert";
import { FormattedPaymentMethod } from '@/models/FormattedPaymentMethod'
import { CreditCardTypeKey } from '@/utils/enum'
import { CompanyId } from '@/utils/enum'

@Component({
  components: {
    CreditCardFormV2,
  },
})
export default class PaymentProfileCardModal extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean

  cardInfo: FormattedPaymentMethod = null
  formIsValid = false
  loading = false

  reset(): void {
    //@ts-ignore
    const cardForm = this.$refs['credit-card-form'].$refs['card-form'] as any
    cardForm.reset()

    //@ts-ignore
    const addressForm = this.$refs['credit-card-form'].$refs['address-form'] as any
    addressForm.reset()
  }

  close(): void {
    this.reset()
    this.$emit('input', false)
  }

  async submit(): Promise<void> {
    const paymentForm = this.$refs['credit-card-form'] as any

    const cardForm = paymentForm.$refs['card-form'] as any
    const cardFormValid = cardForm.validate()

    const addressForm = paymentForm.$refs['address-form'] as any
    const addressFormValid = addressForm.validate()

    const valid = addressFormValid
    if (!valid) {
      return
    }

    let tokenizedCardInfo;
    try {
      const finixForm = this.$refs['credit-card-form'] as any;
      const token = await finixForm.getTokenizedFinixInfo()
      tokenizedCardInfo = {
        tokens: [token],
      }
    } catch (errors) {
      alert.add({
        title: 'Error Submitting Payment Method',
        text: `${errors?.[0] || 'Something went wrong, please check your card information.'}`,
        color: 'error',
      })
      return
    }

    this.loading = true
    try {
      const payload = {
        companyId: 2,
        checkoutPageId: null,
        mask: this.cardInfo.cardNumber.slice(-4),
        nonces: tokenizedCardInfo.tokens,
        cardExpirationDate: this.cardInfo.exp_date,
        expiration: this.cardInfo.exp_date,
        cardCode: this.cardInfo.securityCode,
        accountHolderName: this.cardInfo.cardholderName,
        cardLabel: this.cardInfo.type_label,
        useDefaultPaymentGateway: false,
        customerId: auth.userId,
        address: {
          ...this.cardInfo.address,
          postalCode: this.cardInfo.address.postalCode,
          timeZone: this.cardInfo.address.time_zone
        },
        showOnCharterUp: true,
      }
      const { data } = await paymentProfile.add(payload)
      this.$emit('card-added', data?.customerPaymentProfile)
      this.close()
    } catch (err) {
      console.error(err)
    }
    this.loading = false
  }

}
</script>
