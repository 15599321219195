<template>
  <CUModal :value="value" :closeable="closeable" persistent @input="$emit('input', $event)" @close="$emit('skip')">
    <template #text>
      <div class="d-flex flex-column">
        <div class="d-flex w-full">
          <div class="grow" />
          <v-btn
            v-if="!closeable"
            color="gray-light"
            plain
            small
            class="margin-t-0 font-18  padding-x-0 min-w-0 font-book margin-r-4"
            @click="$emit('skip')"
          >
            Skip
          </v-btn>
        </div>
        <div class="w-full padding-t-6 padding-x-6 padding-b-3">
          <img
            src="@/assets/images/tripReceived.svg"
            class="display-block margin-x-auto max-w-232 margin-b-6"
          />
          <h3 class="font-22 font-bold text-center">
            {{ header }}
          </h3>
          <p class="text-center margin-b-6 font-16">
            {{ subheader }}
          </p>
          <v-form ref="tripReceivedForm">
            <label class="text-left">Cell Phone Number</label>
            <v-text-field
              id="trip-received-cell-phone-number-text-field"
              ref="cell-phone"
              v-model="phone"
              v-mask="['(###) ###-####', '+## ## #### ####']"
              required
              hide-details
              outlined
              :rules="[
                (v) => !!v || 'Phone number is required',
                phoneRaw.length > 9 || 'Phone number must be valid',
                phoneRaw.length <= 12 || 'Phone number must be valid',
              ]"
              placeholder="(888) 888-8888"
              @keydown.enter.prevent="submit"
            />

            <span v-if="showConfirmCheckbox">
              <v-checkbox
                v-model="confirmCheck"
                label="Receive important trip updates such as driver info, ETAs, and more via text message."
                hide-details
              />
              <v-expand-transition>
                <p v-if="confirmCheck" class="text-gray-light font-12 margin-t-2">
                  By confirming, you agree to CharterUP’s
                  <a
                    href="https://www.charterup.com/terms-and-conditions/"
                    target="_blank"
                  >
                    Terms of Service
                  </a>
                  and
                  <a
                    href="https://www.charterup.com/privacy-policy/"
                    target="_blank"
                    class="mr-n1"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
              </v-expand-transition>
            </span>
            <p v-else class="text-center font-12">Standard message and data rates may apply.</p>

            <v-btn
              id="reservation-detail-confirm-phone-number"
              color="primary"
              class="w-full margin-t-6"
              :disabled="!phone"
              :loading="submitting"
              @click="next"
            >
              {{ confirmCheck ? 'Get Confirmation Code' : 'Confirm' }}
            </v-btn>
          </v-form>
        </div>
      </div>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Customer } from '@/models/dto'
import customer from '@/services/customer'
import auth from '@/services/auth'

@Component({})
export default class ReservationDetailConfirmPhone extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: Boolean, required: false }) readonly closeable: boolean
  @Prop({ type: Object, required: false }) readonly customer: Customer
  @Prop({ type: Boolean, required: false }) readonly anonymous: boolean
  @Prop({ type: Boolean, required: false, default: true }) readonly showConfirmCheckbox: boolean
  @Prop({ type: String, required: true }) readonly header: string
  @Prop({ type: String, required: true }) readonly subheader: string

  @Watch('customer', { immediate: true, deep: true })
  customerPhoneChanged(value: Customer): void {
    this.phone = value?.phone || ''
  }

  phone = ''
  confirmCheck = true
  submitting = false

  get phoneRaw(): string {
    return this.phone?.replace(/\D/g, '')
  }

  handleSkip(): void {
    this.$emit('skip')
  }

  next(): void {
    if (!this.confirmCheck) {
      this.$emit('skip')
    } else {
      this.submit()
    }
  }

  async submit(): Promise<void> {
    const form = this.$refs.tripReceivedForm as any
    if (!form.validate()) {
      return
    }

    this.submitting = true
    try {
      if (this.anonymous) {
        await auth.requestSmsOtp({
          phoneNumber: this.phoneRaw,
        })
      } else {
        await customer.updateSMS(this.customer?.customerId, {
          phoneNumber: this.phoneRaw,
        })
      }
    } catch (err) {
      console.error(err)
    }
    this.submitting = false
    this.$emit('update-phone')
    this.$emit('confirm', this.phoneRaw)
  }
}
</script>
