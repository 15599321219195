<template>
  <div>
    <v-tooltip v-if="tooltip" bottom>
      <template #activator="{ on, attrs }">
        <CUIcon
          v-bind="attrs"
          color="gray-medium-dark"
          :class="iconClasses"
          view-box="0 0 24 24"
          v-on="on"
        >
          {{ iconName }}
        </CUIcon>
      </template>
      <span class="text-white">{{ label }}</span>
    </v-tooltip>
    <CUIcon v-else color="primary" :class="iconClasses" view-box="0 0 24 24">
      {{ iconName }}
    </CUIcon>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { convertAmenityKeyToIconName } from '@/utils/string'
import { AmenityType } from '../models/dto'

@Component({})
export default class AmenityIcon extends Vue {
  @Prop({ type: Object, required: true }) readonly amenityType: AmenityType
  @Prop({ type: String, default: '' }) readonly iconClasses: string
  @Prop({ type: Boolean, default: true }) readonly tooltip: boolean

  get iconName(): string {
    return convertAmenityKeyToIconName(this.amenityType?.key)
  }

  get label(): string {
    return this.amenityType?.label
  }
}
</script>
