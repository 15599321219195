<template>
  <div :style="{ 'max-width': '1137px' }">
    <v-row>
      <v-col cols="3">
        <SkeletonBox
          type="header"
          class="margin-b-4 w-160"
          :style="{ 'margin-top': '10px', height: '39px' }"
        />
      </v-col>
      <v-spacer grow />
      <v-col class="shrink padding-r-0 margin-l-1">
        <SkeletonBox
          type="header"
          class="margin-b-4 margin-r-4"
          :style="{ 'margin-top': '10px', height: '39px', width: '133px' }"
        />
      </v-col>
      <v-col class="shrink padding-l-0">
        <SkeletonBox
          type="header"
          class="margin-b-4 w-140"
          :style="{ 'margin-top': '10px', height: '39px' }"
        />
      </v-col>
    </v-row>

    <v-simple-table>
      <template #default>
        <thead
          class="gray-box background-border-gray border-radius-regular position-relative overflow-hidden"
          :style="{ height: '67px' }"
        >
          <tr :style="{ height: '67px' }">
            <th :style="{ width: '67px' }" />
            <th :style="{ width: '115px' }" />
            <th :style="{ width: '115px' }" />
            <th :style="{ width: '222px' }" />
            <th
              v-for="skeletonTableviewRowHeader in 5"
              :key="skeletonTableviewRowHeader"
            >
              <!-- {{ skeletonTableviewRowHeader.text }} -->
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="skeletonTableviewEntryIndex in 9"
            :key="skeletonTableviewEntryIndex"
            :style="{ height: '67px' }"
          >
            <td
              v-for="skeletonTableviewRowIndex in 9"
              :key="skeletonTableviewRowIndex"
            >
              <SkeletonBox type="header" class="margin-y-1 h-24 opacity-30" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({
  components: {
    SkeletonBox,
  },
})
export default class SkeletonResTableView extends Vue {}
</script>

<style lang="scss">
@import '~@/scss/colors';
// With some help from:
// https://markus.oberlehner.net/blog/skeleton-loading-animation-with-vue/
.gray-box {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
