<template>
  <router-link
    v-if="item.link"
    :id="`toolbar-button-${toKebab(item.title)}`"
    :key="`item-link-${item.name}-${index}`"
    :to="{ name: item.link }"
    active-class="active"
    class="app-bar-link padding-y-0 padding-x-6"
  >
    <!-- Double spans allow for precise underlining -->
    <span>
      <v-badge
        v-if="item.name === 'quotes' && quotes.unreadQuoteCount"
        color="error"
        :content="quotes.unreadQuoteCount"
        offset-x="-5"
        offset-y="16"
      >
        <span>{{ item.title }}</span>
      </v-badge>

      <span v-else>{{ item.title }}</span>
    </span>
  </router-link>
  <div
    v-else-if="item.action"
    :id="`toolbar-button-${toKebab(item.title)}`"
    :key="`item-link-${item.name}-${index}`"
    class="nav-action app-bar-link cursor-pointer padding-y-0 padding-x-6"
    @click="item.action"
  >
    <span>
      <span>{{ item.title }}</span>
    </span>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { NavigationItem } from '@/models/NavigationItem'
import quotes from '@/store/modules/quotes'
import { toKebab } from '@/utils/string'

@Component({})
export default class AppBarNavigationItem extends Vue {
  @Prop({ type: Object, required: true }) readonly item: NavigationItem
  @Prop({ type: Number, required: true }) readonly index: number

  quotes = quotes
  toKebab = toKebab
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';

.app-toolbar {
  a,
  .nav-action {
    height: 68px;
    font-size: 0.875rem;
    &.app-bar-link {
      color: $gray;
      > span {
        display: flex;
        align-items: center;
        border-bottom: 2px solid transparent;
        height: 100%;
      }
      &:hover,
      &.active {
        color: $gray !important;
        > span {
          border-bottom: 2px solid $gray;
        }
      }
    }
  }
}
</style>
