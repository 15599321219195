<template>
  <div class="w-full max-w-600">
    <h3 class="margin-b-3 subtitle-1">{{ title }}</h3>
    <div class="d-flex justify-space-between">
      <div
        v-for="(specialTripRequirement,
        specialTripRequirementsIndex) in specialTripRequirements"
        :key="`checkbox-${specialTripRequirement.key}-${specialTripRequirementsIndex}`"
      >
        <v-checkbox
          :input-value="currentTrip[specialTripRequirement.key]"
          :label="specialTripRequirement.label"
          hide-details
          class="margin-t-0 padding-t-0 text-gray-text"
          color="primary"
          @change="handleSelect($event, specialTripRequirement.key)"
        />
        <p class="margin-l-9 margin-t-n1 font-14 text-gray-subtle-text">
          {{ specialTripRequirement.description }}
        </p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import selfServe from '@/store/modules/selfServe'
import {
  SpecialRequirementsTypeLabel,
  SpecialRequirementsTypeKey,
  TripEventTypeId,
} from '@/utils/enum'

import { Vue, Component, Watch } from 'vue-property-decorator'
import { SalesBotTrip } from '@/models/dto'
import { pluralize } from '@/utils/string'
@Component({})
export default class SelfServeSpecialTripRequirementsSelection extends Vue {
  selfServe = selfServe

  @Watch('isK12EventType', { immediate: true })
  updateSPAB(): void {
    const value = this.isK12EventType && this.isFirstStopInCA
    this.handleSelect(value, SpecialRequirementsTypeKey.SPAB)
  }

  get specialTripRequirements(): any[] {
    return [
      {
        label: SpecialRequirementsTypeLabel.ADA,
        key: SpecialRequirementsTypeKey.ADA,
        description: 'Americans with Disabilities Act',
        show: true,
      },
      {
        label: SpecialRequirementsTypeLabel.SPAB,
        key: SpecialRequirementsTypeKey.SPAB,
        description: 'School-Pupil Activity Bus',
        show: this.showSPABCheckbox,
      },
    ].filter((req) => !!req.show)
  }

  get currentTrip(): SalesBotTrip {
    return selfServe.quote?.trips?.[selfServe.currentTripIndex] || null
  }

  get isK12EventType(): boolean {
    return this.currentTrip.tripEventTypeId === TripEventTypeId.K12
  }

  get isFirstStopInCA(): boolean {
    const firstStopState = this.currentTrip?.stops?.[0]?.address?.state
    return firstStopState === 'CA'
  }

  get showSPABCheckbox(): boolean {
    return this.isK12EventType && this.isFirstStopInCA
  }

  get title(): string {
    return pluralize(this.specialTripRequirements.length, 'Special Requirement')
  }

  handleSelect(value, key) {
    if (key === SpecialRequirementsTypeKey.SPAB) {
      selfServe.setTripIsSPAB(value)
    } else if (key === SpecialRequirementsTypeKey.ADA) {
      selfServe.setTripIsADA(value)
    }
  }
}
</script>
