<template>
  <div
    v-if="providerDetail"
    v-show="!$vuetify.breakpoint.lgAndUp"
    ref="mobileTripFooter"
    :style="{ transition: 'top 0.2s ease-in-out' }"
    class="position-sticky bottom-0 left-0 z-10 background-white border-t-1 border-x-0 border-b-0 border-solid border-border-gray w-full min-h-80 d-flex align-center padding-y-2"
  >
    <v-row align="center" justify="center" dense class="margin-t-2 margin-x-6">
      <v-row align="center" justify="end">
        <v-col class="padding-r-0">
          <h4>${{ providerDetail.totalAmount }}</h4>
          <v-row dense justify="start" align="center">
            <v-col cols="auto">
              <CharterUPRating
                :style="{ 'margin-top': '2px' }"
                :rating="providerDetail.company.rating"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-row>
            <CUIcon
              class="cursor-default margin-t-1"
              view-box="0 0 24 24"
              :class="{
                'margin-r-2': $vuetify.breakpoint.xs,
                'margin-l-n3 margin-r-2': $vuetify.breakpoint.smAndUp,
              }"
              :width="$vuetify.breakpoint.xs ? '30px' : '35px'"
              :height="$vuetify.breakpoint.xs ? '30px' : '35px'"
            >
              verified_user
            </CUIcon>
            <div>
              <h5
                :class="{
                  'font-12': $vuetify.breakpoint.xs,
                  'font-16': $vuetify.breakpoint.smAndUp,
                }"
              >
                {{ $t('bookingGuarantee.TITLE') }}
              </h5>
              <BookingProtectionDialog />
            </div>
          </v-row>
        </v-col>
      </v-row>
      <v-col md="3">
        <div class="d-flex flex-column w-full margin-t-2">
          <ShareQuoteDialog
            v-if="showShareQuoteDialog"
            :quote-id="providerDetail.quote.quoteId"
            button-class="margin-t-2"
          />
          <v-btn
            id="provider-button-checkout-mobile"
            block
            small
            color="primary"
            class="margin-y-2"
            :to="{
              name: 'checkout',
              params: {
                quoteId: $route.params.quoteId,
                providerId: $route.params.providerId,
              },
            }"
          >
            {{ $t('quoteDetail.billingSummary.CONTINUE_TO_BOOKING') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import ShareQuoteDialog from '@/components/ShareQuoteDialog.vue'
import BookingProtectionDialog from '@/components/BookingProtectionDialog.vue'
import { TranslateResult } from 'vue-i18n'
import CharterUPRating from '@/components/CharterUPRating.vue'

@Component({ components: { ShareQuoteDialog, BookingProtectionDialog, CharterUPRating } })
export default class ProviderMobileFooter extends Vue {
  @Prop({ type: Object, default: null }) readonly providerDetail: any
  @Prop({ type: Boolean }) readonly showShareQuoteDialog: boolean
  @Prop({ type: Boolean, default: false })
  readonly isElite: boolean
  @Prop({ type: Boolean }) readonly isSelfServe: boolean
}
</script>
