<template>
  <v-list
    nav
    dense
    class="side-menu cursor-pointer"
    :class="$vuetify.breakpoint.smAndDown ? 'mobile' : ''"
  >
    <v-list-item
      v-for="(item, index) in links"
      :id="`side-menu-navigation-${item.name}`"
      :key="`side-menu-navigation-${item.name}-${index}`"
      :class="{'selected': routeName === item.name }"
      @click="handleNavigationClick(index)"
    >
      <v-list-item-icon v-if="anyItemHasIcon">
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content :class="{ 'padding-l-3': !anyItemHasIcon}">
        <v-list-item-title>
          {{ item.label || toTitle(item.name) }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { toTitle } from '@/utils/string'
import { NavigationLink } from '@/models/NavigationLink'

@Component({})
export default class MainWithSidePanelLayoutNavigation extends Vue {
  @Prop({ type: Array, required: true }) readonly links: NavigationLink[]

  toTitle = toTitle

  handleNavigationClick(index): void {
    const { name } = this.links[index]
    this.$router.push({ name })
  }

  get anyItemHasIcon(): boolean {
    return this.links.some((item) => item.icon)
  }

  get routeName(): string {
    return this.$route.name
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';

.v-list {
  &.side-menu {
    background: none;
    &.v-sheet {
      &.mobile {
        background: white;
      }
    }
    .v-list-item {
      .v-list-item__icon {
        margin-right: 16px;
      }
      .v-list-item__content {
        .v-list-item__title {
          font-size: 1rem;
          font-family: 'Inter Medium', Arial, sans-serif;
        }
      }
      &.selected,
      &:hover {
        background: rgba($black, 0.05);
        .v-list-item__icon {
          .v-icon {
            color: $primary !important;
          }
        }
        .v-list-item__content {
          .v-list-item__title {
            color: $primary;
          }
        }
      }
    }
  }
}
</style>
