<template>
  <v-card flat class="padding-y-3 border-none border-0">
    <v-card-text>
      <div v-if="map">
        <SkeletonBox :style="{ height: '150px' }" />
        <br />
      </div>
      <SkeletonBox type="text" />
      <br />
      <SkeletonBox class="h-84" />
      <br />
      <SkeletonBox class="h-60" />
      <br />
      <SkeletonBox type="text" />
      <br />
      <SkeletonBox class="h-60" />
      <br />
      <SkeletonBox type="text" />
      <br />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({ components: { SkeletonBox } })
export default class SkeletonTripCollectionCard extends Vue {
  @Prop({ type: Boolean }) readonly map: boolean
}
</script>
