<template>
  <div id="sales-bot-map-container" class="w-full" :style="containerStyles">
    <div
      class="position-absolute z-2"
      :style="overlayStyles"
    >
      <slot name="trip-summary-overlay" />
    </div>
    <GmapMap
      id="sales-bot-map"
      ref="map"
      :center="center"
      :zoom="zoom"
      :options="options"
      class="h-full w-full"
      :class="{'map-fade': !noFade}"
    >
      <SalesBotMapMarkers :waypoints="waypoints" />
      <SalesBotMapRouteLine :path="path" />
    </GmapMap>
  </div>
</template>


<script lang="ts" setup>
import { ref, computed, watch } from 'vue'
import mapStyles from '@/utils/salesBotMapStyles'
import { useWindowResize } from '@/composables/useWindowResize'
import { useGoogleDirections } from '@/composables/useGoogleDirections'
import SalesBotMapRouteLine from '@/components/SalesBotMapRouteLine.vue'
import SalesBotMapMarkers from '@/components/SalesBotMapMarkers.vue'
import { LatLng } from '@/utils/google'
import { useDisplay } from '@/composables/useDisplay'
import { SALES_BOT_TRIP_SUMMARY_OVERLAY_GAP_PIXELS } from '@/utils/constants'

const TRIP_SUMMARY_OVERLAY_WIDTH_PIXELS = 376
const DEFAULT_ZOOM = 5
const MAX_ZOOM = 19
const DEFAULT_POSITION = { lat: 38.9552458, lng: -94.6900738 }
const SIDEBAR_OVERLAP_PIXELS = 100

const options = {
  zoomControl: false,
  maxZoom: MAX_ZOOM,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  disableDefaultUI: true,
  styles: [...mapStyles],
}

const props = withDefaults(
  defineProps<{
    noFade?: boolean,
    maxHeight?: string,
    height?: string,
    borderRadius?: string,
    waypoints?: LatLng[],
    manualRefreshToggle?: boolean
  }>(),
  {
    noFade: false,
    maxHeight: '100%',
    height: '100%',
    borderRadius: '0px',
    waypoints: [],
    manualRefreshToggle: false
  }
)


const { xs, smOnly } = useDisplay()
const map = ref(null)
const center = ref(DEFAULT_POSITION)
const zoom = ref(DEFAULT_ZOOM)

const xsOnly = computed(() => xs.value)

/**
 * Computed property that returns the styles for the overlay element.
 *
 * @returns {Object} An object containing the CSS styles for the overlay element.
 * @property {string} left - The left position of the overlay, calculated using the SALES_BOT_TRIP_SUMMARY_OVERLAY_GAP_PIXELS constant and SIDEBAR_OVERLAP_PIXELS.
 * @property {string} top - The top position of the overlay, calculated using the SALES_BOT_TRIP_SUMMARY_OVERLAY_GAP_PIXELS constant.
 * @property {string} width - The width of the overlay, calculated using the TRIP_SUMMARY_OVERLAY_WIDTH_PIXELS constant.
 */
const overlayStyles = computed(() => {
  if (xsOnly.value || smOnly.value) {
    return { left: '0', top: '0', width: '100%' }
  }
  return {
    left: `${SALES_BOT_TRIP_SUMMARY_OVERLAY_GAP_PIXELS + SIDEBAR_OVERLAP_PIXELS / 4}px`,
    top: `${SALES_BOT_TRIP_SUMMARY_OVERLAY_GAP_PIXELS}px`,
    bottom: `${SALES_BOT_TRIP_SUMMARY_OVERLAY_GAP_PIXELS}px`,
    width: `${TRIP_SUMMARY_OVERLAY_WIDTH_PIXELS}px`,
  }
})
/**
 * Computes the padding for the map bounds to prevent conflict with the trip summary overlay.
 *
 * The padding is calculated based on the overlap of the sidebar and the
 * dimensions of the trip summary overlay, including a gap on both sides.
 */
const boundsPadding = computed(() => {
  const hasOverlay = !xsOnly.value && !smOnly.value
  const left = hasOverlay ? SIDEBAR_OVERLAP_PIXELS + TRIP_SUMMARY_OVERLAY_WIDTH_PIXELS + SALES_BOT_TRIP_SUMMARY_OVERLAY_GAP_PIXELS * 2 : 0
  return { left, right: 0, bottom: 0, top: 0 }
})

const containerStyles = computed(() => {
  const styles = {
    maxHeight: props.maxHeight,
    height: props.height,
    '--sales-bot-map-border-radius': props.borderRadius
  }
  if (xsOnly.value || props.noFade) {
    return styles
  }
  styles.marginLeft = `-${SIDEBAR_OVERLAP_PIXELS}px`
  styles.position = 'relative'
  return styles
})

const waypoints = computed(() => props.waypoints)

const { fetchDirections, getBounds, path } = useGoogleDirections(waypoints)

/**
 * Adjusts the map view to fit the bounds of the stops and route line.
 *
 * This function retrieves the current bounds using the `getBounds` function.
 * If the bounds are defined and the `mapInstance` is available, it adjusts
 * the map view to fit within the bounds with the specified padding.
 */
const fitToBounds = () => {
  const bounds = getBounds()
  if (bounds && map.value?.$mapObject) {
    map.value?.$mapObject.fitBounds(bounds, boundsPadding.value)
  }
}

useWindowResize(fitToBounds) // Use composable to handle window resize events

/**
 * Watches for changes in the `waypoints` prop and `fetchDirections`
 * and `fitToBounds` when a change is detected.
 */
watch(() => props.waypoints, async () => {
  await fetchDirections()
  // only fit on waypoints change if there are less than 2 waypoints
  if (waypoints.value.length < 2) {
    fitToBounds()
  }
})

watch(() => path.value, () => {
  // only fit on path change if there are two or more 2 waypoints
  if (waypoints.value.length > 1) {
    fitToBounds()
  }
}, { immediate: true })

watch(() => props.manualRefreshToggle, () => {
  if (waypoints.value.length > 1) {
    fitToBounds()
  }
}, { immediate: true })

</script>

<style lang="scss" scoped>
.map-fade {
  -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 1) 200px);
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 1) 200px);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

::v-deep .vue-map {
  border-radius: var(--sales-bot-map-border-radius);
}
</style>
