var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-footer',{staticClass:"background-blue-dark",class:{
    'padding-x-18': _vm.$vuetify.breakpoint.mdAndUp,
    'padding-x-5': _vm.$vuetify.breakpoint.smAndDown,
    'padding-y-2': _vm.$vuetify.breakpoint.xs,
    'padding-y-3': _vm.$vuetify.breakpoint.sm,
    'padding-y-20': _vm.$vuetify.breakpoint.mdAndUp && _vm.padded,
    'padding-y-4': _vm.$vuetify.breakpoint.mdAndUp && !_vm.padded,
  },attrs:{"id":"sales-bot-footer"}},[(_vm.$vuetify.breakpoint.smAndDown)?[_c('div',{staticClass:"w-full d-flex align-center justify-space-between min-h-33"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('p',{staticClass:"font-bold text-white margin-t-0 margin-r-1"},[_vm._v(" The CharterUP Booking Advantage: ")]),_c('p',{staticClass:"text-white margin-t-0"},[_vm._v("Why do customers choose CharterUP?")])]),_c('CUIcon',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],staticClass:"footer-expansion-arrow w-40 h-40 flex-shrink-0",class:_setup.isExpanded ? 'flipped' : '',attrs:{"color":"blue","view-box":"0 0 24 24"},on:{"click":function($event){_setup.isExpanded = !_setup.isExpanded}}},[_vm._v(" keyboard_arrow_down ")])],1),_c('v-expand-transition',[(_setup.isExpanded)?_c('div',{staticClass:"padding-y-6"},_vm._l((_setup.valuePropKeys),function(valuePropKey){return _c(_setup.SalesBotFooterValueProp,{key:valuePropKey,attrs:{"value-prop-key":valuePropKey}})}),1):_vm._e()])]:[_c('div',{staticClass:"d-flex flex-wrap justify-center align-center margin-x-auto",style:({ gap: '40px' })},[_c('div',{staticClass:"flex-shrink-0"},[_c('p',{staticClass:"font-22 font-bold text-white margin-t-0"},[_vm._v(" The CharterUP Booking Advantage ")]),_c('p',{staticClass:"text-white"},[_vm._v("Why do customers choose CharterUP?")])]),_c('div',{staticClass:"d-flex",style:({ flex: 1 })},_vm._l((_setup.valuePropKeys),function(valuePropKey,index){return _c('div',{key:valuePropKey,staticClass:"d-flex"},[(index !== 0)?_c('div',{staticClass:"w-1 background-cup-500 opacity-20 margin-x-1"}):_vm._e(),_c(_setup.CharterUPValueProp,{attrs:{"page":_setup.page,"value-prop-key":valuePropKey}})],1)}),0)])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }