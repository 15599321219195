<template>
  <div v-if="photos && photos.length !== 0">
    <v-row justify="space-between" class="position-relative" no-gutters>
      <v-col
        v-if="photo1"
        :id="`provider-image-${vehicleType}-preview-1`"
        :style="$vuetify.breakpoint.smAndUp ? 'padding-right:6px;' : ''"
        cols="12"
        sm="8"
        @click="openModal(photo1)"
      >
        <div
          class="preview label-box overflow-hidden margin-y-3 margin-x-0 cursor-pointer position-relative"
          :class="!photo2 ? 'show-all-photos-box' : ''"
        >
          <p
            class="position-absolute top-0 font-14 padding-y-1 padding-x-3 text-white z-5"
          >
            {{ photo1.label }}
          </p>
          <v-img
            class="hover:transform-102 transition-all transition-duration-300"
            height="100%"
            aspect-ratio="1.6"
            :src="photo1.imagePath"
          />
          <p
            v-if="!photo2"
            class="position-absolute bottom-0 right-0 text-right font-16 font-medium text-decoration-underline text-white padding-y-1 padding-x-3 z-5"
            @click="openModal(photo1)"
          >
            {{ $t('quoteDetail.operatorInfo.SHOW_ALL_PHOTOS') }}
          </p>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        :style="$vuetify.breakpoint.smAndUp ? 'padding-left:6px;' : ''"
      >
        <div
          v-if="photo2"
          class="preview overflow-hidden margin-y-3 margin-x-0 cursor-pointer position-relative"
          :class="!photo3 ? 'show-all-photos-box' : ''"
          @click="openModal(photo2)"
        >
          <v-img
            :id="`provider-image-${vehicleType}-preview-2`"
            class="hover:transform-102 transition-all transition-duration-300"
            aspect-ratio="1.65"
            height="100%"
            :src="photo2.imagePath"
          />
          <p
            v-if="!photo3"
            class="position-absolute bottom-0 right-0 text-right font-16 font-medium text-decoration-underline text-white padding-y-1 padding-x-3 z-5"
            @click.stop="openModal(photo1)"
          >
            {{ $t('quoteDetail.operatorInfo.SHOW_ALL_PHOTOS') }}
          </p>
        </div>
        <div
          v-if="photo3"
          class="preview show-all-photos-box overflow-hidden margin-y-3 margin-x-0 cursor-pointer position-relative"
          @click="openModal(photo3)"
        >
          <v-img
            :id="`provider-image-${vehicleType}-preview-3`"
            class="hover:transform-102 transition-all transition-duration-300"
            aspect-ratio="1.65"
            height="100%"
            :src="photo3.imagePath"
          />
          <p
            class="position-absolute bottom-0 right-0 text-right font-16 font-medium text-decoration-underline text-white padding-y-1 padding-x-3 z-5"
            @click.stop="openModal(photo1)"
          >
            {{ $t('quoteDetail.operatorInfo.SHOW_ALL_PHOTOS') }}
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { VehiclePhoto } from '@/models/dto'

@Component({})
export default class ProviderInfoVehicleTypePhotos extends Vue {
  @Prop({ type: Array, required: true })
  readonly photos: VehiclePhoto[]

  get vehicleType(): string {
    return this.photos?.[0]?.vehicleTypeDTO?.label
  }

  get photo1(): VehiclePhoto {
    return this.photos?.[0]
  }

  get photo2(): VehiclePhoto {
    return this.photos?.[1]
  }

  get photo3(): VehiclePhoto {
    return this.photos?.[2]
  }

  openModal(image): void {
    this.$emit('open-modal', image)
  }
}
</script>

<style lang="scss" scoped>
.preview {
  &.label-box {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0) 35%
      );
      opacity: 0.75;
    }
    &.show-all-photos-box {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 0) 35%,
          rgba(0, 0, 0, 0) 65%,
          rgba(0, 0, 0, 1) 100%
        );
        opacity: 0.75;
      }
    }
  }

  &.show-all-photos-box {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0) 35%
      );
      opacity: 0.75;
    }
  }
}
</style>
