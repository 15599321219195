<template>
  <div class="max-w-352 min-w-352">
    <h2 v-if="showTitles" class="margin-b-3 font-medium text-primary">
      Payment Update
    </h2>
    <template>
      <b v-if="showTitles">Your request has a price change.</b>
      <v-card class="background-white margin-t-4">
        <v-card-text class="padding-y-4">
          <template>
            <div class="d-flex justify-space-between">
              <div>Original total</div>
              <div>{{ currencyFilter(paymentUpdate?.originalTotal) }}</div>
            </div>
            <div
              v-if="priceDifferencePositive"
              class="d-flex justify-space-between margin-t-8"
            >
              <div class="font-weight-bold">Price Increase</div>
              <div
                class="font-weight-bold background-modified-trip border-radius-regular padding-x-1 padding-y-1"
              >
                + {{ currencyFilter(paymentUpdate.priceDifference) }}
              </div>
            </div>
            <div
              v-else-if="priceDifferenceNegative"
              class="d-flex justify-space-between margin-t-8"
            >
              <div class="font-weight-bold">Price Decrease</div>
              <div
                class="font-weight-bold background-modified-trip border-radius-regular padding-x-1 padding-y-1"
              >
                {{ currencyFilter(paymentUpdate.priceDifference) }}
              </div>
            </div>
            <div class="d-flex justify-space-between margin-t-6">
              <div class="font-weight-bold">Updated total</div>
              <div class="font-weight-bold">
                {{ currencyFilter(paymentUpdate?.updatedTotal) }}
              </div>
            </div>
            <div class="font-weight-bold margin-b-4 payment-update-subtext">
              (USD)
            </div>
            <div v-if="refundPositive">
              <div class="d-flex justify-space-between margin-t-6">
                <div class="font-weight-bold">Refund total</div>
                <div class="font-weight-bold">
                  {{ currencyFilter(paymentUpdate.refund) }}
                </div>
              </div>
              <div class="font-weight-bold margin-b-4 payment-update-subtext">
                (USD)
              </div>
            </div>
            <v-divider />
            <div class="d-flex justify-space-between margin-t-6">
              <div>Balance paid</div>
              <div>{{ currencyFilter(paymentUpdate?.balancePaid) }}</div>
            </div>
            <div class="d-flex justify-space-between margin-t-6">
              <div class="font-weight-bold">Remaining balance</div>
              <div class="font-weight-bold">
                {{ currencyFilter(paymentUpdate?.remainingBalance) }}
              </div>
            </div>
            <div v-if="hasRemainingBalanceDueDate" class="font-weight-bold payment-update-subtext">
              Due on {{ formattedDueDate }}
            </div>
          </template>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script lang="ts">
import { Vue, Component, InjectReactive, Prop } from "vue-property-decorator";
import tripModificationStore from "@/store/modules/tripModification";
import { TripModificationPaymentUpdate } from "@/models/dto";
import { currencyFilter } from '@/utils/string'
import { DateTime } from "luxon";

@Component({})
export default class TripModificationPayment extends Vue {
  @InjectReactive("removePadding") removePadding;
  @Prop({ type: Boolean, default: true }) readonly showTitles: boolean

  currencyFilter = currencyFilter

  get paymentUpdate(): TripModificationPaymentUpdate {
    return tripModificationStore.paymentUpdate;
  }

  get priceDifferencePositive(): boolean {
    return this.paymentUpdate?.priceDifference > 0;
  }

  get priceDifferenceNegative(): boolean {
    return this.paymentUpdate?.priceDifference < 0;
  }

  get refundPositive(): boolean {
    return this.paymentUpdate?.refund > 0;
  }

  get formattedDueDate(): string {
    return this.formatDueDate(this.paymentUpdate?.remainingBalanceDueDate)
  }

  get hasRemainingBalanceDueDate(): boolean {
    return !!this.paymentUpdate?.remainingBalanceDueDate
  }

  formatDueDate(timestamp: string): string {
    return DateTime.fromISO(timestamp).toFormat("MMM d, yyyy");
  }
}
</script>
<style lang="scss" scoped>
.payment-update-subtext {
  font-size: small;
}
</style>
