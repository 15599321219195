<template>
  <div class="w-full">
    <h1>Cancellation Form</h1>
    <p>
      We have received your request to cancel your trip, reservation
      {{ managedId }}. To finalize the cancellation, please fill out the
      following form. Please note,
      <span class="font-medium">
        failure to response to this form in a timely manner could result in the
        reservation not being cancelled and incurring additional fees, up to
        100% of the reservation value.
      </span>
    </p>
    <v-form ref="cancellationForm">
      <div
        class="background-header-gray border-2 border-solid border-border-gray border-radius-regular padding-y-8 padding-x-6 margin-y-4"
      >
        <v-row class="max-w-500 margin-x-auto">
          <v-col cols="12">
            <label>Requester Name</label>
            <v-text-field
              id="cancellation-form-text-requester-name"
              ref="requesterName"
              v-model="requesterName"
              :rules="[(v) => !!v || 'Requester name is required']"
              placeholder="First and Last"
              required
              outlined
              hide-details="auto"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <label>Phone</label>
            <v-text-field
              id="cancellation-form-text-phone"
              ref="phone"
              v-model="phone"
              v-mask="['(###) ###-####', '+## ## #### ####']"
              :rules="[
                (v) => !!v || 'Phone number is required',
                phoneRaw.length > 9 || 'Phone number must be valid',
                phoneRaw.length <= 12 || 'Phone number must be valid',
              ]"
              placeholder="ex. (888) 888-8888"
              required
              outlined
              hide-details="auto"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <label>Email</label>
            <v-text-field
              id="cancellation-form-text-email"
              ref="email"
              v-model="email"
              :rules="[
                (v) => !!v || 'Email is required',
                (v) =>
                  /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,13}$/.test(v) ||
                  'E-mail must be valid',
              ]"
              placeholder="Enter email"
              required
              outlined
              hide-details="auto"
            />
          </v-col>
          <v-col cols="12">
            <label>Payment Method Used</label>
            <v-select
              v-model="paymentMethod"
              :items="paymentMethods"
              :rules="[(v) => !!v || 'Payment method is required']"
              placeholder="Select one"
              append-icon="keyboard_arrow_down"
              required
              outlined
            />
          </v-col>
        </v-row>
      </div>
      <div class="d-flex flex-column">
        <p>
          Are you the person who booked the reservation and agreed to the terms
          on
          {{ termsAgreementDate }}?
        </p>
        <v-radio-group
          v-model="didRequesterAgreeToTerms"
          row
          class="margin-l-3 margin-t-3"
          :rules="[(v) => v !== null || 'This question is required']"
        >
          <v-radio label="Yes" :value="true"></v-radio>
          <v-radio label="No" :value="false"></v-radio>
        </v-radio-group>
        <template v-if="didRequesterAgreeToTerms === false">
          <p>
            Have you been authorized by the person who booked the reservation to
            cancel?
          </p>
          <v-radio-group
            v-model="isUserAuthorizedToCancel"
            row
            class="margin-l-3 margin-t-3"
            :rules="[(v) => v !== null || 'This question is required']"
          >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
        </template>
        <p class="margin-b-4">
          Please note that this cancellation is subject to the agreed upon
          <a
            href="https://www.charterup.com/transportation-terms"
            target="_blank"
          >
            Terms and Conditions, Section 2a.
          </a>
        </p>
        <p>
          Please enter your initials to confirm cancellation and that the
          information above has been entered correctly.
        </p>
        <v-row class="margin-l-0 margin-t-0">
          <v-col cols="auto">
            <label>Your Initials</label>
            <v-text-field
              id="cancellation-form-text-requester-initials"
              ref="requesterInitials"
              v-model="requesterInitials"
              :rules="[(v) => !!v || 'Required']"
              placeholder="ex. AZ"
              required
              outlined
              hide-details="auto"
              class="max-w-80"
            />
          </v-col>
          <v-col cols="auto">
            <label>Date</label>
            <div class="d-flex align-center" :style="{ height: '50px' }">
              {{ currentDate }}
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="d-flex margin-t-8">
        <v-spacer />
        <v-btn
          color="primary"
          :loading="submitting"
          :disabled="disabled"
          @click="submit"
        >
          Submit
        </v-btn>
        <v-spacer />
      </div>
    </v-form>
  </div>
</template>
<script lang="ts">
import { CancellationConfirmation, SimpleReservation } from '@/models/dto'
import { phoneRaw } from '@/utils/phone'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { PaymentMethodTypeLabel } from '@/utils/enum'

@Component({})
export default class CancellationForm extends Vue {
  @Prop({ type: Object, required: true }) reservation!: SimpleReservation
  @Prop({ type: Boolean, required: false }) submitting!: boolean

  didRequesterAgreeToTerms = null
  email = ''
  isUserAuthorizedToCancel = null
  paymentMethod = ''
  phone = ''
  requesterInitials = ''
  requesterName = ''

  get disabled(): boolean {
    return !(
      this.email &&
      this.paymentMethod &&
      this.phone &&
      this.requesterInitials &&
      this.requesterName &&
      (this.didRequesterAgreeToTerms || this.isUserAuthorizedToCancel)
    )
  }

  get managedId(): string {
    return this.reservation?.managedId
  }

  get termsAgreementDate(): string {
    const date = this.$dayjs(this.reservation?.createdOn)
    if (!date) {
      return ''
    }
    return date.format('MM/DD/YYYY')
  }

  get currentDate(): string {
    return this.$dayjs().format('MM/DD/YYYY')
  }

  get currentTimestamp(): string {
    return this.$dayjs().format('MM/DD/YYYY hh:mm A z')
  }

  get phoneRaw(): string {
    return phoneRaw(this.phone)
  }

  get paymentMethods(): string[] {
    return Object.values(PaymentMethodTypeLabel)
  }

  buildPayload(): CancellationConfirmation {
    const reservationId = this.reservation?.reservationId
    const payload = {
      reservationId,
      requesterName: this.requesterName,
      requesterPhone: this.phone,
      requesterEmail: this.email,
      paymentMethod: this.paymentMethod,
      didRequesterAgreeToTerms: this.didRequesterAgreeToTerms,
      isUserAuthorizedToCancel: this.isUserAuthorizedToCancel,
      requesterInitials: this.requesterInitials,
      createdOn: null,
      cancellationConfirmationId: null,
    }
    return payload
  }

  async submit(): Promise<void> {
    const valid = (this.$refs.cancellationForm as any).validate()
    if (!valid) {
      return
    }
    const payload = this.buildPayload()
    this.$emit('submit', payload)
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-select {
  .v-input__control {
    .v-input__slot {
      .v-select__slot {
        .v-select__selections {
          padding: 0;
          .v-select__selection {
            padding-left: 14px !important;
          }
        }
        .v-input__append-inner {
          .v-input__icon--append {
            margin-top: 12px;
            margin-right: 3px;
          }
        }
      }
    }
  }

  * {
    touch-action: manipulation;
  }
}
</style>
