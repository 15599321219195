<template>
  <div class="d-flex flex-column omnibus">
    <div v-if="isDriverHotelRequired">
      <div class="d-flex align-top">
        <CUIcon
          color="midnight-gray-300"
          view-box="0 0 24 24"
          class="cursor-default margin-r-3 min-w-24 min-h-24 w-24 h-24"
        >
          bed
        </CUIcon>
        <p class="margin-t-0 text-midnight-gray-500">
          {{ $t('quoteDetail.billingSummary.DRIVER_HOTEL_POLICY') }}
        </p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CheckoutQuoteSummaryAttentionItems extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly isDriverHotelRequired!: boolean
}
</script>
