<template></template>

<script lang="ts">
import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import twilio from '@/plugins/twilio'
import { User } from '@/models/dto'
import auth from '@/store/modules/auth'
import chatStore from '@/store/modules/chat'
import { LiveChatConfig } from '@/models/Split'
import chat from '@/services/chat'
import logo_letter from '@/assets/images/logo_letter.svg'
import { load } from '@/utils/localStorage'
import { SplitFeatureFlag } from '@/utils/enum'

const DEV_ACCOUNT_SID = 'AC053a1294400d566de24ea1b8ae85c117'
const DEV_FLEX_FLOW_SID = 'FO0b4a14d4f007ebe4717c5238d02b90ae'
const PROD_ACCOUNT_SID = 'AC9d29141927e052177dfe757ed1af9aed'
const PROD_FLEX_FLOW_SID = 'FO8c4b98b66ee3c11bdb78d10bd1e03b31'

const CHAT_DELAY_MILLSECONDS = 10000

interface TwilioContext {
  source: string
  completeName: string
  friendlyName: string
  quoteId?: number
  emailAddress?: string
  userId?: number
  phoneNumber?: string
}

@Component({})
export default class Chat extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly delayed!: boolean
  @Prop({ type: Number, default: null })
  readonly quoteId: number | null

  auth = auth
  friendlyName: string = 'Unknown User'

  @Watch('completeName')
  async onUserChange(value) {
    if (!!value && this.conversationSid) {
      await this.updateChatAttributes()
    }
  }

  @Watch('quoteId')
  async onQuoteChange(value) {
    if (!!value && this.conversationSid) {
      await this.updateChatAttributes()
    }
  }

  get conversationSid(): string | null {
    return chatStore.conversationSid
  }

  get twilioFlexWebchat() {
    return twilio.client()
  }

  get accountSid(): string {
    const hostSubdomain = window.location.host.split(':')[0].split('.')[0]
    if (
      hostSubdomain === 'localhost' ||
      hostSubdomain === 'dev' ||
      hostSubdomain === 'auto'
    ) {
      return DEV_ACCOUNT_SID
    }
    return PROD_ACCOUNT_SID
  }

  get flexFlowSid(): string {
    const hostSubdomain = window.location.host.split(':')[0].split('.')[0]
    if (
      hostSubdomain === 'localhost' ||
      hostSubdomain === 'dev' ||
      hostSubdomain === 'auto'
    ) {
      return DEV_FLEX_FLOW_SID
    }
    return PROD_FLEX_FLOW_SID
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly
  }

  get appConfig() {
    return {
      accountSid: this.accountSid,
      flexFlowSid: this.flexFlowSid,
      attributes: {
        friendlyName: this.friendlyName,
      },
      startEngagementOnInit: this.startEngagementOnInit,
      context: this.twilioContext,
      preEngagementConfig: {
        description:
          'Before connecting you with an agent, we need a few details in case we get disconnected.',
        fields: [
          {
            label: 'Full name',
            type: 'InputItem',
            attributes: {
              name: 'friendlyName',
              type: 'text',
              required: true,
            },
          },
          {
            label: 'Email',
            type: 'InputItem',
            attributes: {
              name: 'emailAddress',
              type: 'email',
              required: true,
            },
          },
          {
            label: 'Phone number',
            type: 'InputItem',
            attributes: {
              name: 'phoneNumber',
              type: 'text',
              required: true,
            },
          },
        ],
        submitLabel: 'Chat with a Live Agent',
      },
      fileAttachment: {
        enabled: false,
      },
      colorTheme: {
        overrides: twilio.brandedColors,
      },
      componentProps: {
        MessagingCanvas: {
          avatarCallback: () =>
            logo_letter.replace('data:image/svg+xml;base64,', ''),
        },
      },
    }
  }

  get user(): User {
    return auth.user
  }

  get completeName(): string | null {
    if (!this.user) {
      return null
    }
    return `${this.user.firstName} ${this.user.lastName}`
  }

  get twilioContext() {
    let context: TwilioContext = {
      source: 'website',
      completeName: this.completeName || this.friendlyName,
      friendlyName: this.completeName || this.friendlyName,
      quoteId: this.quoteId,
    }

    if (this.user) {
      context.emailAddress = this.user.email
      context.userId = this.user.userId
      context.phoneNumber = this.user.phone
    }

    return context
  }

  // if that's an authenticated user we want the below to be true so that the pre-engagement form is skipped
  get startEngagementOnInit() {
    return this.user && this.user.userId
  }

  async mounted() {
    // If there's an existing conversation, only update if the user is logged in
    // This should only trigger on the checkout page
    if (this.conversationSid && this.quoteId) {
      await this.updateChatAttributes()
    }

    const customerFrame = document.getElementById('twilio-customer-frame')
    if (customerFrame) {
      customerFrame.style.display = 'unset'
      return
    }

    setTimeout(async () => {
      await this.renderWebChat()
    }, CHAT_DELAY_MILLSECONDS)
  }

  beforeDestroy() {
    const customerFrame = document.getElementById('twilio-customer-frame')
    if (customerFrame) {
      customerFrame.style.display = 'none'
    }
  }

  async renderWebChat(): Promise<void> {
    const { treatment, config } = await this.$split.getTreatmentWithConfig(
      SplitFeatureFlag.LiveChat
    )
    const parsedConfig: LiveChatConfig = JSON.parse(config)
    if (
      !this.isWithinBusinessHours(
        parsedConfig.minHour,
        parsedConfig.maxHour,
        parsedConfig.minDay,
        parsedConfig.maxDay
      )
    ) {
      return
    }

    try {
      this.twilioFlexWebchat.MainHeader.defaultProps.imageUrl = logo_letter
      this.twilioFlexWebchat.MainHeader.defaultProps.titleText = 'Chat with us'
      this.twilioFlexWebchat.MessagingCanvas.defaultProps.showReadStatus = false
      const webchat = await this.twilioFlexWebchat.createWebChat(this.appConfig)
      const { manager } = webchat
      manager.strings.PredefinedChatMessageAuthorName = 'CharterUp'
      manager.strings.EntryPointTagline = 'Live Chat'
      manager.strings.WelcomeMessage =
        'Welcome to CharterUp! Please let us know how we can help, and we will be with you shortly.'
      // @ts-ignore: no-empty-interface
      window.manager = manager

      this.twilioFlexWebchat.Actions.addListener(
        'afterSendMessage',
        (payload) => {
          const { channelSid } = manager.store.getState().flex.session
          if (channelSid) {
            chatStore.setConversationSid(channelSid)
          }
        }
      )

      webchat.init()
    } catch (error) {
      console.error(error)
    }
  }

  async updateChatAttributes() {
    if (!this.conversationSid) {
      return
    }

    try {
      await chat.updateByConversationSid(this.conversationSid, {
        userId: this.user?.userId || null,
        userFullName: this.completeName || this.friendlyName,
        quoteId: this.quoteId,
      })
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Returns whether the current time in EST
   * is within the provided hours of operation for Live Chat.
   *
   * @param minHour - The minimum hour that chat is available.
   * @param maxHour - The maximum hour that chat is available.
   * @param minDay - The minimum day that chat is available. (0 = Sunday, 6 = Saturday)
   * @param maxDay - The maximum day that chat is available. (0 = Sunday, 6 = Saturday)
   * @returns Whether or not the current time is within the provided hours of operation.
   *
   */ isWithinBusinessHours(
    minHour: number,
    maxHour: number,
    minDay: number,
    maxDay: number
  ): boolean {
    const date = this.$dayjs().tz('America/New_York')
    return (
      date.hour() >= minHour &&
      date.hour() <= maxHour &&
      date.day() >= minDay &&
      date.day() <= maxDay
    )
  }
}
</script>

<style lang="scss" scoped>
.Twilio-ChatItem-Avatar img {
  width: 24px;
}
.Twilio-MainHeader-default img {
  max-height: 24px;
  width: 24px;
}
.Twilio.Twilio-InlineMessage {
  margin-bottom: 40px;
}
.Twilio-EntryPoint-Tagline > .Twilio {
  font-weight: 600;
}
.Twilio-WelcomeMessage-IconContainer .Twilio-Icon.Twilio-Icon-MessageBold {
  color: #3c4853;
}
.Twilio-PreEngagementCanvas label,
input[type='text'],
input[type='email'],
.css-pdm40g {
  font-family: 'Inter Regular', Arial, sans-serif;
  font-size: 14px;
}
.Twilio-PreEngagementCanvas .css-8ixmbd {
  margin-bottom: 5px;
}
.Twilio-PreEngagementCanvas input[type='text'],
input[type='email'] {
  border-radius: 4px;
}
.Twilio-PreEngagementCanvas .css-1iuo08m {
  margin-top: 10px;
}
.Twilio-RootContainer .css-ixdgis {
  max-height: 435px;
}
.Twilio-DynamicForm button {
  border-radius: 4px;
}
</style>
