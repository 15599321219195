/* eslint-disable no-unused-vars */
export enum PartnerTypeId {
  GoldReseller = 1,
  SilverReseller = 2,
  BronzeReseller = 13,
  Platinum = 3,
  Gold = 4,
  Silver = 5,
  Bronze = 6,
  NonPartner = 7,
  CoachRailOnly = 8,
  Onboarding = 9,
  Suspended = 10,
}

export enum KeyCode {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Escape = 27,
  LeftArrow = 37,
  UpArrow = 38,
  RightArrow = 39,
  DownArrow = 40,
  AKey = 65,
  PKey = 80,
  Colon = 186,
}

export enum CompanyId {
  CharterUP = 2,
}

export enum CurrencyType {
  USA = 'USD',
  Canada = 'CAD',
}

export enum TripTypeId {
  OneWay = 1,
  RoundTrip = 2,
  Hourly = 3,
  Daily = 4,
  Shuttle = 5,
  LargeEvent = 6,
  LongTermShuttle = 7,
}

export enum TripTypeKey {
  OneWay = 'one_way',
  RoundTrip = 'round_trip',
  Hourly = 'hourly',
  Daily = 'daily',
  Shuttle = 'shuttle',
  LargeEvent = 'large_event',
  LongTermShuttle = 'long_term_shuttle',
}

export enum TripTypeLabel {
  OneWay = 'One Way',
  RoundTrip = 'Round Trip',
  Hourly = 'Hourly',
  Daily = 'Daily',
  Shuttle = 'Shuttle',
  LargeEvent = 'Large Event',
  LongTermShuttle = 'Long Term Shuttle',
}

export enum PaymentMethodTypeId {
  CreditCard = 1,
  ACH = 2,
  Check = 3,
}

export enum PaymentMethodTypeKey {
  CreditCard = 'credit_card',
  ACH = 'ach',
  Check = 'check',
}

export enum PaymentMethodTypeLabel {
  CreditCard = 'Credit Card',
  Check = 'Check',
  PO = 'PO',
  Wire = 'Wire/ACH',
}

export enum PaymentStatusLabel {
  NotPaid = 'Not Paid',
  PartiallyPaid = 'Partially Paid',
  FullyPaid = 'Fully Paid',
}

export enum ReservationStatusKey {
  Upcoming = 'upcoming',
  Started = 'started',
  Finished = 'finished',
  Cancelled = 'cancelled',
  OnHold = 'hold',
}

export enum ReservationStatusId {
  Upcoming = 1,
  Started = 2,
  Finished = 3,
  Cancelled = 4,
  OnHold = 5,
}

export enum VehicleTypeLabel {
  CharterBus = 'Charter Bus',
  MiniBus = 'Mini Bus',
  Sprinter = 'Sprinter',
  PartyBus = 'Party Bus',
  Sedan = 'Sedan',
  SUV = 'SUV',
  Limousine = 'Limousine',
  Trolley = 'Trolley',
  SchoolBus = 'School Bus',
  Van = 'Van',
}

export enum RiskTypeId {
  IncompleteAddress = 1,
  PickupTimeMissing = 2,
  DriverHotelMissing = 3,
  FlightInfoMissing = 4,
  NonDOTCompliant = 5,
  IncompleteStop = 6,
  MissingStop = 7,
}

export enum RiskTypeLabel {
  IncompleteAddress = 'Incomplete Address',
  PickupTimeMissing = 'Missing Pickup Time',
  DriverHotelMissing = 'Missing Hotel Information',
  FlightInfoMissing = 'Missing Flight Information',
  NonDOTCompliant = 'Non DOT Compliant',
  IncompleteStop = 'Incomplete Stop',
  MissingStop = 'Missing Stop',
}

export enum VehicleTypeKey {
  CharterBus = 'charter_bus',
  MiniBus = 'mini_bus',
  Sprinter = 'sprinter',
  PartyBus = 'party_bus',
  Sedan = 'sedan',
  SUV = 'suv',
  Limousine = 'limousine',
  Trolley = 'trolley',
  SchoolBus = 'school_bus',
  Van = 'van',
}

export enum VehicleTypeId {
  CharterBus = 1,
  MiniBus = 2,
  Sprinter = 3,
  PartyBus = 4,
  Sedan = 5,
  SUV = 6,
  Limousine = 7,
  Trolley = 8,
  SchoolBus = 9,
  Van = 10,
}

export enum VehicleTypeCapacity {
  CharterBus = 56,
  MiniBus = 28,
  Sprinter = 12,
}

export enum CancellationTicketStatus {
  Created = 'Created',
  Open = 'Open',
}

// The source from which the quote was viewed (email or quote table).
export enum ViewQuoteSource {
  Email = 'Email',
  QuoteTable = 'Quote Table',
  Other = 'Other',
}

export enum NPSTypeId {
  PostBooking = 1,
  PostTrip = 2,
}

export enum SupportTicketKey {
  Change = 'Change',
  Issue = 'Issue',
  Billing = 'Billing',
  Other = 'Other',
}

export enum SupportTicketTypeLabel {
  Quote = 'Quote',
  Reservation = 'Reservation',
}

export enum CountryAbbreviation {
  USA = 'US',
  Canada = 'CA',
  Mexico = 'MX',
}

export enum TicketSeverityType {
  Change = 2, // high
  Issue = 2, // high
  Billing = 3, // medium
  Other = 3, // medium
}

export enum TicketTypeId {
  Change = 6, // trip change
  Issue = 4, // issue
  Billing = 5, // general
  Other = 5, // general
}

export enum TicketTitle {
  Change = 'Update My Itinerary',
  Issue = 'Report an Issue',
  Billing = 'Billing Question',
  Other = 'Other Question',
  None = 'Contact Support',
}

export enum SelfServeStepKey {
  TripType = 'trip_type',
  TripDetails = 'trip_details',
  TripItinerary = 'trip_itinerary',
  CustomerInformation = 'customer_information',
  CustomerInformationFollowUp = 'customer_information_follow_up',
  CustomerPassword = 'create_password',
  CustomerSignIn = 'sign_in',
  VehiclePricing = 'vehicle_pricing',
  Amenities = 'amenities',
}

export enum TripModificationStepKey {
  Itinerary = 'itinerary',
  Vehicle = 'vehicle',
  Review = 'review',
  Confirmation = 'confirmation',
  Application = 'application',
  BalanceUpdateReview = 'pricing_review',
  SubmissionError = 'error'
}

export enum SpecialRequirementsTypeLabel {
  ADA = 'ADA',
  SPAB = 'SPAB',
  VehicleNeededEntireTrip = 'Vehicle stays on-site',
}

export enum SpecialRequirementsTypeKey {
  ADA = 'ada',
  SPAB = 'spab',
  VehicleNeededEntireTrip = 'vehicle_needed_entire_trip',
}

export enum AmenityTypeId {
  Wifi = 1,
  Luggage = 2,
  Bathroom = 3,
  SeatBelts = 4,
  ADA = 5,
  TVScreens = 6,
  Outlets = 7,
  LeatherSeats = 8,
  CDC = 9,
  DOD = 10,
  SPAB = 11,
  AlcoholConsumption = 12,
  Certification19A = 14,
  AuxBluetooth = 15,
  PASystem = 16,
  BottledWater = 17,
  MakeModelYear = 18,
  BusColorInterior = 19,
  BusLogos = 20,
  NonStandardBus = 21,
  TripCoordinators = 22,
  BusWraps = 23,
}

export enum AmenityTypeKey {
  Wifi = 'wifi',
  Luggage = 'luggage',
  Bathroom = 'lavatory',
  SeatBelts = 'seatbelts',
  ADA = 'ada',
  TVScreens = 'tv_screens',
  Outlets = 'outlets',
  LeatherSeats = 'leather_seats',
  CDC = 'cdc',
  DOD = 'dod',
  SPAB = 'spab',
  AlcoholConsumption = 'alcohol_allowed',
  Certification19A = '19a_certification',
  AuxBluetooth = 'aux_bluetooth',
  PASystem = 'pa_system',
  BottledWater = 'bottled_water',
  MakeModelYear = 'make_model_year',
  BusColorInterior = 'vehicle_color',
  BusLogos = 'vehicle_logos',
  NonStandardBus = 'non_standard_vehicle_size',
  TripCoordinators = 'trip_coordinators',
  BusWraps = 'wraps',
}

export enum PaidAmenityTypeId {
  Wifi = 1,
  LeatherSeats = 8,
  AlcoholAllowed = 12,
}

export enum TripEventTypeId {
  K12 = 1,
  University = 2,
  Athletics = 3,
  Airlines = 4,
  Military = 5,
  Corporate = 6,
  Personal = 7,
  Wedding = 8,
  AirportTransfer = 9,
  Other = 10,
}

export enum NoteTypeId {
  Internal = 1,
  Customer = 2,
  Driver = 3,
}

export enum ItineraryStopTypeKey {
  Stop = 'stop',
  Dropoff = 'dropoff',
  Wait = 'wait',
  Pickup = 'pickup',
  Travel = 'travel',
}

export enum SplitTrafficType {
  Anonymous = 'anonymous',
  User = 'user',
}

export enum SplitKeyType {
  AnonymousUser = 'anonymous',
}

export enum PaymentGatewayTypeKey {
  Finix = 'finix',
}

// Adding a new feature flag will require specifying the associated traffic type in split.ts
export enum SplitFeatureFlag {
  LiveChat = 'live-chat',
  ItineraryModificationWizard = 'itinerary-modification-wizard',
  Auth0SignIn = 'auth0-charterup',
  NewTripAmenities = 'new-trip-amenities',
  SalesbotLastMinuteQuote = 'salesbot-last-minute-quote',
  BrandedQuoteFlow = 'branded-quote-flow',
  DisplayPartnerURL = 'display-partner-url',
  SalesBotRevamp = 'salesbot-revamp',
}

// Contains 63 characters or fewer.
// Starts with a letter or number.
// Contains only letters, numbers, hyphen, underscore, or period.
export enum SplitEvents {
  QuoteCreated = 'quote-created',
  QuoteConverted = 'quote-converted',
  GuestQuoteConverted = 'guest-quote-converted',
}

export enum ChargeTypeId {
  BaseFare = 1,
  ProcessingFee = 4,
  Discount = 15,
  Amenities = 20,
}

export enum CustomerNotificationSettingTypeKey {
  QuoteSalesFollowUp = 'quote_sales_follow_up',
}

export enum CreditCardTypeKey {
  AmericanExpress = 'amex',
  DinersClub = 'diners',
  Discover = 'discover',
  Visa = 'visa',
  MasterCard = 'mastercard',
}

export enum PricingMethod {
  SinglePrice = 'single_price',
  Bids = 'bids',
  Category = 'category',
}

export enum LiveTrackingMapRefreshInterval {
  STANDARD_REFRESH_INTERVAL = 10,
  LONG_REFRESH_INTERVAL = 60,
}

export enum LiveTrackingStatus {
  InProgress = 'in-progress',
  Completed = 'completed',
  Upcoming = 'upcoming',
  Scheduled = 'scheduled',
}

export enum CancellationRefundPercentage {
  Full = 1,
  Partial = 0.9,
  None = 0,
}

export enum ValuePropKey {
  BookingProtection = 'booking_protection',
  Tracking = 'tracking',
  LiveSupport = 'live_support',
  QualifiedOperators = 'qualified_operators',
}

export enum Page {
  QuoteDetail = 'Quote Detail',
  Provider = 'Provider',
  Checkout = 'Checkout',
  ReservationDetail = 'Reservation Detail',
  SalesBot = 'Sales bot',
  Other = 'Other',
}

export enum InfoModalVariant {
  Simple = 'simple',
  Standard = 'standard',
  Itemized = 'itemized',
  Custom = 'custom',
}

export enum PermissionName {
  OperatorDetailPreview = 'canViewOperatorDetailPreview',
  EnterpriseTracking = 'canViewEnterpriseTracking',
  EnterpriseTrackingReservationDetails = 'canViewEnterpriseTrackingReservationDetails',
  CharterUPCheckoutMeta = 'canViewCharterUPCheckoutMeta',
  CustomerAccountAdmin = 'canViewCustomerAccountAdmin',
}

export enum PaymentTypeKey {
  FullPayment = 'full_payment',
  DownPayment = 'down_payment',
  BillAfterServices = 'bill_after_services',
  PurchaseOrder = 'purchase_order'
}

export enum SalesBotStepKey {
  TripType = 'trip-type',
  Itinerary = 'itinerary',
  TripDetails = 'trip-details',
  CustomerDetails = 'customer-details', // this actually is not part of the accordion, but is a separate page that is shown instead of the accordion
  Vehicles = 'vehicles',
  Amenities = 'amenities',
}
