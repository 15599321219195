<template>
  <div>
    <p class="font-bold">
      {{ $t('paymentSent.BODY') }}
    </p>
    <br />
    <v-form ref="form" v-model="formIsValid">
      <v-row no-gutters>
        <v-col cols="12">
          <label for="cardName">
            {{ $t('paymentSent.CHECK_CONFIRMATION_NUMBER') }}
          </label>
          <v-text-field
            id="checkNumber"
            ref="checkNumber"
            v-model="formData.checkNumber.value"
            v-bind="formData.checkNumber"
            required
            validate-on-blur
            outlined
          />
        </v-col>
        <v-col cols="12">
          <label for="cardName">{{ $t('paymentSent.COMMENT') }}</label>
          <v-textarea
            id="comment"
            ref="comment"
            v-model="formData.comment"
            required
            outlined
            rows="5"
          />
        </v-col>
        <AlertBox :active="!!errorMessage" type="error" class="margin-b-3">
          {{ errorMessage }}
        </AlertBox>
      </v-row>
      <v-row justify="end" :no-gutters="$vuetify.breakpoint.xs">
        <v-col cols="auto">
          <v-btn tabindex="6" small text color="error" @click="$emit('close')">
            {{ $t('common.CANCEL') }}
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn class="primary" small tabindex="5" @click="send">
            {{ $t('common.SUBMIT') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import ticket from '@/services/ticket'
import auth from '@/store/modules/auth'
import AlertBox from '@/components/AlertBox.vue'
import { ReservationIdentifiers } from '@/models/dto/Reservation'

@Component({ components: { AlertBox } })
export default class PaymentSent extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly reservationIdentifiers: ReservationIdentifiers[]

  formIsValid = false
  formData = {
    checkNumber: {
      value: '',
      rules: [(v) => !!v || 'Check/confirmation number is required'],
    },
    comment: '',
  }
  errorMessage = ''

  async send(): Promise<void> {
    const form = this.$refs.form as any
    if (!form.validate()) {
      return
    }

    const currentDate = new Date(Date.now()).toLocaleDateString('en-US')
    this.formData.comment +=
      ` --- ${auth.user.firstName} ${auth.user.lastName} ` +
      `paid on ${currentDate}, check/confirmation number is ${this.formData.checkNumber.value}`

    const params = {
      comments: this.formData.comment,
      title: 'Payment Sent',
      ticketTypeId: 7,
      ticketSeverityTypeId: 3,
      createdById: auth.userId,
      creatorFirstName: auth.user?.firstName,
      creatorLastName: auth.user?.lastName,
      creatorEmail: auth.user?.email,
      reservationId: null,
      managedId: null,
    }

    for (const reservationIdentifier of this.reservationIdentifiers) {
      params.reservationId = reservationIdentifier.reservationId
      params.managedId = reservationIdentifier.managedId
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await ticket.create(params)
        if (response.status === 200) {
          continue
        }
      } catch {
        this.errorMessage = this.$t('paymentSent.ERROR').toString()
      }
    }

    this.formData.checkNumber.value = ''
    this.formData.comment = ''
    this.$emit('sent')
    this.$emit('close')
  }
}
</script>
