<template>
  <div class="w-full">
    <template>
      <div
        class="d-flex font-book flex-wrap"
        :class="{'justify-space-between': showLiveTracking || isLiveTracking}"
      >
        <div class="d-flex align-center padding-y-1">
          <template v-if="showLiveTracking">
            <div
              class="w-8 h-8 border-radius-round background-red margin-r-3"
              :class="{ pulse: isLiveTracking }"
            />
            <p class="margin-y-0 font-14 font-weight-400">
              {{ liveTrackingAvailableCopy }}
            </p>
          </template>
        </div>
        <div
          class="d-flex align-center justify-space-between font-14 font-weight-600 padding-y-1"
        >
          <a class="margin-x-0" @click="onViewMapClick">
            View Map
          </a>

          <v-divider class="margin-x-2" vertical />

          <div class="d-flex align-center">
            <CUIcon
              @click="shareLiveTrackingDialogIsOpen = true"
              view-box="0 0 16 16"
              color="primary"
              class="w-16 h-16 margin-r-2"
            >
              link
            </CUIcon>
            <a @click="shareLiveTrackingDialogIsOpen = true" class="margin-x-0">
              Share Trip
            </a>
          </div>

          <template v-if="showTripModification">
            <v-divider class="margin-x-2" vertical />
              <span
                class="text-primary font-13 cursor-pointer font-weight-600"
                @click="openTripModificationStartDialog"
              >
                Modify Trip
              </span>
          </template>
        </div>
      </div>
    </template>
    <ShareLiveTrackingDialog
      v-model="shareLiveTrackingDialogIsOpen"
      :reservation-hash="reservation.hash"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch, ProvideReactive } from 'vue-property-decorator'
import { ReservationDetail } from '@/models/dto'
import ShareLiveTrackingDialog from '@/components/ShareLiveTrackingDialog.vue'
import { ReservationStatusKey, SplitFeatureFlag } from '@/utils/enum'
import { EventBus } from '@/utils/eventBus'
import auth from '@/store/modules/auth'

@Component({
  components: {
    ShareLiveTrackingDialog
  },
})
export default class TripLiveTracking extends Vue {
  @Prop({ type: Object, required: true }) readonly reservation: ReservationDetail

  shareLiveTrackingDialogIsOpen = false
  isItineraryModificationWizardEnabled = false

  async mounted(): Promise<void> {
    EventBus.$on('open-share-tracking', () => {
      this.shareLiveTrackingDialogIsOpen = true
    })
    this.isItineraryModificationWizardEnabled = await this.$split.isFeatureEnabled(
      SplitFeatureFlag.ItineraryModificationWizard
    )
  }

  get isLiveTracking(): boolean {
    return (
      this.reservation?.hasLiveTrackingData &&
      this.reservation.reservationStatus === ReservationStatusKey.Started
    )
  }

  get willHaveLiveTracking(): boolean {
    return this.reservation?.willHaveLiveTracking
  }

  get showLiveTracking(): boolean {
    if (
      this.reservation.reservationStatus === ReservationStatusKey.Finished ||
      this.reservation.reservationStatus === ReservationStatusKey.Cancelled
    ) {
      return false
    }
    if (this.isLiveTracking || this.willHaveLiveTracking) {
      return true
    }
    return false
  }

  get isUserBookingContact(): boolean {
    return auth.userId === this.reservation.customer?.customerId
  }

  get isReservationUpcomingOrOnHold(): boolean {
    return (
      this.reservation.reservationStatus === ReservationStatusKey.Upcoming ||
      this.reservation.reservationStatus === ReservationStatusKey.OnHold
    )
  }

  get showTripModification(): boolean {
    return this.isItineraryModificationWizardEnabled
      && this.reservation?.allowSelfServeModification
      && !this.reservation?.hasPendingModificationRequest
  }

  get liveTrackingAvailableCopy(): string {
    if (this.isLiveTracking) {
      return `Live tracking available`
    }
    return `Live tracking available on ${this.pickupDate}`
  }

  get pickupDate(): string {
    return this.$dayjs(this.reservation?.pickupDate)
      .tz(this.reservation?.stops[0].address.timeZone)
      .format('MM/DD')
  }

  onViewMapClick() {
    const path = {
      name: 'public-tracking',
      params: {
        hash: this.reservation.hash,
      },
    }
    const routeData = this.$router.resolve(path)
    window.open(routeData.href, '_blank')
  }

  openTripModificationStartDialog(): void {
    EventBus.$emit('open-trip-modification-dialog')
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';

.pulse {
  box-shadow: 0 0 0 0 rgba($red, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($red, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($red, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($red, 0);
  }
}
</style>
