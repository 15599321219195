<template>
  <div class="d-flex align-center">
    <v-menu bottom right origin="top left" :nudge-left="5">
      <template #activator="{ on, attrs }">
        <v-checkbox
          color="primary"
          :input-value="value"
          :indeterminate="indeterminate"
          @change="handleClick($event)"
        />
        <div
          class="d-flex align-center justify-center w-16 h-28 hover:background-black-15 border-radius-regular cursor-pointer z-5 margin-l-n2"
          :style="{ 'margin-top': '-2px' }"
          v-bind="attrs"
          v-on="on"
        >
          <CUIcon
            small
            view-box="0 0 24 24"
            color="gray"
            class="hover:border-radius-regular w-14 h-14"
          >
            keyboard_arrow_down
          </CUIcon>
        </div>
      </template>
      <v-list class="dropdown-menu">
        <v-list-item
          v-for="(predefined, predefinedIndex) in predefinedSelects"
          :id="`v-list-item-${predefinedIndex}`"
          :key="`${toKebab(predefinedIndex.label)}-${predefinedIndex}`"
          @click="$emit('select', predefined.filter)"
        >
          <v-list-item-title>{{ predefined.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { PredefinedSelect } from '@/models/SelectColumn'
import { toKebab } from '@/utils/string'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CUDataTableHeaderCheckbox extends Vue {
  @Prop({ type: String, default: 'primary', required: false })
  readonly color: string
  @Prop({ type: Array, default: () => [], required: false })
  readonly predefinedSelects: PredefinedSelect[]
  @Prop({ type: Array, required: false, default: undefined })
  selectedItems: unknown[]
  @Prop({ type: Array, required: false, default: undefined }) items!: any[]
  @Prop({ type: String, required: false }) itemKey!: string

  toKebab = toKebab

  get value(): boolean {
    const currentItems = this.items.map((item) => item[this.itemKey])
    if (this.selectedItems.length < currentItems.length) {
      return false
    }
    return currentItems.every((item) => !!this.selectedItems?.includes(item))
  }

  get indeterminate(): boolean {
    return !!(this.selectedItems.length && !this.value)
  }

  handleClick(value: boolean): void {
    const filter = (item: unknown) => value
    this.$emit('select', filter)
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';

::v-deep .v-input--selection-controls__ripple {
  width: 40px !important;
}

::v-deep
  .v-input.v-input--selection-controls.v-input--checkbox.v-input--indeterminate {
  .v-input__control {
    .v-input__slot {
      .v-input--selection-controls__input {
        i {
          color: $primary !important;
        }
      }
    }
  }
}
</style>
