<template>
  <v-container
    :style="{ maxWidth }"
    :class="{ 'padding-a-4': $vuetify.breakpoint.xs }"
  >
    <slot name="header" />
    <v-row align="start" justify="center" no-gutters>
      <v-col cols="12" lg="7" md="9">
        <slot name="left-column" />
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.lgAndUp"
        cols="12"
        class="trip-info position-sticky align-start padding-l-11"
        :style="{ top: '5.85rem' }"
        lg="5"
      >
        <slot name="right-column" />
      </v-col>
    </v-row>

    <v-row justify="center" no-gutters>
      <v-col cols="12" lg="12" md="9">
        <slot name="bottom-portion" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class StickyColumnLayout extends Vue {
  @Prop({ type: String, default: '1300px' }) readonly maxWidth!: string
}
</script>
