<template>
  <v-container>
    <!--UPLOAD-->
    <form
      v-if="isInitial || isSaving"
      ref="dropForm"
      enctype="multipart/form-data"
      novalidate
    >
      <v-row id="image-upload" justify="center" class="dropbox text-center">
        <v-col cols="auto">
          <input
            type="file"
            multiple
            :name="uploadFieldName"
            :disabled="isSaving"
            accept="image/*"
            class="opacity-0 h-200 position-absolute cursor-pointer"
            @change="
              filesChange($event.target.name, $event.target.files)
              fileCount = $event.target.files.length
            "
          />
        </v-col>
        <v-col v-if="isInitial" cols="auto">
          Drag your file(s) here to begin
          <br />
          or click to browse
        </v-col>
        <p v-if="isSaving">Uploading {{ fileCount }} files...</p>
      </v-row>
    </form>
    <!--SUCCESS-->
    <div v-if="isSuccess && value">
      <h2>Loaded {{ value.length }} file(s) successfully.</h2>
      <p>
        <a href="javascript:void(0)" @click="reset">{{ resetLabel }}</a>
      </p>
    </div>
    <!--FAILED-->
    <div v-if="isFailed">
      <h2>Uploaded failed.</h2>
      <p>
        <a href="javascript:void(0)" @click="reset">Try again</a>
      </p>
      <pre>{{ uploadError }}</pre>
    </div>
  </v-container>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3
@Component({})
export default class FileUploader extends Vue {
  @Prop({ type: FileList, default: null }) readonly value: FileList
  @Prop({ type: String, default: 'Change Image' }) readonly resetLabel: string

  uploadError = null
  currentStatus = null
  uploadFieldName = 'photos'

  get isInitial(): boolean {
    return this.currentStatus === STATUS_INITIAL
  }
  get isSaving(): boolean {
    return this.currentStatus === STATUS_SAVING
  }
  get isSuccess(): boolean {
    return this.currentStatus === STATUS_SUCCESS
  }
  get isFailed(): boolean {
    return this.currentStatus === STATUS_FAILED
  }

  created(): void {
    this.reset()
  }

  reset(): void {
    // reset form to initial state
    this.currentStatus = STATUS_INITIAL
    this.$emit('input', null)
    this.uploadError = null
  }

  filesChange(fieldName, fileList): void {
    this.currentStatus = STATUS_SAVING
    // handle file changes
    if (!fileList.length) {
      return
    }
    // append the files to FormData
    this.$emit('input', fileList)
    // save it
    this.currentStatus = STATUS_SUCCESS
  }
}
</script>

<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: transparent;
  color: dimgray;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
  transition: 0.3s;
}
.dropbox:hover {
  background: lightgray; /* when mouse over to the drop zone, change color */
  transition: 0.3s;
}
.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
