<template>
  <div
    class="d-flex flex-grow-1"
    :class="{
      'flex-column': $vuetify.breakpoint.xsOnly,
      'align-center': $vuetify.breakpoint.smAndUp,
    }"
  >
    <v-btn
      v-for="(action, actionIndex) in actions"
      :key="`action-${action.key}-${actionIndex}`"
      :disabled="isActionDisabled(action)"
      :color="actionColor(action)"
      small
      text
      class="margin-r-1 padding-x-2 z-5"
      @click="handleAction(action)"
    >
      <CUIcon
        v-if="action.icon"
        view-box="0 0 24 24"
        class="margin-r-1"
        :color="actionColor(action)"
      >
        {{ action.icon }}
      </CUIcon>
      {{ action.displayText }}
    </v-btn>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TableAction } from '@/models/TableAction'

@Component
export default class CUDataTableActionRow extends Vue {
  @Prop({ type: Array, required: true }) actions!: TableAction[]
  @Prop({ required: false, default: undefined }) rows!: any[]

  actionColor(action: TableAction): string {
    return this.isActionDisabled(action)
      ? 'gray-mid-light'
      : action.color || 'primary'
  }

  handleAction(action: TableAction): void {
    if (action.action) {
      action.action(this.rows)
    }
  }

  isActionDisabled(action: TableAction): boolean {
    return (
      (action.isSingleSelectOnly && this.rows?.length > 1) ||
      (action.isMultiSelectOnly && this.rows?.length === 1) ||
      (typeof action.disabled === 'boolean' && action.disabled) ||
      (typeof action.disabled === 'function' && action.disabled(this.rows))
    )
  }
}
</script>
