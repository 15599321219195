<template>
  <div>
    <div class="d-flex align-center font-20 font-bold margin-b-2">
      <CUIcon view-box="0 0 24 24" color="primary" class="w-24 h-24 margin-r-1">charterup_choice</CUIcon>
      <span class="margin-r-1">CharterUP's Choice</span>
      <InfoModal v-model="isOpen" :modal-detail="charterupChoiceModalDetail" max-width="860px">
        <template #activator="{ on, attrs }">
          <a class="font-12 margin-t-1 margin-x-1" v-bind="attrs" v-on="on">
            {{ $t('common.SEE_WHY') }}
          </a>
        </template>
        <template #text>
          <div class="margin-b-n2">
            <p class="margin-t-2">
              We recommend <span class="font-bold">{{ operatorName }}</span> for your trip as the best value for money based on:
            </p>
            <ul class="margin-t-2 margin-l-2">
              <li>&#8226; Competitive price</li>
              <li>&#8226; Punctuality of the operator</li>
              <li>&#8226; Newness of the fleet</li>
              <li>&#8226; Customer satisfaction ratings</li>
            </ul>
            <p class="margin-t-2">
              Operators cannot pay to be CharterUP’s Choice.
            </p>
          </div>
        </template>
      </InfoModal>
    </div>
    <BidCard
      id="provider-bidcard-charterups-choice"
      preferred
      :go-to="{
        name: 'provider-detail',
        params: {
          quoteId: quoteId,
          providerId: bid.companyId,
        },
      }"
      :bid="bid"
      :is-elite="isElite"
      :show-usd="isPickupInCanada"
      :show-amenities="true"
      charter-up-choice-styling
      :is-branded-quote-flow-enabled="isBrandedQuoteFlowEnabled"
    />
    <v-divider class="margin-t-9 margin-b-9" />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import BidCard from '@/components/BidCard.vue'
import { QuoteDetailV2, QuoteDetailV2Bid } from '../models/dto/QuoteDetailV2'
import { InfoModalDetail } from '@/models/InfoModalDetail'
import { InfoModalVariant } from '@/utils/enum'
import InfoModal from './InfoModal.vue'

@Component({ components: { BidCard, InfoModal } })
export default class QuoteDetailBidListCharterUpChoice extends Vue {
  @Prop({ required: true, type: Object }) bid!: QuoteDetailV2Bid
  @Prop({ required: true, type: Boolean }) isElite!: boolean
  @Prop({ type: Object, default: null }) readonly quote: QuoteDetailV2
  @Prop({ type: Boolean, required: true }) isBrandedQuoteFlowEnabled: boolean

  charterupChoiceModalDetail: InfoModalDetail = {
    label: 'CharterUP’s Choice',
    shortLabel: 'CharterUP’s Choice',
    icon: {
      name: 'charterup_choice',
      color: 'primary',
      viewBox: '0 0 24 24',
    },
    modalVariant: InfoModalVariant.Custom,
    showCharterUPLogo: true,
    showSupportButton: false
  }

  isOpen = false

  get operatorName(): string {
    return this.bid.companyDBAName
  }

  get quoteId(): number {
    return this.quote?.quoteId
  }

  get isPickupInCanada(): boolean {
    return this.quote?.trips?.[0]?.stops?.[0]?.address?.country === 'CA'
  }
}
</script>
