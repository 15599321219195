<template>
  <CUSkeletonLoader
    v-if="loading"
    :width="outerDiameter"
    :height="outerDiameter"
    round
  />
  <div v-else>
    <div
      class="position-relative border-solid border-2 border-radius-round"
      :class="{
        'border-primary': !disabled && selected,
        'border-gray-subtle-text': !disabled && !selected,
        'border-border-gray': disabled,
        'w-24 h-24': sizeClass === 'x-large',
        'w-20 h-20': sizeClass === 'large',
        'w-16 h-16': sizeClass === 'regular',
      }"
    >
      <div
        v-if="selected"
        class="border-radius-round position-absolute left-3 top-3"
        :class="{
          'background-primary': !disabled && selected,
          'background-gray': disabled,
          'w-14 h-14': sizeClass === 'x-large',
          'w-10 h-10': sizeClass === 'large',
          'w-6 h-6': sizeClass === 'regular',
        }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CURadioButton extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly loading: boolean
  @Prop({ type: Boolean, required: true }) readonly selected: boolean
  @Prop({ type: Boolean }) readonly disabled: boolean
  @Prop({ type: Boolean }) readonly large: boolean
  @Prop({ type: Boolean }) readonly xLarge: boolean

  get sizeClass(): string {
    if (this.xLarge) {
      return 'x-large'
    }
    if (this.large) {
      return 'large'
    }
    return 'regular'
  }

  get outerDiameter(): string {
    if (this.xLarge) {
      return '24px'
    }
    if (this.large) {
      return '20px'
    }
    return '16px'
  }
}
</script>
