<template>
  <svg
    v-if="iconType === 'in-progress'"
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    viewBox="0 0 24 24"
    aria-labelledby="box"
    role="presentation"
  >
    <g :fill="color">
      <path
        d="M12,1.2c1.5,0,2.9,0.3,4.2,0.9s2.5,1.3,3.5,2.3c1,1,1.7,2.2,2.3,3.5c0.5,1.3,0.9,2.7,0.9,4.2s-0.3,2.9-0.9,4.2
          s-1.3,2.5-2.3,3.5c-1,1-2.2,1.7-3.5,2.4c-1.3,0.4-2.7,0.8-4.2,0.8s-2.9-0.2-4.2-0.9c-1.3-0.5-2.5-1.3-3.5-2.4c-1-1-1.7-2.2-2.4-3.5
          c-0.4-1.2-0.8-2.6-0.8-4.1S1.4,9.1,2,7.8c0.6-1.3,1.4-2.5,2.4-3.5s2.2-1.7,3.5-2.3S10.5,1.2,12,1.2z"
      />
    </g>
  </svg>
  <svg
    v-else-if="iconType === 'upcoming'"
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    viewBox="0 0 24 24"
    aria-labelledby="box"
    role="presentation"
  >
    <g :fill="color">
      <rect
        x="5.5"
        y="4.6"
        :style="{ fill: '#FFFFFF' }"
        width="14.9"
        height="13.8"
      />
      <path
        d="M7.4,16.6c1.3,1.2,2.8,1.8,4.6,1.8s3.3-0.6,4.5-1.8c1.3-1.3,1.9-2.8,1.9-4.6s-0.6-3.3-1.9-4.5
	c-1.2-1.3-2.7-1.9-4.5-1.9S8.7,6.2,7.4,7.5C6.2,8.7,5.5,10.2,5.5,12S6.2,15.3,7.4,16.6z M4.3,19.7c-2.1-2.2-3.1-4.8-3.1-7.7
	s1.1-5.5,3.1-7.6C6.5,2.3,9,1.2,12,1.2s5.5,1.1,7.6,3.2c2.2,2.1,3.2,4.6,3.2,7.6s-1.1,5.5-3.2,7.7c-2.1,2.1-4.6,3.1-7.6,3.1
	S6.5,21.7,4.3,19.7z"
      />
    </g>
  </svg>
  <svg
    v-else-if="iconType === 'scheduled'"
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    viewBox="0 0 24 24"
    aria-labelledby="box"
    role="presentation"
    color="primary"
  >
    <rect width="24" height="24" fill="#FFFFFF" :stroke="color" stroke-width="8"/>
  </svg>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { LiveTrackingStatus } from '@/utils/enum'

@Component
export default class LiveTrackingMapStopIcon extends Vue {
  @Prop({ type: String, required: true, default: '#C4CCD4' })
  readonly color: string
  @Prop({
    type: String,
    required: true,
    default: LiveTrackingStatus.Upcoming,
  })
  readonly iconType: string
}
</script>
