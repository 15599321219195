<template>
  <div
    class="omnibus d-flex align-center"
    :class="{ 'margin-t-3': hasAdditionalCharge }"
  >
    <p class="margin-t-0 font-weight-700">Total (USD)</p>
    <div class="d-flex grow" />
    <p class="margin-t-0 font-weight-700">{{ formattedTotal }}</p>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { CheckoutDetailResult } from '@/models/dto'
import { currencyFilter } from '@/utils/string'

@Component({})
export default class CheckoutQuoteSummaryTotal extends Vue {
  @Prop({ type: Object, required: true })
  readonly checkoutDetail!: CheckoutDetailResult

  get hasAdditionalCharge(): boolean {
    return this.checkoutDetail.charges.filter(({amount}) => !!amount)?.length > 2
  }

  get formattedTotal(): string {
    return currencyFilter(this.checkoutDetail.totalAmount)
  }
}
</script>
