<template>
  <v-card
    v-show="shouldDisplayCard"
    :class="wrapperClasses"
  >
    <v-card-text
      :class="cardTextClasses"
    >
      <v-row>
        <v-col cols="12" class="padding-b-2">
          <h2 class="font-medium">
            {{ title }}
          </h2>
        </v-col>
      </v-row>
      <p v-if="hasAddedAmenities" class="margin-y-3">
        Added extras
      </p>
      <v-row>
        <v-col
          v-for="(amenity, amenityIndex) in addedAmenities"
          :key="`${amenity.id}-added-amenity-${amenityIndex}`"
          :id="`added-amenity-${amenity.id}`"
          cols="6"
          class="padding-y-2"
        >
          <TripAmenityCardItem :amenity="amenity" />
        </v-col>
      </v-row>
      <template v-if="areNewTripAmenitiesEnabled && hasRequestedAmenities">
        <p class="margin-t-5 margin-b-3">
          Requested amenities
        </p>
        <v-row>
          <v-col
            v-for="(amenity, amenityIndex) in requestedAmenities"
            :key="`${amenity.id}-requested-amenity-${amenityIndex}`"
            :id="`requested-amenity-${amenity.id}`"
            cols="6"
            class="padding-y-2"
          >
            <TripAmenityCardItem :amenity="amenity" />
          </v-col>
        </v-row>
      </template>
      <div v-if="formattedIncludedAmenities.length" class="margin-t-6">
        <p class="font-14">
          {{ formattedIncludedAmenities }}
        </p>
      </div>
      <div v-if="hasAmenityNotes && areNewTripAmenitiesEnabled" class="margin-t-2">
        <a class="font-12" @click="isModalOpen = true">
          See amenities notes
        </a>
      </div>
    </v-card-text>
    <TripAmenityCardModal v-model="isModalOpen" :amenities="amenitiesWithNotes" />
  </v-card>
</template>

<script lang="ts">
import { Vue, Component, Prop, InjectReactive, Watch } from 'vue-property-decorator'
import { AmenityItem } from '@/models/AmenityItem'
import TripAmenityCardItem from '@/components/TripAmenityCardItem.vue'
import TripAmenityCardModal from '@/components/TripAmenityCardModal.vue'
import { formatIncludedAmenities } from '@/utils/string'
import { PaidAmenityTypeId, VehicleTypeKey, SplitFeatureFlag } from '@/utils/enum'
import { getAdditionalAmenities, getAmenitiesWithNotes, getIncludedAmenities } from '@/utils/amenity'
import { getVehicleTypeId, vehicleTypeKeyToLabel } from '@/utils/vehicle'

@Component({
  components: {
    TripAmenityCardItem,
    TripAmenityCardModal
  },
})
export default class TripAmenityCard extends Vue {
  @Prop({ type: String, required: true }) readonly vehicleTypeKey!: VehicleTypeKey
  @Prop({ type: Array }) readonly amenities!: AmenityItem[]
  @Prop({ type: Boolean }) readonly isSelfServe!: boolean

  /**
   * Apperance of the card, which has three possible variants:
   * - minimal: no border, no padding
   * - standard: border, padding
   * - relaxed: border, and relaxed padding on smaller screens
   */
  @Prop({ type: String, default: 'standard' }) readonly appearance!: 'minimal' | 'standard' | 'relaxed'

  isModalOpen = false
  areNewTripAmenitiesEnabled = false

  get vehicleTypeId(): number {
    return getVehicleTypeId(this.vehicleTypeKey)
  }

  get isMinimal(): boolean {
    return this.appearance === 'minimal'
  }

  get isStandard(): boolean {
    return this.appearance === 'standard'
  }

  get isRelaxed(): boolean {
    return this.appearance === 'relaxed'
  }

  get wrapperClasses(): Record<string, boolean>{
    return {
      'border-0 margin-t-2': this.isMinimal,
      'margin-y-4': this.isStandard || (this.$vuetify.breakpoint.lgAndUp && this.isRelaxed),
    }
  }

  get cardTextClasses(): Record<string, boolean> {
    return {
      'padding-x-0 padding-y-0': (this.$vuetify.breakpoint.mdAndDown && this.isRelaxed) || this.isMinimal,
      'padding-y-5 padding-x-6': this.$vuetify.breakpoint.lgAndUp && this.isRelaxed,
      'padding-y-5': this.$vuetify.breakpoint.lgAndUp && this.isStandard,
    }
  }

  // Don't show this component if we aren't displaying any amenities
  get shouldDisplayCard(): boolean {
    return !!this.additionalAmenities.length
  }

  get additionalAmenities(): AmenityItem[] {
    return getAdditionalAmenities(this.amenities, this.vehicleTypeId)
  }

  get includedAmenities(): AmenityItem[] {
    return getIncludedAmenities(this.amenities, this.vehicleTypeId)
  }

  // Amenities that were paid for during the self-serve flow
  get addedAmenities(): any[] {
    if (!this.isSelfServe) {
      return []
    }
    return this.additionalAmenities.filter((amenity) => Object.values(PaidAmenityTypeId).includes(amenity.id))
  }

  // All amenities that aren't paid amenities
  get requestedAmenities(): AmenityItem[] {
    return this.additionalAmenities.filter((amenity) =>
      !this.addedAmenities.some(addedAmenity => addedAmenity.id === amenity.id)
    )
  }

  get hasAddedAmenities(): boolean {
    return this.addedAmenities.length !== 0
  }

  get hasRequestedAmenities(): boolean {
    return this.requestedAmenities.length !== 0
  }

  get title(): string {
    return `${vehicleTypeKeyToLabel(this.vehicleTypeKey)} Amenities`
  }

  get formattedIncludedAmenities(): string {
    return this.includedAmenities ? formatIncludedAmenities(this.includedAmenities) : ''
  }

  get hasAmenityNotes(): boolean {
    return this.amenitiesWithNotes.length > 0
  }

  get amenitiesWithNotes(): AmenityItem[] {
    return getAmenitiesWithNotes(this.amenities, this.vehicleTypeId)
  }

  async mounted() {
    this.areNewTripAmenitiesEnabled = await this.$split.isFeatureEnabled(
      SplitFeatureFlag.NewTripAmenities
    )
  }
}
</script>
