<template>
  <div>
    <h3 class="margin-b-n4">Itinerary</h3>
    <TripMap :stops="trip.stops" />
    <TripItineraryV2 :itinerary-stops="itineraryStops" class="margin-t-4" />
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import { Trip } from '../models/dto'
import TripMap from '@/components/TripMap.vue'
import TripItineraryV2 from '@/components/TripItineraryV2.vue'
import { TripItineraryV2Stop } from '@/models/ItineraryStop'
import { getTripItineraryStopsFromTrip } from '@/utils/tripItinerary'

const props = defineProps<{ trip: Trip }>()

const itineraryStops = computed<TripItineraryV2Stop[]>(() => getTripItineraryStopsFromTrip(props.trip))
</script>
