<template>
  <div>
    <TripInfoItineraryHotelInfo
      v-if="showRisks"
      :allow-reservation-management="allowReservationManagement"
      :trip="trip"
      :reservation-id="reservationId"
      :managed-id="managedId"
      :hotel-information="hotelInformation"
      :hotel-requirement="hotelRequirement"
      :trip-status="tripStatus"
    />
    <CUItinerary>
      <CUItineraryItem
        v-for="(stop, stopIndex) in stopsToDisplay"
        :key="stop.id"
        class="w-full"
        :upcoming="!showLiveStops || stopStatuses[stopIndex].upcoming"
        :in-progress="stopStatuses[stopIndex].inProgress"
        :completed="stopStatuses[stopIndex].completed"
        :last-stop="stopStatuses[stopIndex].lastStop"
        color="white"
        small
      >
        <div class="padding-x-3 margin-b-2 w-full padding-b-6">
          <TripInfoItineraryAddress
            v-if="stop.address"
            :id="`trip-info-itinerary-stop-${stopIndex}`"
            :title="stop.address.title"
            :street="stop.address.street1"
            :state="stop.address.state"
            :city="stop.address.city"
          />

          <TripInfoItineraryDropoffTime
            v-if="stop.dropoffDatetime && stop.address"
            :id="`trip-info-itinerary-stop-${stopIndex}-pickup-time`"
            :dropoffDatetime="stop.dropoffDatetime"
            :zoneId="stop.address.timeZone"
          />

          <div>
            <TripInfoItineraryPickupTime
              v-if="stop.pickupDatetime"
              :id="`trip-info-itinerary-stop-${stopIndex}-pickup-time`"
              :pickupDatetime="stop.pickupDatetime"
              :zoneId="stop.address.timeZone"
            />
            <template v-if="showRisks && allowReservationManagement">
              <ItineraryRiskWarning
                v-if="riskTypeIdToShowForStop(stopIndex)"
                class="display-flex align-center justify-start"
                :class="{
                  'margin-t-n2': isMissingStopRisk(
                    riskTypeIdToShowForStop(stopIndex)
                  ),
                }"
                :reservation-id="reservationId"
                :managed-id="managedId"
                :index="stopIndex"
                :stop="stop"
                :risk-type-id="riskTypeIdToShowForStop(stopIndex)"
              />
              <ItineraryRiskWarning
                v-if="isFlightRiskOnStop(risksPerStop[stopIndex])"
                class="display-flex align-center justify-start"
                :reservation-id="reservationId"
                :managed-id="managedId"
                :index="stopIndex"
                :stop="stop"
                :risk-type-id="flightInfoMissingRiskTypeId"
              />
            </template>
            <TripInfoItineraryStopFlightInfo
              v-if="
                !isFlightRiskOnStop(risksPerStop[stopIndex]) &&
                showFlightInformation(stop)
              "
              :id="`stop-risk-item-${stopIndex}`"
              :stop="stop"
              :trip-status="tripStatus"
              :allow-reservation-management="allowReservationManagement"
              class="margin-t-2"
            />
          </div>
          <TripInfoItineraryStopNote :stop="stop" />
        </div>
      </CUItineraryItem>
    </CUItinerary>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import deepClone from '@/utils/deepClone'
import { highestPriorityRiskTypeId } from '@/utils/risk'
import {
  Trip,
  Stop,
  HotelInformation,
  HotelRequirement,
  Risk,
} from '@/models/dto'
import CUItinerary from '@/components/CUItinerary.vue'
import CUItineraryItem from '@/components/CUItineraryItem.vue'
import DriverHotelRequirementsDialog from '@/components/DriverHotelRequirementsDialog.vue'
import ItineraryRiskWarning from '@/components/ItineraryRiskWarning.vue'
import TripInfoItineraryStopNote from '@/components/TripInfoItineraryStopNote.vue'
import TripInfoItineraryAddress from '@/components/TripInfoItineraryAddress.vue'
import TripInfoItineraryStopFlightInfo from '@/components/TripInfoItineraryStopFlightInfo.vue'
import TripInfoItineraryDropoffTime from '@/components/TripInfoItineraryDropoffTime.vue'
import TripInfoItineraryPickupTime from '@/components/TripInfoItineraryPickupTime.vue'
import { RiskTypeId, ReservationStatusKey } from '@/utils/enum'
import TripInfoItineraryHotelInfo from '@/components/TripInfoItineraryHotelInfo.vue'

@Component({
  components: {
    CUItinerary,
    CUItineraryItem,
    TripInfoItineraryStopNote,
    TripInfoItineraryStopFlightInfo,
    ItineraryRiskWarning,
    DriverHotelRequirementsDialog,
    TripInfoItineraryAddress,
    TripInfoItineraryDropoffTime,
    TripInfoItineraryPickupTime,
    TripInfoItineraryHotelInfo,
  },
})
export default class TripItinerary extends Vue {
  @Prop({ type: Object, default: () => {} }) readonly trip: Trip
  @Prop({ type: Array, default: () => [] }) readonly stops: Stop[]
  @Prop({ type: Boolean, default: false }) readonly allowReservationManagement: boolean
  @Prop({ type: Boolean, default: false }) readonly showRisks: boolean
  @Prop({ type: Number }) readonly reservationId: number
  @Prop({ type: String, required: false }) readonly managedId: string
  @Prop({ type: Object, required: false, default: null }) readonly hotelInformation: HotelInformation | null
  @Prop({ type: Object, required: false, default: null }) readonly hotelRequirement: HotelRequirement | null
  @Prop({ type: String, default: '' }) readonly tripStatus: string
  @Prop({ type: Boolean, default: false }) readonly showLiveTracking: boolean

  stopNotes: boolean[] = []

  flightInfoMissingRiskTypeId = RiskTypeId.FlightInfoMissing

  get orderedStops(): any {
    return [...this.stops].sort((a, b) =>
      a.orderIndex > b.orderIndex ? 1 : -1
    )
  }

  get stopStatuses(): {
    upcoming: boolean
    inProgress: boolean
    completed: boolean
    lastStop: boolean
  }[] {
    return this.stopsToDisplay.map((stop, index) => this.getStopStatus(index))
  }

  get risksPerStop(): any[] {
    const stopRisks = []

    if (!this.stopsToDisplay) {
      return stopRisks
    }

    for (const stop of this.stopsToDisplay) {
      const risks = []

      if (stop?.risks) {
        for (const risk of stop?.risks) {
          risks.push(risk)
        }
      }
      if (stop?.address?.risks) {
        for (const risk of stop?.address?.risks) {
          risks.push(risk)
        }
      }
      stopRisks.push(risks)
    }

    return stopRisks
  }

  riskTypeIdToShowForStop(stopIndex: number): number {
    return highestPriorityRiskTypeId(
      this.risksPerStop[stopIndex]
        .filter((risk) => risk.riskTypeId !== RiskTypeId.FlightInfoMissing)
        .map((risk) => risk.riskTypeId)
    )
  }

  get showLiveStops(): boolean {
    return this.tripStatus === 'started' || this.tripStatus === 'finished'
  }

  get nextStopIndex(): number {
    if (!this.stopsToDisplay[0].reached) {
      return 0
    }
    return this.stopsToDisplay.findIndex(
      (stop, i) => !stop.reached && this.stopsToDisplay[i - 1].reached
    )
  }

  get allStopsComplete(): boolean {
    let reachedStopCount = 0
    for (const stop of this.stopsToDisplay) {
      if (stop.reached) {
        reachedStopCount++
      }
    }
    return reachedStopCount === this.stopsToDisplay.length
  }

  get stopsToDisplay(): any {
    const stopsToDisplay = []
    for (const stop of this.orderedStops) {
      const currentStop = deepClone(stop)
      currentStop.risks = currentStop?.risks?.filter(
        (risk) => risk.riskTypeId !== RiskTypeId.MissingStop
      )
      stopsToDisplay.push(currentStop)
      const hasMissingStopRisk = stop?.risks?.some(
        (risk) => risk.riskTypeId === RiskTypeId.MissingStop
      )
      if (hasMissingStopRisk) {
        const missingStop = deepClone(stop)
        missingStop.address = { title: '' }
        missingStop.pickupDatetime = null
        missingStop.dropoffDatetime = null
        stopsToDisplay.push(missingStop)
      }
    }
    return stopsToDisplay
  }

  isMissingStopRisk(riskTypeId: number): boolean {
    return riskTypeId === RiskTypeId.MissingStop
  }

  isFlightRisk(riskTypeId: number): boolean {
    return riskTypeId === RiskTypeId.FlightInfoMissing
  }

  isFlightRiskOnStop(stopRisks: Risk[]): boolean {
    return stopRisks.some(
      (risk) => risk.riskTypeId === RiskTypeId.FlightInfoMissing
    )
  }

  showFlightInformation(stop: Stop): boolean {
    return (
      stop?.flightInformation !== null &&
      stop?.flightInformation?.airline !== null &&
      stop?.flightInformation?.flightNumber !== null
    )
  }

  getStopStatus(stopIndex) {
    const statuses = {
      upcoming: false,
      inProgress: false,
      completed: false,
      lastStop: this.stopsToDisplay.length - 1 === stopIndex,
    }

    if (
      this.tripStatus === ReservationStatusKey.Finished ||
      this.allStopsComplete
    ) {
      statuses.completed = true
      return statuses
    }
    if (this.tripStatus === 'upcoming' || stopIndex > this.nextStopIndex) {
      statuses.upcoming = true
      return statuses
    }
    if (this.showLiveStops && stopIndex === this.nextStopIndex) {
      statuses.inProgress = true
      return statuses
    }
    if (this.showLiveStops && stopIndex < this.nextStopIndex) {
      statuses.completed = true
      return statuses
    }

    return statuses
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-timeline-item__body {
  padding-left: 6px;
}
</style>
