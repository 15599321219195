<template>
  <CUCard :full-width="fullWidth">
    <h2 class="margin-b-6">{{ $t('paymentInformation.TITLE') }}</h2>
    <CheckoutPaymentMethodSelector
      v-if="isPaymentMethodSelectionNeeded"
      v-model="selectedPaymentMethodTypeId"
      :checkout-payment-methods="checkoutPaymentMethods"
    />
    <template v-if="showCreditCardInput">
      <div>
        <div v-show="!showCardForm">
          <PaymentProfileSelectorV2
            v-model="selectedPaymentProfile"
            @update:has-payment-profiles="hasPaymentProfiles = $event"
            @update:loading="loadingPaymentProfiles = $event"
          />
          <v-btn
            v-if="!loadingPaymentProfiles"
            small
            text
            color="primary"
            class="padding-x-4 margin-t-4 font-medium"
            @click="showCardForm = true"
          >
            + {{ $t('paymentProfileSelector.ADD_NEW_CARD') }}
          </v-btn>
        </div>
        <CreditCardFormV2
          v-show="showCardForm"
          ref="credit-card-form"
          v-model="newCard"
          :show-cancel="hasPaymentProfiles"
          :disputed-checkout="disputedCheckout"
          @cancel="showCardForm = false"
        />
      </div>
    </template>
    <template v-if="showCheckWireInput">
      <CheckAndWireFormV2
        ref="check-and-wire-form"
        v-model="checkWireAddress"
      />
    </template>
  </CUCard>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import PaymentProfileSelectorV2 from '@/components/PaymentProfileSelectorV2.vue'
import CreditCardFormV2 from '@/components/CreditCardFormV2.vue'
import CheckAndWireFormV2 from '@/components/CheckAndWireFormV2.vue'
import CheckoutPaymentMethodSelector from '@/components/CheckoutPaymentMethodSelector.vue'
import {
  Address,
  ComputedPaymentProfile,
  PaymentMethodPayload,
  StagePaymentMethod,
  TypeWithId,
} from '@/models/dto'
import { PaymentMethodTypeId, PaymentMethodTypeKey } from '@/utils/enum'
import { isPaymentMethodAllowed, paymentMethodTypeIdToKey } from '@/utils/payment'
import { FormattedPaymentMethod } from '@/models/FormattedPaymentMethod'
import quotes from '@/services/quotes'

@Component({
  components: {
    PaymentProfileSelectorV2,
    CreditCardFormV2,
    CheckAndWireFormV2,
    CheckoutPaymentMethodSelector,
  },
})
export default class CheckoutPaymentInformation extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) readonly disputedCheckout: boolean
  @Prop({ type: Array, required: true }) readonly allowedPaymentMethods: string[]
  @Prop({ type: Boolean, required: false, default: false }) readonly fullWidth!: boolean
  @Prop({ type: Number, required: false }) readonly quoteId: number
  @Prop({ type: Array, required: true }) readonly checkoutPaymentMethods: StagePaymentMethod[]
  @Prop({ type: Array, default: () => [] }) readonly paymentMethodTypes: TypeWithId[]

  showCardForm = false
  selectedPaymentProfile: ComputedPaymentProfile = null
  newCard: FormattedPaymentMethod = null
  checkWireAddress: Address = {} as Address
  hasPaymentProfiles = false
  loadingPaymentProfiles = true
  selectedPaymentMethodTypeId: number = PaymentMethodTypeId.CreditCard

  @Watch('formattedPaymentMethodData', { deep: true })
  onPaymentInfo(): void {
    this.$emit('input', this.formattedPaymentMethodData)
  }

  @Watch('selectedPaymentProfile', { deep: true })
  onSelectedPaymentProfileChange(): void {
    this.$emit('update:payment-profile', this.selectedPaymentProfile)
  }

  @Watch('loadingPaymentProfiles')
  onLoadingPaymentProfilesChange(): void {
    if (!this.hasPaymentProfiles) {
      this.showCardForm = true
    }
  }

  get currentPaymentProfile():
    | ComputedPaymentProfile
    | FormattedPaymentMethod
    | null
  {
    if (this.showCheckWireInput) {
      return null
    }
    return this.showCardForm ? this.newCard : this.selectedPaymentProfile
  }

  get address(): any {
    if (this.showCheckWireInput) {
      return this.checkWireAddress
    }
    return this.currentPaymentProfile?.address
  }

  get formattedPaymentMethodData(): PaymentMethodPayload {
    return this.formatPaymentMethodData()
  }

  get isCreditCardPaymentAllowed(): boolean {
    return isPaymentMethodAllowed(this.checkoutPaymentMethods, PaymentMethodTypeId.CreditCard)
  }

  get isCheckPaymentAllowed(): boolean {
    return isPaymentMethodAllowed(this.checkoutPaymentMethods, PaymentMethodTypeId.Check)
  }

  get isAchPaymentAllowed(): boolean {
    return isPaymentMethodAllowed(this.checkoutPaymentMethods, PaymentMethodTypeId.ACH)
  }

  get isPaymentMethodSelectionNeeded(): boolean {
    return (
      this.isCreditCardPaymentAllowed &&
      (this.isAchPaymentAllowed || this.isCheckPaymentAllowed)
    )
  }

  get activePaymentMethod(): PaymentMethodTypeKey {
    if (this.isPaymentMethodSelectionNeeded) {
      return paymentMethodTypeIdToKey[this.selectedPaymentMethodTypeId]
    }
    if (this.isCreditCardPaymentAllowed) {
      return PaymentMethodTypeKey.CreditCard
    }
    if (this.isAchPaymentAllowed) {
      return PaymentMethodTypeKey.ACH
    }
    if (this.isCheckPaymentAllowed) {
      return PaymentMethodTypeKey.Check
    }
    return null
  }

  get showCreditCardInput(): boolean {
    return this.activePaymentMethod === PaymentMethodTypeKey.CreditCard
  }

  get showCheckWireInput(): boolean {
    return this.activePaymentMethod === PaymentMethodTypeKey.Check ||
      this.activePaymentMethod === PaymentMethodTypeKey.ACH
  }

  created(): void {
    if (this.disputedCheckout) {
      this.showCardForm = true
      this.getDisputedCheckoutData()
    }
  }

  async getDisputedCheckoutData(): Promise<void> {
    let data: any = await quotes.checkoutPaymentMetaById(this.quoteId)
    const isRelevantDataPresent = data?.cardHolderName || data?.cardholderName
    if (isRelevantDataPresent) {
      const metaCardData = data
      delete metaCardData.cardNumber
      this.newCard = metaCardData
      return
    }

    const response = await quotes.getAuthPaymentsById(this.quoteId)
    const result = response?.data?.authPayments || []

    const authPaymentOnCheckout = result.reduce(
      (earliestPayment, currentPayment) =>
        currentPayment.createdOn < earliestPayment.createdOn
          ? currentPayment
          : earliestPayment
    )

    data = JSON.parse(authPaymentOnCheckout?.meta)
    delete data.cardNumber
    this.newCard = data
  }

  validate(): boolean {
    if (this.showCheckWireInput) {
      const checkAndWireForm = this.$refs['check-and-wire-form'] as any
      return checkAndWireForm.validate();
    }

    const creditCardForm = this.$refs['credit-card-form'] as any
    return creditCardForm.validate();
  }

  formatPaymentMethodData(): PaymentMethodPayload {
    const paymentProfile: any = this.currentPaymentProfile
    return {
      activeMethod: this.activePaymentMethod,
      customerPaymentProfileId:
        paymentProfile?.customerPaymentProfileId || null,
      saveForFuturePayments:
        (this.showCardForm && this.newCard?.saveForFuturePayments) || false,
      name: paymentProfile?.name || paymentProfile?.accountHolderName || '',
      cardholderName:
        paymentProfile?.name || paymentProfile?.accountHolderName || '',
      cardNumber: paymentProfile?.cardNumber || '',
      mask: paymentProfile?.mask || '',
      securityCode: paymentProfile?.securityCode || '',
      exp_date: paymentProfile?.exp_date || paymentProfile?.expiration || '',
      expirationMonth: paymentProfile?.expirationMonth || '',
      expirationYear: paymentProfile?.expirationYear || '',
      type_label: paymentProfile?.type_label || paymentProfile?.typeLabel || '',
      address: {
        name: this.address?.name || '',
        street1: this.address?.street1 || '',
        street2: this.address?.street2 || '',
        city: this.address?.city || '',
        state: this.address?.state || '',
        postalCode: this.address?.postalCode || '',
        lat: this.address?.lat || '',
        lng: this.address?.lng || '',
        title: null,
        time_zone: this.address?.timeZone || this.address?.time_zone || '',
        country: this.address?.country || '',
      },
    }
  }
}
</script>
