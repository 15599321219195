<template>
  <div class="max-w-640 margin-x-auto padding-t-3">
    <div class="d-flex flex-column">
      <template v-if="isLoading">
        <SkeletonBox
          v-for="(_, index) in 2"
          :key="`vehicle-pricing-skeleton-${index}`"
          class="border-radius-2x padding-a-3 h-128 margin-b-4"
        />
      </template>
      <template v-else>
        <VehicleOptionCard
          v-for="(quoteDetail, quoteDetailIndex) in sortedQuoteDetails"
          :key="`vehicle-pricing-card-${
            quoteDetail ? quoteDetail.quoteId : 'null'
          }-${quoteDetailIndex}`"
          :selected="quoteDetail.quoteId === selfServe.selectedQuoteId"
          :quote="quoteDetail"
          :vehicles="getWizardVehicles(quoteDetail)"
          @select="selfServe.setSelectedQuoteId(quoteDetail.quoteId)"
          class="margin-b-4"
        />
      </template>
    </div>
    <v-btn
      id="self-serve-vehicle-pricing-continue"
      color="primary"
      class="w-full d-flex margin-t-8 margin-x-auto"
      :class="{
        'max-w-320': $vuetify.breakpoint.smAndUp,
      }"
      :disabled="disabled"
      :loading="submitting"
      @click="submitOrContinue"
    >
      Continue
      <CUIcon view-box="0 0 24 24" class="margin-l-2">
        arrow_right
      </CUIcon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator'
import VehicleOptionCard from '@/components/VehicleOptionCard.vue'
import SkeletonBox from '@/components/SkeletonBox.vue'
import { QuoteDetailV2, TripVehicle } from '@/models/dto'
import selfServe from '@/store/modules/selfServe'
import auth from '@/store/modules/auth'
import { isQuoteSelfServeAndNeedsManualAttention } from '@/utils/quote'
import { VehicleTypeId } from '@/utils/enum'
import deepClone from '@/utils/deepClone'
import { WizardVehicle } from '@/models/dto'

@Component({ components: { VehicleOptionCard, SkeletonBox } })
export default class SelfServeVehiclePricing extends Vue {
  selfServe = selfServe
  submitting = false
  submitInterval: any = null

  @Watch('areAllQuotesLoaded', { immediate: true })
  areAllQuotesLoadedChanged(): void {
    if (!this.areAllQuotesLoaded) {
      return
    }
    selfServe.autoSelectCheapestQuote()
  }

  getVehicles(quoteDetail: QuoteDetailV2): TripVehicle[] {
    return quoteDetail?.trips?.flatMap((trip) => trip.vehicles) || []
  }

  getWizardVehicles(quoteDetail: QuoteDetailV2): WizardVehicle[] {
    // Currently we can assume self-serve quotes consist of either a single trip or multiple trips
    // (that were split from one) where the passenger and vehicle counts are consistent
    // across all trips. Therefore, we are using the vehicles from the first trip only.
    // If this assumption changes, revisit this logic.
    const vehicles = quoteDetail?.trips?.[0]?.vehicles ?? []
    return vehicles.map(({ quantity, vehicleType }) => ({
      quantity,
      vehicleTypeKey: vehicleType?.key
    }))
  }

  isCharterBusOnly(quoteDetail: QuoteDetailV2): boolean {
    return this.getVehicles(quoteDetail).every(
      (v) => v.vehicleTypeId === VehicleTypeId.CharterBus
    )
  }

  isMiniBusOnly(quoteDetail: QuoteDetailV2): boolean {
    return this.getVehicles(quoteDetail).every(
      (v) => v.vehicleTypeId === VehicleTypeId.MiniBus
    )
  }

  isSprinterOnly(quoteDetail: QuoteDetailV2): boolean {
    return this.getVehicles(quoteDetail).every(
      (v) => v.vehicleTypeId === VehicleTypeId.Sprinter
    )
  }

  get quoteDetails(): QuoteDetailV2[] {
    return selfServe.quoteDetails
  }

  get isLoading(): boolean {
    return !this.quoteDetails?.length
  }

  get sortedQuoteDetails(): QuoteDetailV2[] {
    return deepClone(this.quoteDetails).sort((a, b) => {
      if (this.isCharterBusOnly(a)) {
        return -1
      }
      if (this.isCharterBusOnly(b)) {
        return 1
      }

      if (this.isMiniBusOnly(a)) {
        return -1
      }
      if (this.isMiniBusOnly(b)) {
        return 1
      }

      if (this.isSprinterOnly(a)) {
        return 1
      }
      if (this.isSprinterOnly(b)) {
        return -1
      }

      return 0
    })
  }

  get optionsMaxVehicleCount(): number {
    if (this.isLoading) {
      return null
    }
    return Math.max(
      ...this.quoteDetails.map((quoteDetail) =>
        this.getVehicles(quoteDetail).reduce(
          (acc, vehicle) => acc + vehicle.quantity,
          0
        )
      )
    )
  }

  get disabled(): boolean {
    return !selfServe?.selectedQuoteId
  }

  get quotesWithPricing(): QuoteDetailV2[] {
    return this.quoteDetails?.filter(
      (quoteDetail) => quoteDetail?.bids?.[0]?.totalAmount
    )
  }

  get expectedQuotesWithPricing(): QuoteDetailV2[] {
    const expectedQuotesWithPricing = this.quoteDetails.filter(
      (quoteDetail) => !isQuoteSelfServeAndNeedsManualAttention(quoteDetail)
    )
    return expectedQuotesWithPricing
  }

  get areAllQuotesLoaded(): boolean {
    if (this.isLoading) {
      return false
    }
    return (
      this.quotesWithPricing?.length >= this.expectedQuotesWithPricing?.length
    )
  }

  submitOrContinue(): void {
    // For vehicle types that don't offer amenities we want to skip the amenities page,
    // create the quote, and go straight to checkout
    const isSprinterOnly = this.isSprinterOnly(selfServe.selectedQuoteDetail)
    if (!isSprinterOnly) {
      this.trackVehicleTypeSubmission()
      selfServe.nextStep()
      return
    }

    try {
      this.submitting = true
      this.trackVehicleTypeSubmission()
      selfServe.submitSelfServeQuote()
      this.pollForQuoteDetail()
    } catch (err) {
      console.error(err)
      this.submitting = false
    }
  }

  pollForQuoteDetail() {
    this.submitInterval = setInterval(async () => {
      await selfServe.getQuoteDetailAndRedirect()
    }, 1000)
  }

  mounted() {
    this.tryFetchQuoteDetails()
    selfServe.getAvailableAmenities()
    this.trackViewVehicleTypeOptions()
  }

  beforeDestroy() {
    clearInterval(this.submitInterval)
  }

  async tryFetchQuoteDetails() {
    if (this.areAllQuotesLoaded) {
      return
    }

    await selfServe.fetchQuoteDetails()
    // Sleep for a second before retrying
    setTimeout(() => this.tryFetchQuoteDetails(), 1000)
  }

  trackVehicleTypeSubmission(): void {
    this.$ga4.trackEvent('sb_vehicle_type', {
      isElite: !!auth?.customer?.isElite,
      isAuth0: this.$auth0.isInitialized,
      hasBookedBefore: auth?.customer?.convertedQuoteCount > 0,
      isLoggedIn: true,
    })
  }

  trackViewVehicleTypeOptions(): void {
    if (auth.customer != null) {
      this.$ga4.trackEvent('sb_view_options', {
        isElite: !!auth.customer.isElite,
        isAuth0: this.$auth0.isInitialized,
        hasBookedBefore: auth?.customer?.convertedQuoteCount > 0,
        isLoggedIn: true,
      })
    } else {
      this.$ga4.trackEvent('sb_view_options', {
        isElite: false,
        isAuth0: this.$auth0.isInitialized,
        hasBookedBefore: false,
        isLoggedIn: false,
      })
    }
  }
}
</script>
