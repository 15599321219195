<template>
  <v-row class="padding-t-8 padding-b-2 text-center" justify="center" dense>
    <v-col cols="8" class="padding-b-2">
      <v-icon size="70">
        {{ icon }}
      </v-icon>
    </v-col>
    <v-col cols="8">
      <h2><slot name="header" /></h2>
    </v-col>
    <v-col cols="8">
      <p>
        <slot name="content" />
      </p>
    </v-col>
    <v-col cols="8" class="padding-t-7">
      <v-btn
        id="support-dialog-button-back-to-site"
        dark
        primary
        block
        class="primary w-one-half"
        @click="$emit('click')"
      >
        <slot name="button" />
        <span v-if="!$slots.button">Back to site</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class InfoDialogLayout extends Vue {
  @Prop({ type: String, default: '' }) readonly icon: string
}
</script>
