<template>
  <article>
    <h3>
      {{ $t('quoteDetail.operatorInfo.BUS_RULES') }}
    </h3>
    <p v-if="rules && rules.length > 0">
      <v-row dense>
        <v-col
          v-for="(rule, ruleIndex) in rules"
          :key="`${rule.busRuleTypeId}-${ruleIndex}`"
          cols="6"
          class="min-w-300"
        >
          <v-row align="center" justify="start" no-gutters>
            <v-col cols="auto">
              <v-icon small left :style="{ top: '-1px' }">
                {{ convertRuleKeyToIcon(rule.busRuleType.icon) }}
              </v-icon>
            </v-col>
            <v-col cols="auto">
              {{ rule.busRuleType.label }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </p>
    <p v-else>
      {{ $t('quoteDetail.operatorInfo.NO_INFORMATION_PROVIDED') }}
    </p>
  </article>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { BusRule, OperatorDetailCompany } from '../models/dto'

@Component({})
export default class ProviderInfoRules extends Vue {
  @Prop({ type: Object, required: true })
  readonly company!: OperatorDetailCompany

  convertRuleKeyToIcon(/* key */): string {
    return 'not_interested'
  }

  get rules(): BusRule[] {
    return this.company.companyBusRules.filter((rule) => rule.supported)
  }
}
</script>
