<template>
  <div class="d-flex margin-x-n1">
    <div
      v-for="(step, stepIndex) of stepCount"
      :key="`wizard-status-tracker-${stepIndex}`"
      class="d-flex h-8 margin-x-1"
      :style="{ width: `${100 / stepCount}%` }"
      :class="{
        'background-gray-mid-light': stepIndex > currentStepIndex,
        'background-primary': stepIndex < currentStepIndex,
        pulse: stepIndex === currentStepIndex,
      }"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { WizardStore } from '@/store/WizardStore'
import { WizardStep } from '@/models/Wizard'

@Component
export default class WizardProgressTracker extends Vue {
  @Prop({ type: Object, required: true }) readonly wizardStore!: WizardStore<WizardStep>

  get steps(): WizardStep[] {
    return this.wizardStore.steps
  }

  get currentStep(): WizardStep {
    return this.wizardStore.step
  }

  get stepCount(): number {
    return this.wizardStore.steps.reduce((count, step) => {
      return step.excludeFromStepCount ? count : count + 1
    }, 0)
  }

  get currentStepIndex(): number {
    return this.wizardStore.currentStepIndex
  }

}
</script>

<style lang="scss" scoped>
@import '@/scss/colors';

.pulse {
  animation: loader 3s ease-in-out infinite;
  @keyframes loader {
    0% {
      background: $gray-mid-light;
    }
    50% {
      background: $primary;
    }
    100% {
      background: $gray-mid-light;
    }
  }
}
</style>
