<template>
  <InfoModal
    :value="value"
    :modal-detail="tripModificationModalDetail"
    max-width="860px"
    @input="$emit('input', $event)"
  >
    <template #activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>

    <template #text>
      <div
        v-if="isWithin72HoursOfPickup"
        class="d-flex flex-column"
      >
        <div class="margin-t-4">
          To update your itinerary within 72 hours of pickup, please call us.
        </div>
        <div>
          Changes are not guaranteed.
        </div>
        <div
          class="d-flex w-full justify-center"
        >
          <v-btn
            :href="`tel:${supportNumber}`"
            class="margin-t-8 padding-x-12"
            color="primary"
          >
            <CUIcon
              size="24"
              color="white"
              class="margin-r-3"
              view-box="0 0 24 24"
            >
              phone
            </CUIcon>
            {{ formattedSupportNumber }}
          </v-btn>
        </div>
      </div>

      <div
        v-else
        class="d-flex flex-column"
      >
        <div class="font-bold">
          Changes may impact pricing and availability.
        </div>
        <div class="margin-t-4">
          All requests require review and may require operator acceptance. Requests made less than 72 hours prior to trip start date may not be granted.
        </div>
        <div
          class="d-flex w-full justify-center"
        >
          <v-btn
            class="margin-t-8 padding-x-12"
            color="primary"
            @click="handleClickContinue"
          >
            <div class="text-white font-bold padding-x-12 margin-y-0">
              Continue
            </div>
          </v-btn>
        </div>
      </div>
    </template>
  </InfoModal>
</template>

<script lang="ts">
import { Vue, Component, Prop, InjectReactive } from 'vue-property-decorator'
import InfoModal from '@/components/InfoModal.vue'
import { tripModificationStartModalDetail, tripModificationWithin72HoursModalDetail } from '@/utils/infoModals'
import tripModification from '@/store/modules/tripModification'
import { InfoModalDetail } from '@/models/InfoModalDetail'
import { getSupportNumber, phoneFormatFilter } from '@/utils/phone'
import { EventBus } from '@/utils/eventBus'
import auth from '@/store/modules/auth'

@Component({
  components: {
    InfoModal
  },
})
export default class TripModificationStartDialog extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value!: boolean

  @InjectReactive('isWithin72HoursOfPickup') isWithin72HoursOfPickup

  mounted(): void {
    EventBus.$on('open-trip-modification-dialog', () => this.$emit('input', true))
    EventBus.$on('close-trip-modification-dialog', () => this.$emit('input', false))
  }

  async handleClickContinue(): Promise<void> {
    tripModification.setIsOpen(true)
    tripModification.setForwardButtonDisabled(false)
    this.$emit('input', false)
  }

  get tripModificationModalDetail(): InfoModalDetail {
    return this.isWithin72HoursOfPickup ?
      tripModificationWithin72HoursModalDetail :
      tripModificationStartModalDetail
  }

  get supportNumber(): string {
    const isElite = !!auth.customer?.isElite
    return getSupportNumber(isElite)
  }

  get formattedSupportNumber(): string {
    return phoneFormatFilter(this.supportNumber)
  }

  beforeDestroy(): void {
    EventBus.$off('open-trip-modification-dialog')
    EventBus.$off('close-trip-modification-dialog')
  }
}
</script>
