<template>
  <div id="sales-bot-map-markers">
    <GmapMarker
      v-for="(marker, markerIndex) in markers"
      :key="markerIndex"
      :position="marker.position"
      :icon="marker.icon"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue"
import { generateSalesBotStopIconSVG } from "@/utils/icon"
import { gmapApi } from "vue2-google-maps"
import { LatLng } from "@/utils/google"
import { useTheme } from "@/composables/useTheme"

const { themes } = useTheme()

interface Marker {
  position: LatLng
  icon: google.maps.Icon
}

const ICON_SIZE = 16

const props = defineProps({
  waypoints: {
    type: Array as PropType<LatLng[]>,
    required: true,
  },
})

const google = computed(() => gmapApi())
const standardStopIcon = computed(() => createStopIcon(false))
const lastStopIcon = computed(() => createStopIcon(true))

/**
 * Computed property that generates an array of marker objects for each waypoint.
 * Each marker object contains:
 * - `position`: The position of the waypoint.
 * - `icon`: The icon for the marker, determined by whether the waypoint is the last one in the list.
 *
 * @returns {Marker[]} Array of marker objects.
 */
const markers = computed<Marker[]>(() => {
  if (!google.value) {
    return []
  }
  return props.waypoints.map((position, index) => ({
    position,
    icon: index === props.waypoints.length - 1 ? lastStopIcon.value : standardStopIcon.value,
  }))
})

/**
 * Creates a Google Maps icon for a stop.
 *
 * @param {boolean} isLastStop - Indicates if the stop is the last one.
 * @returns {google.maps.Icon} - The Google Maps icon object.
 */
const createStopIcon = (isLastStop: boolean): google.maps.Icon => {
  const iconColor = themes?.value?.light?.primary || ''
  const svgString = generateSalesBotStopIconSVG(iconColor, isLastStop)
  const anchorPoint = ICON_SIZE / 2

  const url = "data:image/svg+xml," + encodeURIComponent(svgString)
  const scaledSize = new google.value.maps.Size(ICON_SIZE, ICON_SIZE)
  const anchor = new google.value.maps.Point(anchorPoint, anchorPoint)

  return { url, anchor, scaledSize }
}
</script>
