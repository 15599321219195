<template>
  <CUCard :full-width="fullWidth">
    <CheckoutTripDetails
      :trip="trip"
      :hide-expand="hideExpand"
      :expanded="computedExpanded"
      @update:expanded="expanded = $event"
      @to-self-serve="$emit('to-self-serve')"
    />
    <v-expand-transition>
      <CheckoutTripItinerary v-if="computedExpanded" :trip="trip" />
    </v-expand-transition>
  </CUCard>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import CheckoutTripDetails from '@/components/CheckoutTripDetails.vue'
import CheckoutTripItinerary from '@/components/CheckoutTripItinerary.vue'
import { Trip } from '../models/dto'

@Component({
  components: { CheckoutTripDetails, CheckoutTripItinerary },
})
export default class CheckoutTripDetailsAndItinerary extends Vue {
  @Prop({ type: Object, required: true }) readonly trip!: Trip
  @Prop({ type: Boolean, required: false, default: false })
  readonly hideExpand!: boolean
  @Prop({ type: Boolean, required: false, default: false })
  readonly fullWidth!: boolean

  expanded = false

  get computedExpanded(): boolean {
    return this.hideExpand || this.expanded
  }
}
</script>
