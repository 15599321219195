<template>
  <CUModal :value="open" persistant @input="$emit('update:open')">
    <template #title>
      {{ '' }}
    </template>
    <template #text>
      <div class="d-flex w-full align-center justify-center flex-column">
        <template v-if="!isPickupAlreadyReviewed">
          <PickupSatisfactionSelector
            v-model="pickupPositive"
            :style="{ transform: 'scale(1.5)' }"
            class="margin-t-6 margin-b-1"
          />
          <p v-if="response" class="font-medium text-center">
            {{ response }}
          </p>
          <v-textarea
            v-if="isNegative"
            v-model="pickupReview"
            autocomplete="off"
            outlined
            height="100"
            no-resize
            class="margin-t-4 w-full"
            hide-details
            placeholder="Tell us more about how your pickup went."
          />
        </template>
        <template v-else>
          <p>
            Hey! It looks like you have already reviewed your pickup. If you'd
            like to edit your review, you can do so in the review section.
          </p>
        </template>
      </div>
    </template>
    <template v-if="!isPickupAlreadyReviewed" #actions>
      <v-spacer />
      <v-btn
        color="primary"
        small
        :disabled="isNull || (isNegative && !pickupReview)"
        @click="save"
      >
        Save
      </v-btn>
    </template>
  </CUModal>
</template>
<script lang="ts">
import { ReservationReview } from '@/models/dto'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import PickupSatisfactionSelector from '@/components/PickupSatisfactionSelector.vue'
import deepClone from '@/utils/deepClone'

@Component({ components: { PickupSatisfactionSelector } })
export default class PickupReviewModal extends Vue {
  @Prop({ type: Object, required: true }) readonly review: ReservationReview
  @Prop({ type: Boolean, required: true }) readonly open: boolean
  @Prop({ type: Boolean, required: true })
  readonly isPickupAlreadyReviewed: boolean

  pickupReview = ''
  pickupPositive = null

  get isNull(): boolean {
    return this.pickupPositive === null
  }

  get isNegative(): boolean {
    return this.pickupPositive === false
  }

  get isPositive(): boolean {
    return !!this.pickupPositive
  }

  get response(): string {
    if (this.isNegative) {
      return "We're sorry to hear your pickup wasn't great. Please tell us what happened."
    }
    if (this.isPositive) {
      return 'Thanks for your feedback! We hope you enjoy the rest of your trip.'
    }
    return ''
  }

  @Watch('review.pickupPositive', { immediate: true })
  onPickupPositiveChange(pickupPositive: boolean): void {
    this.pickupPositive = pickupPositive
  }

  save(): void {
    const review = deepClone(this.review)
    review.pickupReview = this.pickupReview
    review.pickupPositive = this.pickupPositive
    this.$emit('update:review', review)
    this.close()
  }

  close(): void {
    this.$emit('update:open', false)
  }
}
</script>
