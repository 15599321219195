<template>
  <v-row align="stretch" justify="center" class="margin-b-11" no-gutters>
    <v-col cols="7" class="text-center">
      <v-img :src="img" class="margin-t-6 margin-b-3" />
      <div class="font-40  font-medium">
        <slot name="header" />
        <span v-if="!$slots.header">Oops! We took a wrong turn.</span>
      </div>
      <v-col class="padding-b-5 padding-t-2">
        <slot name="content" />
        <span v-if="!$slots.content">
          This page does not exist or some other error has occurred.
          <br />
          Please check your url or return to the
          <router-link :to="{ name: 'home' }">home page</router-link>
          .
        </span>
      </v-col>
      <v-col v-if="$slots['button']">
        <v-btn dark primary small class="primary">
          <slot name="button" />
        </v-btn>
      </v-col>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class NotFoundLayout extends Vue {
  @Prop({ type: String, default: require('@/assets/images/404.png') })
  readonly img!: string
}
</script>
