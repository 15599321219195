<template>
  <v-row class="w-full address-picker" no-gutters>
    <v-col cols="12" class="padding-y-0">
      <label for="add-payment-profile-dialog-text-address">
        {{ computedLabel }}
        <span v-if="required" class="text-error">*</span>
      </label>
      <AutoCompleteAddress
        id="add-payment-profile-dialog-text-address"
        ref="addPaymentAddress"
        :initial-search="address.initialSearch"
        :rules="[
          (address.initialSearch && !!address.street1) ||
            $t('addressPicker.FORM.STREET1.RULES.SELECT'),
          !!address.street1 || $t('addressPicker.FORM.STREET1.RULES.REQUIRED'),
        ]"
        manual-control
        clearable
        clear-icon="replay"
        :tab-index="5"
        is-split-up-address
        validate-on-blur
        :disputed-checkout="disputedCheckout"
        :is-payment-form="isPaymentForm"
        @place-selected="selectAddress"
        @place-cleared="clearAddress"
      />
    </v-col>
    <v-expand-transition>
      <v-row v-show="addressSelected" class="margin-b-1 padding-y-0">
        <v-col cols="12" class="padding-y-0">
          <label for="suiteNumber">
            {{ $t('addressPicker.FORM.STREET2.LABEL') }}
          </label>
          <v-text-field
            id="suiteNumber"
            v-model="address.street2"
            :class="{ 'finix': !disputedCheckout && isPaymentForm }"
            :name="uuidv4()"
            autocomplete="off"
            outlined
            tabindex="6"
          />
        </v-col>

        <v-col cols="12" sm="6" class="padding-y-0">
          <label for="city">
            {{ $t('addressPicker.FORM.CITY.LABEL') }}
            <span v-if="required" class="text-error">*</span>
          </label>
          <v-text-field
            id="city"
            v-model="address.city"
            :class="{ 'finix': !disputedCheckout && isPaymentForm }"
            :name="uuidv4()"
            :rules="[
              (v) => !!v || $t('addressPicker.FORM.CITY.RULES.REQUIRED'),
            ]"
            autocomplete="off"
            outlined
            tabindex="7"
          />
        </v-col>

        <v-col
          cols="6"
          sm="3"
          class="padding-y-0"
          :class="{
            'padding-x-1': $vuetify.breakpoint.smAndUp,
          }"
        >
          <label for="state">
            {{ stateLabel }}
            <span v-if="required" class="text-error">*</span>
          </label>
          <v-select
            v-if="isUSAddress || isCanadaAddress"
            id="state"
            v-model="address.state"
            :class="{ 'finix': !disputedCheckout && isPaymentForm }"
            :items="stateItems"
            :rules="[(v) => !!v || stateErrorCopy]"
            item-text="value"
            item-value="value"
            autocomplete="off"
            outlined
            tabindex="8"
          />
          <v-text-field
            v-else
            id="state"
            v-model="address.state"
            :class="{ 'finix': !disputedCheckout && isPaymentForm }"
            :name="uuidv4()"
            :rules="[(v) => !!v || stateErrorCopy]"
            autocomplete="off"
            outlined
            tabindex="8"
          />
        </v-col>
        <v-col cols="6" sm="3" class="padding-y-0">
          <label for="postalCode">
            {{ postalCodeLabel }}
            <span v-if="required" class="text-error">*</span>
          </label>
          <v-text-field
            id="postalCode"
            v-model="address.postalCode"
            :class="{ 'finix': !disputedCheckout && isPaymentForm }"
            :name="uuidv4()"
            :rules="[(v) => !!v || $t('addressPicker.FORM.ZIP.RULES.REQUIRED')]"
            autocomplete="off"
            outlined
            tabindex="9"
          />
        </v-col>
      </v-row>
    </v-expand-transition>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import AutoCompleteAddress from '@/components/AutoCompleteAddress.vue'
import { getStates, getProvinces, SelectOption } from "@/utils/states";
import { v4 } from 'uuid'
import { AddressSearch } from '@/models/AddressSearch'
import { TranslateResult } from 'vue-i18n'

@Component({
  components: {
    AutoCompleteAddress,
  },
})
export default class AddressPicker extends Vue {
  @Prop({ type: Object, default: () => {} }) readonly value: any
  @Prop({ type: String, default: '' }) readonly label: string
  @Prop({ type: Boolean }) readonly disputedCheckout: boolean
  @Prop({ type: Boolean, default: false }) readonly isPaymentForm: boolean
  @Prop({ type: Boolean, required: false, default: false }) readonly required: boolean

  @Watch('address', { deep: true })
  addressChanged(): void {
    this.$emit('input', this.address)
  }

  @Watch('value', { deep: true })
  valueChanged(): void {
    if (this.disputedCheckout && !this.valueWatcherFired) {
      this.valueWatcherFired = true
      this.selectAddress({ place: this.value })
    }
  }

  valueWatcherFired = false
  addressSelected = false
  address: AddressSearch = {
    street1: null,
    street2: null,
    city: null,
    state: null,
    country: null,
    postalCode: null,
    completeAddress: null,
    addressName: null,
    lat: null,
    lng: null,
    timeZone: null,
  }

  get computedLabel(): TranslateResult {
    return this.addressSelected
      ? this.$t('addressPicker.FORM.STREET1.LABEL')
      : this.label || this.$t('addressPicker.FORM.STREET1.SEARCH_AND_SELECT')
  }

  get isUSAddress(): boolean {
    return this.address.country === 'US'
  }

  get isCanadaAddress(): boolean {
    return this.address.country === 'CA'
  }

  get stateLabel(): TranslateResult {
    if (this.isUSAddress) {
      return this.$t('addressPicker.FORM.STATE.LABEL')
    }
    if (this.isCanadaAddress) {
      return this.$t('addressPicker.FORM.PROVINCE.LABEL')
    }
    return this.$t('addressPicker.FORM.REGION.LABEL')
  }

  get postalCodeLabel(): TranslateResult {
    return this.isUSAddress
      ? this.$t('addressPicker.FORM.ZIP.LABEL')
      : this.$t('addressPicker.FORM.POSTAL.LABEL')
  }

  get stateItems(): Array<SelectOption> {
    if (this.isUSAddress) {
      return getStates()
    }
    if (this.isCanadaAddress) {
      return getProvinces()
    }
    return []
  }

  get stateErrorCopy(): TranslateResult {
    if (this.isUSAddress) {
      return this.$t('addressPicker.FORM.STATE.RULES.REQUIRED')
    }
    if (this.isCanadaAddress) {
      return this.$t('addressPicker.FORM.PROVINCE.RULES.REQUIRED')
    }
    return this.$t('addressPicker.FORM.REGION.RULES.REQUIRED')
  }

  selectAddress(address): void {
    const place = address.place
    this.address = {
      street1: place.street1,
      street2: '',
      city: place.city,
      state: place.state,
      country: place.country,
      postalCode: place.postalCode,
      completeAddress: place.completeAddress,
      addressName: place.addressName,
      lat: place.lat,
      lng: place.lng,
      timeZone: place.timeZone,
      initialSearch: place.street1,
    }
    this.addressSelected = true
  }

  clearAddress(): void {
    this.address = {
      street1: null,
      street2: null,
      city: null,
      state: null,
      country: null,
      postalCode: null,
      completeAddress: null,
      addressName: null,
      lat: null,
      lng: null,
      timeZone: null,
      initialSearch: null,
    }
    this.addressSelected = false
  }

  uuidv4(): any {
    return v4()
  }
}
</script>
