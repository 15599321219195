<template>
  <div class="d-flex flex-column align-center margin-b-12">
    <div class="max-w-700 w-full d-flex flex-column">
      <SelfServeDefaultAmenities v-if="hasCharterBus" />
      <span
        :class="`font-medium ${!hasCharterBus ? 'margin-t-3' : 'margin-t-7'}`"
      >
        Add some extras
      </span>
      <v-row
        id="self-serve-amenities-row"
        class="text-primary w-full d-flex flex-wrap"
        :class="{
          'justify-start': !displayThreeAmenities,
          'justify-space-between': displayThreeAmenities,
          'margin-l-0': $vuetify.breakpoint.smAndDown,
        }"
        cols="12"
      >
        <v-col
          v-for="(amenity, amenityIndex) in availableAmenities"
          :key="`trip-amenity-${amenityIndex}`"
          :class="{
            'min-h-180 padding-x-0': $vuetify.breakpoint.smAndDown,
            'min-h-220': $vuetify.breakpoint.mdAndUp,
          }"
          :cols="`${$vuetify.breakpoint.mdAndUp ? 4 : 12}`"
        >
          <AmenityCard
            :amenity="amenity"
            :is-vehicle-combination="isVehicleCombination"
          />
        </v-col>
      </v-row>
      <span
        v-if="isVehicleCombination"
        id="self-serve-amenities-vehicle-combination-text"
        class="text-gray-light font-14 margin-t-8"
      >
        *Available for Charter Buses only.
      </span>
      <span class="w-full d-flex justify-center margin-t-12">
        <v-btn
          id="self-serve-amenities-submit-button"
          color="primary"
          class="w-full d-flex"
          :class="{
            'max-w-320': $vuetify.breakpoint.smAndUp,
          }"
          :loading="submitting"
          @click="submit"
        >
          Get Your Quote
          <CUIcon view-box="0 0 24 24" class="margin-l-2">
            arrow_right
          </CUIcon>
        </v-btn>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import SelfServeDefaultAmenities from './SelfServeDefaultAmenities.vue'
import AmenityCard from './AmenityCard.vue'
import { AmenityTypeId, TripEventTypeId, VehicleTypeId } from '@/utils/enum'
import { AmenityType } from '@/models/dto'
import selfServe from '@/store/modules/selfServe'
import { getAvailableAmenityTypesByVehicleTypeId } from '@/utils/amenity'

@Component({ components: { SelfServeDefaultAmenities, AmenityCard } })
export default class SelfServeAmenities extends Vue {
  submitting = false
  submitInterval: any = null

  get availableAmenities(): AmenityType[] {
    let availableAmenities = getAvailableAmenityTypesByVehicleTypeId(
      selfServe.availableAmenities,
      selfServe.tripVehicles[0].vehicleTypeId
    )

    const { tripEventTypeId } = selfServe.quote.trips[selfServe.currentTripIndex]
    if (tripEventTypeId === TripEventTypeId.K12) {
      availableAmenities = availableAmenities.filter(
        (amenity) => amenity.id !== AmenityTypeId.AlcoholConsumption
      )
    }

    return availableAmenities
  }

  get vehicleTypeCount(): number {
    const uniqueVehicles = new Set()
    const vehicles = selfServe.tripVehicles
    for (const vehicle of vehicles) {
      uniqueVehicles.add(vehicle.vehicleTypeId)
    }
    return uniqueVehicles.size
  }

  get hasCharterBus(): boolean {
    return selfServe.tripVehicles.some(
      (vehicle) => vehicle.vehicleTypeId === VehicleTypeId.CharterBus
    )
  }

  get isVehicleCombination(): boolean {
    return this.vehicleTypeCount > 1
  }

  get displayThreeAmenities(): boolean {
    return this.availableAmenities?.length === 3
  }

  beforeDestroy() {
    clearInterval(this.submitInterval)
  }

  async submit(): Promise<void> {
    try {
      this.submitting = true
      await selfServe.submitSelfServeQuote()
      this.pollForQuoteDetail()
    } catch (err) {
      console.error(err)
      this.submitting = false
    }
  }

  pollForQuoteDetail() {
    this.submitInterval = setInterval(async () => {
      await selfServe.getQuoteDetailAndRedirect()
    }, 1000)
  }
}
</script>
