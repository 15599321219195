<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex justify-center"
      :class="{
        'flex-column': $vuetify.breakpoint.xs,
      }"
    >
      <div
        :class="{
          'max-w-188 w-full margin-r-3': $vuetify.breakpoint.smAndUp,
          'w-full': $vuetify.breakpoint.xs,
        }"
      >
        <label for="passengerCount">Number of passengers</label>
        <v-text-field
          id="self-serve-quote-form-passenger-count-input"
          :value="passengers"
          hide-details
          outlined
          required
          tabindex="1"
          type="number"
          placeholder="0"
          @input="setPassengerCount($event)"
        >
          <template slot="prepend-inner">
            <CUIcon view-box="0 0 24 24">
              account_group
            </CUIcon>
          </template>
        </v-text-field>
      </div>
      <div
        :class="{
          'max-w-400 w-full': $vuetify.breakpoint.smAndUp,
          'w-full margin-t-3': $vuetify.breakpoint.xs,
        }"
      >
        <label for="passengerCount">Event Type</label>
        <v-select
          id="self-serve-quote-form-event-type-select"
          :value="tripEventTypeId"
          :items="tripEventTypes"
          append-icon="keyboard_arrow_down"
          hide-details=""
          item-text="label"
          item-value="tripEventTypeId"
          outlined
          placeholder="Select an event type"
          required
          tabindex="2"
          @change="setTripEventTypeId($event)"
        />
      </div>
    </div>
    <div class="d-flex justify-center">
      <div class="max-w-600 w-full margin-t-3">
        <label for="tripName">Give your trip a name</label>
        <v-text-field
          id="self-serve-quote-form-trip-name-input"
          ref="trip-name-input"
          :value="tripName"
          outlined
          hint="Optional"
          persistent-hint
          placeholder="e.g. Greatest Wedding of All, Game of the Year, or Class of 2015 Reunion"
          tabindex="3"
          counter="50"
          type="text"
          :rules="[
            (v) =>
              (v || '').length <= 50 ||
              'Trip name must be 50 characters or less',
          ]"
          @update:error="(e) => (errorMessage = e)"
          @input="setTripName($event)"
        />
      </div>
    </div>
    <div class="d-flex justify-center margin-t-3">
      <SelfServeSpecialTripRequirementsSelection />
    </div>
    <div class="d-flex justify-center align-center margin-t-6">
      <v-btn
        color="primary"
        class="w-full d-flex"
        :class="{
          'max-w-320': $vuetify.breakpoint.smAndUp,
        }"
        :disabled="disabled"
        :loading="submitting"
        @click="goToNextStep"
      >
        {{ buttonText }}
        <CUIcon view-box="0 0 24 24" class="margin-l-2">
          arrow_right
        </CUIcon>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { TripEventType } from '@/models/dto'
import type from '@/services/type'
import { Vue, Component } from 'vue-property-decorator'
import SelfServeSpecialTripRequirementsSelection from '@/components/SelfServeSpecialTripRequirementsSelection.vue'
import { SalesBotTrip } from '@/classes/SalesBotTrip'
import selfServe from '../store/modules/selfServe'
import auth from '../store/modules/auth'
import selfServeService from '@/services/selfServe'

@Component({
  components: {
    SelfServeSpecialTripRequirementsSelection,
  },
})
export default class SelfServeTripDetails extends Vue {
  tripEventTypes: TripEventType[] = []
  submitting = false
  errorMessage = false

  get disabled(): boolean {
    return !(
      this.passengers % 1 === 0 &&
      this.passengers > 0 &&
      this.tripEventTypeId &&
      !this.errorMessage
    )
  }

  get currentTrip(): SalesBotTrip {
    return selfServe.quote?.trips?.[selfServe.currentTripIndex] || null
  }

  get passengers(): number {
    return this.currentTrip?.passengerCount || null
  }

  get tripEventTypeId(): number {
    return this.currentTrip?.tripEventTypeId || null
  }

  get tripName(): string {
    return this.currentTrip?.routeName || ''
  }

  get buttonText(): string {
    return selfServe.isEditing ? 'Update' : 'Continue'
  }

  async mounted(): Promise<void> {
    await this.getTripEventTypes()
  }

  async getTripEventTypes(): Promise<void> {
    const tripEventResponse = await type.tripEvent({})
    this.tripEventTypes = tripEventResponse.data.resultList
  }

  setPassengerCount(passengerCount: number, tripIndex = 0) {
    selfServe.setTripPassengerCount({ tripIndex, passengerCount })
  }

  setTripEventTypeId(tripEventTypeId: number, tripIndex = 0) {
    selfServe.setTripEventTypeId({ tripIndex, tripEventTypeId })
  }

  setTripName(tripName: string, tripIndex = 0) {
    selfServe.setTripName({ tripIndex, tripName })
  }

  async goToNextStep(): Promise<void> {
    if (auth.isAuthorizedUser) {
      await this.submit()
    }
    this.trackTripDetailsSubmission()
    selfServe.nextStep()
  }

  async submit(): Promise<void> {
    try {
      this.submitting = true
      const payload = selfServe.quote.toObject()
      payload.oldQuoteId = selfServe.quoteId
      const result = await selfServeService.create(payload)
      const quotes = result.data?.data
      const selfServeId = quotes?.[0]?.selfServeId

      selfServe.setSelfServeId(selfServeId)
      const quoteDetailsResponse = await selfServeService.bySelfServeIdV2(
        selfServeId
      )
      const quoteDetails = quoteDetailsResponse.data?.data
      selfServe.setQuoteDetails(quoteDetails)
    } catch (err) {
      console.error(err)
    }
    this.submitting = false
  }

  trackTripDetailsSubmission(): void {
    if (auth.customer != null) {
      this.$ga4.trackEvent('sb_trip_details', {
        isElite: !!auth.customer.isElite,
        isAuth0: this.$auth0.isInitialized,
        hasBookedBefore: auth?.customer?.convertedQuoteCount > 0,
        isLoggedIn: true,
      })
    } else {
      this.$ga4.trackEvent('sb_trip_details', {
        isElite: false,
        isAuth0: this.$auth0.isInitialized,
        hasBookedBefore: false,
        isLoggedIn: false,
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';

::v-deep .v-select {
  .v-input__control {
    .v-input__slot {
      .v-select__slot {
        .v-select__selections {
          padding: 0;
          .v-select__selection {
            padding-left: 14px !important;
          }
        }
        .v-input__append-inner {
          .v-input__icon--append {
            margin-top: 12px;
            margin-right: 3px;
          }
        }
      }
    }
  }
}

::v-deep .v-text-field {
  .v-input__control {
    .v-input__slot {
      .v-input__prepend-inner {
        z-index: 1;
        margin-top: 12px;
        margin-left: 12px;
        .cu-icon {
          color: $gray-text !important;
        }
      }
    }
    .v-text-field__details {
      .v-messages {
        .v-messages__message {
          color: $gray-subtle-text !important;
        }
      }
    }
  }

  * {
    touch-action: manipulation;
  }
}
</style>
