<template>
  <CUModal v-model="dialog" width="500px" @input="$emit('input', $event)">
    <template #activator="{ on }">
      <v-list-item :id="`add-team`" @click="() => {}" v-on="on">
        <v-list-item-title>
          Add Team
        </v-list-item-title>
      </v-list-item>
    </template>
    <template #title>Create New Team</template>
    <template #text>
      <v-form ref="form" v-model="formIsValid">
        <v-row no-gutters>
          <v-col cols="12">
            <label for="team">Team Name</label>
            <v-text-field
              id="new-team-dialog-text-team-name"
              v-model="formData.teamName.value"
              v-bind="formData.teamName"
              required
              validate-on-blur
              outlined
            />
          </v-col>
          <v-col cols="6">
            <label for="firstName">First name</label>
            <v-text-field
              id="new-team-dialog-text-first-name"
              ref="firstName"
              v-model="formData.firstName.value"
              v-bind="formData.firstName"
              :class="{
                'padding-r-3': $vuetify.breakpoint.xs,
                'padding-r-4': $vuetify.breakpoint.smAndUp,
              }"
              required
              validate-on-blur
              outlined
            />
          </v-col>
          <v-col cols="6">
            <div
              :class="{
                'padding-l-3': $vuetify.breakpoint.xs,
                'padding-l-4': $vuetify.breakpoint.smAndUp,
              }"
            >
              <label for="lastName">Last name</label>
              <v-text-field
                id="new-team-dialog-text-last-name"
                ref="lastName"
                v-model="formData.lastName.value"
                v-bind="formData.lastName"
                required
                validate-on-blur
                outlined
              />
            </div>
          </v-col>
          <v-col cols="12">
            <label for="email">Email</label>
            <v-text-field
              id="new-team-dialog-text-email"
              ref="email"
              v-model="formData.email.value"
              v-bind="formData.email"
              required
              validate-on-blur
              outlined
            />
          </v-col>
          <v-col cols="12">
            <label for="title">Title</label>
            <v-text-field
              id="new-team-dialog-text-title"
              v-model="formData.title.value"
              v-bind="formData.title"
              required
              validate-on-blur
              outlined
            />
          </v-col>
        </v-row>
        <AlertBox :active="!!errorMessage" type="error" class="margin-b-3">
          {{ errorMessage }}
        </AlertBox>
      </v-form>
    </template>
    <template #actions>
      <div class="d-flex grow" />
      <v-btn
        id="new-team-dialog-btn-cancel"
        class="margin-r-2"
        small
        text
        color="error"
        @click="
          () => {
            dialog = false
            $emit('close')
          }
        "
      >
        Cancel
      </v-btn>
      <v-btn
        id="new-team-dialog-btn-create"
        class="primary"
        small
        tabindex="7"
        @click="send"
      >
        Create Team
      </v-btn>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import customerAccount from '@/services/customerAccount'
import auth from '@/store/modules/auth'
import { CreateCustomerAccountPayload, InvitePayload } from '@/models/dto'
import AlertBox from '@/components/AlertBox.vue'

@Component({ components: { AlertBox } })
export default class OrganizationNewTeamDialog extends Vue {
  dialog = false
  success = false
  formData = {
    teamName: {
      value: '',
      rules: [(v) => !!v || 'Team name is required'],
    },
    firstName: {
      value: '',
      rules: [(v) => !!v || 'First name is required'],
    },
    lastName: {
      value: '',
      rules: [(v) => !!v || 'First name is required'],
    },
    email: {
      value: '',
      rules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    },
    title: { value: '', rules: [(v) => !!v || 'Title is required'] },
  }
  errorMessage = null
  formIsValid = false

  async send(): Promise<void> {
    const form = this.$refs.form as any
    if (!form.validate()) {
      return
    }
    this.errorMessage = false
    const parentCustomerAccountId = auth.customerAccount.customerAccountId
    const {
      data: { address },
    } = await customerAccount.byId(parentCustomerAccountId)
    if (address) {
      delete address.addressId
    }
    const newTeamPayload: CreateCustomerAccountPayload = {
      name: this.formData.teamName.value,
      email: this.formData.email.value,
      title: this.formData.title.value,
      industryId: 2,
      address,
      parentCustomerAccountId,
      phone: '',
    }
    try {
      const { data: customerAccountId } = await customerAccount.create(
        newTeamPayload
      )
      const inviteCustomerPayload: InvitePayload = {
        firstName: this.formData.firstName.value,
        lastName: this.formData.lastName.value,
        customerAccountId,
        email: this.formData.email.value,
        role: 1,
        title: '',
      }
      await customerAccount.invite(inviteCustomerPayload)
      this.success = true
      this.dialog = false
      this.$emit('close')
      this.$emit('input', false)
      this.$emit('success')
    } catch (e) {
      const error = e as any
      const errorMessage = error.response?.data?.message
      this.errorMessage = errorMessage
    }
  }
}
</script>
