<template>
  <div
    class="d-flex padding-t-4"
    :class="{
      'justify-space-between margin-b-4 align-center': !isSingleColumn,
      'flex-column margin-b-4 ': isSingleColumn,
    }"
  >
    <h1
      class="font-28"
      :class="{
        'margin-b-2': isSingleColumn,
        'text-center': fullWidth,
        'margin-b-0': !fullWidth,
      }"
    >
      {{ headerText }}
    </h1>
    <div
      v-if="!isInGuestCheckout"
      class="d-flex align-center"
      :class="{ 'justify-end': !fullWidth, 'justify-center': fullWidth }"
    >
      <ShareQuoteDialog
        v-if="showShareQuoteDialog"
        :quote-id="quoteId"
      />

      <DownloadQuoteButton
        v-if="quote.hash && companyId"
        :quote-id="quote.quoteId"
        :quote-hash="quote.hash"
        :company-id="companyId"
        class="margin-l-2"
      />

      <v-btn
        id="quote-detail-button-contact-support"
        text
        color="primary"
        class="padding-x-2"
        small
        @click.stop="openSupport"
      >
        {{ $t('common.CONTACT_SUPPORT') }}
      </v-btn>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import ShareQuoteDialog from '@/components/ShareQuoteDialog.vue'
import DownloadQuoteButton from '@/components/DownloadQuoteButton.vue'
import { CustomerAccount, Quote } from '../models/dto'
import { PricingMethod } from '@/utils/enum'
import auth from '../store/modules/auth'
import support from '../store/modules/support'
import CustomerAccountDefaultValueSet from '@/models/dto/CustomerAccountDefaultValueSet'

@Component({ components: { ShareQuoteDialog, DownloadQuoteButton } })
export default class CheckoutHeader extends Vue {
  @Inject({ from: 'isInGuestCheckout', default: false }) readonly isInGuestCheckout: boolean

  @Prop({ type: Object, required: true }) quote!: Quote
  @Prop({ type: Boolean, default: false }) isSingleColumn!: boolean
  @Prop({ type: Boolean, default: false }) fullWidth!: boolean
  @Prop({ type: Object, default: () => {} }) customerAccount!: CustomerAccount
  @Prop({ type: Object, default: () => {} }) effectiveDefaultValueSet!: CustomerAccountDefaultValueSet

  loading = false

  get quoteId(): number {
    return this.quote?.quoteId
  }

  get companyId(): number | undefined {
    return parseInt(this.$route.params.providerId)
  }

  get isBidListQuote(): boolean {
    return this.quote?.pricingMethod === PricingMethod.Bids
  }

  get quoteCustomerAccountMatchesLoggedInCustomerAccount() {
    return (
      this.quote?.trips[0]?.customer?.customerAccountId &&
      this.quote?.trips[0]?.customer.customerAccountId ===
        this.customerAccount?.customerAccountId
    )
  }

  get headerText(): string {
    const { name } = this.customerAccount || {}
    if (
      this.quoteCustomerAccountMatchesLoggedInCustomerAccount &&
      this.customerAccount.nameApprovedForUserInterface &&
      name
    ) {
      return `Your ${name} trip is ready to book!`
    }
    return 'Your trip is ready to book!'
  }

  get showShareQuoteDialog(): boolean {
    if (!this.quote) {
      return false
    }
    return this.isUserQuoteCustomer || this.isUserInSameOrganization
  }

  get isUserQuoteCustomer(): boolean {
    const quoteCustomerId = this.quote?.trips?.[0]?.customerId
    return quoteCustomerId === auth.user?.userId
  }

  get isUserInSameOrganization(): boolean {
    const quoteCustomerCustomerAccountId = this.quote?.trips?.[0]?.customer?.customerAccountId
    if (!quoteCustomerCustomerAccountId) {
      return false
    }
    return (
      quoteCustomerCustomerAccountId ===
        auth.customerAccount.customerAccountId ||
        auth.childCustomerAccountIds.includes(quoteCustomerCustomerAccountId)
    )
  }

  openSupport(): void {
    support.open({
      reservationId: null,
      quoteId: this.quoteId,
    })
  }
}
</script>
