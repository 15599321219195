<template>
  <CUModal :value="value" width="500px" @input="$emit('input', $event)">
    <template #title>{{ `${''}` }}</template>
    <template #text>
      <div class="display-flex flex-column w-full padding-a-3">
        <img
          id="bus__slider__image"
          src="@/assets/images/trip-name.svg"
          alt="trip name bus"
          class="w-full"
        />
        <h2 class="margin-x-auto margin-y-8">Trip Name</h2>
        <label>What can we call this trip?</label>
        <v-text-field
          id="trip-name-text-field"
          ref="tripName"
          v-model="routeName"
          style="max-height: 75px;"
          required
          outlined
          maxlength="40"
          tabindex="1"
        />
        <v-btn class="primary" @click="handleUpdate">
          Save
        </v-btn>
      </div>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import trip from '@/services/trip'
import { ReservationDetail, UpdateTripPayload } from '@/models/dto'

@Component
export default class TripNameDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: Object, default: () => {} })
  readonly reservation: ReservationDetail

  routeName = ''

  @Watch('reservation.routeName', { immediate: true })
  routeNameChanged(value: string): void {
    this.routeName = value
  }

  async handleUpdate() {
    const tripPayload: Partial<UpdateTripPayload> = {
      routeName: this.routeName,
    }
    await trip.editRouteName(
      this.reservation.tripId,
      tripPayload as UpdateTripPayload
    )
    this.$emit('refresh')
    this.$emit('input', false)
  }
}
</script>
