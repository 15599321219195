<template>
  <div v-if="allowReservationManagement" class="padding-r-3">
    <div v-if="isTripUpcomingOrStarted">
      <div
        v-for="(risk, riskIndex) in risksPerTrip"
        :key="`risk-item-${riskIndex}`"
      >
        <ItineraryRiskWarning
          class="display-flex align-center justify-start margin-b-4"
          :reservation-id="reservationId"
          :managed-id="managedId"
          :index="riskIndex"
          :risk-type-id="risk.riskTypeId"
          :hotel-requirement="hotelRequirement"
          :hotel-information="hotelInformation"
        />
      </div>
    </div>
    <template v-if="useMobileDisplay && showHotelInformation">
      <div class="d-flex padding-l-0" style="column-gap: 12px;">
        <CUIcon view-box="0 0 24 24" :width="18" :height="18">
          bed
        </CUIcon>
        <div class="d-flex font-14  flex-column">
          <div
            v-for="(hotelInfoLine, lineIdx) in hotelInfoList"
            :key="`hotel-info-line-${lineIdx}`"
            class="d-flex align-center justify-start"
          >
            {{ hotelInfoLine }}
          </div>
        </div>
      </div>
      <div class="d-flex font-14  justify-end padding-x-1 margin-b-4">
        <span>
          <a
            class="font-bold margin-r-3"
            @click.stop="hotelInformationDialogIsOpen = true"
          >
            {{ $t('common.EDIT') }}
          </a>
          <a
            class="font-medium text-gray text-decoration-underline"
            @click.stop="hotelRequirementsDialogIsOpen = true"
          >
            {{ $t('reservationDetail.informationNeeded.REQUIREMENTS') }}
          </a>
        </span>
      </div>
    </template>
    <template v-else>
      <div
        v-if="showHotelInformation"
        class="d-flex padding-l-0 margin-b-4"
        style="column-gap: 12px;"
      >
        <CUIcon
          view-box="0 0 24 24"
          :width="18"
          :height="18"
          class="flex-shrink-0"
        >
          bed
        </CUIcon>
        <div class="d-flex justify-space-between w-full flex-shrink-1">
          <div class="d-flex font-14  flex-column flex-shrink-1">
            <div
              v-for="(hotelInfoLine, lineIdx) in hotelInfoList"
              :key="`hotel-info-line-${lineIdx}`"
              class="d-flex align-center"
            >
              {{ hotelInfoLine }}
            </div>
          </div>
          <div
            class="d-flex font-14  flex-column align-end flex-shrink-0 margin-l-2"
          >
            <div class="d-flex">
              <a
                class="font-bold"
                @click.stop="hotelInformationDialogIsOpen = true"
              >
                {{ $t('common.EDIT') }}
              </a>
            </div>
            <div class="d-flex">
              <a
                class="font-medium text-gray text-decoration-underline"
                @click.stop="hotelRequirementsDialogIsOpen = true"
              >
                {{ $t('reservationDetail.informationNeeded.REQUIREMENTS') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
    <DriverHotelRequirementsDialog
      v-model="hotelRequirementsDialogIsOpen"
      :reservation-id="reservationId"
      :hotel-requirement="hotelRequirement"
    />
    <HotelInformationDialog
      v-model="hotelInformationDialogIsOpen"
      :trip-id="tripId"
      :hotel-information="hotelInformation"
      :hotel-requirement="hotelRequirement"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import ItineraryRiskWarning from '@/components/ItineraryRiskWarning.vue'
import HotelInformationDialog from '@/components/HotelInformationDialog.vue'
import DriverHotelRequirementsDialog from '@/components/DriverHotelRequirementsDialog.vue'
import { RiskTypeId } from '@/utils/enum'
import { DateTime } from 'luxon'
import { Trip, HotelInformation, HotelRequirement } from '@/models/dto'

@Component({
  components: {
    ItineraryRiskWarning,
    HotelInformationDialog,
    DriverHotelRequirementsDialog,
  },
})
export default class TripInfoItineraryHotelInfo extends Vue {
  @Prop({ type: Object, default: () => {} }) readonly trip: Trip
  @Prop({ type: Boolean, default: false }) readonly allowReservationManagement: boolean
  @Prop({ type: Number, required: true }) readonly reservationId: number
  @Prop({ type: String, required: false }) readonly managedId: string
  @Prop({ required: false, type: Object, default: null }) readonly hotelInformation: HotelInformation
  @Prop({ required: false, type: Object, default: null }) readonly hotelRequirement: HotelRequirement
  @Prop({ type: String, default: '' }) readonly tripStatus: string

  hotelInformationDialogIsOpen = false
  hotelRequirementsDialogIsOpen = false

  get risksPerTrip(): any[] {
    return this.trip?.risks || []
  }

  get isHotelRiskOnTrip(): boolean {
    return this.risksPerTrip?.some(
      (risk) => risk.riskTypeId === RiskTypeId.DriverHotelMissing
    )
  }

  get tripId(): number {
    return this.hotelRequirement?.tripId
  }

  get hotelInfoList(): string[] {
    const checkIn = this.hotelRequirement?.checkIn
      ? DateTime.fromISO(this.hotelRequirement?.checkIn).toFormat('LL/dd/yyyy')
      : ''
    const checkOut = this.hotelRequirement?.checkOut
      ? DateTime.fromISO(this.hotelRequirement?.checkOut).toFormat('LL/dd/yyyy')
      : ''
    const address = this.hotelInformation?.address
    return [
      `Hotel booked ${checkIn} — ${checkOut}`,
      `${this.hotelInformation?.hotelName} — ${this.hotelInformation?.confirmationNumber}`,
      `${address?.street1}, ${address?.city}, ${address?.state} ${address?.postalCode}`,
      `${this.hotelInformation?.nameOnReservation}`,
    ]
  }

  get useMobileDisplay(): boolean {
    return this.$vuetify.breakpoint.xsOnly
  }

  get showHotelInformation(): boolean {
    return (
      !this.isHotelRiskOnTrip &&
      this.hotelInformation !== null &&
      this.hotelInformation?.hotelName !== null &&
      this.hotelInformation?.confirmationNumber !== null &&
      this.hotelInformation?.nameOnReservation !== null &&
      this.hotelInformation?.address !== null &&
      this.hotelRequirement?.checkIn !== null &&
      this.hotelRequirement?.checkOut !== null
    )
  }

  get isTripUpcomingOrStarted(): boolean {
    return this.tripStatus === 'upcoming' || this.tripStatus === 'started'
  }
}
</script>
