import Vue, { VueConstructor } from "vue";

/**
 * Custom plugin for Google Analytics 4 (GA4) * $ga4.trackEvent() can be used globally to track custom events (e.g., button click)
 * $ga4Event() takes an event name (e.g., 'view_quote') and any event parameters (e.g., quoteId)
 */
let instance: Vue | null = null;

export const useGoogleAnalytics = (): Vue => {
  if (instance) {
    return instance
  }

  instance = new Vue({
    methods: {
      // Some events do not require any event params. In that case, pass in `undefined`.
      // E.g., this.$ga4.trackEvent('login', undefined)
      trackEvent(name: string, params?: any) {
        (window.dataLayer || []).push({
          event: name,
          ...params,
        })
      },
    },
  })

  return instance
}

export const GoogleAnalyticsPlugin = {
  install(Vue: VueConstructor): void {
    Vue.prototype.$ga4 = useGoogleAnalytics();
  },
};

Vue.use(GoogleAnalyticsPlugin);
