<template>
  <div
    :style="{ maxWidth }"
    :class="{ 'padding-a-4': $vuetify.breakpoint.xs, 'container': !plainContainer }"
  >
    <v-row v-if="$slots.rowAbove">
      <v-col cols="12">
        <slot name="rowAbove" />
      </v-col>
    </v-row>
    <v-row
      align="start"
      :justify="$vuetify.breakpoint.lgAndUp ? 'space-between' : 'center'"
    >
      <v-col v-if="$slots.header" cols="12" class="padding-t-5 padding-b-0">
        <slot name="header" />
      </v-col>
      <v-col
        v-if="$slots['left-column']"
        cols="12"
        :lg="leftColumn"
        md="9"
        order="2"
      >
        <slot name="left-column" />
      </v-col>
      <v-col
        v-if="$slots['right-column']"
        cols="12"
        :lg="rightColumn"
        md="9"
        :order="$vuetify.breakpoint.lgAndUp ? 3 : 1"
        class="align-start"
        :class="{ 'position-sticky padding-l-15': $vuetify.breakpoint.lgAndUp }"
        :style="{ top: $vuetify.breakpoint.lgAndUp ? '5.85rem' : '' }"
      >
        <slot name="right-column" />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class TwoColumnLayout extends Vue {
  @Prop({ type: String, default: '1250px' }) readonly maxWidth: string
  @Prop({ type: Number, default: 7 }) readonly leftColumn: number
  @Prop({ type: Boolean, default: false }) readonly plainContainer: boolean

  get rightColumn(): number {
    return 12 - this.leftColumn
  }
}
</script>
