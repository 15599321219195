<template>
  <div
    v-if="selectedTripRequirements.length > 0"
    class="d-flex align-center justify-start row margin-t-n1 margin-b-2"
  >
    <div
      v-for="(selectedTripRequirement,
      selectedTripRequirementIndex) in selectedTripRequirements"
      :key="`${selectedTripRequirement}-trip-requirement-${selectedTripRequirementIndex}-${$vuetify.breakpoint.xs}`"
      class="padding-y-1 col-md-4 col-lg-6 col-sm-6"
      :class="{
        'col-6':
          selectedTripRequirement.key !==
          SpecialRequirementsTypeKey.VehicleNeededEntireTrip,
        'col-12':
          selectedTripRequirement.key ===
          SpecialRequirementsTypeKey.VehicleNeededEntireTrip,
      }"
    >
      <div class="d-flex align-center">
        <CUIcon color="gray-subtle-text-2" class="cursor-default margin-r-1">
          {{ selectedTripRequirement.icon }}
        </CUIcon>
        <p class="margin-t-0">{{ selectedTripRequirement.title }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  SpecialRequirementsTypeKey,
  SpecialRequirementsTypeLabel,
} from '@/utils/enum'

@Component({})
export default class TripRequirements extends Vue {
  @Prop({ type: Boolean, default: false }) readonly ada: boolean
  @Prop({ type: Boolean, default: false }) readonly spab: boolean
  @Prop({ type: Boolean, default: false })
  readonly vehicleNeededEntireTrip: boolean

  SpecialRequirementsTypeKey = SpecialRequirementsTypeKey

  get selectedTripRequirements(): Array<{
    icon: string
    title: string
    key: string
  }> {
    return [
      {
        icon: 'bus_alert',
        title: SpecialRequirementsTypeLabel.VehicleNeededEntireTrip,
        condition: this.vehicleNeededEntireTrip,
        key: SpecialRequirementsTypeKey.VehicleNeededEntireTrip,
      },
      {
        icon: 'accessible',
        title: SpecialRequirementsTypeLabel.ADA,
        condition: this.ada,
        key: SpecialRequirementsTypeKey.ADA,
      },
      {
        icon: 'school',
        title: SpecialRequirementsTypeLabel.SPAB,
        condition: this.spab,
        key: SpecialRequirementsTypeKey.SPAB,
      },
    ]
      .filter(({ condition }) => condition)
      .map(({ icon, title, key }) => {
        return { icon, title, key }
      })
  }
}
</script>
