<template>
  <CUModal v-model="dialog" max-width="518px">
    <template #activator="{ on }">
      <v-btn
        id="invite-user-add"
        class="margin-r-3 padding-x-6"
        small
        color="primary"
        v-on="on"
      >
        Add Rider
      </v-btn>
    </template>
    <template #title>Add Rider</template>
    <template #text>
      <v-form ref="form" v-model="formIsValid">
        <v-row no-gutters>
          <v-col cols="12">
            <label for="email">Email</label>
            <v-text-field
              id="invite-user-team-email"
              ref="email"
              v-model="formData.email.value"
              v-bind="formData.email"
              :class="{
                'padding-r-3': $vuetify.breakpoint.xs,
                'padding-r-4': $vuetify.breakpoint.smAndUp,
              }"
              validate-on-blur
              outlined
            />
          </v-col>
          <v-col cols="6">
            <label for="firstName">First name</label>
            <v-text-field
              id="invite-user-team-first-name"
              ref="firstName"
              v-model="formData.firstName.value"
              v-bind="formData.firstName"
              :class="{
                'padding-r-3': $vuetify.breakpoint.xs,
                'padding-r-4': $vuetify.breakpoint.smAndUp,
              }"
              validate-on-blur
              outlined
            />
          </v-col>
          <v-col cols="6">
            <div
              :class="{
                'padding-l-3': $vuetify.breakpoint.xs,
                'padding-l-4': $vuetify.breakpoint.smAndUp,
              }"
            >
              <label for="lastName">Last name</label>
              <v-text-field
                id="invite-user-team-last-name"
                ref="lastName"
                v-model="formData.lastName.value"
                v-bind="formData.lastName"
                validate-on-blur
                outlined
              />
            </div>
          </v-col>
          <v-col cols="6">
            <label for="employeeId">Employee ID</label>
            <v-text-field
              id="invite-user-team-employeeId"
              ref="employeeId"
              v-model="formData.employeeId.value"
              hint="Required if email not entered"
              persistent-hint
              v-bind="formData.employeeId"
              validate-on-blur
              outlined
            />
          </v-col>
          <v-col cols="6">
            <div
              :class="{
                'padding-l-3': $vuetify.breakpoint.xs,
                'padding-l-4': $vuetify.breakpoint.smAndUp,
              }"
            >
              <label for="phone">Phone</label>
              <v-text-field
                id="invite-user-team-phone"
                ref="phone"
                v-model="formData.phone.value"
                v-bind="formData.phone"
                validate-on-blur
                outlined
              />
            </div>
          </v-col>
          <v-col cols="12">
            <OrganizationEditRiderChildCustomerAccounts
              v-model="childCustomerAccountIds"
              :available-customer-accounts="customerAccount.teams"
            />
          </v-col>
          <v-col cols="6">
            <div
              :class="{
                'padding-r-3': $vuetify.breakpoint.xs,
                'padding-r-4': $vuetify.breakpoint.smAndUp,
              }"
            >
              <v-checkbox
                v-model="sendInviteEmail"
                hide-details
                label="Send Invite Email"
              />
            </div>
          </v-col>
        </v-row>
        <AlertBox :active="!!errorMessage" type="error" class="margin-b-3">
          {{ errorMessage }}
        </AlertBox>
      </v-form>
    </template>
    <template #actions>
      <div class="d-flex grow" />
      <v-btn
        id="invite-user-team-cancel"
        small
        text
        class="margin-r-2"
        @click="dialog = false"
      >
        Cancel
      </v-btn>
      <v-btn id="invite-user-team-submit" class="primary" small @click="send">
        Add Rider
      </v-btn>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import rider from '@/services/rider'
import auth from '@/store/modules/auth'
import { CustomerAccount } from '@/models/dto'
import axios from 'axios'
import OrganizationEditRiderChildCustomerAccounts from './OrganizationEditRiderChildCustomerAccounts.vue'
import AlertBox from '@/components/AlertBox.vue'

@Component({
  components: {
    OrganizationEditRiderChildCustomerAccounts,
    AlertBox,
  },
})
export default class OrganizationAddRiderDialog extends Vue {
  @Prop({ type: Object, default: () => {} })
  readonly customerAccount: CustomerAccount

  @Watch('dialog')
  dialogChanged(): void {
    this.resetValues()
    this.setup()
  }

  formIsValid = false
  dialog = false
  success = false
  errorMessage = ''
  sendInviteEmail = false
  childCustomerAccountIds = []

  formData: Record<string, { value: string; rules: any[] }> = {
    employeeId: {
      value: '',
      rules: [],
    },
    firstName: {
      value: '',
      rules: [],
    },
    lastName: {
      value: '',
      rules: [],
    },
    email: {
      value: '',
      rules: [(v) => !v || /.+@.+/.test(v) || 'E-mail must be valid'],
    },
    phone: {
      value: '',
      rules: [
        (v) =>
          !v ||
          /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
            v
          ) ||
          'Phone must be valid',
      ],
    },
  }

  mounted(): void {
    this.setup()
  }

  setup() {
    this.success = false
  }

  resetValues(): void {
    const form = this.$refs.form as any
    form.reset()
    this.errorMessage = ''
  }

  backToSite(): void {
    this.success = false
    this.setup()
    this.dialog = false
  }

  async send(): Promise<void> {
    const form = this.$refs.form as any
    if (!form.validate()) {
      return
    }
    this.errorMessage = ''
    if (!this.formData.email.value && !this.formData.employeeId.value) {
      this.errorMessage = 'Email or employee ID is required.'
      return
    }
    if (this.sendInviteEmail && !this.formData.email.value) {
      this.errorMessage = 'Email required to send invite email.'
      return
    }
    const payload = {
      firstName: this.formData.firstName.value,
      lastName: this.formData.lastName.value,
      employeeId: this.formData.employeeId.value
        ? this.formData.employeeId.value
        : this.formData.email.value,
      email: this.formData.email.value,
      phone: this.formData.phone.value,
      customerAccountId: auth.customerAccount.customerAccountId,
      sendInviteEmail: this.sendInviteEmail,
      childCustomerAccountIds: this.childCustomerAccountIds,
    }
    try {
      const resp = await rider.create(payload)

      if (resp.status === 200) {
        this.success = true
        this.dialog = false
        this.$emit('success')
      } else {
        this.errorMessage = resp.data.message || 'Error adding rider.'
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errorMessage = e.response?.data?.message
        this.errorMessage = errorMessage
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.invite-checkbox {
  margin-top: 16px;
}
</style>
