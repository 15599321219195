<template>
  <div>
    <label v-if="label">
      {{ label }}
    </label>
    <v-menu
      v-model="isOpen"
      :content-props="{ 'id': 'cu-date-picker--menu' }"
      :close-on-content-click="false"
      transition="scale-transition"
      :top="top"
      :bottom="bottom"
      :left="left"
      :right="right"
      offset-y
      :rounded="isMultiMonth ? 'lg' : ''"
      :origin="origin || `top center 0`"
      min-width="auto"
      :disabled="disabled"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :id="id"
          :value="inputDisplayText"
          :placeholder="placeholder"
          autocomplete="off"
          name="CU Date Picker"
          readonly
          :rules="rules"
          :hide-details="hideDetails"
          outlined
          :disabled="disabled"
          v-bind="attrs"
          v-on="on"
          append-icon="mdi-chevron-down"
          @click:append="on.click"
        />
      </template>
      <vc-date-picker
        v-if="isMultiMonth"
        :value="value"
        :columns="effectiveMonths"
        :attributes="[currentDayStyles]"
        :select-attribute="selectedDayStyles"
        :min-date="minDate"
        :timezone="timezone"
        @input="handleVCEmit"
      >
        <template #header-left-button>
          <CUIcon id="cu-date-picker--arrow-back" view-box="0 0 24 24" aria-label="Previous">
            keyboard_arrow_left
          </CUIcon>
        </template>
        <template #header-right-button>
          <CUIcon id="cu-date-picker--arrow-forward" view-box="0 0 24 24" aria-label="Next">
            keyboard_arrow_right
          </CUIcon>
        </template>
      </vc-date-picker>
      <v-date-picker
        v-else
        :value="value"
        no-title
        :min="min"
        @input="e => emit('input', e)"
      />
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, defineProps, defineEmits } from 'vue'
import dayjs from 'dayjs'
import { useDisplay } from '@/composables/useDisplay'
import { DateTime } from 'luxon'

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  rules: {
    type: Array,
    default: () => [],
  },
  hideDetails: {
    type: Boolean,
    default: false,
  },
  value: {
    type: String,
    required: true,
  },
  displayValue: {
    type: String,
    default: '',
  },
  min: {
    type: String,
    default: '',
  },
  minDate: {
    type: Object as PropType<DateTime>,
    default: '',
  },
  top: {
    type: Boolean,
    default: false,
  },
  bottom: {
    type: Boolean,
    default: false,
  },
  right: {
    type: Boolean,
    default: false,
  },
  left: {
    type: Boolean,
    default: false,
  },
  origin: {
    type: String,
    default: '',
  },
  months: {
    type: Number,
    default: 1,
  },
  placeholder: {
    type: String,
    default: '',
  },
  timezone: {
    type: String,
    default: 'UTC',
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['input'])

const isOpen = ref(false)

const inputDisplayText = computed(() => {
  return props.displayValue
})

const isMultiMonth = computed(() => {
  return (props.months || 1) > 1
})

const { smAndDown } = useDisplay()
const effectiveMonths = computed(() => {
  return smAndDown.value ? 1 : props.months
})

function handleVCEmit(e: string) {
  emit('input', !!e ? dayjs(e).format('YYYY-MM-DD') : '')
}

watch(() => props.value, () => {
  isOpen.value = false
})

// VC Date Picker styles
const currentDayStyles = {
  highlight: {
    style: {
      height: '36px',
      width: '36px',
      background: '#08A6F0',
    },
    contentStyle: {
      fontWeight: '600',
      color: '#fff',
    },
  },
  dates: new Date(),
}

const selectedDayStyles = {
  highlight: {
    style: {
      height: '36px',
      width: '36px',
      background: '#000',
    },
    contentStyle: {
      fontWeight: '700',
      color: '#fff',
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .vc-container {
  border-radius: 10px !important;
  padding: 8px 16px;

  .vc-pane > div:not(.column-from-end-1) {
    padding-right: 16px !important;
  }

  .vc-popover-content-wrapper {
    color: #42516D;

    .vc-nav-popover-container {
      border: none;
      background-color: white;

      .vc-nav-arrow {
        &:hover {
          background-color: rgba(204, 214, 224, .3) !important;
        }

        &:focus {
          border-color: transparent !important;
        }

        & > svg {
          color: #42516D !important;
        }
      }

      .vc-nav-title {
        font-family: 'Nunito', sans-serif;
        color: #42516D;

        &:hover {
          background-color: rgba(204, 214, 224, .3) !important;
        }

        &:focus {
          border-color: transparent !important;
        }
      }
    }

    .vc-nav-items {
      padding-top: 2px;
    }

    .vc-nav-item {
      &:hover {
        background-color: rgba(204, 214, 224, .3) !important;
        border-color: transparent !important;
        color: #42516D;
        box-shadow: none !important;
      }

      &:focus {
        border-color: white;
        box-shadow: none;
      }

      &.is-active {
        color: white;
        background-color: black !important;
        font-weight: 600 !important;
        box-shadow: none !important;
      }

      &.is-current {
        border-color: transparent !important;
        color: white;
        background-color: #08A6F0 !important;
        font-weight: 600 !important;
        box-shadow: none !important;
      }
    }
  }

  .vc-title {
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #42516D !important;
    padding-bottom: 12px !important;
  }

  .vc-weekday {
    font-weight: 600 !important;
    color: #42516D !important;
  }

  .vc-day-content {
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-weight: 400;
    margin: 2px;

    &.is-disabled:hover {
      cursor: default;
      background-color: transparent;
    }
  }
}

::v-deep .v-input__append-inner {
  margin-top: 14px !important;
  margin-right: 8px !important;
}
</style>
