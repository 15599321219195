<template>
  <div>
    <div class="d-flex flex-row justify-end">
      <v-btn
        v-if="isModeView"
        id="my-profile-edit-button"
        color="primary"
        small
        @click="startEdit"
      >
        {{ $t('common.EDIT') }}
      </v-btn>
      <v-btn
        v-if="isModeEdit"
        id="my-profile-cancel-button"
        color="error"
        small
        outlined
        :loading="submitting"
        class="margin-r-2"
        @click="cancel"
      >
        {{ $t('common.CANCEL') }}
      </v-btn>
      <v-btn
        v-if="isModeEdit"
        id="my-profile-submit-button"
        :disabled="disabled"
        color="primary"
        small
        :loading="submitting"
        @click="submit"
      >
        {{ $t('common.SAVE') }}
      </v-btn>
    </div>
    <v-row no-gutters>
      <v-col cols="12" sm="3">
        <v-row
          :class="{
            'justify-center': $vuetify.breakpoint.xsOnly,
            'justify-start': $vuetify.breakpoint.smAndUp,
          }"
        >
          <v-col cols="auto">
            <img
              width="100"
              class="border-radius-round w-100 h-100 object-fit-cover"
              :src="avatarPhoto"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="3">
        <v-row
          :class="{
            'justify-center': $vuetify.breakpoint.xsOnly,
            'justify-start': $vuetify.breakpoint.smAndUp,
          }"
        >
          <v-col
            cols="auto"
            :class="{ 'text-center': $vuetify.breakpoint.xsOnly }"
          >
            <p class="margin-b-2">{{ $t('profile.PROFILE.YOUR_AVATAR') }}</p>
            <v-btn
              color="primary"
              outlined
              small
              :disabled="disabled"
              @click="choosePhoto"
            >
              {{ $t('common.UPDATE') }}
            </v-btn>
            <input
              ref="avatarUpload"
              type="file"
              accept="image/*"
              hidden
              @change="addPhotoForUpload"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="margin-t-6 margin-b-6" />
    <v-row no-gutters>
      <v-col cols="12">
        <v-form
          v-if="profile.customer"
          ref="profileSettingsForm"
          lazy-validation
        >
          <label>{{ $t('profile.PROFILE.FORM.FIRST_NAME.LABEL') }}</label>
          <v-text-field
            id="my-profile-text-first-name"
            ref="firstName"
            :value="profile.customer.firstName"
            :rules="[
              (v) =>
                !!v || $t('profile.PROFILE.FORM.FIRST_NAME.RULES.REQUIRED'),
            ]"
            :disabled="disabled"
            required
            outlined
            tabindex="1"
            @input="profile.setCustomerFirstName($event)"
          />
          <label>{{ $t('profile.PROFILE.FORM.LAST_NAME.LABEL') }}</label>
          <v-text-field
            id="my-profile-text-last-name"
            ref="lastName"
            :value="profile.customer.lastName"
            :rules="[
              (v) => !!v || $t('profile.PROFILE.FORM.LAST_NAME.RULES.REQUIRED'),
            ]"
            required
            :disabled="disabled"
            outlined
            tabindex="2"
            @input="profile.setCustomerLastName($event)"
          />
          <label class="d-flex align-center">
            <span>
              {{ $t('profile.PROFILE.FORM.EMAIL.LABEL') }}
            </span>
            <v-tooltip top color="gray">
              <template #activator="{ on, attrs }">
                <CUIcon
                  v-bind="attrs"
                  v-on="on"
                  color="gray-light"
                  view-box="0 0 24 24"
                  width="16"
                  height="16"
                  class="margin-l-1"
                >
                  info
                </CUIcon>
              </template>
              <span class="text-white">
                To update your email address, please contact our support team
                for assistance.
              </span>
            </v-tooltip>
          </label>
          <v-text-field
            id="my-profile-text-email"
            ref="email"
            :value="profile.customer.email"
            disabled
            outlined
            tabindex="3"
          />
          <div class="d-flex align-center">
            <label>{{ $t('profile.PROFILE.FORM.PHONE.LABEL') }}</label>
            <CUIcon
              v-if="smsConfirmed"
              color="primary"
              view-box="0 0 24 24"
              width="16px"
              height="16px"
              class="margin-l-1 cursor-default"
            >
              verified
            </CUIcon>
            <v-spacer />
            <button
              v-if="!smsConfirmed && !isModeEdit"
              id="confirm-phone-number-button"
              type="button"
              class="font-medium font-14 cursor-pointer"
              :class="{
                'text-gray-medium-light cursor-default': isModeEdit,
                'text-primary': !isModeEdit,
              }"
              :disabled="isModeEdit"
              @click="profile.beginSMSConfirmation"
            >
              {{ $t('common.VERIFY') }}
            </button>
          </div>
          <v-text-field
            id="my-profile-text-phone"
            ref="phoneNumber"
            v-mask="['(###) ###-####', '+## ## #### ####']"
            :value="profile.customer.phone"
            :rules="[
              (v) => !!v || $t('profile.PROFILE.FORM.PHONE.RULES.REQUIRED'),
              phoneRawNumber.length > 9 ||
                $t('profile.PROFILE.FORM.PHONE.RULES.VALID'),
              phoneRawNumber.length <= 12 ||
                $t('profile.PROFILE.FORM.PHONE.RULES.VALID'),
            ]"
            required
            validate-on-blur
            :disabled="disabled"
            outlined
            tabindex="4"
            @input="profile.setCustomerPhone($event)"
          />
        </v-form>
        <p class="text-body-2">
          {{ $t('profile.PROFILE.FORM.PHONE.LEGALESE_1') }}
          <a
            href="https://www.charterup.com/terms-and-conditions/"
            target="_blank"
          >
            {{ $t('common.TERMS_OF_SERVICE') }}
          </a>
          {{ $t('profile.PROFILE.FORM.PHONE.AND') }}
          <a
            href="https://www.charterup.com/privacy-policy/"
            target="_blank"
            class="mr-n1"
          >
            {{ $t('common.PRIVACY_POLICY') }}
          </a>
          .
        </p>
      </v-col>
    </v-row>
    <PhoneOTCVerificationDialog
      :value="profile.verifyingPhone"
      :phone="phoneRawNumber"
      :customer-id="profile.customer.userId"
      :verified="smsConfirmed"
      closeable
      @retry="profile.beginSMSConfirmation"
      @confirm="profile.setPhoneVerified()"
      @input="profile.setVerifyingPhone($event)"
      @skip="profile.setVerifyingPhone(false)"
      @close="profile.setVerifyingPhone(false)"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator'
import auth from '@/store/modules/auth'
import user from '@/services/user'
import customer from '@/services/customer'
import { phoneRaw } from '@/utils/phone'
import PhoneOTCVerificationDialog from '@/components/PhoneOTCVerificationDialog.vue'
import profile from '@/store/modules/profile'
import { validateEmailAddress } from '@/utils/string'

@Component({
  components: {
    PhoneOTCVerificationDialog,
  },
})
export default class ProfileProfile extends Vue {
  submitting = false
  rules: any = {}
  debounce = null
  isModeView = true
  isModeEdit = false

  profile = profile
  validateEmailAddress = validateEmailAddress

  get disabled(): boolean {
    return this.isModeView
  }

  get avatarPhoto(): string {
    const photo = profile?.uploadedPhoto || profile?.profilePhoto
    return photo?.imagePath
  }

  get phoneRawNumber(): string {
    return phoneRaw(profile.customer?.phone)
  }

  get smsConfirmed(): boolean {
    return profile?.smsConfirmed
  }

  mounted(): void {
    profile.loadCustomerProfile()
  }

  startEdit(): void {
    this.isModeEdit = true
    this.isModeView = false
  }

  endEdit(): void {
    this.isModeEdit = false
    this.isModeView = true
  }

  async cancel(): Promise<void> {
    await profile.loadCustomerProfile()
    this.endEdit()
  }

  choosePhoto(): void {
    const avatarUpload = this.$refs.avatarUpload as any
    avatarUpload.click()
  }

  addPhotoForUpload(): void {
    const avatarUpload = this.$refs.avatarUpload as any
    const file = avatarUpload.files[0]
    const newImageObject = {
      active: true,
      imagePath: null,
      primaryImage: true,
      userId: profile.customer.userId,
      userPhotoId: null,
      file: null,
      filename: null,
    }

    if (file) {
      const link = URL.createObjectURL(file)
      newImageObject.imagePath = link
      newImageObject.file = file
      newImageObject.filename = `${
        profile.customer.userId
      }_${new Date().getTime()}_${file.name}`
    } else {
      delete newImageObject.file
      delete newImageObject.filename
    }
    profile.setUploadedPhoto(newImageObject)
  }

  clearPhotoUpload(): void {
    profile.setUploadedPhoto(null)
    const fileInput = this.$refs.avatarUpload as any
    fileInput.value = ''
  }

  async uploadPhoto(): Promise<void> {
    await profile.uploadPhoto()
    this.clearPhotoUpload()
  }

  async submit(): Promise<void> {
    if (this.submitting) {
      return
    }
    const profileSettingsForm = this.$refs.profileSettingsForm as any
    if (!profileSettingsForm.validate()) {
      return
    }
    this.submitting = true
    try {
      profile.setCustomerPhone(this.phoneRawNumber)
      profile.setCustomerAccount(null)
      await customer.edit(profile.customer)
      if (profile?.uploadedPhoto?.imagePath) {
        await this.uploadPhoto()
      }
      profile.loadCustomerProfile()
      profile.setUploadedPhoto(null)
      auth.fetchUserDetails()
      this.endEdit()
    } catch (err) {
      console.warn(err)
    }
    this.rules = {}
    this.submitting = false
  }
}
</script>
