<template>
  <div class="font-14">
    <v-menu offset-y>
      <template #activator="{ on }">
        <CUIcon
          id="info-actions"
          view-box="0 0 24 24"
          class="kebab-button"
          v-bind="$attrs"
          v-on="on"
        >
          more_vert
        </CUIcon>
      </template>
      <v-list class="dropdown-menu padding-a-0">
        <v-list-item id="edit-user" key="edit-user" @click="isEditing = true">
          <v-list-item-title>
            Edit User
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          id="delete-user"
          key="delete-user"
          @click="isDeleting = true"
        >
          <v-list-item-title>
            Delete User
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="!isSignedUp"
          id="resend-invite"
          key="resend-invite"
          @click="resendInvite"
        >
          <v-list-item-title>
            Resend Invitation
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <CUModal
      v-model="isEditing"
      width="550"
      :content-class="$vuetify.breakpoint.xs ? 'fullscreen-dialog' : ''"
      :fullscreen="$vuetify.breakpoint.xs"
      @keydown.stop="
        (event) => {
          if (event.keyCode === 27) {
            isEditing = false
          }
        }
      "
    >
      <template #title>{{ `${''}` }}</template>
      <template #text>
        <div class="w-full padding-x-4 padding-b-4">
          <div class="w-full text-center">
            <h2>{{ $t('editCustomerForm.HEADER') }}</h2>
          </div>
          <div class="w-full margin-t-3">
            <OrganizationTeamEditUser
              :existing-customer="row"
              @close="
                isEditing = false
                $emit('refresh')
              "
            />
          </div>
        </div>
      </template>
    </CUModal>
    <CUModal
      v-model="isDeleting"
      width="550"
      :content-class="$vuetify.breakpoint.xs ? 'fullscreen-dialog' : ''"
      :fullscreen="$vuetify.breakpoint.xs"
      @keydown.stop="
        (event) => {
          if (event.keyCode === 27) {
            isDeleting = false
          }
        }
      "
    >
      <template #title>{{ `${''}` }}</template>
      <template #text>
        <div class="w-full padding-x-4 padding-b-4">
          <div class="w-full text-center">
            <h2>{{ $t('deleteCustomerForm.HEADER') }}</h2>
            <h4>{{ $t('deleteCustomerForm.CONFIRMATION') }}</h4>
          </div>
          <div class="w-full margin-t-3">
            <OrganizationTeamDeleteUser
              :existing-customer="row"
              @close="
                isDeleting = false
                $emit('refresh')
              "
            />
          </div>
        </div>
      </template>
    </CUModal>
  </div>
</template>

<script lang="ts">
import { TableViewCustomer } from '@/models/dto'
import customerAccount from '@/services/customerAccount'
import { Vue, Component, Prop } from 'vue-property-decorator'
import OrganizationTeamEditUser from '@/components/OrganizationTeamEditUser.vue'
import OrganizationTeamDeleteUser from '@/components/OrganizationTeamDeleteUser.vue'
import alert from '@/store/modules/alert'

@Component({
  components: { OrganizationTeamEditUser, OrganizationTeamDeleteUser },
})
export default class OrganizationTeamMoreActions extends Vue {
  @Prop({ type: Object, required: true }) readonly row: TableViewCustomer

  sending = false
  sent = false
  isEditing = false
  isDeleting = false

  get isSignedUp(): boolean {
    return this.row.isSignedUp
  }

  async resendInvite(): Promise<void> {
    try {
      if (this.isSignedUp) {
        return
      }
      this.sending = true
      await customerAccount.resendInvite({ email: this.row.email })
      this.sent = true
      alert.add({
        text: 'Successfully sent email invite.',
        color: 'primary',
        title: 'Success',
        dismissible: true,
      })
    } catch (e) {
      this.sent = false
      alert.add({
        text: 'Error sending invite. Please try again later.',
        color: 'error',
        title: 'Error',
        dismissible: true,
      })
    }
    this.sending = false
  }
}
</script>
