<template>
  <v-card class="padding-a-4 margin-b-2" @click="$emit('click')">
    <div class="d-flex justify-space-between align-center">
      <span class="margin-r-3 font-bold">{{ routeName }}</span>
      <div class="d-flex align-center flex-row">
        <v-checkbox
          :input-value="selected"
          hide-details
          class="margin-t-0 padding-t-0"
          @click.stop
          @change="handleSelectClick($event)"
        />
        <CUIcon view-box="0 0 24 24" color="primary">
          keyboard_arrow_right
        </CUIcon>
      </div>
    </div>

    <div class="d-flex justify-space-between">
      <div class="d-flex flex-column">
        <div>
          <span class="text-gray-mid-light margin-r-1">ID {{ managedId }}</span>
          {{ tripType }}
        </div>
        <div class="d-flex align-center">
          {{ vehicles }}
          <CUIcon view-box="0 0 24 24" color="gray-light" class="margin-x-1">
            person
          </CUIcon>
          {{ passengerCount }}
        </div>
        <div class="flex-grow-1" />
        <VehicleTypeIcon
          v-if="displayVehicleType"
          :vehicle-type-label="displayVehicleType"
          width="100"
          height="60"
        />
      </div>
      <div class="d-flex flex-column text-right">
        <div>
          {{ reservationStatus }}
        </div>
        <div>
          {{ pickupDate }}
        </div>
        <div>
          {{ pickupTime }}
        </div>
        <div class="font-bold">
          {{ price }}
        </div>
        <div>
          {{ paymentStatus }}
        </div>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { TableViewReservation, TableViewStop, Trip } from '@/models/dto'
import VehicleTypeIcon from '@/components/VehicleTypeIcon.vue'
import {
  currencyFilter,
  formatPickupDestinationTextFromAddresses,
  pluralize,
} from '@/utils/string'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { EventBus } from '@/utils/eventBus'

@Component({ components: { VehicleTypeIcon } })
export default class ReservationIndexMobileCard extends Vue {
  @Prop({ type: Object, required: false, default: null })
  row: TableViewReservation
  @Prop({ type: String, required: true }) uuid: string
  @Prop({ type: Array, required: true }) selectedReservationIds: number[]

  get selected(): boolean {
    return this.selectedReservationIds.includes(this.row.reservationId)
  }

  get firstPickup(): TableViewStop {
    const minOrderIndex = Math.min(
      ...this.row.stops.map((stop) => stop.orderIndex)
    )
    return (
      this.row?.stops?.find((stop) => stop.orderIndex === minOrderIndex) || null
    )
  }

  get firstDropoff(): TableViewStop {
    const minOrderIndex = this.firstPickup?.orderIndex
    if (!minOrderIndex && minOrderIndex !== 0) {
      return null
    }
    return (
      this.row?.stops?.find((stop) => stop.orderIndex === minOrderIndex + 1) ||
      null
    )
  }

  get routeName(): string {
    if (!this.firstPickup || !this.firstDropoff) {
      return ''
    }

    return (
      this.row?.tripRouteName ||
      formatPickupDestinationTextFromAddresses(
        this.firstPickup.address,
        this.firstDropoff.address,
        '>'
      )
    )
  }

  get managedId(): string {
    return this.row?.managedId
  }

  get tripType(): string {
    return this.row?.tripTypeLabel
  }

  get reservationStatus(): string {
    return this.row?.reservationStatusLabel === 'On Hold'
      ? 'Upcoming'
      : this.row?.reservationStatusLabel
  }

  get vehicles(): string {
    const vehicles = this.row?.vehicles
    if (!vehicles) {
      return ''
    }
    if (vehicles.length > 1) {
      const totalVehicleCount = this.row.vehicles.reduce(
        (sum, vehicle) => sum + vehicle.quantity,
        0
      )
      return `${totalVehicleCount} Vehicles`
    }
    const quantity = vehicles[0].quantity
    const label = vehicles[0].vehicleType.label
    return `${quantity} ${pluralize(quantity, label)}`
  }

  get passengerCount(): number {
    return this.row?.passengerCount || null
  }

  get pickupDate(): string {
    if (!this.firstPickup) {
      return ''
    }
    return this.$dayjs(this.firstPickup.pickupDate)
      .tz(this.firstPickup.address.timeZone)
      .format('MM/DD/YY')
  }

  get pickupTime(): string {
    if (!this.firstPickup) {
      return ''
    }
    return this.$dayjs(this.firstPickup.pickupDate)
      .tz(this.firstPickup.address.timeZone)
      .format('h:mm A')
  }

  get price(): string {
    const price = this.row?.marketplaceClientTotal
    if (!price) {
      return ''
    }
    return currencyFilter(price)
  }

  get paymentStatus(): string {
    return this.row?.paymentStatus
  }

  get displayVehicleType(): string {
    return this.row?.vehicles?.[0]?.vehicleType?.label || null
  }

  handleSelectClick(value: boolean): void {
    EventBus.$emit(`toggle-select-item-${this.uuid}`, {
      key: this.row.reservationId,
      value,
    })
  }
}
</script>
