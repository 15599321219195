<template>
  <div>
    <h3 class="subtitle-1">
      {{ $t('reservationDetail.paymentOverview.TITLE') }}
    </h3>
    <v-data-table
      :headers="paymentHeaders"
      :items="transactions"
      hide-default-header
      hide-default-footer
      dense
      class="elevation-0 margin-t-3 border-1 border-solid border-border-gray"
      :style="{ 'margin-bottom': '6px' }"
    >
      <template #item="{ item }">
        <tr class="last-child:font-medium">
          <td class="font-16">
            {{ item.notes ? item.notes : 'Null' }}
          </td>
          <td class="font-16" align="right">
            {{ item.amount }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <div
      v-if="
        !!reservation &&
        reservation.balance > 0 &&
        reservation.showPayRemainingBalance
      "
      class="d-flex bold-links"
      justify="start"
      :style="{ 'margin-top': '18px' }"
    >
      <a
        id="reservation-detail-mark-payment-sent"
        class="font-bold padding-r-3"
        @click="$emit('update:payRemainingBalanceDialogIsOpen', true)"
      >
        {{ $t('reservationDetail.paymentOverview.PAY_REMAINING_BALANCE') }}
      </a>
    </div>
  </div>
</template>
<script lang="ts">
import { ReservationDetail } from '@/models/dto'
import { DateTime } from 'luxon'
import { currencyFilter } from '@/utils/string'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'

@Component({})
export default class ReservationDetailPaymentOverview extends Vue {
  @Prop({ type: Object, required: true })
  readonly reservation: ReservationDetail

  paymentHeaders: DataTableHeader[] = [
    { text: 'Date', value: 'date', align: 'center' },
    { text: 'Description', value: 'paymentType.label', align: 'start' },
    { text: 'Amount', value: 'amount', align: 'end' },
  ]

  get showUsd(): boolean {
    return this.reservation.stops?.[0]?.address?.country === 'CA'
  }

  formattedNotes(base: string): string {
    return this.showUsd ? `${base} (USD)` : base
  }

  get transactions(): {
    notes: string
    amount: string
  }[] {
    const transactions = [
      {
        notes: this.formattedNotes(
          this.$t('reservationDetail.paymentOverview.GRAND_TOTAL').toString()
        ),
        amount: currencyFilter(this.reservation.amount),
      },
    ]

    let autoPayOn = ''
    if (this.reservation.dueDate) {
      const payByDate = this.reservation.dueDate
        ? DateTime.fromISO(this.reservation.dueDate).toFormat('LL/dd/yy')
        : ''
      const label =
        this.reservation.paymentStatus === 'not_paid'
          ? this.$t('reservationDetail.paymentOverview.BILL_AFTER_SERVICES')
          : this.$t('reservationDetail.paymentOverview.AUTO_PAY_ON', {
              payByDate,
            })
      autoPayOn = `(${label})`
    }

    transactions.push({
      notes: this.formattedNotes(
        this.$t('reservationDetail.paymentOverview.REMAINING_BALANCE', {
          autoPayOn,
        }).toString()
      ),
      amount: currencyFilter(this.reservation.balance),
    })
    return transactions
  }
}
</script>
