<template>
  <div
    class="gray-box background-border-gray border-radius-regular position-relative overflow-hidden"
    :class="`gray-box-${type}`"
  />
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class SkeletonBox extends Vue {
  @Prop({ type: String, default: 'default' }) readonly type: string
}
</script>

<style lang="scss">
@import '~@/scss/colors';
// With some help from:
// https://markus.oberlehner.net/blog/skeleton-loading-animation-with-vue/
.gray-box {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.gray-box-text {
  height: 22px;
}

.gray-box-header {
  height: 26px;
}

.gray-box-button {
  height: 44px;
}
</style>
