<template>
  <div class="dialog-background w-full-vw h-full-vh position-absolute z-100">
    <v-dialog
      :value="true"
      persistent
      :fullscreen="fullscreen"
      max-width="744"
    >
      <div
        class="d-flex flex-column align-center h-full"
        :class="{
          'padding-a-8': fullscreen,
          'padding-x-18 padding-t-18 padding-b-14 justify-center': !fullscreen
        }"
      >
        <div v-if="fullscreen" class="w-full d-flex justify-center margin-b-6">
          <CharterUPLogo width="120" />
        </div>
        <h2 class="margin-b-6 text-center">We just need a few details before we can proceed</h2>
        <CustomerRequiredDetailsForm />
        <v-btn
            id="required-customer-information-back-to-sign-in-button"
            class="margin-t-3 font-medium"
            color="gray-text"
            plain
            small
            @click="logout"
          >
            Back to Log In
          </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import CustomerRequiredDetailsForm from '@/components/CustomerRequiredDetailsForm.vue'
import CharterUPLogo from '@/icons/CharterUPLogo.vue'
import auth from '@/store/modules/auth'

@Component({ components: { CustomerRequiredDetailsForm, CharterUPLogo } })
export default class CustomerRequiredInfoDialog extends Vue {
  get fullscreen(): boolean {
    return this.$vuetify.breakpoint.xsOnly
  }

  logout(): void {
    auth.logout()
  }

}
</script>


<style scoped lang="scss">
@import '@/scss/colors.scss';

.dialog-background {
  backdrop-filter: blur(3px);
}

</style>
