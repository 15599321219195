<template>
  <div>
    <div
      v-for="(textItem, textItemIndex) in modalText"
      :id="`info-modal-text-item-${textItem.subLabel}-${textItemIndex}`"
      :key="`info-modal-text-item-${textItem.subLabel}-${textItemIndex}`"
      class="d-flex align-center"
    >
      <p :style="{ 'line-height': '1.5em' }">
        <b class="font-bold">{{ textItem.subLabel }}</b>
        {{ "&ensp;–&ensp;" + textItem.body }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { InfoModalDetail } from '@/models/InfoModalDetail'
import { TranslateResult } from 'vue-i18n';
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class InfoModalTextItemized extends Vue {
  @Prop({ type: Object, required: true }) readonly modalDetail: InfoModalDetail

  get modalText(): { subLabel: string; body: TranslateResult }[] {
    return this.modalDetail?.modalText || []
  }
}
</script>
