<template>
  <div class="text-gray-light">
    {{ $t('reservationDetail.tripInfo.ESTIMATED_ARRIVAL') }}
    {{ formattedDisplayTime }}
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { formatStopTime } from '@/utils/datetime'

@Component({})
export default class TripInfoItineraryDropoffTime extends Vue {
  @Prop({ type: String, required: true }) dropoffDatetime: string
  @Prop({ type: String, required: true }) zoneId: string

  get formattedDisplayTime(): string {
    return formatStopTime(this.dropoffDatetime, this.zoneId)
  }
}
</script>
