<template>
  <div
    class="border-y-1 border-solid border-midnight-gray-50 background-midnight-gray-white omnibus border-radius-16 border-x-1"
  >
    <div
      v-if="formattedCancellationDate"
      class="d-flex justify-center font-14 background-midnight-gray-50 border-radius-top-right-12 border-radius-top-left-12"
    >
      <p class="font-bold margin-y-2">
        {{ $t('reservationDetail.cancellationSummary.TRIP_CANCELLED_ON') }}
        {{ formattedCancellationDate }}
      </p>
    </div>
    <ReservationCancellationSummaryTripInfo
      :reservation="reservation"
      class="padding-x-5 padding-t-3 padding-b-5 margin-a-2"
    />
    <v-divider />
    <ReservationCancellationSummaryCharges :penalty-info="penaltyInfo" />
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ClientCancellationPenaltyInfo, ReservationDetail } from '@/models/dto'
import ReservationCancellationSummaryTripInfo from '@/components/ReservationCancellationSummaryTripInfo.vue'
import ReservationCancellationSummaryCharges from '@/components/ReservationCancellationSummaryCharges.vue'

@Component({
  components: {
    ReservationCancellationSummaryTripInfo,
    ReservationCancellationSummaryCharges,
  },
})
export default class ReservationCancellationSummary extends Vue {
  @Prop({ type: Object, required: true })
  readonly reservation!: ReservationDetail
  @Prop({ type: Object, required: true })
  readonly penaltyInfo!: ClientCancellationPenaltyInfo

  get formattedCancellationDate(): string {
    const { cancelledOn } = this.reservation || {}
    if (!cancelledOn) {
      return ''
    }
    return this.$dayjs(cancelledOn).format('MMMM D, h:mm A')
  }

}
</script>
