<template>
  <GmapInfoWindow
    :position="position"
    :opened="opened"
  >
    <div>
      <b>{{ content }}</b>
    </div>
  </GmapInfoWindow>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'

@Component({
  components: {
    GmapInfoWindow: () =>
      import('vue2-google-maps/dist/components/infoWindowImpl'),
  },
})
export default class LiveTrackingVehicleInfoWindow extends Vue {
  @Prop({ type: Boolean, default: false }) readonly opened: boolean
  @Prop({ type: Object, default: null }) readonly position: object
  @Prop({ type: String, required: true }) readonly content: string
}
</script>
