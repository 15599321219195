<template>
  <v-form ref="invite-user-form">
    <v-row dense>
      <v-col cols="12">
        <label>
          {{ $t('reservationDetail.tripContactForm.form.email.LABEL') }}
        </label>
        <v-text-field
          id="invite-user-email-text-field"
          v-model="customer.email"
          :placeholder="
            $t('reservationDetail.tripContactForm.form.email.PLACEHOLDER')
          "
          :rules="[
            (v) =>
              !!v ||
              $t('reservationDetail.tripContactForm.form.email.rules.REQUIRED'),
            (v) =>
              /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,13}$/.test(v) ||
              $t('reservationDetail.tripContactForm.form.email.rules.VALID'),
          ]"
          hide-details="auto"
          required
          validate-on-blur
          outlined
        />
      </v-col>
      <template v-if="tripContactMode">
        <v-col cols="6">
          <label>
            {{ $t('reservationDetail.tripContactForm.form.firstName.LABEL') }}
          </label>
          <v-text-field
            id="invite-user-first-name-text-field"
            v-model="customer.firstName"
            :placeholder="
              $t('reservationDetail.tripContactForm.form.firstName.PLACEHOLDER')
            "
            :rules="[
              (v) =>
                !!v ||
                $t(
                  'reservationDetail.tripContactForm.form.firstName.rules.REQUIRED'
                ),
            ]"
            hide-details="auto"
            required
            validate-on-blur
            outlined
          />
        </v-col>
        <v-col cols="6">
          <label>
            {{ $t('reservationDetail.tripContactForm.form.lastName.LABEL') }}
          </label>
          <v-text-field
            id="invite-user-last-name-text-field"
            v-model="customer.lastName"
            :placeholder="
              $t('reservationDetail.tripContactForm.form.lastName.PLACEHOLDER')
            "
            :rules="[
              (v) =>
                !!v ||
                $t(
                  'reservationDetail.tripContactForm.form.lastName.rules.REQUIRED'
                ),
            ]"
            hide-details="auto"
            required
            validate-on-blur
            outlined
          />
        </v-col>
        <v-col cols="12">
          <label>
            {{ $t('reservationDetail.tripContactForm.form.phoneNumber.LABEL') }}
          </label>
          <v-text-field
            id="invite-user-phone-text-field"
            v-model="customer.phone"
            v-mask="['(###) ###-####', '+## ## #### ####']"
            :placeholder="
              $t(
                'reservationDetail.tripContactForm.form.phoneNumber.PLACEHOLDER'
              )
            "
            :rules="[
              (v) =>
                !!v ||
                $t(
                  'reservationDetail.tripContactForm.form.phoneNumber.rules.REQUIRED'
                ),
              phoneRaw.length > 9 ||
                $t(
                  'reservationDetail.tripContactForm.form.phoneNumber.rules.VALID'
                ),
              phoneRaw.length <= 12 ||
                $t(
                  'reservationDetail.tripContactForm.form.phoneNumber.rules.VALID'
                ),
            ]"
            hide-details="auto"
            required
            validate-on-blur
            outlined
          />
        </v-col>
      </template>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import { InviteCustomerPayload } from '@/models/dto'
import deepClone from '@/utils/deepClone'
import { phoneRaw } from '@/utils/phone'
import { validateEmailAddress } from '@/utils/string'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class CustomerInviteForm extends Vue {
  @Prop({ type: Boolean }) readonly triggerSubmit: boolean
  @Prop({ type: String, default: '' }) readonly initialEmail: string
  @Prop({ type: Boolean }) readonly tripContactMode: boolean

  @Watch('triggerSubmit')
  submitTriggered(value: boolean): void {
    if (!value) {
      return
    }
    this.submit()
  }

  @Watch('initialEmail', { immediate: true })
  initialEmailChanged(value: string): void {
    this.customer.email = value
  }

  customer: InviteCustomerPayload = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  }
  validateEmailAddress = validateEmailAddress

  get phoneRaw(): string {
    return phoneRaw(this.customer.phone)
  }

  submit(): void {
    const form = this.$refs['invite-user-form'] as any
    if (!form.validate()) {
      this.$emit('invalid')
      return
    }
    const payload = deepClone(this.customer)
    payload.phone = this.phoneRaw

    this.$emit('submit', payload)
  }
}
</script>
