<template>
  <div>
    <div
      v-for="(textItem, textItemIndex) in modalText"
      :id="`info-modal-text-item-${textItem.subLabel}-${textItemIndex}`"
      :key="`info-modal-text-item-${textItem.subLabel}-${textItemIndex}`"
      :class="{ 'margin-b-3': textItemIndex < modalText.length - 1 }"
    >
      <h4
        class="font-bold"
        :class="{ 'margin-t-2': textItemIndex !== 0 }"
      >
        {{ textItem.subLabel }}
      </h4>
      <p :style="{ 'line-height': '1.5em' }">
        {{ textItem.body }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { InfoModalDetail } from '@/models/InfoModalDetail'
import { TranslateResult } from 'vue-i18n';
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class InfoModalTextStandard extends Vue {
  @Prop({ type: Object, required: true }) readonly modalDetail: InfoModalDetail

  get modalText(): { subLabel: string; body: TranslateResult }[] {
    return this.modalDetail?.modalText || []
  }
}
</script>
