<template>
  <CUModal :value="value" max-width="518px" @input="$emit('input', $event)">
    <template #title>Delete Rider</template>
    <template #text>
      <div class="w-full">
        Are you sure you want to delete the user with employeeId
        {{ rider.employeeId }}?
      </div>
      <AlertBox :active="!!errorMessage" type="error" class="margin-b-3">
        {{ errorMessage }}
      </AlertBox>
    </template>
    <template #actions>
      <div class="d-flex grow" />
      <v-btn
        id="invite-user-team-cancel"
        small
        text
        class="margin-r-2"
        @click="close"
      >
        Cancel
      </v-btn>
      <v-btn
        id="invite-user-team-submit"
        class="error text-white"
        small
        @click="send"
      >
        Delete Rider
      </v-btn>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import rider from '@/services/rider'
import auth from '@/store/modules/auth'
import { TableViewRider } from '@/models/dto/TableViewRider'
import axios from 'axios'
import AlertBox from '@/components/AlertBox.vue'

@Component({ components: { AlertBox } })
export default class OrganizationDeleteRiderDialog extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value: boolean
  @Prop({ type: Object, default: () => {} }) readonly rider: TableViewRider

  success = false
  errorMessage = ''

  close(): void {
    this.$emit('input', false)
  }
  backToSite(): void {
    this.success = false
    this.close()
  }
  async send(): Promise<void> {
    this.errorMessage = ''
    const payload = {
      customerAccountId: auth.customerAccount.customerAccountId,
      riderId: this.rider.riderId,
    }
    try {
      await rider.delete(payload)
      this.success = true
      this.close()
      this.$emit('success')
      // eslint-disable-next-line prettier/prettier
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errorMessage = e.response?.data?.message
        this.errorMessage = errorMessage
      }
    }
  }
}
</script>
