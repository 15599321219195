<template>
  <div>
    <h2 class="margin-t-12 margin-b-3 font-medium">
      Payment balance update
    </h2>
    <v-row>
      <v-col cols="7">
        <template v-if="mustHaveACreditCard">
          <template v-if="reservationPickupDateWithin7Days">
            <p>
              {{ $t('itineraryModification.paymentBalanceUpdate.UPCOMING[0]') }}
            </p>
            <br />
            <p class="margin-b-2">
              {{ $t('itineraryModification.paymentBalanceUpdate.UPCOMING[1]') }}
            </p>
          </template>
          <template v-else-if="isCreditCardPaymentMethod">
            <p>
              {{ $t('itineraryModification.paymentBalanceUpdate.CREDIT_CARD[0]') }}
            </p>
            <br />
            <p class="margin-b-2">
              {{ $t('itineraryModification.paymentBalanceUpdate.CREDIT_CARD[1]') }}
            </p>
          </template>
          <PaymentProfileV2
            v-if="currentPaymentProfile"
            key="payment-profile"
            :payment-profile="currentPaymentProfile"
            is-selected
          />
          <p v-else class="margin-t-3 font-medium">
            <a @click="handleClickAddNewCard">
              + {{ $t('paymentProfileSelector.ADD_NEW_CARD') }}
            </a>
          </p>
          <PaymentProfileCardModal
            v-model="cardDialogIsOpen"
            @input="handleClose"
            @card-added="newProfile => setCurrentPaymentProfile(newProfile, true)"
          />
          <CheckoutTerms class="margin-t-2 margin-b-n1" />
        </template>
        <template v-else-if="isPurchaseOrderPaymentMethodType">
          <p>
            {{ $t('itineraryModification.paymentBalanceUpdate.PO[0]') }}
          </p>
          <br />
          <p>
            {{ $t('itineraryModification.paymentBalanceUpdate.PO[1]') }}
          </p>
          <CheckoutTerms class="margin-t-2 margin-b-n1" />
        </template>
        <template v-else-if="isBillAfterServicesPaymentMethodType">
          <p>
            {{ $t('itineraryModification.paymentBalanceUpdate.UPCOMING[0]') }}
          </p>
          <br />
          <p>
            {{ $t('itineraryModification.paymentBalanceUpdate.UPCOMING[1]') }}
          </p>
          <CheckoutTerms class="margin-t-2 margin-b-n1" />
        </template>
        <template v-else-if="isWirePaymentMethod">
          <p>
            {{ $t('itineraryModification.paymentBalanceUpdate.ACH_DIRECT_DEPOSIT[0]') }}
          </p>
          <br />
          <p>
            {{ $t('itineraryModification.paymentBalanceUpdate.ACH_DIRECT_DEPOSIT[1]') }}
          </p>
          <br />
          <p>
            {{ $t('itineraryModification.paymentBalanceUpdate.ACH_DIRECT_DEPOSIT[2]') }}
          </p>
          <ul class="margin-t-2 margin-l-2">
            <li>&#8226; {{ $t('itineraryModification.paymentBalanceUpdate.ACH_DIRECT_DEPOSIT[3]') }}</li>
            <li>&#8226; {{ $t('itineraryModification.paymentBalanceUpdate.ACH_DIRECT_DEPOSIT[4]') }}</li>
            <li>&#8226; {{ $t('itineraryModification.paymentBalanceUpdate.ACH_DIRECT_DEPOSIT[5]') }}</li>
          </ul>
          <CheckoutTerms class="margin-t-2 margin-b-n1" />
        </template>
        <template v-else-if="isCheckPaymentMethod">
          <p>
            {{ $t('itineraryModification.paymentBalanceUpdate.CHECK[0]') }}
          </p>
          <br />
          <p>
            {{ $t('itineraryModification.paymentBalanceUpdate.CHECK[1]') }}
          </p>
          <br />
          <p>
            {{ $t('itineraryModification.paymentBalanceUpdate.CHECK[2]') }}
          </p>
          <br />
          <b>
            {{ $t('itineraryModification.paymentBalanceUpdate.CHECK[3]') }}
          </b>
          <p>
            {{ $t('itineraryModification.paymentBalanceUpdate.CHECK[4]') }}
          </p>
          <p>
            {{ $t('itineraryModification.paymentBalanceUpdate.CHECK[5]') }}
          </p>
          <CheckoutTerms class="margin-t-2 margin-b-n1" />
        </template>
      </v-col>
      <v-col>
        <TripModificationPayment :show-titles="false" />
      </v-col>
    </v-row>

  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import tripModificationStore from '@/store/modules/tripModification'
import PaymentProfileV2 from '@/components/PaymentProfileV2.vue'
import {
  Address,
  ComputedPaymentProfile,
  PaymentMethodPayload,
  StagePaymentMethod,
  CustomerPaymentProfile,
} from '@/models/dto'
import { PaymentMethodTypeId, PaymentMethodTypeKey, PaymentTypeKey } from '@/utils/enum'
import { isPaymentMethodAllowed } from '@/utils/payment'
import { FormattedPaymentMethod } from '@/models/FormattedPaymentMethod'
import { ReservationDetail } from '@/models/dto'
import CheckoutTerms from '@/components/CheckoutTerms.vue'
import TripModificationPayment from './TripModificationPayment.vue'
import { DateTime } from 'luxon'
import paymentProfile from '../services/paymentProfile'
import PaymentProfileCardModal from '@/components/PaymentProfileCardModal.vue'

@Component({
  components: {
    PaymentProfileV2,
    CheckoutTerms,
    TripModificationPayment,
    PaymentProfileCardModal,
  },
})
export default class TripModificationBalanceUpdateReview extends Vue {
  @Prop({ type: Object, required: true }) readonly reservation: ReservationDetail
  tripModificationStore = tripModificationStore
  checkWireAddress: Address = {} as Address
  currentPaymentProfile: ComputedPaymentProfile = null
  cardDialogIsOpen = false

  get balancePaymentMethods(): StagePaymentMethod[] {
    return this.reservation?.stagePaymentMethods?.balancePaymentMethods
  }

  get isCreditCardPaymentAllowed(): boolean {
    return isPaymentMethodAllowed(this.balancePaymentMethods, PaymentMethodTypeId.CreditCard)
  }

  get isCheckPaymentAllowed(): boolean {
    return isPaymentMethodAllowed(this.balancePaymentMethods, PaymentMethodTypeId.Check)
  }

  get isAchPaymentAllowed(): boolean {
    return isPaymentMethodAllowed(this.balancePaymentMethods, PaymentMethodTypeId.ACH)
  }

  get activePaymentMethod(): PaymentMethodTypeKey {
    if (this.isCreditCardPaymentAllowed) {
      return PaymentMethodTypeKey.CreditCard
    }
    if (this.isAchPaymentAllowed) {
      return PaymentMethodTypeKey.ACH
    }
    if (this.isCheckPaymentAllowed) {
      return PaymentMethodTypeKey.Check
    }
    return null
  }

  get isCreditCardPaymentMethod(): boolean {
    return this.activePaymentMethod === PaymentMethodTypeKey.CreditCard
  }

  get isCheckPaymentMethod(): boolean {
    return this.activePaymentMethod === PaymentMethodTypeKey.Check
  }

  get isWirePaymentMethod(): boolean {
    return this.activePaymentMethod === PaymentMethodTypeKey.ACH
  }

  get isPurchaseOrderPaymentMethodType(): boolean {
    return this.reservation.paymentTypeKey === PaymentTypeKey.PurchaseOrder
  }

  get isBillAfterServicesPaymentMethodType(): boolean {
    return this.reservation.paymentTypeKey === PaymentTypeKey.BillAfterServices
  }

  get reservationPickupDateWithin7Days(): boolean {
    const now = DateTime.now()
    const date = DateTime.fromISO(this.reservation?.pickupDate)
    const diff = date.diff(now, 'days').days
    return diff <= 7
  }

  handleClose(): void {
    this.cardDialogIsOpen = false
  }

  handleClickAddNewCard(): void {
    this.cardDialogIsOpen = true
  }

  get mustHaveACreditCard(): boolean {
    if (this.isPurchaseOrderPaymentMethodType || this.isBillAfterServicesPaymentMethodType) {
      // Since the customer is explicitly paying either after the trip or by a pre-arranged
      // payment order agreement, we do not need to collect card information from them.
      return false
    }

    // Otherwise, we require a card only if the customer is already using a card
    // for their balance payment method OR if the pickup is close enough that we
    // need more confidence the balance can actually get collected.
    return this.reservationPickupDateWithin7Days || this.isCreditCardPaymentMethod
  }

  async mounted() {
    tripModificationStore.setForwardButtonDisabled(this.mustHaveACreditCard)

    const { data } = await paymentProfile.getPaymentProfileUsedForCheckout(this.reservation?.managedId)
    const cardUsedOnCheckout = data
    if (cardUsedOnCheckout != null && cardUsedOnCheckout?.label != null && cardUsedOnCheckout?.mask != null) {
      this.setCurrentPaymentProfile(cardUsedOnCheckout, false)
    }
  }

  setCurrentPaymentProfile(customerPaymentProfile: CustomerPaymentProfile, newProfile: boolean): void {
    if (customerPaymentProfile == null || customerPaymentProfile.label == null || customerPaymentProfile.mask == null) {
      return
    }

    const address = customerPaymentProfile?.address
    this.currentPaymentProfile = {
      customerPaymentProfileId: customerPaymentProfile?.customerPaymentProfileId,
      mask: customerPaymentProfile?.mask,
      typeLabel: customerPaymentProfile?.label,
      isDefault: false,
      accountHolderName: customerPaymentProfile?.accountHolderName,
      expiration: customerPaymentProfile?.expiration,
      address: {
        street1: address?.street1,
        street2: address?.street2,
        city: address?.city,
        state: address?.state,
        postalCode: address?.postalCode,
        completeAddress: address?.completeAddress,
        addressName: address?.addressName,
        timeZone: null,
        lat: null,
        lng: null
      }
    }

    tripModificationStore.setForwardButtonDisabled(false)
    if (newProfile) {
      tripModificationStore.setNewCustomerPaymentProfileId(customerPaymentProfile?.customerPaymentProfileId)
    }

  }
}
</script>
