<template>
  <div>
    <div
      class="d-flex margin-b-4"
      :class="
        $vuetify.breakpoint.xsOnly ? 'flex-column align-start' : 'align-center'
      "
    >
      <div class="d-flex-column padding-r-4">
        <h2 class="margin-b-2">{{ $t('accountItems.USERS_AND_TEAMS') }}</h2>
        <h3 class="font-14 margin-b-3">{{ $t('accountItems.USERS_AND_TEAMS_DESCRIPTION') }}</h3>
      </div>
      <v-row>
        <v-spacer />
        <v-col
          class="d-flex align-center"
          :class="{ 'padding-l-6': $vuetify.breakpoint.xsOnly }"
          cols="12"
          sm="auto"
        >
          <v-text-field
            id="organization-search"
            v-model="search"
            solo
            outlined
            :append-icon="search ? 'close' : 'search'"
            flat
            class="margin-r-3 search-bar"
            placeholder="Search users"
            @click:append="clearSearch"
          />
          <OrganizationInviteUserTeamDialog
            v-if="$vuetify.breakpoint.smAndUp"
            :id="`organization-add-user`"
            :teams="teams"
            @success="$emit('success')"
          />
          <OrganizationTeamsActionsMenu
            v-if="$vuetify.breakpoint.smAndUp"
            :teams="teams"
            :is-enterprise-account="isEnterpriseAccount"
            @upload-image="$emit('upload-image', $event)"
            @success="$emit('success')"
          />
        </v-col>
      </v-row>
    </div>

    <OrganizationTeam
      v-for="(team, teamIndex) in teams"
      :key="`${team.customerAccountId}-${teamIndex}`"
      :index="teamIndex"
      :team="team"
      :teams="teams"
      :search="search"
      @success="$emit('success')"
      @upload-image="team.teamLogoImage = $event"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import OrganizationTeam from '@/components/OrganizationTeam.vue'
import OrganizationInviteUserTeamDialog from '@/components/OrganizationInviteUserTeamDialog.vue'
import OrganizationTeamsActionsMenu from '@/components/OrganizationTeamsActionsMenu.vue'
import { Team } from '@/models/dto'

@Component({
  components: {
    OrganizationTeam,
    OrganizationInviteUserTeamDialog,
    OrganizationTeamsActionsMenu,
  },
})
export default class OrganizationTeams extends Vue {
  @Prop({ type: Array, required: true }) readonly teams: Team[]
  @Prop({ type: Boolean }) readonly isEnterpriseAccount: boolean

  search = ''

  clearSearch(): void {
    if (this.search) {
      this.search = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors.scss';
.v-text-field {
  &.search-bar {
    height: 40px;
    ::v-deep .v-input__control {
      min-height: 40px;
      .v-input__slot {
        min-height: 40px;
        .v-text-field__slot {
          height: 40px;
          input {
            height: 40px;
            max-height: 40px;
          }
        }
        .v-input__icon--append {
          margin-right: 5px;
          margin-top: -4px;
          .v-icon {
            color: rgba($black, 0.25);
          }
        }
      }
    }
  }
}
</style>
