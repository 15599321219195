<template>
  <div>
    <div class="bid-header font-24">
      {{ $t('quoteDetail.bidList.UNAVAILABLE_COMPANIES') }}
    </div>
    <v-row>
      <v-col
        v-for="(bid, bidIndex) in bids"
        :key="`${bid.companyId}-${bidIndex}`"
        cols="12"
      >
        <BidCard
          :id="`provider-bidcard-${bidIndex}`"
          preferred
          :go-to="{
            name: 'provider-detail',
            params: {
              quoteId: quoteId,
              providerId: bid.companyId,
            },
          }"
          :bid="bid"
          :is-elite="isElite"
          :is-branded-quote-flow-enabled="isBrandedQuoteFlowEnabled"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import BidCard from '@/components/BidCard.vue'
import { QuoteDetailV2Bid } from '../models/dto/QuoteDetailV2'

@Component({ components: { BidCard } })
export default class QuoteDetailBidListUnavailableBids extends Vue {
  @Prop({ required: true, type: Array }) bids!: QuoteDetailV2Bid[]
  @Prop({ required: true, type: Boolean }) isElite!: boolean
  @Prop({ required: true, type: Number }) quoteId!: number
  @Prop({ required: true, type: Boolean }) isBrandedQuoteFlowEnabled: boolean

}
</script>

<style lang="scss" scoped>
@import '@/scss/colors';

.bid-header {
  padding-top: 15px;
  margin-bottom: -6px;
}
</style>
