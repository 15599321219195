import { inject, provide, ref } from 'vue'
import type { Auth0Plugin } from '@/shims-auth0'

const AUTH0_SYMBOL = Symbol('auth0')

export const provideAuth0 = (auth0Instance: any) => {
  provide(AUTH0_SYMBOL, auth0Instance)
}

export const useAuth0 = (): Auth0Plugin | null => {
  const auth0: Auth0Plugin | null  = inject(AUTH0_SYMBOL)

  if (!auth0) {
    console.warn('[useAuth0] Auth0 is not available. Ensure it is provided in the app setup.')
    return null
  }

  return auth0
}
