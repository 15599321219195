<template>
  <div class="cu-timeline-item d-flex">
    <div class="cu-timeline-item__divider position-relative">
      <div
        class="cu-timeline-item__line w-0 position-absolute z-0 border-l-2 border-y-0 border-r-0 border-solid border-black"
        :style="{ left: '50%', transform: 'translateX(-50%)', top: firstStop ? '12px' : '0px', bottom: lastStop ? 'calc(100% - 12px)' : '0px' }"
      />

      <SkeletonBox v-if="loading" class="h-16 w-16 border-radius-round" />
      <div
        class="position-relative top-8"
        v-html="iconHTML"
      />
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import SkeletonBox from '@/components/SkeletonBox.vue'
import { useTheme } from '@/composables/useTheme'
import { generateSalesBotStopIconSVG } from '@/utils/icon'
import { computed } from 'vue'

const { themes } = useTheme()

const props = defineProps({
  loading: { type: Boolean, required: false },
  firstStop: { type: Boolean, required: false },
  lastStop: { type: Boolean, required: false },
})

const iconHTML = computed((): string => {
  return generateSalesBotStopIconSVG(themes.value?.light?.primary, props.lastStop, '16px', '16px')
})
</script>
