var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"d-flex flex-row justify-end"},[(_vm.isModeView)?_c('v-btn',{attrs:{"id":"my-profile-edit-button","color":"primary","small":""},on:{"click":_vm.startEdit}},[_vm._v(" "+_vm._s(_vm.$t('common.EDIT'))+" ")]):_vm._e(),(_vm.isModeEdit)?_c('v-btn',{staticClass:"margin-r-2",attrs:{"id":"my-profile-cancel-button","color":"error","small":"","outlined":"","loading":_vm.submitting},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('common.CANCEL'))+" ")]):_vm._e(),(_vm.isModeEdit)?_c('v-btn',{attrs:{"id":"my-profile-submit-button","disabled":_vm.disabled,"color":"primary","small":"","loading":_vm.submitting},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('common.SAVE'))+" ")]):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-row',{class:{
          'justify-center': _vm.$vuetify.breakpoint.xsOnly,
          'justify-start': _vm.$vuetify.breakpoint.smAndUp,
        }},[_c('v-col',{attrs:{"cols":"auto"}},[_c('img',{staticClass:"border-radius-round w-100 h-100 object-fit-cover",attrs:{"width":"100","src":_vm.avatarPhoto}})])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-row',{class:{
          'justify-center': _vm.$vuetify.breakpoint.xsOnly,
          'justify-start': _vm.$vuetify.breakpoint.smAndUp,
        }},[_c('v-col',{class:{ 'text-center': _vm.$vuetify.breakpoint.xsOnly },attrs:{"cols":"auto"}},[_c('p',{staticClass:"margin-b-2"},[_vm._v(_vm._s(_vm.$t('profile.PROFILE.YOUR_AVATAR')))]),_c('v-btn',{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.disabled},on:{"click":_vm.choosePhoto}},[_vm._v(" "+_vm._s(_vm.$t('common.UPDATE'))+" ")]),_c('input',{ref:"avatarUpload",attrs:{"type":"file","accept":"image/*","hidden":""},on:{"change":_vm.addPhotoForUpload}})],1)],1)],1)],1),_c('v-divider',{staticClass:"margin-t-6 margin-b-6"}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.profile.customer)?_c('v-form',{ref:"profileSettingsForm",attrs:{"lazy-validation":""}},[_c('label',[_vm._v(_vm._s(_vm.$t('profile.PROFILE.FORM.FIRST_NAME.LABEL')))]),_c('v-text-field',{ref:"firstName",attrs:{"id":"my-profile-text-first-name","value":_vm.profile.customer.firstName,"rules":[
            (v) =>
              !!v || _vm.$t('profile.PROFILE.FORM.FIRST_NAME.RULES.REQUIRED'),
          ],"disabled":_vm.disabled,"required":"","outlined":"","tabindex":"1"},on:{"input":function($event){return _vm.profile.setCustomerFirstName($event)}}}),_c('label',[_vm._v(_vm._s(_vm.$t('profile.PROFILE.FORM.LAST_NAME.LABEL')))]),_c('v-text-field',{ref:"lastName",attrs:{"id":"my-profile-text-last-name","value":_vm.profile.customer.lastName,"rules":[
            (v) => !!v || _vm.$t('profile.PROFILE.FORM.LAST_NAME.RULES.REQUIRED'),
          ],"required":"","disabled":_vm.disabled,"outlined":"","tabindex":"2"},on:{"input":function($event){return _vm.profile.setCustomerLastName($event)}}}),_c('label',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('profile.PROFILE.FORM.EMAIL.LABEL'))+" ")]),_c('v-tooltip',{attrs:{"top":"","color":"gray"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('CUIcon',_vm._g(_vm._b({staticClass:"margin-l-1",attrs:{"color":"gray-light","view-box":"0 0 24 24","width":"16","height":"16"}},'CUIcon',attrs,false),on),[_vm._v(" info ")])]}}],null,false,1908311629)},[_c('span',{staticClass:"text-white"},[_vm._v(" To update your email address, please contact our support team for assistance. ")])])],1),_c('v-text-field',{ref:"email",attrs:{"id":"my-profile-text-email","value":_vm.profile.customer.email,"disabled":"","outlined":"","tabindex":"3"}}),_c('div',{staticClass:"d-flex align-center"},[_c('label',[_vm._v(_vm._s(_vm.$t('profile.PROFILE.FORM.PHONE.LABEL')))]),(_vm.smsConfirmed)?_c('CUIcon',{staticClass:"margin-l-1 cursor-default",attrs:{"color":"primary","view-box":"0 0 24 24","width":"16px","height":"16px"}},[_vm._v(" verified ")]):_vm._e(),_c('v-spacer'),(!_vm.smsConfirmed && !_vm.isModeEdit)?_c('button',{staticClass:"font-medium font-14 cursor-pointer",class:{
              'text-gray-medium-light cursor-default': _vm.isModeEdit,
              'text-primary': !_vm.isModeEdit,
            },attrs:{"id":"confirm-phone-number-button","type":"button","disabled":_vm.isModeEdit},on:{"click":_vm.profile.beginSMSConfirmation}},[_vm._v(" "+_vm._s(_vm.$t('common.VERIFY'))+" ")]):_vm._e()],1),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['(###) ###-####', '+## ## #### ####']),expression:"['(###) ###-####', '+## ## #### ####']"}],ref:"phoneNumber",attrs:{"id":"my-profile-text-phone","value":_vm.profile.customer.phone,"rules":[
            (v) => !!v || _vm.$t('profile.PROFILE.FORM.PHONE.RULES.REQUIRED'),
            _vm.phoneRawNumber.length > 9 ||
              _vm.$t('profile.PROFILE.FORM.PHONE.RULES.VALID'),
            _vm.phoneRawNumber.length <= 12 ||
              _vm.$t('profile.PROFILE.FORM.PHONE.RULES.VALID'),
          ],"required":"","validate-on-blur":"","disabled":_vm.disabled,"outlined":"","tabindex":"4"},on:{"input":function($event){return _vm.profile.setCustomerPhone($event)}}})],1):_vm._e(),_c('p',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t('profile.PROFILE.FORM.PHONE.LEGALESE_1'))+" "),_c('a',{attrs:{"href":"https://www.charterup.com/terms-and-conditions/","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('common.TERMS_OF_SERVICE'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('profile.PROFILE.FORM.PHONE.AND'))+" "),_c('a',{staticClass:"mr-n1",attrs:{"href":"https://www.charterup.com/privacy-policy/","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('common.PRIVACY_POLICY'))+" ")]),_vm._v(" . ")])],1)],1),_c('PhoneOTCVerificationDialog',{attrs:{"value":_vm.profile.verifyingPhone,"phone":_vm.phoneRawNumber,"customer-id":_vm.profile.customer.userId,"verified":_vm.smsConfirmed,"closeable":""},on:{"retry":_vm.profile.beginSMSConfirmation,"confirm":function($event){return _vm.profile.setPhoneVerified()},"input":function($event){return _vm.profile.setVerifyingPhone($event)},"skip":function($event){return _vm.profile.setVerifyingPhone(false)},"close":function($event){return _vm.profile.setVerifyingPhone(false)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }