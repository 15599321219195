<template>
  <CUModal
    :value="value"
    width="700px"
    max-width="800px"
    @input="$emit('input', $event)"
  >
    <template #title>
      <div class="text-midnight-gray-700 font-22 margin-t-3"></div>
    </template>
    <template #text>
      <div>
        <div
          class="text-midnight-gray-700 font-22 font-bold margin-t-3 margin-b-8"
        >
          {{ $t('reservationDetail.cancellationModal.ARE_YOU_SURE') }}
        </div>
        <div class="d-flex justify-center">
          <v-btn
            id="cancellation-confirmation-modal-no-button"
            class="margin-l-2 margin-b-6"
            color="primary"
            width="150px"
            outlined
            text
            small
            @click="close"
          >
            {{ $t('reservationDetail.cancellationModal.NO') }}
          </v-btn>
          <v-btn
            id="cancellation-confirmation-modal-yes-button"
            class="margin-l-2 margin-b-6"
            color="primary"
            width="150px"
            :loading="isSubmitting"
            small
            @click="$emit('cancel')"
          >
            {{ $t('reservationDetail.cancellationModal.YES') }}
          </v-btn>
        </div>
      </div>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop, InjectReactive } from 'vue-property-decorator'

@Component({})
export default class ReservationCancelConfirmModal extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @InjectReactive('isSubmitting') isSubmitting

  close(): void {
    this.$emit('input', false)
  }
}
</script>
<style lang="scss" scoped>
@import '@/scss/colors';
.v-btn.v-btn {
  border-color: $primary !important;
  color: $primary;
}
</style>
