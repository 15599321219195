<template>
  <v-row v-if="company" align="start" no-gutters justify="space-between">
    <v-col cols="auto">
      <v-row no-gutters align="start">
        <v-col cols="12">
          <div class="d-flex align-center">
            <h2 class="margin-b-1">
              {{ company.companyDBAName }}
            </h2>
            <ResellerTooltip v-if="isReseller" :dba="company.companyDBAName" />
          </div>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.smAndUp" cols="auto">
          <CharterUPRating
            v-if="company.rating"
            :style="{ 'margin-top': '1px' }"
            :rating="company.rating"
          />
        </v-col>
        <v-col
          v-if="company.preferred && $vuetify.breakpoint.smAndUp"
          cols="auto"
          class="margin-x-2"
        >
          •
        </v-col>
        <v-col v-if="company.preferred" cols="auto" class="margin-l-0">
          <InfoModal
              v-model="isOpen"
              :modal-detail="certifiedPartnerModalDetail"
              max-width="860px"
            >
              <template #activator="{ on, attrs }">
                <span
                  class="text-black font-12 cursor-pointer"
                  v-bind="attrs"
                  v-on="on"
                >
                  <CertifiedPartnerBadge />
                </span>
              </template>
            </InfoModal>
        </v-col>
        <v-col
          v-if="website && (company.preferred || $vuetify.breakpoint.smAndUp)"
          cols="auto"
          class="margin-x-2"
        >
          •
        </v-col>
        <v-col v-if="website" cols="auto" align-self="start">
          <a
            id="provider-detail-link-website"
            :href="getUrl(website)"
            target="_blank"
            class="d-flex align-center justify-center"
          >
            <CUIcon
              class="w-18 h-18 margin-t-n1 margin-r-1"
              view-box="0 0 24 24"
              :class="{
                'text-primary': !isDisplayPartnerUrlEnabled,
                'text-gray': isDisplayPartnerUrlEnabled
              }"
            >
              open_in_new
            </CUIcon>
            <span v-if="!isDisplayPartnerUrlEnabled" class="text-primary font-bold child margin-b-1">
              {{ $t('quoteDetail.operatorInfo.WEBSITE') }}
            </span>
            <span v-else class="text-gray child margin-b-1 overflow-hidden text-overflow-ellipsis max-w-230">
              {{ displayingUrl }}
            </span>
          </a>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { OperatorDetailCompany } from '../models/dto'
import CertifiedPartnerBadge from './CertifiedPartnerBadge.vue'
import CharterUPRating from '@/components/CharterUPRating.vue'
import ResellerTooltip from '@/components/ResellerTooltip.vue'
import { certifiedPartnerModalDetail } from '@/utils/infoModals'
import InfoModal from '@/components/InfoModal.vue'
import { PartnerTypeId } from '@/utils/enum'

@Component({
  components: { CertifiedPartnerBadge, InfoModal, CharterUPRating, ResellerTooltip }
})
export default class ProviderInfoHeader extends Vue {
  @Prop({ type: Object, required: true }) readonly company!: OperatorDetailCompany
  @Prop({ type: Boolean, required: true }) readonly isDisplayPartnerUrlEnabled: boolean

  certifiedPartnerModalDetail = certifiedPartnerModalDetail
  isOpen = false

  get website(): string {
    return this.company.customerFacingUrl
  }

  get displayingUrl(): string {
    let url = this.website
    return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0]
  }

  get isReseller(): boolean {
    return [PartnerTypeId.GoldReseller,
            PartnerTypeId.SilverReseller,
            PartnerTypeId.BronzeReseller].includes(this.company.partnerTypeId)
  }

  getUrl(url): string {
    if (!url.match(/^https?:\/\//i)) {
      url = `http://${url}`
    }
    return url
  }
}
</script>
