<template>
  <div
    class="d-flex justify-space-between margin-t-12"
    :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
  >
    <div
      class="min-w-240"
      :class="{
        'max-w-240': $vuetify.breakpoint.smAndUp,
        'margin-b-6': $vuetify.breakpoint.xsOnly,
      }"
    >
      <label for="passengerCount">Number of passengers</label>
      <v-text-field
        :value="passengerCount"
        hide-details
        outlined
        required
        type="number"
        placeholder="0"
        :rules="[(v) => v >= 1]"
        :class="{ 'max-w-160': $vuetify.breakpoint.smAndUp }"
        @input="setPassengerCount($event)"
      >
        <template slot="prepend-inner">
          <CUIcon
            view-box="0 0 24 24"
            width="20"
            height="20"
          >
            account_group
          </CUIcon>
        </template>
      </v-text-field>
      <p>Special requirements</p>

      <div class="d-flex margin-t-1 margin-b-3">
        <v-checkbox
          :input-value="ada"
          class="margin-t-0"
          hide-details
          @change="setAda($event)"
        />
        <div>
          <p class="margin-t-1">ADA</p>
          <p class="font-14 text-gray-medium-light margin-t-0">
            Americans with Disabilities Act
          </p>
        </div>
      </div>

      <div class="d-flex">
        <v-checkbox
          :input-value="spab"
          class="margin-t-0"
          hide-details
          @change="setSpab($event)"
        />
        <div>
          <p class="margin-t-1">SPAB</p>
          <p class="font-14 text-gray-medium-light margin-t-0">
            School Pupil Activity Bus
          </p>
        </div>
      </div>
    </div>

    <TripModificationVehicleOptions
      :vehicle-options="vehicleOptions"
      :class="{ 'max-w-640': $vuetify.breakpoint.mdAndUp }"
      :loading="loading"
      @select="selectVehicles($event)"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator'
import TripModificationVehicleOptions from '@/components/TripModificationVehicleOptions.vue'
import { WizardVehicle } from '@/models/dto'
import tripModification from '@/services/tripModification'
import tripModificationStore from '@/store/modules/tripModification'
import { DateTime } from 'luxon'
import { estimateDropoffDatetime } from '@/utils/stop'
import { deepCompareArrays } from '@/utils/array'
@Component({
  components: {
    TripModificationVehicleOptions,
  },
})
export default class TripModificationVehicle extends Vue {
  tripModificationStore = tripModificationStore
  vehicleOptions: WizardVehicle[][] = []
  debounce: any = null
  loading = false

  /**
   * Generates a key string based on the ADA, SPAB, and passenger count values.
   * This key is used solely to trigger the `@Watch('optionsKey')` method,
   * where changes in these values will prompt the fetching of vehicle options.
   */
  get optionsKey(): string {
    return `${this.ada}-${this.spab}-${this.passengerCount}`
  }

  @Watch('optionsKey')
  async onOptionsKeyChange(): Promise<void> {
    await this.getVehicleOptions()
  }

  async created(): Promise<void> {
    await this.getVehicleOptions()
  }

  async getVehicleOptions(): Promise<void> {
    tripModificationStore.setForwardButtonDisabled(true)
    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }

    this.debounce = window.setTimeout(async () => {
      this.loading = true
      const requestedTrip = tripModificationStore.requestedTrip
      const { stops, passengerCount, ada } = requestedTrip

      if (!passengerCount) {
        return
      }

      const firstStop = stops[0]
      const penultimateStop = stops[stops.length - 2]
      const lastStop = stops[stops.length - 1]

      const travelTimeInSeconds = lastStop.travelTimeFromPreviousStopInSeconds
      const lastStopTimeZone = lastStop.address.zoneId

      const lastStopDropoffdateTime = DateTime.fromISO(lastStop.dropoffDatetime)

      const payload = {
        passengerCount,
        firstPickupLat: firstStop.address.lat,
        firstPickupLng: firstStop.address.lng,
        firstPickupTime: DateTime.fromISO(firstStop.pickupDatetime)
          .toUTC()
          .toISO(),
        lastDropoffTime: lastStopDropoffdateTime.toUTC().toISO(),
        ada,
      }

      try {
        const { data } = await tripModification.vehicleConfigurations(payload)
        this.vehicleOptions = data
      } catch (error) {
        console.error(error)
      }
      this.loading = false
      tripModificationStore.setForwardButtonDisabled(
        !this.isValidVehicleConfigurationSelected
      )
    }, 250)
  }

  get ada(): boolean {
    return tripModificationStore.requestedTrip.ada
  }

  get spab(): boolean {
    return tripModificationStore.requestedTrip.spab
  }

  get passengerCount(): number {
    return tripModificationStore.requestedTrip.passengerCount
  }

  get isValidVehicleConfigurationSelected(): boolean {
    const requestedVehicles = tripModificationStore.requestedTrip.vehicles
    return this.vehicleOptions.some((vehicles) =>
      deepCompareArrays(vehicles, requestedVehicles)
    )
  }

  mounted() {
    tripModificationStore.setForwardButtonDisabled(
      this.passengerCount < 1 || !this.isValidVehicleConfigurationSelected
    )
  }

  setAda(value: boolean): void {
    tripModificationStore.setRequestedAda(value)
  }

  setSpab(value: boolean): void {
    tripModificationStore.setRequestedSpab(value)
  }

  setPassengerCount(passengerCount: string): void {
    const parsedPassengerCount = parseInt(passengerCount, 10)
    if (parsedPassengerCount < 1) {
      tripModificationStore.setForwardButtonDisabled(true)
    } else {
      tripModificationStore.setRequestedPassengerCount(parsedPassengerCount)
    }
  }

  selectVehicles(vehicles: WizardVehicle[]): void {
    tripModificationStore.setRequestedVehicles(vehicles)
    tripModificationStore.setForwardButtonDisabled(
      !this.isValidVehicleConfigurationSelected
    )
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';

::v-deep .v-select {
  .v-input__control {
    .v-input__slot {
      .v-select__slot {
        .v-select__selections {
          padding: 0;
          .v-select__selection {
            padding-left: 14px !important;
          }
        }
        .v-input__append-inner {
          .v-input__icon--append {
            margin-top: 12px;
            margin-right: 3px;
          }
        }
      }
    }
  }
}

::v-deep .v-text-field {
  .v-input__control {
    .v-input__slot {
      .v-input__prepend-inner {
        z-index: 1;
        margin-top: 12px;
        margin-left: 12px;
        .cu-icon {
          color: $midnight-gray-300 !important;
        }
      }
    }
  }

  * {
    touch-action: manipulation;
  }
}
</style>
