<template>
  <v-container
    fluid
    class="padding-a-8 fill-height align-start"
    :style="styles"
  >
    <div class="w-full" :class="{ 'h-full': fullHeight }">
      <slot />
    </div>
  </v-container>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Main extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly fullHeight!: boolean

  get styles(): Record<string, string> {
    const styles = {
      'max-width': `${this.$vuetify.breakpoint.thresholds.md}px`,
    }
    return styles
  }
}
</script>
