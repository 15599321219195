<template>
  <div>
    <v-btn
      text
      color="primary"
      small
      class="padding-l-3 padding-r-1"
      @click="isExpanded = !isExpanded"
    >
      Select
      <CUIcon
        :key="`select-arrow-icon-${isExpanded}`"
        small
        view-box="0 0 24 24"
        color="primary"
        class="w-20 h-20"
      >
        {{ !isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}
      </CUIcon>
    </v-btn>
    <div v-if="isExpanded" class="margin-y-2">
      <v-chip
        v-for="(predefined, predefinedIndex) in selectColumn.predefinedSelects"
        :key="`predefined-select-chip-${toKebab(
          predefined.label
        )}-${predefinedIndex}`"
        class="margin-r-1"
        outlined
        color="primary"
        @click="$emit('select', predefined.filter)"
      >
        {{ predefined.label }}
      </v-chip>
    </div>
  </div>
</template>

<script lang="ts">
import { SelectColumn } from '@/models/SelectColumn'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { toKebab } from '@/utils/string'

@Component({})
export default class CUDataTableMobilePredefinedSelects extends Vue {
  @Prop({ type: Object, required: true }) readonly selectColumn: SelectColumn
  toKebab = toKebab

  isExpanded = false
}
</script>
