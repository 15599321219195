<template>
  <div>
    <v-card class="margin-y-3 margin-x-0">
      <div class="margin-y-3">
        <div class="w-full display-flex flex-wrap align-center justify-start">
          <CUIcon
            view-box="0 0 28 28"
            color="blue"
            width="28px"
            height="28px"
            class="margin-l-3 margin-r-1 cursor-default"
          >
            info
          </CUIcon>
          <p class="font-16 font-bold margin-t-0">
            {{
              $vuetify.breakpoint.smAndUp
                ? $t('reservationDetail.informationNeeded.TITLE')
                : $t('reservationDetail.informationNeeded.TITLE_MOBILE')
            }}
          </p>
        </div>
        <ul
          v-for="(riskTypeId, riskTypeIdIndex) in risksTypeIdsWithUniqueText"
          :key="`risk-item-${riskTypeId}-${riskTypeIdIndex}`"
          class="margin-t-1"
        >
          <li
            :key="`risk-item-${riskTypeIdIndex}`"
            class="margin-l-11 margin-r-4"
            :style="{ 'list-style': 'disc' }"
          >
            <template v-if="showTripModification(riskTypeId)">
              <TripModificationRequestDialog
                v-if="hasPendingModificationRequest"
                :managed-id="reservation.managedId"
              >
                <template #activator="{ on, attrs }">
                  <span
                    class="text-primary font-bold cursor-pointer"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ riskActionLink(riskTypeId) }}
                  </span>
                </template>
              </TripModificationRequestDialog>

              <span v-else
                class="text-primary font-bold cursor-pointer"
                @click="openStartTripModificationDialog"
              >
                {{ riskActionLink(riskTypeId) }}
              </span>
            </template>

            <a
              v-else
              :id="`risk-item-modal-link-${riskTypeId}`"
              class="font-bold margin-t-3"
              @click.stop="openActionModal(riskTypeId)"
            >
              {{ riskActionLink(riskTypeId) }}
            </a>

            {{ riskMessage(riskTypeId) }}
            <a
              v-if="riskInfoLink(riskTypeId)"
              :id="`risk-item-info-modal-link-${riskTypeId}`"
              class="font-medium margin-t-3 text-gray text-decoration-underline"
              @click.stop="openInfoModal(riskTypeId)"
            >
              {{ riskInfoLink(riskTypeId) }}
            </a>
          </li>
        </ul>
      </div>
    </v-card>
    <DriverHotelRequirementsDialog
      v-model="actionableRisk.driverHotelRequirementsDialogIsOpen"
      :reservation-id="reservation.reservationId"
      :hotel-requirement="hotelRequirement"
    />
    <HotelInformationDialog
      v-model="actionableRisk.hotelInformationDialogIsOpen"
      :trip-id="reservation.tripId"
      :hotel-information="hotelInformation"
      :hotel-requirement="hotelRequirement"
    />
    <ItineraryRequirementsDialog
      v-model="actionableRisk.itineraryRequirementsDialogIsOpen"
      :reservation-id="reservation.reservationId"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import DriverHotelRequirementsDialog from './DriverHotelRequirementsDialog.vue'
import ItineraryRequirementsDialog from './ItineraryRequirementsDialog.vue'
import HotelInformationDialog from './HotelInformationDialog.vue'
import TripModificationRequestDialog from './TripModificationRequestDialog.vue'
import { TranslateResult } from 'vue-i18n'
import { EventBus } from '@/utils/eventBus'
import { RiskTypeId, ReservationStatusKey, SplitFeatureFlag } from '@/utils/enum'
import {
  ReservationDetail,
  HotelInformation,
  HotelRequirement,
} from '@/models/dto'
import { ActionableRisk } from '@/models/ActionableRisk'

@Component({
  components: {
    DriverHotelRequirementsDialog,
    HotelInformationDialog,
    ItineraryRequirementsDialog,
    TripModificationRequestDialog
  },
})
export default class ReservationDetailWarningSummary extends Vue {
  @Prop({ required: true, type: Array }) readonly riskTypeIds: number[]
  @Prop({ required: true, type: Object }) readonly reservation: ReservationDetail

  isItineraryModificationWizardEnabled = false
  actionableRisk = new ActionableRisk()

  @Watch('reservation', { immediate: true })
  reservationChanged(reservation) {
    this.actionableRisk.setReservationId(reservation?.reservationId)
    this.actionableRisk.setStops(reservation?.stops)
  }

  async mounted(): Promise<void> {
    EventBus.$on('open-hotel-info', () => {
      if (this.hotelRequirement) {
        this.openActionModal(RiskTypeId.DriverHotelMissing)
      }
    })
    this.isItineraryModificationWizardEnabled = await this.$split.isFeatureEnabled(
      SplitFeatureFlag.ItineraryModificationWizard
    )
  }

  get hotelInformation(): HotelInformation {
    return this.reservation?.hotelInformation
  }

  get hotelRequirement(): HotelRequirement {
    return this.reservation?.hotelRequirement
  }

  get risksTypeIdsWithUniqueText(): number[] {
    const uniqueRiskTypeIds = new Set<number>()
    const uniqueRiskLabels = new Set<string>()
    for (const riskTypeId of this.riskTypeIds) {
      const label = this.riskObject(riskTypeId).riskText
      if (!uniqueRiskLabels.has(label)) {
        uniqueRiskLabels.add(label)
        uniqueRiskTypeIds.add(riskTypeId)
      }
    }

    return [...uniqueRiskTypeIds]
  }

  get hasPendingModificationRequest(): boolean {
    return this.reservation?.hasPendingModificationRequest
  }

  get isReservationUpcomingOrOnHold(): boolean {
    return (
      this.reservation?.reservationStatus === ReservationStatusKey.Upcoming ||
      this.reservation?.reservationStatus === ReservationStatusKey.OnHold
    )
  }

  showTripModification(riskTypeId: number): boolean {
    // We don't need to check if the user is allowed to manage the reservation
    // since we would only ever show these risk warnings if they could

    if([RiskTypeId.DriverHotelMissing, RiskTypeId.FlightInfoMissing].includes(riskTypeId)) {
      return false
    }

    return this.isItineraryModificationWizardEnabled && this.isReservationUpcomingOrOnHold
  }

  riskMessage(riskTypeId: number): TranslateResult {
    if (this.showTripModification(riskTypeId)) {
      return ' for all stops'
    }

    const riskText = this.riskObject(riskTypeId).riskText
    return this.$t('reservationDetail.informationNeeded.summary')[riskText]
      .MESSAGE
  }

  riskActionLink(riskTypeId: number): TranslateResult {
    if (this.showTripModification(riskTypeId)) {
      return 'Provide complete info'
    }

    const riskText = this.riskObject(riskTypeId).riskText
    return this.$t('reservationDetail.informationNeeded.summary')[riskText]
      .ACTION_LINK
  }

  riskInfoLink(riskTypeId: number): TranslateResult {
    const riskText = this.riskObject(riskTypeId).riskText
    return this.$t('reservationDetail.informationNeeded.summary')[riskText]
      .INFO_LINK
  }

  openActionModal(riskTypeId: number): void {
    this.riskObject(riskTypeId).openActionModal()
  }

  openInfoModal(riskTypeId: number): void {
    this.riskObject(riskTypeId).openInfoModal()
  }

  riskObject(riskTypeId: number) {
    return this.actionableRisk?.riskObject(riskTypeId)
  }

  openStartTripModificationDialog(): void {
    EventBus.$emit('open-trip-modification-dialog')
  }
}
</script>
