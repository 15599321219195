<template>
  <div class="padding-x-8 padding-t-6 padding-b-4">
    <div>
      <div class="d-flex justify-space-between align-center">
        <div class="d-flex flex-column">
          <p class="margin-t-0">
            <span v-if="tripCount > 1" class="text-gray-light margin-r-1">
              {{ tripCountOfTotalText }}
            </span>
            <span class="font-bold">{{ pickupDropoffText }}</span>
          </p>
          <p class="margin-t-0">
            {{ $t('common.PICKUP') }} {{ pickupDatetimeText }}
          </p>
        </div>
        <div>
          <CUIcon
            v-if="closeable"
            color="gray"
            view-box="0 0 24 24"
            width="24"
            height="24"
            @click="close"
          >
            close
          </CUIcon>
          <v-btn
            v-else
            color="gray-light"
            plain
            small
            class="margin-t-0 font-18  padding-x-0 min-w-0 font-book"
            @click="goToNextTrip"
          >
            Skip
          </v-btn>
        </div>
      </div>
      <v-divider class="margin-y-6" />
    </div>
    <div class="background-white">
      <h3 class="font-22  font-bold text-center">
        {{ $t('reservationDetail.tripContactForm.TITLE') }}
      </h3>
      <p class="text-center margin-t-0 font-14 text-gray-light">
        ({{ $t('common.OPTIONAL') }})
      </p>
      <p class="text-left margin-b-6">
        {{ $t('reservationDetail.tripContactForm.BODY') }}
      </p>
      <CustomerInviteSearch
        v-if="!hasTripContact"
        ref="customerInviteSearch"
        trip-contact-mode
        :label="$t('reservationDetail.tripContactForm.SEARCH_LABEL')"
        :placeholder="
          $t('reservationDetail.tripContactForm.SEARCH_PLACEHOLDER')
        "
        :error-messages="inviteErrorMessages"
        :shared-list="sharedList"
        :show-back="showInviteForm"
        @select="editTripContact($event)"
        @invite="showForm"
        @back="hideForm"
      />
      <template v-else>
        <div>{{ $t('reservationDetail.tripContactForm.TRIP_CONTACT') }}</div>
        <div class="d-flex align-center margin-t-1">
          <div
            class="d-flex background-black w-40 h-40 border-radius-round margin-r-2 align-center justify-center text-white font-bold"
          >
            {{ tripContactInitials }}
          </div>
          <div class="max-w-300 margin-r-2">
            <p
              class="font-bold margin-y-0 overflow-hidden white-space-nowrap display-block text-overflow-ellipsis"
            >
              {{ reservation.tripContact.firstName }}
              {{ reservation.tripContact.lastName }}
            </p>
            <p
              class="margin-t-n1 overflow-hidden white-space-nowrap display-block text-overflow-ellipsis"
            >
              {{ reservation.tripContact.email }}
            </p>
          </div>
          <v-spacer />
          <div>
            <CUIcon
              id="remove-trip-contact-button"
              color="gray-mid-light"
              hover-color="error"
              class="margin-b-n3"
              width="32"
              height="32"
              @click="editTripContact(null)"
            >
              delete
            </CUIcon>
          </div>
        </div>
      </template>
      <CustomerInviteForm
        v-if="!hasTripContact && showInviteForm"
        trip-contact-mode
        :reservation-id="reservation.reservationId"
        class="margin-t-3"
        :trigger-submit="tripContactSubmitting"
        :initial-email="inviteEmail"
        @invalid="stopSubmitting"
        @submit="submitCustomer($event)"
      />
      <div v-if="showTripNameInput" class="margin-t-2">
        <label>What can we call this trip?</label>
        <v-text-field
          id="trip-name-text-field"
          v-model="routeName"
          outlined
          maxlength="40"
          placeholder="Enter trip name"
        />
      </div>
    </div>
    <v-btn
      id="reservation-detail-add-trip-contact"
      color="primary"
      class="w-full margin-y-3"
      :loading="submitting"
      @click="startSubmit"
    >
      {{ $t('common.CONTINUE') }}
    </v-btn>
  </div>
</template>

<script lang="ts">
import {
  Customer,
  InviteCustomerPayload,
  UpdateTripPayload,
} from '@/models/dto'
import deepClone from '@/utils/deepClone'
import {
  formatReservationPickupDestinationText,
  validateEmailAddress,
  getInitials,
} from '@/utils/string'
import { Vue, Component, Prop, Watch, Inject } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import trip from '@/services/trip'
import CustomerInviteSearch from '@/components/CustomerInviteSearch.vue'
import CustomerInviteForm from '@/components/CustomerInviteForm.vue'
import reservation from '@/services/reservation'
import customer from '@/services/customer'
import user from '@/services/user'
import { ReservationDetail } from '../models/dto/ReservationDetail'

@Component({ components: { CustomerInviteSearch, CustomerInviteForm } })
export default class ReservationDetailTripContactForm extends Vue {
  @Inject({ from: 'isInPostConversionDialog', default: false })
  readonly isInPostConversionDialog: boolean
  @Prop({ type: Array, required: true })
  readonly reservations: ReservationDetail[]
  @Prop({ type: Array, required: true }) readonly sharedList: Customer[]
  @Prop({ type: Boolean, default: false }) readonly closeable: boolean
  @Prop({ type: Boolean }) readonly showTripName: boolean

  @Watch('currentReservation', { deep: true, immediate: true })
  currentReservationChanged(): void {
    this.reservation = deepClone(this.currentReservation)
    const routeName = this.reservation?.routeName
    this.routeName = routeName ? `${routeName}` : ''
  }

  @Watch('submitSuccess')
  submitSuccessChanged(value: boolean): void {
    if (!value) {
      return
    }
    this.goToNextTrip()
  }

  reservation: ReservationDetail = null
  index = 0
  showInviteForm = false
  routeName = ''

  tripNameSubmitting = false
  tripContactSubmitting = false
  tripNameSuccess = false
  tripContactSuccess = false
  inviteEmail = ''
  inviteErrorMessages = []

  showTripNameInput = false
  validEmailField = true

  mounted() {
    this.showTripNameInput = this.showTripName
  }

  get submitting(): boolean {
    return this.tripNameSubmitting || this.tripContactSubmitting
  }

  get showButton(): boolean {
    return this.showInviteForm || this.showTripNameInput
  }

  get currentReservation(): ReservationDetail {
    if (!(this.reservations && this.reservations.length)) {
      return null
    }
    return this.reservations[this.index]
  }

  get hasTripContact(): boolean {
    if (!this.reservation) {
      return false
    }
    return !!this.reservation.tripContact?.customerId
  }

  get tripContactInitials(): string {
    if (!this.hasTripContact) {
      return null
    }
    return (
      getInitials(
        this.reservation.tripContact?.firstName,
        this.reservation.tripContact?.lastName
      ) ||
      this.reservation.tripContact?.email?.[0]?.toUpperCase() ||
      ''
    )
  }

  get pickupDropoffText(): string {
    if (!this.reservation) {
      return ''
    }
    return formatReservationPickupDestinationText(
      this.reservation,
      this.$t('common.TO')
    )
  }

  get pickupDatetimeText(): string {
    const firstStop = this.reservation?.stops?.[0]
    const pickupDatetime = firstStop?.pickupDatetime
    const timeZone = firstStop?.address?.timeZone
    if (!pickupDatetime || !timeZone) {
      return ''
    }
    return DateTime.fromISO(pickupDatetime, { zone: timeZone }).toFormat(
      'LL/dd/yy - hh:mm a'
    )
  }

  get tripCount(): number {
    return this.reservations?.length
  }

  get tripCountOfTotalText(): string {
    const currentTrip = this.index + 1
    return `Trip ${currentTrip} of ${this.tripCount}`
  }

  get phoneRaw(): string {
    const phone = this.reservation.tripContact?.phone
    if (!phone) {
      return ''
    }
    return phone.replace(/\D/g, '')
  }

  get submitSuccess(): boolean {
    return (
      this.tripContactSuccess &&
      (!this.showTripNameInput || this.tripNameSuccess)
    )
  }

  stopSubmitting(): void {
    this.tripNameSubmitting = false
    this.tripContactSubmitting = false
  }

  close(): void {
    this.$emit('close')
  }

  goToNextTrip(): void {
    if (this.index < this.reservations.length - 1) {
      this.index++
      this.setSuccessFalse()
    } else {
      this.$emit('confirm')
    }
  }

  setSuccessFalse(): void {
    this.tripNameSuccess = false
    this.tripContactSuccess = false
  }

  showForm(email: string): void {
    this.showInviteForm = true
    this.showTripNameInput = false
    this.inviteEmail = email
  }

  hideForm(): void {
    this.showInviteForm = false
    this.showTripNameInput = this.showTripName
  }

  async editTripContact(customerId: number): Promise<void> {
    await trip.editTripContact(this.reservation.tripId, {
      tripContact: { customerId },
    })
    if (this.isInPostConversionDialog) {
      this.refreshReservation()
    }
    this.$emit('refresh')
  }

  async refreshReservation(): Promise<void> {
    const reservationResponse = await reservation.byId(
      this.reservation.reservationId
    )
    this.reservation = reservationResponse.data.data
  }

  validateEmail(email: string): boolean {
    this.inviteErrorMessages = []
    const valid = validateEmailAddress(email)
    if (!valid) {
      this.inviteErrorMessages.push('Please enter a valid email.')
      return false
    }
    return true
  }

  async startSubmit(): Promise<void> {
    const inviteSearch = this.$refs['customerInviteSearch'] as any
    const inviteEmail = inviteSearch ? inviteSearch.search : null
    if (inviteEmail) {
      this.tripContactSubmitting = true
      if (!this.showInviteForm) {
        await this.submitCustomer({
          email: inviteEmail,
          firstName: null,
          lastName: null,
          phone: null,
        })
      }
    } else {
      //empty email field is valid
      this.validEmailField = true
    }

    if (this.routeName && this.validEmailField) {
      this.tripNameSubmitting = true
      this.submitRouteName()
    } else if (this.validEmailField && !inviteEmail) {
      //no email and no trip name should allow continue
      this.$emit('confirm')
    }
  }

  handleTripContactSubmit(success: boolean): void {
    this.tripContactSubmitting = false
    this.tripContactSuccess = success
  }

  async submitRouteName(): Promise<void> {
    this.tripNameSubmitting = true
    if (this.showTripNameInput) {
      const payload: Partial<UpdateTripPayload> = {
        routeName: this.routeName,
      }
      try {
        await trip.editRouteName(
          this.reservation.tripId,
          payload as UpdateTripPayload
        )
        this.tripNameSuccess = true
        this.$emit('confirm')
      } catch (error) {
        console.error(error)
      }
    }
    this.tripNameSubmitting = false
  }

  async submitCustomer(payload: InviteCustomerPayload): Promise<void> {
    const valid = this.validateEmail(payload.email)
    if (!valid && payload.email) {
      this.validEmailField = false
      this.tripContactSubmitting = false
      return
    }
    this.validEmailField = true

    const response = await user.checkEmail(payload.email)
    const userExists = response?.data?.exists
    if (!userExists && !this.showInviteForm) {
      this.handleTripContactSubmit(false)
      this.showForm(payload.email)
      return
    }

    if (
      this.showInviteForm &&
      (!payload.firstName || !payload.lastName || !payload.phone)
    ) {
      return
    }

    try {
      const customerResponse = await customer.inviteByReservationId(
        this.reservation.reservationId,
        payload,
        true
      )
      const customerId = customerResponse.data.customer.userId
      await this.editTripContact(customerId)
      this.handleTripContactSubmit(true)
      this.hideForm()
      if (!this.isInPostConversionDialog) {
        this.$emit('close')
      }
    } catch (error) {
      console.error(error)
      this.handleTripContactSubmit(false)
    }
  }
}
</script>
