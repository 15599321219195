<template>
  <v-card
    class="survey-card border-none border-0 background-primary position-fixed right-24 w-400 padding-t-4 padding-b-6 padding-x-6 min-h-200"
    :class="{ 'submitted text-center background-primary': submitted }"
  >
    <v-row class="justify-end">
      <CUIcon
        view-box="0 0 24 24"
        class="margin-t-2 margin-r-n1 text-white"
        @click="survey.close"
      >
        close
      </CUIcon>
    </v-row>
    <template v-if="!submitted">
      <p
        class="font-medium text-white text-center padding-y-0 padding-x-6 margin-b-3 cursor-default"
        :style="{ 'line-height': '1.2em' }"
      >
        How likely are you to recommend CharterUP to a friend or colleague?
      </p>
      <BusSlider
        v-model="rating"
        :min="0"
        :max="10"
        :step="1"
        @show-submit="submitHidden = false"
        @submit="oneClickSubmit($event)"
      />
      <v-expand-transition>
        <v-row v-if="!submitHidden" justify="center">
          <v-btn
            x-small
            color="white"
            :style="{ 'padding-top': '15px', 'padding-bottom': '15px' }"
            class="submit-button margin-t-0 padding-y-0"
            :loading="submitting"
            @click="submit"
          >
            Submit
          </v-btn>
        </v-row>
      </v-expand-transition>
    </template>
    <template v-else>
      <div
        class="position-absolute width-full text-center margin-y-0 left-0"
        :style="{
          top: '50%',
          'margin-left': 'auto',
          'margin-right': 'auto',
          '-ms-transform': 'translateY(-50%)',
          transform: 'translateY(-50%)',
        }"
      >
        <h3 class="font-bold font-24 text-white">
          Thank you for your feedback
        </h3>
        <p
          class="font-book text-white text-center padding-y-0 padding-x-6 margin-b-3 margin-t-0 cursor-default"
          :style="{ 'line-height': '1.2em' }"
        >
          {{ responseMessage }}
        </p>
      </div>
    </template>
  </v-card>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import BusSlider from '@/components/BusSlider.vue'
import nps from '@/services/nps'
import auth from '@/store/modules/auth'
import survey from '@/store/modules/survey'
import { NPSTypeId } from '@/utils/enum'

@Component({
  components: {
    BusSlider,
  },
})
export default class TheBookingExperienceSurvey extends Vue {
  submitHidden = true
  submitted = false
  submitting = false
  rating = 5
  survey = survey

  get responseMessage(): string {
    if (this.rating <= 6) {
      return 'We highly value all ideas and suggestions from all our customers. In the future, our team may reach out to you to learn more about how we can improve your experience so that it exceeds your expectations.'
    }
    if (this.rating <= 8) {
      return 'Our goal is to create the best possible booking experience and your feedback plays a major role in helping us improve.'
    }
    return 'It’s great to hear that you enjoyed your CharterUP booking experience.'
  }

  oneClickSubmit(delay): void {
    const delayMilliseconds = delay * 1000
    setTimeout(() => {
      this.submit()
    }, delayMilliseconds)
  }

  async submit(): Promise<void> {
    this.submitting = true
    const payload = {
      quoteId: survey.quoteId,
      userId: auth.userId,
      rating: this.rating,
      npsTypeId: NPSTypeId.PostBooking,
    }
    try {
      await nps.add(payload)
      this.trackTakeNpsSurvey()
      this.submitted = true
    } catch (error) {
      console.error(error)
    }
    this.submitting = false
  }

  trackTakeNpsSurvey(): void {
    this.$ga4.trackEvent('take_nps_survey', {
      page: 'Confirmation',
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors.scss';

.v-icon:after {
  display: none;
}
.survey-card {
  bottom: -5px;
  z-index: 206 !important;
}

.submit-button {
  color: $primary !important;
  ::v-deep .v-btn__loader {
    .v-progress-circular {
      color: $primary !important;
    }
  }
}
</style>
