<template>
  <CUModal :value="value" @input="$emit('input', $event)">
    <template #title>Pending</template>
    <template #text>
      We are confirming your itinerary with your bus operator. Once finalized,
      we will update this status to an "Upcoming" trip.
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class PendingTripDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
}
</script>
