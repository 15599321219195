<template>
  <div class="d-flex background-header-gray padding-y-5 padding-x-6" :style="{ gap: isWidth320OrLess ? 0 : '24px' }">
    <div
      class="d-flex w-104"
      :class="{ 'position-relative left-n10 margin-r-n3': isWidth320OrLess }"
    >
      <VehicleOptionImage :vehicle-type-keys="[vehicle.vehicleType.key]" />
    </div>
    <div
      class="d-flex flex-wrap flex-column"
      :class="{ 'margin-l-n3': isWidth320OrLess }"
    >
      <div class="d-flex flex-wrap flex-column" style="column-gap: 8px;">
        <div class="d-flex align-center">
          <h3>
            {{ vehicle.vehicleType.label }}
          </h3>
          <CUIcon viewBox="0 0 24 24" class="w-16 h-16 margin-l-1 margin-t-1">
            close
          </CUIcon>
          <h3>
            {{ vehicle.quantity }}
          </h3>
        </div>
      </div>
      <div v-if="amenities.length" class="d-flex flex-column margin-t-2">
        <div
          v-for="(amenity, amenityIndex) in amenities"
          :key="`vehicle-amenity-${amenityIndex}`"
          class="d-flex font-12 align-center"
        >
          <CUIcon
            viewBox="0 0 24 24"
            class="w-14 h-14 min-w-14 min-h-14 margin-r-2"
          >
            {{ getAmenityIcon(amenity.id) }}
          </CUIcon>
          {{ amenity.quantity }}
          <CUIcon viewBox="0 0 24 24" class="w-12 h-12 margin-r-1">
            close
          </CUIcon>
          {{ amenity.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import VehicleOptionImage from '@/components/VehicleOptionImage.vue'
import { AmenityItemDetail } from '@/models/AmenityItem'
import { TripVehicle } from '@/models/dto'
import { getAmenityIcon } from '@/utils/amenity'

@Component({
  components: {
    VehicleOptionImage,
  },
})
export default class CheckoutTripDetailsVehicle extends Vue {
  @Prop({ type: Object }) vehicle: TripVehicle
  @Prop({ type: Array }) amenities: AmenityItemDetail[]

  getAmenityIcon = getAmenityIcon

  get isWidth320OrLess(): boolean {
    return this.$vuetify.breakpoint.width <= 320
  }

}
</script>
