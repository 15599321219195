<template>
  <div
    class="border-y-1 border-solid border-midnight-gray-50 background-midnight-gray-white omnibus"
    :class="{
      'border-radius-16 border-x-1 padding-a-6': !fullWidth,
      'border-x-0 padding-a-4': fullWidth,
    }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CUCard extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly fullWidth!: boolean
}
</script>
