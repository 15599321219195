<template>
  <div>
    <p :style="{ 'line-height': '1.5em' }">
      {{ modalDetail.simpleBody }}
    </p>
  </div>
</template>

<script lang="ts">
import { InfoModalDetail } from '@/models/InfoModalDetail'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class InfoModalTextSimple extends Vue {
  @Prop({ type: Object, required: true }) readonly modalDetail: InfoModalDetail
}
</script>
