<template>
  <div>
    <h2 class="margin-b-4">{{ header }}</h2>
    <TwoColumnLayout :full="!$vuetify.breakpoint.md" plain-container>
      <template #left-column>
        <div class="margin-b-4">
          {{ $t('reservationDetail.cancellationReason.NEED_HELP') }}
          <a
            :tel="`${supportNumber}`"
            :href="`tel:${supportNumber}`"
            class="text-primary"
          >
            {{ formattedSupportNumber }}
          </a>
        </div>
        <ReservationCancelCostWarning
          :reservation="reservation"
          :penalty-info="penaltyInfo"
        />

        <div class="margin-t-2">
          <h3>{{ $t('reservationDetail.cancellationReason.REASON') }}</h3>
          <v-radio-group
            :value="reasonId"
            class="cursor-pointer"
            hide-details="auto"
            @change="$emit('update:reason-id', $event)"
          >
            <v-radio
              v-for="(classification, classificationIndex) in formattedCancellationClassifications"
              :key="`${classification.classificationId}-${classificationIndex}`"
              :label="classification.label"
              :value="classification.classificationId"
            />
          </v-radio-group>
          <CUTextField
            v-if="isCancellationReasonOther"
            :value="otherDescription"
            class="margin-t-2 margin-l-9"
            placeholder="Please explain your reason for cancelling"
            @input="$emit('update:other-description', $event)"
          />
          <p class="margin-t-8 font-medium">
            {{ $t('reservationDetail.cancellationReason.ARE_YOU_SURE') }}
          </p>
          <div class="d-flex margin-t-4">
            <v-btn
              id="cancellation-reason-continue-button"
              class="margin-r-2"
              color="primary"
              small
              :disabled="continueDisabled"
              @click="$emit('next-step')"
            >
              {{ $t('reservationDetail.cancellationReason.YES') }}
            </v-btn>
            <v-btn
              id="cancellation-reason-dismiss-button"
              :class="{
                'margin-l-2': $vuetify.breakpoint.mdAndUp,
                'margin-l-n2': $vuetify.breakpoint.smAndDown,
              }"
              color="primary"
              text
              small
              @click="$emit('to-reservation-detail')"
            >
              {{ $t('reservationDetail.cancellationReason.NO') }}
            </v-btn>
          </div>
        </div>
      </template>
      <template #right-column>
        <ReservationCancellationSummary
          :class="{
            'margin-t-3': $vuetify.breakpoint.mdAndDown,
          }"
          :reservation="reservation"
          :penalty-info="penaltyInfo"
        />
        <ReservationCancellationPolicy
          class="margin-t-4"
          :start-date="reservation.pickupDate"
          :refund-policy-percent="refundPolicyPercent"
          :refund-policy-percent-valid-until-time="refundPolicyPercentValidUntilTime"
          :refund-policy="refundPolicy"
          :is-elite="isElite"
          :is-self-serve="isSelfServe"
        />
      </template>
    </TwoColumnLayout>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import TwoColumnLayout from '@/layouts/TwoColumnLayout.vue'
import ReservationCancellationSummary from './ReservationCancellationSummary.vue'
import ReservationCancelCostWarning from '@/components/ReservationCancelCostWarning.vue'
import ReservationCancellationPolicy from '@/components/ReservationCancellationPolicy.vue'
import auth from '@/store/modules/auth'
import reservation from '@/services/reservation'
import cancellation from '@/services/cancellation'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'
import {
  ReservationDetail,
  ClientCancellationPenaltyInfo,
  RefundPolicyEntry,
  Classification,
} from '@/models/dto'

@Component({
  components: {
    TwoColumnLayout,
    ReservationCancelCostWarning,
    ReservationCancellationSummary,
    ReservationCancellationPolicy,
  },
})
export default class ReservationCancelReason extends Vue {
  @Prop({ type: Number }) readonly reasonId: number
  @Prop({ type: Object }) readonly reservation: ReservationDetail
  @Prop({ type: String }) readonly otherDescription: string
  @Prop({ type: Object, required: true })
  readonly penaltyInfo!: ClientCancellationPenaltyInfo
  @Prop({ type: String, required: true })
  readonly header: string

  cancellationClassifications: Classification[] = []

  async created() {
    const response = await cancellation.getCancellationClassifications()
    this.cancellationClassifications = response.data.subClassifications
  }

  get isElite(): boolean {
    return !!this.reservation?.isElite
  }

  get isSelfServe(): boolean {
    return !!this.reservation?.isSelfServe
  }

  get refundPolicyPercent(): number {
    return this.penaltyInfo?.refundPolicyPercent
  }

  get refundPolicy(): RefundPolicyEntry[] {
    return this.penaltyInfo?.refundPolicy
  }

  get refundPolicyPercentValidUntilTime(): string {
    return this.penaltyInfo?.refundPolicyPercentValidUntilTime
  }

  get supportNumber(): string {
    return getSupportNumber(auth.customer.isElite)
  }

  get formattedSupportNumber(): string {
    return phoneFormatFilterHyphens(this.supportNumber)
  }

  get isCancellationReasonOther(): boolean {
    return this.cancellationClassifications.find(
        (classification) => classification.classificationId === this.reasonId
      )?.isOther
  }

  get continueDisabled(): boolean {
    return !this.reasonId  || (this.isCancellationReasonOther && !this.otherDescription)
  }

  get formattedCancellationClassifications(): Classification[] {
    const classificationKeys = {
      'event_cancelled': 'My event was cancelled',
      'event_rescheduled': 'My event was rescheduled',
      'rebooked_with_charterup': 'I found a better option on CharterUP',
      'found_cheaper_option_elsewhere': 'I found a cheaper option somewhere else',
      'found_better_option_elsewhere': 'I found a better option somewhere else',
      'other_please_specify': 'Other - please specify:'
    };

    const filteredClassifications = Object.keys(classificationKeys)
        .map(key => {
            const classification = this.cancellationClassifications.find(c => c.key === key);
            return classification ? { ...classification, label: classificationKeys[key] } : null;
        })
        .filter(Boolean);

    return filteredClassifications;
  }

}
</script>

<style lang="scss" scoped>
@import '@/scss/colors';

::v-deep .v-radio {
  .v-input--selection-controls__input {
    .v-icon {
      color: $primary !important;
    }
  }
  label {
    color: $gray-dark;
  }
}

@media only screen and (max-width: 599px) {
  ::v-deep .v-btn {
    .v-btn__content {
      max-width: 150px;
      white-space: normal;
      text-align: start;
    }
  }
}
</style>
