<template>
  <div :style="{ 'max-width': '1137px', 'padding-left': '22px' }">
    <SkeletonBox
      type="header"
      class="margin-t-3 h-32"
      :style="{ 'margin-bottom': '10px', width: '110px' }"
    />
    <v-simple-table>
      <template #default>
        <thead
          class="gray-box background-border-gray border-radius-regular position-relative overflow-hidden"
        >
          <tr class="h-48">
            <th :style="{ width: '130px' }" />
            <th :style="{ width: '115px' }" />
            <th :style="{ width: '115px' }" />
            <th :style="{ width: '222px' }" />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="skeletonTableviewEntryIndex in 9"
            :key="skeletonTableviewEntryIndex"
            :style="{ height: '50px' }"
          >
            <td
              v-for="skeletonTableviewRowIndex in 5"
              :key="skeletonTableviewRowIndex"
            >
              <SkeletonBox type="header" class="margin-y-1 opacity-30 h-24" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({ components: { SkeletonBox } })
export default class SkeletonQuotesTableView extends Vue {}
</script>

<style lang="scss">
@import '~@/scss/colors';
// With some help from:
// https://markus.oberlehner.net/blog/skeleton-loading-animation-with-vue/
.gray-box {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
