<template>
  <v-img
    :src="vehicleImage"
    :class="{
      'w-136 h-60': hasCombo,
      'w-104 h-60': !hasCombo
    }"
    contain
  />
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { VehicleTypeKey } from '@/utils/enum'

import charterBus from '@/assets/images/vehicles/charter_bus.png'
import miniBus from '@/assets/images/vehicles/mini_bus.png'
import sprinter from '@/assets/images/vehicles/sprinter.png'
import charterBusMiniBus from '@/assets/images/vehicles/charter_bus_mini_bus.png'

@Component
export default class VehicleOptionImage extends Vue {
  @Prop({ type: Array, required: true }) readonly vehicleTypeKeys: VehicleTypeKey[]

  get hasCharterBus(): boolean {
    return this.getHasVehicleType(VehicleTypeKey.CharterBus)
  }

  get hasMiniBus(): boolean {
    return this.getHasVehicleType(VehicleTypeKey.MiniBus)
  }

  get hasSprinter(): boolean {
    return this.getHasVehicleType(VehicleTypeKey.Sprinter)
  }

  get hasCombo(): boolean {
    return this.hasCharterBus && this.hasMiniBus
  }

  get vehicleImage(): any {
    if (this.hasCombo) {
      return charterBusMiniBus
    }
    if (this.hasCharterBus) {
      return charterBus
    }
    if (this.hasMiniBus) {
      return miniBus
    }
    if (this.hasSprinter) {
      return sprinter
    }
    return null
  }

  getHasVehicleType(vehicleTypeKey: VehicleTypeKey): boolean {
    return this.vehicleTypeKeys.some((key) => {
      return key === vehicleTypeKey
    })
  }
}
</script>
