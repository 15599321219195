<template>
  <v-row no-gutters>
    <v-col cols="12">
      <span v-if="error" class="text-error">
        {{ errorMessage }}
      </span>
      <v-form ref="changePasswordForm" v-model="valid" lazy-validation>
        <label>{{ $t('profile.PASSWORD.FORM.CURRENT_PASSWORD.LABEL') }}</label>

        <v-text-field
          id="my-profile-password-text-old-password"
          ref="oldPassword"
          v-model="formFields.oldPassword"
          :rules="rules.oldPassword"
          :append-icon="passwordHidden ? 'visibility' : 'visibility_off'"
          :type="passwordHidden ? 'password' : 'text'"
          hide-details="auto"
          required
          validate-on-blur
          outlined
          @click:append="() => (passwordHidden = !passwordHidden)"
        />

        <label>{{ $t('profile.PASSWORD.FORM.NEW_PASSWORD.LABEL') }}</label>
        <v-text-field
          id="my-profile-password-text-new-password"
          ref="newPassword"
          v-model="formFields.newPassword"
          name="password"
          :rules="rules.newPassword"
          :append-icon="passwordHidden ? 'visibility' : 'visibility_off'"
          :type="passwordHidden ? 'password' : 'text'"
          hide-details="auto"
          required
          validate-on-blur
          outlined
          @click:append="() => (passwordHidden = !passwordHidden)"
          @change="resetPasswordValidation"
        />

        <label>
          {{ $t('profile.PASSWORD.FORM.CONFIRM_NEW_PASSWORD.LABEL') }}
        </label>
        <v-text-field
          id="my-profile-password-text-confirm-new-password"
          ref="confirmNewPassword"
          v-model="formFields.confirmNewPassword"
          :rules="rules.confirmNewPassword"
          name="password"
          :append-icon="passwordHidden ? 'visibility' : 'visibility_off'"
          :type="passwordHidden ? 'password' : 'text'"
          hide-details="auto"
          required
          validate-on-blur
          outlined
          @click:append="() => (passwordHidden = !passwordHidden)"
        />
      </v-form>
    </v-col>
    <v-btn
      medium
      :disabled="disabled"
      :color="submitButtonOptions.color"
      :loading="submitting"
      :style="{ 'min-width': '230px' }"
      :class="{ 'text-white': success || error }"
      class="margin-t-4"
      @click="submit"
    >
      {{ submitButtonOptions.text }}
    </v-btn>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator'
import auth from '@/store/modules/auth'
import authService from '@/services/auth'
import { TranslateResult } from 'vue-i18n'

@Component({})
export default class ProfilePassword extends Vue {
  @Watch('formFields', { deep: true })
  formFieldsChanged(): void {
    this.success = false
    this.error = false
    this.disabled = this.isDisabled()
  }

  valid = false
  formFields = {
    oldPassword: null,
    newPassword: null,
    confirmNewPassword: null,
  }
  rules: Record<string, any> = {}
  asyncPasswordResponse = {
    data: {
      valid: false,
      message: 'Something went wrong'
    }
  }
  shouldValidatePassword = false
  passwordHidden = true
  success = false
  error = false
  submitting = false
  disabled = true
  errorMessage = this.$t('profile.PASSWORD.INVALID')

  get submitButtonOptions(): {
    color: string
    text: TranslateResult
  } {
    let color = 'success'
    let text = this.$t('profile.PASSWORD.PASSWORD_CHANGED')
    if (!this.success) {
      color = this.error ? 'error' : 'primary'
      text = this.error
        ? this.$t('profile.PASSWORD.ERROR_CHANGING_PASSWORD')
        : this.$t('profile.PASSWORD.CHANGE_PASSWORD')
    }
    return {
      color,
      text,
    }
  }

  isDisabled(): boolean {
    if (this.formFields.oldPassword === null || !this.formFields.oldPassword) {
      return true
    }
    if (this.formFields.newPassword === null || !this.formFields.newPassword) {
      return true
    }
    if (
      this.formFields.confirmNewPassword === null ||
      !this.formFields.confirmNewPassword
    ) {
      return true
    }
    return false
  }

  activateRules(): void {
    this.rules = {
      oldPassword: [
        (v) =>
          !!v ||
          this.$t('profile.PASSWORD.FORM.CURRENT_PASSWORD.RULES.REQUIRED'),
      ],
      newPassword: [
        (v) =>
          !!v || this.$t('profile.PASSWORD.FORM.NEW_PASSWORD.RULES.REQUIRED'),
        (v) => !this.shouldValidatePassword || this.asyncPasswordResponse.data.valid || this.asyncPasswordResponse.data.message,
      ],
      confirmNewPassword: [
        (v) =>
          !!v ||
          this.$t('profile.PASSWORD.FORM.CONFIRM_NEW_PASSWORD.RULES.REQUIRED'),
        (v) =>
          v === this.formFields.newPassword ||
          this.$t('profile.PASSWORD.FORM.CONFIRM_NEW_PASSWORD.RULES.MATCH'),
      ],
    }
  }

  async submit(): Promise<void> {
    this.asyncPasswordResponse = await authService.validatePassword(this.formFields.newPassword)
    this.shouldValidatePassword = true
    this.activateRules()

    this.$nextTick(async () => {
      const form = this.$refs.changePasswordForm as any
      this.valid = form.validate()

      if (this.valid) {
        try {
          this.error = false
          const payload = {
            userId: auth.userId,
            currentPassword: this.formFields.oldPassword,
            newPassword: this.formFields.newPassword,
          }
          this.submitting = true
          await authService.setPassword(payload)
          this.success = true
          this.rules = {}
        } catch(e: any) {
          this.errorMessage = e?.response?.data?.message || this.$t('profile.PASSWORD.INVALID')
          this.error = true
        }
      } else {
        this.error = true
      }
      this.submitting = false
    })
  }

  resetPasswordValidation(): void {
    this.shouldValidatePassword = false
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors.scss';

.v-btn {
  transition: all 0.25s ease;
}
::v-deep .v-progress-circular {
  color: white !important;
}

::v-deep .v-input__append-inner {
  margin-top: 13px !important;
  margin-right: 15px;
}

label {
  color: $gray-text;
}

::v-deep .v-text-field input {
  color: $gray-text !important;
  font-weight: 500 !important;
}
</style>
