import { render, staticRenderFns } from "./SalesBotTripType.vue?vue&type=template&id=3cd249e8&scoped=true"
import script from "./SalesBotTripType.vue?vue&type=script&lang=ts"
export * from "./SalesBotTripType.vue?vue&type=script&lang=ts"
import style0 from "./SalesBotTripType.vue?vue&type=style&index=0&id=3cd249e8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cd249e8",
  null
  
)

export default component.exports