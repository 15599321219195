<template>
  <div class="terms__body container">
    <h3>Service Terms And Agreement for Transportation Reservations</h3>
    <p>
      This Service Terms and Agreement for Transportation Reservations (the
      “Agreement”) is by and between CharterUp LLC (“CharterUp”) and you (either
      individually or on behalf of any entity whom you are representing)
      (“Client” or “you”). This Agreement governs your procurement of bus
      charter services (the “Charter Services”) provided by an independent
      third-party company (the “Charter Company”).
    </p>

    <p>
      BY CLICKING “I AGREE”, MAKING A RESERVATION, UTILIZING THE CHARTER
      SERVICES, OR OTHERWISE SIGNIFYING YOUR ACCEPTANCE OF THIS AGREEMENT, YOU
      REPRESENT AND WARRANT THAT (A) YOU ARE AUTHORIZED TO ENTER THIS AGREEMENT
      FOR AND ON BEHALF OF YOURSELF AND, IF APPLICABLE, YOUR ORGANIZATION, AND
      ARE DOING SO, (B) YOU AND YOUR ORGANIZATION CAN LEGALLY ENTER INTO THESE
      TERMS AND (C) YOU HAVE READ AND UNDERSTAND AND AGREE THAT YOU AND THE
      ORGANIZATION SHALL BE BOUND BY THE TERMS OF THIS AGREEMENT.
    </p>

    <p>
      You agree that you are fully responsible for the actions of all of you,
      your passengers, guests and invitees (collectively, the “Passengers”)
      aboard the vehicles provided through CharterUp (the “Service Buses”).
    </p>

    <h5>1. RESERVATIONS AND DEPOSIT</h5>

    <p>
      Reservations for Charter Services may be made electronically through
      CharterUp’s website – www.CharterUp.com (the “Website”) or by phone. Until
      the reservation is confirmed by CharterUp in writing (including by email)
      and the full payment received from you, the reservation is subject to
      change by CharterUp, the Charter Company, or you.
    </p>

    <p>
      You understand and agree that payment for all charter services reserved
      through CharterUp shall be made payable to, and collected by, CharterUp
      LLC without regard to the name of the Charter Company.
    </p>

    <p>
      You understand and agree that the rates shown on CharterUp’s website or
      otherwise provided to you by CharterUp for a particular Charter Company
      (defined below), may be different (e.g., higher or lower) than rates you
      may be offered when contacting the Charter Company directly as such rates
      may include revenue to CharterUp and/or discounts offered for trips
      arranged though the CharterUp portal. You further understand and agree
      that Charter Company options presented to you by CharterUp may not include
      all potential options available on the CharterUp platform or may be
      presented in an order/page ranking based on factors such as corporate
      affiliation with CharterUp or other factors within the sole discretion of
      CharterUp.
    </p>

    <p>
      CharterUp is not responsible for any confirmation of your reservations
      that is lost or otherwise not received when booking via the internet. If
      your spam blocker is on, a confirmation may not get through. It is your
      responsibility to call and get a confirmation email when making a booking.
    </p>

    <p>
      All reservations that are more than fourteen (14) days from the scheduled
      date of service (the “Date of Service”) require a deposit of at least ten
      (10) percent. CharterUp shall confirm the amount of the deposit at the
      time Client makes its reservation. A deposit of the full estimated cost of
      the Charter Services (the “Estimated Cost”) is required for all
      reservations made less than fourteen (14) days prior to the Date of
      Service.
    </p>

    <p>
      Payment of the full Estimated Cost is due no later than fourteen (14) days
      prior to the Date of Service. If you fail to timely pay any deposits or
      Estimated Costs or any other amounts owed to CharterUp as and when
      required in accordance with this Section 1, (a) you hereby authorize
      CharterUp to, and CharterUp may in its sole discretion, initiate entries
      to your check/savings account and/or credit card at the financial
      institution provided by you at the time of reservation for the payment of
      any such deposits or Estimated Costs and/or (b) your reservation may be
      cancelled in the sole discretion of CharterUp.
    </p>

    <p>
      CharterUP depends on Charter Companies to update their availability on the
      Website as well as other software and methods to try to detect
      availability. CharterUP shall attempt when possible to source your Service
      Buses from the Charter Company you select. Notwithstanding the foregoing,
      CharterUP reserves the right, in its sole discretion, at any time to
      substitute any or all Charter Companies you select or are assigned for a
      different Charter Company. Among other things, any such changes may result
      in a change in pricing, services, and/or equipment. You understand and
      agree that such substitutions shall impose no obligation for CharterUP to
      notify you of such substitution at any time.
    </p>

    <p>
      Client may change a reservation subject to (a) the availability of the
      requested Charter Services as proposed by the changed reservation, (b)
      payment of the applicable fee as determined by CharterUp in its sole
      discretion and (c) the approval by Charter Company in its sole discretion.
      Subject to the foregoing, all reservations are final and all payments and
      deposits are non-refundable.
    </p>

    <h5>2. CANCELLATIONS AND REFUND POLICY</h5>

    <p>
      (a) i) Any vehicle or reservation cancellation made at least thirty (30)
      days prior to the Date of Service will be 100% fully refundable and not
      subject to any charge or cancellation fee; ii) any vehicle or reservation
      cancellation made between twenty-nine (29) days and seventy-two (72) hours
      prior to the Date of Service will result in a cancellation fee and charge
      equal to 10% of the contracted amount that will be owed and collected;
      iii) any vehicle or reservation cancellation made within seventy-two (72)
      hours before the Date of Service or anytime afterward will result in a
      cancellation fee and charge equal to 100% of the contracted amount that
      will be owed and collected. In the event that you partially cancel a
      reservation by reducing the size or scope of vehicles reserved,
      cancellation fees will be applied pro-rata based on the percentage impact
      to the total Estimated Cost.
    </p>

    <p>
      In certain specific circumstances, CharterUp may in its sole discretion
      refund some or all of a deposit or the Estimated Costs that have been paid
      by you if the Charter Service Bus procured by you for Charter Services
      breaks down or breaches its obligations under this Agreement to provide
      the Charter Services (a “Performance Failure”). Any such Performance
      Failure shall not affect, and there shall be no refunds of any sort, in
      connection with any Service Buses not affected by a Performance Failure
      that you may have chartered for Charter Services under this Agreement. You
      shall remain fully responsible and liable for all deposits, the Estimated
      Costs and all other amounts due under this Agreement for any such other
      Service Buses and Charter Services in accordance with the terms of this
      Agreement.
    </p>

    <p>
      (b) If you want to cancel or request a change to your reservation, you
      shall send written notice to: support@charterup.com
    </p>

    <p>
      (c) You agree to pay any cancellation or change fees that you incur. Any
      charge associated with a cancellation is final and not transferable to
      future or alternative services.
    </p>

    <p>
      (d) If the driver arrives at the scheduled pick-up location and Client is
      not there, and the Charter Company is unable to locate or contact the
      contact person within 60 minutes of the scheduled pick-up time, by the
      means provided in the reservation, then the ride will be considered a
      no-show and the reservation cancelled. You shall be fully liable for 100%
      of the Estimated Cost.
    </p>

    <p>
      (e) If you would like to submit a complaint due to a Performance Failure
      or otherwise request for refund, you may do so by contacting CharterUp’s
      support team within forty-eight (48) hours after the completion of a
      confirmed reservation. Complaints received by CharterUP more than
      forty-eight (48) hours after a reservation’s completion shall be
      considered late and may not be considered for purposes of providing
      refunds or otherwise in CharterUp’s sole discretion.
    </p>

    <p>
      (f) The cancellation policies outlined in this Section 2 apply to both (i)
      reservation cancellations and (ii) reservation changes that reduce the
      size or scope, including number, passenger count, and duration of use, of
      vehicles reserved.
    </p>

    <p>
      (g) Notwithstanding the foregoing, CharterUP may, in its sole discretion,
      grant limited exceptions to this cancellation policy in the event of Force
      Majeure (defined below) events. To qualify for such an exception, you
      must: i) prove the existence of a Force Majeure event; ii) show in writing
      the existence of a mandatory order from a governmental authority, limiting
      activities (a “Mandatory Order”); iii) show that your pickup address,
      final destination or only available route is directly impacted by the
      Force Majeure event and the Mandatory Order; and iv) submit the
      cancellation request at least 24 hours prior to scheduled departure.
    </p>

    <h5>3. RATES AND PAYMENT</h5>
    <p>
      (a) All advertised rates are subject to change without notice and may be
      increased at any time prior to confirmation by CharterUp in its sole
      discretion.
    </p>

    <p>
      (b) The rate quoted for the Charter Services is an estimate based on the
      information that you provided to CharterUp in connection with your
      reservation. There may be additional charges including for taxes, credit
      card processing fees, damage to the buses, injury to the driver, overtime
      (i.e., exceeding the time of your reservation), traveling further than
      originally requested and clean-up that requires a greater than normal
      amount of time and material necessary to clean the buses properly
      (“Excessive Cleaning”). The fee for Excessive Cleaning shall be determined
      by CharterUp in its sole discretion but the minimum fee is $250.
    </p>

    <p>
      (c) CharterUP reserves the right to notify (by phone, text, email or other
      reasonable means) you at any time prior to the scheduled trip of any
      adjustments to quoted and confirmed prices or services (“Price/Service
      Adjustments”) up to the time of any booking’s time of departure to
      accommodate (i) any itinerary changes or services that you may request and
      /or (ii) changes to the Charter Company or equipment and/ or services
      selected to service your reservation. You recognize that any Price/Service
      Adjustments are legally binding changes for any previously provided quotes
      and/or confirmed prices, and CharterUP is under such circumstances under
      no obligation to honor previously-quoted or confirmed prices, equipment
      and/ or services or to send an updated quote or confirmed prices when
      there are Price/Service Adjustments.
    </p>

    <p>
      (d) Client shall reimburse CharterUP for any other expenses incurred, by
      Charter Company or otherwise, in connection with the provision of the
      Charter Services, including driver’s lodging, tolls, parking fees,
      additional stops, and any other expenses described in Section 4(b) (the
      “Customer Expenses”) and CharterUp shall have no responsibility or
      liability for any such Customer Expenses.
    </p>

    <p>
      (e) You hereby authorize CharterUp to initiate entries to your
      check/savings account and/or credit card at the financial institution
      provided by you at the time of reservation, and, if necessary, initiate
      adjustments for any transactions credited or debited in error or
      additional charges. This authority remains in effect until CharterUp is
      notified in writing to cancel it. To cancel such authority, you shall
      provide written notice to CharterUp no less than thirty (30) days prior to
      the effective date of such cancellation. CharterUp may attempt multiple
      smaller transactions if your financial institution declines large
      transaction attempts.
    </p>

    <p>
      You agree that if any attempt to charge a credit or debit card is declined
      at any time, then CharterUp may terminate the option to pay by credit or
      debit card. If CharterUp terminates this option, then all remaining
      payments shall be made via check or electronic wire transfer. If you
      refuse to pay the remaining balance via check or wire then your
      reservation(s) will be cancelled and you will be subject to the
      cancellation charges set forth in Section 2. You agree that CharterUp
      shall ask, at its discretion, for all total payments exceeding $5,000 to
      be made via check or electronic wire transfer. Refusing to pay via check
      or electronic wire transfer does not nullify this agreement and shall not
      relieve you of cancellation charges as set forth in Section 2.
    </p>

    <p>
      (f) You agree not to dispute any fees or charges in connection with the
      Charter Services that were otherwise made consistent with the terms of
      this Agreement, including cancellation fees, warranties, dispute
      resolution, the Waived Conditions and any other risks for which CharterUp
      has disclaimed responsibility or any claims that you have waived (the
      “Improperly Disputed Amounts”). If you dispute any such fees or charges,
      then CharterUp shall have the right to charge you, and you shall pay, any
      related fees charged by any financial institution involved with the
      dispute, any attorneys' fees and expenses and court or arbitration costs
      associated with enforcing and collecting payment of any such fees or
      charges and any other damages suffered by CharterUp related to or arising
      from the Improperly Disputed Amounts.
    </p>

    <p>
      (g) It is a violation of law to place a reservation in a false name or
      with an invalid credit card number with the intent to defraud. Please be
      aware that even if you do not give CharterUp your real name, your Web
      browser transmits a unique Internet address to us that can be used by law
      enforcement officials to identify you. Fraudulent users will be prosecuted
      to the fullest extent of the law. Accordingly, if you knowingly input
      false information in a reservation form, including someone else’s name,
      e-mail, address, physical address, phone number, random or made up name,
      address, e-mail, or phone number, you agree to fully indemnify and be
      liable to CharterUp and its affiliate companies, for an amount of $10,000
      to each company involved, plus any attorneys' fees and expenses and court
      or arbitration costs.
    </p>

    <h5>4. OVERTIME AND ADDITIONAL CHARGES</h5>
    <p>
      (a) Client shall be fully responsible for any overtime incurred by the
      Charter Company. Overtime will be charged at a rate determined by
      CharterUp in its sole discretion and is incurred in hourly increments
      (plus additional taxes and fees). CharterUp cannot guarantee the
      availability of overtime. It is of particular importance then that you
      make allowances for anticipated delays and adhere to the agreed time
      schedule. Time and charges begin when the Service Bus arrives at the
      pick-up location. If there is an error in the stated address, you shall be
      charged for the time needed to reroute the Service Bus to a different
      address.
    </p>

    <p>
      (b) You agree to pay the following additional expenses incurred in
      connection with Charter Services directly to CharterUP: (i) driver's
      lodging, (ii) tolls, (iii) parking fees (iv) additional stops, (v)
      reasonable overtime charges incurred by Charter Company, including
      overtime charges resulting from traffic delays, and (vi) any other
      expenses incurred directly by the Charter Company in connection with the
      provision of the Charter Services. You agree that if required, as
      determined by CharterUP in its sole discretion, you will arrange for
      appropriate lodging for drivers that meet or exceed the following minimum
      standards: 3.0 star or better hotel/motel, private room with private
      shower and bathroom. If you fail to provide the driver(s) with private
      lodging meeting the above listed requirements, you (i) authorize CharterUp
      or the Charter Company to procure such alternative lodging for the
      driver(s) and (ii) agree to reimburse CharterUp for the cost of any such
      alternative lodging.
    </p>

    <h5>5. CHARTER SERVICES</h5>

    <p>
      (a) Client acknowledges and agrees that CharterUp is not a transportation
      carrier and will not directly provide the Charter Services. CharterUp
      merely provides a marketplace in which you may engage and pay for the
      services of independent third-party transportation carriers (the
      “Carriers”) that are directly responsible for providing the Charter
      Services to you. CharterUp uses reasonable commercial efforts to ensure
      that all such Carriers are (i) DOT licensed and (ii) qualified and able to
      provide the Charter Services. Notwithstanding the foregoing, CharterUp
      shall not be responsible, and shall have no liability, for the acts or
      omissions of the Charter Company, including errors, late shows, the
      quality of the Service Bus, the performance of the drivers, any violations
      of applicable laws, rules or regulations or any failure to provide the
      Charter Services.
    </p>

    <p>
      (b) Images of, and information regarding, buses and other vehicles
      presented on the Website are provided by the Charter Companies and may
      differ from the actual Service Buses. While Carriers are required to
      provide accurate images and information, CharterUp does not independently
      verify the accuracy of any images or information provided by Carriers;
      therefore, you agree that you are not relying on CharterUp for any damages
      arising from any such inaccurate information. You agree that it is your
      sole obligation to investigate independently the suitability of any
      Charter Company selected by you through CharterUp or based on any
      information provided by or through CharterUp.
    </p>

    <p>
      (c) CharterUp cannot guarantee any of the following (collectively, the
      “Waived Conditions”): • the specific make, model, year, or specific bus
      appearance of the Service Bus • the functionality of the hvac,
      air-conditioning or plumbing (including on-board restrooms) on the Service
      Bus • the availability or working condition of requested amenities
      including, but not limited to, seat type, on-board restrooms, wheel-chair
      lifts, WI-FI internet, DVD/TV equipment, USB charging, or electric plugs •
      the behavior of the drivers and how they treat the Passengers • the
      driver's use of a cell phone • the provision of the Charter Services if
      any of the Service Buses are affected by weather, mechanical breakdown,
      blown tires, motor vehicle accident or other similar events (collectively,
      the “Interference Events”). You acknowledge and agree that CharterUp does
      not guarantee the Waived Conditions and you specifically waive any claims
      against CharterUp or right to terminate this Agreement or demand any
      refund from CharterUp related to or in connection with the Waived
      Conditions. At CharterUp’s sole discretion, CharterUp may provide limited
      assistance in resolving claims against a Carrier when such claims are
      presented before trip end. With respect to any Interference Event,
      CharterUp shall use commercially reasonable efforts to encourage the
      Charter Company to get the Service Bus operating again or to obtain a
      replacement bus as soon as possible.
    </p>

    <p>
      (d) You acknowledge and agree that Service Buses and their drivers may not
      arrive on time for pickup, and may deliver Passengers later than their
      original scheduled arrival time. You specifically waive any claims against
      CharterUp and the Charter Company or to terminate this Agreement or any
      sort of refund if the Service Bus is delayed due to events of Force
      Majeure, including GPS malfunctions, GPS signal problems incorrect route
      information on the GPS, road closures, or traffic delays. In addition, you
      acknowledge and agree that certain road and vehicle conditions may require
      the Service Bus to travel slower than usual. The speed at which to travel,
      the route and any other trip related decisions shall be solely determined
      by the driver in his sole discretion.
    </p>

    <p>
      (e) Unless agreed to in writing at the time of the reservation, CharterUp
      is not obligated to provide options for buses or drivers that are SPAB
      certified / school certified, DOD certified, or require special permits or
      certificates. Cancellation for such reasons shall not relieve you of
      cancellation charges as set forth in Section 2.
    </p>

    <p>
      (f) You agree that Charter Company may reserve a reasonable number of
      seats on the Service Bus to allow any required additional drivers or
      support staff to travel on the Service Bus.
    </p>

    <p>
      (g) CharterUp shall not be responsible for any lost or damaged items.
      CharterUp reserves the right to charge a fee for returning any items found
      in the Service Buses.
    </p>

    <p>
      (h) CharterUp, the Charter Company or the driver may terminate any trip
      without refund in its sole discretion, if (i) the driver or the dispatcher
      on duty feels that you or any of the Passengers are putting the driver,
      the Service Bus or any of the Passengers in danger or harm or (ii) you
      and/or any of the Passengers are in possession of any illegal material
      and/or substance. This is without exception.
    </p>

    <p>
      (i) Drivers must follow FMCSA Hours of Service Regulations and other
      applicable regulations and shall not agree to any request to exceed the
      regulated FMCSA Hours of Service or to violate any other regulations.
      These regulations restrict driving time to ten (10) hours and duty time to
      fifteen (15) hours. Unless agreed to in writing at the time of the
      reservation, Charter Company is not obligated to arrange buses with more
      than one driver on each bus. Drivers are entitled to a hotel room in
      accordance with Section 4(b) no later than when they reach these
      restrictions on driving and/or duty times. Charter Company may terminate
      any trip without refund in its sole discretion if you or any of the
      Passengers attempt to force the driver to exceed regulated FMCSA Hours of
      Service or to violate any other applicable regulations. Additional detail
      can be found on the FMCSA website:
      https://www.fmcsa.dot.gov/regulations/hours-service/summary-hours-service-regulations.
    </p>

    <p>
      (j) You, or such representative designated by you, agree that as requested
      by the Driver, you will input such requested information into the
      CharterUp application utilized by the Driver on a mobile device for the
      purpose of confirming the trip and the completion thereof.
    </p>

    <h5>6. WARRANTIES; DISCLAIMERS AND LIMITATIONS OF LIABILITY</h5>

    <p>
      (a) Warranties. Each party represents and warrants to the other that (a)
      it has the full power to enter into this Agreement and to perform its
      obligations hereunder, (b) this Agreement constitutes a legal, valid and
      binding obligation of such party, enforceable against such party in
      accordance with its terms, and (c) this Agreement does not contravene,
      violate or conflict with any other agreement of such party.
    </p>

    <p>
      (b) Disclaimer. EXCEPT AS SPECIFICALLY PROVIDED IN THIS AGREEMENT,
      CHARTERUP MAKES, AND CHARTERUP EXPRESSLY DISCLAIMS, ANY REPRESENTATIONS OR
      WARRANTIES IN CONNECTION WITH THIS AGREEMENT, THE CHARTER SERVICES, THE
      SERVICE BUSES OR THE CHARTER CARRIER, WHETHER EXPRESS, IMPLIED, STATUTORY
      OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF
      THIRD PARTY RIGHTS, TITLE, ANY WARRANTIES ARISING OUT OF A COURSE OF
      PERFORMANCE, DEALING OR TRADE USAGE, AND THEIR EQUIVALENTS UNDER THE LAWS
      OF ANY JURISDICTION. WITHOUT LIMITATION TO THE FOREGOING, CHARTERUP DOES
      NOT PROVIDE ANY WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF
      ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, THAT WITH
      RESPECT TO THE CHARTER SERVICES OR THE SERVICE BUSES WILL MEET YOUR
      REQUIREMENTS OR ACHIEVE ANY INTENDED RESULTS.
    </p>

    <p>
      YOU EXPRESSLY AGREE THAT THE CHARTER SERVICES AND THE SERVICE BUSES ARE
      PROVIDED ON AN “AS IS” AND “AS AVAILABLE”, “WITH ALL FAULTS” BASIS AND
      WITHOUT WARRANTIES OR REPRESENTATIONS OF ANY KIND EITHER EXPRESS OR
      IMPLIED.
    </p>

    <p>
      (c) Limitation of Liability. CHARTERUP SHALL NOT BE LIABLE TO YOU OR ANY
      OF YOUR PASSENGERS FOR ANY SPECIAL, CONSEQUENTIAL, PUNITIVE, INCIDENTAL,
      OR INDIRECT DAMAGES, OR ANY DAMAGES FOR LOST DATA, BUSINESS INTERRUPTION,
      LOST PROFITS, LOST REVENUE OR LOST BUSINESS, ARISING OUT OF OR IN
      CONNECTION WITH THIS AGREEMENT, THE CHARTER SERVICES OR THE SERVICE BUSES
      HOWEVER CAUSED AND BASED ON ANY THEORY OF LIABILITY, ARISING OUT OF THIS
      AGREEMENT, THE CHARTER SERVICES OR THE SERVICE BUSES, WHETHER OR NOT
      CHARTERUP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE, AND
      NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
      APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR
      INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION
      MAY NOT APPLY. IN SUCH CASES, CHARTERUP’S LIABILITY WILL BE LIMITED TO THE
      FULLEST EXTENT PERMITTED BY APPLICABLE LAW. In no event will CharterUp’s
      total liability to you for all damages, losses, and causes of action
      arising out of or relating to this Agreement or the Charter Services
      (whether in contract, tort including negligence, warranty, or otherwise),
      exceed the amount paid by you, if any, to CharterUp during the twelve (12)
      months immediately preceding the date of the claim, or five thousand
      dollars ($5,000.00), whichever is greater. The essential purpose of this
      provision is to limit the potential liability of CharterUp arising out of
      this Agreement or the Charter Services whether for breach of contract,
      negligence, or otherwise. These limitations shall apply notwithstanding
      any failure of essential purpose of any limited remedy.
    </p>

    <p>
      (d) You acknowledge and agree that CharterUp and Charter Company are,
      jointly are severally, providing the CharterUp portal and services and
      Charter Services, respectively, and other services, sets prices, and
      entered into this Agreement in reliance upon the warranty disclaimers and
      the limitations of liability set forth herein, that the warranty
      disclaimers and the limitations of liability set forth herein reflect a
      reasonable and fair allocation of risk between you and CharterUp, and that
      the warranty disclaimers and the limitations of liability set forth herein
      form an essential basis of the bargain between you and CharterUp.
      CharterUp would not be able to provide the Charter Services to you on an
      economically reasonable basis without these limitations.
    </p>

    <p>
      (e) Neither CharterUp nor Charter Company shall be liable for any delay or
      failure in its performance of any of the acts required by this Agreement
      or the Charter Services, when such delay or failure arises from
      circumstances beyond the control and without the fault or negligence of
      such party (“Force Majeure”). Such causes may include, without limitation,
      traffic, mechanical breakdowns, road conditions, acts of local, state or
      national governments or public agencies, acts of public enemies, acts of
      civil or military authority, labor disputes, utility or communication
      failures or delays, earthquakes, fire, flood, other natural disasters,
      epidemics, pandemics, riots or strikes. The time for performance of any
      act delayed by any such event may be postponed for a period equal to the
      period of such delay.
    </p>

    <h5>7. LICENSE</h5>

    <p>
      (a) In situations where You engage CharterUp to provide Charter Services
      to an event (each, an “Event”) You own or are directly hosting or
      sponsoring, You hereby grant to CharterUp a perpetual, fully paid,
      non-exclusive, and royalty-free license to utilize Your trade names,
      trademarks, service marks, logos, domain names, marks or other business
      identifiers related to such Event (collectively, the “Marks”) within the
      scope of license (the “Scope”) defined in Section 7(c) below.
    </p>

    <p>
      (b) In situations where You engage CharterUp to reserve and/or pay for
      Charter Services to an Event that is owned, hosted or sponsored by a third
      party, You covenant, warrant and agree to obtain from such third party a
      perpetual, fully paid, non-exclusive, and royalty-free license to utilize
      such third party’s trade names, trademarks, service marks, logos, domain
      names, marks or other business identifiers related to such Event
      (collectively, the “Third Party Marks”) within the scope of license (the
      “Scope”) defined below.
    </p>

    <p>
      (c) For the purposes of this Agreement, the “Scope” shall mean CharterUp’s
      use of the Marks or Third Party Marks, as the case may be, to advertise
      and promote CharterUp’s Charter Services by publicizing the fact that
      CharterUp has provided such Charter Services to or on behalf of such
      Event.
    </p>

    <h5>8. INDEMNIFICATION</h5>

    <p>
      You shall indemnify and hold harmless CharterUp, and each of its officers,
      directors, agents, contractors, subcontractors, licensees and employees
      (collectively referred to as the “CharterUp Indemnitees”), and each of
      them, against and from any and all third party allegations, demands,
      claims, liabilities, damages, fines, penalties or costs of whatever nature
      (including reasonable attorneys’ fees), and whether by reason of death of
      or injury to any person or loss of or damage to any property or otherwise,
      (“CharterUp Claims”), arising out of or in any way connected with (a) any
      actual or alleged violation or breach by you (including any of your
      employees. contractors or passengers) of any of the terms and conditions
      of this Agreement, (b) violation of any law, rule or regulation, (c) use
      of the Website by you (including any of your employees or contractors) on
      behalf of yourself or any third party, (d) any failure to procure the
      license rights contemplated under Section 7(b) above, and (e) the acts or
      omissions of you (including any of your employees and contractors). If any
      CharterUp Claim is made or any action or proceeding is brought against
      CharterUp Indemnitees, or any of them, any such CharterUp Indemnitee may,
      by notice to you, require you, at your expense, to resist such CharterUp
      Claim or take over the defense of any such action or proceeding and employ
      counsel for such purpose, such counsel to be subject to the prior approval
      of such CharterUp Indemnitee. Your reimbursement, indemnity and
      contribution obligations under this Section 7 shall be in addition to any
      liability that you may otherwise have, and shall extend upon the same
      terms and conditions to CharterUp Indemnitees.
    </p>

    <p>
      You and CharterUp agree that any dispute, claim or controversy at law or
      equity that arises out of this Agreement or the Charter Services (a
      “Claim”) will be resolved in accordance with this Section 8 or as
      CharterUp and you otherwise agree in writing.
    </p>

    <h5>9. GOVERNING LAW AND DISPUTE RESOLUTION.</h5>

    <p>
      (a) Choice of Law. This Agreement shall be governed in all respects by the
      laws of the State of Georgia, without regard to its conflict of law
      provisions. EACH PARTY IRREVOCABLY AND UNCONDITIONALLY WAIVES, TO THE
      FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ANY RIGHT IT MAY HAVE TO A
      TRIAL BY JURY IN ANY LEGAL ACTION, PROCEEDING, CAUSE OF ACTION OR
      COUNTERCLAIM ARISING OUT OF OR RELATING TO ANY CLAIM OR OTHERWISE IN
      CONNECTION WITH THIS AGREEMENT OR THE CHARTER SERVICES.
    </p>

    <p>
      (b) Arbitration and Class Action Waiver. PLEASE REVIEW AS THIS AFFECTS
      YOUR LEGAL RIGHTS.
    </p>

    <p>
      (i) Arbitration. YOU AGREE THAT ALL CLAIMS BETWEEN YOU AND CHARTERUP
      (WHETHER OR NOT SUCH CLAIM INVOLVES A THIRD PARTY) IN CONNECTION WITH THIS
      AGREEMENT OR THE CHARTER SERVICES WILL BE RESOLVED BY BINDING, INDIVIDUAL
      ARBITRATION UNDER THE AMERICAN ARBITRATION ASSOCIATION'S RULES FOR
      ARBITRATION OF CONSUMER-RELATED DISPUTES IN FULTON COUNTY, GEORGIA. YOU
      AND CHARTERUP HEREBY EXPRESSLY WAIVE TRIAL BY JURY. DISCOVERY AND RIGHTS
      TO APPEAL IN ARBITRATION ARE GENERALLY MORE LIMITED THAN IN A LAWSUIT, AND
      OTHER RIGHTS THAT YOU AND CHARTERUP WOULD HAVE IN COURT MAY NOT BE
      AVAILABLE IN ARBITRATION.
    </p>

    <p>
      Neither you nor CharterUp will participate in a class action or class-wide
      arbitration for any claims covered by this agreement to arbitrate.
    </p>

    <p>
      YOU ARE GIVING UP YOUR RIGHT TO PARTICIPATE AS A CLASS REPRESENTATIVE OR
      CLASS MEMBER ON ANY CLASS CLAIM YOU MAY HAVE AGAINST CHARTERUP INCLUDING
      ANY RIGHT TO CLASS ARBITRATION OR ANY CONSOLIDATION OF INDIVIDUAL
      ARBITRATIONS. You also agree not to participate in claims brought in a
      private attorney general or representative capacity, or consolidated
      claims involving another person's account, if CharterUp is a party to the
      proceeding. This dispute resolution provision will be governed by the
      Federal Arbitration Act and not by any state law concerning arbitration.
      In the event the American Arbitration Association is unwilling or unable
      to set a hearing date within one hundred and sixty (160) days of filing
      the case, then either CharterUp or you can elect to have the arbitration
      administered instead by the Judicial Arbitration and Mediation Services.
      Judgment on the award rendered by the arbitrator may be entered in any
      court having competent jurisdiction. Any provision of applicable law
      notwithstanding, the arbitrator will not have authority to award damages,
      remedies or awards that conflict with these Terms.
    </p>

    <p>
      (ii) Judicial Forum for Disputes. In the event that the agreement to
      arbitrate under this Section 8(b) is found not to apply to you or your
      claim, you and CharterUp agree that any judicial proceeding (other than
      small claims actions) must be brought, solely and exclusively in, and will
      be subject to the service of process and other applicable procedural rules
      of, the federal or state courts of Fulton County, Georgia. Both you and
      CharterUp consent to venue and personal jurisdiction there.
      Notwithstanding the foregoing, CharterUp may bring a claim for equitable
      relief in any court with proper jurisdiction.
    </p>

    <p>
      (iii) This arbitration agreement will survive the termination of this
      Agreement or your relationship with CharterUp.
    </p>

    <p>
      (c) Improperly Filed Claims. All claims you bring against CharterUp must
      be resolved in accordance with this Section 8. All claims filed or brought
      contrary to this Section 8 shall be considered improperly filed. Should
      you file a claim contrary to this Section 8, CharterUp may recover
      attorneys’ fees and costs up to $15,000, provided that CharterUp has
      notified you in writing of the improperly filed claim, and you have failed
      to promptly withdraw the claim.
    </p>

    <p>
      (e) Limitation on Time to File Claims. Any cause of action or claim you
      may have arising out of or relating to this Agreement or the Charter
      Services must be commenced within one (1) year after the cause of action
      accrues, otherwise, such cause of action or claim is permanently barred
    </p>

    <h5>10. Non-Disparagement.</h5>

    <p>
      You agree to take no action which is intended, or would reasonably be
      expected, to harm the Company or its or their reputation or which would
      reasonably be expected to lead to unwanted or unfavorable publicity to the
      Company provided, however, that in connection with any review of the
      Company’s services, your actions will be only actionable when it is
      defamatory, libelous, slanderous, harassing, abusive, obscene, vulgar,
      sexually explicit, and/or is inappropriate with respect to race, gender,
      sexuality, ethnicity, or other intrinsic characteristics. Should you take
      such action, CharterUp, in addition to any damages it incurs, may also
      recover attorneys’ fees and costs up to $20,000.
    </p>

    <h5>11. Miscellaneous.</h5>

    <p>
      (a) In the event that any provision of this Agreement shall be adjudged
      illegal or otherwise unenforceable, such provision shall be severed and
      the balance of this Agreement shall continue in full force and effect.
    </p>

    <p>
      (b) The waiver by CharterUp of any breach of any provision of this
      Agreement shall not operate or be construed as a waiver of any other
      breach
    </p>

    <p>
      (c) All notices pursuant to this Agreement to either party shall be in
      writing and shall be personally delivered or mailed by overnight courier,
      or transmitted electronically, fees prepaid. Notice shall be deemed given
      and effective on receipt by the party to whom such notice is directed.
    </p>

    <p>
      To: CharterUP LLC. 6595 Roswell Rd Suite G291 Atlanta, GA 30328 Email:
      legal@CharterUp.com Attn: CharterUp LLC.
    </p>

    <p>
      To Client: To such address as provided to CharterUp
    </p>

    <p>
      (d) Except as provided in Section 8, the rights and remedies of the
      parties hereunder shall not be exclusive, and are in addition to any of
      other rights provided by this Agreement or by law.
    </p>

    <p>
      (e) This Agreement is the entire agreement of the parties with respect to
      the Charter Services. Any additional terms or any modification to this
      Agreement shall not be binding on either party unless in a writing duly
      signed by the party to be charged.
    </p>

    <p>
      (f) This Agreement and any rights and licenses granted hereunder, may not
      be transferred or assigned by you (by operation of law or otherwise), but
      may be assigned by CharterUp without restriction. Any assignment attempted
      to be made in violation of this Agreement shall be void.
    </p>

    <p>
      (g) No agency, partnership, joint venture, employee-employer or
      franchiser-franchisee relationship is intended or created by these Terms.
    </p>

    <p>
      (h) Upon termination of this Agreement, any provision which, by its nature
      or express terms should survive, will survive such termination or
      expiration, including, but not limited to, Sections 2(a) and 3- 9.
    </p>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class TransportationTerms extends Vue {
}
</script>

<style lang="scss" scoped>
div.terms__body {
  text-align: left;
  h3 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 24px;
  }
  h5 {
    margin-top: 12px;
  }
  p {
    font-size: 0.75rem;
  }
}
</style>
