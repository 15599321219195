<template>
  <div class="d-flex flex-row justify-space-between align-start">
    <div
      v-if="$vuetify.breakpoint.smAndUp"
      class="d-flex flex-column border-radius-regular padding-x-1"
      :class="{
        'background-modified-trip': vehiclesChanged,
      }"
    >
      <div
        v-for="(vehicle, vehicleIndex) in vehicles"
        :id="`trip-info-vehicle-${vehicleIndex}-content`"
        :key="`${vehicle.vehicleTypeKey}-${vehicleIndex}`"
      >
        {{ vehiclesPretty(vehicle.vehicleTypeKey, vehicle.quantity) }}
      </div>
    </div>
    <div
      v-else
      class="border-radius-regular padding-x-1"
      :class="{
        'background-modified-trip': vehiclesChanged,
      }"
    >
      {{ formattedVehiclesList }}
    </div>
    <span
      id="trip-info-passenger-count"
      class="border-radius-regular padding-x-1"
      :class="{
        'background-modified-trip': passengerCountChanged,
      }"
    >
      {{ tripPassengerCount }}
    </span>
    <span
      class="border-radius-regular padding-x-1"
      :class="{
        'background-modified-trip': tripTypeChanged,
      }"
    >
      {{ tripTypeTitle }}
    </span>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { WizardVehicle } from '@/models/dto'
import { VehicleTypeKey } from '@/utils/enum'
import { pluralize } from '@/utils/string'
import { vehicleTypeKeyToLabel } from '@/utils/vehicle'
import { tripTypeIdToLabel } from '@/utils/trip'

@Component({})
export default class TripReviewMetadata extends Vue {
  @Prop({ type: Array, required: true }) readonly vehicles: WizardVehicle[]
  @Prop({ type: Number, required: true }) readonly passengerCount: number
  @Prop({ type: Number, required: true }) readonly tripTypeId: number
  @Prop({ type: Boolean, default: false }) readonly tripTypeChanged: boolean
  @Prop({ type: Boolean, default: false })
  readonly passengerCountChanged: boolean
  @Prop({ type: Boolean, default: false }) readonly vehiclesChanged: boolean

  get tripPassengerCount(): string {
    return `${this.passengerCount} Passengers`
  }

  get tripTypeTitle(): string {
    return tripTypeIdToLabel(this.tripTypeId) ?? 'Unknown trip type'
  }

  get formattedVehiclesList(): string {
    return (
      this.vehicles
        ?.map(({ vehicleTypeKey, quantity }) =>
          this.vehiclesPretty(vehicleTypeKey, quantity)
        )
        .join(', ') || ''
    )
  }

  vehiclesPretty(vehicleTypeKey: VehicleTypeKey, quantity: number): string {
    const label = pluralize(quantity, vehicleTypeKeyToLabel(vehicleTypeKey))
    return `${quantity} ${label}`
  }
}
</script>
