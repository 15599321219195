<template>
  <div
    class="d-flex border-radius-regular border-1 border-solid border-border-gray padding-a-2 omnibus"
  >
    <CUIcon
      view-box="0 0 24 24"
      class="w-24 h-24 margin-r-1"
      :style="{ 'margin-top': '2px' }"
    >
      price_expire
    </CUIcon>
    <div>
      <h4>
        Prices and availability may change until booked.
      </h4>
      <span class="font-14">
        This quote will be active until {{ formattedExpirationDate }}.
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { QuoteDetailV2 } from '../models/dto/QuoteDetailV2'

@Component({})
export default class QuoteDetailBidListExpirationWarning extends Vue {
  @Prop({ type: Object, default: null }) readonly quote: QuoteDetailV2

  get expirationDate(): string {
    return this.quote?.expirationDate
  }

  get expirationTimezone(): string {
    return this.quote?.expirationTimezone
  }

  get formattedExpirationDate(): string {
    const expirationDateWithTimezone = this.$dayjs(this.expirationDate).tz(
      this.expirationTimezone
    )

    const localTimezone = (this.$dayjs as any).tz.guess()
    const expirationDateInLocalTimezone = expirationDateWithTimezone.tz(
      localTimezone
    )

    return expirationDateInLocalTimezone.format('MMM D, YYYY, h:mm A z')
  }
}
</script>
