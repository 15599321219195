<template>
  <ReservationDetailVerifySMS
    v-if="showSMSConfirmation"
    confirmation-header="Trip Received"
    confirmation-subheader="Please confirm or update your cell phone number."
    :customer="customer"
    :closeable="false"
    :skippable="true"
    @skip="skipPhone"
    @confirm="markPhoneVerified"
    @update-phone="customerPhoneUpdated"
  />
  <ReservationDetailNextSteps
    v-else-if="showNextSteps"
    :value="showNextSteps"
    :is-elite="areReservationsElite"
    @confirm="markNextStepsConfirmed"
  />
  <CUModal
    v-else
    persistent
    :value="value"
    :closeable="false"
    width="500px"
    @input="$emit('input', $event)"
  >
    <template #text>
      <ReservationDetailTripContactForm
        v-if="showTripInfo"
        show-trip-name
        :closeable="false"
        :shared-list="sharedList"
        :reservations="reservations"
        @confirm="markTripInfoConfirmed"
      />
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Provide } from 'vue-property-decorator'

import ReservationDetailTripContactForm from '@/components/ReservationDetailTripContactForm.vue'
import ReservationDetailNextSteps from '@/components/ReservationDetailNextSteps.vue'
import ReservationDetailVerifySMS from '@/components/ReservationDetailVerifySMS.vue'
import { Customer, ReservationDetail } from '@/models/dto'
import auth from '@/store/modules/auth'
import customer from '@/services/customer'

@Component({
  components: {
    ReservationDetailTripContactForm,
    ReservationDetailNextSteps,
    ReservationDetailVerifySMS,
  },
})
export default class ReservationDetailPostConversionDialog extends Vue {
  @Provide('isInPostConversionDialog') isInPostConversionDialog = true
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: Array, required: true })
  readonly reservations: ReservationDetail[]

  customer: Customer = null
  isTripInfoConfirmed = false
  isNextStepsConfirmed = false
  showConfirmPhone = true
  sharedList = []

  async mounted(): Promise<void> {
    await this.refreshCustomer()
  }

  async customerPhoneUpdated(): Promise<void> {
    await this.refreshCustomer()
  }

  async refreshCustomer(): Promise<void> {
    const customerResponse = await customer.byId(auth.userId)
    this.customer = customerResponse.data.customer
    if (this.customer.smsConfirmed) {
      this.showConfirmPhone = false
    }
  }

  //all reservations should have the same elite status, so grab the first one
  get areReservationsElite(): boolean {
    if (this.reservations && this.reservations.length) {
      return this.reservations[0].isElite
    }
    return false
  }

  get showSMSConfirmation(): boolean {
    return !!this.customer && this.showConfirmPhone
  }

  get showTripInfo(): boolean {
    return (
      !!this.customer && !this.showConfirmPhone && !this.isTripInfoConfirmed
    )
  }

  get showNextSteps(): boolean {
    return !!this.customer && !this.showConfirmPhone && !this.showTripInfo
  }

  get customerId(): number {
    return this.customer?.userId
  }

  markTripInfoConfirmed(): void {
    this.isTripInfoConfirmed = true
    this.$emit('refresh')
  }

  markNextStepsConfirmed(): void {
    this.isNextStepsConfirmed = true
    this.$emit('input', false)
  }

  skipPhone(): void {
    this.showConfirmPhone = false
  }

  markPhoneVerified(): void {
    this.showConfirmPhone = false
  }
}
</script>
