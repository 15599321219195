<template>
  <div>
    <v-tooltip right color="gray">
      <template #activator="{ on, attrs }">
        <v-icon
          id="infoIcon"
          size="18"
          color="grey"
          class="cursor-pointer margin-r-2 margin-b-0 margin-l-2"
          :style="{ 'margin-top': '-2px' }"
          v-bind="attrs"
          v-on="on"
        >
          info
        </v-icon>
      </template>
      <div class="w-220">
        <span class="text-white">{{tooltipText}}</span>
      </div>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ResellerTooltip extends Vue {

  @Prop({ type: String, required: true }) readonly dba: string

  get tooltipText(): string {
    return `${this.dba} is a nationwide network of bus companies that independently operate under the ${this.dba} brand.`
  }
}
</script>
