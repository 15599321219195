<template>
  <CUModal
    id="sales-will-call-dialog"
    :value="value"
    max-width="586px"
    width="586px"
    @input="$emit('input', $event)"
  >
    <template #title>{{ `${' '}` }}</template>
    <template #text>
      <div class="d-flex flex-column text-center margin-x-auto">
        <div class="d-flex flex-column text-center margin-x-auto padding-b-4">
          <img :src="largeEventIcon" class="min-h-60 max-h-60 margin-b-4" />
          <h2>
            Thank you for using CharterUP
            <br v-if="$vuetify.breakpoint.smAndUp" />
            to plan your large event!
          </h2>
          <p>
            Your booking is our priority
          </p>
        </div>
        <v-divider />
        <div class="d-flex flex-column align-start padding-x-4 padding-t-2">
          <p class="padding-y-2">
            Here's what's next:
          </p>
          <div
            class="d-flex align-start"
            :class="{
              'padding-y-4': $vuetify.breakpoint.smAndDown,
              'padding-y-2': $vuetify.breakpoint.mdAndUp,
            }"
          >
            <img
              :src="greenCheckCircle"
              class="min-h-10 max-h-20 margin-t-2 margin-r-2"
            />
            <p class="text-left">
              Our Large Events team has been alerted to secure your
              {{ busCount }} buses.
            </p>
          </div>
          <div
            class="d-flex align-start"
            :class="{
              'padding-y-4': $vuetify.breakpoint.smAndDown,
              'padding-y-2': $vuetify.breakpoint.mdAndUp,
            }"
          >
            <img
              :src="checkCircle"
              class="min-h-10 max-h-20 margin-t-2 margin-r-2"
            />
            <p class="text-left">
              <strong>We'll call you{{ mainActionText }}</strong>
              at {{ customerPhoneComputed }} to discuss any special trip
              requirements and email your detailed quote.
            </p>
          </div>
        </div>
        <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
        <v-btn color="primary" class="w-full margin-t-6" @click="close">
          Ok, got it!
        </v-btn>
      </div>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'
import InfoDialogLayout from '@/layouts/InfoDialogLayout.vue'
import largeEventIcon from '@/assets/images/selfServe/blueLargeEvent.svg'
import greenCheckCircle from '@/assets/images/selfServe/greenCheckCircle.svg'
import checkCircle from '@/assets/images/selfServe/checkCircle.svg'
import dayjs from 'dayjs'
import { phoneFormatFilterHyphens } from '@/utils/phone'

@Component({ components: { InfoDialogLayout } })
export default class SalesWillCallDialog extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean
  @Prop({ type: Number, required: true }) readonly busCount: number
  @Prop({ type: String, required: true }) readonly createdOn: string
  @Prop({ type: String, required: true }) readonly customerPhoneNumber: string

  largeEventIcon = largeEventIcon
  greenCheckCircle = greenCheckCircle
  checkCircle = checkCircle

  get mainActionText() {
    if (this.isOutsideOfCharterUPESTBusinessHours) {
      return ' during business hours'
    }

    if (!this.quoteCreatedMoreThan10MinutesAgo) {
      return ' within 10 minutes'
    }
    return ' shortly'
  }

  get customerPhoneComputed() {
    return phoneFormatFilterHyphens(this.customerPhoneNumber)
  }

  get nowUTC() {
    return dayjs.utc()
  }

  get nowEST() {
    return this.nowUTC.tz('America/New_York')
  }

  get isOutsideOfCharterUPESTBusinessHours() {
    const businessStart = dayjs()
      .tz('America/New_York')
      .hour(8)
      .minute(0)
      .second(0)
      .millisecond(0)
    const businessEnd = dayjs()
      .tz('America/New_York')
      .hour(23)
      .minute(0)
      .second(0)
      .millisecond(0)

    return (
      this.nowEST.isBefore(businessStart) || this.nowEST.isAfter(businessEnd)
    )
  }

  get quoteCreatedMoreThan10MinutesAgo() {
    const tenMinutesAgo = this.nowUTC.subtract(10, 'minute')
    const createdOnTime = dayjs.utc(this.createdOn)
    return createdOnTime.isBefore(tenMinutesAgo)
  }

  close(): void {
    this.$emit('input', false)
    this.$emit('close')
  }
}
</script>
