<template>
  <v-form ref="edit-customer-form">
    <v-row dense>
      <v-col cols="12">
        <label>
          {{ $t('editCustomerForm.form.email.LABEL') }}
        </label>
        <v-text-field
          id="invite-customer-email-text-field"
          v-model="existingCustomer.email"
          disabled
          outlined
        />
      </v-col>
      <v-col cols="6">
        <label>
          {{ $t('editCustomerForm.form.firstName.LABEL') }}
        </label>
        <v-text-field
          id="edit-customer-first-name-text-field"
          v-model="customer.firstName"
          :placeholder="$t('editCustomerForm.form.firstName.PLACEHOLDER')"
          :rules="[
            (v) => !!v || $t('editCustomerForm.form.firstName.rules.REQUIRED'),
          ]"
          hide-details="auto"
          required
          validate-on-blur
          outlined
        />
      </v-col>
      <v-col cols="6">
        <label>
          {{ $t('editCustomerForm.form.lastName.LABEL') }}
        </label>
        <v-text-field
          id="invite-customer-last-name-text-field"
          v-model="customer.lastName"
          :placeholder="$t('editCustomerForm.form.lastName.PLACEHOLDER')"
          :rules="[
            (v) => !!v || $t('editCustomerForm.form.lastName.rules.REQUIRED'),
          ]"
          hide-details="auto"
          required
          validate-on-blur
          outlined
        />
      </v-col>
      <v-col cols="12">
        <label>
          {{ $t('editCustomerForm.form.phoneNumber.LABEL') }}
        </label>
        <v-text-field
          id="invite-customer-phone-text-field"
          disabled
          v-model="existingCustomer.phone"
          v-mask="['(###) ###-####', '+## ## #### ####']"
          outlined
        />
      </v-col>
      <v-col cols="12">
        <label>
          {{ $t('editCustomerForm.form.title.LABEL') }}
        </label>
        <v-text-field
          id="invite-customer-title-text-field"
          v-model="customer.title"
          :placeholder="$t('editCustomerForm.form.title.PLACEHOLDER')"
          hide-details="auto"
          validate-on-blur
          outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-btn
          id="edit-customer-cancel"
          color="secondary"
          class="w-full"
          @click="cancel"
        >
          {{ $t('editCustomerForm.form.CANCEL') }}
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          id="edit-customer-save"
          color="primary"
          class="w-full"
          :loading="submitting"
          @click="submit"
        >
          {{ $t('editCustomerForm.form.SAVE') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import { EditCustomerPayload, TableViewCustomer } from '@/models/dto'
import deepClone from '@/utils/deepClone'
import { validateEmailAddress } from '@/utils/string'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Customer from '@/services/customer'
import alert from '@/store/modules/alert'

@Component({})
export default class OrganizationTeamEditUser extends Vue {
  @Prop({ type: Object, required: true })
  readonly existingCustomer: TableViewCustomer

  submitting = false

  @Watch('existingCustomer', { immediate: true })
  existingCustomerChanged(value: TableViewCustomer): void {
    this.customer = {
      firstName: value.firstName,
      lastName: value.lastName,
      title: value.title,
    }
  }

  customer: EditCustomerPayload = {
    firstName: '',
    lastName: '',
    title: '',
  }

  cancel(): void {
    this.$emit('close')
  }

  async submit(): Promise<void> {
    const form = this.$refs['edit-customer-form'] as any
    if (!form.validate()) {
      this.$emit('invalid')
      return
    }
    const payload = deepClone(this.customer)
    this.submitting = true

    try {
      await Customer.editCustomer(this.existingCustomer.customerId, payload)
      this.$emit('close')
    } catch (e) {
      alert.add({
        text:
          'Error editing user. Please try again later. If the problem persists, contact support.',
        color: 'error',
        title: 'Error',
        dismissible: true,
      })

      this.$emit('close')
    }

    this.submitting = false
  }
}
</script>
