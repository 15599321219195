<template>
  <div class="d-flex flex-column align-center">
    <CUItinerary class="max-w-600 w-full">
      <CUItineraryItem
        v-for="(stop, stopIndex) in stops"
        :key="`stop-itinerary-item-${stop.stopId}-${stopIndex}`"
        class="w-full"
        upcoming
        :last-stop="isLastStop(stopIndex)"
      >
        <SalesBotTripItineraryStop
          :key="`stop-itinerary-item-${stop.orderIndex}-${stopIndex}`"
          :index="stopIndex"
          :stop="stop"
          :error-message="getErrorMessage(stopIndex)"
          :current-trip="currentTrip"
          :previous-stop="stopIndex !== 0 ? stops[stopIndex - 1] : null"
          :hide-delete="stops.length <= 2 || isLastStopOfRoundTrip(stopIndex)"
          :hide-add="isLastStopOfRoundTrip(stopIndex)"
        />
      </CUItineraryItem>
    </CUItinerary>
    <v-btn
      color="primary"
      class="w-full margin-t-4"
      :class="{
        'max-w-320': $vuetify.breakpoint.smAndUp,
      }"
      :disabled="disabled"
      :loading="submitting"
      @click="handleContinue"
    >
      {{ buttonText }}
      <CUIcon view-box="0 0 24 24" class="margin-l-2">
        arrow_right
      </CUIcon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { SalesBotTrip, SalesBotStop } from '@/models/dto'
import selfServe from '@/store/modules/selfServe'
import { Vue, Component, Watch } from 'vue-property-decorator'
import CUItinerary from '@/components/CUItinerary.vue'
import CUItineraryItem from '@/components/CUItineraryItem.vue'
import SalesBotTripItineraryStop from '@/components/SalesBotTripItineraryStop.vue'
import { TripTypeId } from '@/utils/enum'
import { DateTime, SystemZone } from 'luxon'
import auth from '@/store/modules/auth'
import { validateAddressStateAndCountry } from '@/utils/validators'

@Component({
  components: { CUItinerary, CUItineraryItem, SalesBotTripItineraryStop },
})
export default class SelfServeTripItinerary extends Vue {
  @Watch('stopCount')
  stopCountChanged() {
    selfServe.getTripEstimations()
  }

  submitting = false
  get currentTrip(): SalesBotTrip {
    return selfServe.quote?.trips?.[selfServe.currentTripIndex] || null
  }

  get stopCount(): number {
    return this.currentTrip?.stops?.length || 0
  }

  get isRoundTrip(): boolean {
    if (!this.currentTrip) {
      return false
    }
    return this.currentTrip?.tripTypeId === TripTypeId.RoundTrip
  }

  get stops(): SalesBotStop[] {
    return this.currentTrip?.stops || []
  }

  get invalidStopMap(): Record<number, string> {
    const invalidStopMap: Record<number, string> = {}
    let greatestDateTime

    for (let stopIndex = 0; stopIndex < this.stops.length - 1; stopIndex++) {
      const stop = this.stops[stopIndex]
      const pickupDatetime = DateTime.fromISO(stop?.pickupDatetime)
      if (stop.orderIndex === 0) {
        const minPickupDatetime = DateTime.local({ zone: stop?.address?.timeZone || new SystemZone() })
        if (pickupDatetime <= minPickupDatetime) {
          invalidStopMap[stopIndex] = 'Your trip must start in the future'
        }
        greatestDateTime = pickupDatetime
      } else if (pickupDatetime < greatestDateTime) {
        invalidStopMap[stopIndex] = 'Pickup must be after prior stops'
      } else if (
        stop.estimatedDropoffDatetime &&
        pickupDatetime < DateTime.fromISO(stop.estimatedDropoffDatetime) &&
        stop?.address?.timeZone
      ) {
        const arrivalDatetime = DateTime.fromISO(
          stop.estimatedDropoffDatetime,
          { zone: stop.address.timeZone }
        )
        const arrivalTimeFormatted = arrivalDatetime.toFormat('hh:mm a')
        const arrivalDateFormatted = arrivalDatetime.toFormat('LL/dd/yy')
        invalidStopMap[
          stopIndex
        ] = `Pickup must be after the estimated arrival time of ${arrivalTimeFormatted} on ${arrivalDateFormatted}`
      }

      greatestDateTime = DateTime.max(greatestDateTime, pickupDatetime)
    }

    return invalidStopMap
  }

  get invalidStopCount(): number {
    return Object.keys(this.invalidStopMap)?.length || 0
  }

  get stopsAreValid(): boolean {
    return !this.invalidStopCount
  }

  get disabled(): boolean {
    if (!this.stops) {
      return false
    }

    return this.stops.some(
      (stop) =>
        !this.isStopFilledOut(stop) ||
        !validateAddressStateAndCountry(stop?.address)
    )
  }

  get buttonText(): string {
    return selfServe.isEditing ? 'Update' : 'Continue'
  }

  isLastStop(stopIndex): boolean {
    return this.stops.length - 1 === stopIndex
  }

  getErrorMessage(stopIndex: number): string {
    if (!this.invalidStopCount) {
      return ''
    }
    return this.invalidStopMap?.[stopIndex] || ''
  }

  isStopFilledOut(stop: SalesBotStop): boolean {
    return (
      !!stop?.address?.lat &&
      !!stop?.address?.lng &&
      !!(stop.orderIndex === this.stops?.length - 1 || stop?.pickupDatetime)
    )
  }

  isLastStopOfRoundTrip(index: number): boolean {
    return this.isRoundTrip && index === this.stops.length - 1
  }

  handleContinue(): void {
    if (!this.stopsAreValid) {
      return
    }
    this.trackTripItinerarySubmission()
    selfServe.nextStep()
  }

  trackTripItinerarySubmission(): void {
    if (auth.customer != null) {
      this.$ga4.trackEvent('sb_itinerary', {
        isElite: !!auth.customer.isElite,
        isAuth0: this.$auth0.isInitialized,
        hasBookedBefore: auth?.customer?.convertedQuoteCount > 0,
        isLoggedIn: true,
      })
    } else {
      this.$ga4.trackEvent('sb_itinerary', {
        isElite: false,
        isAuth0: this.$auth0.isInitialized,
        hasBookedBefore: false,
        isLoggedIn: false,
      })
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  touch-action: manipulation;
}
</style>
