<template>
  <div class="d-flex flex-column justify-center">
    <v-expansion-panels accordion flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-checkbox
            :input-value="value"
            label="I’d like to receive text messages about my quotes"
            hide-details
            class="margin-t-0 padding-t-0 text-gray-text max-w-full"
            color="gray-text"
            @change="onChange"
          />
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="margin-l-9 font-14 margin-t-0 text-gray-subtle-text">
            By signing up via text,
            you agree to receive messages at the phone number provided.
            Consent is not a condition of purchase.
            Reply STOP to unsubscribe.
            Message frequency varies.
            Msg & data rates may apply. View our
            <a
              href="https://www.charterup.com/privacy-policy/"
              target="_blank"
              class="mr-n1"
            >
              Privacy Policy
            </a>
            &nbsp;and
            <a
              href="https://www.charterup.com/terms-and-conditions/"
              target="_blank"
            >
              Terms of Service
            </a>
            .
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script setup lang="ts">

defineProps({
  value: {
    type: Boolean,
    required: true
  }
})

const emit = defineEmits(['update:value'])

const onChange = (value: boolean) => {
  emit('update:value', value)
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors.scss';

::v-deep i.v-icon.mdi-checkbox-marked {
  color: $gray-subtle-text !important;
}
</style>
