<template>
  <CUModal
    :value="value"
    width="518px"
    :content-class="$vuetify.breakpoint.xs ? 'fullscreen-dialog' : ''"
    :fullscreen="$vuetify.breakpoint.xs"
    @input="$emit('input', $event)"
    @keydown.stop="
      (event) => {
        if (event.keyCode === 27) {
          $emit('input', false)
        }
      }
    "
  >
    <template #title>Mark payment as sent</template>
    <template #text>
      <PaymentSent
        :reservation-identifiers="reservationIdentifiers"
        @close="$emit('input', false)"
      />
    </template>
    <!-- <template #actions></template> -->
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import PaymentSent from '@/components/PaymentSent.vue'
import { ReservationIdentifiers } from '@/models/dto/Reservation'

@Component({
  components: {
    PaymentSent,
  },
})
export default class PaymentSentDialog extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly reservationIdentifiers: ReservationIdentifiers[]
  @Prop({ type: Boolean }) readonly value: boolean
}
</script>
