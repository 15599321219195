<template>
  <div class="d-flex justify-center">
    <v-form ref="required-customer-info-form" class="w-full">
      <v-row dense>
        <v-col v-if="singleColumn" cols="12" :class="{'order-2': singleColumn}">
          <label>Email</label>
          <v-text-field
            id="required-customer-information-email-text-field"
            ref="email-name"
            :value="user.email"
            placeholder="e.g. joseph@email.com"
            hide-details="auto"
            disabled
            required
            outlined
          />
        </v-col>

        <v-col cols="12" :sm="singleColumn ? 12 : 6" :class="{'order-0': singleColumn}">
          <label>First Name</label>
          <v-text-field
            id="required-customer-information-first-name-text-field"
            ref="first-name"
            :value="user.firstName"
            placeholder="e.g. Joseph"
            :rules="[(v) => !!v || 'First name is required']"
            hide-details="auto"
            autocomplete="given-name"
            required
            validate-on-blur
            outlined
            @input="user.setFirstName($event)"
            @blur="onBlurRequiredField"
          />
        </v-col>

        <v-col cols="12" :sm="singleColumn ? 12 : 6" :class="{'order-1': singleColumn}">
          <label>Last Name</label>
          <v-text-field
            id="required-customer-information-last-name-text-field"
            :value="user.lastName"
            placeholder="e.g. Smith"
            :rules="[(v) => !!v || 'Last name is required']"
            hide-details="auto"
            autocomplete="family-name"
            required
            validate-on-blur
            outlined
            @input="user.setLastName($event)"
            @blur="onBlurRequiredField"
          />
        </v-col>

        <v-col cols="12" :sm="singleColumn ? 12 : 6" :class="{'order-4': singleColumn}">
          <label>Phone Number</label>
          <v-text-field
            id="required-customer-information-phone-text-field"
            v-mask="['(###) ###-####', '+## ## #### ####']"
            :value="user.phone"
            placeholder="e.g. (123) 456-7890"
            :rules="[
              (v) => !!v || 'Phone number is required',
              phoneRaw.length > 9 || 'Phone number must be valid',
              phoneRaw.length <= 12 || 'Phone number must be valid',
            ]"
            hide-details="auto"
            autocomplete="tel"
            required
            validate-on-blur
            outlined
            @input="user.setPhone($event)"
            @blur="onBlurRequiredField"
          />
          <div class="d-flex justify-start margin-l-1">
            <span class="font-12 text-gray-subtle-text">
              In case we need to contact you about your bookings.
            </span>
          </div>
        </v-col>

        <v-col cols="12" :sm="singleColumn ? 12 : 6" :class="{'order-7': singleColumn}">
          <label>
            How many buses do you book in a year?
          </label>
          <v-text-field
            id="required-customer-information-bookings-text-field"
            :value="user.bookings"
            type="number"
            placeholder="e.g. 1"
            hide-details="auto"
            :rules="[
              (v) =>
                !v || Number.isInteger(Number(v)) || 'Must be a whole number',
              (v) => !v || parseInt(v) > 0 || 'Must be a positive number',
            ]"
            outlined
            step="1"
            min="1"
            max="999999"
            @input="user.setBookings($event)"
          />
          <div class="d-flex justify-start margin-l-1">
            <span class="font-12 text-gray-subtle-text">Optional</span>
          </div>
        </v-col>

        <v-col cols="12" v-if="showReservationSMSOptIn" :class="{'order-5': singleColumn}">
          <ReservationUpdateSMSOptIn :model-value="isReservationSMSEnabled" @update:modelValue="isReservationSMSEnabled = $event" />
        </v-col>

        <v-col cols="12" :class="{'order-6': singleColumn}">
          <QuoteSalesSMSOptIn :value="user.isQuoteFollowUpSMSEnabled" @update:value="user.setIsQuoteFollowUpSMSEnabled" />
        </v-col>

        <v-col cols="12" :class="{'order-8': singleColumn}">
          <p v-if="errorMessage" class="text-center text-error font-medium">
            {{ errorMessage }}
          </p>
            <slot
              name="submit-button"
              :id="'required-customer-information-submit-button'"
              :loading="isSubmitting"
              :click="submit"
              :validate="validate"
            >
              <v-btn
                id="required-customer-information-submit-button"
                class="w-full margin-t-3"
                color="primary"
                :loading="isSubmitting"
                @click="submit"
              >
                Continue
              </v-btn>
            </slot>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { phoneRaw } from '@/utils/phone'
import user from '@/store/modules/user'
import QuoteSalesSMSOptIn from '@/components/QuoteSalesSMSOptIn.vue'
import ReservationUpdateSMSOptIn from '@/components/ReservationUpdateSMSOptIn.vue'
import auth from '@/store/modules/auth'
import axios from 'axios'
import { IS_RESERVATION_INVITE_QUERY_PARAM, RECEIVE_SMS_QUERY_PARAM } from '@/utils/constants'
import customer from '@/services/customer'
import { MfaSmsPayload } from '@/models/dto/MfaSms'

@Component({ components: { QuoteSalesSMSOptIn, ReservationUpdateSMSOptIn } })
export default class CustomerRequiredDetailsForm extends Vue {
  @Prop({ type: Boolean, default: false }) readonly singleColumn!: boolean

  user = user
  isReservationSMSEnabled = false
  isSubmitting = false
  errorMessage: string | null = null

  async mounted(): Promise<void> {
    await user.prepopulateFromIdToken()
    this.isReservationSMSEnabled = this.showReservationSMSOptIn
  }

  get phoneRaw(): string {
    return user?.phone ? phoneRaw(user.phone) : ''
  }

  get showReservationSMSOptIn(): boolean {
    const isFromReservationInvite = this.$route.query?.[IS_RESERVATION_INVITE_QUERY_PARAM] === 'true'
    return !!isFromReservationInvite
  }

  onBlurRequiredField(): void {
    const areRequiredFieldsNonEmpty = !!user?.firstName && !!user?.lastName && !!user?.phone
    if (areRequiredFieldsNonEmpty) {
      this.validate()
    }
  }

  validate(): boolean {
    const form = this.$refs['required-customer-info-form'] as any
    const isValid = form.validate()
    this.$emit('form:validate', isValid)
    return isValid
  }

  async submit(): Promise<void> {
    if (!this.validate()) {
      return
    }

    this.isSubmitting = true
    this.errorMessage = null
    await this.createOrUpdateCustomer()
    if (!!this.errorMessage) {
      return
    }
    await this.login()
    if (!!this.errorMessage) {
      return
    }
    if (this.$route.name !== 'self-serve') { // this triggers a view rerender and we don't want that in self-serve until after the quote is created
      await this.refreshUserRequiredInformation()
    }
    this.$emit('update:customer')
    if (this.isReservationSMSEnabled) {
      await this.updateCustomerSMSForVerification()
      this.updateQueryParamsForReservationSMSOptIn()
    }

    this.$ga4.trackEvent('login', {
      isAuth0: true,
    })
  }

  async updateCustomerSMSForVerification(): Promise<void> {
    const customerId = auth.userId
    const phoneNumber = this.phoneRaw
    await customer.updateSMS(
      customerId,
      { phoneNumber }
    )
  }

  updateQueryParamsForReservationSMSOptIn() {
      const route = this.$route
      const query = { ...route.query }
      query[RECEIVE_SMS_QUERY_PARAM] = 'true'
      delete query[IS_RESERVATION_INVITE_QUERY_PARAM]
      const newRoute = { ...route, query }
      this.$router.replace(newRoute)
  }

  async createOrUpdateCustomer(): Promise<void> {
    try {
      if (user.isExistingCustomer) {
        await user.updateRequiredInformation()
      } else {
        await user.createWithRequiredInformation()
      }
    } catch (err: unknown) {
      this.isSubmitting = false
      if (axios.isAxiosError(err)) {
        this.errorMessage = err.response?.data?.message || 'An error occurred saving your information'
      } else {
        this.errorMessage = 'An error occurred saving your information'
      }
      console.warn(err)
    }
  }

  async login(): Promise<void> {
    try {
      await auth.jwtLogin()
    } catch (err: unknown) {
      this.errorMessage = 'An error occurred logging in. Please go back to the login page and try again.'
      console.warn(err)
    }
  }

  async refreshUserRequiredInformation(): Promise<void> {
    try {
      await user.fetchRequiredInformation()
    } catch (err: unknown) {
      console.warn(err)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors.scss';
* {
  touch-action: manipulation;
}

label {
  color: $gray-text;
}

::v-deep .v-text-field input {
  color: $gray-text !important;
  font-weight: 500 !important;
}
</style>
