<template>
  <div>
    <label>Teams</label>
    <v-card outlined border class="team-card">
      <v-checkbox
        v-for="team in possibleChildCustomerAccounts"
        :key="team.customerAccountId"
        v-model="childCustomerAccountIds"
        class="team-checkbox"
        :label="team.name"
        :value="team.customerAccountId"
        hide-details
      ></v-checkbox>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Vue, Component, VModel, Prop } from 'vue-property-decorator'
import { CustomerAccount } from '@/models/dto'

@Component({})
export default class OrganizationEditRiderChildCustomerAccounts extends Vue {
  @VModel({ type: Array, default: () => [] })
  readonly childCustomerAccountIds: Array<number>
  @Prop({ type: Array }) readonly availableCustomerAccounts: Array<
    CustomerAccount
  >

  get possibleChildCustomerAccounts(): CustomerAccount[] {
    return this.availableCustomerAccounts.filter((team) => !team.isParent)
  }
}
</script>

<style lang="scss" scoped>
.team-checkbox {
  margin-top: 3px;
}
.team-card {
  border-width: 2px !important;
}
</style>
