<template>
  <div class="w-full margin-t-2" id="sales-bot-trip-details-container">
    <v-text-field
      id="sales-bot-route-name"
      :value="routeName"
      hide-details
      outlined
      autofocus
      placeholder="Give your trip a name"
      @input="setRouteName($event)"
      class="margin-b-1"
    />
    <div class="d-flex margin-t-2">
      <v-text-field
        id="sales-bot-passenger-count"
        :value="passengerCount"
        hide-details
        outlined
        required
        type="number"
        placeholder="0"
        :min="0"
        :rules="[(v) => !!v && v >= 1]"
        class="max-w-120"
        @input="updatePassengerCount($event)"
      >
        <template slot="prepend-inner">
          <CUIcon
            color="midnight-gray-300"
            view-box="0 0 24 24"
            width="24"
            height="24"
          >
            groups
          </CUIcon>
        </template>
      </v-text-field>

      <v-select
        id="sales-bot-trip-event-type"
        :value="tripEventTypeKey"
        @change="updateTripEventTypeKey"
        placeholder="Select an event type"
        :items="tripEventTypeOptions"
        item-value="key"
        item-text="label"
        outlined
        required
        hide-details
        autocomplete="off"
        append-icon="mdi-chevron-down"
        class="margin-t-0 margin-l-3"
      />
    </div>
    <p v-if="errorMessage" class="font-medium text-error margin-b-2 margin-t-1">
      {{ errorMessage }}
    </p>

    <h3
      v-if="requirementOptions.length > 0"
      class="margin-b-2 margin-t-4"
    >
      Special Requirements -
      <span
        class="font-16" :style="{ 'font-style': 'italic' }"
      >
        Optional
      </span>
    </h3>

    <div class="d-flex flex-column padding-x-3 margin-b-2">
      <SalesBotSpecialRequirementOptionCard
        v-for="(specialRequirement, specialRequirementIndex) in requirementOptions"
        :key="`special-requirement-card-${specialRequirementIndex}`"
        :special-requirement="specialRequirement"
        :selected="isRequirementSelected(specialRequirement.key)"
        @set="setSpecialRequirement(specialRequirement.key, $event)"
        class="margin-b-3"
      />
    </div>

    <slot name="next-button" :validate="validate" />
  </div>
</template>

<script setup lang="ts">
import { useSalesBotStore } from "@/store/modules/salesBot";
import salesbot from "@/services/salesbot";
import { storeToRefs } from "pinia";
import { computed, ref, onMounted } from "vue";
import { SpecialRequirementsTypeKey } from "@/utils/enum";
import { EventType, SpecialRequirement } from "@/models/dto/SalesBotRequirements";
import SalesBotSpecialRequirementOptionCard from '@/components/SalesBotSpecialRequirementOptionCard.vue'
import CUIcon from '@/components/CUIcon.vue'

const emit = defineEmits(['form:validate'])

const { requirementOptions, tripEventTypeOptions, ada, spab, passengerCount, tripEventTypeKey, stops, routeName } = storeToRefs(useSalesBotStore())
const { setAda, setSpab, setPassengerCount, setTripEventTypeKey, setRouteName }  = useSalesBotStore()

const errorMessage = ref('')

const isRequirementSelected = (key: SpecialRequirementsTypeKey): boolean => {
  if (key == SpecialRequirementsTypeKey.ADA) {
    return ada.value
  } else if (key == SpecialRequirementsTypeKey.SPAB) {
    return spab.value
  } else {
    console.warn('Attempting to check if undefined requirement is selected')
    return false
  }
}

const setSpecialRequirement = (key: SpecialRequirementsTypeKey, value: boolean): void => {
  if (key == SpecialRequirementsTypeKey.ADA) {
    return setAda(value)
  } else if (key == SpecialRequirementsTypeKey.SPAB) {
    return setSpab(value)
  } else {
    console.warn('Attempting to provide an undefined requirement setter for special requirements')
    return
  }
}

const updatePassengerCount = (value: number): void => {
  setPassengerCount(value)
  errorMessage.value = passengerCount.value > 0 ? '' : 'Number of passengers is required'
  validate()
}

const updateTripEventTypeKey = (value: string): void => {
  setTripEventTypeKey(value)
  validate()
}

onMounted(() => {
  validate()
})

const validate = (): boolean => {
  const isValid = passengerCount.value > 0 && tripEventTypeKey.value != null
  emit('form:validate', isValid)
  return isValid
}

</script>

<style lang="scss" scoped>
@import '@/scss/colors';

::v-deep .v-text-field {
  .v-input__control {
    .v-input__slot {
      .v-input__prepend-inner {
        z-index: 1;
        margin-top: 14px;
        margin-left: 12px;
        .cu-icon {
          color: $midnight-gray-300 !important;
        }
      }
    }
  }

  * {
    touch-action: manipulation;
  }
}

.v-list-item {
  cursor: pointer;
  background: $white;
  &:hover {
    .v-list-item__title {
      color: $white;
    }
    background-color: $primary;
  }
}

::v-deep .v-input__append-inner {
  margin-top: 14px !important;
  margin-right: 8px !important;
}
</style>
