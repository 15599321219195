<template>
  <div class="d-flex flex-column justify-center">
    <v-checkbox
      id="receiveSMSCheckbox"
      :input-value="modelValue"
      :label="$t('signup.FORM.SMS.LABEL')"
      class="mt-0 pt-0"
      color="gray-text"
      hide-details
      @change="onChange"
    />
    <v-expand-transition>
      <p
        v-if="modelValue"
        class="margin-l-9 font-14 margin-t-0 text-gray-subtle-text"
      >
        {{ $t('signup.FORM.SMS.LEGALESE') }}
      </p>
    </v-expand-transition>
  </div>
</template>

<script setup lang="ts">
import { useEmit } from 'vue'

defineProps({
  modelValue: {
    type: Boolean as () => boolean,
    required: true,
  },
})

/**
 * Explicitly declares the events this component can emit.
 * In Vue 2, `defineEmits` is not enforced but is recommended for documentation,
 * type safety, and tooling support. However, in Vue 3, `defineEmits` is enforced,
 * and emitting undeclared events will throw an error.
 */
const emit = defineEmits(['update:modelValue'])

const onChange = (value: boolean) => {
  emit('update:modelValue', value)
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';

::v-deep i.v-icon.mdi-checkbox-marked {
  color: $gray-subtle-text !important;
}
</style>
