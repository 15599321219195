<template>
  <SkeletonBox v-if="loading" :style="{ width: width }" />
  <div
    v-else
    class="background-blue-pale position-relative"
    :style="{ width: width }"
  >
    <VehicleTypeIcon
      v-for="(vehicleType, vehicleTypeIndex) in vehiclesToDisplay"
      :key="`vehicle-type-icon-${vehicleType}-${vehicleTypeIndex}`"
      :vehicle-type-label="vehicleType"
      :width="vehicleWidth"
      :height="vehicleHeight"
      class="position-absolute"
      :style="computedStyles(vehicleTypeIndex)"
    />
  </div>
</template>

<script lang="ts">
import { RequiredVehicleType } from '@/models/dto'
import { Vue, Component, Prop } from 'vue-property-decorator'
import VehicleTypeIcon from '@/components/VehicleTypeIcon.vue'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({
  components: {
    VehicleTypeIcon,
    SkeletonBox,
  },
})
export default class ReservationCardVehicleImage extends Vue {
  @Prop({ type: [Number, String], required: true }) readonly width:
    | number
    | string
  @Prop({ type: Array, required: true })
  readonly requiredVehicles: RequiredVehicleType[]
  @Prop({ type: Boolean }) readonly loading: boolean

  get vehiclesToDisplay(): string[] {
    const firstVehicleTypeLabel = this.requiredVehicles?.[0]?.vehicleType?.label
    const secondVehicleTypeLabel = this.requiredVehicles?.[1]?.vehicleType
      ?.label

    const types = []
    if (!firstVehicleTypeLabel) {
      return types
    }

    types.push(firstVehicleTypeLabel)

    if (secondVehicleTypeLabel) {
      types.push(secondVehicleTypeLabel)
      return types
    }

    if (this.requiredVehicles[0].quantity > 1) {
      types.push(firstVehicleTypeLabel)
    }

    return types
  }

  get displayCount(): number {
    return this.vehiclesToDisplay?.length || 0
  }

  get vehicleWidth(): string {
    return this.displayCount === 1 ? '75%' : '60%'
  }

  get vehicleHeight(): string {
    return this.displayCount === 1 ? '75%' : '60%'
  }

  computedStyles(index: number): Record<string, string | number> {
    const xDirection = index === 0 ? -1 : 1
    const yDirection = -1 * xDirection
    const xOffset = this.displayCount === 1 ? 0 : 17
    const yOffset = this.displayCount === 1 ? 0 : 12
    const styles = {
      left: `${50 + xOffset * xDirection}%`,
      top: `${50 + yOffset * yDirection}%`,
      transform: 'translate(-50%, -50%)',
      'z-index': 5 - index,
    }
    return styles
  }
}
</script>
