<template>
  <div
    class="w-full h-full border-radius-2x border-solid cursor-pointer"
    :class="{
      'border-2 border-solid border-primary': selected,
      'border-1 border-solid border-border-gray': !selected
    }"
    @click="$emit('set', !selected)"
  >
    <div
      class="padding-x-3 padding-b-3 padding-t-2 d-flex flex-column w-full h-full"
      :class="{
        'border-solid border-transparent border-1': !selected,
      }"
    >
      <div class="d-flex">
        <v-checkbox
          class="margin-t-0"
          :input-value="selected"
        />
        <div class="d-flex flex-column w-full">
          <p class="font-medium margin-t-1">{{ specialRequirement.label }}</p>
          <p class="text-gray-light margin-t-1 font-14">
            {{ specialRequirement.description }}
          </p>
        </div>
        <div class="d-flex justify-end">
          <CUIcon
            class="margin-t-1"
            view-box="0 0 24 24"
            width="20"
            height="20"
            :color="selected ? 'primary' : 'midnight-gray-300'"
          >
            {{ getRequirementIconByKey(specialRequirement.key) }}
          </CUIcon>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, defineEmits } from 'vue'
import { currencyFilter } from '@/utils/string'
import { getAmenityIconByKey } from '@/utils/amenity'
import { SpecialRequirement } from "@/models/dto/SalesBotRequirements"
import { SpecialRequirementsTypeKey } from '@/utils/enum';
import { getRequirementIconByKey } from '@/utils/icon';

const props = defineProps<{
  specialRequirement: SpecialRequirement,
  selected: boolean,
}>()
const emit = defineEmits(['toggle'])
const toggle = () => emit('toggle')
</script>
