<template>
  <div v-if="!routes" class="margin-y-10 d-flex flex-column align-self-center">
    <div class="align-self-center">No data available.</div>
    <div class="align-self-center">
      Route usage will appear here once riders start taking trips.
    </div>
  </div>
  <div
    v-else
    class="margin-y-4 align-self-center"
    :class="$vuetify.breakpoint.smAndUp ? 'w-600' : 'w-300'"
  >
    <CUBarChart
      :chart-data="formattedChartData"
      :chart-options="{
        responsive: true,
        aspectRatio: '1/3',
        plugins: {
          legend: {
            display: true,
          },
          datalabels: {
            display: false,
          },
        },
        scales: {
          yAxes: {
            stacked: true,
            min: minTickMark,
            max: maxTickMark,
            ticks: {
              display: true,
              maxTicksLimit: 6,
              beginAtZero: false,
            },
          },
          xAxes: {
            stacked: true,
          },
        },
      }"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUBarChart from '@/components/CUBarChart.vue'

@Component({
  components: { CUBarChart },
})
export default class OrganizationRiderDataChart extends Vue {
  @Prop({ type: Array, required: true }) readonly routes: any[]
  @Prop({ type: Number, required: true }) readonly itemsLength: number

  get formattedChartData(): {
    labels: string[]
    datasets: {
      data: number[]
      label?: string
      backgroundColor?: string
      borderColor?: string
      borderWidth?: number
    }[]
  } {
    if (
      this.routes.every(
        (data) =>
          !data.releasedCount && !data.scheduledCount && !data.boardedCount
      )
    ) {
      return {
        labels: this.routes.map((data) => data.routeName),
        datasets: [
          {
            label: 'Scanned',
            backgroundColor: '#00dd9c', // green
            data: this.routes.map((data) => data.count),
          },
        ],
      }
    }
    return {
      labels: this.routes.map((data) => data.routeName),
      datasets: [
        {
          label: 'Released',
          backgroundColor: '#ff3c5080', // red
          data: this.routes.map((data) => -1 * data.releasedCount),
        },
        {
          label: 'Scheduled',
          backgroundColor: '#f7b100', // yellow
          data: this.routes.map((data) => data.scheduledCount),
        },
        {
          label: 'Boarded',
          backgroundColor: '#00dd9c', // green
          data: this.routes.map((data) => data.boardedCount),
        },
        {
          label: 'Remaining Capacity', // blue
          borderColor: '#00a6f2',
          backgroundColor: '#00a6f230',
          data: this.routes.map(
            (data) =>
              data.passengerCapacity - (data.boardedCount + data.scheduledCount)
          ),
        },
      ],
    }
  }

  get maxTickMark(): number {
    const maxPassengerCapacity = this.routes.reduce((max, route) => {
      if (
        !route.passengerCapacity &&
        !route.boardedCount &&
        !route.scheduledCount
      ) {
        return Math.max(max, route.count)
      }
      return Math.max(
        max,
        route.passengerCapacity,
        route.boardedCount + route.scheduledCount
      )
    }, 0)

    return Math.ceil(maxPassengerCapacity / 10) * 10
  }

  get minTickMark(): number {
    const maxReleasedCount = this.routes.reduce((max, route) => {
      return Math.max(max, route.releasedCount)
    }, 0)

    return -Math.ceil(maxReleasedCount / 10) * 10
  }
}
</script>
