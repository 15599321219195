<template>
  <v-btn
    small
    outlined
    color="primary"
    class="border-radius-8 margin-r-2 padding-a-2 h-32 max-h-32"
    :loading="loading"
    @click="downloadQuoteInvoice"
  >
    <span class="d-flex align-center">
      <CUIcon view-box="0 -960 960 960" class="w-18 h-18 margin-r-1">
        download
      </CUIcon>
      {{ $t('checkout.DOWNLOAD_QUOTE') }}
    </span>
  </v-btn>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { createDownloadUri, triggerFileDownload } from '@/utils/download'
import alert from '@/store/modules/alert'
import quotes from '@/services/quotes'

@Component({})
export default class DownloadQuoteButton extends Vue {

  @Prop({ type: Number, required: true }) readonly quoteId: number
  @Prop({ type: String, required: true }) readonly quoteHash: string
  @Prop({ type: Number, required: true }) readonly companyId: number

  loading: boolean = false

  async downloadQuoteInvoice(): Promise<void> {
    /*
      Since the PDF service does not currently have an endpoint to return quote invoices
      with content-type 'application/pdf' (currently only supports JSON), we cannot simply
      include an a-tag with the URL as it's download property. Instead, we need to manually
      parse the base-64 encoded string returned from the endpoint, convert it to a blob,
      and trigger the file download by creating and re-clicking an invisible a-tag.

      This implementation is definitely quite wacky and non-ideal. However, since we're
      actively searching for a replacement to the PDF service, we want to avoid adding more
      to it to support this use case. We'll have to live with the weird download implementation
      for now and replace this when we've found another option.
    */

    this.loading = true
    try {
      // Fetch the Base64 encoded PDF
      const response = await quotes.quoteInvoicePDFByHashAndCompanyId(this.quoteHash, this.companyId)

      if (!response?.data) {
        console.warn('Failed to download quote because of failed network call.')
        alert.add({
          text: '',
          color: 'red',
          title: 'Failed to download quote',
          dismissible: true,
        })
        return;
      }

      const blobUrl = createDownloadUri(response.data, 'application/pdf');
      triggerFileDownload(blobUrl, `charterup-quote-${this.quoteId}-${this.companyId}.pdf`);

    } catch (error) {
      console.warn('Failed to download quote because of an error.', error)
      alert.add({
        text: '',
        color: 'red',
        title: 'Failed to download quote',
        dismissible: true,
      })
    }
    this.loading = false
  }
}
</script>
