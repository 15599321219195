<template>
  <v-timeline-item
    class="enterprise-public-itinerary-timeline-item"
    :style="cssVariables"
  >
    <EnterpriseTrackingSidebarItineraryCard
      :location="location"
      :next-stop-time="nextStopTime"
      :total-pickup-and-dropoff-count="totalPickupAndDropoffCount"
    />
  </v-timeline-item>
</template>

<script lang="ts">
import EnterpriseTrackingSidebarItineraryCard from '@/components/EnterpriseTrackingSidebarItineraryCard.vue'
import pinNumbers from '@/utils/pinNumbers.js'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    EnterpriseTrackingSidebarItineraryCard,
  },
})
export default class EnterpriseTrackingSidebarItineraryItem extends Vue {
  @Prop({ type: Object, required: true }) readonly location: any
  @Prop({ type: String, default: null }) readonly nextStopTime: string
  @Prop({ type: Number, required: true }) readonly index: number
  @Prop({ type: Number, required: true })
  readonly totalPickupAndDropoffCount: number

  get cssVariables() {
    return {
      '--stop-icon': `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 42 51' style='enable-background:new 0 0 42 51;' xml:space='preserve'><path fill='${this.location.color.replace(
        '#',
        '%23'
      )}' d='M21,0.2c-11.6,0-21,9.4-21,21C0,27.4,2.7,33,7.1,36.9L21,50.8l13.9-13.9C39.3,33,42,27.4,42,21.2 C42,9.6,32.6,0.2,21,0.2z'/><path fill='black' fill-opacity='0.35' d='${
        this.numberPath
      }' /></svg>")`,
    }
  }
  get numberPath(): string {
    return pinNumbers[this.index + 1]
  }
}
</script>

<style lang="scss" scoped>
$stop-icon: var(--stop-icon);

.v-timeline-item.enterprise-public-itinerary-timeline-item {
  ::v-deep .v-timeline-item__dot {
    height: 32px;
    width: 32px;
    margin-right: -6px;
    background-color: transparent;
    background-repeat: repeat-y;
    background-image: $stop-icon !important;
    .v-timeline-item__inner-dot {
      opacity: 0 !important;
      height: 24px;
      width: 24px;
    }
  }
}
::v-deep .v-timeline-item__divider {
  padding-right: 12px;
}

@media screen and (min-width: 600px) {
  .v-timeline-item.enterprise-public-itinerary-timeline-item {
    ::v-deep .v-timeline-item__dot {
      margin-top: -6px;
    }
  }
}
</style>
