<template>
  <NotFoundLayout
    :img="require('@/assets/images/no-quotes-graphic.png')"
    class="margin-t-8"
  >
    <template #header>
      No quotes here
    </template>
    <template #content>
      Book your trip now. Agents are available 24/7 to assist you.
    </template>
    <template #button>
      <div class="d-flex align-center text-white">
        <CUIcon
          color="white"
          width="18px"
          view-box="0 0 24 24"
          class="margin-r-2"
        >
          phone
        </CUIcon>
        1-855-920-2287
      </div>
    </template>
  </NotFoundLayout>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import NotFoundLayout from '@/layouts/NotFoundLayout.vue'
@Component({ components: { NotFoundLayout } })
export default class QuoteIndexNotFound extends Vue {}
</script>
