<template>
  <div class="d-flex flex-column justify-center margin-t-1">
    <v-checkbox
      :input-value="isQuoteSalesSMSEnabled"
      label="I’d like to receive text messages about my quote"
      hide-details
      class="margin-t-0 padding-t-0 text-gray-text"
      color="gray-text"
      @change="selfServe.setQuoteSalesSMSEnabled($event)"
    />
    <div>
      <p class="margin-l-9 font-14 margin-t-0 text-gray-subtle-text">
      By signing up via text,
      you agree to receive messages at the phone number provided.
      Consent is not a condition of purchase.
      Reply STOP to unsubscribe.
      Message frequency varies.
      Msg & data rates may apply. View our
      <a
        href="https://www.charterup.com/privacy-policy/"
        target="_blank"
        class="mr-n1"
      >
        Privacy Policy
      </a>
      &nbsp;and
      <a
        href="https://www.charterup.com/terms-and-conditions/"
        target="_blank"
      >
        Terms of Service
      </a>
      .
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import selfServe from '@/store/modules/selfServe'

@Component({})
export default class SelfServeQuoteSalesSMSOptIn extends Vue {
  selfServe = selfServe
  get isQuoteSalesSMSEnabled(): boolean {
    return selfServe.quote?.customer?.isQuoteSalesSMSEnabled
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors.scss';
::v-deep i.v-icon.mdi-checkbox-marked {
  color: $gray-subtle-text !important;
}
</style>
