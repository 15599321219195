<template>
  <CUModal :value="value" max-width="518px" @input="$emit('input', false)">
    <template #title>Rider QR Code</template>
    <template #text>
      <div class="d-flex w-inherit justify-center">
        <div
          ref="qrCodeContent"
          class="d-flex flex-column justify-center padding-a-4"
        >
          <div class="d-flex justify-center">
            <qrcode-vue :value="qrCodeJSON" size="300" />
          </div>
          <div class="display-flex justify-center font-18">
            <p>
              {{ riderDisplayName }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <div class="d-flex grow" />
      <v-btn small text class="margin-r-2" @click="close">
        Close
      </v-btn>
      <v-btn small text class="primary" @click="downloadQRCode">
        Download
      </v-btn>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import QrcodeVue from 'qrcode.vue'
import { TableViewRider } from '@/models/dto/TableViewRider'
import html2canvas from 'html2canvas'
import rider from '@/services/rider'

@Component({ components: { QrcodeVue } })
export default class ImportRiderModal extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean
  @Prop({ type: Object }) readonly rider: TableViewRider

  riderTicketHash = ''

  get qrCodeJSON(): string {
    return JSON.stringify({ riderTicketHash: this.riderTicketHash })
  }

  get riderDisplayName(): string {
    if (this.rider.firstName && this.rider.lastName) {
      return `${this.rider.firstName} ${this.rider.lastName}`
    }
    return this.rider.employeeId
  }

  @Watch('rider', { deep: true })
  async riderChanged(): Promise<void> {
    if (this.rider?.riderId) {
      this.riderTicketHash = (
        await rider.getRiderTicketHash(this.rider.riderId)
      ).data.riderTicketHash.hash
    }
  }

  async downloadQRCode(): Promise<void> {
    const el: any = this.$refs.qrCodeContent

    const options: any = {
      type: 'dataURL',
    }
    const printCanvas = await html2canvas(el, options)

    const link = document.createElement('a')
    link.setAttribute('download', 'RiderQRCode.png')
    link.setAttribute(
      'href',
      printCanvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream')
    )
    link.click()
  }

  close(): void {
    this.$emit('input', false)
  }
}
</script>
