var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"d-flex align-center font-20 font-bold margin-b-2"},[_c('CUIcon',{staticClass:"w-24 h-24 margin-r-1",attrs:{"view-box":"0 0 24 24","color":"primary"}},[_vm._v("charterup_choice")]),_c('span',{staticClass:"margin-r-1"},[_vm._v("CharterUP's Choice")]),_c('InfoModal',{attrs:{"modal-detail":_vm.charterupChoiceModalDetail,"max-width":"860px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({staticClass:"font-12 margin-t-1 margin-x-1"},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('common.SEE_WHY'))+" ")])]}},{key:"text",fn:function(){return [_c('div',{staticClass:"margin-b-n2"},[_c('p',{staticClass:"margin-t-2"},[_vm._v(" We recommend "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.operatorName))]),_vm._v(" for your trip as the best value for money based on: ")]),_c('ul',{staticClass:"margin-t-2 margin-l-2"},[_c('li',[_vm._v("• Competitive price")]),_c('li',[_vm._v("• Punctuality of the operator")]),_c('li',[_vm._v("• Newness of the fleet")]),_c('li',[_vm._v("• Customer satisfaction ratings")])]),_c('p',{staticClass:"margin-t-2"},[_vm._v(" Operators cannot pay to be CharterUP’s Choice. ")])])]},proxy:true}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}})],1),_c('BidCard',{attrs:{"id":"provider-bidcard-charterups-choice","preferred":"","go-to":{
      name: 'provider-detail',
      params: {
        quoteId: _vm.quoteId,
        providerId: _vm.bid.companyId,
      },
    },"bid":_vm.bid,"is-elite":_vm.isElite,"show-usd":_vm.isPickupInCanada,"show-amenities":true,"charter-up-choice-styling":"","is-branded-quote-flow-enabled":_vm.isBrandedQuoteFlowEnabled}}),_c('v-divider',{staticClass:"margin-t-9 margin-b-9"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }