<template>
  <div class="omnibus d-flex flex-column">
    <div
      v-for="(charge, chargeIndex) of charges"
      :key="`charge-${charge.amount}-${chargeIndex}`"
      class="d-flex align-top margin-b-1"
    >
      <div
        class="d-flex flex-wrap align-center"
        :class="{ 'margin-b-2': chargeCount <= 2 }"
      >
        <p
          class="margin-t-0 white-space-nowrap margin-r-3"
          :class="{ 'text-cup-500': charge.amount < 0 }"
        >
          {{ charge.label }}
        </p>
        <p
          v-if="charge.notes"
          class="font-12 text-midnight-gray-300 margin-r-3"
          :style="{ 'margin-top': '2px' }"
        >
          {{ charge.notes }}
        </p>
      </div>
      <div class="d-flex grow" />
      <p class="margin-t-0" :class="{ 'text-cup-500': charge.amount < 0 }">
        {{ currencyFilter(charge.amount) }}
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import { TranslateResult } from 'vue-i18n'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { CheckoutDetailResult } from '@/models/dto'
import { ChargeTypeId } from '@/utils/enum'
import { currencyFilter } from '@/utils/string'

@Component({})
export default class CheckoutQuoteSummaryCharges extends Vue {
  @Prop({ type: Object, required: true })
  readonly checkoutDetail!: CheckoutDetailResult

  currencyFilter = currencyFilter

  get charges(): { label: TranslateResult; amount: number; notes?: string }[] {
    const charges: {
      label: TranslateResult
      amount: number
      notes?: string
    }[] = [
      {
        label: this.$t('quoteDetail.billingSummaryV2.BASE_FARE'),
        notes: this.formattedVehiclesList,
        amount: this.baseFare,
      },
    ]
    if (this.processingFeeAmount) {
      charges.push({
        label: this.$t('quoteDetail.billingSummaryV2.PROCESSING_FEE'),
        amount: this.processingFeeAmount,
      })
    }
    if (this.totalAmenityAmount) {
      charges.push({
        label: this.$t('quoteDetail.billingSummaryV2.AMENITIES'),
        amount: this.totalAmenityAmount,
      })
    }
    if (this.discountAmount) {
      charges.push({
        label: `${this.$t('quoteDetail.billingSummaryV2.DISCOUNT')} ${
          this.formattedDiscountPercent
        }`,
        amount: this.discountAmount,
      })
    }
    return charges.filter((charge) => charge.amount !== 0)
  }

  get baseFare(): number {
    return this.checkoutDetail.charges.find(
      ({ chargeType }) => chargeType.id === ChargeTypeId.BaseFare
    )?.amount
  }

  get processingFeeAmount(): number {
    return this.checkoutDetail.charges.find(
      ({ chargeType }) => chargeType.id === ChargeTypeId.ProcessingFee
    )?.amount
  }

  get discountAmount(): number {
    return this.checkoutDetail.charges.find(
      ({ chargeType }) => chargeType.id === ChargeTypeId.Discount
    )?.amount
  }

  get totalAmenityAmount(): number {
    return this.checkoutDetail.charges.find(
      ({ chargeType }) => chargeType.id === ChargeTypeId.Amenities
    )?.amount
  }

  get formattedVehiclesList(): string {
    return this.checkoutDetail.quote.trips[0]?.vehicles
      ?.map(({ quantity, vehicleType }) => `${quantity}x ${vehicleType?.label}`)
      .join(', ')
  }

  get formattedDiscountPercent(): string {
    return this.checkoutDetail.discountPercent
      ? `(${Math.abs(this.checkoutDetail.discountPercent)}%)`
      : ''
  }

  get chargeCount(): number {
    return this.charges.length
  }
}
</script>
