import {
  DefaultPaymentProfilePayload,
  PaymentProfilePayload,
  PaymentProfileResult,
  TableViewParameters,
  TableViewPaymentProfile,
  TableViewResult,
  TableViewFilter,
  CustomerPaymentProfile,
} from '@/models/dto'
import { baseUrl } from '@/utils/env'
import axios, { AxiosResponse } from 'axios'
import { HttpService } from './common/HttpService'
import { filter } from '@/utils/filter'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Retrieves a table view of payment profiles based on the given parameters.
   *
   * @param params - The parameters for filtering, sorting, and paginating the payment profiles.
   * @returns A promise that resolves to the payment profiles table view result.
   */
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<TableViewPaymentProfile>>> {
    const { filters = null, sorts = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/customerPaymentProfiles?${query}`
    return httpService.get(url)
  },
  /**
   * Adds a payment profile with the given payload.
   *
   * @param payload - The payload for the payment profile to add.
   * @returns A promise that resolves to the payment profile result.
   */
  add(payload: PaymentProfilePayload): Promise<AxiosResponse<PaymentProfileResult>> {
    const host = baseUrl()
    let url = `https://${host}/customerPaymentProfile/create?paymentGatewayTypeKey=finix`
    return httpService.post<PaymentProfileResult, PaymentProfilePayload>(
      url,
      payload
    )
  },
  /**
   * Deletes the payment profile with the given ID.
   *
   * @param paymentProfileId - The ID of the payment profile to delete.
   * @returns A promise that resolves to the payment profile result.
   */
  delete(
    paymentProfileId: number
  ): Promise<AxiosResponse<PaymentProfileResult>> {
    const host = baseUrl()
    const url = `https://${host}/customerPaymentProfile/${paymentProfileId}/delete`
    return httpService.delete(url)
  },
  /**
   * Sets the defualt payment profile using the customer ID and payment profile ID.
   *
   * @param payload - The customer ID and the payment profile ID to set as their default.
   * @returns A promise that resolves to the payment profile result.
   */
  setDefault(
    payload: DefaultPaymentProfilePayload
  ): Promise<AxiosResponse<PaymentProfileResult>> {
    const host = baseUrl()
    const url = `https://${host}/customerPaymentProfile/setDefault`
    return httpService.patch(url, payload)
  },
  loadSavedCustomerPaymentProfiles(
    userId: number
  ): Promise<AxiosResponse<TableViewResult<TableViewPaymentProfile>>> {
    if (!userId) {
      this.paymentProfiles = []
      return
    }

    const filters = filter()
    const parentFilter = filters.createParent('and')
    const showOnCharterUpFilter: TableViewFilter = {
      column: {
        _t_id: 'showOnCharterUpFilter_filter',
        value: 'showOnCharterUp',
        filterType: 'eq',
        text: '',
      },
      value: true,
    }
    filters.add(parentFilter, showOnCharterUpFilter)
    const params = {
      pageSize: -1,
      filters: filters.asQueryParams(),
    }
    return this.tableView(params)
  },
  getPaymentProfileUsedForCheckout(
    reservationManagedId: string
  ): Promise<AxiosResponse<CustomerPaymentProfile>> {
    const host = baseUrl()
    let url = `https://${host}/reservations/${reservationManagedId}/cardUsedAtCheckout`
    return axios.get(url);
  },
}
