<template>
  <CUSkeletonLoaderTextField v-if="skeletonLoader" :label="$attrs.label" />
  <div v-else class="cu-text-field">
    <label v-if="$attrs.label">
      {{ $attrs.label }}
      <span v-if="required" class="text-error">*</span>
    </label>
    <slot name="label" />
    <v-text-field
      :dense="dense"
      :class="{ dense: dense }"
      v-bind="$attrs"
      solo
      :label="null"
      flat
      outlined
      :tabindex="tabIndex"
      v-on="listeners"
    >
      <template #append>
        <slot name="append" />
      </template>
    </v-text-field>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUSkeletonLoaderTextField from '@/components/CUSkeletonLoaderTextField.vue'

@Component({ components: { CUSkeletonLoaderTextField } })
export default class CUTextField extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) dense!: boolean
  @Prop({ required: false, default: false, type: Boolean }) skeletonLoader: boolean
  @Prop({ type: Boolean, required: false, default: false }) readonly required: boolean
  @Prop({ type: Number, required: false, default: null }) readonly tabIndex: number
  @Prop({ type: Boolean, required: false, default: false }) readonly isFinix: boolean

  get listeners(): Record<string, unknown> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    return Object.assign({}, this.$listeners, {
      input(event: InputEvent) {
        vm.$emit('input', event)
      },
    })
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-text-field {
  &.dense {
    .v-input__control {
      > .v-input__slot {
        min-height: 40px !important;
      }
    }
  }
}
</style>
