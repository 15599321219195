<template>
  <div
    class="d-flex justify-center align-items-start align-content-start flex-wrap"
    :style="{ gap: '40px' }"
  >
    <div
      v-for="(valueProp, valuePropIndex) in valueProps"
      :key="`value-prop-${valuePropIndex}`"
      class="d-flex flex-column text-center max-w-152"
    >
      <CUIcon
        color="midnight-gray-700"
        view-box="0 0 24 24"
        class="w-40 h-40 margin-x-auto"
      >
        {{ valueProp.icon }}
      </CUIcon>
      <h4 class="margin-t-3">
        {{ valueProp.title }}
      </h4>
      <p class="text-midnight-gray-500 margin-t-3 font-14">
        {{ valueProp.description }}
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class CheckoutValuePropositions extends Vue {
  valueProps: { icon: string; title: string; description: string }[] = [
    {
      icon: 'support_agent',
      title: '24/7 Support',
      description: 'Our award winning customer support is here for you.',
    },
    {
      icon: 'share_location',
      title: 'Live tracking',
      description: 'Real-time bus tracking from the first day of your trip.',
    },
    {
      icon: 'payments',
      title: 'Unbeatable prices',
      description: 'Largest marketplace for charter buses in the U.S.',
    },
  ]
}
</script>
