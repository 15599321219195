import { Address, Stop, Trip } from '@/models/dto'
import {
  doTimestampsSpanMultipleYears,
  formatStopTimeV2,
  getMinimizedFormattedTimeBetween,
} from './datetime'
import { ItineraryStopTypeKey } from './enum'
import { formatCityStateZip, formatStreetAddress } from './address'
import { TripItineraryV2Stop } from '../models/ItineraryStop'
import { SalesBotV2Stop } from '@/models/SalesbotV2Quote'
import { DateTime } from 'luxon'


/**
 * Returns an array of TripItineraryV2Stop objects based on the input array of Trip objects.
 * @param trips - An array of Trip objects.
 * @returns An array of TripItineraryV2Stop objects.
 */
export const getTripItineraryStopsFromTrip = (trip: Trip): TripItineraryV2Stop[] => {
  if (!trip) {
    return []
  }
  const vehicleStaysOnSite = trip.vehicleNeededEntireTrip
  const activeStops = trip?.stops?.filter(({ active }) => active)
  const stops = activeStops.map(toItineraryStopFromStop)
  return getTripItineraryStops(stops, vehicleStaysOnSite)
}


/**
 * Generates an array of TripItineraryV2Stop objects from SalesBotV2Stop objects.
 *
 * @param stops - An array of SalesBotV2Stop objects representing the stops in the trip itinerary.
 * @param estimatedDropoffDatetimes - An array of DateTime or null values representing the estimated dropoff times for each stop.
 * @param vehicleNeededEntireTrip - A boolean indicating whether a vehicle is needed for the entire trip.
 * @returns An array of TripItineraryV2Stop objects.
 */
export const getTripItineraryStopsFromSalesBot = (stops: SalesBotV2Stop[], estimatedDropoffDatetimes: (DateTime | null)[], vehicleNeededEntireTrip: boolean): TripItineraryV2Stop[] => {
    if (!stops?.length) {
      return []
    }

    const itineraryStops: ItineraryStop[] = stops
      .filter(({ address }) => !!address?.name) // don't include stops without complete addresses
      .map((stop, index) => {
        const pickupDatetime: string = stop.pickupDatetime?.toISO()
        const dropoffDatetime: string = estimatedDropoffDatetimes[index]?.toISO()
        const address = {
          street1: stop.address?.street1 || '',
          street2: '',
          city: stop.address?.city || '',
          state: stop.address?.state || '',
          postalCode: stop.address?.postalCode || '',
          name: stop.address?.name || '',
          title: stop.address?.title || '',
          country: stop.address?.country || '',
          zoneId: stop.address?.zoneId || '',
        }
        return {
          address,
          pickupDatetime,
          dropoffDatetime,
          type: null
        }
      })

    return getTripItineraryStops(itineraryStops, vehicleNeededEntireTrip)
}

const ICON_MAP = {
  stop: 'trip_stop',
  dropoff: 'trip_finish',
  wait: 'place',
  pickup: 'trip_origin',
  travel: 'arrow_downward',
}

const ICON_COLOR_MAP = {
  stop: 'cup-500',
  dropoff: 'cup-500',
  wait: 'midnight-gray-300',
  pickup: 'cup-500',
  travel: 'midnight-gray-300',
}

interface ItineraryStop {
  dropoffDatetime: string
  pickupDatetime: string
  address: ItineraryStopAddress
  type: ItineraryStopTypeKey
}
interface ItineraryStopAddress {
  street1: string
  street2: string
  city: string
  state: string
  postalCode: string
  name: string
  title: string
  country: string
  zoneId: string
}
/**
 * Generates an array of `TripItineraryV2Stop` objects from an array of `ItineraryStop` objects.
 *
 * @param stops - An array of `ItineraryStop` objects representing the stops in the trip.
 * @param vehicleStaysOnSite - A boolean indicating whether the vehicle stays on site during the stops.
 * @returns An array of `TripItineraryV2Stop` objects representing the detailed itinerary of the trip.
 *
 * This function processes each stop in the provided `stops` array and determines the type of each stop.
 * It also checks if the trip spans multiple years and creates itinerary stops accordingly.
 * For each stop, it creates a corresponding `TripItineraryV2Stop` object and adds it to the result array.
 * If the stop is not the last one, it also creates a travel item between the current stop and the next stop.
 */
const getTripItineraryStops = (stops: ItineraryStop[], vehicleStaysOnSite: boolean): TripItineraryV2Stop[] => {
  const isMultiYearTrip = doStopsSpanMultipleYears(stops)

  const itineraryStops: TripItineraryV2Stop[] = []

  stops.forEach((stop, i) => {
    const isFirstStop = i === 0
    const isLastStop = i === stops.length - 1
    const nextStop = !isLastStop ? stops[i + 1] : null

    const stopTypes = getItineraryStopTypeKeys(stop.pickupDatetime, stop.dropoffDatetime, isFirstStop, isLastStop)
    stopTypes.forEach((type, typeIndex) => {
      const stopItem = createTripItineraryV2Stop(
        { ...stop, type },
        {...nextStop, type: typeIndex !== stopTypes.length - 1 ? stopTypes[typeIndex + 1] : null },
        isMultiYearTrip,
        vehicleStaysOnSite
      )
      itineraryStops.push(stopItem)
    })
  })

  return itineraryStops
}

/**
 * Converts a `Stop` object to an `ItineraryStop` object.
 *
 * @param {Stop} stop - The stop object to convert.
 * @returns {ItineraryStop} The converted itinerary stop object.
 * @throws {Error} If the stop object is not provided.
 */
const toItineraryStopFromStop = (stop: Stop): ItineraryStop => {
  if (!stop) {
    throw new Error('Stop is required')
  }
  return {
    dropoffDatetime: stop.dropoffDatetime,
    pickupDatetime: stop.pickupDatetime,
    address: toItineraryStopAddressFromAddress(stop.address),
    type: null,
  }
}

/**
 * Converts an Address object to an ItineraryStopAddress object.
 *
 * @param {Address} address - The address object to convert.
 * @returns {ItineraryStopAddress | null} The converted itinerary stop address object, or null if the input address is falsy.
 */
const toItineraryStopAddressFromAddress = (address: Address): ItineraryStopAddress => {
  if (!address) {
    return null
  }
  return {
    street1: address.street1,
    street2: address.street2,
    city: address.city,
    state: address.state,
    postalCode: address.postalCode,
    name: address.addressName,
    title: address.title,
    country: address.country,
    zoneId: address.timeZone,
  }
}

/**
 * Creates a trip itinerary stop object for version 2 of the trip itinerary.
 *
 * @param {ItineraryStop} stop - The current stop in the itinerary.
 * @param {ItineraryStop} nextStop - The next stop in the itinerary.
 * @param {boolean} isMultiYearTrip - Indicates if the trip spans multiple years.
 * @param {boolean} vehicleStaysOnSite - Indicates if the vehicle stays on site during the stop.
 * @returns {TripItineraryV2Stop} The formatted trip itinerary stop object.
 */
const createTripItineraryV2Stop = (
  stop: ItineraryStop,
  nextStop: ItineraryStop,
  isMultiYearTrip: boolean,
  vehicleStaysOnSite: boolean
): TripItineraryV2Stop => {

  const { zoneId } = stop.address
  const { dropoffDatetime, pickupDatetime } = stop
  const type = stop.type
  const nextStopDropoffDatetime = nextStop?.dropoffDatetime

  const formattedTime = getFormattedStopTimeObject(dropoffDatetime, pickupDatetime, zoneId, type)
  const duration = getDuration(type, dropoffDatetime, pickupDatetime, nextStopDropoffDatetime)

  const itineraryStop: TripItineraryV2Stop = {
    duration,
    icon: ICON_MAP[type] || '',
    iconColor: ICON_COLOR_MAP[type] || '',
    year: isMultiYearTrip ? formattedTime.year : '',
    label: getLabel(type, stop.address),
    type,
    dashedLine:
      type === ItineraryStopTypeKey.Stop || type === ItineraryStopTypeKey.Wait,
    noTopGap: nextStop?.type === ItineraryStopTypeKey.Travel,
    hideBottomMargin: nextStop?.type === ItineraryStopTypeKey.Travel,
    vehicleStaysOnSite:
      type === ItineraryStopTypeKey.Wait && vehicleStaysOnSite,
  }

  if (
    ![ItineraryStopTypeKey.Wait, ItineraryStopTypeKey.Travel].includes(type)
  ) {
    Object.assign(itineraryStop, {
      time: formattedTime.time,
      date: formattedTime.date,
    })
    itineraryStop.formattedAddress = getFormattedAddress(stop.address)
  }

  return itineraryStop
}

/**
 * Determines if the stops in the itinerary span multiple years.
 *
 * @param stops - An array of itinerary stops.
 * @returns A boolean indicating whether the stops span multiple years.
 */
const doStopsSpanMultipleYears = (stops: ItineraryStop[]): boolean => {
  if (!stops || stops.length === 0) {
    return false
  }

  const firstStop = stops[0]
  const lastStop = stops[stops.length - 1]
  return doTimestampsSpanMultipleYears(
    firstStop.pickupDatetime || firstStop.dropoffDatetime,
    firstStop.address.zoneId,
    lastStop.pickupDatetime || lastStop.dropoffDatetime,
    lastStop.address.zoneId
  )
}

const getItineraryStopTypeKeys = (
  pickupDatetime: string,
  dropoffDatetime: string,
  isFirstStop: boolean,
  isLastStop: boolean,
): ItineraryStopTypeKey[] => {
const stopTypes: ItineraryStopTypeKey[] = []

  const hasPickup = !!pickupDatetime
  const hasDropoff = !!dropoffDatetime

  if (hasDropoff) {
    stopTypes.push(
      hasPickup && hasDropoff
        ? ItineraryStopTypeKey.Stop
        : ItineraryStopTypeKey.Dropoff
    )
  }

  if (hasPickup && hasDropoff) {
    stopTypes.push(ItineraryStopTypeKey.Wait)
  }

  if (hasPickup || isFirstStop) {
    stopTypes.push(ItineraryStopTypeKey.Pickup)
  }

  if (!isLastStop) {
    stopTypes.push(ItineraryStopTypeKey.Travel)
  }

  return stopTypes
}

/**
 * Generates a label for an itinerary stop based on the type and location details.
 *
 * @param {ItineraryStopTypeKey} type - The type of the itinerary stop.
 * @param {ItineraryStopAddress} address - The address details for the itinerary stop.
 * @returns {string} - The generated label for the itinerary stop.
 */
const getLabel = (type: ItineraryStopTypeKey, address: ItineraryStopAddress): string => {
  if (type === ItineraryStopTypeKey.Travel) {
    return ''
  }

  const { street1, city, state, postalCode, name, title } = address

  const labelMap = {
    stop: 'Stop',
    dropoff: 'Dropoff',
    wait: 'Stay',
    pickup: 'Pickup',
  }

  const prefix = `${labelMap[type]} at`

  if (type === ItineraryStopTypeKey.Wait) {
    return `${prefix} the destination`
  }

  const location = title || street1 ||
    formatCityStateZip(city, state, postalCode, name)?.replace(/[0-9]/g, '')
  return `${prefix} ${location}`.trim()
}


/**
 * Formats an address by combining street address, city, state, postal code, and country into a single string.
 *
 * @param address - The address object containing the address details.
 * @returns The formatted address as a single string.
 */
const getFormattedAddress = (address: ItineraryStopAddress): string => {
  const { street1, street2, city, state, postalCode, name, country } = address
  const streetAddress = formatStreetAddress(street1, street2)
  const cityStateZip = formatCityStateZip(city, state, postalCode, name)

  return [streetAddress, cityStateZip, country].filter(Boolean).join(', ')
}

/**
 * Calculates the duration between itinerary stops based on the type of stop.
 *
 * @param itineraryStopType - The type of the itinerary stop (e.g., Wait, Travel).
 * @param dropoffDatetime - The datetime when the dropoff occurs.
 * @param pickupDatetime - The datetime when the pickup occurs.
 * @param nextStopDropoffDatetime - The datetime when the next stop dropoff occurs (optional).
 * @returns The formatted duration between the specified datetimes.
 */
const getDuration = (
  itineraryStopType: ItineraryStopTypeKey,
  dropoffDatetime: string,
  pickupDatetime: string,
  nextStopDropoffDatetime?: string
): string => {
  if (itineraryStopType === ItineraryStopTypeKey.Wait) {
    return getMinimizedFormattedTimeBetween(dropoffDatetime, pickupDatetime)
  }

  if (itineraryStopType === ItineraryStopTypeKey.Travel) {
    return getMinimizedFormattedTimeBetween(pickupDatetime, nextStopDropoffDatetime ?? null)
  }

  return ''
}

/**
 * Formats the stop time object based on the provided parameters.
 *
 * @param dropoffDatetime - The datetime string for the dropoff.
 * @param pickupDatetime - The datetime string for the pickup.
 * @param zoneId - The time zone identifier.
 * @param type - The type of itinerary stop, either pickup or dropoff.
 * @returns An object containing the formatted date, time, and year.
 */
const getFormattedStopTimeObject = (
  dropoffDatetime: string,
  pickupDatetime: string,
  zoneId: string,
  type: ItineraryStopTypeKey
): { date: string; time: string; year: string } => {
  const time =
    type === ItineraryStopTypeKey.Pickup
      ? pickupDatetime
      : dropoffDatetime
  if (!time || !zoneId) {
    return { date: '', time: '', year: '' }
  }
  return formatStopTimeV2(time, zoneId)
}
