<template>
  <div class="padding-l-4 w-full">
    <div class="d-flex align-center">
      <div
        class="d-flex font-bold text-white align-center justify-center border-radius-round w-24 h-24 margin-r-2"
        :class="errorMessage ? 'background-red' : 'background-gray'"
      >
        {{ nextIndex }}
      </div>
      <span
        class="font-bold"
        :class="errorMessage ? 'text-error' : 'text-gray'"
      >
        {{ stopType }}
      </span>
      <span v-if="errorMessage" class="margin-l-2 font-14 text-error">
        {{ errorMessage }}
      </span>
    </div>
    <div>
      <label class="d-flex margin-t-1">
        {{ addressLabel }}
        <span v-if="addressTitle" class="font-medium">
          - {{ addressTitle }}
        </span>
        <v-spacer />
        <span
          v-if="!isFirstStop && timeFromPreviousStopFormatted"
          class="font-14 text-gray-light"
        >
          Travel: {{ timeFromPreviousStopFormatted }}
        </span>
      </label>
      <AutoCompleteAddress
        :id="`self-serve-itinerary-stop-address-${stopIndex}`"
        :key="`self-serve-itinerary-stop-address-${stopIndex}`"
        clearable
        clear-icon="replay"
        :initial-search="addressName"
        hide-details
        :auto-focus="isFirstStop && !stop.address"
        limit-countries
        @place-selected="emitAddress($event.place)"
        @place-cleared="emitAddress(null)"
      />
      <!-- DISABLING UNTIL WE ADD BACK ITINERARY WARNINGS -->
      <!-- <div
        v-for="(addressRisk, index) in addressRisks"
        :key="`address-risk-${addressRisk.riskTypeId}-${index}`"
        class="d-flex align-center margin-b-2"
      >
        <CUIcon
          :key="`itinerary-warning-icon-${index}`"
          view-box="0 0 24 24"
          :class="`text-${addressRisk.class}`"
          :width="20"
          :height="20"
        >
          {{ addressRisk.icon }}
        </CUIcon>
        <p class="margin-l-2 margin-y-0 font-14 text-gray">
          {{ addressRisk.label }}
        </p>
      </div> -->
      <div v-if="showKeepOnSite" class="d-flex align-center">
        <v-checkbox
          :input-value="trip.vehicleNeededEntireTrip"
          label="Keep vehicle on-site"
          hide-details
          class="margin-t-0 padding-t-0"
          @change="$emit('update:vehicleNeededEntireTrip', $event)"
        />
        <v-spacer />
        <div
          class="font-12 font-medium text-primary d-flex align-center cursor-pointer padding-y-1"
          @click="isKeepOnSiteDialogOpen = true"
        >
          <CUIcon
            color="primary"
            view-box="0 0 24 24"
            class="w-16 h-16 margin-r-1"
          >
            info
          </CUIcon>
          What's this?
        </div>
      </div>
    </div>
    <v-row v-if="!isLastStop">
      <v-col cols="6">
        <CUDatePicker
          :id="`self-serve-itinerary-date-picker-${stopIndex}`"
          :label="stopDate.label"
          :value="stopDate.value"
          :display-value="stopDate.displayValue"
          :min="stopDate.min"
          hide-details
          :disabled="disableDateTimeFields"
          @input="setStopDate"
        />
      </v-col>
      <v-col cols="6">
        <CUTimePicker
          :id="`self-serve-itinerary-stop-time-${stopIndex}`"
          :label="stopTime.label"
          :value="time"
          :display-value="stopTime.displayValue"
          hide-details
          :disabled="disableDateTimeFields"
          @input="setStopTime"
        />
      </v-col>
    </v-row>
    <div class="d-flex">
      <v-btn
        v-if="!hideAdd"
        color="primary"
        class="padding-x-0"
        small
        plain
        @click="addStop"
      >
        + Add a Stop
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="!hideDelete"
        color="error"
        class="padding-x-0"
        small
        plain
        @click="trip.removeStop(stopIndex)"
      >
        Remove stop
      </v-btn>
    </div>
    <SelfServeTripItineraryStopKeepOnSiteDialog
      v-model="isKeepOnSiteDialogOpen"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import { DateTime, Duration, SystemZone } from 'luxon'
import AutoCompleteAddress from '@/components/AutoCompleteAddress.vue'
import CUDigitalTimePicker from '@/components/CUDigitalTimePicker.vue'
import CUDatePicker from '@/components/CUDatePicker.vue'
import CUTimePicker from '@/components/CUTimePicker.vue'
import SelfServeTripItineraryStopKeepOnSiteDialog from '@/components/SelfServeTripItineraryStopKeepOnSiteDialog.vue'
import CUIcon from '@/components/CUIcon.vue'
import { TripModificationTrip } from '@/classes/TripModificationTrip'
import { TripModificationStop } from '@/classes/TripModificationStop'
import { Address, PlaceDetail } from '@/models/dto'
import { secondsToFormattedDuration } from '@/utils/datetime'
import { placeDetailToAddress } from '@/utils/address'
import { RiskTypeId, RiskTypeLabel } from '@/utils/enum'

const DEFAULT_STOP_TIME = '08:00:00'
const STOP_TIME_LABEL = 'Pickup Time'
const STOP_DATE_LABEL = 'Pickup Date'
const HOUR_MINUTE_FORMAT = 'hh:mm a'
const MONTH_DAY_YEAR_FORMAT = 'LL/dd/yy'

@Component({
  components: {
    AutoCompleteAddress,
    CUDigitalTimePicker,
    CUIcon,
    CUDatePicker,
    CUTimePicker,
    SelfServeTripItineraryStopKeepOnSiteDialog,
  },
})
export default class TripModificationItineraryStop extends Vue {
  @Prop({ type: Object, required: true })
  readonly trip: TripModificationTrip
  @Prop({ type: Object, required: true }) readonly stop: TripModificationStop
  @Prop({ type: Boolean }) readonly hideDelete: boolean
  @Prop({ type: Boolean }) readonly hideAdd: boolean
  @Prop({ type: Number, required: true }) readonly stopIndex: number

  isKeepOnSiteDialogOpen = false
  lastValidDate: string = ''

  @Emit('update:address')
  emitAddress(place: PlaceDetail): { address: Address; index: number } {
    const address = placeDetailToAddress(place)
    return { address, index: this.stopIndex }
  }

  @Emit('update:datetime')
  emitDateTime(date: string, time:string): { date: string; time: string; index: number } {
    return { date, time, index: this.stopIndex }
  }

  @Emit('update:addStop')
  emitAddStop(index: number): { index: number } {
    return { index }
  }

  @Watch('errorMessage')
  errorMessageChanged(): void {
    this.$emit('update:has-error', !!this.errorMessage)
  }

  @Watch('date', {immediate: true})
  setLastValidDate(): void {
    if (this.date) {
      this.lastValidDate = this.date
    }
  }

  get nextIndex(): number {
    return this.stopIndex + 1
  }

  get isFirstStop(): boolean {
    return this.stopIndex === 0
  }

  get isLastStop(): boolean {
    return this.stopIndex === this.trip.stops.length - 1
  }

  get previousStop(): TripModificationStop | null {
    return !this.isFirstStop ? this.trip.stops[this.stopIndex - 1] : null
  }

  get stopType(): string {
    switch (this.stopIndex) {
      case 0:
        return 'Pickup'
      case this.trip.stops.length - 1:
        return 'Dropoff'
      default:
        return 'Stop'
    }
  }

  get addressLabel(): string {
    return `${this.stopType} Location`
  }

  get addressTitle(): string {
    return this.stop?.address?.title || ''
  }

  get addressName(): string {
    return this.stop?.address?.name || ''
  }

  get errorMessage(): string {
    const pickupDatetime = DateTime.fromISO(this.stop?.pickupDatetime)
    const dropoffDatetime = DateTime.fromISO(this.stop?.dropoffDatetime)
    const minPickupDatetime = DateTime.local({
      zone: this.stop?.address?.zoneId || new SystemZone(),
    })

    if (this.isFirstStop && pickupDatetime <= minPickupDatetime) {
      return 'Your trip must start in the future'
    }

    const previousPickupDatetime = DateTime.fromISO(
      this.previousStop?.pickupDatetime
    )
    if (previousPickupDatetime && pickupDatetime < previousPickupDatetime) {
      return 'Pickup must be after prior stops'
    }

    if (
      dropoffDatetime &&
      pickupDatetime < dropoffDatetime &&
      this.stop?.address?.zoneId
    ) {
      const arrivalDatetime = DateTime.fromISO(dropoffDatetime, {
        zone: this.stop.address.zoneId,
      })
      const arrivalTimeFormatted = arrivalDatetime.toFormat(HOUR_MINUTE_FORMAT)
      const arrivalDateFormatted = arrivalDatetime.toFormat(
        MONTH_DAY_YEAR_FORMAT
      )
      return `Pickup must be after the estimated arrival time of ${arrivalTimeFormatted} on ${arrivalDateFormatted}`
    }

    return ''
  }

  get stopDate(): {
    label: string
    value: string
    displayValue: string
    min: string
  } {
    const formattedStopDate = this.date
      ? DateTime.fromISO(this.date).toFormat(MONTH_DAY_YEAR_FORMAT)
      : ' '

    const previousStopDuration = DateTime.fromISO(
      this.previousStop?.pickupDatetime,
      {
        zone: this.previousStop?.address?.zoneId,
      }
    )
    const previousStopDate =
      this.extractDateString(previousStopDuration) ||
      this.extractDateString(DateTime.local({ zone: this.stop?.address?.zoneId }))

    const splitDate = previousStopDate.split('-')
    const formattedPreviousDate =
      splitDate.length === 3 ? `${splitDate[0]}-${splitDate[1]}` : ''

    return {
      label: STOP_DATE_LABEL,
      value: this.date || formattedPreviousDate,
      displayValue: formattedStopDate,
      min: previousStopDate,
    }
  }

  get stopTime(): {
    label: string
    displayValue: string
  } {
    const formattedStopTime = this.time
      ? DateTime.fromISO(this.time).toFormat(HOUR_MINUTE_FORMAT)
      : ''
    return {
      label: STOP_TIME_LABEL,
      displayValue: formattedStopTime,
    }
  }

  get showKeepOnSite(): boolean {
    return !this.isFirstStop && !this.isLastStop && this.trip.stops.length > 2
  }

  get timeFromPreviousStopFormatted(): string {
    return this.stop.travelTimeFromPreviousStopInSeconds
      ? secondsToFormattedDuration(
          this.stop.travelTimeFromPreviousStopInSeconds
        )
      : ''
  }

  get addressRisks(): {
    riskTypeId: number
    icon: string
    label: string
    class: string
  }[] {
    const riskIconMap = []
    for (const risk of this.stop?.address?.risks || []) {
      const { riskTypeId } = risk
      if (riskTypeId === RiskTypeId.IncompleteAddress) {
        riskIconMap.push({
          riskTypeId,
          icon: 'warning',
          label: RiskTypeLabel.IncompleteAddress,
          class: 'yellow',
        })
      }
    }
    return riskIconMap
  }

  get pickupDatetime(): DateTime {
    return DateTime.fromISO(this.stop?.pickupDatetime, {
      zone: this.stop?.address?.zoneId,
    })
  }

  get time(): string {
    return this.extractTimeString(this.pickupDatetime)
  }

  get date(): string {
    const dateFromPickupTime = this.extractDateString(this.pickupDatetime)
    if (!dateFromPickupTime || dateFromPickupTime === '') {
      return this.lastValidDate
    }

    return dateFromPickupTime
  }

  get disableDateTimeFields(): boolean {
    return !this.stop.address
  }

  mounted(): void {
    if (!this.stop?.pickupDatetime) {
      return
    }
  }

  extractTimeString(time: Duration): string {
    if (!time.isValid) {
      return ''
    }
    return time.toISOTime()
  }

  extractDateString(date: Duration): string {
    if (!date.isValid) {
      return ''
    }
    return date.toISODate()
  }

  setStopDate(event: string): void {
    const newTime = this.time || this.setDefaultTime()
    this.emitDateTime(event, newTime)
  }

  setStopTime(event: string): void {
    const newTime = event === null || event === undefined ? '' : event
    this.emitDateTime(this.date, newTime)
  }

  setDefaultTime(): string {
    const baseTime = this.previousStop?.pickupDatetime
    const zone = this.stop?.address?.zoneId

    if (!this.previousStop || !baseTime || !zone) {
      return DEFAULT_STOP_TIME
    }

    // Get time and round up to the nearest hour
    const calculatedStopTime = DateTime.fromISO(baseTime, { zone })
      .plus({
        seconds: this.stop.travelTimeFromPreviousStopInSeconds,
      })
      .plus({ hours: 1 })
      .startOf('hour')

    return this.extractTimeString(calculatedStopTime)
  }

  addStop(): void {
    const newStopIndex = this.stopIndex + 1
    this.trip.addStop(newStopIndex)
    this.emitAddStop(newStopIndex)
  }
}
</script>
<style lang="scss" scoped>
::v-deep .v-label {
  color: $gray !important;
}

::v-deep .v-input .v-input--selection-controls__input {
  margin-right: 4px !important;
}
</style>
