<template>
  <CUModal
    :value="value"
    max-width="1000px"
    width="100%"
    @input="$emit('input', false)"
  >
    <template #title>{{ row.firstName }} {{ row.lastName }}</template>
    <template #text>
      <CUDataTable
        id="user-shared-trips-modal"
        :columns="columns"
        :items="paginatedItems"
        :loading="loading"
        item-key="userId"
        no-data-text="No trips found"
        :options.sync="tableOptions"
        :server-items-length="itemsLength"
        :hide-default-header="$vuetify.breakpoint.sm"
        mobile-view-on-breakpoint="sm"
        dense
        v-on="$listeners"
        @refresh="getUserTripsTable"
      >
        <template #mobileLayout="{ item }">
          <tr>
            <td class="padding-t-4 padding-b-6 padding-r-8">
              <v-row class="padding-t-2">
                <b class="margin-r-1">Quote ID:</b>
                <SharedTripsModalQuoteColumn :row="item" />
              </v-row>
              <v-row>
                <b class="margin-r-1">Reservation ID:</b>
                <SharedTripsModalReservationColumn :row="item" />
              </v-row>
              <v-row>
                <div>
                  <b class="margin-r-1">Invited By:</b>
                  {{ item.invitedByName }}
                </div>
              </v-row>
              <v-row>
                <div>
                  <b class="margin-r-1">Route:</b>
                  {{ item.routeName }}
                </div>
              </v-row>
              <v-row>
                <b class="margin-r-1">Pickup Date:</b>
                {{ $dayjs(item.pickupDateTime).format('DD MMM YY - h:mm A') }}
              </v-row>
              <v-row>
                <a class="font-bold" @click="unshare(item)">
                  Unshare {{ item.reservationId ? 'Reservation' : 'Quote' }}
                </a>
              </v-row>
            </td>
          </tr>
        </template>
      </CUDataTable>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { DataTableColumn } from '@/models/DataTableColumn'
import SharedContact from '@/models/dto/SharedContact'
import SharedTrip from '@/models/dto/SharedTrip'
import user from '@/services/user'
import { Vue, Prop, Component } from 'vue-property-decorator'
import { DataOptions } from 'vuetify'
import SharedTripsModalQuoteColumn from './SharedTripsModalQuoteColumn.vue'
import SharedTripsModalReservationColumn from './SharedTripsModalReservationColumn.vue'
import SharedTripsModalUnshareActions from './SharedTripsModalUnshareActions.vue'
import reservation from '@/services/reservation'
import quotes from '@/services/quotes'
import { EventBus } from '@/utils/eventBus'
import alert from '@/store/modules/alert'

@Component({
  components: {
    SharedTripsModalQuoteColumn,
    SharedTripsModalReservationColumn,
  },
})
export default class SharedTripsModal extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value!: boolean
  @Prop({ required: true }) readonly row: SharedContact

  expanded = false
  loading = false
  tableOptions: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortBy: [],
    sortDesc: [],
  }

  columns: DataTableColumn[] = [
    {
      _t_id: '93ee63e9-e97e-4bc5-8de5-fb44db196765',
      elementId: 'quoteId',
      text: 'Quote ID',
      value: 'quoteId',
      type: 'slot',
      component: SharedTripsModalQuoteColumn,
    },
    {
      _t_id: 'd940384a-d531-4e25-bd68-abb3b2df7cba',
      elementId: 'reservationManagedId',
      text: 'Reservation ID',
      value: 'reservationManagedId',
      type: 'slot',
      component: SharedTripsModalReservationColumn,
    },
    {
      _t_id: 'c56ccee8-a663-4833-b2ba-4366e62fce14',
      elementId: 'invitedByName',
      text: 'Invited By',
      value: 'invitedByName',
      type: 'text',
    },
    {
      _t_id: 'ee74e3eb-3344-4727-8996-3280c86bd554',
      elementId: 'routeName',
      text: 'Route',
      value: 'routeName',
      type: 'text',
    },
    {
      _t_id: 'f5324da2-f55b-4fac-a361-0fea13812897',
      elementId: 'pickupDateTime',
      text: 'Pickup Date',
      value: 'pickupDateTime',
      type: 'text',
      computedText: (row: SharedTrip): string =>
        row.pickupDateTime
          ? this.$dayjs(row.pickupDateTime).format('DD MMM YY')
          : '',
    },
    {
      _t_id: 'bc3e1faf-5077-40d3-b5ed-fbdaca37bb6d',
      elementId: 'pickupDateTime',
      text: 'Pickup Time',
      value: 'pickupDateTime',
      type: 'text',
      computedText: (row: SharedTrip): string =>
        row.pickupDateTime
          ? this.$dayjs(row.pickupDateTime).format('h:mm A')
          : '',
    },
    {
      _t_id: '7c978911-7ac1-4e97-a71a-77da43ccf607',
      elementId: 'more-actions',
      text: 'Actions',
      value: '',
      type: 'slot',
      component: SharedTripsModalUnshareActions,
    },
  ]

  items = []
  itemsLength = 0

  get paginatedItems(): SharedContact[] {
    return this.items.slice(
      (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
      this.tableOptions.page * this.tableOptions.itemsPerPage
    )
  }

  mounted(): void {
    this.getUserTripsTable()
    EventBus.$on('update-shared-contacts', () => {
      this.getUserTripsTable()
    })
  }

  async getUserTripsTable() {
    this.loading = true
    try {
      const response = await user.sharedTrips(this.row.userId)
      this.items = response.data
      this.itemsLength = this.items?.length
      this.loading = false
    } catch (e) {
      console.error(e)
      this.loading = false
    }
  }

  async unshare(item: SharedTrip) {
    this.loading = true
    try {
      if (!!item.reservationId) {
        await reservation.unshare(item.reservationId, this.row.userId)
      } else {
        await quotes.unshare(item.quoteId, this.row.userId)
      }
      this.loading = false
      alert.add({
        text: 'Successfully unshared trip.',
        color: 'primary',
        title: 'Success',
        dismissible: true,
      })
      EventBus.$emit('update-shared-contacts')
    } catch (e) {
      console.error(e)
      this.loading = false
    }
  }
}
</script>
