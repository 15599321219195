<template>
  <div class="d-flex justify-center ">
    <CustomerRequiredDetailsForm class="max-w-720" @update:customer="onCustomerUpdate" />
  </div>
</template>

<script setup lang="ts">
import CustomerRequiredDetailsForm from '@/components/CustomerRequiredDetailsForm.vue'
import selfServe from '@/store/modules/selfServe'
import user from '@/store/modules/user'
import { onMounted } from 'vue'

onMounted(() => {
  user.setIsQuoteFollowUpSMSEnabled(true)
})

const onCustomerUpdate = async (): Promise<void> => {
  await selfServe.createQuote()
  selfServe.nextStep()
}
</script>
