<template>
  <v-card
    :class="{
      'padding-t-4 padding-x-4 padding-b-2 margin-t-0 margin-r-4 margin-b-4 margin-l-3':
        $vuetify.breakpoint.smAndUp,
      'padding-t-2 padding-x-2 padding-b-1': $vuetify.breakpoint.xsOnly,
    }"
  >
    <v-card-title
      class="padding-t-2 padding-x-2 padding-b-4"
      :class="{ expanded: expanded }"
    >
      <span :style="{ 'word-break': 'keep-all', width: 'calc(100% - 24px)' }">
        {{ locationTitle }}
      </span>
      <v-icon
        v-if="!hideExpand"
        color="gray"
        :class="{ flipped: expanded }"
        @click="expanded = !expanded"
      >
        keyboard_arrow_down
      </v-icon>
    </v-card-title>
    <v-card-subtitle
      v-if="expanded && location.address.title"
      class="padding-t-0 padding-x-2 padding-b-2"
    >
      {{ addressDisplayText }}
    </v-card-subtitle>
    <v-expand-transition>
      <v-card-text
        v-if="expanded"
        :class="{
          'padding-y-2 padding-r-0 padding-l-4': $vuetify.breakpoint.xsOnly,
        }"
      >
        <p v-if="times.length">{{ timesLabel }}</p>
        <v-chip
          v-for="(time, timeIndex) in times"
          :key="`${time}-${timeIndex}-stop`"
          outlined
          disabled
          class="margin-t-0 margin-l-0 margin-r-1 margin-b-1"
          :color="time === nextStopTime ? 'primary' : 'grayMediumLight'"
        >
          {{ formatStopItineraryTime(time.timestamp, location.address) }}
        </v-chip>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script lang="ts">
import { DateTime } from 'luxon'
import { Address } from '@/models/dto'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class EnterpriseTrackingSidebarItineraryCard extends Vue {
  @Prop({ type: Object, required: true }) readonly location: any
  @Prop({ type: String, default: null }) readonly nextStopTime: string
  @Prop({ type: Boolean }) readonly hideExpand: boolean
  @Prop({ type: Number, required: true })
  readonly totalPickupAndDropoffCount: number

  expanded = true

  get locationTitle(): string {
    return this.location?.address?.title || this.addressDisplayText
  }
  get addressDisplayText(): string {
    if (this.location?.address?.name) {
      return this.location.address.name
    }
    const address = this.location.address
    let addressString = address.street1 !== ' ' ? `${address.street1},` : ''
    addressString = address?.street2
      ? `${addressString} ${address.street2},`
      : addressString
    addressString = `${addressString} ${address.city}, ${address.state}`
    return addressString
  }
  get timesLabel(): string {
    if (this.totalPickupAndDropoffCount === 2) {
      return this.location.dropoffTimes.length
        ? 'Scheduled End'
        : 'Scheduled Start'
    }
    return 'Scheduled Times'
  }
  get times(): any[] {
    const mappedDropoffTimes = this.location.dropoffTimes.map((time) => {
      return { timestamp: time, label: 'DROP' }
    })
    const mappedPickupTimes = this.location.pickupTimes.map((time) => {
      return { timestamp: time, label: 'PICK' }
    })
    const times = [...mappedDropoffTimes, ...mappedPickupTimes]
    return times.sort(
      (a, b) =>
        DateTime.fromISO(a.timestamp) - DateTime.fromISO(b.timestamp)
    )
  }

  formatStopItineraryTime(timestamp: string, address: Address): void {
    return DateTime.fromISO(timestamp, {
      zone: address.timeZone,
    }).toFormat('h:mm a')
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors';

.v-card {
  &::before,
  &::after {
    display: none;
  }
  .v-card__title {
    font-family: 'Inter Bold', Arial, sans-serif;
    font-size: 1rem !important;
    line-height: 1rem;
    background: none !important;
    border: none !important;
  }
  .v-card__subtitle {
    color: $gray;
    font-family: 'Inter Regular', Arial, sans-serif;
    font-size: 0.875rem;
  }
  .v-card__text {
    padding: 0 8px 8px !important;
    p {
      color: $gray-medium-light;
      margin: 0 0 4px;
    }
  }
}

.v-icon {
  transform: rotate(0deg);
  transition: transform 0.2s linear;
  &.flipped {
    transform: rotate(-180deg);
    transition: transform 0.2s linear;
  }
}

.v-chip {
  width: 88px;
  justify-content: center;
  font-family: 'Inter Medium', Arial, sans-serif;
  &.v-chip--disabled {
    opacity: 1;
  }
}

.v-icon.v-icon::after {
  background: none;
}

@media screen and (min-width: 600px) {
  .v-card {
    width: 317px;
  }
}
@media screen and (max-width: 599px) {
  .v-card {
    border-radius: 0;
    border: none !important;
    width: 100%;
    &:not(:last-child) {
      border-bottom: 1px solid $border-gray !important;
    }
  }
}
</style>
