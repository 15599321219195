<template>
  <div>
    <p>{{ $t('profile.BILLING.SAVED_CARDS') }}</p>
    <PaymentProfileSelectorV2
      ref="payment-profile-selector"
      v-model="selectedPaymentProfile"
      @update:loading="loadingPaymentProfiles = $event"
    />
    <p class="margin-t-3 font-medium">
      <a @click="handleClickAddNewCard">
        + {{ $t('paymentProfileSelector.ADD_NEW_CARD') }}
      </a>
    </p>
    <PaymentProfileCardModal
      v-model="cardDialogIsOpen"
      @input="handleClose"
      @card-added="refreshCards"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch, Provide } from 'vue-property-decorator'
import PaymentProfileSelectorV2 from '@/components/PaymentProfileSelectorV2.vue'
import PaymentProfileCardModal from '@/components/PaymentProfileCardModal.vue'
import { ComputedPaymentProfile } from '@/models/dto'

@Component({
  components: {
    PaymentProfileSelectorV2,
    PaymentProfileCardModal
  },
})
export default class ProfileBilling extends Vue {
  @Provide('isInBilling') isInBilling = true

  selectedPaymentProfile: ComputedPaymentProfile = null
  cardDialogIsOpen = false
  showAddNewCard = true
  loadingPaymentProfiles = false
  isFinixCheckoutEnabled = false

  refreshCards(): void {
    //@ts-ignore
    this.$refs['payment-profile-selector'].loadCustomerPaymentProfiles()
  }

  handleClose(): void {
    this.cardDialogIsOpen = false
  }

  handleClickAddNewCard(): void {
    this.cardDialogIsOpen = true
  }

}
</script>
