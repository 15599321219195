<template>
  <GmapPolyline id="sales-bot-map-polyline" :path="props.path" :options="routeLineOptions" />
</template>

<script lang="ts" setup>
import { LatLng } from "@/utils/google"
import { computed, PropType } from "vue"
import { useTheme } from "@/composables/useTheme"

const STROKE_OPACITY = 1
const STROKE_WEIGHT = 4
const { themes } = useTheme()

const props = defineProps({
  path: {
    type: Array as PropType<LatLng[]>,
    default: () => [],
  },
})

const routeLineOptions = computed(() => ({
  strokeOpacity: STROKE_OPACITY,
  strokeWeight: STROKE_WEIGHT,
  strokeColor: themes?.value?.light?.midnightGray900 || ''
}))
</script>
