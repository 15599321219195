<template>
  <div>
    <div class="d-flex align-center">
      <h3>Info</h3>
      <v-menu offset-y>
      <template #activator="{ on }">
        <CUIcon
          id="info-actions"
          view-box="0 0 24 24"
          class="kebab-button"
          v-bind="$attrs"
          v-on="on"
        >
          more_vert
        </CUIcon>
        </template>
        <v-list class="dropdown-menu padding-a-0">
          <v-list-item
            id="edit-info"
            key="edit-info"
            @click="isEditing = true"
          >
            <v-list-item-title>
              Edit
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <div class="flex-grow-1" />
      <v-btn v-if="isEditing" small color="error" text class="padding-x-4 margin-r-3" @click="cancel">Cancel</v-btn>
      <v-btn v-if="isEditing" small color="primary" class="padding-x-3" @click="save">Save</v-btn>
    </div>
    <v-form ref="info-form">
      <v-row class="margin-b-5" dense>
        <v-col v-for="(info, infoIndex) in customerAccountInformation" :key="infoIndex" :class="info.columnClasses">
          <template v-if="!isEditing">
            <p class="font-bold">{{ info.label }}</p>
            <p class="margin-t-0">{{ info.formattedValue || info.value }}</p>
          </template>
          <template v-else>
            <template v-if="info.key === 'address'">
              <label>{{ info.label }}</label>
              <AutoCompleteAddress
                clearable
                clear-icon="replay"
                :initial-search="initialSearch"
                hide-details
                @place-selected="info.inputHandler($event.place)"
                @place-cleared="info.inputHandler(null)"
                :rules="info.rules"
              />
            </template>
            <CUTextField
              v-else
              :label="info.label"
              :value="info.value"
              :rules="info.rules"
              @input="info.inputHandler($event)"
            />
          </template>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Address, CustomerAccount, EditCustomerAccountPayload } from "@/models/dto";
import { formatCityStateZip, formatStreetAddress } from "@/utils/address";
import { phoneFormatFilter } from "@/utils/phone";
import { Vue, Component, Prop } from "vue-property-decorator";
import AutoCompleteAddress from "@/components/AutoCompleteAddress.vue";
import customerAccount from "@/services/customerAccount";
import alert from "@/store/modules/alert";

@Component({ components: { AutoCompleteAddress }})
export default class OrganizationAccount extends Vue {
  @Prop({ type: Object, required: true }) customerAccount!: CustomerAccount

  isEditing = false

  get initialSearch(): string {
    return this.customerAccount?.address?.addressName || ''
  }

  get customerAccountInformation(): {label: string; key: string; value: any; formattedValue?: string, columnClasses?: string, inputHandler: (input: any) => void, rules: Array<(v: string) => boolean | string>;}[] {
    const { name, email, phone } = this.customerAccount || {}
    return [
      {
        label: 'Name',
        key: 'name',
        value: name || '',
        columnClasses: 'col-md-4 col-12',
        inputHandler: (name: string) => this.emitUpdate({
          ...this.customerAccount,
          name
        }),
        rules: [(v: string) => !!v || 'Name is required'],
      },
      {
        label: 'Email',
        key: 'email',
        value: email || '',
        columnClasses: 'col-md-4 col-12',
        inputHandler: (email: string) => this.emitUpdate({
          ...this.customerAccount,
          email
        }),
        rules: [(v: string) => !!v || 'Email is required'],
      },
      {
        label: 'Phone',
        key: 'phone',
        value:  phone,
        formattedValue: phoneFormatFilter(phone),
        columnClasses: 'col-md-4 col-12',
        inputHandler: (phone: string) => this.emitUpdate({
          ...this.customerAccount,
          phone
        }),
        rules: [
          (v: string) => !!v || 'Phone is required',
          (v: string): boolean | string => /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) || 'Phone number must be valid'
        ],
      },
      {
        label: 'Address',
        key: 'address',
        value: this.customerAccount?.address,
        formattedValue: this.accountAddress,
        columnClasses: 'col-12',
        inputHandler: (address: Address) => this.emitUpdate({
          ...this.customerAccount,
          address
        }),
        rules: [(v: string) => !!v || 'Address is required'],
      }
    ]
  }

  get accountAddress(): string {
    const { address } = this.customerAccount || {}
    if (!address) {
      return ''
    }
    const streetAddress = formatStreetAddress(address.street1, address.street2)
    const cityStateZip = formatCityStateZip(address.city, address.state, address.postalCode, address.addressName)
    return `${streetAddress}, ${cityStateZip}`
  }

  async save(): Promise<void> {
    if (!(this.$refs['info-form'] as any).validate()) {
      return
    }

    try {
      const payload: EditCustomerAccountPayload = {
        name: this.customerAccount.name,
        email: this.customerAccount.email,
        phone: this.customerAccount.phone,
        address: this.customerAccount.address
      }
      delete payload.address.addressId
      await customerAccount.edit(this.customerAccount.customerAccountId, payload)
      this.$emit('refresh')
      this.isEditing = false
    } catch (error) {
      alert.add({
        text: 'Error saving customer account information',
        color: 'error',
        title: 'Error',
        dismissible: true
      })
    }
  }

  cancel(): void {
    this.$emit('refresh')
    this.isEditing = false
  }

  emitUpdate(customerAccount: CustomerAccount): void {
    this.$emit('update:customer-account', customerAccount)
  }

}
</script>
