<template>
  <div class="d-flex background-border-gray padding-x-2 rounded-pill align-center justify-center">
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <CUIcon
          v-bind="attrs"
          v-on="on"
          width="16"
          view-box="0 0 16 16"
          class="margin-r-2"
        >
          charter_up
        </CUIcon>
      </template>
      <span class="text-white">CharterUP Rating</span>
    </v-tooltip>
    <StarRating
      :increment="0.1"
      :rating="rating"
      read-only
      :show-rating="false"
      :star-size="starSize"
      :active-color="$vuetify.theme.themes.light.yellow"
      class="margin-r-1 padding-a-0"
    />
    <div class="font-12">
      {{ formattedRating }}
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { alwaysShowTenthsPlace } from '@/utils/string'
import StarRating from 'vue-star-rating'

@Component({
  components: {
    StarRating,
  },
})
export default class CharterUPRating extends Vue {
  @Prop({ type: Number, default: 0 }) readonly rating: number
  @Prop({ type: Number, default: 14 }) readonly starSize: number

  get formattedRating(): string {
    return alwaysShowTenthsPlace(this.rating)
  }
}
</script>
