<template>
  <div class="max-w-700 w-full d-flex flex-column">
    <span class="font-medium">
      Amenities included on Charter Buses
    </span>
    <div
      class="d-flex align-center justify-space-between"
      :class="{
        'max-w-500': $vuetify.breakpoint.smAndUp,
        'max-w-300': $vuetify.breakpoint.xs,
      }"
    >
      <div class="margin-t-7">
        <span class="d-flex align-center">
          <CUIcon
            class="cursor-default text-gray-subtle-text-2"
            viewBox="0 -960 960 960"
            width="23px"
            height="23px"
          >
            bathroom
          </CUIcon>
          <p class="font-medium margin-l-2 margin-t-0">Bathroom</p>
        </span>
        <span class="margin-t-3 d-flex align-center">
          <CUIcon
            class="cursor-default text-gray-subtle-text-2"
            viewBox="0 0 24 24"
            width="24px"
            height="24px"
          >
            luggage
          </CUIcon>
          <p class="font-medium margin-l-2 margin-t-0">Luggage</p>
        </span>
      </div>
      <div class="margin-t-7">
        <span class="d-flex align-center">
          <v-icon class="text-gray-subtle-text-2">
            power
          </v-icon>
          <p class="font-medium margin-l-2 margin-t-0">Outlets</p>
        </span>
        <span class="margin-t-3 d-flex align-center">
          <v-icon class="text-gray-subtle-text-2">
            tv
          </v-icon>
          <p class="font-medium margin-l-2 margin-t-0">TV Screen</p>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class SelfServeDefaultAmenities extends Vue {}
</script>
