<template>
  <div>
    <div class="d-flex justify-space-between margin-b-1" :style="{ gap: '10px' }">
      <div>
        <h2 class="margin-t-n1">
          {{ tripTitle || tripDescription }}
        </h2>
        <h3 v-if="tripTitle">
          {{ tripDescription }}
        </h3>
      </div>
      <div
        class="d-flex flex-shrink-0"
        :style="{ textAlign: 'right', gap: '10px' }"
      >
        <a
          v-if="isSelfServe && !isSplitTrip"
          class="align-end"
          :style="{ textAlign: 'right' }"
          @click="$emit('to-self-serve')"
        >
          Edit trip
        </a>
        <CUIcon
          v-if="!hideExpand"
          view-box="0 0 24 24"
          color="midnight-gray-500"
          class="w-24 h-24 transition-all transition-duration-300 transition-linear flex-shrink-0"
          :style="{ transform: expanded ? 'rotate(-180deg)' : '' }"
          @click.stop="$emit('update:expanded', !expanded)"
        >
          keyboard_arrow_down
        </CUIcon>
      </div>
    </div>
    <div class="margin-y-4">
      <div v-if="hasRecurrence" class="d-flex margin-b-1">
        <CUIcon
          view-box="0 -960 960 960"
          class="w-14 h-14 margin-r-2 margin-t-1"
          color="midnight-gray-500"
        >
          repeat
        </CUIcon>
        <div class="d-flex flex-column">
          <span class="text-midnight-gray-900">
            {{ trip.recurrenceTripCount }} recurring trips
          </span>
          <div class="font-12 text-midnight-gray-900">
            {{ recurrenceDatesText }}
          </div>
        </div>
      </div>
      <div
        v-for="(tripRequirement, tripRequirementsIndex) in tripRequirements"
        :key="`trip-requirement-${tripRequirementsIndex}`"
        class="d-flex align-center margin-r-2 margin-b-1"
      >
        <CUIcon
          view-box="0 0 24 24"
          class="w-20 h-20 margin-r-2"
          color="midnight-gray-500"
        >
          {{ tripRequirement.icon }}
        </CUIcon>
        <span class="text-midnight-gray-900">{{ tripRequirement.label }}</span>
        <template v-if="tripRequirement.label === 'Passengers'">
          <CUIcon viewBox="0 0 24 24" class="w-16 h-16 margin-l-1" :style="{ marginTop: '3px' }">
            close
          </CUIcon>
          <span class="text-midnight-gray-900">
            {{ trip.passengerCount }}
          </span>
        </template>
      </div>
    </div>
    <v-expand-transition>
      <div>
        <CheckoutTripDetailsVehicles :trip="trip" />

        <template v-if="areNewTripAmenitiesEnabled && amenitySupportedVehicleTypeKeys.length">
          <TripAmenityCard
            v-for="(vehicleTypeKey, vehicleTypeKeyIndex) in amenitySupportedVehicleTypeKeys"
            :key="`trip-amenity-card-${vehicleTypeKey}-${vehicleTypeKeyIndex}`"
            :amenities="tripAmenities"
            :is-self-serve="isSelfServe"
            :vehicle-type-key="vehicleTypeKey"
            appearance="minimal"
          />
        </template>
        <v-divider
          v-if="expanded"
          :class="{
            'margin-y-2': $vuetify.breakpoint.smAndDown,
            'margin-y-5': $vuetify.breakpoint.mdAndUp,
          }"
        />
        <template v-if="tripNotes && expanded">
          <h3>
            Notes
          </h3>
          <p v-html="tripNotes" />
          <v-divider
            :class="{
              'margin-y-2': $vuetify.breakpoint.smAndDown,
              'margin-y-5': $vuetify.breakpoint.mdAndUp,
            }"
          />
        </template>
      </div>
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import {
  Vue,
  Component,
  Prop,
  InjectReactive,
} from 'vue-property-decorator'
import CheckoutTripDetailsVehicles from '@/components/CheckoutTripDetailsVehicles.vue'
import TripAmenityCard from '@/components/TripAmenityCard.vue'
import { AmenityType, Trip } from '@/models/dto'
import { NoteTypeId, SplitFeatureFlag, VehicleTypeKey } from '@/utils/enum'
import { getItineraryDescription } from '@/utils/string'
import { AMENITY_CARD_SUPPORTED_VEHICLE_TYPE_KEYS } from '@/utils/amenity'

@Component({
  components: {
    CheckoutTripDetailsVehicles,
    TripAmenityCard
  },
})
export default class CheckoutTripDetails extends Vue {
  @Prop({ type: Object, required: true }) readonly trip!: Trip
  @Prop({ type: Boolean, required: false }) readonly expanded!: boolean
  @Prop({ type: Boolean, required: false, default: false })
  readonly hideExpand!: boolean

  @InjectReactive('isSelfServe') isSelfServe

  areNewTripAmenitiesEnabled = false

  get passengerCount(): number {
    return this.trip.passengerCount
  }
  get tripAmenities(): AmenityType[] {
    return this.trip.tripAmenities
  }

  get amenitySupportedVehicleTypeKeys(): VehicleTypeKey[] {
    return this.trip.vehicles
      .map( (vehicle) => vehicle.vehicleType.key)
      .filter((key) => AMENITY_CARD_SUPPORTED_VEHICLE_TYPE_KEYS.includes(key))
  }

  get isSplitTrip(): boolean {
    return this.trip?.isSplit
  }

  get tripRequirements(): { icon: string; label: string }[] {
    const passengerCount = this.passengerCount

    const possibleRequirements = [
      {
        condition: true,
        label: `Passengers`,
        icon: 'groups',
      },
      {
        condition: this.trip?.vehicleNeededEntireTrip,
        label: 'Vehicles to stay on-site',
        icon: 'bus_alert_warn',
      },
      {
        condition: this.trip?.ada,
        label: 'Accessible vehicle',
        icon: 'accessible',
      },
      {
        condition: this.trip?.spab,
        label: 'SPAB Certified Driver',
        icon: 'spab_2',
      },
    ]

    return possibleRequirements
      .filter(({ condition }) => condition)
      .map(({ label, icon }) => ({ label, icon }))
  }

  get hasRecurrence(): boolean {
    return this.trip?.hasRecurrence
  }

  get recurrenceDatesText(): string {
    const recurrence = this.trip?.recurrences?.find(
      (recurrence) => recurrence.active
    )
    return recurrence?.startDate && recurrence?.endDate
      ? `${this.$dayjs(recurrence.startDate).format(
          'MMM DD, YYYY'
        )} - ${this.$dayjs(recurrence.endDate).format('MMM DD, YYYY')}`
      : ''
  }

  get tripNotes(): string {
    if (!this.trip?.tripNotes || !this.trip?.tripNotes.length) {
      return ''
    }
    const customerNotes = this.trip?.tripNotes.find((note) => {
      return note.noteType === NoteTypeId.Customer
    })
    return customerNotes?.html || customerNotes?.note
  }

  get tripTitle(): string {
    return this.trip?.routeName
  }

  get tripDescription(): string {
    return getItineraryDescription(this.trip)
  }

  async mounted() {
    this.areNewTripAmenitiesEnabled = await this.$split.isFeatureEnabled(
      SplitFeatureFlag.NewTripAmenities
    )
  }
}
</script>
