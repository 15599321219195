<template>
  <article>
    <div class="d-flex align-center">
      <CUIcon
        class="margin-r-1"
        view-box="0 -960 960 960"
        width="24"
        color="yellow"
      >
        verified_outline
      </CUIcon>
      <h3>
        {{ $t('quoteDetail.operatorInfo.CERTIFIED_PARTNER') }}
      </h3>
    </div>
    <p>
      {{ $t('quoteDetail.operatorInfo.CERTIFIED_PARTNER_DESCRIPTION') }}
    </p>
  </article>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class ProviderInfoCertifiedPartner extends Vue {}
</script>
