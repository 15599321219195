<template>
  <p class="font-14 text-gray-subtle-text">
    {{ description }}
  </p>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { VehicleTypeKey } from '@/utils/enum'

@Component
export default class VehicleOptionDescription extends Vue {
  @Prop({ type: Array, required: true }) readonly vehicleTypeKeys: VehicleTypeKey[]

  get hasCharterBus(): boolean {
    return this.getHasVehicleType(VehicleTypeKey.CharterBus)
  }

  get hasMiniBus(): boolean {
    return this.getHasVehicleType(VehicleTypeKey.MiniBus)
  }

  get hasSprinter(): boolean {
    return this.getHasVehicleType(VehicleTypeKey.Sprinter)
  }

  get hasCombo(): boolean {
    return this.hasCharterBus && this.hasMiniBus
  }

  get description(): string {
    if (this.hasCombo) {
      return 'A combination of charter and mini buses to efficiently accommodate all passengers in your group.'
    }
    if (this.hasCharterBus) {
      return 'Spacious and luxurious charter bus provides comfort for large groups with amenities such as reclining seats, on-board bathroom, and luggage storage underneath. Ideal for long trips.'
    }
    if (this.hasMiniBus) {
      return 'Mid-sized coach with plush interior and reclining seats. Great for smaller groups and shorter trips.'
    }
    if (this.hasSprinter) {
      return 'Sprinter vans offer a compact, luxurious experience for small groups. With reclining seats and climate control, they\'re perfect for short trips and easy city navigation.'
    }
    return ''
  }

  getHasVehicleType(vehicleTypeKey: VehicleTypeKey): boolean {
    return this.vehicleTypeKeys.some((key) => {
      return key === vehicleTypeKey
    })
  }
}
</script>
