<template>
  <InfoModal
    v-model="isOpen"
    :modal-detail="tripModificationRequestModalDetail"
    :fullscreen="true"
  >
    <template #activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>

    <template #text>
      <div class="d-flex w-full flex-column">
        <TripModificationReview />
        <div
          class="d-flex w-full justify-center"
        >
          <v-btn
            class="margin-t-8 padding-x-12"
            :class="{ 'margin-b-8': $vuetify.breakpoint.smAndDown }"
            color="primary"
            @click="handleClickClose"
          >
            <div class="text-white font-bold padding-x-12 margin-y-0">
              Close
            </div>
          </v-btn>
        </div>
      </div>
    </template>
  </InfoModal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch, ProvideReactive } from 'vue-property-decorator'
import { tripModificationRequestModalDetail } from '@/utils/infoModals'
import { EventBus } from '@/utils/eventBus'
import tripModificationStore from '@/store/modules/tripModification'
import InfoModal from '@/components/InfoModal.vue'
import TripModificationReview from '@/components/TripModificationReview.vue'

@Component({
  components: {
    InfoModal,
    TripModificationReview
  },
})
export default class TripModificationRequestDialog extends Vue {
  @Prop({ type: String, required: true }) readonly managedId: string

  @ProvideReactive('removePadding') removePadding = true

  tripModificationRequestModalDetail = tripModificationRequestModalDetail
  isOpen = false

  async mounted(): Promise<void> {
    EventBus.$on('open-trip-modification-request-dialog', () => this.isOpen = true)

    if (this.managedId) {
      await tripModificationStore.setManagedId(this.managedId)
      await tripModificationStore.getPendingModification()
    }
  }

  handleClickClose(): void {
    this.isOpen = false
  }
}
</script>
