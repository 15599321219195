<template>
  <CUCard :full-width="fullWidth">
    <div class="d-flex align-start">
      <CUIcon
        view-box="0 0 24 24"
        class="cursor-default min-w-40 w-40 min-h-40 h-40 margin-r-4"
      >
        verified_user
      </CUIcon>
      <div class="d-flex flex-column">
        <h4>
          {{ $t('bookingGuarantee.TITLE') }}
        </h4>
        <p class="text-midnight-gray-900 margin-t-1">
          {{ $t('bookingGuarantee.CHECKOUT_DESCRIPTION') }}
        </p>
        <BookingProtectionDialog :is-self-serve="isSelfServe" />
      </div>
    </div>
  </CUCard>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import BookingProtectionDialog from '@/components/BookingProtectionDialog.vue'

@Component({ components: { BookingProtectionDialog } })
export default class CheckoutBookingProtectionGuarantee extends Vue {
  @Prop({ default: false }) isSelfServe!: boolean
  @Prop({ type: Boolean, required: false, default: false })
  readonly fullWidth!: boolean
}
</script>
