<template>
  <div v-if="!!$slots.notFound && serverItemsLength === 0">
    <slot name="notFound" />
  </div>
  <div
    v-else-if="items"
    :class="{
      'v-data-table__detail': isDetailTable,
      'v-data-table__editable': isEditableTable,
      'v-data-table__mobile': isMobile,
    }"
  >
    <v-data-table
      :hide-default-header="hideDefaultHeader"
      :class="{ 'v-data-table__transparent-header': transparentHeaders }"
      class="position-relative"
      :headers="columns"
      :items="items"
      :server-items-length="serverItemsLength"
      disable-sort
      :mobile-breakpoint="
        $vuetify.breakpoint.thresholds[mobileViewOnBreakpoint]
      "
      disable-filtering
      :loader-height="2"
      v-bind="$attrs"
      :options="options"
      :no-data-text="noDataText"
      :dense="dense"
      :show-select="showSelect"
      :hide-default-footer="hideDefaultFooter || isDetailTable || isMobile"
      @update:options="$emit('update:options', $event)"
    >
      <template v-if="selectColumn" #[`header.select`]="{ on, props }">
        <CUDataTableHeaderCheckbox
          :predefined-selects="selectColumn.predefinedSelects"
          :selected-items="selectedItems"
          :items="items"
          :item-key="itemKey"
          @select="$emit('select-predefined', $event)"
        />
      </template>
      <template v-if="selectColumn && (areItemsSelected || isMobile)" #header>
        <thead
          v-if="areItemsSelected"
          class="d-flex h-60 align-center"
          :class="{ 'position-absolute top-2 left-88': !isMobile }"
        >
          <CUDataTableHeaderActionRow :actions="selectColumn.actions" />
        </thead>
        <CUDataTableMobilePredefinedSelects
          v-if="isMobile"
          :select-column="selectColumn"
          @select="$emit('select-predefined', $event)"
        />
      </template>
      <template v-for="(col, index) in headerColumns" #[col.headerName]="props">
        <thead
          :key="`header-${col.slotName}-${index}`"
          :class="{
            'opacity-0': areItemsSelected,
            'opacity-100': !areItemsSelected,
          }"
          :style="areItemsSelected ? 'pointer-events: none;' : ''"
          class="transition-all transition-duration-150 transition-ease-in-out"
        >
          <th class="text-start" :style="{ 'border-bottom': 0 }">
            <span v-if="col.headerComponent">
              <component :is="col.headerComponent" />
            </span>
            <span
              v-else-if="!!props"
              class="d-flex"
              :class="{ 'text-transparent': areItemsSelected }"
              :style="[areItemsSelected ? 'opacity: 0;' : '']"
            >
              {{ props.header.text }}
            </span>
          </th>
        </thead>
      </template>
      <template v-if="!isMobile" #item="{ item, index }">
        <tr :class="{ 'cursor-pointer': !!rowClickHandler }">
          <td
            v-for="(col, colIndex) in columns"
            :key="`column-${col.value}-${colIndex}-${index}`"
            :class="col.classes || ''"
            @click="handleRowClick(col, item, index)"
          >
            <CUDataTableCell
              v-if="!hideCell(col, item)"
              :key="`data-table-cell-${col.value}-${colIndex}-${index}`"
              :column="col"
              :row="item"
              :row-index="index"
              :actions="actions"
              :select-column="selectColumn"
              :selected-items="selectedItems"
              :is-mobile="false"
              :is-detail-table="isDetailTable"
              :display-actions-on-mobile="displayActionsOnMobile"
              :detail-name="detailName"
              :item-key="itemKey"
              :table-element-id="tableElementId"
              v-on="$listeners"
              @refresh="$emit('refresh')"
              @select="$emit('select', $event)"
            />
          </td>
        </tr>
      </template>
      <template v-else-if="!!$scopedSlots.mobileLayout" #item="{ item, index }">
        <slot name="mobileLayout" :item="item" :index="index" />
      </template>
      <template v-else #item="{ item, index }">
        <div
          class="d-flex border-solid border-gray-mid-light padding-a-3 border-radius-8 margin-y-3 border-1"
          :class="{
            'padding-x-3': isDetailTable,
            'flex-row flex-wrap': isEditableTable,
            'flex-column': !isEditableTable,
            'border-primary': isItemSelected(item),
          }"
        >
          <div
            v-for="(col, colIndex) in columns"
            :key="`column-${col.value}-${colIndex}-${index}`"
            :class="{
              'flex-basis-48-percent margin-x-1-percent':
                isEditableTable && col.type !== 'actions',
              'flex-basis-full': isEditableTable && col.type === 'actions',
              'cursor-pointer': !!rowClickHandler,
            }"
            @click="handleRowClick(col, item, index)"
          >
            <CUDataTableCell
              v-if="!hideCell(col, item)"
              :column="col"
              :row="item"
              :row-index="index"
              :actions="actions"
              :select-column="selectColumn"
              :selected-items="selectedItems"
              :is-detail-table="isDetailTable"
              is-mobile
              :detail-name="detailName"
              :display-actions-on-mobile="displayActionsOnMobile"
              :item-key="itemKey"
              v-on="$listeners"
              @refresh="$emit('refresh')"
              @select="$emit('select', $event)"
            />
          </div>
        </div>
      </template>
      <v-pagination
        v-if="!isDetailTable"
        v-model="options.page"
        :length="serverItemsLength"
      />
    </v-data-table>
    <v-pagination
      v-if="isMobile && serverItemsLength >= 1"
      :value="options.page"
      :length="Math.ceil(serverItemsLength / options.itemsPerPage)"
      :total-visible="5"
      @input="updateOptionsPage($event)"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUDataTableActionColumn from '@/components/CUDataTableActionColumn.vue'
import CUDataTableHeaderCheckbox from '@/components/CUDataTableHeaderCheckbox.vue'
import CUDataTableHeaderActionRow from '@/components/CUDataTableHeaderActionRow.vue'
import CUDataTableMobilePredefinedSelects from '@/components/CUDataTableMobilePredefinedSelects.vue'
import { ActionColumn } from '@/models/ActionColumn'
import CUDataTableCell from '@/components/CUDataTableCell.vue'
import { DataTableColumn } from '@/models/DataTableColumn'
import { TableViewParameters } from '@/models/TableView'
import deepClone from '@/utils/deepClone'
import { SelectColumn } from '@/models/SelectColumn'

@Component({
  components: {
    CUDataTableActionColumn,
    CUDataTableCell,
    CUDataTableHeaderCheckbox,
    CUDataTableHeaderActionRow,
    CUDataTableMobilePredefinedSelects,
  },
})
export default class CUDataTable extends Vue {
  @Prop({ type: Array, required: false, default: undefined })
  actions!: ActionColumn[]
  @Prop({ type: Array, required: false, default: undefined }) items!: any[]
  @Prop({ type: Array, required: false, default: undefined })
  selectedItems: unknown[]
  @Prop({ type: Object, required: false, default: () => {} })
  selectColumn!: SelectColumn
  @Prop({ type: Array, required: true, default: [] })
  columns!: DataTableColumn[]
  @Prop({ type: Number, required: true }) serverItemsLength!: number
  @Prop({ type: Object, required: true }) options!: TableViewParameters
  @Prop({ type: Boolean, default: false }) isDetailTable!: boolean
  @Prop({ type: String, required: false }) detailName!: string
  @Prop({ type: Boolean, required: false }) isEditableTable!: boolean
  @Prop({ type: String, required: false }) itemKey!: string
  @Prop({ type: String, required: false, default: 'xs' })
  mobileViewOnBreakpoint!: string
  @Prop({ type: Boolean, required: false }) hideDefaultHeader!: boolean
  @Prop({ type: Boolean, required: false }) displayActionsOnMobile!: boolean
  @Prop({ type: String, required: false, default: 'No data found' })
  noDataText!: string
  @Prop({ type: Boolean, required: false, default: false })
  transparentHeaders!: boolean
  @Prop({ type: String, required: false, default: 'data-table' })
  tableElementId!: string
  @Prop({ type: String, required: false, default: '' }) uuid!: string
  @Prop({ type: Boolean, required: false }) dense!: boolean
  @Prop({ type: Boolean, required: false }) showSelect!: boolean
  @Prop({ type: Boolean, required: false }) hideDefaultFooter!: boolean
  @Prop(Function) rowClickHandler: (row: any, index: number) => any

  get isMobile(): boolean {
    switch (this.mobileViewOnBreakpoint) {
      case 'xs':
        return this.$vuetify.breakpoint.xs
      case 'sm':
        return this.$vuetify.breakpoint.smAndDown
      case 'md':
        return this.$vuetify.breakpoint.mdAndDown
      case 'lg':
        return this.$vuetify.breakpoint.lgAndDown
      default:
        return false
    }
  }

  get headerColumns(): any[] {
    return this.columns
      .filter((col) => col.value !== 'select')
      .map((col) => ({
        ...col,
        slotName: `header.${col.value}`,
        headerName: `header.${col.value}`,
      }))
  }

  get areItemsSelected(): boolean {
    return !!this.selectedItems?.length
  }

  hideCell(col, item): boolean {
    if (col.hideCell) {
      return col.hideCell(item)
    }
    return false
  }

  handleRowClick(col: DataTableColumn, item: any, index: number): void {
    if (['actions', 'select'].includes(col.type) || !this.rowClickHandler) {
      return
    }
    this.rowClickHandler(item, index)
  }

  updateOptionsPage(pageNumber: number): void {
    const options = deepClone(this.options)
    options.page = pageNumber
    this.$emit('update:options', options)
  }

  isItemSelected(item: any): boolean {
    return !!this.selectedItems?.includes(item[this.itemKey])
  }
}
</script>
