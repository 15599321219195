<template>
  <div class="font-16">
    <div class="d-flex align-center">
      <p class="font-bold mr-2">{{ pickupDropoffText }}</p>
      <p>ID {{ managedId }}</p>
    </div>
    <div class="d-flex align-center mt-1">
      <p class="ma-0">{{ routeName }}</p>
    </div>
    <div class="d-flex align-center mt-1">
      <CUIcon class="mr-1" width="18" height="18" view-box="0 0 24 24">
        event
      </CUIcon>
      <p class="ma-0">{{ tripDates }}</p>
    </div>
    <div class="d-flex align-center mt-1">
      <CUIcon class="mr-1" width="18" height="18" view-box="0 0 24 24">
        directions_bus
      </CUIcon>
      <p class="ma-0">{{ requiredVehicles }}</p>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ReservationDetail } from '@/models/dto'
import {
  pluralize,
  formatReservationPickupDestinationText,
} from '@/utils/string'

@Component({})
export default class ReservationCancellationSummaryTripInfo extends Vue {
  @Prop({ type: Object, required: true })
  readonly reservation!: ReservationDetail

  get managedId(): string {
    return this.reservation?.managedId
  }

  get pickupDropoffText(): string {
    if (!this.reservation) {
      return ''
    }
    return formatReservationPickupDestinationText(
      this.reservation,
      this.$t('common.TO')
    )
  }

  get routeName(): string {
    return this.reservation?.routeName
  }

  get firstPickupDate(): string {
    return this.$dayjs(this.reservation?.pickupDate)
      .tz(this.reservation.stops?.[0]?.address.timeZone)
      .format('MM/DD/YY')
  }

  get lastDropoffDate(): string {
    const stops = this.reservation?.stops
    const lastStop = stops[stops.length - 1]
    return this.$dayjs(lastStop?.dropoffDatetime)
      .tz(lastStop?.address?.timeZone)
      .format('MM/DD/YY')
  }

  get tripDates(): string {
    if (this.firstPickupDate && this.lastDropoffDate && this.firstPickupDate != this.lastDropoffDate) {
      return `${this.firstPickupDate} - ${this.lastDropoffDate}`
    }
    return this.firstPickupDate
  }

  get requiredVehicles(): string {
    return this.reservation?.requiredVehicles.map(requiredVehicle => {
        const quantity = requiredVehicle.quantity
        const label = requiredVehicle.vehicleType.label
        return `${quantity} ${pluralize(quantity, label)}`
      }
    ).join(', ')
  }

}
</script>
