<template>
  <v-menu
    v-model="teamOptionsMenuOpen"
    offset-y
    :close-on-click="false"
    internal-activator
  >
    <template #activator="{ on, attrs }">
      <v-icon
        :id="`organization-menu-team-${index}`"
        class="kebab-button margin-t-n1"
        :class="{ 'margin-r-n5': $vuetify.breakpoint.smAndDown }"
        v-bind="attrs"
        v-on="on"
      >
        more_vert
      </v-icon>
    </template>
    <v-list class="kebab-dropdown padding-a-0">
      <OrganizationInviteUserTeamDialog
        v-if="$vuetify.breakpoint.xsOnly"
        :id="`organization-add-user-${index}`"
        :teams="teams"
        :starting-team="team"
        display-in-list
      />
      <OrganizationEditTeamDialog
        :id="`organization-edit-team-${index}`"
        :team="team"
        :index="index"
        @upload-image="$emit('upload-image', $event)"
        @success="$emit('success')"
        @close="teamOptionsMenuOpen = false"
      />
      <OrganizationDeleteTeamDialog
        :id="`organization-delete-team-${index}`"
        :index="index"
        :team="team"
        @success="$emit('success')"
        @close="teamOptionsMenuOpen = false"
      />
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import OrganizationEditTeamDialog from '@/components/OrganizationEditTeamDialog.vue'
import OrganizationDeleteTeamDialog from '@/components/OrganizationDeleteTeamDialog.vue'
import OrganizationInviteUserTeamDialog from '@/components/OrganizationInviteUserTeamDialog.vue'
import { Team } from '@/models/dto'
@Component({
  components: {
    OrganizationEditTeamDialog,
    OrganizationDeleteTeamDialog,
    OrganizationInviteUserTeamDialog,
  },
})
export default class OrganizationTeamActionsMenu extends Vue {
  @Prop({ type: Object, required: true }) readonly team: Team
  @Prop({ type: Array, required: true }) readonly teams: Team[]
  @Prop({ type: Number, required: true }) readonly index: number

  teamOptionsMenuOpen = false
}
</script>

<style lang="scss">
@import '@/scss/colors.scss';
.v-list {
  &.kebab-dropdown {
    .v-list-item {
      cursor: pointer;
      &:hover {
        background: $border-gray;
      }
      .v-list-item__title {
        font-size: 0.875rem;
        font-family: 'Inter Medium', Arial, sans-serif;
      }
    }
  }
}
</style>
