<template>
  <CUModal :value="value" max-width="518px" @input="$emit('input', false)">
    <template #title>Rider Historical Data</template>
    <template #text>
      <CUDataTable
        id="rider-historical-data-table"
        ref="parentRiderTable"
        :items="items"
        :columns="columns"
        :options.sync="tableOptions"
        :server-items-length="itemsLength"
        mobile-view-on-breakpoint="lg"
        :loading="loading"
        class="rider-data-table"
        item-key="scheduledDate"
        :hide-default-footer="itemsLength <= tableOptions.itemsPerPage"
        :hide-default-header="$vuetify.breakpoint.lg"
        v-on="$listeners"
        @update:options="updateItems"
      >
        <template #mobileLayout="{ item }">
          <tr>
            <td class="padding-t-4 padding-b-6 padding-r-8">
              <v-row class="padding-t-2">
                <b class="margin-r-1">Route Name:</b>
                {{ item.routeName }}
              </v-row>
              <v-row>
                <b class="margin-r-1">Scheduled Date:</b>
                {{ item.scheduledDate }}
              </v-row>
              <v-row>
                <div>
                  <b class="margin-r-1">Pickup:</b>
                  {{ item.pickupLocation }}
                </div>
              </v-row>
              <v-row>
                <div>
                  <b class="margin-r-1">Dropoff:</b>
                  {{ item.dropoffLocation }}
                </div>
              </v-row>
              <v-row>
                <b class="margin-r-1">Status:</b>
                {{ item.status }}
              </v-row>
            </td>
          </tr>
        </template>
      </CUDataTable>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import QrcodeVue from 'qrcode.vue'
import { TableViewRider } from '@/models/dto/TableViewRider'
import rider from '@/services/rider'
import { DataTableColumn } from '@/models/DataTableColumn'
import { DataOptions } from 'vuetify'

@Component({ components: { QrcodeVue } })
export default class RiderHistoricalDataModal extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean
  @Prop({ type: Object }) readonly rider: TableViewRider

  loading = false
  items = []
  itemsLength = 0
  tableOptions: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortBy: [],
    sortDesc: [],
  }
  columns: DataTableColumn[] = [
    {
      _t_id: '47600b02-de8c-43fb-a401-dccb51c43c87',
      elementId: 'pickupLocation',
      text: 'Pickup',
      value: 'pickupLocation',
      type: 'text',
    },
    {
      _t_id: '5cd18ce0-a5ac-4f97-bb78-d00d76be212a',
      elementId: 'dropoffLocation',
      text: 'Drop-off',
      value: 'dropoffLocation',
      type: 'text',
    },
    {
      _t_id: 'f6338d63-9125-4f61-afbe-07a01a4f22f7',
      elementId: 'createdOn',
      text: 'Created On',
      value: 'createdOn',
      type: 'text',
      computedText: (row: TableViewRider) =>
        this.formattedDatetime(row.createdOn),
    },
    {
      _t_id: '99d4adcc-a136-4127-9167-d6fe695b5d53',
      elementId: 'boardedOn',
      text: 'Boarded On',
      value: 'boardedOn',
      type: 'text',
      computedText: (row: TableViewRider) =>
        this.formattedDatetime(row.boardedOn),
    },
    {
      _t_id: '86ea64fd-21f4-4dec-902b-65ffccc5fe56',
      elementId: 'releasedOn',
      text: 'Released On',
      value: 'releasedOn',
      type: 'text',
      computedText: (row: TableViewRider) =>
        this.formattedDatetime(row.releasedOn),
    },
    {
      _t_id: 'ed243411-e20c-41ee-8358-e6f60fb5e198',
      elementId: 'status',
      text: 'Status',
      value: 'status',
      type: 'text',
      classes: 'font-medium',
    },
  ]

  @Watch('rider', { deep: true })
  async riderChanged(): Promise<void> {
    this.loading = true
    this.items = []
    this.itemsLength = 0
    await this.updateItems()
    this.loading = false
  }

  async updateItems(): Promise<void> {
    if (this.rider?.riderId) {
      const res = (await rider.getHistoricalData(this.rider.riderId)).data
        .riderRoutes
      this.items = res.slice(
        (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
        this.tableOptions.page * this.tableOptions.itemsPerPage
      )
      this.itemsLength = res.length
    }
  }

  formattedDatetime(datetime): string {
    return datetime ? this.$dayjs(datetime).format('MM/DD/YYYY h:mm A z') : ''
  }

  close(): void {
    this.$emit('input', false)
  }
}
</script>
