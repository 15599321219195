import axios, { AxiosResponse } from 'axios'
import { baseUrl } from '@/utils/env'
import { HttpService } from './common/HttpService'
import {
  ApiResult,
  AuthPaymentResult,
  CheckoutMetaResult,
  ConfirmQuoteResult,
  ConvertedByCompanyResult,
  FinanceTableViewQuote,
  Quote,
  QuoteConvertResult,
  CheckoutDetailResult,
  QuoteResult,
  SalesBotQuote,
  SharedListResult,
  TableViewParameters,
  TableViewQuote,
  TableViewResult,
  TripEstimationRequest,
  TripEstimationResponse,
  UnreadQuoteResult,
  CheckoutDetailQuote,
  GenericApiResult,
  SimpleContract,
} from '@/models/dto'
import { JSONValue } from '@/models/JSONValue'
import { QuoteDetailV2 } from '../models/dto/QuoteDetailV2'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Gets a table view of quotes based on the given parameters.
   *
   * @param params - The parameters to use for filtering, sorting, and pagination of the table view.
   * @returns A promise that resolves to the table view result containing a count and list of quotes.
   */
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<TableViewQuote>>> {
    const { filters = null, sorts = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/quotes/customer?${query}`
    return httpService.get(url)
  },
  /**
   * Retrieves the quote with the given ID.
   *
   * @param params - The parameters to use for filtering, sorting, and pagination of the table view.
   * @returns A promise that resolves to the table view results.
   */
  financeAdminTableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<FinanceTableViewQuote>>> {
    const { filters = null, sorts = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/quotes?${query}`
    return httpService.get(url)
  },
  /**
   *
   * @param quoteId - The ID of the quote for with to retrieve the payment metadata.
   * @returns - A promise that resolves to the payment metadata for the quote with the given ID.
   */
  async checkoutPaymentMetaById(quoteId: number): Promise<JSONValue> {
    const url = `https://${baseUrl()}/transaction/metaByQuoteId/${quoteId}`
    const response: AxiosResponse<CheckoutMetaResult> = await httpService.get(
      url
    )
    return JSON.parse(response.data.meta)
  },
  /**
   *
   * @param quoteId - The ID of the quote for with to retrieve the auth payment metadata.
   * @returns - A promise that resolves to the auth payment for the quote with the given ID.
   */
  getAuthPaymentsById(
    quoteId: number
  ): Promise<AxiosResponse<AuthPaymentResult>> {
    const url = `https://${baseUrl()}/authPayment/${quoteId}`
    return httpService.get(url)
  },
  /**
   * Retrieves the quote for a given quote ID.
   * @param quoteId - The quote ID to retrieve the quote for.
   * @returns A promise that resolves to the quote for the specified quote ID.
   */
  byId(quoteId: number): Promise<AxiosResponse<QuoteResult>> {
    const url = `https://${baseUrl()}/v2/quotes/${quoteId}`
    return httpService.get(url)
  },
  /**
   * Retrieves the quote for a given quote ID.
   * @param quoteId - The quote ID to retrieve the quote for.
   * @returns A promise that resolves to the quote for the specified quote ID.
   */
  quoteInvoicePDFByHashAndCompanyId(quoteHash: string, companyId: number): Promise<AxiosResponse<string>> {
    const url = `https://${baseUrl('pdf')}/pdf/quote/invoice/${quoteHash}/${companyId}`;
    return httpService.get(url)
  },
  /**
   * Retrieves the V2 quote detail for a given quote ID.
   * @param quoteId - The quote ID to retrieve the V2 quote detail for.
   * @param markAsSeen - A boolean that denotes whether to mark the quote as seen. Defaults to true.
   * @returns A promise that resolves to the V2 quote detail for the specified quote ID.
   */
  detailV2(
    quoteId: number,
    markAsSeen = true
  ): Promise<AxiosResponse<GenericApiResult<QuoteDetailV2>>> {
    let url = `https://${baseUrl()}/quoteDetail/v2/${quoteId}`
    if (markAsSeen) {
      url = `${url}?markAsSeen=${markAsSeen}`
    }
    return httpService.get(url)
  },

  /**
   * Retrieves the quote for a given quote hash.
   * @param hash - The quote hash to retrieve the quote for.
   * @returns A promise that resolves to the quote for the specified quote hash.
   */
  byHashV2(
    hash: string
  ): Promise<AxiosResponse<GenericApiResult<QuoteDetailV2>>> {
    const url = `https://${baseUrl()}/quoteDetail/v2/hash/${hash}`
    return httpService.get(url)
  },

  /**
   * Retrieves the checkout detail for a given quote ID and providerID.
   * @param quoteId - The quote ID to retrieve the quote detail for.
   * @param providerId - The provider ID to retrieve the quote detail for.
   * @returns A promise that resolves to the quote checkout for the specified quote ID and provider ID.
   */
  checkoutDetail(
    quoteId: number,
    providerId: number,
    disputedCheckout = false
  ): Promise<AxiosResponse<CheckoutDetailResult>> {
    let query = ''
    if (disputedCheckout) {
      query = `?disputedCheckout=${disputedCheckout}`
    }
    const url = `https://${baseUrl()}/checkoutDetail/${quoteId}/${providerId}${query}`
    return httpService.get(url)
  },

  /**
   * Retrieves the checkout detail for a given self serve quote ID.
   * @param quoteId - The quote ID to retrieve the quote detail for.
   * @param markAsSeen - A boolean that denotes whether to mark the quote as seen. Defaults to true.
   * @returns A promise that resolves to the quote checkout for the specified quote ID.
   */
  selfServeCheckoutDetail(
    quoteId: number,
    markAsSeen = true
  ): Promise<AxiosResponse<CheckoutDetailResult>> {
    let url = `https://${baseUrl()}/checkoutDetail/selfServe/${quoteId}`
    if (markAsSeen) {
      url = `${url}?markAsSeen=${markAsSeen}`
    }
    return httpService.get(url)
  },

  /**
   * Retrieves the guest checkout detail for a given quote hash.
   * @param quoteHash - The quote hash to retrieve the quote for.
   * @param markAsSeen - A boolean that denotes whether to mark the quote as seen. Defaults to true.
   * @returns A promise that resolves to the quote checkout for the specified quote hash.
   */
  guestCheckoutDetail(
    quoteHash: string,
    markAsSeen = true
  ): Promise<AxiosResponse<CheckoutDetailResult>> {
    let url = `https://${baseUrl()}/checkoutDetail/guest/${quoteHash}`
    if (markAsSeen) {
      url = `${url}?markAsSeen=${markAsSeen}`
    }
    return httpService.get(url)
  },

  /**
   * Shares the quote with the given ID with the user with the given user ID.
   *
   * @param quoteId - The ID of the quote to share.
   * @param userId - The ID of the user to share the quote with.
   * @returns A promise that resolves to a standard API result.
   */
  share(quoteId: number, userId: number): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${baseUrl()}/quotes/${quoteId}/share/${userId}`
    return httpService.post(url, null)
  },
  /**
   * Unshares the quote with the given ID with the user with the given user ID.
   *
   * @param quoteId - The ID of the quote to unshare.
   * @param userId - The ID of the user to unshare the quote with.
   * @returns A promise that resolves to a standard API result.
   */
  unshare(quoteId: number, userId: number): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${baseUrl()}/quotes/${quoteId}/unshare/${userId}`
    return httpService.post(url, null)
  },
  /**
   * Retrieves the list of users that the quote with the given ID is shared with.
   * @param quoteId - The ID of the quote to retrieve the shared list for.
   * @returns A promise that resolves to the list of users that the quote with the given ID is shared with.
   */
  sharedList(quoteId: number): Promise<AxiosResponse<SharedListResult>> {
    const url = `https://${baseUrl()}/quotes/${quoteId}/sharedList`
    return httpService.get(url)
  },
  /**
   * Retrieves a count of the number of active quotes that have not been seen by the current user.
   * @returns A promise that resolves to the count of the number of active quotes that have not been seen by the current user.
   */
  async count(): Promise<number> {
    const url = `https://${baseUrl()}/quotes/count`
    const quoteCountResponse: AxiosResponse<UnreadQuoteResult> = await httpService.get(
      url
    )
    return quoteCountResponse?.data?.unreadQuoteCount
  },
  /**
   * Converts the quote with the given ID to a reservation, collecting the payment for the quote.
   * @param quote - The quote to convert to a reservation.
   * @param paymentInfo - The payment information to use for the payment.
   * @param paymentMethodData - The payment method data to use for the payment.
   * @param bidIds - The IDs of the bids to convert.
   * @param paymentProfileId - the id of the payment profile to use for the payment.
   * @param showOnCharterUp - A boolean that denotes whether to show the reservation on CharterUp.
   *
   * @returns A promise that resolves to a standard API response including the reservationIds that were created.
   */
  async convert(
    quote: Quote | CheckoutDetailQuote,
    paymentInfo: any = null,
    paymentMethodData: any = {},
    bidIds: number[] = [],
    paymentProfileId: number = null,
    showOnCharterUp = false
  ): Promise<AxiosResponse<QuoteConvertResult>> {
    let payload

    if (!quote) {
      throw new Error('Missing parameters in convert()')
    }

    if (paymentMethodData) {
      delete paymentMethodData?.securityCode
      delete paymentMethodData?.expirationMonth
      delete paymentMethodData?.expirationYear
      delete paymentMethodData?.cardNumber
    }

    // Either we're dealing with manual conversion of a quote when paying after services or we're dealing with checkout. Whether we have a token should be a good indicator.
    if (paymentProfileId) {
      payload = {
        paymentProfileId,
        payment_method: paymentMethodData.activeMethod,
        billing: paymentMethodData,
        payment_gateway: paymentInfo,
        bidIds,
        quoteConvertType: 'charterup',
      }
    } else if (paymentInfo) {
      payload = {
        nonces: paymentInfo.tokens,
        payment_method: paymentMethodData.activeMethod,
        billing: paymentMethodData,
        payment_gateway: paymentInfo.paymentGateway,
        bidIds,
        quoteConvertType: 'charterup',
      }
    } else {
      payload = {
        meta: {
          notes: 'Manual conversion',
        },
      }
    }

    const url = `https://${baseUrl()}/quotes/${
      quote.quoteId
    }/convert?showOnCharterUp=${showOnCharterUp}&doneByCustomer=true`

    return await axios.post(url, payload)
  },
  /**
   * Confirms the itinerary details of a quote with the given quote ID.
   * @param quoteId - The quote ID to confirm.
   * @returns A promise that resolves to the result of the confirmation.
   */
  confirm(quoteId: number): Promise<AxiosResponse<ConfirmQuoteResult>> {
    const url = `https://${baseUrl()}/quotes/${quoteId}/confirm`
    return httpService.get(url)
  },
  /**
   * Gets the company that was selected to converted a quote with the given trip ID.
   * @param tripId - The trip ID to get the converted company for.
   * @returns A promise that resolves to the result containing the converted company.
   */
  convertedByCompany(
    tripId: number
  ): Promise<AxiosResponse<ConvertedByCompanyResult>> {
    const url = `https://${baseUrl()}/quotes/getConvertedCompany/${tripId}`
    return httpService.get(url)
  },
  /**
   * Gets the trip estimation for the given self-serve quote.
   * @param quote - The self-serve quote to get the trip estimation for.
   * @returns A promise that resolves to the result containing the trip estimation.
   */
  tripEstimation(
    quote: SalesBotQuote | TripEstimationRequest
  ): Promise<AxiosResponse<TripEstimationResponse[]>> {
    const url = `https://${baseUrl()}/tripEstimations`
    return httpService.post(url, quote)
  },
  /**
   * Gets the trip estimation for the given self-serve quote using the endpoint that doesn't require authentication.
   * @param quote - The self-serve quote to get the trip estimation for.
   * @returns A promise that resolves to the result containing the trip estimation.
   */
  tripEstimationPublic(
    quote: SalesBotQuote | TripEstimationRequest
  ): Promise<AxiosResponse<TripEstimationResponse[]>> {
    const url = `https://${baseUrl()}/tripEstimations/selfServe`
    return httpService.post(url, quote)
  },
  /**
   * Finds contract ids by customer account id.
   * @param customerAccountId - The user's customer account id.
   * @returns A list of contract ids as an API result.
   */
  getContractsByCustomerAccountId(
    customerAccountId: number
  ): Promise<AxiosResponse<Array<SimpleContract>>> {
    const url = `https://${baseUrl()}/charterup/v1/customer-accounts/${customerAccountId}/contracts`
    return httpService.get(url)
  },
}
