<template>
  <v-footer
    id="sales-bot-footer"
    class="background-blue-dark"
    :class="{
      'padding-x-18': $vuetify.breakpoint.mdAndUp,
      'padding-x-5': $vuetify.breakpoint.smAndDown,
      'padding-y-2': $vuetify.breakpoint.xs,
      'padding-y-3': $vuetify.breakpoint.sm,
      'padding-y-20': $vuetify.breakpoint.mdAndUp && padded,
      'padding-y-4': $vuetify.breakpoint.mdAndUp && !padded,
    }"
  >
    <!-- Mobile footer -->
    <template v-if="$vuetify.breakpoint.smAndDown">
      <div class="w-full d-flex align-center justify-space-between min-h-33">
        <div class="d-flex flex-wrap">
          <p class="font-bold text-white margin-t-0 margin-r-1">
            The CharterUP Booking Advantage:
          </p>
          <p class="text-white margin-t-0">Why do customers choose CharterUP?</p>
        </div>
        <CUIcon
          v-ripple="false"
          class="footer-expansion-arrow w-40 h-40 flex-shrink-0"
          :class="isExpanded ? 'flipped' : ''"
          color="blue"
          view-box="0 0 24 24"
          @click="isExpanded = !isExpanded"
        >
          keyboard_arrow_down
        </CUIcon>
      </div>
      <v-expand-transition>
        <div v-if="isExpanded" class="padding-y-6">
          <SalesBotFooterValueProp
            v-for="valuePropKey in valuePropKeys"
            :key="valuePropKey"
            :value-prop-key="valuePropKey"
          />
        </div>
      </v-expand-transition>
    </template>

    <!-- Desktop footer -->
    <template v-else>
      <div class="d-flex flex-wrap justify-center align-center margin-x-auto" :style="{ gap: '40px' }">
        <div class="flex-shrink-0">
          <p class="font-22 font-bold text-white margin-t-0">
            The CharterUP Booking Advantage
          </p>
          <p class="text-white">Why do customers choose CharterUP?</p>
        </div>
        <div class="d-flex" :style="{ flex: 1 }">
          <div
            v-for="(valuePropKey, index) in valuePropKeys"
            :key="valuePropKey"
            class="d-flex"
          >
            <div v-if="index !== 0" class="w-1 background-cup-500 opacity-20 margin-x-1" />
            <CharterUPValueProp
              :page="page"
              :value-prop-key="valuePropKey"
            />
          </div>
        </div>
      </div>
    </template>
  </v-footer>

</template>

<script setup lang="ts">
import { ref } from "vue";
import { Page, ValuePropKey } from "@/utils/enum";
import CharterUPValueProp from "./CharterUPValueProp.vue";
import SalesBotFooterValueProp from "./SalesBotFooterValueProp.vue";

defineProps({
  padded: {
    type: Boolean,
    default: false
  }
})

const isExpanded = ref(false);

const page = Page.SalesBot;
const valuePropKeys: ValuePropKey[] = [
  ValuePropKey.BookingProtection,
  ValuePropKey.Tracking,
  ValuePropKey.LiveSupport,
  ValuePropKey.QualifiedOperators,
];
</script>

<style lang="scss" scoped>
.theme--light.v-icon:focus::after {
  display: none;
}

.footer-expansion-arrow {
  transform: rotate(-90deg);
  transition: transform 0.2s linear;

  &.flipped {
    transform: rotate(-180deg);
    transition: transform 0.2s linear;
  }
}
</style>
