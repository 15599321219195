<template>
  <div class="text-center position-absolute z-1 right-25 bottom-10">
    <v-dialog
      :value="value"
      :content-class="$vuetify.breakpoint.xs ? 'fullscreen-dialog' : ''"
      fullscreen
      width="600"
      @input="$emit('input', $event)"
    >
      <v-card tile class="margin-t-5">
        <v-row
          class="text-gray"
          :style="{ 'padding-top': '10px', 'margin-right': '10px' }"
        >
          <v-spacer />
          <v-btn
            id="provider-button-close-buttons"
            color="gray"
            icon
            small
            @click="$emit('input', false)"
          >
            <CUIcon view-box="0 0 24 24">
              close
            </CUIcon>
          </v-btn>
        </v-row>
        <Carousel
          :value="index"
          class="margin-t-5"
          :photos="photos"
          @input="$emit('update:index', $event)"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import Carousel from '@/components/Carousel.vue'
import { VehiclePhoto } from '@/models/dto'

@Component({ components: { Carousel } })
export default class ProviderInfoVehiclePhotoCarousel extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value!: boolean
  @Prop({ type: Number, required: true }) readonly index!: number
  @Prop({ type: Array, required: true }) readonly photos!: VehiclePhoto[]
}
</script>
