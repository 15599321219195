var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('InfoModal',{attrs:{"modal-detail":_vm.valuePropDetail,"max-width":"860px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center h-full font-16 font-medium",class:{
        'padding-x-4 padding-y-2': _vm.isSalesBot,
        'padding-a-4': !_vm.isSalesBot,
      },on:{"click":_vm.handleOpen}},'div',attrs,false),on),[_c('CUIcon',{staticClass:"flex-shrink-0",class:{
          'w-40 h-40 text-cup-500': _vm.isSalesBot,
          'w-32 h-32': !_vm.isSalesBot,
        },attrs:{"view-box":"0 0 24 24"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]),_c('span',{staticClass:"margin-l-2",class:{ 'text-white font-14 font-weight-600': _vm.isSalesBot }},[_vm._v(" "+_vm._s(_vm.label)+" ")])],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}})
}
var staticRenderFns = []

export { render, staticRenderFns }