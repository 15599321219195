<template>
  <div id="cu-time-picker-list">
    <label v-if="label">
      {{ label }}
    </label>
    <v-menu
      v-model="isOpen"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      :left="$vuetify.breakpoint.xsOnly"
      origin="top center 0"
      min-width="160px"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :id="id"
          :value="inputDisplayText"
          readonly
          :rules="rules"
          :hide-details="hideDetails"
          outlined
          :placeholder="placeholder"
          v-bind="attrs"
          v-on="on"
          append-icon="mdi-chevron-down"
          @click:append="on.click"
        />
      </template>
      <v-list class="max-h-500 background-white">
        <v-list-item
          v-for="(timeOption, timeOptionIndex) in timeOptions"
          :id="`time-picker-option-${timeOption.value.replace(':', '-')}`"
          :key="`time-picker-option-${timeOption.value}-${timeOptionIndex}`"
          dense
          @click="onInput(timeOption.value)"
        >
          <v-list-item-title>{{ timeOption.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, defineProps, defineEmits, withDefaults } from 'vue'
import { convertHours24To12, isAMOrPM } from '@/utils/datetime'
import { DateTime } from 'luxon'

const HOURS_IN_DAY = 24
const MINUTES_IN_HOUR = 60

interface Props {
  /** Label for the time picker */
  label?: string

  /** Placeholder text for the input field */
  placeholder?: string

  /** ID for the input field */
  id?: string

  /** Validation rules for the input */
  rules?: Array<(value: any) => boolean | string>

  /** Whether to hide details below the input field */
  hideDetails?: boolean

  /** The currently selected time value, formatted as HH:mm (24-hour format) */
  value: string

  /** The display value shown in the input field */
  displayValue?: string

  /**
   * Interval in minutes for selectable times (e.g., 15 for 15-minute intervals)
   * Defaults to 15.
  */
  intervalMinutes?: number

  /**
   * Minimum allowed time, formatted as HH:mm:ss.SSS (24-hour format).
   * Defaults to "00:00:00.000".
   */
  minTime?: string

  /**
   * Maximum allowed time, formatted as HH:mm:ss.SSS (24-hour format).
   * Defaults to "23:59:59.999".
   */
  maxTime?: string
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  placeholder: '',
  id: '',
  rules: () => [],
  hideDetails: false,
  intervalMinutes: 15,
  minTime: '00:00:00.000',
  maxTime: '23:59:59.999',
})

const emit = defineEmits(['input'])

const isOpen = ref(false)

const minDateTime = computed(() => props.minTime ? DateTime.fromISO(props.minTime) : null)
const maxDateTime = computed(() => props.maxTime ? DateTime.fromISO(props.maxTime) : null)

const inputDisplayText = computed(() => props.displayValue || props.value)
const timeOptions = computed(() => {
  const options = []
  for (let hour = 0; hour < HOURS_IN_DAY; hour++) {
    for (let minute = 0; minute < 60; minute += props.intervalMinutes) {
      const formattedHour = hour.toString().padStart(2, '0')
      const formattedMinute = minute.toString().padStart(2, '0')

      // Create a DateTime object for filtering
      const currentTime = DateTime.fromObject({ hour, minute })

      // Check bounds
      if (
        (minDateTime.value && currentTime < minDateTime.value) ||
        (maxDateTime.value && currentTime > maxDateTime.value)
      ) {
        continue // Skip times outside bounds
      }

      // Use utility functions for conversion
      const hour12 = convertHours24To12(hour)
      const period = isAMOrPM(hour)

      options.push({
        text: `${hour12}:${formattedMinute} ${period}`, // Use 12-hour format with AM/PM
        value: `${formattedHour}:${formattedMinute}`, // Keep value in 24-hour format
      })
    }
  }
  return options
})

const onInput = (value: string) => {
  emit('input', value)
  isOpen.value = false
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors';

.v-list-item {
  cursor: pointer;
  background: $white;
  &:hover {
    .v-list-item__title {
      color: $white;
    }
    background-color: $primary;
  }
}

::v-deep .v-input__append-inner {
  margin-top: 14px !important;
  margin-right: 8px !important;
}
</style>
