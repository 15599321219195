<template>
  <div class="w-full margin-t-2" id="sales-bot-vehicle-container">
    <div class="d-flex flex-column padding-x-3 margin-b-2">
      <template v-if="vehicleOptionsLoading">
        <div class="d-flex flex-column align-center margin-b-4 padding-r-5">
          <p class="font-16 font-bold text-primary margin-l-2 margin-b-5">Finding the best prices for your trip...</p>
          <v-progress-circular
            indeterminate
            color="primary"
            :size="24"
            :width="3"
          />
        </div>
      </template>
      <template v-else-if="vehicleOptionsEmpty">
        <p class="font-16 font-medium text-error margin-b-5">Please complete the itinerary details above to see vehicle options.</p>
      </template>
      <template v-else>
        <SalesBotVehicleOptionCard
          v-for="(vehicleOption, vehicleOptionIndex) in vehicleOptions"
          :id="`sales-bot-vehicle-option-card-${vehicleOptionIndex}`"
          :key="`vehicle-pricing-card-${vehicleOptionIndex}`"
          :vehicle-option="vehicleOption"
          @select="select(vehicleOptionIndex)"
          class="margin-b-3"
        />
      </template>
    </div>
    <slot name="next-button" :validate="validate" />
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { useSalesBotStore } from '@/store/modules/salesBot'
import SkeletonBox from '@/components/SkeletonBox.vue'
import SalesBotVehicleOptionCard from '@/components/SalesBotVehicleOptionCard.vue'

const salesBotStore = useSalesBotStore()
const { setSelectedVehicleOption, setEstimatedPrice } = salesBotStore

const vehicleOptions = computed(() => salesBotStore.vehicleOptions)
const vehicleOptionsLoading = computed(() => salesBotStore.vehicleOptionsLoading)
const vehicleOptionsEmpty = computed(() => {
  return vehicleOptions.value.length === 0
})

const select = (vehicleOptionIndex): void => {
  setSelectedVehicleOption(vehicleOptionIndex)

  const amount = vehicleOptions.value[vehicleOptionIndex].amount
  if (amount) {
    setEstimatedPrice(amount)
  }
}

const emit = defineEmits(['form:validate'])

watch(vehicleOptions, () => {
  validate()
})

const validate = (): boolean => {
  const isValid = vehicleOptions.value.filter(({ selected }) => selected).length === 1
  emit('form:validate', isValid)
  return isValid
}

</script>
