<template>
  <div class="d-flex justify-space-between align-center">
    <div
      v-if="
        !tracking.disableTracking && !hasSingleVehicle && !tracking.isFinished
      "
      class="d-flex margin-r-3 w-240"
    >
      <v-select
        :items="trackingAndNotTrackingVehicles"
        :item-text="(vehicle) => vehicle.busName"
        :item-value="(vehicle) => vehicle.journeyId"
        :value="selectedValue"
        :item-disabled="(vehicle) => !vehicle.isTracking"
        flat
        solo
        outlined
        placeholder="Select vehicle"
        @change="onVehicleSelect"
      >
        <template #selection="data">
          <div class="d-flex align-center">
            <v-icon small :color="data.item.color" class="margin-r-2">
              circle
            </v-icon>
            <span>
              {{ data.item.busName }}
            </span>
          </div>
        </template>
        <template #item="data">
          <v-list-item-title>
            <div class="d-flex align-center">
              <v-icon
                v-if="data.item.isTracking"
                small
                :color="data.item.color"
                class="margin-r-2"
              >
                circle
              </v-icon>
              <span
                :class="{ 'text-midnight-gray-100': !data.item.isTracking }"
              >
                {{ data.item.busName }}
                {{
                  data.item.isTracking
                    ? ''
                    : $t('liveTracking.itinerary.NOT_TRACKING')
                }}
              </span>
            </div>
          </v-list-item-title>
        </template>
      </v-select>
    </div>
    <span
      v-if="tracking.disableTracking || !hasSingleVehicle"
      class="text-midnight-gray-100"
    >
      {{ vehicleCountText }}
    </span>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import trackingVehicleStore from '@/store/modules/trackingVehicles'
import tracking from '@/store/modules/tracking'
import { pluralize } from '@/utils/string'

@Component({})
export default class LiveTrackingVehicleSelector extends Vue {
  trackingVehicleStore = trackingVehicleStore
  tracking = tracking

  get trackingAndNotTrackingVehicles() {
    return this.trackingVehicleStore.trackingAndNotTrackingVehicles
  }

  get hasSingleVehicle() {
    return this.tracking.reservation?.requiredVehicleCount === 1
  }

  get selectedValue(): number | null {
    const { isTracking } =
      this.trackingVehicleStore?.activeTrackingVehicle || {}
    if (!isTracking) {
      return null
    }
    return this.trackingVehicleStore.activeTrackingVehicle.journeyId
  }

  get vehicleCountText() {
    let vehicleCount = this.trackingAndNotTrackingVehicles?.length

    if (tracking.disableTracking) {
      vehicleCount = tracking.reservation?.requiredVehicleCount
    }

    if (!vehicleCount) {
      return this.$t('liveTracking.itinerary.NO_VEHICLES')
    }

    if (vehicleCount === 1 && tracking.disableTracking) {
      return `${vehicleCount} ${
        tracking.reservation?.requiredVehicles?.[0].vehicleType.label ||
        this.$t('liveTracking.itinerary.VEHICLE').toString()
      }`
    }
    return `${vehicleCount} ${pluralize(
      vehicleCount,
      this.$t('liveTracking.itinerary.VEHICLE').toString()
    )}`
  }

  async onVehicleSelect(journeyId: number) {
    const vehicle = this.trackingAndNotTrackingVehicles.find(
      (v) => v.journeyId === journeyId
    )
    if (vehicle) {
      await this.trackingVehicleStore.setActiveTrackingVehicle(vehicle)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors';

::v-deep .v-select .v-text-field__details {
  display: none;
}
</style>
