<template>
  <div
    class="d-flex flex-column h-full justify-center padding-b-12 text-center max-w-500 margin-x-auto"
  >
    <v-icon class="margin-x-auto margin-b-3" size="70">
      $color-sent
    </v-icon>
    <h1>
      Cancellation Started
    </h1>
    We want to thank you for working with CharterUP. We hope to see you for your
    future travels!
  </div>
</template>
<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class CancellationStarted extends Vue {}
</script>
