import { render, staticRenderFns } from "./SalesBotMap.vue?vue&type=template&id=50839f2b&scoped=true"
import script from "./SalesBotMap.vue?vue&type=script&lang=ts&setup=true"
export * from "./SalesBotMap.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./SalesBotMap.vue?vue&type=style&index=0&id=50839f2b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50839f2b",
  null
  
)

export default component.exports