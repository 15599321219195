<template>
  <v-card>
    <v-card-title
      class="font-bold font-16 padding-t-2 padding-r-2 padding-b-4 padding-l-4"
    >
      <span class="word-break-keep-all" :style="{ width: 'calc(100% - 24px)' }">
        <SkeletonBox class="w-full" type="text" />
      </span>
    </v-card-title>
    <v-card-subtitle
      class="font-book font-14 text-gray padding-t-0 padding-x-2 padding-b-2"
    >
      <SkeletonBox class="w-three-fourths h-18 margin-t-1" type="text" />
    </v-card-subtitle>
    <v-expand-transition>
      <v-card-text class="padding-t-0 padding-x-2 padding-b-2">
        <SkeletonBox class="w-one-half margin-y-2 margin-x-0" type="text" />
        <v-chip
          v-for="i in 5"
          :key="`skeleton-chip-${i}`"
          outlined
          disabled
          color="borderGray"
        >
          <SkeletonBox type="text" class="w-one-half h-18 opacity-50" />
        </v-chip>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({ components: { SkeletonBox } })
export default class EnterpriseTrackingSidebarItineraryCardSkeleton extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/scss/colors';

.v-card {
  &::before,
  &::after {
    display: none;
  }
  .v-card__title {
    line-height: 1rem;
    background: none !important;
    border: none !important;
  }
  .v-card__text {
    p {
      color: $gray-medium-light;
      margin: 0 0 4px;
    }
  }
}

.v-chip {
  width: 84px;
  margin: 0 4px 4px 0;
  justify-content: center;
  &.v-chip--disabled {
    opacity: 1;
  }
}

@media screen and (min-width: 600px) {
  .v-card {
    padding: 16px 16px 8px;
    margin: 0 24px 16px 12px;
    width: 317px;
  }
}
@media screen and (max-width: 599px) {
  .v-card {
    border-radius: 0;
    border: none !important;
    border-bottom: 1px solid $border-gray !important;
    padding: 10px 8px 0px;
    margin-bottom: -5px;
    width: 100%;
    .v-card__text {
      padding: 8px 0 8px 16px !important;
    }
  }
}
</style>
