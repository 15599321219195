<template>
  <div>
    <div class="d-inline-flex align-center" @keydown="meridianKeyPressHandler">
      <input
        :id="`hour-input-${uuid}`"
        ref="hour"
        v-mask="['#', '##']"
        :value="hour"
        type="text"
        class="font-32  font-medium w-80 h-64 border-2 border-border-gray focus:border-primary border-solid border-radius-regular text-center focus:text-primary text-primary"
        :class="{}"
        placeholder="00"
        @keydown="hourKeyPressHandler"
        @input="setHourValue(null)"
      />
      <div class="d-flex flex-column margin-x-2">
        <div
          class="background-primary h-6 w-6 border-radius-round margin-b-2"
        />
        <div class="background-primary h-6 w-6 border-radius-round" />
      </div>
      <input
        :id="`minute-input-${uuid}`"
        ref="minute"
        v-mask="['#', '##']"
        :value="minute"
        type="text"
        class="font-32  font-medium w-80 h-64 border-2 border-border-gray focus:border-primary border-solid border-radius-regular text-center focus:text-primary text-primary"
        placeholder="00"
        @keydown="minuteKeyPressHandler"
        @input="setMinuteValue(null)"
      />
      <div class="d-flex flex-column font-medium margin-l-2">
        <button
          class="padding-x-2 padding-y-1 font-14 border-2 border-solid border-radius-top-right-regular border-radius-top-left-regular transition-all transition-ease transition-duration-150"
          :class="{
            'text-primary border-primary z-2 background-blue-10': isAM,
            'border-border-gray text-gray-light': !isAM,
          }"
          @click="setToAM"
        >
          AM
        </button>
        <button
          class="padding-x-2 padding-y-1 font-14 border-2 border-solid border-radius-bottom-right-regular border-radius-bottom-left-regular transition-all transition-ease transition-duration-150"
          :style="{ 'margin-top': '-2px' }"
          :class="{
            'text-primary border-primary z-2 background-blue-10': isPM,
            'border-border-gray text-gray-light': !isPM,
          }"
          @click="setToPM"
        >
          PM
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'
import { KeyCode } from '@/utils/enum'
import {
  convertHours12to24,
  convertHours24To12,
  isAMOrPM,
} from '@/utils/datetime'

@Component({})
export default class CUDigitalTimePicker extends Vue {
  @Prop({ type: String, required: true }) readonly value: string

  @Watch('isoTime')
  isoTimeChanged(value: string | null): void {
    this.$emit('input', value)
  }

  @Watch('value')
  valueChanged(value: string) {
    this.updateInputsFromValueChange(value)
  }

  isAM = true
  isPM = false
  hour: string = null
  minute: string = null
  uuid = ''

  get hours24(): number {
    if (this.hour === null) {
      return null
    }
    return convertHours12to24(parseInt(this.hour), this.isAM ? 'AM' : 'PM')
  }

  get isoTime(): string {
    if (
      (!this.hours24 && this.hours24 !== 0) ||
      (!this.minute && parseInt(this.minute) !== 0)
    ) {
      return null
    }
    const hour = this.hours24 < 10 ? `0${this.hours24}` : `${this.hours24}`
    const minute = `${this.minute}`
    return `${hour}:${minute}:00`
  }

  mounted(): void {
    this.uuid = uuid()
    if (this.value) {
      this.updateInputsFromValueChange(
        this.value,
        this.$refs.hour,
        this.$refs.minute
      )
    }
  }

  setToAM(): void {
    this.isAM = true
    this.isPM = false
  }

  setToPM(): void {
    this.isPM = true
    this.isAM = false
  }

  hourKeyPressHandler(event: any): void {
    const keyCode = event.keyCode
    if (keyCode === KeyCode.Colon) {
      const minuteElement = this.$refs.minute as HTMLElement | null
      minuteElement.focus()
    }
    if (keyCode === KeyCode.UpArrow) {
      const current = parseInt(this.hour) || 0
      this.setHourValue(current + 1, null)
      return
    }
    if (keyCode === KeyCode.DownArrow) {
      const current = parseInt(this.hour) || 0
      this.setHourValue(current - 1, null)
      return
    }
  }

  minuteKeyPressHandler(event: any): void {
    const keyCode = event.keyCode
    if (keyCode === KeyCode.UpArrow) {
      const current = parseInt(this.minute) || 0
      this.setMinuteValue(current + 1, null)
      return
    }
    if (keyCode === KeyCode.DownArrow) {
      const current = parseInt(this.minute) || 0
      this.setMinuteValue(current - 1, null)
      return
    }
  }

  setHourValue(valueToSet: number, el: any): void {
    const element =
      el || (document.getElementById(`hour-input-${this.uuid}`) as any)
    const value = valueToSet || valueToSet === 0 ? valueToSet : element.value
    if (value === '') {
      this.hour = null
      return
    }
    const intValue = parseInt(value)
    if (isNaN(intValue)) {
      element.value = this.hour
      return
    }
    if (`${value}`.length > 2) {
      return
    }
    if (value > 12 || value < 1) {
      element.value = this.hour
      return
    }
    this.hour = value
  }

  setMinuteValue(valueToSet: number, el: any): void {
    const element =
      el || (document.getElementById(`minute-input-${this.uuid}`) as any)
    const value = valueToSet || valueToSet === 0 ? valueToSet : element.value
    if (value === '') {
      this.minute = null
      return
    }
    if (value === '') {
      this.minute = null
      return
    }
    const intValue = parseInt(value)
    if (isNaN(intValue)) {
      element.value = this.minute
      return
    }
    if (intValue > 59 || intValue < 0) {
      element.value = this.minute
      return
    }
    if (intValue < 10) {
      const twoDigitValue = `0${intValue}`
      element.value = twoDigitValue
      this.minute = twoDigitValue
      return
    }
    this.minute = intValue.toString()
  }

  meridianKeyPressHandler(event: any): void {
    const keyCode = event.keyCode
    if (keyCode === KeyCode.AKey) {
      this.setToAM()
      return
    }
    if (keyCode === KeyCode.PKey) {
      this.setToPM()
      return
    }
  }

  updateInputsFromValueChange(
    value: string,
    hourEl: any = null,
    minuteEl: any = null
  ): void {
    const splitValue = value.split(':')
    const newHour = parseInt(splitValue[0])
    const newHour12Hour = convertHours24To12(newHour)
    const newMinute = parseInt(splitValue[1])
    const oldHour = parseInt(this.hour)
    const oldMinute = parseInt(this.minute)
    if (newHour12Hour !== oldHour) {
      this.setHourValue(newHour12Hour, hourEl)
      if (isAMOrPM(newHour) === 'AM') {
        this.setToAM()
      } else {
        this.setToPM()
      }
    }
    if (newMinute !== oldMinute) {
      this.setMinuteValue(newMinute, minuteEl)
    }
  }
}
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
