<template>
  <div class="w-full margin-t-2" id="sales-bot-amenity-container">
    <p class="margin-b-3">Add optional extras</p>
    <div class="d-flex flex-column padding-x-3 padding-b-3">
      <template v-if="amenityOptionsLoading">
        <SkeletonBox
          v-for="(_, index) in 2"
          :key="`amenity-pricing-skeleton-${index}`"
          class="border-radius-2x padding-a-3 h-128 margin-b-5"
        />
      </template>
      <template v-else-if="amenityOptionsEmpty">
        <p class="font-16 font-medium text-error margin-b-5">Please select a vehicle option above to see available amenities.</p>
      </template>
      <template v-else>
        <SalesBotAmenityOptionCard
          v-for="(amenityOption, amenityOptionIndex) in amenityOptions"
          :key="`amenity-pricing-card-${amenityOptionIndex}`"
          :amenity-option="amenityOption"
          :selected="isAmenitySelected(amenityOption.amenityTypeKey)"
          @toggle="toggleAmenity(amenityOption.amenityTypeKey)"
          class="margin-b-3"
        />
      </template>
    </div>
    <slot name="next-button" :validate="validate" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useSalesBotStore } from '@/store/modules/salesBot'
import SkeletonBox from '@/components/SkeletonBox.vue'
import SalesBotAmenityOptionCard from '@/components/SalesBotAmenityOptionCard.vue'

const salesBotStore = useSalesBotStore()
const { toggleAmenity } = salesBotStore

const amenitiesKeys = computed(() => salesBotStore.amenitiesKeys)
const amenityOptions = computed(() => salesBotStore.amenityOptions)
const amenityOptionsLoading = computed(() => salesBotStore.amenityOptionsLoading)
const amenityOptionsEmpty = computed(() => {
  return amenityOptions.value.length === 0
})

const isAmenitySelected = (amenityKey: string): boolean => {
  return amenitiesKeys.value.includes(amenityKey)
}

const emit = defineEmits(['form:validate'])

const validate = (): boolean => {
  // There is currently no way to select invalid amenities
  const isValid = true

  emit('form:validate', isValid)
  return isValid
}

</script>
