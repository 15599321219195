<template>
  <div
    class="w-full display-flex flex-wrap align-center justify-space-between margin-t-2 padding-y-2 min-h-32 font-14 background-yellow-20 border-radius-regular"
  >
    <div class="display-flex align-center justify-start margin-l-2">
      <CUIcon
        view-box="0 0 24 24"
        :width="20"
        :height="20"
        color="yellow"
        class="margin-r-2 cursor-default"
      >
        warning
      </CUIcon>
      <p class="margin-t-0">
        {{ actionLabel }}
      </p>
    </div>

    <template v-if="!useMobileActionLabel">

      <TripModificationRequestDialog
        v-if="showTripModification && hasPendingModificationRequest"
        :managed-id="managedId"
      >
        <template #activator="{ on, attrs }">
          <span
            class="text-primary font-14 font-bold cursor-pointer margin-r-3"
            v-bind="attrs"
            v-on="on"
          >
            {{ linkActionLabel }}
          </span>
        </template>
      </TripModificationRequestDialog>

      <span
        v-else-if="showTripModification"
        class="text-primary font-14 font-bold cursor-pointer margin-r-3"
        @click="openStartTripModificationDialog"
      >
        {{ linkActionLabel }}
      </span>

      <a
        v-else
        :id="`risk-action-modal-link-${index}`"
        class="font-bold font-14 margin-r-3"
        @click.stop="openActionModal"
      >
        {{ linkActionLabel }}
      </a>
    </template>

    <div
      v-if="linkInfoLabel"
      class="w-full display-flex flex-wrap align-center justify-space-between"
    >
      <div class="margin-x-3">
        <a
          :id="`risk-info-modal-link-${index}`"
          class="font-medium margin-t-3 margin-l-6 font-14 text-gray text-decoration-underline"
          @click.stop="openInfoModal"
        >
          {{ linkInfoLabel }}
        </a>
      </div>

      <template v-if="useMobileActionLabel">
        <span
          v-if="showTripModification"
          class="text-primary font-14 font-bold cursor-pointer margin-r-3"
          @click="openStartTripModificationDialog"
        >
          {{ linkActionLabel }}
        </span>

        <a
          v-else
          :id="`risk-action-modal-link-${index}`"
          class="font-bold font-14 margin-r-3"
          @click.stop="openActionModal"
        >
          {{ linkActionLabel }}
        </a>
      </template>
    </div>
    <DriverHotelRequirementsDialog
      v-model="actionableRisk.driverHotelRequirementsDialogIsOpen"
      :reservation-id="reservationId"
      :hotel-requirement="hotelRequirement"
    />
    <HotelInformationDialog
      v-model="actionableRisk.hotelInformationDialogIsOpen"
      :trip-id="tripId"
      :hotel-information="hotelInformation"
      :hotel-requirement="hotelRequirement"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Component, Watch, InjectReactive } from 'vue-property-decorator'
import { HotelRequirement, HotelInformation, Stop, Trip } from '@/models/dto'
import { ReservationStatusKey, SplitFeatureFlag, RiskTypeId } from '@/utils/enum'
import { TranslateResult } from 'vue-i18n'
import DriverHotelRequirementsDialog from './DriverHotelRequirementsDialog.vue'
import HotelInformationDialog from './HotelInformationDialog.vue'
import TripModificationRequestDialog from './TripModificationRequestDialog.vue'
import DriverHotelWarningInfoLabel from './DriverHotelWarningInfoLabel.vue'
import { ActionableRisk } from '@/models/ActionableRisk'
import { EventBus } from '@/utils/eventBus'

@Component({
  components: {
    DriverHotelRequirementsDialog,
    HotelInformationDialog,
    TripModificationRequestDialog,
    DriverHotelWarningInfoLabel,
  },
})
export default class ItineraryRiskWarning extends Vue {
  @Prop({ required: true, type: Number }) readonly reservationId: number
  @Prop({ required: true, type: String }) readonly managedId: string
  @Prop({ required: false, type: Number, default: null }) readonly index: number
  @Prop({ required: false, type: Object, default: null }) readonly stop: Stop
  @Prop({ required: false, type: Object, default: null }) readonly trip: Trip
  @Prop({ required: false, type: Object, default: null }) readonly hotelRequirement: HotelRequirement
  @Prop({ required: false, type: Object, default: null }) readonly hotelInformation: HotelInformation
  @Prop({ required: true, type: Number }) readonly riskTypeId: number

  @InjectReactive('reservationStatus') reservationStatus
  @InjectReactive('hasPendingModificationRequest') hasPendingModificationRequest

  isItineraryModificationWizardEnabled = false
  actionableRisk = new ActionableRisk()

  @Watch('reservationId', { immediate: true })
  reservationIdChanged(reservationId) {
    this.actionableRisk.setReservationId(reservationId)
  }

  @Watch('stop', { immediate: true })
  stopChanged(stop) {
    this.actionableRisk.setStops([stop])
  }

  get isReservationUpcomingOrOnHold(): boolean {
    return (
      this.reservationStatus === ReservationStatusKey.Upcoming ||
      this.reservationStatus === ReservationStatusKey.OnHold
    )
  }

  get showTripModification(): boolean {
    // We don't need to check if the user is allowed to manage the reservation
    // since we would only ever show these risk warnings if they could
    return this.isItineraryModificationWizardEnabled
      && this.isReservationUpcomingOrOnHold
      && !this.isDriverHotelMissing
      && !this.isFlightInfoMissing
  }

  get tripId(): number {
    return this.hotelRequirement?.tripId
  }

  get isDriverHotelMissing(): boolean {
    return this.riskTypeId === RiskTypeId.DriverHotelMissing
  }

  get isFlightInfoMissing(): boolean {
    return this.riskTypeId === RiskTypeId.FlightInfoMissing
  }

  get useMobileActionLabel(): boolean {
    return (
      this.$vuetify.breakpoint.xsOnly &&
      [RiskTypeId.DriverHotelMissing].includes(this.riskTypeId)
    )
  }

  get actionLabel(): TranslateResult {
    const actionLabel = this.riskObject(this.riskTypeId).actionLabel
    return this.$t('reservationDetail.informationNeeded.itineraryWarning')[
      actionLabel
    ]
  }

  get linkActionLabel(): TranslateResult {
    const linkActionLabel = this.riskObject(this.riskTypeId).linkActionLabel
    if (this.showTripModification && linkActionLabel == 'MESSAGE_US') {
      return 'Add'
    }
    return this.$t('common')[linkActionLabel]
  }

  get linkInfoLabel(): TranslateResult {
    const linkInfoLabel = this.riskObject(this.riskTypeId).linkInfoLabel
    if (!linkInfoLabel) {
      return null
    }
    return this.$t(linkInfoLabel)
  }

  async mounted() {
    this.isItineraryModificationWizardEnabled = await this.$split.isFeatureEnabled(
      SplitFeatureFlag.ItineraryModificationWizard
    )
  }

  openActionModal(): void {
    this.riskObject(this.riskTypeId).openActionModal()
  }

  openInfoModal(): void {
    this.riskObject(this.riskTypeId).openInfoModal()
  }

  riskObject(riskTypeId: number) {
    return this.actionableRisk.riskObject(riskTypeId)
  }

  openStartTripModificationDialog(): void {
    EventBus.$emit('open-trip-modification-dialog')
  }
}
</script>
