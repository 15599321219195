<template>
  <div class="d-flex align-center">
    <div
      class="position-relative margin-r-2"
      @click="changePickupSentiment(true)"
    >
      <CUIcon
        :color="positiveFaceColor"
        view-box="0 0 24 24"
        width="48px"
        height="48px"
        :class="{ 'cursor-default': disabled }"
      >
        satisfied
      </CUIcon>
      <div
        class="background-white w-16 h-16 border-radius-round position-absolute top-0 right-0"
      >
        <CUIcon
          view-box="0 0 24 24"
          :color="positiveIconColor"
          width="16px"
          height="16px"
          :class="{ 'cursor-default': disabled }"
        >
          check_circle_old
        </CUIcon>
      </div>
    </div>
    <div class="position-relative" @click="changePickupSentiment(false)">
      <CUIcon
        :color="negativeFaceColor"
        view-box="0 0 24 24"
        width="48px"
        height="48px"
        :class="{ 'cursor-default': disabled }"
      >
        dissatisfied
      </CUIcon>
      <div
        class="background-white w-16 h-16 border-radius-round position-absolute top-0 right-0"
      >
        <CUIcon
          view-box="0 0 24 24"
          :color="negativeIconColor"
          width="16px"
          height="16px"
          :class="{ 'cursor-default': disabled }"
        >
          cancel
        </CUIcon>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class PickupSatisfactionSelector extends Vue {
  @Prop({ type: Boolean, required: true }) value!: boolean
  @Prop({ type: Boolean }) disabled!: boolean

  get positiveIconColor(): string {
    return this.value === null || this.value ? 'success' : 'gray-medium-light'
  }

  get positiveFaceColor(): string {
    return this.value === null || this.value ? 'gray' : 'gray-medium-light'
  }

  get negativeIconColor(): string {
    return this.value ? 'gray-medium-light' : 'error'
  }

  get negativeFaceColor(): string {
    return this.value ? 'gray-medium-light' : 'gray'
  }

  changePickupSentiment(positive: boolean): void {
    if (this.disabled) {
      return
    }
    this.$emit('input', positive)
  }
}
</script>
