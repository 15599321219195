<template>
  <div class="cu-timeline-item d-flex">
    <div class="cu-timeline-item__divider position-relative top-0">
      <div
        class="cu-timeline-item__line w-0 h-full position-absolute z-0 top-2 border-l-2 border-y-0 border-r-0 border-gray-subtle-text"
        :class="{
          'border-dashed': upcoming,
          'border-solid': !upcoming,
        }"
        :style="{
          left: '50%',
          transform: 'translateX(-50%)',
        }"
      />
      <slot name="icon">
        <SkeletonBox v-if="loading" class="h-16 w-16 border-radius-round" />
        <img v-else class="h-16 w-16 position-relative top-0" :src="icon" />
      </slot>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import upcomingIcon from '@/assets/images/stopIcons/upcoming.png'
import inprogressIcon from '@/assets/images/stopIcons/inprogress.png'
import completedIcon from '@/assets/images/stopIcons/completed.png'
import lastIcon from '@/assets/images/stopIcons/last.png'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({ components: { SkeletonBox } })
export default class CUItineraryItem extends Vue {
  @Prop({ type: Boolean, required: false }) readonly upcoming: boolean
  @Prop({ type: Boolean, required: false }) readonly inProgress: boolean
  @Prop({ type: Boolean, required: false }) readonly completed: boolean
  @Prop({ type: Boolean, required: false }) readonly lastStop: boolean
  @Prop({ type: Boolean, required: false }) readonly loading: boolean

  get icon() {
    if (this.completed) {
      return completedIcon
    }
    if (this.lastStop) {
      return lastIcon
    }
    if (this.inProgress) {
      return inprogressIcon
    }
    if (this.upcoming) {
      return upcomingIcon
    }
    return upcomingIcon
  }
}
</script>

<style lang="scss" scoped>
.cu-timeline-item {
  &:last-of-type {
    .cu-timeline-item__line {
      display: none;
    }
  }
}
</style>
