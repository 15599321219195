<template>
  <div class="background-white border-radius-8 padding-a-6 shadow">
    <div class="d-flex justify-space-between align-center">
      <div>
        <p class="font-medium margin-t-0 margin-b-1">{{ formattedLocationText }}</p>
        <p class="font-14 margin-t-0" :style="{'line-height': 1.2}">{{ formattedTimeText }}</p>
        <p class="font-14 margin-t-0" :style="{'line-height': 1.2}">{{ formattedPassengerText }}</p>
      </div>
      <v-btn
        color="primary"
        text
        small
        @click="isTripSummaryDialogOpen = $event"
      >
        Summary
      </v-btn>
    </div>

    <CUModal v-model="isTripSummaryDialogOpen" :closeable="false" max-width="100%" width="100%">
      <template #text>
        <SalesBotTripSummary class="w-full" :isModalOpen="isTripSummaryDialogOpen" closeable @close="isTripSummaryDialogOpen = false" />
      </template>
    </CUModal>
  </div>
</template>

<script setup lang="ts">
import SalesBotTripSummary from "@/components/SalesBotTripSummary.vue"
import { useDisplay } from "@/composables/useDisplay"
import { useSalesBotStore } from "@/store/modules/salesBot"
import { pluralize } from "@/utils/string"
import { computed, ref } from "vue"

const salesBotStore = useSalesBotStore()
const { smOnly } = useDisplay()

const isTripSummaryDialogOpen = ref(false)

const tripSummary = computed(() => {
  return salesBotStore.tripSummary
})

const formattedLocationText = computed(() => {
  const { pickupLocation, dropoffLocation } = tripSummary.value
  if (pickupLocation && dropoffLocation) {
    return `${pickupLocation} to ${dropoffLocation}`
  }
  return ''
})

const formattedTimeText = computed(() => {
  const { firstPickupDatetime, lastDropoffDatetime } = tripSummary.value
  if (firstPickupDatetime && lastDropoffDatetime) {
    const formattedPickupTime = firstPickupDatetime.toFormat('MMM d h:mm a')
    const formattedDropoffTime = lastDropoffDatetime.toFormat('MMM d h:mm a')
    return `${formattedPickupTime} - ${formattedDropoffTime}`
  }
  return ''
})

const formattedPassengerText = computed(() => {
  const { passengers } = tripSummary.value
  if (passengers) {
    return `${passengers} ${pluralize(passengers, 'passenger')}`
  }
  return ''
})

</script>

<style scoped lang="scss">
.shadow {
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);
}
</style>
