<template>
  <div class="d-flex align-center padding-x-1">
    <v-menu offset-y>
      <template #activator="{ on }">
        <CUIcon
          :id="`import-actions`"
          view-box="0 0 24 24"
          class="kebab-button margin-t-n1"
          v-bind="attrs"
          v-on="on"
        >
          more_vert
        </CUIcon>
      </template>
      <v-list class="dropdown-menu padding-a-0">
        <v-list-item
          id="import-all-item"
          key="import-all-item"
          @click="importAll"
        >
          <v-list-item-title>
            Import All
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          id="import-update-item"
          key="import-update-item"
          @click="importUpdate"
        >
          <v-list-item-title>
            Import Update
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <input
      v-show="false"
      id="importAllInput"
      ref="file"
      type="file"
      @change="importAllSubmit"
    />
    <input
      v-show="false"
      id="importUpdateInput"
      ref="file"
      type="file"
      @change="importUpdateSubmit"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import rider from '@/services/rider'
import { CustomerAccount } from '@/models/dto'

@Component({})
export default class ImportActionsDropdown extends Vue {
  uploadReady = true

  @Prop({ type: Object }) readonly customerAccount: CustomerAccount

  importAll(): void {
    if (!this.uploadReady) {
      return
    }
    const inputElement = document.getElementById('importAllInput')
    inputElement.click()
  }

  importUpdate(): void {
    if (!this.uploadReady) {
      return
    }
    const inputElement = document.getElementById('importUpdateInput')
    inputElement.click()
  }

  importAllSubmit(event: Event): void {
    const target = event.target as HTMLInputElement
    const formData = new FormData()
    formData.set('file', target.files[0])
    rider.import(this.customerAccount.customerAccountId, formData, true)
    this.uploadReady = false
    this.$nextTick(() => {
      this.uploadReady = true
    })
    this.$emit('open-import-dialog')
  }

  importUpdateSubmit(event: Event): void {
    const target = event.target as HTMLInputElement
    const formData = new FormData()
    formData.set('file', target.files[0])
    rider.import(this.customerAccount.customerAccountId, formData)
    this.uploadReady = false
    this.$nextTick(() => {
      this.uploadReady = true
    })
    this.$emit('open-import-dialog')
  }
}
</script>
