<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex align-center justify-center font-12 text-midnight-gray-500"
    >
      {{ $t('checkout.AGREE_TO_TERMS_V2') }}
      <a
        id="checkout-link-terms-and-conditions"
        href="https://www.charterup.com/transportation-terms"
        target="_blank"
        class="text-decoration-underline text-midnight-gray-500 margin-l-1"
      >
        {{ $t('common.TERMS_AND_CONDITIONS') }}
      </a>
      <CUIcon
        v-if="!hideDropdown"
        v-ripple="false"
        class="terms-expansion-arrow w-20 h-20"
        :class="isTermsExpanded ? 'flipped' : ''"
        color="midnight-gray-500"
        view-box="0 0 24 24"
        @click="isTermsExpanded = !isTermsExpanded"
      >
        keyboard_arrow_down
      </CUIcon>
    </div>
    <div v-if="!hideDropdown" class="d-flex">
      <v-expand-transition>
        <v-col
          v-if="isTermsExpanded"
          class="d-flex justify-center padding-r-0 padding-l-0 max-h-360"
        >
          <v-card class="w-full overflow-y-scroll">
            <TransportationTerms />
          </v-card>
        </v-col>
      </v-expand-transition>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import TransportationTerms from '@/components/TransportationTerms.vue'

@Component({ components: { TransportationTerms } })
export default class CheckoutTerms extends Vue {
  @Prop({ type: Boolean, default: false }) hideDropdown!: boolean

  isTermsExpanded = false
}
</script>

<style lang="scss" scoped>
.theme--light.v-icon:focus::after {
  display: none;
}
.terms-expansion-arrow {
  transform: rotate(0deg);
  transition: transform 0.2s linear;
  &.flipped {
    transform: rotate(-180deg);
    transition: transform 0.2s linear;
  }
}
</style>
