<template>
  <v-card v-show="isVisible" class="padding-y-2">
    <v-card-title class="plain padding-y-0">
      <v-col cols="1" class="flex-shrink-1">
        <v-icon color="primary">
          bus_alert_warn
        </v-icon>
      </v-col>
      <v-col class="padding-y-0 margin-y-0">
        {{ alert.title }}
      </v-col>
      <v-col cols="1" class="flex-shrink-1">
        <v-icon @click="isVisible = false">
          close
        </v-icon>
      </v-col>
    </v-card-title>
    <v-card-text v-html="alert.text" class="padding-t-0 margin-t-0" />
  </v-card>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Alert } from '../models/dto'

@Component({})
export default class EnterpriseTrackingSidebarVehicleCard extends Vue {
  @Prop({ type: Object, required: true }) readonly alert: Alert
  isVisible = true
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors';

$hover-color: var(--hover-color);

.v-card {
  &.hover {
    outline-style: solid;
    outline-width: 2px;
    outline-offset: -2px;
    outline-color: $hover-color;
  }
  .v-card__title {
    font-family: 'Inter Bold', Arial, sans-serif;
    font-size: 1rem !important;
    line-height: 1rem;
  }
  .v-card__text {
    p {
      font-size: 0.875rem;
      color: $gray;
      margin: 0 0 2px;
    }
  }
}
</style>
