<template>
  <v-container :style="{ height: '65vh' }">
    <v-row justify="center" class="fill-height" align="center">
      <v-col cols="12">
        <v-img
          v-if="currentImage"
          contain
          :max-height="$vuetify.breakpoint.smAndUp ? '50vh' : '60vh'"
          :src="currentImage.imagePath"
        />
      </v-col>
      <v-col v-if="imageLabel" cols="12" class="padding-t-0">
        <p class="text-center">{{ imageLabel }}</p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        v-for="(thumbnail, itemIndex) in visibleThumbnails"
        :key="`vehiclePhoto-${extractVehiclePhotoCharterUpId(thumbnail)}-${itemIndex}`"
        :cols="Math.floor(12 / thumbnailCount)"
      >
        <v-img
          class="cursor-pointer border-solid border-2 hover:transform-102 transition-all transition-duration-300"
          :src="thumbnail.imagePath"
          :aspect-ratio="16 / 9"
          :class="{
            'border-primary':
              extractVehiclePhotoCharterUpId(photos[value]) ===
              extractVehiclePhotoCharterUpId(thumbnail),
            'border-transparent':
              extractVehiclePhotoCharterUpId(photos[value]) !==
              extractVehiclePhotoCharterUpId(thumbnail),
          }"
          @click="selectPhoto(thumbnail)"
        />
      </v-col>
      <v-col cols="12">
        <v-row
          class="padding-t-5"
          align="stretch"
          :justify="$vuetify.breakpoint.lgAndUp ? 'center' : 'space-around'"
        >
          <v-col
            id="carousel-arrow-left"
            cols="4"
            class="d-flex justify-center cursor-pointer"
            @click="handleArrowKey({ keyCode: 37 })"
          >
            <CUIcon view-box="0 0 24 24" color="gray">
              keyboard_arrow_left
            </CUIcon>
          </v-col>
          <v-col
            :cols="$vuetify.breakpoint.mdAndUp ? 1 : 4"
            class="d-flex justify-center"
          >
            {{ `${value + 1} / ${photos.length}` }}
          </v-col>
          <v-col
            id="carousel-arrow-right"
            class="d-flex justify-center cursor-pointer"
            cols="4"
            @click="handleArrowKey({ keyCode: 39 })"
          >
            <CUIcon view-box="0 0 24 24" color="gray">
              keyboard_arrow_right
            </CUIcon>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { VehiclePhoto } from '@/models/dto'
import { KeyCode } from '@/utils/enum'

@Component({})
export default class Carousel extends Vue {
  @Prop({ type: Number, default: 0 }) readonly value: number
  @Prop({ type: Array, default: () => [] }) readonly photos: VehiclePhoto[]

  get currentImage(): VehiclePhoto {
    return this.photos?.[this.value]
  }
  get thumbnailCount(): number {
    if (this.$vuetify.breakpoint.xl) {
      return 10
    }
    if (this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.md) {
      return 6
    }
    if (this.$vuetify.breakpoint.sm) {
      return 4
    }
    return 3
  }

  get imageLabel(): string {
    return this.currentImage?.label || ''
  }

  get visibleThumbnails(): VehiclePhoto[] {
    if (this.photos.length <= this.thumbnailCount) {
      return this.photos
    }
    if (this.photos.length - this.value >= this.thumbnailCount) {
      return this.photos.slice(this.value, this.value + this.thumbnailCount)
    }
    const backfillCount =
      this.thumbnailCount - (this.photos.length - this.value)
    const backfill = this.photos.slice(this.value - backfillCount, this.value)
    const frontfill = this.photos.slice(this.value, this.photos.length)
    return backfill.concat(frontfill)
  }

  mounted(): void {
    document.addEventListener('keydown', this.handleArrowKey)
  }

  destroyed(): void {
    document.removeEventListener('keydown', this.handleArrowKey)
  }

  handleArrowKey(e): void {
    let emitValue = null
    if (e.keyCode === KeyCode.RightArrow) {
      emitValue = this.value >= this.photos.length - 1 ? 0 : this.value + 1
    } else if (e.keyCode === KeyCode.LeftArrow) {
      emitValue = this.value <= 0 ? this.photos.length - 1 : this.value - 1
    }
    this.$emit('input', emitValue)
  }

  selectPhoto(photo): void {
    this.$emit('input', this.photos.indexOf(photo))
  }

  extractVehiclePhotoCharterUpId(photo: VehiclePhoto): number | undefined {
    return photo?.vehiclePhotoCharterUpId;
  }
}
</script>
