<template>
  <div
    v-if="showBack"
    class="d-flex align-center opacity-70 hover:opacity-100 transition-all transition-duration-300"
  >
    <div class="d-flex align-center cursor-pointer" @click="goBack">
      <CUIcon color="primary">keyboard_arrow_left</CUIcon>
      <p class="text-primary margin-t-0 margin-b-1">
        {{ $t('reservationDetail.tripContactForm.BACK') }}
      </p>
    </div>
  </div>
  <div v-else>
    <label v-if="$attrs.label">{{ $attrs.label }}</label>
    <v-menu
      v-model="isOpen"
      :open-on-click="false"
      :close-on-click="false"
      nudge-bottom="50"
    >
      <template #activator="{ on }">
        <v-text-field
          ref="customerSearchTextField"
          v-bind="$attrs"
          :autofocus="autoFocus"
          :name="uuidv4()"
          :value="search"
          :disabled="disabled"
          :error-messages="errorMessages"
          :rules="rules"
          :clearable="clearable"
          flat
          :append-icon="appendIcon ? appendIcon : ''"
          autocomplete="off"
          outlined
          :tabindex="tabIndex"
          hide-details="auto"
          label=""
          :loading="loading"
          v-on="on"
          @blur="onBlur"
          @focus="onFocus"
          @input="customerAutocomplete"
          @keyup.native="handleKeyEvent"
          @click:append="appendIconHandler"
        />
      </template>
      <v-card v-if="filteredCustomers.length > 0">
        <v-list>
          <v-list-item
            v-for="(customer, customerIndex) in filteredCustomers"
            :id="`customer-search-item-${customer.email}-${customerIndex}`"
            :key="`customer-search-item-${customer.email}-${customerIndex}`"
            class="cursor-pointer padding-y-2"
            :class="{
              'background-black-10': arrowPosition === customerIndex,
            }"
            @mouseover="arrowPosition = customerIndex"
            @mousedown="selectCustomer(customer)"
          >
            <div
              class="d-flex background-black w-40 h-40 min-w-40 max-w-40 border-radius-round margin-r-2 align-center justify-center text-white font-bold"
            >
              {{
                getInitials(customer.firstName, customer.lastName) ||
                customer.email[0].toUpperCase()
              }}
            </div>
            <div
              class="margin-r-2"
              :class="{
                'max-w-240': $vuetify.breakpoint.xsOnly,
                'max-w-300': $vuetify.breakpoint.smAndUp,
              }"
            >
              <p
                class="font-bold margin-y-0 overflow-hidden white-space-nowrap display-block text-overflow-ellipsis"
              >
                {{ customer.firstName }} {{ customer.lastName }}
              </p>
              <p
                class="margin-y-0 overflow-hidden white-space-nowrap display-block text-overflow-ellipsis"
              >
                {{ customer.email }}
              </p>
            </div>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { v4 } from 'uuid'
import { KeyCode } from '@/utils/enum'
import { Customer, ReservationDetail, SharedList } from '@/models/dto'
import { getInitials, validateEmailAddress } from '@/utils/string'
import SharedContactBasicInfo from '@/models/dto/SharedContact'
import customerAccount from '@/services/customerAccount'
import auth from '@/store/modules/auth'
import deepClone from '@/utils/deepClone'

@Component({})
export default class CustomerInviteSearch extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly rules: any[]
  @Prop({ type: Boolean }) readonly clearable: boolean
  @Prop({ type: Boolean }) readonly manualControl: boolean
  @Prop({ type: Boolean }) readonly disabled: boolean
  @Prop({ type: String, default: () => undefined }) readonly identifier: string
  @Prop({ type: String, default: () => undefined }) readonly appendIcon: string
  @Prop({ type: Array, default: () => [] }) readonly errorMessages: string[]
  @Prop({ type: Boolean }) readonly autoFocus: boolean
  @Prop({ type: Function, default: () => true }) readonly appendIconHandler: any
  @Prop({ type: Number, required: false, default: null })
  readonly tabIndex: number
  @Prop({ type: Array, required: true })
  readonly sharedList: SharedList
  @Prop({ type: Object, default: () => {} })
  readonly reservation: ReservationDetail
  @Prop({ type: Boolean }) readonly tripContactMode: boolean
  @Prop({ type: String, default: null }) readonly tripContactEmail: string
  @Prop({ type: Boolean }) readonly showBack: boolean

  @Watch('showBack')
  showBackChanged(value: boolean): void {
    if (value) {
      this.isOpen = false
    }
  }

  @Watch('tripContactEmail')
  updateSearch(value: string): void {
    if (value !== '') {
      this.search = value
    }
  }

  loading = false
  search = ''

  debounce: any = null
  arrowPosition = null
  customers: SharedContactBasicInfo[] = []
  filteredCustomers: SharedContactBasicInfo[] = []
  customerCount: number = null
  isOpen = false
  getInitials = getInitials

  get showAddNew(): boolean {
    return this.customerCount === 0
  }

  get firstName(): string {
    return this.search.slice(0, this.indexOfFirstSpace)
  }

  get lastName(): string {
    if (this.indexOfFirstSpace < 0) {
      return ''
    }
    const lastName = this.search.slice(this.search.indexOf(' ') + 1)
    if (lastName === ' ') {
      return ''
    }
    return lastName
  }

  get email(): string {
    return this.search.replace(/\s/g, '')
  }

  get indexOfFirstSpace(): number {
    return this.search.indexOf(' ')
  }

  get computedInviteText(): string {
    if (
      this.tripContactMode ||
      this.search.includes(' ') ||
      !validateEmailAddress(this.search)
    ) {
      return 'Invite a new user'
    }
    return `Invite ${this.search}`
  }

  mounted(): void {
    this.loadSharedContacts()
  }

  async loadSharedContacts(): Promise<void> {
    const sharedContactsResponse = await customerAccount.sharedContactsList(
      auth.customerAccount.customerAccountId
    )
    const customersResponse = await customerAccount.customers(
      auth.customerAccount.customerAccountId
    )
    const emailMap = new Map()

    // Iterate over sharedContactsResponse.data and customersResponse.data
    ;[...sharedContactsResponse.data, ...customersResponse.data].forEach(
      (contact) => {
        if (!emailMap.has(contact.email)) {
          emailMap.set(contact.email, contact)
        }
      }
    )

    // Convert the Map values to an array
    this.customers = Array.from(emailMap.values())
    this.customerCount = this.customers.length
  }

  searchCustomers(): void {
    let filteredCustomers = deepClone(this.customers)
    filteredCustomers = filteredCustomers.filter(
      (c) =>
        (this.firstName && c.firstName.includes(this.firstName)) ||
        (this.lastName && c.lastName.includes(this.lastName)) ||
        (this.email && c.email.includes(this.email))
    )
    this.filteredCustomers = filteredCustomers.slice(0, 3)
  }

  handleKeyEvent(event): void {
    const maxPosition = this.customers.length
    if (event.keyCode === KeyCode.UpArrow) {
      if (typeof this.arrowPosition === 'undefined') {
        this.arrowPosition = maxPosition
      } else if (this.arrowPosition >= 0) {
        this.arrowPosition = this.arrowPosition - 1
      }
      return
    }
    if (event.keyCode === KeyCode.DownArrow) {
      if (typeof this.arrowPosition === 'undefined') {
        this.arrowPosition = 0
      } else if (this.arrowPosition + 1 === maxPosition) {
        this.arrowPosition = 0
      } else {
        this.arrowPosition = this.arrowPosition + 1
      }
      return
    }
    if (event.keyCode === KeyCode.Enter) {
      if (typeof this.customers[this.arrowPosition] !== 'undefined') {
        this.selectCustomer(this.customers[this.arrowPosition])
      }
      return
    }
  }
  customerAutocomplete(input: string): void {
    if (typeof input === 'undefined' || input === null || input.length === 0) {
      this.clearCustomer()
      return
    }
    if (input.length < 3) {
      return
    }
    this.search = input
    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }
    this.debounce = window.setTimeout(async () => {
      this.loading = true
      this.arrowPosition = undefined
      await this.searchCustomers()
      this.loading = false
    }, 250)
  }

  selectCustomer(customer: SharedContactBasicInfo): void {
    if (!customer) {
      return
    }
    this.search = customer.email
  }

  clearCustomer(): void {
    this.search = ''
    this.$emit('clear', null)
    this.searchCustomers()
  }

  uuidv4(): any {
    return v4()
  }

  isAlreadySharedWithCustomer(customerId: number): boolean {
    if (!this.sharedList || this.tripContactMode) {
      return false
    }
    return !!this.sharedList.find((c) => c.customerId === customerId)
  }

  buttonText(customer: Customer): string {
    return !this.isAlreadySharedWithCustomer(customer.customerId)
      ? 'Share'
      : 'Unshare'
  }

  handleInviteNew(): void {
    this.$emit('invite', this.search)
    this.search = ''
  }

  onFocus(): void {
    this.isOpen = true
  }

  onBlur(): void {
    this.isOpen = false
  }

  goBack(): void {
    this.$emit('back')
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/colors.scss';

::v-deep button.v-icon.v-icon--link {
  color: rgba($black, 0.5);
  margin-top: 14px !important;
  margin-right: 16px !important;
}
</style>
