<template>
  <CUModal :value="value" @input="$emit('input', $event)">
    <template #title>Importing Riders</template>
    <template #text>
      We are loading your rider data. You may need to refresh the page a few
      times.
    </template>
    <template #actions>
      <div class="d-flex grow" />
      <v-btn
        id="import-user-ok-button"
        small
        class="primary"
        @click="$emit('input', false)"
      >
        OK
      </v-btn>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class OrganizationImportRiderDialog extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean
}
</script>
