import { render, staticRenderFns } from "./QuoteSalesSMSOptIn.vue?vue&type=template&id=564d4ede&scoped=true"
import script from "./QuoteSalesSMSOptIn.vue?vue&type=script&setup=true&lang=ts"
export * from "./QuoteSalesSMSOptIn.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./QuoteSalesSMSOptIn.vue?vue&type=style&index=0&id=564d4ede&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "564d4ede",
  null
  
)

export default component.exports