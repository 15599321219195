<template>
  <CUModal :value="value" width="500px" @input="$emit('input', $event)">
    <template #text>
      <div class="text-center w-full padding-t-6">
        <v-icon size="70" class="margin-b-3">$color-cash</v-icon>
        <h2>{{ $t('paymentSuccess.TITLE') }}</h2>
        <p>{{ confirmationMessage }}</p>
      </div>
    </template>
    <template #actions>
      <v-btn
        id="success-modal-button-ok"
        block
        color="primary"
        @click="$emit('input', false)"
      >
        {{ $t('common.CONTINUE') }}
      </v-btn>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import { PaymentMethodTypeKey } from '@/utils/enum'

@Component
export default class PaymentSuccessDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: String }) readonly paymentMethod: PaymentMethodTypeKey

  get confirmationMessage(): TranslateResult {
    if (this.paymentMethod === PaymentMethodTypeKey.CreditCard) {
      return this.$t('paymentSuccess.BODY_CARD')
    }
    return this.$t('paymentSuccess.BODY_CHECK')
  }
}
</script>
