<template>
  <CUItinerary>
    <CUItineraryItem
      v-for="stopIndex in count"
      :key="`stop-skeleton-loader-${stopIndex}`"
      loading
    >
      <div class="padding-l-3 padding-b-4 margin-t-n1">
        <SkeletonBox class="margin-y-1 margin-x-0 h-18 w-200" />
        <SkeletonBox class="margin-y-1 margin-x-0 h-14 w-248" />
        <SkeletonBox class="margin-y-1 margin-x-0 h-18 w-248" />
        <SkeletonBox
          v-if="stopIndex > 1 && stopIndex < count"
          class="margin-y-1 margin-x-0 h-18 w-248"
        />
      </div>
    </CUItineraryItem>
  </CUItinerary>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUItinerary from '@/components/CUItinerary.vue'
import CUItineraryItem from '@/components/CUItineraryItem.vue'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({ components: { CUItinerary, CUItineraryItem, SkeletonBox } })
export default class CUItinerarySkeletonLoader extends Vue {
  @Prop({ type: Number, required: false, default: 3 }) readonly count: number
}
</script>
