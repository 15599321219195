import Vue from 'vue'
import { Module, VuexModule } from 'vuex-class-modules'
import { NavigationItem } from '@/models/NavigationItem'
import i18n from '@/plugins/i18n'
import support from '@/store/modules/support'
import auth from '@/store/modules/auth'
import store from '@/store/index'
import { SplitFeatureFlag } from "@/utils/enum";

@Module({ generateMutationSetters: true })
class AppMenuModule extends VuexModule {
  get menuItems(): NavigationItem[] {
    if (!auth.user) {
      // auth.user used instead of isTokenSet because isTokenSet will cause momentary flashing of items before the user profile is loaded in and items can be properly calculated
      return []
    }
    const items = [
      {
        title: i18n.t('menuItems.QUOTES'),
        link: 'quote-index',
        name: 'quotes',
        hide: false,
        location: 'main',
      },
      {
        title: i18n.t('menuItems.RESERVATIONS'),
        link: 'reservation-index',
        name: 'reservations',
        hide: false,
        location: 'main',
      },
      {
        title: i18n.t('menuItems.SUPPORT'),
        name: 'support',
        action: () => support.open(null),
        hide: auth.isEnterpriseAdmin || auth.isEnterpriseClient,
        location: 'main',
      },
      {
        title: i18n.t('menuItems.SELF_SERVE'),
        link: 'self-serve',
        name: 'self-serve',
        location: 'main',
      },
      {
        title: i18n.t('menuItems.TRACKING'),
        link: 'enterprise-tracking',
        name: 'enterprise-tracking',
        hide: !auth.isEnterpriseAdmin && !auth.isEnterpriseClient,
        location: 'main',
      },
      {
        title: i18n.t('accountItems.PROFILE'),
        link: 'profile',
        name: 'profile',
        hide: false,
        location: 'dropdown',
      },
      {
        title: i18n.t('accountItems.ACCOUNT'),
        link: 'organization',
        name: 'organization',
        hide: !auth.isCustomerAccountAdmin,
        location: 'dropdown',
      },
      {
        title: i18n.t('accountItems.FINANCE'),
        link: 'finance',
        name: 'finance',
        hide: !auth.isFinanceAdmin,
        location: 'dropdown',
      },
    ]

    const isSalesBotRevampEnabled = Vue.prototype.$split.isFeatureEnabled(SplitFeatureFlag.SalesBotRevamp)
    if (isSalesBotRevampEnabled) {
      let selfServeItemIdx = items.findIndex((item) => item.name === 'self-serve')
      items[selfServeItemIdx] = {
        title: i18n.t('menuItems.SELF_SERVE'),
        link: 'quote-builder',
        name: 'quote-builder',
        location: 'main',
      }
    }

    return items.filter((item) => !item.hide)
  }
}

export default new AppMenuModule({ store, name: 'appMenu' })
