<template>
  <div class="d-flex flex-column align-center padding-t-14">
    <CUItinerary class="max-w-600 w-full">
      <CUItineraryItem
        v-for="(stop, stopIndex) in stops"
        :key="`stop-itinerary-item-${stop.orderIndex}-${stopIndex}`"
        class="w-full"
        upcoming
        :last-stop="isLastStop(stopIndex)"
      >
        <TripModificationItineraryStop
          :trip="requestedTrip"
          :stop="stop"
          :hide-delete="hideDelete"
          :stop-index="stopIndex"
          @update:address="updateAddress"
          @update:datetime="updateDateTime"
          @update:vehicleNeededEntireTrip="updateVehicleNeededEntireTrip"
          @update:has-error="updateHasError(stopIndex, $event)"
          @update:addStop="addStopFollowUp"
        />
      </CUItineraryItem>
    </CUItinerary>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import CUItinerary from '@/components/CUItinerary.vue'
import CUItineraryItem from '@/components/CUItineraryItem.vue'
import TripModificationItineraryStop from '@/components/TripModificationItineraryStop.vue'
import {
  TripModificationTrip,
  TripModificationStop,
  Address,
} from '@/models/dto'
import { TripModificationAddress } from '@/classes/TripModificationAddress'
import tripModification from '@/store/modules/tripModification'
import { validateAddressStateAndCountry } from '@/utils/validators'

@Component({
  components: { CUItinerary, CUItineraryItem, TripModificationItineraryStop },
})
export default class TripModificationItinerary extends Vue {

  @Watch('shouldDisableForwardButton', { immediate: true })
  shouldDisableForwardButtonChanged(): void {
    tripModification.setForwardButtonDisabled(this.shouldDisableForwardButton)
  }

  stopErrorList: number[] = []

  get requestedTrip(): TripModificationTrip {
    return tripModification.requestedTrip
  }

  get stops(): TripModificationStop[] {
    return this.requestedTrip?.stops || []
  }

  get stopCount(): number {
    return this.stops.length
  }

  get hideDelete(): boolean {
    return this.stopCount <= 2
  }

  get shouldDisableForwardButton(): boolean {
    if (!this.stops) {
      return false
    }

    return this.stops.some((stop) => !this.isStopValid(stop))
  }

  isLastStop(stopIndex): boolean {
    return this.stopCount - 1 === stopIndex
  }

  isStopValid(stop: TripModificationStop): boolean {
    const hasLatLng = !!stop?.address?.lat && !!stop?.address?.lng
    const addressHasStateAndCountry = validateAddressStateAndCountry(
      stop?.address
    )
    const isAddressValid = hasLatLng && addressHasStateAndCountry

    const hasPickupDatetime = !!stop?.pickupDatetime
    const isLastStop = this.isLastStop(stop.orderIndex)
    const isFirstStop = stop.orderIndex === 0
    const hasTravelTimeFromPreviousStop =
      stop.travelTimeFromPreviousStopInSeconds !== null
    const areStopTimesValid =
      (hasPickupDatetime || isLastStop) &&
      (hasTravelTimeFromPreviousStop || isFirstStop)

    const hasError = this.stopErrorList.includes(stop.orderIndex)

    return isAddressValid && areStopTimesValid && !hasError
  }

  updateHasError(stopIndex: number, hasError: boolean): void {
    if (hasError) {
      this.stopErrorList.push(stopIndex)
    } else {
      this.stopErrorList = this.stopErrorList.filter(
        (orderIndex) => orderIndex !== stopIndex
      )
    }
  }

  async updateAddress(event: {
    address: Address
    index: number
  }): Promise<void> {
    const { address, index } = event
    this.requestedTrip.stops[index].address = new TripModificationAddress(
      address
    )

    if (address === null) {
      this.removeTimeEstimationFromAffectedStops(index)
    } else {
      await tripModification.estimateTrip(index)
      this.updateAddressRisks()
    }
  }

  removeTimeEstimationFromAffectedStops(index: number): void {
    this.requestedTrip.stops[index].travelTimeFromPreviousStopInSeconds = null
    if (index + 1 < this.stopCount) {
      this.requestedTrip.stops[
        index + 1
      ].travelTimeFromPreviousStopInSeconds = null
    }
  }

  updateAddressRisks(): void {
    // TODO: Implement endpoint to calculate address risks
  }

  async addStopFollowUp(event: {
    index: number
  }): Promise<void> {
    try {
      const { index } = event
      await tripModification.clearEstimatedValues(index)
    } catch (error) {
      console.error('Failed to clear estimated values', error)
    }

  }

  async updateDateTime(event: {
    date: string
    time: string
    index: number
  }): Promise<void> {
    const { date, time, index } = event
    const stop = this.stops[index]
    const zone = stop?.address?.zoneId
    const stopDatetime = DateTime.fromISO(`${date}T${time}`, { zone })

    if (!date || !time || !zone || !stopDatetime) {
      this.requestedTrip.stops[index].pickupDatetime = null
    } else {
      this.requestedTrip.stops[index].pickupDatetime = stopDatetime.toISO()
    }
    await tripModification.estimateTrip(index)
  }

  updateVehicleNeededEntireTrip(event: boolean): void {
    this.requestedTrip.vehicleNeededEntireTrip = event
  }
}
</script>

<style lang="scss" scoped>
* {
  touch-action: manipulation;
}
</style>
