<template>
  <p class="font-12  margin-b-3">
    <v-tooltip top color="gray">
      <template #activator="{ on, attrs }">
        <v-icon
          class="cursor-pointer margin-r-2 margin-b-0 margin-l-0"
          :style="{ 'margin-top': '-2px' }"
          v-bind="attrs"
          v-on="on"
        >
          error
        </v-icon>
      </template>
      <span
        class="tooltip-text font-12  text-white display-block margin-x-0"
        :style="{
          'padding-top': '6px',
          'padding-bottom': '6px',
          'line-height': 1.45,
        }"
      >
        {{ tooltipText }}
      </span>
    </v-tooltip>
    {{ messageText }}
  </p>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class SoldOutDetail extends Vue {
  @Prop({ type: String, default: '' }) readonly messageText: string
  @Prop({ type: String, default: '' }) readonly tooltipText: string
}
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 960px) {
  .v-tooltip__content {
    max-width: 430px !important;
    margin-left: 203px;
    margin-top: 8px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 960px) {
  .v-tooltip__content {
    max-width: 330px !important;
    margin-left: 153px;
    margin-top: 8px;
  }
}
@media only screen and (max-width: 599px) {
  .v-tooltip__content {
    max-width: 100% !important;
    margin-left: 14px;
    margin-right: 26px;
    margin-top: 8px;
  }
}
</style>
