<template>
  <div class="d-flex flex-column">
    <h3 class="margin-b-0 subtitle-1">
      {{ $t('reservationDetail.contactInformation.TITLE') }}
    </h3>
    <ReservationDetailContactInfomationVerifySMS
      v-if="!isUserSMSVerified"
      :is-verify-phone-modal-open="isVerifyPhoneModalOpen"
    />
    <template v-for="(contactType, contactTypeIndex) in contacts">
      <h4
        :key="`contact-label-${contactTypeIndex}`"
        class="margin-t-3 font-book text-gray-light"
      >
        {{ contactType.label }}
      </h4>
      <v-row
        v-for="(contact, contactIndex) in contactType.list"
        :key="`contact-row-${contactTypeIndex}-${contact.id}-${contactIndex}`"
        :class="{
          'elevation-0 margin-y-3 border-1 border-solid border-border-gray rounded contact-card-mobile':
            $vuetify.breakpoint.xsOnly,
          'last-of-type:margin-b-0 align-center': $vuetify.breakpoint.smAndUp,
        }"
      >
        <v-col
          v-if="contact.firstName || contact.lastName"
          cols="6"
          sm="6"
          md="3"
          :class="{
            'padding-b-0': $vuetify.breakpoint.xsOnly,
            'padding-b-1': contactIndex !== contactType.list.length - 1,
          }"
        >
          <p class="font-14  margin-t-0">
            {{ $t('reservationDetail.contactInformation.NAME') }}
          </p>
          <p class="margin-t-n1 font-medium">
            {{ contact.firstName }} {{ contact.lastName }}
          </p>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="5"
          :class="{
            'padding-b-0': contact.phone && $vuetify.breakpoint.xsOnly,
            'padding-b-1': contactIndex !== contactType.list.length - 1,
            'padding-b-2': !contact.phone && $vuetify.breakpoint.xsOnly,
          }"
        >
          <p class="font-14  margin-t-0">
            {{ $t('reservationDetail.contactInformation.EMAIL') }}
          </p>
          <p
            class="margin-t-n1 font-medium white-space-nowrap overflow-hidden text-overflow-ellipsis"
          >
            {{ contact.email }}
          </p>
        </v-col>
        <v-col
          v-if="contact.phone"
          cols="12"
          sm="6"
          md="auto"
          :class="{
            'padding-b-0': $vuetify.breakpoint.xsOnly,
            'padding-b-1': contactIndex !== contactType.list.length - 1,
          }"
        >
          <div class="d-flex align-center">
            <label class="font-14  margin-t-0">
              {{ $t('reservationDetail.contactInformation.PHONE_NUMBER') }}
            </label>
            <CUIcon
              v-if="isSMSEnabled(contactType.label)"
              color="primary"
              view-box="0 0 24 24"
              width="16px"
              height="16px"
              class="margin-l-1 cursor-default"
            >
              verified
            </CUIcon>
          </div>
          <p
            class="margin-t-n1 font-medium"
            :class="{
              'padding-b-2': $vuetify.breakpoint.xsOnly,
            }"
          >
            {{ phoneFormatFilter(contact.phone) }}
          </p>
        </v-col>
        <template
          v-if="allowUserManagement && contact.id !== bookingContact.id"
        >
          <v-spacer />
          <v-col
            class="shrink"
            :class="{ 'delete-icon-mobile': $vuetify.breakpoint.xsOnly }"
          >
            <CUIcon
              :id="`${toKebab(
                contactType.label
              )}-remove-contact-${contactIndex}`"
              color="gray-mid-light"
              hover-color="error"
              class="margin-b-n3"
              width="32"
              height="32"
              @click="remove(contact.id)"
            >
              delete
            </CUIcon>
          </v-col>
        </template>
      </v-row>
      <div
        v-if="contactTypeIndex === 0 && !showTripContact && allowUserManagement"
        :key="`contact-label-${contactTypeIndex}-trip-contact`"
        class="d-flex flex-column margin-t-2"
      >
        <p class="align-self-start">
          {{
            $t('reservationDetail.contactInformation.IS_THERE_A_TRIP_CONTACT')
          }}
        </p>
        <p
          class="font-bold text-primary margin-t-0 padding-t-0 cursor-pointer align-self-start"
          @click="openTripContactDialog"
        >
          {{ $t('reservationDetail.contactInformation.ADD_A_TRIP_CONTACT') }}
        </p>
      </div>
    </template>
    <template v-if="allowUserManagement && !isFinished">
      <p
        class="font-bold text-primary cursor-pointer align-self-start"
        @click="isShareReservationDialogOpen = true"
      >
        {{ $t('reservationDetail.contactInformation.SHARE_WITH_RIDERS') }}
      </p>
      <ReservationDetailShareDialog
        v-model="isShareReservationDialogOpen"
        :reservation-id="reservation.reservationId"
        :shared-list="sharedList"
        @refresh-shared-list="$emit('refresh-shared-list')"
      />
      <v-dialog
        v-model="isTripContactDialogOpen"
        :fullscreen="$vuetify.breakpoint.xs"
        max-width="500px"
      >
        <ReservationDetailTripContactForm
          :reservations="[reservation]"
          :shared-list="sharedList"
          closeable
          @close="isTripContactDialogOpen = false"
          @refresh="$emit('refresh')"
        />
      </v-dialog>
    </template>
  </div>
</template>

<script lang="ts">
import {
  EditTripContactPayload,
  ReservationDetail,
  SharedList,
} from '@/models/dto'
import { phoneFormatFilter } from '@/utils/phone'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Contact } from '@/models/Contact'
import { Customer } from '@/models/dto/Customer'
import ReservationDetailShareDialog from '@/components/ReservationDetailShareDialog.vue'
import ReservationDetailTripContactForm from '@/components/ReservationDetailTripContactForm.vue'
import ReservationDetailContactInfomationVerifySMS from '@/components/ReservationDetailContactInfomationVerifySMS.vue'
import reservation from '@/services/reservation'
import trip from '@/services/trip'
import { toKebab } from '@/utils/string'
import auth from '../store/modules/auth'
import { ReservationStatusKey } from '../utils/enum'
import customer from '@/services/customer'
import { EventBus } from '@/utils/eventBus'
import { TranslateResult } from 'vue-i18n'

@Component({
  components: {
    ReservationDetailShareDialog,
    ReservationDetailTripContactForm,
    ReservationDetailContactInfomationVerifySMS,
  },
})
export default class ReservationDetailContactInfo extends Vue {
  @Prop({ type: Object, required: true })
  readonly reservation: ReservationDetail
  @Prop({ type: Array, required: true })
  readonly sharedList: SharedList
  @Prop({ type: Boolean, required: false, default: false })
  readonly allowUserManagement: boolean
  @Prop({ type: Boolean, required: false, default: false })
  readonly isTripContactModalOpen: boolean
  @Prop({ type: Boolean, required: false, default: false })
  readonly isRiderModalOpen: boolean
  @Prop({ type: Boolean, required: false, default: false })
  readonly isVerifyPhoneModalOpen: boolean
  phoneFormatFilter = phoneFormatFilter
  isShareReservationDialogOpen = false
  isTripContactDialogOpen = false
  toKebab = toKebab
  tripContactCustomer: Customer = null
  customer: Customer = null

  @Watch('isTripContactModalOpen')
  isTripContactModalOpenChanged(val: boolean): any {
    this.isTripContactDialogOpen = !this.showTripContact && this.isTripContactModalOpen
  }

  @Watch('isRiderModalOpen')
  isRiderModalOpenChanged(val: boolean): any {
    this.isShareReservationDialogOpen = this.isRiderModalOpen
  }

  get isFinished(): boolean {
    return this.reservation?.reservationStatus === ReservationStatusKey.Finished
  }

  get isUserSMSVerified(): boolean {
    return auth.customer?.smsConfirmed
  }

  get isCustomerSMSEnabled(): boolean {
    return this.customer?.smsConfirmed
  }

  get isTripContactSMSEnabled(): boolean {
    return this.tripContactCustomer?.smsConfirmed
  }

  get bookingContact(): Contact {
    return {
      id: this.customer?.customerId,
      firstName: this.customer?.firstName,
      lastName: this.customer?.lastName,
      email: this.customer?.email,
      phone: this.customer?.phone,
    }
  }

  get tripContact(): Contact {
    return {
      id: this.tripContactCustomer?.customerId,
      firstName: this.tripContactCustomer?.firstName,
      lastName: this.tripContactCustomer?.lastName,
      email: this.tripContactCustomer?.email,
      phone: this.tripContactCustomer?.phone,
    }
  }

  get showTripContact(): boolean {
    return (
      this.reservation?.tripContact?.customerId &&
      this.reservation?.tripContact?.customerId !== this.customer?.customerId
    )
  }

  get invitedUserContacts(): Contact[] {
    if (!this.sharedList.length) {
      return []
    }

    let sharedList = this.sharedList
    if (!this.allowUserManagement) {
      sharedList = sharedList.filter((user) => user.customerId === auth.userId)
    } else {
      const tripContactId = this.tripContact?.id || this.reservation?.tripContact?.customerId
      sharedList = sharedList.filter(
        (user) =>
          ![tripContactId, this.bookingContact.id].includes(
            user.customerId
          )
      )
    }
    return sharedList.map((user) => {
      return {
        id: user.customerId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
      }
    })
  }

  get contacts(): { label: TranslateResult; list: Contact[] }[] {
    const contacts = []

    const booking = {
      label: this.$t('reservationDetail.contactInformation.BOOKING_CONTACT'),
      list: [this.bookingContact],
    }
    contacts.push(booking)

    if (this.showTripContact) {
      const trip = {
        label: this.$t('reservationDetail.contactInformation.TRIP_CONTACT'),
        list: [this.tripContact],
      }
      contacts.push(trip)
    }

    if (this.invitedUserContacts.length) {
      const invited = {
        label: this.$t(
          'reservationDetail.contactInformation.RIDERS_WITH_ACCESS'
        ),
        list: this.invitedUserContacts,
      }
      contacts.push(invited)
    }

    return contacts
  }

  mounted(): void {
    this.refreshContacts()

    EventBus.$on('refresh-contacts', this.refreshContacts)
    this.isTripContactDialogOpen = !this.showTripContact && this.isTripContactModalOpen
    this.isShareReservationDialogOpen = this.isRiderModalOpen
  }

  beforeUnmount(): void {
    EventBus.$off('refresh-contacts')
  }

  isSMSEnabled(contactTypeLabel: string): boolean {
    if (contactTypeLabel === 'Booking Contact') {
      return this.isCustomerSMSEnabled
    } else if (contactTypeLabel === 'Trip Contact') {
      return this.isTripContactSMSEnabled
    } else {
      return false
    }
  }

  refreshContacts(): void {
    this.loadCustomer()
    this.loadTripContact()
  }

  openTripContactDialog(): void {
    this.isTripContactDialogOpen = true
  }

  async remove(userId: number): Promise<void> {
    if (!userId) {
      return
    }
    if (userId === this.tripContact.id) {
      const tripId = this.reservation.tripId
      const payload: EditTripContactPayload = {
        tripContact: {
          customerId: null,
        },
      }
      await trip.editTripContact(tripId, payload)
      this.tripContactCustomer = null
      this.$emit('refresh')
      return
    }
    await reservation.unshare(this.reservation.reservationId, userId)
    this.$emit('refresh-shared-list')
  }

  async loadTripContact(): Promise<void> {
    if (!this.reservation?.tripContact?.customerId) {
      return
    }

    const tripContactResponse = await customer.byId(
      this.reservation?.tripContact?.customerId
    )

    this.tripContactCustomer = tripContactResponse.data.customer
  }

  async loadCustomer(): Promise<void> {
    const customerResponse = await customer.byId(
      this.reservation?.customer?.customerId
    )
    this.customer = customerResponse.data.customer
  }
}
</script>

<style scoped>
.contact-card-mobile {
  margin-right: 1px;
  margin-left: 1px;
}

.delete-icon-mobile {
  display: block;
  width: 20px;
  position: absolute;
  right: 12%;
}
</style>
