<template>
  <div>
    <div
      class="d-flex align-center justify-space-between cursor-pointer margin-b-3"
      @click="isReviewExpanded = !isReviewExpanded"
    >
      <h3>{{ $t('reservationDetail.review.TITLE') }}</h3>
      <CUIcon
        view-box="0 0 24 24"
        icon-name="keyboard-arrow"
        :width="24"
        :height="24"
        color="gray"
        class="transition-all transition-duration-200 transition-ease-in-out"
        :style="{
          transform: isReviewExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
        }"
      >
        keyboard_arrow_down
      </CUIcon>
    </div>
    <v-expand-transition>
      <TripReviewForm v-show="isReviewExpanded" :reservation="reservation" />
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ReservationDetail, ReservationReview } from '@/models/dto'
import TripReviewForm from '@/components/TripReviewForm.vue'

@Component({ components: { TripReviewForm } })
export default class ReservationDetailReview extends Vue {
  @Prop({ type: Object, default: () => {} })
  readonly reservation: ReservationDetail

  isReviewExpanded = true

  get reservationReview(): ReservationReview {
    return this.reservation?.reservationReview
  }
}
</script>
