<template>
  <CUModal
    :value="value"
    width="520px"
    max-width="520px"
    :closeable="false"
    @input="close"
  >
    <template #title>{{ $t('itineraryRequirements.TITLE') }}</template>
    <template #text>
      <div class="d-flex w-full flex-column">
        <p class="font-16 flex-shrink-1 margin-t-0">
          {{ $t('itineraryRequirements.PROVIDING_DETAILS') }}
        </p>
        <ul class="margin-l-6 margin-t-2">
          <li
            v-for="(requirement, requirementIndex) in requirements"
            :key="`hotel-requirement-${requirement}-${requirementIndex}`"
            class="margin-t-2 font-16"
            :style="{ 'list-style': 'disc' }"
          >
            {{ requirement }}
          </li>
        </ul>
        <p>
          <a class="font-bold margin-t-4" @click.stop="openSupportModal">
            {{ $t('common.MESSAGE_US') }}
          </a>
          {{ $t('itineraryRequirements.CALL_TO_PROVIDE_INFO') }}
        </p>
        <v-btn class="primary margin-t-4" @click="close">
          Close
        </v-btn>
      </div>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import support from '@/store/modules/support'
import { SupportTicketKey } from '@/utils/enum'

@Component
export default class ItineraryRequirementsDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: Number, default: null }) readonly reservationId: number

  requirements = [
    this.$t('itineraryRequirements.requirements.AVOID_SURPRISE_CHARGES'),
    this.$t('itineraryRequirements.requirements.DOT_COMPLIANCE'),
    this.$t('itineraryRequirements.requirements.PLAN_ROUTES'),
  ]

  openSupportModal(): void {
    this.close()
    support.open({
      reservationId: this.reservationId,
      ticketTypeKey: SupportTicketKey.Change,
    })
  }

  close(): void {
    this.$emit('input', false)
  }
}
</script>
