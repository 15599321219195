<template>
  <div class="d-flex font-24 flex-column">
    <div>
      <h2 v-if="featuredBidCompanyName && isBrandedQuoteFlowEnabled">Your Quote for {{featuredBidCompanyName}}</h2>
    </div>
    <div class="d-flex font-24 align-center">
      <span v-if="featuredBidCompanyName && isBrandedQuoteFlowEnabled" class="margin-t-0">{{ title }}</span>
      <h2 v-else class="margin-t-0">{{ title }}</h2>
      <span class="margin-l-2">
        •
      </span>
      <span class="margin-l-2">
        {{ `ID ${quoteId}` }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { formatQuotePickupDestinationText } from '@/utils/string'
import { QuoteDetailV2 } from '../models/dto/QuoteDetailV2'

@Component({})
export default class BookingHeader extends Vue {
  @Prop({ type: Object, required: true }) readonly quote: QuoteDetailV2
  @Prop({ type: String, required: false }) readonly featuredBidCompanyName: string
  @Prop({ type: Boolean, required: true }) readonly isBrandedQuoteFlowEnabled: boolean

  get quoteId(): number {
    return this.quote?.quoteId
  }

  get title(): string {
    return formatQuotePickupDestinationText(this.quote, this.$t('common.TO'))
  }

}
</script>
