<template>
  <div class="w-full" id="sales-bot-itinerary-container">
    <SalesBotItineraryItem
      v-for="(itineraryStop, stopIndex) in itineraryStops"
      :key="`sales-bot-itinerary-item-${stopIndex}`"
      :id="`sales-bot-itinerary-item-${stopIndex}`"
      :first-stop="stopIndex === 0"
      :last-stop="itineraryStop.isLastStop"
    >
      <SalesBotItineraryStop
        :key="`sales-bot-itinerary-stop-${stopIndex}`"
        :id="`sales-bot-itinerary-stop-${stopIndex}`"
        :index="stopIndex"
        :stop="itineraryStop.stop"
        :stop-type-label="itineraryStop.stopTypeLabel"
        :error-message="itineraryStop.errorMessage"
        :hide-delete="itineraryStop.hideDelete"
        :hide-add="itineraryStop.hideAdd"
        :is-last-stop="itineraryStop.isLastStop"
        :show-keep-on-site="itineraryStop.showKeepOnSite"
        :vehicle-needed-entire-trip="vehicleNeededEntireTrip"
        :minimum-datetime="itineraryStop.minimumDatetime"
        :arrival-text="itineraryStop.arrivalText"
        :bias-coordinate="previousStopCoordinates[stopIndex]"
        @stop:add="addBlankStop(stopIndex + 1)"
        @stop:delete="removeStop(stopIndex)"
        @stop:update="updateStop(stopIndex, $event)"
        @vehicle-needed-entire-trip:update="setVehicleNeededEntireTrip($event)"
      />
    </SalesBotItineraryItem>
    <div class="margin-t-3 margin-l-8">
      <slot name="next-button" :validate="validate" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import SalesBotItineraryItem from '@/components/SalesBotItineraryItem.vue'
import SalesBotItineraryStop from '@/components/SalesBotItineraryStop.vue'
import { useSalesBotStore } from '@/store/modules/salesBot'

const salesBotStore = useSalesBotStore()
const { vehicleNeededEntireTrip, addBlankStop, removeStop, updateStop, setVehicleNeededEntireTrip } = salesBotStore
const itineraryStops = computed(() => salesBotStore.itineraryStops)

const emit = defineEmits(['form:validate'])

const stopCount = computed<number>(() => {
  return itineraryStops.value?.length || 0
})

const invalidStopCount = computed<number>(() => itineraryStops.value?.filter(({isValid}) => !isValid)?.length || 0)
const areAllStopsValid = computed<boolean>(() => invalidStopCount.value === 0)

/**
 * Gets the LatLng of the last filled out stop (skipping empty ones)
 * in order to bias the address search results for a subsequent stop.
 *
 * Tracked as a computed value so we can re-evaluate whenever previous
 * stop addresses are changed.
 *
 * @param stopIndex - The index of the stop we want to bias results for
 * @returns a LatLng object (or null if there is not one among previous stops)
 */
const previousStopCoordinates = computed(() => {
  return itineraryStops.value.map((_, stopIndex) => {
    for (let i = stopIndex - 1; i >= 0; i--) {
      const address = itineraryStops.value[i]?.stop?.address
      if (address?.lat && address?.lng) {
        return { lat: address.lat, lng: address.lng }
      }
    }
    return null
  })
})

const validate = (): boolean => {
  const isValid = stopCount.value > 0 && areAllStopsValid.value
  emit('form:validate', isValid)
  return isValid
}

watch(itineraryStops, () => {
  validate()
})

watch(invalidStopCount, (_) => {
  validate()
})
</script>

<style lang="scss" scoped>
* {
  touch-action: manipulation;
}
</style>
