<template>
  <div>
    <v-menu
      v-model="teamOptionsMenuOpen"
      offset-y
      :close-on-click="false"
      internal-activator
    >
      <template #activator="{ on, attrs }">
        <v-icon
          :id="`organizations-menu-team`"
          class="kebab-button margin-t-n1 margin-l-1"
          :class="{ 'margin-r-n5': $vuetify.breakpoint.smAndDown }"
          v-bind="attrs"
          v-on="on"
        >
          more_vert
        </v-icon>
      </template>
      <v-list class="kebab-dropdown padding-a-0">
        <OrganizationNewTeamDialog
          v-if="isEnterpriseAccount"
          @success="$emit('success')"
          @close="teamOptionsMenuOpen = false"
        />
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import OrganizationNewTeamDialog from '@/components/OrganizationNewTeamDialog.vue'
import { Team } from '@/models/dto'
@Component({
  components: {
    OrganizationNewTeamDialog,
  },
})
export default class OrganizationTeamsActionsMenu extends Vue {
  @Prop({ type: Boolean }) readonly isEnterpriseAccount: boolean
  @Prop({ type: Array, required: true }) readonly teams: Team[]

  index = 0
  team: Team = null
  teamOptionsMenuOpen = false
}
</script>

<style lang="scss">
@import '@/scss/colors.scss';
.v-list {
  &.kebab-dropdown {
    .v-list-item {
      cursor: pointer;
      &:hover {
        background: $border-gray;
      }
      .v-list-item__title {
        font-size: 0.875rem;
        font-family: 'Inter Medium', Arial, sans-serif;
      }
    }
  }
}
</style>
