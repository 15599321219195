<template>
  <div
    class="w-full d-flex h-60 align-center justify-center border-radius-regular border-2 border-dashed position-relative cursor-pointer"
    :class="importDivClass"
    @dragover.prevent
    @drop.prevent="handleDrop"
  >
    <input
      :disabled="submittedFileName"
      type="file"
      class="opacity-0 w-full h-full position-absolute top-0 left-0 cursor-pointer"
      @change="handleBrowse"
    />
    <CUIcon
      v-if="!submittedFileName"
      id="upload-file-icon"
      key="upload-file-icon"
      :color="!errorMessage ? 'primary' : 'error'"
      height="32"
      width="32"
      view-box="0 0 24 24"
      class="margin-x-2"
    >
      upload_circle
    </CUIcon>
    <h4 class="font-bold font-weight-400" :class="{ 'text-red': errorMessage }">
      {{ importMessage }}
    </h4>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ImportRiderFileUpload extends Vue {
  errorMessage: string | null = null
  submittedFileName: string | null = null

  get importDivClass(): string {
    if (this.errorMessage) {
      return 'background-red-10 border-error'
    }
    if (this.submittedFileName) {
      return 'background-green-10 border-success'
    }
    return 'background-blue-10 border-primary'
  }

  get importMessage(): string {
    if (this.errorMessage) {
      return this.errorMessage
    }
    if (this.submittedFileName) {
      return `File submitted: ${this.submittedFileName}`
    }
    return 'Drop 1 file here, or click to browse'
  }

  handleBrowse(event: Event): void {
    const target = event.target as HTMLInputElement
    this.addFiles(target.files)
  }
  handleDrop(event: DragEvent): void {
    if (this.submittedFileName) {
      return
    }
    this.addFiles(event.dataTransfer.files)
  }

  addFiles(files: FileList): void {
    this.errorMessage = null
    const filesArray = Array.from(files)
    if (filesArray.length > 1) {
      this.errorMessage = `Only 1 file allowed to upload at a time. Please try again.`
      return
    }
    this.submittedFileName = files.item(0).name
    this.$emit('file-added', files[0])
  }

  clearFile(): void {
    this.submittedFileName = null
  }
}
</script>
