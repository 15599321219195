<template>
  <div v-if="loading" class="d-flex align-center justify-center w-full background-loading-background border-radius-8 min-h-200">
    <v-progress-circular indeterminate color="primary" :size="32" />
  </div>
  <div v-else>
     <VehicleOptionCard
      v-for="(vehicles, vehiclesIndex) in vehicleOptions"
      :key="`vehicle-option-card-${vehiclesIndex}`"
      :selected="selected(vehicles)"
      :vehicles="vehicles"
      class="margin-b-4"
      @select="$emit('select', vehicles)"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import VehicleOptionCard from '@/components/VehicleOptionCard.vue'
import { WizardVehicle } from '@/models/dto'
import tripModification from '@/store/modules/tripModification'
import { deepCompareArrays } from '@/utils/array'

@Component({
  components: {
    VehicleOptionCard,
  }
})
export default class TripModificationVehicleOptions extends Vue {
  @Prop({ type: Array, required: true }) readonly vehicleOptions: WizardVehicle[][]
  @Prop({ type: Boolean, default: false }) readonly loading: boolean

  selected(vehicles: WizardVehicle[]): boolean {
    return deepCompareArrays(tripModification.requestedTrip.vehicles, vehicles)
  }

}
</script>
