<template>
  <div class="d-flex justify-center">
    <v-form ref="self-serve-sign-in-form" class="max-w-600 w-full">
      <v-row>
        <v-col cols="12" sm="12">
          <label>Email</label>
          <v-text-field
            id="sign-in-email-text-field"
            :value="email"
            hide-details="auto"
            outlined
            disabled
            readonly
          />
        </v-col>
        <v-col cols="12" sm="12">
          <label class="d-flex">
            Password
            <v-spacer />
            <a
              id="sign-in-forgot-password"
              :href="forgotPasswordUrl"
              target="_blank"
              class="margin-a-0 padding-a-0 text-decoration-none font-14"
              right
            >
              {{ $t('login.FORGOT_PASSWORD') }}
            </a>
          </label>
          <v-text-field
            id="sign-in-password-text-field"
            :value="password"
            placeholder="Enter password"
            :rules="[(v) => !!v || 'Password is required']"
            :append-icon="passwordHidden ? 'visibility' : 'visibility_off'"
            :type="passwordHidden ? 'password' : 'text'"
            hide-details="auto"
            required
            validate-on-blur
            outlined
            @click:append="() => (passwordHidden = !passwordHidden)"
            @input="customer.password = $event"
          />
        </v-col>
        <v-expand-transition>
          <v-col v-if="loginFailed" cols="12">
            <AlertBox active type="error">
              The password entered is not correct. Please try again.
            </AlertBox>
          </v-col>
        </v-expand-transition>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            color="primary"
            class="w-full"
            :class="{
              'max-w-320': $vuetify.breakpoint.smAndUp,
            }"
            :disabled="disabled"
            :loading="submitting"
            @click="submit"
          >
            Continue
            <CUIcon view-box="0 0 24 24" class="margin-l-2">
              arrow_right
            </CUIcon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script lang="ts">
import { SalesBotCustomer } from '@/models/dto'
import selfServe from '@/store/modules/selfServe'
import selfServeService from '@/services/selfServe'
import { Vue, Component, Watch } from 'vue-property-decorator'
import auth from '@/store/modules/auth'
import AlertBox from '@/components/AlertBox.vue'

@Component({ components: { AlertBox } })
export default class SelfServeCustomerSignIn extends Vue {
  submitting = false
  loginFailed = false
  passwordHidden = true

  @Watch('password')
  passwordChanged(): void {
    this.loginFailed = false
  }

  get customer(): SalesBotCustomer {
    return selfServe.quote.customer
  }

  get email(): string {
    return this.customer?.email || ''
  }

  get password(): string {
    return this.customer?.password || ''
  }

  get disabled(): boolean {
    return !this.password
  }

  get forgotPasswordUrl(): string {
    return this.$router.resolve({ name: 'forgot-password' })?.href
  }

  mounted(): void {
    selfServe.quote.customer.password = null
  }

  async login(): Promise<void> {
    const payload = {
      email: this.email,
      password: this.password,
    }

    const success = await auth.login(payload)
    if (!success) {
      this.loginFailed = true
    }
  }

  async submit(): Promise<void> {
    if (this.submitting) {
      return
    }
    this.loginFailed = false

    const form = this.$refs['self-serve-sign-in-form'] as any
    const isFormValid = form.validate()
    if (!isFormValid) {
      return
    }
    try {
      this.submitting = true
      await this.login()
      this.trackLogin()
      await this.createSelfServeQuotes()
    } catch (err) {
      console.error(err)
    }
    this.submitting = false
  }

  async createSelfServeQuotes(): Promise<void> {
    const payload = selfServe.quote.toObject()
    payload.customer = null
    const result = await selfServeService.create(payload)

    const quotes = result.data?.data
    const selfServeId = quotes?.[0]?.selfServeId

    selfServe.setSelfServeId(selfServeId)
    const quoteDetailsResponse = await selfServeService.bySelfServeIdV2(
      selfServeId
    )
    const quoteDetails = quoteDetailsResponse.data?.data
    selfServe.setQuoteDetails(quoteDetails)

    selfServe.getAvailableAmenities()
    selfServe.nextStep()
  }

  trackLogin(): void {
    this.$ga4.trackEvent('login', {
      isAuth0: this.$auth0.isInitialized,
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors.scss';
* {
  touch-action: manipulation;
}

::v-deep .v-input__append-inner {
  margin-top: 13px !important;
  margin-right: 15px;
}

::v-deep .v-text-field input {
  color: $gray-text !important;
  font-weight: 500 !important;
}
</style>
