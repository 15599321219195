<template>
  <CUCard :full-width="fullWidth">
    <div class="d-flex">
      <CUIcon
        view-box="0 0 24 24"
        class="w-26 h-26 cursor-default margin-r-3 flex-shrink-0"
        color="midnight-gray-700"
      >
        free_cancellation
      </CUIcon>
      <div>
        <h4 class="font-weight-600">
          {{ $t('refundPolicy.CHECKOUT_HEADER') }}
        </h4>
        <p class="margin-t-1 font-16">
          {{ cancellationPolicyCopy }}
        </p>
        <InfoModal v-model="isOpen" :modal-detail="refundPolicyModalDetail" max-width="860px">
          <template #activator="{ on, attrs }">
            <div class="margin-t-1">
              <a
                class="text-decoration-underline"
                :style="{ 'text-underline-offset': '3px' }"
                v-bind="attrs"
                v-on="on"
                @click="trackOpenRefundPolicy"
              >
                {{ $t('common.LEARN_MORE') }}
              </a>
            </div>
          </template>
        </InfoModal>
      </div>
    </div>
  </CUCard>
</template>
<script lang="ts">
import { TranslateResult } from 'vue-i18n'
import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import { CancellationRefundPercentage } from '@/utils/enum'
import InfoModal from '@/components/InfoModal.vue'
import { refundPolicyModalDetail } from '@/utils/infoModals'
import { RefundPolicyEntry } from '@/models/dto'
import { formattedCancellationPolicyDate } from '@/utils/cancellation'
import { InfoModalDetail } from '@/models/InfoModalDetail'

@Component({ components: { InfoModal } })
export default class ReservationCancellationPolicy extends Vue {
  @Prop({ type: String, required: true }) startDate!: string
  @Prop({ type: Boolean, required: false, default: false })
  readonly fullWidth!: boolean
  @Prop({ type: Number, required: true })
  refundPolicyPercent!: number
  @Prop({ type: String, required: true })
  refundPolicyPercentValidUntilTime!: string
  @Prop({ type: Array, required: true })
  refundPolicy!: RefundPolicyEntry[]
  @Prop({ type: Boolean, default: false }) readonly isElite: boolean
  @Prop({ type: Boolean, default: false }) readonly isSelfServe: boolean

  @Inject({ from: 'isInCheckout', default: false })
  readonly isInCheckout: boolean
  @Inject({ from: 'isInGuestCheckout', default: false })
  readonly isInGuestCheckout: boolean
  @Inject({ from: 'isInReservationCancel', default: false })
  readonly isInReservationCancel: boolean

  isOpen = false

  get refundPolicyModalDetail(): InfoModalDetail {
    return refundPolicyModalDetail(this.refundPolicy)
  }

  get cancellationPolicyCopy(): TranslateResult {
    if (this.refundPolicyPercent === CancellationRefundPercentage.Full) {
      // Full refund
      return this.$t('refundPolicy.CHECKOUT_100_PERCENT_REFUND', {
        date: formattedCancellationPolicyDate(
          this.refundPolicyPercentValidUntilTime
        ),
      })
    }
    if (this.refundPolicyPercent === CancellationRefundPercentage.None) {
      // No refund
      return this.$t('refundPolicy.CHECKOUT_NO_REFUND')
    }
    return this.$t('refundPolicy.CHECKOUT_PARTIAL_PERCENT_REFUND', {
      percent: this.refundPolicyPercent * 100,
      date: formattedCancellationPolicyDate(
        this.refundPolicyPercentValidUntilTime
      ),
    })
  }

  get threeDaysBeforeFirstTrip(): string {
    if (!this.startDate) {
      return null
    }
    return this.$dayjs(this.startDate).subtract(3, 'day').format('MMMM D')
  }

  get thirtyDaysBeforeFirstTrip(): string {
    if (!this.startDate) {
      return null
    }
    return this.$dayjs(this.startDate).subtract(30, 'day').format('MMMM D')
  }

  get page(): string {
    if (this.isInCheckout) {
      return 'Checkout'
    }
    if (this.isInGuestCheckout) {
      return 'Guest Checkout'
    }
    if (this.isInReservationCancel) {
      return 'Reservation Cancel'
    }
    return 'Other'
  }

  trackOpenRefundPolicy(): void {
    this.$ga4.trackEvent('open_refund_policy', {
      page: this.page,
      isSelfServe: this.isSelfServe,
      isElite: this.isElite,
      isAuth0: this.$auth0.isInitialized
    })
  }
}
</script>
