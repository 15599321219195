<template>
  <div>
    <div
      class="d-flex align-center justify-space-between cursor-pointer margin-b-3"
      @click="isDriverInfoExpanded = !isDriverInfoExpanded"
    >
      <h3 class="subtitle-1">
        {{ $t('reservationDetail.driverInfo.TITLE') }}
      </h3>
      <CUIcon
        view-box="0 0 24 24"
        icon-name="keyboard-arrow"
        :width="24"
        :height="24"
        color="gray"
        class="transition-all transition-duration-200 transition-ease-in-out"
        :style="{
          transform: isDriverInfoExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
        }"
      >
        keyboard_arrow_down
      </CUIcon>
    </div>
    <v-expand-transition>
      <div v-if="isDriverInfoExpanded">
        <div
          v-if="noNumber || !assigned || !isWithin48Hours"
          class="text-center"
        >
          <h2>{{ noDriversHeader }}</h2>
          <p>{{ noDriversBody }}</p>
        </div>
        <div v-else class="d-flex flex-column w-full">
          <div
            v-for="(driver, driverIndex) in assignedDrivers"
            :key="`call-driver-dialog-id-${driver.driverId}-${driverIndex}`"
            class="d-flex flex-row font-medium align-center w-full"
          >
            <DriverInfoContact
              :driver="driver"
              :hide-number="!isWithin3Hours || isFinished"
              class="margin-y-2"
              :class="{
                'margin-b-0': driverIndex === assignedDrivers.length - 1,
              }"
            />
          </div>
          <div
            v-if="!isWithin3Hours"
            class="w-three-fourths margin-x-auto text-center font-book font-14  margin-t-2"
          >
            {{ contactInfoMessage }}
          </div>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Driver, ReservationDetail, Stop } from '@/models/dto'
import DriverInfoContact from '@/components/DriverInfoContact.vue'
import { ReservationStatusKey } from '@/utils/enum'
import { TranslateResult } from 'vue-i18n'

@Component({ components: { DriverInfoContact } })
export default class ReservationDetailDriverInfo extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: Object, default: () => {} })
  readonly reservation: ReservationDetail

  contactInfoMessage: TranslateResult = this.$t(
    'reservationDetail.driverInfo.DETAILS'
  )
  isDriverInfoExpanded = false

  get assignedDrivers(): Driver[] {
    return this.reservation?.assignedDrivers
  }

  get noNumber(): boolean {
    return !this.assignedDrivers
  }

  get noDrivers(): boolean {
    return !this.assigned
  }

  get assigned(): boolean {
    return !!this.assignedDrivers?.length
  }

  get noDriversHeader(): TranslateResult {
    if (!this.isWithin3Hours) {
      return this.$t('reservationDetail.driverInfo.NOT_AVAILABLE')
    }
    if (this.noNumber || !this.assigned) {
      return this.$t('reservationDetail.driverInfo.NO_LISTED_NUMBERS')
    }
    return ''
  }

  get noDriversBody(): TranslateResult {
    if (!this.isWithin3Hours) {
      return this.contactInfoMessage
    }
    if (this.noNumber || !this.assigned) {
      return this.$t('reservationDetail.driverInfo.CANT_FIND_NUMBER', {
        supportPhone: '1-855-920-2287',
      })
    }
    return ''
  }

  get firstStop(): Stop {
    const stops = this.reservation?.stops
    if (!stops || stops?.length === 0) {
      return null
    }
    return stops.find((stop) => stop.orderIndex === 0)
  }

  get isWithin3Hours(): boolean {
    return this.isWithinXHours(3)
  }

  get isWithin48Hours(): boolean {
    return this.isWithinXHours(48)
  }

  get isFinished(): boolean {
    return this.reservation.reservationStatus === ReservationStatusKey.Finished
  }

  isWithinXHours(hours: number) {
    const pickupDatetime = this.firstStop?.pickupDatetime
    if (!pickupDatetime) {
      return false
    }
    const pickupDate = this.$dayjs(pickupDatetime)
    const now = this.$dayjs()
    return pickupDate.diff(now, 'hours') <= hours
  }
}
</script>
