<template>
  <div class="d-flex align-center justify-end">
    <v-progress-circular
      v-if="submitting"
      indeterminate
      color="grayLight"
      size="14"
      width="2"
    />
    <CUIcon
      v-if="success"
      viewBox="0 0 42 42"
      :width="18"
      :height="18"
      color="gray-light"
      :style="{ 'margin-top': '-2px' }"
    >
      check
    </CUIcon>
    <p class="font-14 margin-y-0 margin-l-1 font-medium">
      <span v-if="submitting" class="text-gray-light">
        {{ $t('reservationDetail.review.autosave.SAVING') }}
      </span>
      <span v-if="success" class="text-gray-light">
        {{ $t('reservationDetail.review.autosave.AUTOSAVED') }}
      </span>
      <span v-if="error" class="text-error">
        {{ $t('reservationDetail.review.autosave.ERROR') }}
      </span>
    </p>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class TripReviewFormAutosaveIndicator extends Vue {
  @Prop({ type: Boolean, required: true }) submitting!: boolean
  @Prop({ type: Boolean, required: true }) success!: boolean
  @Prop({ type: Boolean, required: true }) error!: boolean
}
</script>
