<template>
  <div
    class="omnibus border-radius-8 border-solid"
    :class="{
      'border-2 border-cup-500': isSelected,
      'border-1 border-midnight-gray-100': !isSelected,
    }"
    :style="{ padding: isSelected ? '0px' : '1px' }"
    @click="handleClick"
  >
    <div class="d-flex border-radius-8 padding-x-4 padding-y-2">
      <CUIcon view-box="0 0 24 24" class="w-40 h-40 margin-r-3">
        {{ type }}
      </CUIcon>
      <div class="d-flex flex-column grow">
        <div
          class="d-flex align-center h-40"
          :class="{ 'cursor-default': isExpired, 'cursor-pointer': !isExpired }"
        >
          <h4>{{ label }}</h4>
          <div class="d-flex grow" />
          <div
            v-if="isExpired || isDefault"
            class="border-radius-4 font-weight-600 font-14 padding-x-2 padding-y-1 line-height-1 margin-r-1 text-midnight-gray-white"
            :class="{
              'background-red': isExpired,
              'background-midnight-gray-100': isDefault,
            }"
          >
            {{
              isExpired
                ? $t('creditCardPicker.EXPIRED')
                : $t('creditCardPicker.DEFAULT')
            }}
          </div>
          <h4 v-else>
            &bull;&bull;&bull;&bull;
          </h4>
          <h4 class="margin-l-1">
            {{ lastFour }}
          </h4>
          <CUIcon
            view-box="0 0 24 24"
            color="midnight-gray-500"
            class="margin-l-3 transition-all transition-duration-300 transition-linear"
            :style="{ transform: isExpanded ? 'rotate(-180deg)' : '' }"
            @click.stop="isExpanded = !isExpanded"
          >
            keyboard_arrow_down
          </CUIcon>
        </div>
        <v-expand-transition>
          <div v-if="isExpanded" class="padding-b-2" @click.stop>
            <p>{{ $t('creditCardPicker.EXPIRATION_DATE') }}</p>
            <p class="font-medium margin-t-0 margin-b-4">
              {{ expiration }}
            </p>
            <p>{{ $t('creditCardPicker.NAME_ON_CARD') }}</p>
            <p class="font-medium margin-t-0 margin-b-4">
              {{ cardholderName }}
            </p>
            <p>{{ $t('creditCardPicker.BILLING_ADDRESS') }}</p>
            <p class="font-medium margin-t-0">
              {{ address.street1 }}
              <br />
              <span v-if="address.street2">
                {{ address.street2 }}
                <br />
              </span>
              {{ address.city }}, {{ address.state }} {{ address.postalCode }}
            </p>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <v-card-actions v-if="isExpanded">
            <v-row justify="end">
              <v-btn
                v-if="isInBilling"
                :id="`remove-payment-profile-button-${paymentProfile.customerPaymentProfileId}`"
                small
                plain
                color="red"
                class="padding-x-3"
                @click.stop="deletePaymentProfile"
              >
                {{ $t('common.REMOVE') }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-expand-transition>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import {
  ComputedPaymentProfile,
  ComputedPaymentProfileAddress,
} from '../models/dto'
import paymentProfile from '@/services/paymentProfile'

@Component({})
export default class PaymentProfileV2 extends Vue {
  @Prop({ type: Object, required: true }) readonly paymentProfile: ComputedPaymentProfile
  @Prop({ type: Boolean, default: false }) readonly isSelected: boolean
  @Inject({ from: 'isInBilling', default: false }) readonly isInBilling: boolean

  isExpanded = false

  get type(): string {
    return this.paymentProfile.typeLabel || 'default'
  }

  get label(): string {
    const labelMap = {
      amex: 'American Express',
      discover: 'Discover',
      mastercard: 'MasterCard',
      visa: 'Visa',
    }
    return labelMap[this.type] || 'Saved Card'
  }

  get lastFour(): string {
    return this.paymentProfile.mask
  }

  get cardholderName(): string {
    return this.paymentProfile.accountHolderName
  }

  get address(): ComputedPaymentProfileAddress {
    return this.paymentProfile.address
  }

  get expiration(): string {
    if (!this.expirationYear || !this.expirationMonth) {
      return ''
    }

    return `${this.expirationMonth}/${this.expirationYear}`
  }

  get isDefault(): boolean {
    return this.paymentProfile.isDefault
  }

  get isExpired(): string {
    const expirationDate = this.$dayjs(
      `${this.expirationYear}-${this.expirationMonth}-01`
    ).endOf('month')
    return expirationDate.isBefore(this.$dayjs())
  }

  get expirationMonth(): string {
    const { expiration } = this.paymentProfile

    if (!expiration) {
      return ''
    }

    const [month] = expiration.split('/')

    return month.padStart(2, '0').substr(0, 2)
  }

  get expirationYear(): string {
    const { expiration } = this.paymentProfile

    if (!expiration) {
      return ''
    }

    const [, yearFragment] = expiration.split('/')

    if (yearFragment) {
      return yearFragment.length === 2 ? `20${yearFragment}` : yearFragment
    }

    return expiration.length === 4
      ? `20${expiration.slice(-2)}`
      : expiration.slice(-4)
  }

  handleClick(): void {
    if (this.isExpired) {
      return
    }
    this.$emit('click')
  }

  async deletePaymentProfile(): Promise<void> {
    try {
      await paymentProfile.delete(this.paymentProfile.customerPaymentProfileId)
      this.$emit('payment-profile-deleted')
    } catch {
      console.error('Could not delete the payment profile')
    }
  }
}
</script>
