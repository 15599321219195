<template>
  <div
    class="background-midnight-gray-white padding-a-4 border-radius-top-right-16 border-radius-top-left-16 border-t-1 border-r-1 border-l-1 border-b-0 border-solid border-midnight-gray-50"
    :style="{
      'box-shadow':
        '0px -4px 8px 0px rgba(22, 22, 29, 0.06), 0px -4px 16px 0px rgba(22, 22, 29, 0.08)',
    }"
  >
    <div
      class="w-full cursor-pointer text-center"
      :class="{ 'margin-t-n1 margin-b-n6': !isExpanded }"
      @click.stop="isExpanded = !isExpanded"
    >
      <CUIcon
        view-box="0 0 24 24"
        color="midnight-gray-500"
        class="w-24 h-24 margin-x-auto transition-all transition-duration-300 transition-linear"
        :style="{ transform: !isExpanded ? 'rotate(-180deg)' : '' }"
      >
        keyboard_arrow_down
      </CUIcon>
    </div>
    <v-expand-transition>
      <div v-if="isExpanded">
        <h2 class="margin-b-6">Quote #{{ quoteId }}</h2>
        <CheckoutQuoteSummaryCharges :checkout-detail="checkoutDetail" />
      </div>
    </v-expand-transition>
    <CheckoutQuoteSummaryTotal :checkout-detail="checkoutDetail" />
    <v-expand-transition>
      <div v-if="isExpanded">
        <v-divider class="margin-y-6" />
        <CheckoutQuoteSummaryDue
          :checkout-detail="checkoutDetail"
          :quote="quote"
        />
        <template v-if="hasAttentionItems">
          <v-divider class="margin-y-6" />
          <CheckoutQuoteSummaryAttentionItems
            :is-driver-hotel-required="isDriverHotelRequired"
          />
        </template>
      </div>
    </v-expand-transition>
    <slot name="button" />
    <CheckoutTerms hide-dropdown class="margin-t-2 margin-b-n1" />
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { CheckoutDetailResult, Quote } from '@/models/dto'
import CheckoutQuoteSummaryCharges from '@/components/CheckoutQuoteSummaryCharges.vue'
import CheckoutQuoteSummaryDue from '@/components/CheckoutQuoteSummaryDue.vue'
import CheckoutQuoteSummaryAttentionItems from '@/components/CheckoutQuoteSummaryAttentionItems.vue'
import CheckoutQuoteSummaryTotal from '@/components/CheckoutQuoteSummaryTotal.vue'
import CheckoutTerms from '@/components/CheckoutTerms.vue'
import { RiskTypeId } from '../utils/enum'

@Component({
  components: {
    CheckoutQuoteSummaryCharges,
    CheckoutQuoteSummaryDue,
    CheckoutQuoteSummaryAttentionItems,
    CheckoutQuoteSummaryTotal,
    CheckoutTerms,
  },
})
export default class CheckoutSummaryOverlay extends Vue {
  @Prop({ type: Object, required: true })
  readonly quote!: Quote
  @Prop({ type: Object, required: true })
  readonly checkoutDetail!: CheckoutDetailResult

  isExpanded = false

  get quoteId(): number {
    return this.quote.quoteId
  }

  get isDriverHotelRequired(): boolean {
    return this.quote?.trips?.some((trip) =>
      trip?.risks?.some(
        (risk) => risk?.riskTypeId === RiskTypeId.DriverHotelMissing
      )
    )
  }

  get hasAttentionItems(): boolean {
    return [this.isDriverHotelRequired].filter((val) => val).length > 0
  }
}
</script>
