import Vue from 'vue'
import { AuthorizationParams, RedirectLoginOptions } from "@auth0/auth0-spa-js";
import { Dictionary, Location, RawLocation, Route } from "vue-router/types/router";
import { SplitFeatureFlag } from "./enum";
import Auth0AppState from "@/models/dto/Auth0AppState";
import { IS_RESERVATION_INVITE_QUERY_PARAM } from "./constants";

/**
 * Builds the options required for redirecting to the login or sign up page.
 *
 * @param {any} appState - The state of the application to use when redirecting.
 * @param {string} email - (optional) The email address of the user to pre-fill the login or sign up form.
 * @returns The options for redirecting to the login or signup page.
 */
export const buildRedirectLoginOptions = (appState: any, email?: string): RedirectLoginOptions => {
  const authorizationParams: AuthorizationParams = {
    redirect_uri: `${window.location.origin}/auth-callback`
  }
  if (email) {
    authorizationParams.login_hint = email
  }
  return {
    appState,
    authorizationParams
  }
}


/**
 * Determines whether to use Auth0 login based on the given route and feature flag.
 *
 * This function first checks for Auth0 override flags on the route. If an override is found,
 * it returns the corresponding boolean value. If no override is found, it checks the feature
 * flag to determine if Auth0 login should be enabled.
 *
 * @param {Route} route - The route to check for Auth0 overrides and feature flag.
 * @returns {Promise<boolean>} - A promise that resolves to `true` if Auth0 login should be used,
 *                               or `false` otherwise.
 */
export const shouldUseAuth0Login = async (route: Route): Promise<boolean> => {
  if (isAuth0OverrideTrue(route)) {
    return true
  }

  if (isAuth0OverrideFalse(route)) {
    return false
  }

  const isAuth0Enabled = await Vue.prototype.$split.isFeatureEnabled(SplitFeatureFlag.Auth0SignIn)
  return isAuth0Enabled
}

/**
 * Checks if the Auth0 override is set to true in the route query parameters.
 *
 * @param {Route} route - The route object containing query parameters.
 * @returns {boolean} - Returns true if the Auth0 override is set to 'true', otherwise false.
 */
const isAuth0OverrideTrue = (route: Route): boolean => {
  return route.query.auth0 === 'true'
}

/**
 * Checks if the Auth0 override is set to false in the route query parameters.
 *
 * @param {Route} route - The route object containing query parameters.
 * @returns {boolean} - Returns true if the auth0 query parameter is 'false', otherwise false.
 */
const isAuth0OverrideFalse = (route: Route): boolean => {
  return route.query.auth0 === 'false'
}


// === Begin Sign Up Redirect via Auth0 Utils === //

const getAuth0SignUpRedirect = (query: Dictionary<string | string[]>): Location => {
  const { redirectFrom, entityType } = query || {}
  const isReservationInvite = entityType === 'reservation'

  if (!redirectFrom) {
    return { name: 'quote-index' } as Location
  }

  const baseUrl = redirectFrom.toString()
  const hasExistingParams = baseUrl.includes('?')
  const paramPrefix = hasExistingParams ? '&' : '?'

  const signupFromReservationInviteParam = isReservationInvite ? `${IS_RESERVATION_INVITE_QUERY_PARAM}=true` : ''
  const toQuery = `${baseUrl}${paramPrefix}${signupFromReservationInviteParam}`
  return { path: toQuery } as Location
}

const buildAuth0SignUpAppState = (name: string, path: string): Auth0AppState => {
  const appState: Auth0AppState = {}
  if (name) {
    appState.routeName = name
  }
  if (path) {
    appState.redirectFrom = path
  }
  return appState
}

export const goToAuth0SignUpViaRedirect = async (query: Dictionary<string | string[]>, userHash: string, email: string): Promise<void> => {
  const isNewCustomer = !!userHash
  const redirectLocation: Location = getAuth0SignUpRedirect(query)
  const { name, path } = redirectLocation
  const appState = buildAuth0SignUpAppState(name, path)
  const options: RedirectLoginOptions = buildRedirectLoginOptions(appState, email)

  if (isNewCustomer) {
    await Vue.prototype.$auth0.signupWithRedirect(options)
  } else {
    await Vue.prototype.$auth0.loginWithRedirect(options)
  }
}

// ===     End Sign Up Redirect via Auth0     === //
