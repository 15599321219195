<template>
  <CUModal
    :value="value"
    width="auto"
    :max-width="maxWidth"
    closeable
    close-icon-right-padding
    @input="$emit('input', $event)"
    @keydown.stop="
      (event) => {
        if (event.keyCode === KeyCode.Escape) {
          close()
        }
      }
    "
  >
    <template #activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>

    <template #title>
      <CUIcon
        v-if="icon"
        :view-box="iconViewBox"
        class="cursor-default min-w-48 min-h-48 margin-t-2"
        height="48px"
        :color="iconColor"
      >
        {{ icon }}
      </CUIcon>
      <div class="w-full margin-l-4 margin-t-2 omnibus">
        <h3 class="font-bold margin-t-2">{{ label }}</h3>
        <v-divider class="margin-t-4" />
      </div>
    </template>

    <template #text>
      <div class="d-flex flex-column w-full justify-space-between">
        <div
          class="d-flex w-full omnibus margin-b-6"
          :class="{ 'margin-t-4': $vuetify.breakpoint.smAndDown }"
        >
          <div v-if="$vuetify.breakpoint.smAndUp && icon" class="min-w-48"></div>
          <div
            class="d-flex w-full flex-column"
            :class="{ 'padding-l-4 padding-r-2': $vuetify.breakpoint.smAndUp }"
          >
            <slot name="text">
              <component :is="textComponent" :modal-detail="modalDetail" />
            </slot>

            <!-- FOOTER TEXT -->
            <div v-if="showFooterText" class="margin-t-8">
              <p :style="{ 'line-height': '1.5em' }">
                {{ modalDetail.footerText }}
              </p>
            </div>
          </div>
        </div>

        <div
          v-if="showSupportButton || showCharterUPLogo"
          class="d-flex w-full flex-column omnibus margin-b-6"
          :class="{ 'margin-t-8': !showFooterText }"
        >
          <!-- SUPPORT BUTTON -->
          <div
            v-if="showSupportButton"
            class="d-flex w-full justify-center"
          >
            <v-btn
              class="margin-t-2 margin-b-0"
              color="primary"
              :href="`tel:${supportNumber}`"
            >
              <div class="d-flex align-center">
                <CUIcon color="white" width="24" view-box="0 0 24 24">
                  phone
                </CUIcon>
                <p class="text-white margin-t-0 margin-l-4"> {{ formattedSupportNumber }} </p>
              </div>
            </v-btn>
          </div>

          <!-- CHARTERUP LOGO -->
          <div
            v-if="showCharterUPLogo"
            class="d-flex w-full justify-end padding-r-3"
            :class="{ 'margin-t-8': showSupportButton }"
          >
            <CharterUPLogo width="150" />
          </div>
        </div>
      </div>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import { InfoModalDetail } from '@/models/InfoModalDetail'
import CharterUPLogo from '@/icons/CharterUPLogo.vue'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'
import auth from '@/store/modules/auth'
import InfoModalTextSimple from './InfoModalTextSimple.vue'
import InfoModalTextItemized from './InfoModalTextItemized.vue'
import InfoModalTextStandard from './InfoModalTextStandard.vue'
import { InfoModalVariant, KeyCode } from '@/utils/enum'

@Component({ components: { CharterUPLogo } })
export default class InfoModal extends Vue {
  @Prop({ type: Object, required: true }) readonly modalDetail: InfoModalDetail
  @Prop({ required: false, default: 'auto' }) readonly maxWidth: string
  @Model('input') readonly value!: boolean | null

  KeyCode = KeyCode

  get icon(): string {
    return this.modalDetail?.icon?.name || ''
  }

  get iconViewBox(): string {
    return this.modalDetail?.icon?.viewBox || '0 0 38 37'
  }

  get iconColor(): string {
    return this.modalDetail?.icon?.color || ''
  }

  get label(): string {
    return this.$vuetify.breakpoint.mdAndUp
      ? this.modalDetail.label
      : this.modalDetail.shortLabel
  }

  get showCharterUPLogo(): boolean {
    return !!this.modalDetail?.showCharterUPLogo
  }

  get showFooterText(): boolean {
    return !!this.modalDetail?.footerText
  }

  get textComponent(): string {
    const variant = this.modalDetail?.modalVariant || ''
    const map = {
      [InfoModalVariant.Simple]: InfoModalTextSimple,
      [InfoModalVariant.Itemized]: InfoModalTextItemized,
      [InfoModalVariant.Standard]: InfoModalTextStandard,
    }
    return map[variant] || null
  }

  get showSupportButton(): boolean {
    return !!this.modalDetail?.showSupportButton
  }

  get supportNumber(): string {
    return getSupportNumber(auth.customer.isElite)
  }

  get formattedSupportNumber(): string {
    return `1-${phoneFormatFilterHyphens(this.supportNumber)}`
  }

  close(): void {
    this.$emit('input', false)
  }
}
</script>
