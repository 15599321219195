<template>
  <div ref="slider" class="slider position-relative">
    <div class="slider__values">
      <div
        v-for="val in stepListCount"
        :key="val"
        :ref="`value-${val}`"
        class="slider__values__value"
        :class="{ 'slider__values__value--active': val === value }"
        @click="handleValueClick(val)"
        @mouseover="moveBusToHoverValue(val)"
        @mouseleave="handleMouseLeave"
      >
        {{ val }}
      </div>
    </div>
    <div
      id="slider-bus"
      ref="slider-bus"
      class="slider__bus position-absolute z-10 cursor-pointer draggable"
      :style="{
        transform: busDirection < 0 ? 'scaleX(-1)' : '',
        transition: `left ${busAnimationLength}s`,
      }"
      @mousedown="startDrag"
      @mouseup="mouseUpHandler"
      @mouseleave="mouseUpHandler"
    >
      <img
        id="bus__slider__image"
        src="@/assets/images/sliderBus.svg"
        alt="bus slider handle"
        height="18"
        :style="{
          transition: 'all 0.25s',
          'transform-origin': '50% 50%',
          transform: dragTarget ? 'scale(1.33)' : '',
        }"
      />
    </div>
    <div ref="slider-bar" class="slider__bar">
      <div
        ref="left-bar"
        class="slider__bar--left"
        :style="{ transition: `width ${busAnimationLength}s` }"
      />
      <div
        ref="right-bar"
        class="slider__bar--right"
        :style="{ transition: `width ${busAnimationLength}s` }"
      />
    </div>
    <v-row>
      <v-col
        class="shrink font-medium font-12  cursor-default white-space-nowrap text-white padding-l-6"
        :style="{ 'margin-top': '2px' }"
      >
        Not Likely
      </v-col>
      <v-spacer />
      <v-col
        class="shrink font-medium font-12  cursor-default white-space-nowrap text-white padding-l-6"
        :style="{ 'margin-top': '2px' }"
      >
        Very Likely
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class BusSlider extends Vue {
  @Prop({ type: Number, required: true }) readonly min: number
  @Prop({ type: Number, required: true }) readonly max: number
  @Prop({ type: Number, default: 1 }) readonly step: number
  @Prop({ type: Number, default: 5 }) readonly value: number

  busDirection = 1
  busAnimationLength = 1
  debounce = null
  delay = null
  dragTarget = null
  maxXPosition = 0

  get stepListCount(): number[] {
    const values = []
    for (let i = this.min; i <= this.max; i += this.step) {
      values.push(i)
    }
    return values
  }

  mounted(): void {
    this.setInitialValue()
    setTimeout(() => {
      this.moveBusToValue(this.value)
    }, 1)
    document.getElementById('bus__slider__image').ondragstart = () => {
      return false
    }
    this.addDragHandler()
  }

  setInitialValue(): void {
    if (this.value !== null) {
      let value = this.value
      if (typeof value === 'string') {
        value = Number(value)
      }
      if (typeof value === 'number' && (value < this.min || value > this.max)) {
        this.$emit('input', this.max)
      }
    }
  }

  setBusPosition(moveTo: any): void {
    const bus = this.$refs['slider-bus'] as any
    const slider = this.$refs['slider'] as any
    const busOffset = bus.offsetWidth / 2
    let positionPixels
    let middlePoint

    const moveToType = typeof moveTo
    if (moveToType === 'number') {
      positionPixels = moveTo
      middlePoint = ((positionPixels + busOffset) / slider.offsetWidth) * 100
    } else if (moveToType === 'object') {
      const valueOffset = moveTo.offsetWidth / 2
      positionPixels = `${moveTo.offsetLeft - busOffset + valueOffset}`
      middlePoint =
        ((moveTo.offsetLeft + valueOffset) / slider.offsetWidth) * 100
    }
    const positionPercent = (positionPixels / slider.offsetWidth) * 100
    bus.style.left = `${positionPercent}%`
    this.setBarPositions(middlePoint)
  }

  setBarPositions(middlePoint: number): void {
    const left = this.$refs['left-bar'] as any
    const right = this.$refs['right-bar'] as any
    left.style.width = `${middlePoint}%`
    right.style.width = `${100 - middlePoint}%`
  }

  moveBusToValue(value: number): void {
    this.setBusAnimationLength(value)
    const element = this.$refs[`value-${value}`][0]
    this.setBusPosition(element)
  }

  moveBusToHoverValue(value: number): void {
    if (this.dragTarget) {
      return
    }
    this.delay = setTimeout(() => {
      clearTimeout(this.debounce)
      this.moveBusToValue(value)
    }, 100)
  }

  returnBusToActiveValue(): void {
    if (this.dragTarget) {
      return
    }
    this.debounce = setTimeout(() => {
      this.moveBusToValue(this.value)
    }, 250)
  }

  handleValueClick(value: number): void {
    this.moveBusToValue(value)
    this.$emit('input', value)
    this.$emit('submit', this.busAnimationLength)
  }

  handleMouseLeave(): void {
    clearTimeout(this.delay)
    this.returnBusToActiveValue()
  }

  setBusAnimationLength(destinationValue: number): void {
    const destinationElement = this.$refs[`value-${destinationValue}`][0] as any
    const busElement = this.$refs['slider-bus'] as any
    const slider = this.$refs['slider'] as any
    const minumumDuration = 0.25
    const maximumDuration = 2

    const busPosition = busElement?.offsetLeft
    const destinationPosition =
      destinationElement?.offsetLeft + destinationElement.offsetWidth / 2

    const positionDifference = destinationPosition - busPosition
    if (positionDifference < 0) {
      this.busDirection = -1
    } else if (positionDifference > 0) {
      this.busDirection = 1
    }

    const percentToMove = Math.abs(positionDifference) / slider.offsetWidth
    this.busAnimationLength = Math.max(
      percentToMove * maximumDuration,
      minumumDuration
    )
  }
  startDrag(): void {
    const rightEdgeOfBar =
      (this.$refs['right-bar'] as any).offsetLeft +
      (this.$refs['right-bar'] as any).offsetWidth
    const busOffset = (this.$refs['slider-bus'] as any).offsetWidth / 2
    this.maxXPosition = rightEdgeOfBar - busOffset
    this.dragTarget = this.$refs['slider-bus']
    this.busAnimationLength = 0
  }

  mouseMoveHandler(event: any): void {
    if (this.dragTarget === null) {
      return
    }

    let xDisplacement = event.movementX
    if (xDisplacement === xDisplacement && xDisplacement !== 0) {
      if (this.dragTarget.offsetLeft + xDisplacement < 0) {
        xDisplacement = -1 * this.dragTarget.offsetLeft
      } else if (
        this.dragTarget.offsetLeft + xDisplacement >
        this.maxXPosition
      ) {
        xDisplacement = this.maxXPosition - this.dragTarget.offsetLeft
      }
      const busDirection = xDisplacement / Math.abs(xDisplacement)
      this.busDirection = busDirection !== 0 ? busDirection : this.busDirection
      this.setBusPosition(this.dragTarget.offsetLeft + xDisplacement)
      const closestValue = this.findClosestValue().value
      this.$emit('input', closestValue)
    }
  }
  addDragHandler(): void {
    document.addEventListener('mousemove', this.mouseMoveHandler)
  }
  mouseUpHandler(): void {
    if (this.dragTarget) {
      this.$emit('show-submit', true)
    }
    this.moveBusToValue(this.findClosestValue().value)
    this.dragTarget = null
  }
  findClosestValue(): any {
    const values = []
    const busOffsetLeft = (this.$refs['slider-bus'] as any).offsetLeft
    for (const value of this.stepListCount) {
      const offsetLeft = this.$refs[`value-${value}`][0].offsetLeft
      const distanceToBus = Math.abs(offsetLeft - busOffsetLeft)
      values.push({ value, distanceToBus })
    }
    values.sort((a, b) => {
      return a.distanceToBus - b.distanceToBus
    })
    return values[0]
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors.scss';

.slider {
  &__bus {
    transition-timing-function: linear;
  }

  &__bar {
    margin-top: 7px;
    width: 100%;
    height: 5px;
    display: flex;
    padding: 0 12px;
    &--right,
    &--left {
      height: 100%;
      background: $white;
      transition-timing-function: linear;
    }
    &--right {
      border-top-right-radius: 2.5px;
      border-bottom-right-radius: 2.5px;
      width: 100%;
      opacity: 0.5;
    }
    &--left {
      width: 0%;
      border-top-left-radius: 2.5px;
      border-bottom-left-radius: 2.5px;
    }
  }

  &__values {
    padding-bottom: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    &__value {
      height: 24px;
      width: 24px;
      padding-top: 2px;
      border-radius: 50%;
      background: $white;
      font-family: 'Inter Medium', Arial, sans-serif;
      font-size: 0.875rem;
      cursor: pointer;
      text-align: center;
      color: $gray-light !important;

      &:hover {
        background: $border-gray;
        color: $black !important;
      }

      &--active {
        color: $primary !important;
      }
    }
  }
}
</style>
