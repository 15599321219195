<template>
  <div
    :id="`${tableElementId}-${row.elementId}-${column.elementId}`"
    :class="{ 'd-flex flex-column align-start margin-y-1': isMobile }"
  >
    <h4
      v-if="
        isMobile &&
        ![
          'actions',
          'editable',
          'add-new-select',
          'slot',
          'tooltip',
          'select',
        ].includes(column.type)
      "
    >
      {{ column.text }}
    </h4>
    <template v-if="column.type === 'select'">
      <v-checkbox
        :key="`${row[itemKey]}-${isSelected}`"
        :input-value="isSelected"
        hide-details
        class="margin-t-0 padding-t-0"
        @change="$emit('select', { value: $event, key: row[itemKey] })"
      />
    </template>
    <template v-if="column.type === 'slot'">
      <component
        :is="column.component"
        :key="`component-column-${rowIndex}-${row[itemKey]}`"
        :row="row"
        :is-mobile="isMobile"
        :actions="actions"
        v-on="$listeners"
      />
    </template>
    <template v-else-if="column.type === 'actions' && !row.isEditable">
      <CUDataTableActionColumn
        :id="`${tableElementId}-${row.elementId}-${column.elementId}-button`"
        :key="`action-column-${rowIndex}`"
        :actions="actions"
        :row="row"
        :row-index="rowIndex"
        :display-actions-on-mobile="displayActionsOnMobile"
        :is-mobile="isMobile"
        :is-detail-table="isDetailTable"
        @refresh="$emit('refresh')"
      />
    </template>
    <template
      v-else-if="column.type === 'actions' && row.isEditable && row.isNewRow"
    >
      <CUDataTableRowEditActions
        :is-mobile="isMobile"
        :row="row"
        :row-index="rowIndex"
        event-name="add"
        v-on="$listeners"
      />
    </template>
    <template v-else-if="column.type === 'actions' && row.isEditable">
      <CUDataTableRowEditActions
        :is-mobile="isMobile"
        :row="row"
        :row-index="rowIndex"
        event-name="update"
        v-on="$listeners"
      />
    </template>
    <template
      v-else-if="column.type === 'add-new-select' && (row.isNewRow || isMobile)"
    >
      <CUSelect
        :id="`${tableElementId}-select-${column[columnId]}`"
        :key="`editable-select-row-${rowIndex}-${column._t_id}`"
        hide-details
        :label="isMobile ? column.text : null"
        :items="row.items"
        class="w-full"
        :rules="row.isNewRow ? column.editableRules : []"
        :disabled="!row.isNewRow"
        :value="op(row, column.value)"
        :menu-props="{ maxHeight: 'auto' }"
        validate-on-blur
        @input="
          (e) =>
            $emit('update-editable-select', {
              value: e,
              rowIndex: rowIndex,
              columnValue: column.value,
            })
        "
      >
        <template #item="{ item }">
          <div
            :id="`list-item-${item.id}`"
            role="option"
            class="v-list-item v-list-item--link theme--light"
          >
            <div class="v-list-item__content">
              <div class="v-list-item__title">{{ item.text }}</div>
            </div>
          </div>
        </template>
      </CUSelect>
    </template>
    <template v-else-if="column.type === 'phone'">
      <a :href="`tel:${cellItem}`">
        {{ phoneFormatFilter(String(cellItem)) }}
      </a>
    </template>
    <template v-else-if="column.type === 'email'">
      <a :href="`mailto:${cellItem}`">{{ cellItem }}</a>
    </template>
    <template v-else-if="column.type === 'editable'">
      <CUTextField
        :key="`editable-text-field-row-${rowIndex}-${column._t_id}`"
        :value="op(row, column.value)"
        :disabled="!row.isEditable"
        class="w-full"
        :label="isMobile ? column.text : null"
        hide-details
        type="number"
        validate-on-blur
        min="0"
        step="0.01"
        :rules="row.isEditable ? column.editableRules : []"
        @input="
          (e) =>
            $emit('update-editable-input', {
              value: e,
              rowIndex: rowIndex,
              columnValue: column.value,
            })
        "
      />
    </template>
    <template v-else-if="column.type === 'tooltip'">
      <v-tooltip top>
        <template #activator="{ on }">
          <CUIcon v-on="on">info</CUIcon>
        </template>
        <span class="text-white">{{ row.tooltip }}</span>
      </v-tooltip>
    </template>
    <template v-else>
      <span>{{ computedCellItemText }}</span>
    </template>
  </div>
</template>

<script lang="ts">
import CUDataTableActionColumn from '@/components/CUDataTableActionColumn.vue'
import { ActionColumn } from '@/models/ActionColumn'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DataTableColumn } from '@/models/DataTableColumn'
import { phoneFormatFilter } from '@/utils/phone'
import op from 'simple-object-path'
import { isNotEmptyInput, isNotNegative } from '@/utils/validators'
import CUDataTableRowEditActions from '@/components/CUDataTableRowEditActions.vue'

@Component({
  components: { CUDataTableActionColumn, CUDataTableRowEditActions },
})
export default class CUDataTableCell extends Vue {
  @Prop({ type: Object, required: true }) row!: any
  @Prop({ type: Number, required: true }) rowIndex!: number
  @Prop({ type: Object, required: true }) column!: DataTableColumn
  @Prop({ type: Array, required: false, default: undefined })
  actions!: ActionColumn[]
  @Prop({ type: Array, required: false, default: undefined })
  selectedItems: unknown[]
  @Prop({ type: Boolean, required: false, default: false })
  isDetailTable!: boolean
  @Prop({ type: String, required: false }) detailName!: string
  @Prop({ type: String, required: false }) itemKey!: string
  @Prop({ type: Boolean, required: true }) isMobile!: boolean
  @Prop({ type: Boolean, required: false }) displayActionsOnMobile!: boolean
  @Prop({ type: String, required: false, default: 'data-table' })
  tableElementId!: string

  op = op

  get cellItem(): any {
    return op(this.row, this.column.value)
  }

  get computedCellItemText(): unknown {
    if (this.column.computedText) {
      return this.column.computedText(this.row)
    }
    return this.cellItem
  }

  get isSelected(): boolean {
    if (this.column?.type !== 'select') {
      return false
    }
    const key = this.row[this.itemKey]
    return !!this.selectedItems?.includes(key)
  }

  phoneFormatFilter = phoneFormatFilter
  isNotEmptyInput = isNotEmptyInput
  isNotNegative = isNotNegative
}
</script>
