<template>
  <CUModal
    width="500px"
    :value="value"
    @input="close(false)"
    @close="close(true)"
    @keydown.stop="handleKeydown"
  >
    <template #title>
      <div class="d-flex flex-row align-center">
        <CUIcon
          v-if="!sent && ticketTypeKey"
          id="support-dialog-link-back"
          color="primary"
          view-box="0 0 24 24"
          width="32"
          height="32"
          :style="{ 'margin-left': '-10px' }"
          @click="goBack"
        >
          keyboard_arrow_left
        </CUIcon>
        {{ title }}
      </div>
    </template>
    <template #text>
      <div v-if="!ticketTypeKey && !sent" class="w-full">
        <div
          v-for="(type, typeIndex) in ticketTypeOptions"
          :id="type.id"
          :key="`type-${type.id}-${typeIndex}`"
          class="d-flex text-primary font-medium padding-y-3 border-b-1 border-solid border-x-0 border-t-0 border-border-gray last-of-type:border-b-0 cursor-pointer"
          @click="setTicket(type.key)"
        >
          {{ type.label }}
          <div class="d-flex grow" />
          <CUIcon color="primary" view-box="0 0 24 24">
            keyboard_arrow_right
          </CUIcon>
        </div>
        <AccountSupport class="pt-3">
          <template #quotesTitle>
            Need Help With Quotes?
          </template>
          <template #updatesAndSupportTitle>
            Need Help With Trip Updates or Other Support?
          </template>
        </AccountSupport>
      </div>

      <div
        v-else-if="ticketTypeKey && !sent && !isSelectDisabled"
        class="w-full"
      >
        <template v-if="loading">
          <div class="d-flex justify-center margin-y-5">
            <v-progress-circular indeterminate color="blue-grey" size="150" />
          </div>
        </template>
        <template v-else>
          <v-tabs :value="tab" @change="handleTabChange">
            <v-tab
              v-for="(tab, tabIndex) in tabs"
              :key="`${tab.label}-${tabIndex}`"
              :disabled="!tab.items || !tab.items.length"
            >
              {{ tab.label }}s
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="(tab, tabIndex) in tabs"
              :key="`${tab.label}-${tabIndex}`"
              eager
            >
              <v-form
                :ref="`form-${tabIndex}`"
                v-model="tab.valid"
                @submit.prevent="submitTicket"
              >
                <div class="margin-t-4">
                  <v-select
                    :value="tab.select"
                    :items="tab.items"
                    :rules="[(v) => !!v || 'Item is required']"
                    item-value="id"
                    :label="`Select ${tab.label} ID`"
                    return-object
                    outlined
                    @input="handleSelectChange"
                  >
                    <template #selection="data">
                      <v-list-item-title>
                        <span class="font-bold">
                          {{ data.item.managedId || data.item.id }}&nbsp;
                        </span>
                        {{ data.item.label }}
                      </v-list-item-title>
                    </template>
                    <template #item="data">
                      <v-list-item-title>
                        <span class="font-bold">
                          {{ data.item.managedId || data.item.id }}&nbsp;
                        </span>
                        {{ data.item.label }}
                      </v-list-item-title>
                    </template>
                  </v-select>
                  <div v-if="!showTripModification">
                    <label for="message">Message</label>
                    <v-textarea
                      id="message"
                      v-model="tab.message"
                      name="message"
                      :rules="[(v) => !!v || 'Message is required']"
                      outlined
                      rows="10"
                    />
                  </div>
                </div>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
          <p v-if="errorMessage" class="text-error">{{ errorMessage }}</p>
        </template>
      </div>

      <div
        v-else-if="ticketTypeKey && !sent && isSelectDisabled"
        class="w-full"
      >
        <v-form ref="form" v-model="valid" @submit.prevent="submitTicket">
          <div flat class="margin-t-4">
            <label for="message">Message</label>
            <v-textarea
              id="message"
              v-model="message"
              name="message"
              :rules="[(v) => !!v || 'Message is required']"
              outlined
              rows="10"
            />
          </div>
        </v-form>
      </div>

      <div v-else class="text-center w-full">
        <v-icon size="70">$color-sent</v-icon>
        <h3>
          Message sent!
        </h3>
        <p>
          Your message has been received
          <br />
          We&rsquo;ll get back to you soon.
        </p>
      </div>
    </template>
    <template v-if="ticketTypeKey && !sent" #actions>
      <v-spacer />
      <v-btn
        id="support-dialog-button-cancel"
        text
        small
        class="margin-r-2"
        @click="close(true)"
      >
        Cancel
      </v-btn>
      <v-btn
        id="support-dialog-button-submit"
        dark
        small
        :disabled="isSubmitDisabled"
        :loading="isSubmitting"
        color="primary"
        @click="submitTicket"
      >
        Submit
      </v-btn>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import InfoDialogLayout from '@/layouts/InfoDialogLayout.vue'
import AccountSupport from '@/components/AccountSupport.vue'
import { logger } from '@/utils/logger'
import { EventBus } from '@/utils/eventBus'
import {
  KeyCode,
  SupportTicketKey,
  SupportTicketTypeLabel,
  TicketSeverityType,
  TicketTypeId,
  TicketTitle,
  ReservationStatusKey,
  SplitFeatureFlag,
} from '@/utils/enum'
import support from '@/store/modules/support'
import auth from '@/store/modules/auth'
import ticket from '@/services/ticket'
import { CreateTicketParams, ReservationDetail } from '@/models/dto'
import reservation from '@/services/reservation'

@Component({
  components: {
    InfoDialogLayout,
    AccountSupport,
  },
})
export default class TheSupportDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean

  @Watch('value')
  @Watch('loading') // modal may open before fetch completes
  onValueChange(): void {
    if (this.value && !this.loading) {
      this.setTabItems()
      this.checkRoute()
      this.setActiveTab()
      this.refreshTabItems()
    }
  }

  isItineraryModificationWizardEnabled = false
  loading = false
  valid = false
  sent = false
  isSubmitting = false
  isSelectDisabled = false
  message = ''
  errorMessage = ''
  tab = 0
  tabs: {
    valid: boolean
    label: string
    items: {
      id: number
      managedId?: string
      hash: string
      label: string
      customerId?: number
      reservationStatus?: string
    }[]
    select: {
      id: number
      managedId?: string
      hash: string
      label: string
      customerId?: number
      reservationStatus?: string
    }
    message: string
  }[] = [
    {
      valid: false,
      label: SupportTicketTypeLabel.Quote,
      items: [],
      select: null,
      message: '',
    },
    {
      valid: false,
      label: SupportTicketTypeLabel.Reservation,
      items: [],
      select: null,
      message: '',
    },
  ]
  ticketTypeOptions: { id: string; key: string; label: string }[] = [
    {
      id: 'support-dialog-button-change-my-itinerary',
      key: SupportTicketKey.Change,
      label: TicketTitle[SupportTicketKey.Change],
    },
    {
      id: 'support-dialog-button-report-an-issue',
      key: SupportTicketKey.Issue,
      label: TicketTitle[SupportTicketKey.Issue],
    },
    {
      id: 'support-dialog-button-billing-question',
      key: SupportTicketKey.Billing,
      label: TicketTitle[SupportTicketKey.Billing],
    },
    {
      id: 'support-dialog-button-other',
      key: SupportTicketKey.Other,
      label: TicketTitle[SupportTicketKey.Other],
    },
  ]

  get showTripModification(): boolean {
    const reservationStatus = this.activeTab.select?.reservationStatus
    const isReservationUpcomingOrOnHold =
      reservationStatus === ReservationStatusKey.Upcoming ||
      reservationStatus === ReservationStatusKey.OnHold
    const isUserBookingContact =
      auth.userId === this.activeTab.select?.customerId
    const isItineraryTicketType = this.ticketTypeKey === SupportTicketKey.Change

    return (
      this.isItineraryModificationWizardEnabled &&
      isUserBookingContact &&
      isReservationUpcomingOrOnHold &&
      isItineraryTicketType
    )
  }

  get ticketTypeKey(): string {
    return support.ticketTypeKey
  }
  get title(): string {
    if (!this.ticketTypeKey) {
      return TicketTitle.None
    }
    return TicketTitle[this.ticketTypeKey]
  }
  get ticketType(): number {
    return TicketTypeId[this.ticketTypeKey]
  }
  get ticketSeverityType(): number {
    return TicketSeverityType[this.ticketTypeKey]
  }
  get reservationsTab(): number {
    return this.tabs.findIndex(
      ({ label }) => label === SupportTicketTypeLabel.Reservation
    )
  }
  get quotesTab(): number {
    return this.tabs.findIndex(
      ({ label }) => label === SupportTicketTypeLabel.Quote
    )
  }
  get activeTab(): {
    valid: boolean
    label: string
    items: {
      id: number
      managedId?: string
      hash: string
      label: string
      customerId?: number
      reservationStatus?: string
    }[]
    select: {
      id: number
      managedId?: string
      hash: string
      label: string
      customerId?: number
      reservationStatus?: string
    }
    message: string
  } {
    return this.tabs[this.tab]
  }
  get isSubmitDisabled(): boolean {
    if (this.isSelectDisabled) {
      return !this.valid
    }
    return this.activeTab ? !this.activeTab.valid : true
  }

  async mounted(): Promise<void> {
    if (support.quotes == null || support.reservations == null) {
      this.loading = true
      await support.fetchAll()
      this.loading = false
    } else {
      support.fetchAll()
    }
    this.isItineraryModificationWizardEnabled = await this.$split.isFeatureEnabled(
      SplitFeatureFlag.ItineraryModificationWizard
    )
  }

  setTabItems(): void {
    this.tabs[this.quotesTab].items = support.quotes
    this.tabs[this.reservationsTab].items = support.reservations
  }

  async refreshTabItems(): Promise<void> {
    // update tab items in background
    await support.fetchAll()
    this.setTabItems()
  }

  setActiveTab(): void {
    if (support.quoteId) {
      const quotes = this.tabs[this.quotesTab].items
      this.tabs[this.quotesTab].select = quotes?.find(
        ({ id }) => id === support.quoteId
      )
      this.tab = this.quotesTab
    }
    if (support.reservationId) {
      const reservations = this.tabs[this.reservationsTab].items
      this.tabs[this.reservationsTab].select = reservations?.find(
        ({ id }) => id === support.reservationId
      )
      this.tab = this.reservationsTab
    }
    // Cycle through tabs until one has items, otherwise disable select
    if (!this.activeTab.items?.length) {
      this.tab = this.tabs.findIndex((tab) => tab.items?.length)
      if (this.tab === -1) {
        this.isSelectDisabled = true
      }
    } else {
      this.isSelectDisabled = false
    }
  }

  checkRoute(): void {
    switch (this.$route.name) {
      case 'quote-detail':
        support.setQuoteId(Number(this.$route.params.id))
        break
      case 'quote-detail-by-hash':
        support.setQuoteId(
          support.quotes.find((q) => q.hash === this.$route.params.hash).id
        )
        break
      case 'provider-detail':
        support.setQuoteId(Number(this.$route.params.quoteId))
        break
      case 'checkout':
        support.setQuoteId(Number(this.$route.params.quoteId))
        break
      case 'checkout-single-bid':
        support.setQuoteId(Number(this.$route.params.quoteId))
        break
      case 'reservation-detail':
        support.setReservationId(Number(this.$route.params.id))
        break
      case 'reservation-detail-by-hash':
        support.setReservationId(
          support.reservations.find((r) => r.hash === this.$route.params.hash)
            .id
        )
        break
    }
  }

  clearErrorMessage(): void {
    this.errorMessage = ''
  }

  handleTabChange(tab): void {
    this.tab = tab
    this.clearErrorMessage()
  }

  handleSelectChange(select: any): void {
    this.activeTab.select = select
    this.clearErrorMessage()
  }

  setTicket(key): void {
    support.setTicketTypeKey(key)
  }

  handleKeydown(event): void {
    if (event.keyCode === KeyCode.Escape) {
      this.close(false)
    }
  }

  clearSelection(): void {
    this.message = null
    this.tabs = this.tabs.map((tab) => {
      return {
        ...tab,
        select: null,
        message: '',
      }
    })
  }

  close(event): void {
    // Why this? Becasue I can call pass `close(false)` to avoid
    // changing the ticket state. This is for accidental closures
    // of support.
    if (event) {
      this.setTicket(null)
      this.clearSelection()
    }
    this.sent = false
    support.close()
  }

  goBack(): void {
    this.setTicket(null)
    this.clearSelection()
  }

  async submitTicket(): Promise<void> {
    if (this.isSubmitting) {
      return
    }
    this.isSubmitting = true

    const params: CreateTicketParams = this.getCreateTicketPayload()

    // Validate form
    const formValid = this.isSelectDisabled
      ? (this.$refs.form as any).validate()
      : (this.$refs[`form-${this.tab}`][0] as any).validate()

    if (!formValid) {
      this.isSubmitting = false
      return
    }

    // Set comments based on input type
    params.comments = this.isSelectDisabled
      ? this.message
      : this.activeTab.message

    // If not select disabled, handle the activeTab cases
    if (!this.isSelectDisabled) {
      const { select, label } = this.activeTab

      const isReservation = label === SupportTicketTypeLabel.Reservation
      const isChangeRequest = support.ticketTypeKey === SupportTicketKey.Change

      if (isReservation && isChangeRequest && this.showTripModification) {
        const reservationId = select.id
        const data = await this.getReservationDetails(reservationId)
        const {
          allowSelfServeModification,
          hasPendingModificationRequest,
        } = data

        if (!allowSelfServeModification) {
          this.errorMessage = 'This trip cannot be modified.'
        } else if (hasPendingModificationRequest) {
          this.errorMessage =
            'This reservation already has an active edit request.'
        } else {
          this.handleTripModification(select.id)
        }

        this.isSubmitting = false
        return
      }

      // Set IDs for Quote and Reservation
      if (label === 'Quote') {
        params.quoteId = select.id
      } else if (label === 'Reservation') {
        params.reservationId = select.id
        params.managedId = select.managedId
      }
    }

    await this.createTicket(params)
    this.isSubmitting = false
  }

  getCreateTicketPayload(): CreateTicketParams {
    return {
      comments: undefined,
      title: this.title,
      ticketTypeId: this.ticketType,
      ticketSeverityTypeId: this.ticketSeverityType,
      createdById: auth.userId,
      creatorFirstName: auth.user?.firstName,
      creatorLastName: auth.user?.lastName,
      creatorEmail: auth.user?.email,
    }
  }

  async getReservationDetails(
    reservationId: number
  ): Promise<ReservationDetail> {
    const { data } = await reservation.byId(reservationId)
    return data.data
  }

  /**
   * Handles redirect to trip modification wizard
   * When the route is different, redirect user
   * When the route is the same, emit an event
   *
   * @param reservationId - number indicating the internal reservationId to modify
   *
   */
  handleTripModification(reservationId: number): void {
    const isDifferentRoute =
      this.$route.name !== 'reservation-detail' ||
      this.$route.params.id !== reservationId.toString()

    if (isDifferentRoute) {
      this.$router.push({
        name: 'reservation-detail',
        params: {
          id: reservationId.toString(),
          openTripModificationWizard: 'true',
        },
      })
    } else {
      EventBus.$emit('open-trip-modification-dialog')
    }

    support.close()
  }
  async createTicket(params: CreateTicketParams): Promise<void> {
    try {
      const response = await ticket.create(params)
      if (response.status === 200) {
        this.sent = true
        this.clearSelection()
      }
    } catch (error) {
      logger.error(error)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';

.v-select {
  ::v-deep .v-input__control {
    .v-input__slot {
      .v-select__slot {
        .v-label {
          line-height: 1 !important;
        }
        .v-input__append-inner {
          align-self: auto;
        }
      }
    }
  }
}

.v-text-field {
  ::v-deep .v-input__control {
    .v-input__slot {
      border: 0px !important;
    }
  }
}

.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: $gray-lighter !important;
  color: $gray-light !important;
}

.v-tab.v-tab--disabled {
  color: $gray-light !important;
}

.v-tabs {
  ::v-deep .v-tabs-slider-wrapper {
    background-color: $gray !important;
  }
}

.v-list.v-select-list.v-sheet {
  max-width: 437px !important;
}
</style>
