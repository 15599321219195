<template>
  <v-btn
    color="primary"
    class="padding-y-4 w-full d-flex align-center font-20 font-weight-700"
    :disabled="props.disabled"
    :loading="loading"
    @click="onClick"
  >
    Proceed to Booking
    <CUIcon view-box="0 0 24 24" class="w-24 h-24 margin-l-1">
      arrow_right
    </CUIcon>
  </v-btn>
</template>


<script lang="ts" setup>
import { PropType, ref } from "vue"
import { useSalesBotStore } from '@/store/modules/salesBot'
import router from "@/router";
import { useRoute } from 'vue-router/composables'
import { useGoogleAnalytics, Event } from "@/composables/useGoogleAnalytics";
import { useAuth0 } from "@/composables/useAuth0";
import auth from "@/store/modules/auth";

const props = defineProps({
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const route = useRoute()
const salesBotStore = useSalesBotStore()
const { hasSpecialScenario } = salesBotStore

const { quoteId: previousQuoteExternalId } = route?.params
const loading = ref(false)

async function onClick() {
  try {
    loading.value = true
    const quoteId = await salesBotStore.saveQuote(previousQuoteExternalId || null)
    trackCreateQuote()

    if (hasSpecialScenario) {
      await router.push({
        name: 'quote-index',
        query: { quoteId: quoteId.toString() }
      })
    } else {
      await router.push({
        name: 'checkout-single-bid',
        params: { quoteId: quoteId.toString() }
      })
    }
  } catch(e) {
    console.error(e)
  }
  loading.value = false
}

// GA4 TRACKING
const analytics = useGoogleAnalytics()
const auth0 = useAuth0()

function trackCreateQuote(): void {
  const isLoggedIn = !!auth.isTokenSet && !!auth.userId
  const hasBookedBefore = auth?.customer?.convertedQuoteCount > 0

  analytics.trackEvent(Event.QuoteCreate, {
    isAuth0: auth0.isInitialized,
    hasBookedBefore,
    isLoggedIn,
  })
}

</script>
