<template>
  <div
    class="w-full h-full border-radius-2x border-solid cursor-pointer"
    :class="{
      'border-2 border-solid border-primary': selected,
      'border-1 border-solid border-border-gray': !selected
    }"
    @click="toggle"
  >
    <div
      class="padding-x-3 padding-b-3 d-flex flex-column w-full h-full"
      :class="{
        'border-solid border-transparent border-1': !selected,
      }"
    >
      <div
        class="d-flex justify-space-between padding-t-2"
      >
        <v-checkbox
          :input-value="selected"
          class="margin-t-0"
          hide-details
        />
        <p class="font-medium font-16">
          {{ price }}
        </p>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex">
          <v-icon
            class="margin-r-2"
            :size="22"
            :color="selected ? 'primary' : 'gray'"
          >
            {{ getAmenityIconByKey(amenityOption.amenityTypeKey) }}
          </v-icon>
          <p class="font-medium">{{ amenityOption.label }}</p>
        </div>
        <p class="text-gray-light margin-t-1 font-14">
          {{ amenityOption.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, defineEmits } from 'vue'
import { currencyFilter } from '@/utils/string'
import { getAmenityIconByKey } from '@/utils/amenity'
import { SalesBotAmenityOption } from "@/models/dto/SalesbotV2VehicleOptions"

const props = defineProps<{
  amenityOption: SalesBotAmenityOption,
  selected: boolean
}>()
const emit = defineEmits(['toggle'])

const price = computed(() => {
  if (props.amenityOption.price === 0) return 'Free'
  return `${currencyFilter(props.amenityOption.price)} per vehicle`
})

const toggle = () => emit('toggle')
</script>
