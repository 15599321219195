<template>
  <div>
    <ProviderInfoHeader
      :company="company"
      :is-display-partner-url-enabled="isDisplayPartnerUrlEnabled"
    />
    <v-divider :style="{ 'margin-top': '15px' }" />
    <ProviderInfoVehicleDetails :company="company" />
    <ProviderInfoCompanyInfo :company="company" />
    <ProviderInfoCertifiedPartner v-if="isCertifiedPartner" />
    <ProviderInfoRules :company="company" />
    <ProviderInfoInsurance :company="company" />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { OperatorDetail, OperatorDetailCompany } from '@/models/dto'
import ProviderInfoHeader from '@/components/ProviderInfoHeader.vue'
import ProviderInfoVehicleDetails from '@/components/ProviderInfoVehicleDetails.vue'
import ProviderInfoCompanyInfo from '@/components/ProviderInfoCompanyInfo.vue'
import ProviderInfoCertifiedPartner from './ProviderInfoCertifiedPartner.vue'
import ProviderInfoRules from '@/components/ProviderInfoRules.vue'
import ProviderInfoInsurance from '@/components/ProviderInfoInsurance.vue'

@Component({
  components: {
    ProviderInfoVehicleDetails,
    ProviderInfoCompanyInfo,
    ProviderInfoCertifiedPartner,
    ProviderInfoRules,
    ProviderInfoInsurance,
    ProviderInfoHeader,
  },
})
export default class ProviderInfo extends Vue {
  @Prop({ type: Object, default: null }) readonly providerDetail: OperatorDetail
  @Prop({ type: Boolean, required: true }) readonly isDisplayPartnerUrlEnabled: boolean

  get company(): OperatorDetailCompany {
    return this.providerDetail?.company
  }

  get isCertifiedPartner(): boolean {
    return !!this.providerDetail?.company?.preferred
  }
}
</script>
