<template>
  <CUCard :full-width="fullWidth">
    <div class="d-flex">
      <div class="margin-r-3">
        <CUIcon
          view-box="0 -960 960 960"
          class="w-24 h-24 cursor-default"
          color="midnight-gray-700"
        >
          paid
        </CUIcon>
      </div>
      <div class="d-flex flex-column">
        <h4>
          {{ $t('tripSplitNotice.CHECKOUT_HEADER') }}
        </h4>
        <p class="margin-t-1 font-16">
          {{ $t('tripSplitNotice.TRIP_SPLIT_DETAILS') }}
        </p>
      </div>
    </div>
  </CUCard>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { QuoteDetailV2 } from '../models/dto/QuoteDetailV2'

@Component({})
export default class CheckoutTripSplitNotice extends Vue {
  @Prop({ default: null }) quoteDetail!: QuoteDetailV2
  @Prop({ type: Boolean, required: false, default: false })
  readonly fullWidth!: boolean
}
</script>
