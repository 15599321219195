<template>
  <div>
    <v-icon
      v-if="$vuetify.breakpoint.xs"
      class="panel-handle margin-b-2"
      :class="drawerOpen ? 'flipped' : ''"
      @click="drawerOpen = !drawerOpen"
    >
      keyboard_arrow_up
    </v-icon>
    <v-row class="padding-y-0 padding-x-4 justify-center">
      <v-col v-if="!isModePublic" class="shrink">
        <v-menu :close-on-content-click="false">
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              small
              color="primary"
              class="padding-y-0 padding-r-5"
              :style="{ 'padding-left': '15px' }"
              v-on="on"
            >
              <CUIcon color="white" width="32" height="32" class="margin-t-2">
                filter
              </CUIcon>
              Filter
            </v-btn>
          </template>
          <EnterpriseTrackingFiltering
            :last-vehicle-positions="lastVehiclePositions"
            :is-enterprise-admin="isEnterpriseAdmin"
            @input="$emit('update:filters', $event)"
          />
        </v-menu>
      </v-col>
      <template v-if="isEnterpriseAdmin && $vuetify.breakpoint.smAndUp">
        <v-spacer />
        <v-col class="shrink">
          <div class="toggle-view-type-button-group">
            <div
              class="toggle-view-type-button padding-y-0 -0 padding-l-2"
              :class="{ 'toggle-view-type-button--selected': !isTableView }"
              @click="emitListMode('vehicle')"
            >
              <CUIcon
                :color="!isTableView ? 'white' : 'primary'"
                width="32"
                height="32"
                :style="{ 'margin-top': '7px', 'margin-left': '-1px' }"
              >
                grid_view
              </CUIcon>
            </div>

            <div
              class="toggle-view-type-button padding-y-0 padding-r-0 padding-l-2"
              :class="{ 'toggle-view-type-button--selected': isTableView }"
              @click="emitListMode('table-view')"
            >
              <CUIcon
                :color="isTableView ? 'white' : 'primary'"
                width="32"
                height="32"
                :style="{ 'margin-top': '7px', 'margin-left': '-1px' }"
              >
                list_view
              </CUIcon>
            </div>
          </div>
        </v-col>
      </template>
      <template v-if="!isEnterpriseAdmin && shouldShowTimeline">
        <v-spacer />
        <v-col class="shrink">
          <SkeletonBox v-if="loading" type="button" class="padding-a-0 w-80" />
          <div v-else class="toggle-view-type-button-group">
            <div
              class="toggle-view-type-button padding-y-0 padding-r-0 padding-l-2"
              :class="{
                'toggle-view-type-button--selected': isListModeItinerary,
              }"
              @click="emitListMode('itinerary')"
            >
              <CUIcon
                :color="isListModeItinerary ? 'white' : 'primary'"
                width="32"
                height="32"
                :style="{ 'margin-top': '7px', 'margin-left': '-1px' }"
              >
                schedule
              </CUIcon>
            </div>
            <div
              class="toggle-view-type-button padding-y-0 padding-r-0 padding-l-2"
              :class="{
                'toggle-view-type-button--selected': !isListModeItinerary,
              }"
              @click="emitListMode('vehicle')"
            >
              <CUIcon
                :color="!isListModeItinerary ? 'white' : 'primary'"
                width="32"
                height="32"
                :style="{ 'margin-top': '7px', 'margin-left': '-1px' }"
              >
                bus
              </CUIcon>
            </div>
          </div>
        </v-col>
      </template>
    </v-row>

    <v-expand-transition>
      <div v-if="shouldShowTimeline" class="timeline-container">
        <div v-if="isListModeVehicle" class="padding-b-0 margin-t-3">
          <EnterpriseTrackingSidebarVehicleCard
            v-for="(vehicle, vehicleIndex) in filteredVehicles"
            :id="`${vehicle.trak4DeviceId}-vehicle-${vehicleIndex}`"
            :key="`${vehicle.trak4DeviceId}-vehicle-${vehicleIndex}`"
            :style="`outline-color: ${vehicle.color};`"
            class="margin-x-4 margin-b-4"
            :vehicle="vehicle"
            :is-enterprise-admin="isEnterpriseAdmin"
            :hover="hoverItem === vehicle.trak4DeviceId"
            :eld-types="eldTypes"
            @vehicle-active-toggle="vehicle.active = $event"
            @hover-enter="setHover(vehicle.trak4DeviceId)"
            @hover-leave="setHover('0')"
          />
        </div>
        <v-data-table
          v-else-if="isTableView"
          :headers="columns"
          :items="filteredVehicles"
          :items-per-page="-1"
          hide-default-footer
          class="vehicle-table margin-t-3"
        >
          <template #item.contractName="{ item }">
            <span>{{ item.contractName }}</span>
          </template>
          <template #item.reservationId="{ item }">
            <span>{{ item.reservationId }}</span>
          </template>
          <template #item.lastTransmitted="{ item }">
            <span>{{ timeAgo(`${item.lastTransmitted.split('+')[0]}Z`) }}</span>
          </template>
          <template #item.receivedDate="{ item }">
            <span>{{ formatTimestamp(item.receivedDate) }}</span>
          </template>
        </v-data-table>
        <div v-else-if="isListModeItinerary">
          <v-row
            v-if="
              reservationItineraries.length > 0 &&
              selectedItineraryReservationId !== null
            "
            class="padding-x-8 margin-t-2 margin-b-4"
          >
            <div class="pointer" @click="clearReservationItinerarySelection">
              <v-icon>
                keyboard_arrow_left
              </v-icon>
              Back
            </div>
          </v-row>
          <v-timeline
            v-if="selectedItineraryReservationId !== null"
            align-top
            dense
            right
            class="enterprise-public-itinerary-timeline margin-l-8"
          >
            <template v-if="loading">
              <EnterpriseTrackingSidebarItineraryItemSkeleton
                v-for="index in 3"
                :key="`skeleton-itinerary-card-${index}`"
              />
            </template>
            <template v-else>
              <EnterpriseTrackingSidebarItineraryItem
                v-for="(location, locationIndex) in itinerarySource"
                :key="`${location.address.addressId}-${locationIndex}`"
                :location="location"
                :next-stop-time="nextStopTime"
                :index="locationIndex"
                :total-pickup-and-dropoff-count="totalPickupAndDropoffCount"
              />
            </template>
          </v-timeline>
          <template v-else-if="selectedItineraryReservationId === null">
            <v-card
              v-for="(reservation, reservationIndex) in reservationItineraries"
              :key="`${reservation[0].reservationId}-${reservationIndex}`"
              class="margin-a-4 padding-x-5 padding-y-5 pointer border-2"
              :style="{
                'border-color': reservation[0].color,
              }"
              @click="selectReservationItinerary(reservation[0])"
            >
              <span class="medium-font">
                {{ reservation[0].tripName || reservation[0].managedId }}
              </span>
            </v-card>
          </template>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>
<script lang="ts">
import EnterpriseTrackingFiltering from '@/components/EnterpriseTrackingFiltering.vue'
import EnterpriseTrackingSidebarVehicleCard from '@/components/EnterpriseTrackingSidebarVehicleCard.vue'
import EnterpriseTrackingSidebarItineraryItem from '@/components/EnterpriseTrackingSidebarItineraryItem.vue'
import EnterpriseTrackingSidebarItineraryItemSkeleton from '@/components/EnterpriseTrackingSidebarItineraryItemSkeleton.vue'
import { DateTime } from 'luxon'
import { timeAgo } from '@/utils/datetime'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TypeWithId } from '../models/dto'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({
  components: {
    EnterpriseTrackingFiltering,
    EnterpriseTrackingSidebarItineraryItem,
    EnterpriseTrackingSidebarVehicleCard,
    EnterpriseTrackingSidebarItineraryItemSkeleton,
    SkeletonBox,
  },
})
export default class EnterpriseTrackingSidebar extends Vue {
  @Prop({ type: Boolean, required: true }) readonly isModePublic: boolean
  @Prop({ type: Boolean }) readonly loading: boolean
  @Prop({ type: Array, default: () => [] }) readonly lastVehiclePositions: any[]
  @Prop({ type: String, required: true }) readonly listMode: string
  @Prop({ type: Boolean }) readonly isEnterpriseAdmin: boolean
  @Prop({ type: Boolean }) readonly isTableView: boolean
  @Prop({ type: Array, default: () => [] }) readonly filteredVehicles: any[]
  @Prop({ type: [String, Number], required: true }) readonly hoverItem: any
  @Prop({ type: Array, default: () => [] })
  readonly reservationItineraries: any[]
  @Prop({ type: Object, required: true }) readonly filters: any
  @Prop({ type: Array, default: () => [] }) readonly eldTypes: TypeWithId[]

  drawerOpen = false
  selectedItineraryReservationId = null
  timeAgo = timeAgo

  get totalPickupAndDropoffCount(): number {
    let count = 0
    if (this.itinerarySource) {
      for (const location of this.itinerarySource) {
        count += location.dropoffTimes.length
        count += location.pickupTimes.length
      }
    }
    return count
  }
  get itinerarySource(): any[] {
    if (this.selectedItineraryReservationId !== null) {
      const itinerary = this.reservationItineraries.find(
        (itinerary) =>
          itinerary[0].reservationId === this.selectedItineraryReservationId
      )
      if (itinerary.length >= 2) {
        const dropoffTimes = []
        for (const location of itinerary) {
          dropoffTimes.push(...location.dropoffTimes)
        }
        const lastDropoff = dropoffTimes.sort()[dropoffTimes.length - 1]
        for (const location of itinerary) {
          location.dropoffTimes = location.dropoffTimes.filter(
            (dropoffTime) => dropoffTime === lastDropoff
          )
        }
      }
      return itinerary
    }
    return null
  }
  get shouldShowTimeline(): boolean {
    return this.drawerOpen || this.$vuetify.breakpoint.smAndUp
  }
  get columns(): any[] {
    return [
      { text: 'Vehicle', value: 'vehicleName' },
      { text: 'Contract', value: 'contractName', sortable: false },
      { text: 'Res ID', value: 'reservationId', sortable: false },
      { text: 'Operator', value: 'operatorName' },
      { text: 'Tracker #', value: 'trak4DeviceId' },
      {
        text: 'Last Transmitted',
        value: 'lastTransmitted',
      },
      { text: 'Timestamp', value: 'receivedDate' },
      { text: 'Active', value: 'active' },
    ]
  }
  get isListModeVehicle(): boolean {
    return this.listMode === 'vehicle'
  }
  get isListModeItinerary(): boolean {
    return this.listMode === 'itinerary'
  }
  get nextStopTime(): string {
    const now = DateTime.utc().toISO()
    const stopTimes = []
    for (const itinerary of this.reservationItineraries) {
      for (let i = 0; i < itinerary.length; i++) {
        for (const time of itinerary[i].pickupTimes) {
          if (time && now < time) {
            stopTimes.push(time)
          }
        }
      }
    }
    stopTimes.sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime()
    })
    return stopTimes[0] || null
  }

  emitListMode(mode: string): void {
    this.$emit('change-list-mode', mode)
  }
  formatTimestamp(timestamp: string): string {
    const datetime = DateTime.fromISO(timestamp)
    return datetime.toFormat('LL-dd-yyyy hh:mm a ZZZZ')
  }
  setHover(value): void {
    this.$emit('hover', value)
  }
  selectReservationItinerary(reservation): void {
    this.selectedItineraryReservationId = reservation.reservationId
    const newFilters = {
      ...this.filters,
      reservationId: [reservation.managedId],
    }
    this.$emit('update:filters', newFilters)
  }
  clearReservationItinerarySelection(): void {
    this.selectedItineraryReservationId = null
    const newFilters = {
      ...this.filters,
    }
    delete newFilters.reservationId
    this.$emit('update:filters', newFilters)
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors';

#panel {
  h1 {
    margin: 0;
  }
  h4 {
    margin-bottom: 16px;
  }
  button::after {
    display: none !important;
  }
  .toggle-view-type-button-group {
    display: inline-block;
    width: 80px;
    .toggle-view-type-button {
      display: inline-block;
      background: white;
      border: 2px solid $primary;
      height: 40px;
      width: 40px;
      &:first-child {
        border-radius: 5px 0 0 5px;
        border-right: 1px solid $primary;
      }
      &:last-child {
        border-radius: 0 5px 5px 0;
        border-left: 1px solid $primary;
      }
      &--selected {
        background: $primary;
      }
    }
  }
}

.medium-font {
  font-family: 'Inter Medium', Arial, sans-serif;
}

.vehicle-table {
  overflow: auto;
  height: calc(100vh - 168px);
}

.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: $primary !important;
  color: $primary;
}

::v-deep .v-data-table {
  .v-data-table__wrapper {
    table {
      thead {
        tr {
          th {
            font-size: 0.875rem !important;
            white-space: nowrap;
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 0.875rem !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 599px) {
  #panel {
    .timeline-container {
      overflow: auto;
      max-height: 50vh;
      min-height: 100px;
      .v-timeline {
        &.enterprise-public-itinerary-timeline {
          ::v-deep .v-timeline-item__body {
            margin-top: -16px;
          }
        }
      }
    }

    .panel-handle {
      color: $gray-medium-light;
      width: 100%;
      color: $gray;
      transform: rotate(0deg);
      transition: transform 0.2s linear;
      &.flipped {
        transform: rotate(-180deg);
        transition: transform 0.2s linear;
      }
      .v-icon {
        &::before,
        &::after {
          display: none;
        }
      }
    }
  }

  .v-timeline {
    &.enterprise-public-itinerary-timeline {
      ::v-deep .v-timeline-item {
        &__dot {
          margin-right: 0;
          margin-left: -14px;
        }
      }
    }
  }
}
</style>
