<template>
  <div v-if="hasStopNotes" class="d-flex w-full margin-t-2">
    <CUIcon
      view-box="0 0 24 24"
      icon-name="text-snippet"
      :width="18"
      :height="18"
      class="margin-x-2 flex-shrink-0 cursor-default"
    >
      text_snippet
    </CUIcon>

    <p
      v-if="stopNotes"
      class="d-inline-block max-w-340 margin-t-n2 font-14"
      :class="{
        'text-truncate': !isExpanded,
      }"
      v-html="stopNotes"
    />
    <p
      v-else-if="stop.notes"
      class="d-inline-block max-w-340 margin-t-0 font-14"
      :class="{
        'text-truncate': !isExpanded,
      }"
      :style="{ 'font-style': 'italic' }"
    >
      &ldquo;{{ stop.notes }}&rdquo;
    </p>

    <CUIcon
      v-if="!hideStopNotesArrow"
      view-box="0 0 24 24"
      icon-name="keyboard-arrow"
      :width="18"
      :height="18"
      class="flex-shrink-0 transition-all transition-duration-100 transition-ease-in-out"
      :class="{
        'icon-arrow--flipped': isExpanded,
      }"
      :style="formattedAddress.length > 45 ? 'bottom: -27px;' : 'top: 4px;'"
      @click.native="toggleStopNotes"
    >
      keyboard_arrow_down
    </CUIcon>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Stop } from '../models/dto'
import { addressPrettyFromStop } from '../utils/string'

@Component({})
export default class TripInfoItineraryStopNote extends Vue {
  @Prop({ type: Object, required: true }) stop!: Stop

  isExpanded = false

  get stopNotes(): string {
    return this.stop.stopNotes?.[0]?.html || this.stop.stopNotes?.[0]?.note
  }

  get hasStopNotes(): boolean {
    return !!this.stopNotes
  }

  get formattedAddress(): string {
    return addressPrettyFromStop(this.stop)
  }

  get hideStopNotesArrow(): boolean {
    const CUTOFF_TO_HIDE_ARROW = 55

    if (this.stopNotes) {
      return this.stopNotes.length < CUTOFF_TO_HIDE_ARROW
    }
    return this.stop.notes.length < CUTOFF_TO_HIDE_ARROW
  }

  toggleStopNotes(): void {
    this.isExpanded = !this.isExpanded
  }
}
</script>

<style scoped lang="scss">
.icon-arrow {
  &--flipped {
    transform: rotate(180deg);
  }
}
</style>
