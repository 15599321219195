<template>
  <v-card
    class="padding-y-2 cssVariables"
    :class="{ hover: hover }"
    @mouseover="$emit('hover-enter')"
    @mouseleave="$emit('hover-leave')"
  >
    <v-card-title class="plain">
      <v-col class="padding-y-0 margin-y-0">
        {{ vehicle.vehicleName }}
        <v-icon v-if="vehicle.isADACompliant">
          accessible
        </v-icon>
      </v-col>
      <v-spacer v-if="isEnterpriseAdmin" />
      <v-col v-if="isEnterpriseAdmin" class="shrink padding-a-0 margin-a-0">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-switch
                :value="vehicle.active"
                class="padding-y-0 padding-r-3 padding-l-0 margin-a-0"
                hide-details
                @input="$emit('vehicle-active-toggle', $event)"
              />
            </div>
          </template>
          <span class="text-white">
            {{ vehicle.vehicleName }} is
            {{ vehicle.active ? 'active' : 'inactive' }}
          </span>
        </v-tooltip>
      </v-col>
    </v-card-title>
    <v-card-text class="padding-t-0 margin-t-0">
      <template v-if="isEnterpriseAdmin">
        <p>Reservation: {{ vehicle.reservationId }}</p>
        <p>Operator: {{ vehicle.operatorName }}</p>
        <p>Contract: {{ vehicle.contractName }}</p>
        <p>Device ID: {{ vehicle.deviceId }}</p>
        <p>
          ELD:
          {{ getEldLabel(vehicle) }}
        </p>
        <p>
          Timestamp:
          {{ new Date(Date.parse(vehicle.receivedDate)).toLocaleString() }}
        </p>
      </template>

      <p>Vehicle Type: {{ vehicle.vehicleType }}</p>
      <p>Traveling: {{ vehicle.gpsSpeed || 0 }} mph {{ vehicle.direction }}</p>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { timeAgo } from '@/utils/datetime'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TypeWithId } from '../models/dto'

@Component({})
export default class EnterpriseTrackingSidebarVehicleCard extends Vue {
  @Prop({ type: Object, required: true }) readonly vehicle: any
  @Prop({ type: Boolean }) readonly isEnterpriseAdmin: boolean
  @Prop({ type: Boolean }) readonly hover: boolean
  @Prop({ type: Array, default: () => [] }) readonly eldTypes: TypeWithId[]

  timeAgo = timeAgo

  get cssVariables(): any {
    return { '--hover-color': this.vehicle.color }
  }

  getEldLabel(vehicle) {
    return (
      vehicle.eldType ??
      (this.eldTypes.find((eldType) => eldType.id === vehicle.eldTypeId)
        ?.label ||
        '')
    )
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors';

$hover-color: var(--hover-color);

.v-card {
  &.hover {
    outline-style: solid;
    outline-width: 2px;
    outline-offset: -2px;
    outline-color: $hover-color;
  }
  .v-card__title {
    font-family: 'Inter Bold', Arial, sans-serif;
    font-size: 1rem !important;
    line-height: 1rem;
  }
  .v-card__text {
    p {
      font-size: 0.875rem;
      color: $gray;
      margin: 0 0 2px;
    }
  }
}
</style>
