<template>
  <CUModal
    id="prompt-call-for-bas-dialog"
    :value="value"
    width="500px"
    @input="$emit('input', $event)"
  >
    <template #text>
      <div class="d-flex flex-column text-center margin-x-auto max-w-400">
        <v-icon class="margin-x-auto margin-b-3" size="70">
          $color-phone
        </v-icon>
        <h2>
          You're almost there!
        </h2>
        <p>
          Your requested pickup is less than 4 days away. Please call us now to
          confirm your quote and secure your travel.
        </p>
        <p v-if="accountExecutive">
          Account Executive:
          {{ `${accountExecutive.firstName} ${accountExecutive.lastName}` }}
        </p>
        <v-btn
          color="primary"
          class="margin-t-6"
          @click="quotePromptCallNumber"
        >
          <CUIcon view-box="0 0 24 24" class="margin-r-2">
            phone
          </CUIcon>
          {{ formattedAccountExecutiveNumber }}
        </v-btn>
      </div>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import InfoDialogLayout from '@/layouts/InfoDialogLayout.vue'
import auth from '@/store/modules/auth'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'
import CustomerAccountDefaultValueSet from '@/models/dto/CustomerAccountDefaultValueSet'
import AccountExecutive from '@/models/dto/AccountExecutive'
import customerAccount from '@/services/customerAccount'

@Component({ components: { InfoDialogLayout } })
export default class PromptCallForBASDialog extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean
  effectiveDefaultValueSet: CustomerAccountDefaultValueSet = null

  async created(): Promise<void> {
    await this.getEffectiveDefaultValueSet()
  }

  @Watch('auth.customerAccount.customerAccountId')
  customerAccountChanged(): void {
    this.getEffectiveDefaultValueSet()
  }

  get formattedAccountExecutiveNumber(): string {
    const accountExecutivePhoneNumber = this.accountExecutive?.phone
    const isElite = !!auth.customer?.isElite
    const supportNumber = getSupportNumber(isElite)
    const rawPhone = accountExecutivePhoneNumber || supportNumber
    return phoneFormatFilterHyphens(rawPhone)
  }

  get accountExecutive(): AccountExecutive {
    return this.effectiveDefaultValueSet?.accountExecutive
  }

  quotePromptCallNumber(): void {
    window.open(`tel:${this.formattedAccountExecutiveNumber}`)
  }

  async getEffectiveDefaultValueSet(): Promise<void> {
    if (!auth.user || !auth.customerAccount) {
      return
    }
    try {
      const { data } = await customerAccount.effectiveDefaultValueSet(
        auth.customerAccount.customerAccountId
      )
      this.effectiveDefaultValueSet = data
    } catch (error) {
      console.error('Error fetching effective default value set', error)
    }
  }
}
</script>
