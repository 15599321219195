<template>
  <CUModal
    :value="value"
    width="560px"
    max-width="560px"
    :closeable="false"
    @input="$emit('input', $event)"
    @keydown.stop="
      (event) => {
        if (event.keyCode === KeyCode.Escape) {
          closeDialog()
        }
      }
    "
  >
    <template #title>
      <div class="font-16 font-medium w-full" style="text-align: center;">
        <p>
          Are you sure you want to leave?
        </p>
        <p>
          Changes will not be saved.
        </p>
      </div>
    </template>
    <template #text>
      <v-btn
        id="trip-modification-close-dialog-button-no"
        outlined
        small
        color="primary"
        class="margin-r-2 w-140"
        @click="closeDialog"
      >
        No
      </v-btn>
      <v-btn
        id="trip-modification-close-dialog-button-yes"
        color="primary"
        small
        class="margin-l-2 w-140"
        @click="closeWizard"
      >
        Yes
      </v-btn>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Model } from 'vue-property-decorator'
import CUModal from '@/components/CUModal.vue'
import InfoModal from '@/components/InfoModal.vue'
import tripModificationStore from '@/store/modules/tripModification'
import { KeyCode } from '@/utils/enum'

@Component({
  components: {
    CUModal,
    InfoModal,
  },
})
export default class TripModificationCloseDialog extends Vue {
  @Model('input') readonly value!: boolean | null
  KeyCode = KeyCode

  closeDialog(): void {
    this.$emit('input', false)
  }

  closeWizard(): void {
    tripModificationStore.setIsOpen(false)
    tripModificationStore.resetState()
  }
}
</script>
