import Vue from 'vue'
import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'
import customer from '@/services/customer'
import { load, save } from '@/utils/localStorage'
import { stripUSCountryCode } from '@/utils/phone'
import RequiredCustomerInfoUpdateDTO from '@/models/dto/RequiredCustomerInfoUpdateDTO'
import RequiredCustomerInfoCreateDTO from '@/models/dto/RequiredCustomerInformationCreateDTO'

@Module({ generateMutationSetters: true })
class UserModule extends VuexModule {
  _isRequiredInformationComplete: boolean = load('requiredInformationComplete') || false
  _firstName: string = ''
  _lastName: string = ''
  _email: string = ''
  _phone: string = ''
  _bookings: number = null
  _isQuoteFollowUpSMSEnabled: boolean = true
  _isExistingCustomer: boolean = false

  /**
   * Returns whether the required information is complete.
   * @returns whether the required information is complete.
   */
  get isRequiredInformationComplete(): boolean {
    return this._isRequiredInformationComplete
  }

  /**
   * Returns the user's first name.
   * @returns the user's first name.
   */
  get firstName(): string {
    return this._firstName
  }

  /**
   * Returns the user's last name.
   * @returns the user's last name.
   */
  get lastName(): string {
    return this._lastName
  }

  /**
   * Returns the user's email.
   * @returns the user's email.
   */
  get email(): string {
    return this._email
  }

  /**
   * Returns the user's phone number.
   * @returns the user's phone number.
   */
  get phone(): string {
    return this._phone
  }

  /**
   * Returns the user's bookings.
   * @returns the user's bookings.
   */
  get bookings(): number {
    return this._bookings
  }

  /**
   * Returns whether the user has enabled quote follow up SMS.
   * @returns whether the user has enabled quote follow up SMS.
   */
  get isQuoteFollowUpSMSEnabled(): boolean {
    return this._isQuoteFollowUpSMSEnabled
  }

  /**
   * Returns whether the user is an existing customer.
   * @returns whether the user is an existing
   */
  get isExistingCustomer(): boolean {
    return this._isExistingCustomer
  }

  @Action
  async fetchRequiredInformation(): Promise<void> {
    const { data } = await customer.getRequiredInfo()
    const { isComplete, email, firstName, lastName, phone, bookings, isQuoteFollowUpSMSEnabled, isExistingCustomer } = data
    const processedPhone = stripUSCountryCode(phone)

    this.setRequiredInformationComplete(isComplete)
    this.setFirstName(firstName)
    this.setLastName(lastName)
    this.setEmail(email)
    this.setPhone(processedPhone)
    this.setBookings(bookings)
    this.setIsQuoteFollowUpSMSEnabled(isQuoteFollowUpSMSEnabled)
    this.setIsExistingCustomer(isExistingCustomer)
  }

  private setRequiredInformationComplete(isComplete: boolean): void {
    this._isRequiredInformationComplete = isComplete
    save('requiredInformationComplete', isComplete)
  }

  @Action
  setFirstName(firstName: string): void {
    this._firstName = firstName
  }

  @Action
  setLastName(lastName: string): void {
    this._lastName = lastName
  }

  @Action
  setEmail(email: string): void {
    this._email = email
  }

  @Action
  setPhone(phone: string): void {
    this._phone = phone
  }

  @Action
  setBookings(bookings: number): void {
    this._bookings = bookings
  }

  @Action
  setIsQuoteFollowUpSMSEnabled(isQuoteFollowUpSMSEnabled: boolean): void {
    this._isQuoteFollowUpSMSEnabled = isQuoteFollowUpSMSEnabled
  }

  @Action
  setIsExistingCustomer(isExistingCustomer: boolean): void {
    this._isExistingCustomer = isExistingCustomer
  }

  @Action
  async prepopulateFromIdToken(): Promise<void> {
    if (!Vue.prototype.$auth0.isInitialized) { // make sure we don't do this if Auth0 is not enabled
      return
    }

    try {
      const idToken = await Vue.prototype.$auth0.getIdTokenClaims()

      if (!idToken) {
        console.warn('No ID token available.')
        return
      }

      const { given_name = '', family_name = '', phone_number = '' } = idToken

      const firstName = this._firstName || given_name
      const lastName = this._lastName || family_name
      const email = this._email || idToken.email
      const phone = this._phone || phone_number

      this.setFirstName(firstName)
      this.setLastName(lastName)
      this.setEmail(email)
      this.setPhone(phone)
    } catch (error) {
      console.error('Failed to prepopulate user details from ID token:', error)
    }
  }

  @Action
  async updateRequiredInformation(): Promise<void> {
    const payload: RequiredCustomerInfoUpdateDTO = {
      firstName: this._firstName,
      lastName: this._lastName,
      phone: this._phone,
      bookings: this._bookings,
      isQuoteFollowUpSMSEnabled: this._isQuoteFollowUpSMSEnabled
    }
    await customer.updateRequiredInfo(payload)
  }

  @Action
  async createWithRequiredInformation(): Promise<void> {
    const payload: RequiredCustomerInfoCreateDTO = {
      firstName: this._firstName,
      lastName: this._lastName,
      phone: this._phone,
      bookings: this._bookings,
      isQuoteFollowUpSMSEnabled: this._isQuoteFollowUpSMSEnabled
    }
    await customer.selfServeCreate(payload)
  }

}

export default new UserModule({ store, name: 'user' })
