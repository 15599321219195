<template>
  <div
    class="d-flex justify-space-between"
    :class="{
      'padding-t-8': !removePadding,
      'padding-t-12 padding-x-32': !isMobile && !removePadding && !paymentUpdate,
      'flex-column': isMobile
    }"
  >
    <div class="max-w-352 min-w-352">
      <template v-if="currentTrip">
        <h2 class="margin-b-3 font-medium">
          Current trip
        </h2>
        <TripReviewMetadata
          :vehicles="currentTrip.vehicles"
          :passengerCount="currentTrip.passengerCount"
          :tripTypeId="currentTrip.tripTypeId"
        />
        <v-card class="background-white margin-t-4">
          <v-card-text class="padding-t-4 padding-b-0 padding-x-4">
            <template
              v-if="
                currentTrip.ada ||
                currentTrip.spab ||
                currentTrip.vehicleNeededEntireTrip
              "
            >
              <TripReviewRequirements
                :ada="currentTrip.ada"
                :spab="currentTrip.spab"
                :vehicleNeededEntireTrip="currentTrip.vehicleNeededEntireTrip"
              />
              <v-divider />
            </template>
            <TripReviewItinerary
              :stops="currentTrip.stops"
              class="margin-t-4"
            />
          </v-card-text>
        </v-card>
      </template>
    </div>
    <div
      class="d-flex align-center"
      :class="{ 'justify-center margin-y-8': isMobile }"
    >
      <CUIcon v-if="isMobile" view-box="0 0 24 24" class="w-24 h-24">
        arrow_downward
      </CUIcon>
      <CUIcon v-else view-box="0 0 24 24" class="w-36 h-36">
        arrow_right
      </CUIcon>
    </div>
    <div class="max-w-352 min-w-352">
      <h2 class="margin-b-3 font-medium text-primary">
        Trip modifications
      </h2>
      <template v-if="requestedTripForReview && changesMade">
        <TripReviewMetadata
          :vehicles="requestedTripForReview.vehicles"
          :passengerCount="requestedTripForReview.passengerCount"
          :tripTypeId="requestedTripForReview.tripTypeId"
          :tripTypeChanged="requestedTripForReview.tripTypeChanged"
          :passengerCountChanged="requestedTripForReview.passengerCountChanged"
          :vehiclesChanged="requestedTripForReview.vehiclesChanged"
        />
        <v-card class="background-white margin-t-4">
          <v-card-text class="padding-t-4 padding-b-0 padding-x-4">
            <template
              v-if="
                requestedTripForReview.ada ||
                requestedTripForReview.spab ||
                requestedTripForReview.vehicleNeededEntireTrip
              "
            >
              <TripReviewRequirements
                :ada="requestedTripForReview.ada"
                :spab="requestedTripForReview.spab"
                :vehicleNeededEntireTrip="
                  requestedTripForReview.vehicleNeededEntireTrip
                "
                :adaChanged="requestedTripForReview.adaChanged"
                :spabChanged="requestedTripForReview.spabChanged"
                :vehicleNeededEntireTripChanged="
                  requestedTripForReview.vehicleNeededEntireTripChanged
                "
              />
              <v-divider />
            </template>
            <TripReviewItinerary
              :stops="requestedTripForReview.stops"
              class="margin-t-4"
            />
          </v-card-text>
        </v-card>
      </template>
      <template v-else>
        <p>
          No changes were specified.
        </p>
      </template>
    </div>
    <TripModificationPayment v-if="paymentUpdate && isTripModificationsV3Enabled"/>
  </div>
</template>

<script lang="ts">
import { Vue, Component, InjectReactive } from 'vue-property-decorator'
import TripReviewItinerary from '@/components/TripReviewItinerary.vue'
import TripReviewRequirements from '@/components/TripReviewRequirements.vue'
import TripReviewMetadata from '@/components/TripReviewMetadata.vue'
import tripModificationStore from '@/store/modules/tripModification'
import { TripModificationTrip, ModifiedTrip, TripModificationPaymentUpdate } from '@/models/dto'
import TripModificationPayment from './TripModificationPayment.vue'
import { SplitFeatureFlag } from '@/utils/enum'

@Component({
  components: {
    TripReviewItinerary,
    TripReviewRequirements,
    TripReviewMetadata,
    TripModificationPayment
  },
})
export default class TripModificationReview extends Vue {
  @InjectReactive('removePadding') removePadding

  isTripModificationsV3Enabled = false

  get currentTrip(): TripModificationTrip {
    return tripModificationStore.currentTrip
  }

  get requestedTripForReview(): ModifiedTrip {
    return tripModificationStore.requestedTripForReview
  }

  get paymentUpdate(): TripModificationPaymentUpdate {
    return tripModificationStore.paymentUpdate
  }

  get changesMade(): boolean {
    const requirementsModified =
      this.requestedTripForReview.adaChanged ||
      this.requestedTripForReview.spabChanged ||
      this.requestedTripForReview.vehicleNeededEntireTripChanged
    const metadataModified =
      this.requestedTripForReview.passengerCountChanged ||
      this.requestedTripForReview.tripTypeChanged ||
      this.requestedTripForReview.vehiclesChanged
    const stopsModified = this.requestedTripForReview.stops.some(
      (stop) => stop.stopChanged
    )
    return requirementsModified || metadataModified || stopsModified
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly
  }

  async mounted() {
    this.isTripModificationsV3Enabled = await this.$split.isFeatureEnabled(
      SplitFeatureFlag.ItineraryModificationWizard
    )
    tripModificationStore.setForwardButtonDisabled(false)
  }
}
</script>
