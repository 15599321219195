<template>
  <div>
    <BusJoinUsColorIcon class="w-220 h-100 margin-b-8" />

    <h1 class="signup-header-secondary font-30">
      {{ $t('signup.VALUE_PROP.HEADER_LINE_1') }}
      <br />
      {{ $t('signup.VALUE_PROP.HEADER_LINE_2') }}
    </h1>

    <p
      class="font-18"
      :style="{ 'line-height': '23px', 'margin-bottom': '30px' }"
    >
      {{ $t('signup.VALUE_PROP.SUBHEAD') }}
    </p>

    <ul class="font-medium">
      <li
        v-for="(valueProp, valuePropIndex) in valueProps"
        :key="`amenity-${valuePropIndex}-${valueProp.text}`"
        class="margin-b-4 d-flex align-center"
      >
        <component :is="valueProp.icon" :style="{ width: '35px', height: '35px'}" />
        <span class="margin-l-3">
          {{ valueProp.text }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import BusJoinUsColorIcon from '@/icons/BusJoinUsColorIcon.vue'
import ProgressBlueColorIcon from '@/icons/ProgressBlueColorIcon.vue'
import MarkerColorIcon from '@/icons/MarkerColorIcon.vue'
import CashColorIcon from '@/icons/CashColorIcon.vue'
import PhoneColorIcon from '@/icons/PhoneColorIcon.vue'

@Component({ components: { ProgressBlueColorIcon, CashColorIcon, PhoneColorIcon, MarkerColorIcon, BusJoinUsColorIcon } })
export default class SignUpSidebar extends Vue {
  valueProps: { icon: any; text: TranslateResult }[] = [
    { icon: ProgressBlueColorIcon, text: this.$t('signup.VALUE_PROP.PRICE') },
    { icon: CashColorIcon, text: this.$t('signup.VALUE_PROP.CANCELLATION') },
    { icon: PhoneColorIcon, text: this.$t('signup.VALUE_PROP.SUPPORT') },
    { icon: MarkerColorIcon, text: this.$t('signup.VALUE_PROP.TRACKING') },
  ]
}
</script>
