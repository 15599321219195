<template>
  <v-fade-transition :duration="{ enter: 300, leave: 500 }">
    <div
      v-if="show"
      class="d-flex grow h-auto min-w-200 min-h-48 position-fixed bottom-8 right-8 shadow-5 border-radius-regular background-white align-stretch"
      :style="{ 'z-index': 205 }"
      :class="{
        'max-w-388': $vuetify.breakpoint.smAndUp,
        'max-w-full left-8': $vuetify.breakpoint.xsOnly,
        'cursor-pointer': !!alert.action,
      }"
      @input="close"
    >
      <div
        class="min-h-full h-auto max-h-full min-w-8 max-w-8 border-radius-top-left-regular border-radius-bottom-left-regular"
        :class="`background-${color}`"
      >
        &nbsp;
      </div>
      <div class="d-flex align-center w-full">
        <div class="flex-column padding-y-2 padding-x-4">
          <div
            v-if="title"
            class="font-16 font-bold"
            :class="`text-${color}`"
            :style="{ flex: 1 }"
          >
            {{ title }}
          </div>
          <div
            class="font-14 font-medium"
            :class="`text-${color}`"
            :style="{ flex: 1 }"
          >
            {{ text }}
          </div>
        </div>
        <div
          v-if="dismissible"
          class="d-flex margin-l-auto margin-r-2 margin-y-auto"
        >
          <CUIcon
            :color="color"
            class="cursor-pointer"
            view-box="0 0 24 24 "
            @click.stop="close"
          >
            close
          </CUIcon>
        </div>
      </div>
    </div>
  </v-fade-transition>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator'

import { Alert } from '@/models/Alert'
import alert from '@/store/modules/alert'
@Component({})
export default class TheAlert extends Vue {
  timeout = null

  @Watch('alert', { deep: true })
  alertChange(a: Alert): void {
    if (a?.duration && a.duration >= 0) {
      this.timeout = setTimeout(this.close, a.duration)
    }
  }
  get alert(): Alert {
    return alert.current
  }

  get show(): boolean {
    return !!this.alert
  }

  get color(): string {
    return this.alert?.color || ''
  }

  get title(): string {
    return this.alert?.title || ''
  }

  get text(): string {
    return this.alert?.text || ''
  }

  get dismissible(): boolean {
    return !!this.alert?.dismissible
  }

  close(): void {
    alert.clear()
    clearTimeout(this.timeout)
  }
}
</script>
