<template>
  <v-row align="stretch" justify="center" class="margin-b-11" no-gutters>
    <v-col cols="8" class="text-center">
      <v-img
        :src="require('@/assets/images/no-quotes-graphic.png')"
        class="margin-y-6"
      />
      <div class="font-40  font-medium">
        <slot />
      </div>
      <v-col>
        Book your trip now. Agents are available 24/7 to assist you.
      </v-col>
      <v-col>
        <v-btn
          dark
          primary
          small
          class="primary"
          @click="quoteExpiredIsOpen = false"
        >
          <CUIcon color="white" left size="16">
            phone
          </CUIcon>
          1-855-920-2287
        </v-btn>
      </v-col>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class EmptyTable extends Vue {}
</script>
