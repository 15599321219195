<template>
  <div
    v-if="active"
    class="alert-box-row d-flex border-radius-4 border-1 border-solid padding-a-1"
    :class="{
      'border-error background-background-error': type === 'error',
      'border-success background-background-success': type === 'success',
    }"
    :style="`height:${height}`"
    no-gutters
    align="stretch"
  >
    <div
      class="d-flex flex-shrink flex-column border-radius-top-left-4 border-radius-top-right-0 border-radius-bottom-right-0 border-radius-bottom-left-4 padding-y-3 padding-l-2"
      cols="auto"
      align-self="center"
      :class="{
        'd-flex align-center justify-center': $vuetify.breakpoint.smAndUp,
        'text-center': $vuetify.breakpoint.xsOnly,
      }"
    >
      <CUIcon v-if="type === 'error'" color="error" view-box="0 0 24 24">
        {{ errorIcon }}
      </CUIcon>
      <CUIcon v-else color="success" view-box="0 0 24 24">
        check_circle
      </CUIcon>
    </div>
    <v-col
      class="border-radius-top-right-3 border-radius-bottom-right-3 font-14  d-flex align-center padding-y-0 padding-x-4"
      :class="{
        'background-background-success': type === 'success',
        'background-background-error': type === 'error',
      }"
    >
      <div><slot /></div>
    </v-col>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class AlertBox extends Vue {
  @Prop({ type: String, default: 'success' }) readonly type: string
  @Prop({ type: Boolean }) readonly active: boolean
  @Prop({ type: String, default: 'warning' }) readonly errorIcon: string
  @Prop({ type: String, default: null }) readonly height: string
}
</script>
