<template>
  <div>
    <label v-if="label">
      {{ label }}
    </label>
    <v-menu
      v-model="isOpen"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      :left="$vuetify.breakpoint.xsOnly"
      origin="top center 0"
      min-width="auto"
      :disabled="disabled"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :id="id"
          :value="inputDisplayText"
          readonly
          :rules="rules"
          :hide-details="hideDetails"
          outlined
          :placeholder="placeholder"
          :disabled="disabled"
          v-bind="attrs"
          v-on="on"
        >
          <template #append>
            <slot name="append" />
          </template>
        </v-text-field>
      </template>
      <v-card class="background-white border-0 padding-a-4">
        <CUDigitalTimePicker :value="value" @input="$emit('input', $event)" />
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUDigitalTimePicker from '@/components/CUDigitalTimePicker.vue'

@Component({ components: { CUDigitalTimePicker } })
export default class CUTimePicker extends Vue {
  @Prop({ type: String, required: false, default: '' }) readonly label: string
  @Prop({ type: String, required: false, default: '' }) readonly placeholder: string
  @Prop({ type: String, required: false, default: '' }) readonly id: string
  @Prop({ type: Array, required: false, default: () => [] })
  readonly rules: any[]
  @Prop({ type: Boolean }) readonly hideDetails: boolean
  @Prop({ type: String, required: true }) readonly value: string
  @Prop({ type: String, required: false }) readonly displayValue: string
  @Prop({ type: Boolean, required: false, default: false}) readonly disabled: boolean

  get inputDisplayText(): string {
    return this.displayValue || this.value
  }

  isOpen = false
}
</script>
