<template>
  <v-container class="padding-a-0">
    <v-row
      v-if="$vuetify.breakpoint.xs"
      align="center"
      no-gutters
      justify="space-between"
      class="margin-b-n4"
    >
      <v-col>
        <v-btn
          icon
          color="white"
          :to="{
            name: 'quote-detail',
            params: { id: $route.params.quoteId },
          }"
        >
          <CUIcon small left color="gray">
            arrow_back_ios
          </CUIcon>
        </v-btn>
      </v-col>
    </v-row>

    <StickyColumnLayout>
      <template #left-column>
        <SkeletonBox type="header" class="h-30 max-w-400" />
        <SkeletonBox type="text" class="margin-t-2 h-18 max-w-600" />
        <v-divider :style="{ 'margin-top': '15px' }" />
        <SkeletonBox type="text" class="margin-y-6 margin-x-0 h-28 max-w-276" />
        <SkeletonBox
          type="text"
          class="margin-t-6 margin-x-0 margin-b-8 h-276"
        />
        <v-divider class="margin-t-8" />
        <SkeletonBox
          type="text"
          class="margin-t-8 margin-x-0 margin-b-6 h-28 max-w-276"
        />
        <SkeletonBox type="text" class="margin-t-2 max-w-full" />
        <SkeletonBox type="text" class="margin-t-2 margin-b-6 max-w-full" />
        <SkeletonBox type="text" class="margin-t-8 max-w-full h-40" />
        <SkeletonBox type="text" class="margin-t-2 max-w-full h-40" />
        <SkeletonBox type="text" class="margin-t-2 max-w-full h-40" />
        <v-divider class="margin-y-8 margin-x-0" />
        <SkeletonBox
          type="text"
          class="margin-t-8 margin-x-0 margin-b-2 max-w-276 h-28"
        />
        <v-row
          v-for="index in 5"
          :key="`skeleton-1-${index}`"
          class="margin-t-0 margin-b-n4 padding-a-0"
        >
          <v-col cols="6">
            <SkeletonBox type="text" />
          </v-col>
          <v-col cols="6">
            <SkeletonBox type="text" />
          </v-col>
        </v-row>
        <v-divider class="margin-y-8 margin-x-0" />
        <SkeletonBox
          type="text"
          class="margin-t-8 margin-x-0 margin-b-2 max-w-276 h-28"
        />
        <v-row
          v-for="index in 2"
          :key="`skeleton-2-${index}`"
          class="margin-t-0 margin-b-n4 padding-a-0"
        >
          <v-col cols="6">
            <SkeletonBox type="text" />
          </v-col>
          <v-col cols="6">
            <SkeletonBox type="text" />
          </v-col>
        </v-row>
        <v-divider class="margin-y-8 margin-x-0" />
        <SkeletonBox
          type="text"
          class="margin-t-8 margin-x-0 margin-b-6 max-w-276 h-28"
        />
        <SkeletonBox type="text" class="margin-b-2" />
        <SkeletonBox type="text" class="margin-b-2" />
        <SkeletonBox type="text" class="margin-b-2" />
      </template>
      <template #right-column>
        <v-card :style="{ 'margin-top': '70px' }">
          <v-card-text :style="{ padding: '17px 25px' }">
            <v-row dense class="font-16">
              <v-col cols="8">
                <SkeletonBox type="text" />
              </v-col>
              <v-spacer />
              <v-col cols="3">
                <SkeletonBox type="text" />
              </v-col>
              <v-col cols="8">
                <SkeletonBox type="text" />
              </v-col>
              <v-spacer />
              <v-col cols="3">
                <SkeletonBox type="text" />
              </v-col>
              <v-col cols="8">
                <SkeletonBox type="text" />
              </v-col>
              <v-spacer />
              <v-col cols="3">
                <SkeletonBox type="text" />
              </v-col>
            </v-row>
            <SkeletonBox
              type="button"
              class="margin-t-4"
              :style="{ height: '57px' }"
            />
          </v-card-text>
        </v-card>

        <SkeletonTripCollectionCard />
      </template>
    </StickyColumnLayout>
  </v-container>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import StickyColumnLayout from '@/layouts/StickyColumnLayout.vue'
import SkeletonTripCollectionCard from '@/components/SkeletonTripCollectionCard.vue'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({
  components: {
    StickyColumnLayout,
    SkeletonTripCollectionCard,
    SkeletonBox,
  },
})
export default class ProviderInfoSkeleton extends Vue {}
</script>
