<template>
  <v-container>
    <div class="text-center margin-b-6">
      <div class="d-inline-flex align-center">
        <h1 class="margin-a-0 padding-t-0 padding-x-4">
          {{ tripTypeStep.header }}
        </h1>
      </div>
      <h3 class="margin-a-0 padding-a-0 subtitle-1 font-weight-400">
        {{ tripTypeStep.subheader }}
      </h3>
    </div>
    <v-row>
      <v-col
          v-for="(tripType, tripTypeIndex) in tripTypes"
          :key="`trip-type-${tripType.label}-${tripTypeIndex}`"
          cols="12"
          md="4"
      >
        <div
            class="border-radius-2x padding-a-4 border-2 border-solid font-medium text-center cursor-pointer transition-all transition-duration-300 transition-ease h-full d-flex"
            :class="{
            'border-border-gray': !isTripTypeSelected(tripType.key),
            'border-primary': isTripTypeSelected(tripType.key),
            'flex-column align-center justify-end': smAndUp,
          }"
            @click="selectTripType(tripType.key)"
        >
          <img
              v-if="smAndUp"
              :src="tripType.icon"
              class="min-h-76 max-h-100 margin-b-4"
          />
          <span class="order-1 order-sm-0">{{ tripType.label }}</span>
          <CURadioButton
              class="order-0 order-sm-1"
              x-large
              :selected="isTripTypeSelected(tripType.key)"
              :class="{
              'margin-r-4': xs,
              'margin-t-2': smAndUp,
            }"
          />
        </div>
      </v-col>
      <v-expand-transition>
        <v-col v-if="selectedTripType.description" cols="12">
          <div
              class="align-center d-flex justify-center padding-x-4 text-body-1"
          >
            <CUIcon view-box="0 0 24 24" color="primary" class="margin-r-2">
              info_outlined
            </CUIcon>
            <p class="margin-t-0">{{ selectedTripType.description }}</p>
          </div>
        </v-col>
      </v-expand-transition>
      <v-col cols="12" class="d-flex justify-center">
        <v-btn
            color="primary"
            :disabled="!selectedTripType"
            class="w-full"
            :class="{
            'max-w-320': smAndUp,
          }"
            @click="onClickContinue"
        >
          Continue
          <CUIcon view-box="0 0 24 24" class="margin-l-2"> arrow_right</CUIcon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { SalesBotStepKey, TripTypeKey } from "@/utils/enum"
import { computed, onMounted } from "vue"
import CURadioButton from "@/components/CURadioButton.vue"
import { useDisplay } from '@/composables/useDisplay'
import { useGoogleAnalytics, Event } from "@/composables/useGoogleAnalytics"
import { useAuth0 } from "@/composables/useAuth0"
import oneWayIcon from "@/assets/images/one-way.svg"
import roundTripIcon from "@/assets/images/round-trip.svg"
import largeEventIcon from "@/assets/images/large-events.svg"
import { useSalesBotStore } from "@/store/modules/salesBot"
import auth from "@/store/modules/auth";

interface TripType {
  key: TripTypeKey
  label: string
  icon: any
  description: string
}

// TODO: This type will be added to support corporate shuttles as part of CUWEB-1081.
// {
//   key: TripTypeKey.Shuttle,
//   label: "Corporate Shuttle",
//   icon: corporateShuttleIcon,
//   description: "Daily commutes and business travel for employees.",
// },
const tripTypes: TripType[] = [
  {
    key: TripTypeKey.OneWay,
    label: "One Way",
    icon: oneWayIcon,
    description: "A one way trip is pretty self-explanatory.",
  },
  {
    key: TripTypeKey.RoundTrip,
    label: "Round Trip",
    icon: roundTripIcon,
    description: "A round trip always brings you back home.",
  },
  {
    key: TripTypeKey.LargeEvent,
    label: "Large Event",
    icon: largeEventIcon,
    description: "A large event requires six or more vehicles.",
  },
]

const salesBotStore = useSalesBotStore()
const { xs, smAndUp } = useDisplay()
const startingTripTypeKey = salesBotStore.tripTypeKey

const tripTypeStep = salesBotStore.getStepByKey(SalesBotStepKey.TripType)

const selectedTripType = computed<TripType | null>(() => {
  const selectedTripType = tripTypes.find(
      (tripType) => salesBotStore.tripTypeKey === tripType.key
  )
  return selectedTripType || null
})

function isTripTypeSelected(key: TripTypeKey): boolean {
  if (!selectedTripType) {
    return false
  }
  return selectedTripType.value.key === key
}

function selectTripType(key: TripTypeKey): void {
  salesBotStore.setTripTypeKey(key)
}

const isValid = () => Object.values(TripTypeKey).includes(selectedTripType.value.key)

function onClickContinue(): void {
  if (!isValid()) {
    return
  }
  salesBotStore.setStepValid(SalesBotStepKey.TripType, true)
  salesBotStore.applyDefaultStopLogic(startingTripTypeKey, selectedTripType.value.key)

  trackEvent(Event.TripType);
  tripTypeStep.next()
}

onMounted(() => {
  trackEvent(Event.ViewStart)
})

// GA4 TRACKING
const analytics = useGoogleAnalytics()
const auth0 = useAuth0()

function trackEvent(event: Event): void {
  const isLoggedIn = !!auth.isTokenSet && !!auth.userId
  const hasBookedBefore = auth?.customer?.convertedQuoteCount > 0

  analytics.trackEvent(event, {
    isAuth0: auth0.isInitialized,
    hasBookedBefore,
    isLoggedIn,
  })
}

</script>

<style lang="scss" scoped>
* {
  touch-action: manipulation;
}
</style>
