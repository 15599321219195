<template>
  <v-navigation-drawer
    :value="sidebar.isOpen"
    app
    right
    width="70vw"
    temporary
    class="z-100 font-medium font-20  padding-a-4"
    @input="sidebar.setIsOpen($event)"
  >
    <div
      id="sidebar-button-close"
      class="d-flex justify-end"
      @click="sidebar.setIsOpen(false)"
    >
      <CUIcon x-large class="cursor-pointer">
        close
      </CUIcon>
    </div>

    <v-toolbar-title>
      <router-link id="sidebar-button-logo" :to="{ name: 'home' }" tag="a">
        <CharterUPLogo width="180" />
      </router-link>
    </v-toolbar-title>

    <v-list>
      <v-list-item-group
        v-model="listModel"
        class="margin-t-3"
        active-class="text-gray"
      >
        <v-list-item
          v-for="(menuItem, menuIndex) in menuItems"
          :id="`sidebar-button-${menuItem.title}`"
          :key="`sidebar-button-${menuItem.title}-${menuIndex}`"
          @click="handleNavigationItemClick(menuItem)"
        >
          <v-badge
            v-if="menuItem.name === 'quotes' && quotes.unreadQuoteCount"
            color="error"
            :content="quotes.unreadQuoteCount"
            offset-x="-5"
            offset-y="19"
          >
            <span>{{ menuItem.title }}</span>
          </v-badge>

          <template v-else>{{ menuItem.title }}</template>
        </v-list-item>
        <v-list-item id="sidebar-button-logout" @click="auth.logout">
          {{ $t('accountItems.LOGOUT') }}
        </v-list-item>
        <v-list-item class="font-14">
          {{ userDisplayName }}
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import auth from '@/store/modules/auth'
import support from '@/store/modules/support'
import CharterUPLogo from '@/icons/CharterUPLogo.vue'
import { NavigationItem } from '@/models/NavigationItem'
import sidebar from '../store/modules/sidebar'
import quotes from '../store/modules/quotes'

@Component({
  components: {
    CharterUPLogo,
  },
})
export default class TheSidebar extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly menuItems: NavigationItem[]

  @Watch('$vuetify.breakpoint.smAndDown')
  onBreakpointChange(value: boolean): void {
    if (value) {
      return
    }
    sidebar.setIsOpen(false)
  }

  listModel = 1
  auth = auth
  support = support
  sidebar = sidebar
  quotes = quotes

  get userEmail(): string {
    return auth.user?.email
  }

  get userDisplayName(): string {
    const name = `${auth.user?.firstName || ''} ${auth.user?.lastName || ''}`
    return name.length > 1 ? name : auth.user?.email
  }

  handleNavigationItemClick(item: NavigationItem): void {
    if (item.link) {
      this.$router.push({ name: item.link })
    } else if (item.action) {
      item.action()
    }
  }
}
</script>

<style lang="scss" scoped>
.v-list-item {
  padding: 0px;
}
</style>
