<template>
  <div>
    <v-expand-transition>
      <v-row v-if="errorMessage" no-gutters>
        <p class="text-error font-bold margin-y-0 margin-x-auto">
          {{ errorMessage }}
        </p>
      </v-row>
    </v-expand-transition>
    <div>
      <PaymentProfileSelectorV2
        v-if="!showCardForm"
        v-model="selectedPaymentProfile"
        @update:has-payment-profiles="hasPaymentProfiles = $event"
        @update:loading="loadingPaymentProfiles = $event"
      />
      <v-btn
        v-if="!loadingPaymentProfiles && !showCardForm"
        small
        text
        color="primary"
        class="padding-x-4 margin-t-4 font-medium"
        @click="showCardForm = true"
      >
        + {{ $t('paymentProfileSelector.ADD_NEW_CARD') }}
      </v-btn>
    </div>
    <CreditCardFormV2
      v-show="showCardForm"
      ref="credit-card-form"
      v-model="newCard"
      :show-cancel="hasPaymentProfiles"
      @cancel="showCardForm = false"
    />
    <v-row justify="end" :no-gutters="$vuetify.breakpoint.xs">
      <v-col cols="auto">
        <v-btn small text color="error" @click="close">
          {{ $t('common.CANCEL') }}
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn class="primary" small :loading="loading" @click="submit">
          {{
            $t('reservationDetail.paymentOverview.payRemainingBalance.PAY', {
              amount: currencyFilter(reservation.balance),
            })
          }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Billing, ComputedPaymentProfile, PaymentPayload, ReservationDetail, SimplifiedPaymentGateway } from '@/models/dto'
import { FormattedPaymentMethod } from '../models/FormattedPaymentMethod'
import { PaymentMethodTypeKey, PaymentGatewayTypeKey, SplitFeatureFlag, CompanyId } from '@/utils/enum'
import { currencyFilter } from '@/utils/string'
import payment from '@/services/payment'
import confetti from '@/store/modules/confetti'
import PaymentProfileSelectorV2 from '@/components/PaymentProfileSelectorV2.vue'
import CreditCardFormV2 from '@/components/CreditCardFormV2.vue'

@Component({
  components: {
    PaymentProfileSelectorV2,
    CreditCardFormV2
  },
})
export default class PayRemainingBalance extends Vue {
  @Prop({ type: Object, required: true }) readonly reservation: ReservationDetail

  selectedPaymentProfile: ComputedPaymentProfile = null
  newCard: FormattedPaymentMethod = null
  loading = false
  loadingPaymentProfiles = false
  showCardForm = false
  hasPaymentProfiles = false
  errorMessage = null
  currencyFilter = currencyFilter

  async collectPaymentFromExistingMethod(): Promise<boolean> {
    let isSuccessful = false
    this.loading = true

    // TODO: collect payment should not need to be passed a payment gateway, it should use the one saved on the profile
    const paymentGatewayResponse = await payment.getDefaultGateway(CompanyId.CharterUP)
    const paymentGatewayData = paymentGatewayResponse.data.paymentGateways[0]

    const paymentGateway = {
      id: paymentGatewayData.companyPaymentGatewayId,
      key: paymentGatewayData.paymentGatewayTypeKey,
    }

    const payload: PaymentPayload = {
      payment_method: PaymentMethodTypeKey.CreditCard,
      payment_gateway: paymentGateway,
      paymentProfileId: this.selectedPaymentProfile?.customerPaymentProfileId,
      reservationIds: [this.reservation.reservationId],
      reservationToVersionMap: { [this.reservation.reservationId]: this.reservation.reservationVersion },
      sendEmail: true,
      amount: this.reservation.balance || 0,
    }

    try {
      await payment.collect(payload)
      isSuccessful = true
    } catch (err) {
      const error = err as any
      this.errorMessage = error.response?.data?.message
    }
    this.loading = false
    return isSuccessful
  }

  async collectPaymentFromNewMethod(): Promise<boolean> {
    let isSuccessful = false
    this.loading = true

    let tokenizedCardInfo
    try {
      // Attempt to get the finix payment gateway and tokenize the card info with Finix.
      const finixPaymentGateway = await this.getFinixPaymentGateway()
      if (!finixPaymentGateway || !finixPaymentGateway?.id) {
        this.loading = false
        return isSuccessful
      }

      const finixForm = this.$refs['credit-card-form'] as any;
      const token = await finixForm.getTokenizedFinixInfo()
      tokenizedCardInfo = {
        tokens: [token],
        paymentGateway: finixPaymentGateway
      }
    } catch (error) {
      console.error(error)
    }

    const billing: Billing = {
      ...this.newCard,
      cardholderName: this.newCard.name,
    }

    const formattedPaymentInfo: PaymentPayload = {
      billing,
      payment_gateway: null,
      nonces: null,
      payment_method: null,
      reservationIds: null,
      reservationToVersionMap: {},
      sendEmail: null,
      amount: null,
      notes: null,
      paymentProfileId: null,
    }

    if (tokenizedCardInfo?.paymentGateway && tokenizedCardInfo?.tokens) {
      formattedPaymentInfo.payment_gateway = tokenizedCardInfo?.paymentGateway
      formattedPaymentInfo.nonces = tokenizedCardInfo?.tokens
      formattedPaymentInfo.payment_method = PaymentMethodTypeKey.CreditCard
      formattedPaymentInfo.reservationIds = [this.reservation.reservationId]
      formattedPaymentInfo.reservationToVersionMap = { [this.reservation.reservationId]: this.reservation.reservationVersion }
      formattedPaymentInfo.sendEmail = true
      formattedPaymentInfo.amount = this.reservation.balance

      try {
        await payment.collect(formattedPaymentInfo)
        isSuccessful = true
      } catch (err) {
        const error = err as any
        this.errorMessage = error.response?.data?.message
      }
    } else {
      this.errorMessage = `Error collecting payment. Please check your card information.`
    }
    this.loading = false
    return isSuccessful
  }

  async getFinixPaymentGateway(): Promise<SimplifiedPaymentGateway> {
    const defaultPaymentGatewayResponse = await payment.getPaymentGateways()
    const finixGateway = defaultPaymentGatewayResponse
      .data
      .paymentGateways
      .filter((paymentGateway) => paymentGateway.companyId === CompanyId.CharterUP)
      .filter((paymentGateway) => paymentGateway.checkoutPageId === null)
      .filter((paymentGateway) => paymentGateway.paymentGatewayTypeKey === PaymentGatewayTypeKey.Finix)
      ?.[0]
    return {
      id: finixGateway?.companyPaymentGatewayId,
      key: finixGateway?.paymentGatewayTypeKey,
    }
  }

  async submit(): Promise<void> {
    this.errorMessage = null

    const paymentForm = this.$refs['credit-card-form'] as any

    const cardForm = paymentForm.$refs['card-form'] as any
    const cardFormValid = cardForm.validate()

    const addressForm = paymentForm.$refs['address-form'] as any
    const addressFormValid = addressForm.validate()

    let success = false
    if (this.selectedPaymentProfile && !this.showCardForm) {
      success = await this.collectPaymentFromExistingMethod()
    } else if (this.showCardForm && addressFormValid) {
      success = await this.collectPaymentFromNewMethod()
    } else if (!this.selectedPaymentProfile && !this.showCardForm) {
      this.errorMessage = 'Please select a payment method.'
    }
    if (success) {
      this.$emit('paid')
      confetti.setShow()
    }
  }

  close(): void {
    this.$emit('close')
    this.showCardForm = false
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors.scss';

::v-deep .v-btn {
  &--loading {
    .v-btn__loader {
      .v-progress-circular {
        color: white;
      }
    }
  }
}
</style>
