<template>
  <CUSelect
    :value="locale.locale"
    :items="locale.options"
    item-text="label"
    item-value="value"
    hide-details="auto"
    dense
    :class="{ 'max-w-100 no-input': hideLabel, 'max-w-180': !hideLabel }"
    @input="locale.setLocale($event)"
  >
    <template #selection="{ item, index }">
      <i :class="[{ 'margin-r-4': !hideLabel }, 'em', item.flag]"></i>
      <template v-if="!hideLabel">{{ item.label }}</template>
    </template>
    <template #item="slotProps">
      <i :class="['margin-r-4', 'em', slotProps.item.flag]"></i>
      {{ slotProps.item.label }}
    </template>
  </CUSelect>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import locale from '../store/modules/locale'

@Component
export default class LanguageSelector extends Vue {
  @Prop({ type: Boolean, default: false, required: false })
  readonly hideLabel: boolean

  locale = locale
}
</script>

<style lang="scss" scoped>
.no-input {
  ::v-deep .v-input {
    .v-input__control {
      .v-input__slot {
        .v-select__slot {
          .v-select__selections {
            input {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
