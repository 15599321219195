<template>
  <InfoModal v-model="isOpen" :modal-detail="bookingProtectionModalDetail" max-width="860px">
    <template #activator="{ on, attrs }">
      <a
        class="text-decoration-underline font-weight-600 font-14 margin-t-1"
        :style="{ 'text-underline-offset': '3px' }"
        @click="handleOpen"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('common.LEARN_MORE') }}
      </a>
    </template>
  </InfoModal>
</template>

<script lang="ts">
import { Vue, Component, Inject, Prop } from 'vue-property-decorator'
import { Page } from '@/utils/enum'
import InfoModal from './InfoModal.vue'
import { bookingProtectionModalDetail } from '@/utils/infoModals'

@Component({
  components: {
    InfoModal,
  },
})
export default class BookingProtectionDialog extends Vue {
  @Inject({ from: 'isInProvider', default: false })
  readonly isInProvider: boolean
  @Inject({ from: 'isInCheckout', default: false })
  readonly isInCheckout: boolean
  @Inject({ from: 'isInGuestCheckout', default: false })
  readonly isInGuestCheckout: boolean
  @Prop({ type: Boolean }) readonly isSelfServe: boolean

  bookingProtectionModalDetail = bookingProtectionModalDetail
  isOpen = false

  get page(): string {
    if (this.isInProvider) {
      return Page.Provider
    }
    if (this.isInCheckout || this.isInGuestCheckout) {
      return Page.Checkout
    }
    return Page.Other
  }

  handleOpen(): void {
    this.trackOpenBookingProtection()
  }

  trackOpenBookingProtection(): void {
    this.$ga4.trackEvent('open_booking_protection', {
      page: this.page,
      isSelfServe: this.isSelfServe,
    })
  }
}
</script>
