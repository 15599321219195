<template>
  <CUModal
    id="prompt-call-dialog"
    :value="value"
    width="500px"
    @input="$emit('input', $event)"
  >
    <template #title>{{ `${' '}` }}</template>
    <template #text>
      <div class="d-flex flex-column text-center margin-x-auto max-w-400">
        <v-icon class="margin-x-auto margin-b-3" size="70">
          $color-phone
        </v-icon>
        <h2>
          You're almost there!
        </h2>
        <p>
          {{ dialogBody }}
        </p>
        <v-btn
          color="primary"
          class="margin-t-6"
          @click="quotePromptCallNumber"
        >
          <CUIcon view-box="0 0 24 24" class="margin-r-2">
            phone
          </CUIcon>
          {{ formattedSupportNumber }}
        </v-btn>
      </div>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'
import InfoDialogLayout from '@/layouts/InfoDialogLayout.vue'
import auth from '@/store/modules/auth'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'
import { SplitFeatureFlag } from '@/utils/enum'

@Component({ components: { InfoDialogLayout } })
export default class PromptCallDialog extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean

  useNewLastMinuteTrip = false

  quotePromptCallNumber(): void {
    window.open(`tel:${this.formattedSupportNumber}`)
  }
  get supportNumber(): string {
    const isElite = !!auth.customer?.isElite
    return getSupportNumber(isElite)
  }
  get formattedSupportNumber(): string {
    return `1-${phoneFormatFilterHyphens(this.supportNumber)}`
  }
  get dialogBody(): string {
    return this.useNewLastMinuteTrip
      ? 'Your requested pickup time is less than 2 hours away or outside our working hours. Please call us now to receive a quote.'
      : 'Your requested pickup time is less than 24 hours away. Please call us now to receive a quote.'
  }

  async mounted() {
    const res = await this.$split.getTreatmentWithConfig(
      SplitFeatureFlag.SalesbotLastMinuteQuote
    )
    this.useNewLastMinuteTrip = res.treatment === 'on'
  }
}
</script>
