<template>
  <div
    class="w-48 h-48 z-3 position-absolute right-12 background-white top-280 border-1 border-solid border-border-gray d-flex align-center justify-center"
  >
    <v-tooltip
      :nudge-left="copied && $vuetify.breakpoint.mdAndUp ? 27 : 10"
      left
    >
      <template #activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-center"
          v-bind="attrs"
          v-on="on"
          @mouseover="copied = false"
          @click="copyURL"
        >
          <CUIcon view-box="0 0 24 24" class="w-22 h-22" color="primary">
            share_arrow
          </CUIcon>
        </div>
      </template>
      <span class="text-white">{{ tooltipCopy }}</span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import { LocaleMessage } from 'vue-i18n'
import { Vue, Component, InjectReactive } from 'vue-property-decorator'

@Component({ components: {} })
export default class LiveTrackingMapShareButton extends Vue {
  copied = false

  @InjectReactive('trackingLink') trackingLink

  get tooltipCopy(): LocaleMessage {
    if (this.$vuetify.breakpoint.smAndDown) {
      return this.$t('liveTracking.actions.SHARE')
    }
    return this.copied
      ? this.$t('liveTracking.actions.LINK_COPIED')
      : this.$t('liveTracking.actions.COPY_LINK')
  }

  async copyURL(): Promise<void> {
    try {
      if (this.$vuetify.breakpoint.smAndDown && navigator.share) {
        await navigator.share({
          title: this.$t('liveTracking.actions.SHARE_TITLE').toString(),
          url: this.trackingLink,
          text: this.$t('liveTracking.actions.SHARE_TEXT').toString(),
        })
      } else {
        await navigator.clipboard.writeText(this.trackingLink)
      }
      this.copied = true
    } catch (err) {
      console.error(err)
    }
  }
}
</script>
