<template>
  <v-row justify="center" class="max-width-600">
    <v-col cols="12" sm="10">
      <v-img
        :src="require('@/assets/images/wrongQuote.png')"
        position="center"
      />
    </v-col>
    <v-col cols="12">
      <h2 class="text-center">
        Your account does not have access to this quote
      </h2>
      <p class="text-center">
        You are logged in with
        <span class="font-bold">{{ wrongEmail }}</span>. This quote belongs to <span class="font-bold">{{ correctEmail }}</span>.
      </p>
      <p class="text-center">
        To view the quote, please <a @click="auth.logout">logout</a>
        and access the link from the email again and login with the correct
        account credentials.
      </p>
      <p class="text-center">
        If you believe this is an error, please contact support at <a :href="`mailto:${DEFAULT_CHARTERUP_SUPPORT_EMAIL}`">{{ DEFAULT_CHARTERUP_SUPPORT_EMAIL }}</a>.
      </p>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import auth from '@/store/modules/auth'

const DEFAULT_CHARTERUP_SUPPORT_EMAIL = 'support@charterup.com'

@Component({})
export default class QuoteNotOwned extends Vue {
  @Prop({ type: String, required: true }) wrongEmail!: string
  @Prop({ type: String, required: true }) correctEmail!: string

  DEFAULT_CHARTERUP_SUPPORT_EMAIL = DEFAULT_CHARTERUP_SUPPORT_EMAIL
  auth = auth
}
</script>
