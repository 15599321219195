<template>
  <div>
    <TwoColumnLayout :full="!$vuetify.breakpoint.md" plain-container>
      <template #left-column>
        <div class="d-flex flex-column">
          <h2>
            {{ $t('reservationDetail.cancellationError.COULD_NOT_CANCEL') }}
          </h2>
          <p>
            {{ $t('reservationDetail.cancellationError.PLEASE_CALL_IN') }}
            <a :href="`tel:${supportNumber}`">{{ formattedSupportNumber }}</a>
            {{
              $t('reservationDetail.cancellationError.UNDERSTAND_NEXT_STEPS')
            }}
          </p>
          <div class="d-flex margin-t-4">
            <v-btn
              color="primary"
              small
              class="margin-r-2"
              @click="callCustomerService"
            >
              {{
                $t('reservationDetail.cancellationError.CALL_CUSTOMER_SERVICE')
              }}
            </v-btn>
            <v-btn color="primary" text small @click="goToReservationDetail">
              {{ $t('reservationDetail.cancellationError.SEE_TRIP_DETAILS') }}
            </v-btn>
          </div>
        </div>
      </template>
      <template #right-column>
        <ReservationCancellationSummary
          :class="{
            'margin-t-3': $vuetify.breakpoint.mdAndDown,
          }"
          :reservation="reservation"
          :penalty-info="penaltyInfo"
          complete
        />
      </template>
    </TwoColumnLayout>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import TwoColumnLayout from '@/layouts/TwoColumnLayout.vue'
import { ReservationDetail, ClientCancellationPenaltyInfo } from '@/models/dto'
import ReservationCancellationSummary from './ReservationCancellationSummary.vue'
import auth from '@/store/modules/auth'
import { getSupportNumber, phoneFormatFilter } from '@/utils/phone'

@Component({ components: { TwoColumnLayout, ReservationCancellationSummary } })
export default class ReservationCancelError extends Vue {
  @Prop({ type: Number, required: true }) readonly reservation: ReservationDetail
  @Prop({ type: Object, required: true })
  readonly penaltyInfo!: ClientCancellationPenaltyInfo

  get supportNumber(): string {
    return getSupportNumber(auth.customer.isElite)
  }

  get formattedSupportNumber(): string {
    return phoneFormatFilter(this.supportNumber)
  }

  callCustomerService(): void {
    window.open(`tel:${this.supportNumber}`, '_self')
  }

  goToReservationDetail(): void {
    this.$emit('to-reservation-detail')
  }
}
</script>
