<template>
  <div class="d-flex flex-column">
    <h3 class="margin-b-3 subtitle-1">
      {{ $t('reservationDetail.cancellationInformation.TITLE') }}
    </h3>
    <div>
      <span>
        {{ cancellationBodyCopy }}
      </span>
      <InfoModal v-model="isRefundPolicyModalOpen" :modal-detail="refundPolicyModalDetail" max-width="860px">
        <template #activator="{ on, attrs }">
          <span
            class="text-primary text-decoration-underline font-14 cursor-pointer"
            :class="{ 'margin-l-2': $vuetify.breakpoint.smAndUp }"
            v-bind="attrs"
            @click="cancellationLinkAction"
            v-on="on"
          >
            {{ cancellationLinkCopy }}
          </span>
        </template>
      </InfoModal>
    </div>
    <div v-if="allowCancellation">
      <p
        v-if="cancellationAvailable"
        id="reservation-detail-cancel-reservation-button"
        class="font-bold text-primary cursor-pointer margin-t-2 align-self-start"
        @click="goToCancellationView"
      >
        {{ $t('reservationDetail.cancellationInformation.CANCEL_RESERVATION') }}
      </p>
      <InfoModal v-else v-model="isCancellationNotAvailableModalOpen" :modal-detail="cancellationNotAvailableModalDetail" max-width="860px">
        <template #activator="{ on, attrs }">
          <p
            id="reservation-detail-cancel-reservation-button"
            class="font-bold text-primary cursor-pointer margin-t-2 align-self-start"
            v-bind="attrs"
            @click="trackClickedCancelReservation"
            v-on="on"
          >
            {{
              $t('reservationDetail.cancellationInformation.CANCEL_RESERVATION')
            }}
          </p>
        </template>
      </InfoModal>
    </div>
  </div>
</template>

<script lang="ts">
import { RefundPolicyEntry, ReservationDetail } from '@/models/dto'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ReservationStatusKey } from '../utils/enum'
import { TranslateResult } from 'vue-i18n'
import InfoModal from '@/components/InfoModal.vue'
import {
  refundPolicyModalDetail,
  cancellationNotAvailableModalDetail,
} from '@/utils/infoModals'
import { InfoModalDetail } from '@/models/InfoModalDetail'
import { formattedCancellationPolicyDate } from '@/utils/cancellation'

@Component({ components: { InfoModal } })
export default class ReservationDetailCancellationInfo extends Vue {
  @Prop({ type: Object, required: true })
  readonly reservation: ReservationDetail

  cancellationNotAvailableModalDetail = cancellationNotAvailableModalDetail
  isRefundPolicyModalOpen = false
  isCancellationNotAvailableModalOpen = false

  get refundPolicyModalDetail(): InfoModalDetail {
    return refundPolicyModalDetail(this.refundPolicy)
  }

  get isCancelled(): boolean {
    return (
      this.reservation?.reservationStatus === ReservationStatusKey.Cancelled
    )
  }

  get cancellationLinkCopy(): TranslateResult {
    if (this.isCancelled) {
      return this.$t('reservationDetail.cancellationInformation.SEE_TERMS')
    }

    return this.$t('reservationDetail.cancellationInformation.LEARN_MORE')
  }

  cancellationLinkAction(): void {
    if (!this.isCancelled) {
      this.trackClickedCancellationLearnMore()
    }
  }

  trackClickedCancellationLearnMore(): void {
    this.$ga4.trackEvent('clicked_cancellation_policy_learn_more', {
      userId: this.reservation?.customer?.customerId,
      reservationId: this.reservation?.reservationId,
      managedId: this.reservation?.managedId,
    })
  }

  get cancellationBodyCopy(): TranslateResult {
    if (this.isCancelled) {
      return this.$t(
        'reservationDetail.cancellationInformation.ALREADY_CANCELLED',
        {
          date: this.zonedCancellationDate,
        }
      )
    }

    if (this.refundPolicyPercent !== 0) {
      return `${this.refundPolicyPercent * 100}${this.$t(
        'reservationDetail.cancellationInformation.REFUNDABLE_BEFORE',
        {
          date: formattedCancellationPolicyDate(
            this.refundPolicyPercentValidUntilTime
          ),
        }
      )}`
    }
    return this.$t('reservationDetail.cancellationInformation.NO_REFUND')
  }

  get refundPolicyPercent(): number {
    return this.reservation?.refundPolicyPercent
  }

  get refundPolicy(): RefundPolicyEntry[] {
    return this.reservation?.refundPolicy
  }

  get refundPolicyPercentValidUntilTime(): string {
    return this.reservation?.refundPolicyPercentValidUntilTime
  }

  get allowCancellation(): boolean {
    const isFinished = this.reservation?.reservationStatus === ReservationStatusKey.Finished
    const isStarted = this.reservation?.reservationStatus === ReservationStatusKey.Started
    return (
      !(this.isCancelled || isStarted || isFinished)
    )
  }

  get cancellationAvailable(): boolean {
    return this.hoursUntilFirstTrip > 3
  }

  get zonedCancellationDate(): string {
    if (!this.reservation?.cancelledOn) {
      return null
    }
    return this.$dayjs(this.reservation?.cancelledOn).format('MMMM D, h:mm A')
  }

  get hoursUntilFirstTrip(): number {
    if (!this.startDate) {
      return null
    }
    const now = this.$dayjs()
    return this.$dayjs(this.startDate).diff(now, 'hour')
  }

  get startDate(): string {
    if (!this.reservation) {
      return null
    }
    return this.reservation?.pickupDate
  }

  goToCancellationView(): void {
    this.trackClickedCancelReservation()
    this.$router.push({
      name: 'reservation-cancel',
      params: { id: this.reservation.reservationId.toString() },
    })
  }

  trackClickedCancelReservation(): void {
    this.$ga4.trackEvent('clicked_cancel_reservation', {
      userId: this.reservation?.customer?.customerId,
      reservationId: this.reservation?.reservationId,
      managedId: this.reservation?.managedId,
    })
  }
}
</script>
