<template>
  <div class="d-flex w-full justify-center">
    <v-progress-circular
      indeterminate
      color="primary"
      size="48"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import selfServe from '@/store/modules/selfServe'
import { buildRedirectLoginOptions } from '@/utils/auth'
import { RedirectLoginOptions } from '@auth0/auth0-spa-js'

@Component
export default class SelfServeCustomerAuthentication extends Vue {
  mounted(): void {
    const currentQuery = this.$route.query
    const query = { ...currentQuery, uuid: selfServe.uuid }
    this.$router.replace({query})
    this.goToAuth0UsernameSignUp()
  }

  getRedirectLoginOptions(email?: string): RedirectLoginOptions {
    const appState = { routeName: 'self-serve', query: { uuid: selfServe.uuid } }
    const options: RedirectLoginOptions = buildRedirectLoginOptions(appState, email)
    return options
  }

  goToAuth0UsernameSignUp(email?: string): void {
    selfServe.storeStateWithUUID()
    const options: RedirectLoginOptions = this.getRedirectLoginOptions(email)
    this.$auth0.signupWithRedirect(options)
  }
}
</script>
