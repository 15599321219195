<template>
  <div class="d-flex flex-column w-full">
    <TripTabs v-if="showTabs" v-model="currentTripIndex" :trips="trips" />
    <TripMap v-if="!hideMap" :stops="stops" />
    <template v-if="isMobile">
      <QuoteCardMobileInformation
        class="margin-y-3"
        :quote="quote"
        :trip="currentTrip"
      />
      <v-divider />
    </template>
    <TripMetadata
      v-if="currentTrip"
      :vehicles="currentTrip.vehicles"
      :passengerCount="currentTrip.passengerCount"
      :tripTypeLabel="currentTrip.tripType.label"
      class="margin-y-3"
    />
    <template v-if="areNewTripAmenitiesEnabled">
      <TripAmenityCard
        v-for="(vehicleTypeKey, vehicleTypeKeyIndex) in amenityEligibleVehicleTypeKeys"
        :key="`trip-amenity-card-${vehicleTypeKey}-${vehicleTypeKeyIndex}`"
        :amenities="amenities"
        :vehicle-type-key="vehicleTypeKey"
      />
    </template>
    <v-divider />
    <template v-if="tripNotes && tripNotes.length">
      <TripNotes v-if="tripNotes" :notes="tripNotes" class="margin-y-5" />
      <v-divider v-if="!isMobile" />
    </template>
    <TripItinerary v-if="!hideItinerary" :stops="stops" class="margin-t-4" />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Note, Stop } from '../models/dto'
import TripAmenityCard from '@/components/TripAmenityCard.vue'
import TripTabs from '@/components/TripTabs.vue'
import TripMap from '@/components/TripMap.vue'
import TripMetadata from '@/components/TripMetadata.vue'
import TripNotes from '@/components/TripNotes.vue'
import TripItinerary from '@/components/TripItinerary.vue'
import QuoteCardMobileInformation from '@/components/QuoteCardMobileInformation.vue'
import { NoteTypeId, VehicleTypeKey } from '../utils/enum'
import { QuoteDetailV2, QuoteDetailV2Trip } from '../models/dto/QuoteDetailV2'
import { AmenityItem } from '@/models/AmenityItem'
import { AMENITY_CARD_SUPPORTED_VEHICLE_TYPE_KEYS } from '@/utils/amenity'
import { SplitFeatureFlag } from '@/utils/enum'

@Component({
  components: {
    TripAmenityCard,
    TripTabs,
    TripMap,
    TripMetadata,
    TripNotes,
    TripItinerary,
    QuoteCardMobileInformation,
  },
})
export default class QuoteCard extends Vue {
  @Prop({ type: Object, required: true }) quote!: QuoteDetailV2
  @Prop({ type: Boolean, required: false, default: false }) hideMap!: boolean
  @Prop({ type: Boolean, required: false, default: false })
  hideItinerary!: boolean

  currentTripIndex = 0
  areNewTripAmenitiesEnabled = false

  get trips(): QuoteDetailV2Trip[] {
    return this.quote?.trips
  }

  get currentTrip(): QuoteDetailV2Trip {
    return this.trips?.[this.currentTripIndex] || null
  }

  get amenityEligibleVehicleTypeKeys(): VehicleTypeKey[] {
    const keys = this.currentTrip?.vehicles
      .map(({vehicleType}) => vehicleType.key)
      .filter((key) => AMENITY_CARD_SUPPORTED_VEHICLE_TYPE_KEYS.includes(key))

    return [...new Set(keys)]
  }

  get stops(): Stop[] {
    return this.currentTrip?.stops || []
  }

  get amenities(): AmenityItem[] {
    return this.currentTrip?.amenities || []
  }

  get showTabs(): boolean {
    return this.trips?.length > 1
  }

  get tripNotes(): Note[] {
    return this.currentTrip?.tripNotes?.filter(
      (note) => note.noteType === NoteTypeId.Customer
    )
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  async mounted() {
    this.areNewTripAmenitiesEnabled = await this.$split.isFeatureEnabled(
      SplitFeatureFlag.NewTripAmenities
    )
  }
}
</script>
