<template>
  <v-footer v-show="$vuetify.breakpoint.mdAndUp" dark>
    <div class="footer-links margin-y-0 margin-x-auto font-14 ">
      <v-row justify="space-between">
        <a
          v-for="link in sectionLinks"
          :id="link.id"
          :key="link.id"
          :href="link.link"
          target="_blank"
          class="text-decoration-none text-white"
        >
          {{ link.label }}
        </a>
      </v-row>

      <v-row>
        <v-divider />
      </v-row>

      <v-row class="footer-links-bottom text-white">
        {{ copyrightText }}
        <v-spacer />
        <a
          v-for="link in socialLinks"
          :id="link.id"
          :key="link.id"
          :href="link.link"
          target="_blank"
        >
          <v-icon color="white">{{ link.icon }}</v-icon>
        </a>
      </v-row>
    </div>
  </v-footer>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import app from '@/store/modules/app'
import { TranslateResult } from 'vue-i18n'

@Component({})
export default class TheFooter extends Vue {
  sectionLinks: { id: string; link: string; label: TranslateResult }[] = [
    {
      id: 'footer-link-join-our-team',
      link: 'https://www.charterup.com/careers',
      label: this.$t('footer.JOIN_OUR_TEAM'),
    },
    {
      id: 'footer-link-contact-us',
      link: 'mailto:info@charterup.com?subject=CharterUP Web Contact',
      label: this.$t('footer.CONTACT_US'),
    },
    {
      id: 'footer-link-terms-and-conditions',
      link: 'https://www.charterup.com/terms-and-conditions',
      label: this.$t('common.TERMS_AND_CONDITIONS'),
    },
    {
      id: 'footer-link-privacy-policy',
      link: 'https://www.charterup.com/privacy-policy',
      label: this.$t('common.PRIVACY_POLICY'),
    },
  ]
  socialLinks: { id: string; link: string; icon: string }[] = [
    {
      id: 'footer-link-facebook',
      link: 'https://www.facebook.com/charterup/',
      icon: '$facebook',
    },
    {
      id: 'footer-link-instagram',
      link: 'https://www.instagram.com/charterup/',
      icon: '$instagram',
    },
    {
      id: 'footer-link-twitter',
      link: 'https://twitter.com/charter_up',
      icon: '$twitter',
    },
    {
      id: 'footer-link-linkedin',
      link: 'https://www.linkedin.com/company/charterup/',
      icon: '$linkedin',
    },
  ]

  get appVersion(): string {
    return app.version
  }
  get currentYear(): number {
    return DateTime.local().year
  }
  get copyrightText(): string {
    return `${this.$t('common.COPYRIGHT_ICON')} ${this.currentYear} ${this.$t(
      'common.COPYRIGHT'
    )}`
  }
}
</script>

<style lang="scss" scoped>
.footer-links {
  > * {
    margin: 20px 0;
  }

  a {
    + a {
      margin-left: 60px;
    }
  }

  .footer-links-bottom {
    > a {
      font-size: 1rem;
      color: white;
      + a {
        margin-left: 10px;
      }
      > i,
      .v-icon {
        font-size: 1rem;
      }
    }
  }
}
</style>
