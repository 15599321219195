<template>
  <div :class="`${amenityCardClass}`" @click="handleAmenityClick">
    <div
      class="padding-x-2 padding-b-3 d-flex flex-column w-full h-full"
      :class="{
        'border-solid border-transparent border-1': !isSelected,
      }"
    >
      <div
        class="d-flex justify-space-between padding-t-2 padding-l-1 padding-r-2"
      >
        <v-checkbox :input-value="isSelected" class="margin-t-0" hide-details />
        <v-icon class="margin-t-0" :color="amenityIconColor">
          {{ getAmenityIcon(amenity.id) }}
        </v-icon>
      </div>
      <div class="margin-l-2 d-flex flex-column grow">
        <p class="font-medium margin-t-1">{{ title }}</p>
        <p class="text-gray-light margin-t-0 font-14">
          {{ amenity.description }}
        </p>
        <div class="d-flex grow" />
        <p class="font-medium font-16">
          {{ priceCopy }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { AmenityTypeId } from '@/utils/enum'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { currencyFilter } from '@/utils/string'
import selfServe from '@/store/modules/selfServe'
import { AmenityType } from '../models/dto'
import { getAmenityIcon } from '@/utils/amenity'

@Component({})
export default class AmenityCard extends Vue {
  @Prop({ type: Object, default: null }) readonly amenity: any
  @Prop({ type: Boolean, default: false }) readonly isVehicleCombination: boolean

  getAmenityIcon = getAmenityIcon

  get isSelected(): boolean {
    const amenityTypeId = this.amenity.id
    const currentTrip = selfServe.quote.trips[selfServe.currentTripIndex]
    const amenity = currentTrip.tripAmenities.find(
      (a: AmenityType) => a.id === amenityTypeId
    )
    return !!amenity
  }

  get title(): string {
    let title = this.amenity.label
    if (this.isVehicleCombination && this.amenity.id === AmenityTypeId.Wifi) {
      title += '*'
    }
    return title
  }

  get priceCopy(): string {
    if (this.amenity.price === 0) {
      return 'Free'
    }
    return `${currencyFilter(this.amenity.price)} per vehicle`
  }

  get amenityIconColor(): string {
    return this.isSelected ? 'primary' : 'black'
  }

  get amenityCardClass(): string {
    let cardClass =
      'w-full h-full shadow-actionable-card border-radius-2x border-solid cursor-pointer margin-t-4 '
    if (this.isSelected) {
      cardClass += 'border-2 border-solid border-primary '
    } else {
      cardClass += 'border-1 border-solid border-border-gray '
    }
    return cardClass
  }

  handleAmenityClick(): void {
    selfServe.setAmenitySelection({
      value: !this.isSelected,
      amenityTypeId: this.amenity.id,
    })
  }
}
</script>
