<template>
  <CUModal
    :value="flight.isOpen"
    width="550"
    closeable
    @input="close"
    @close="close"
    @keydown.stop="handleKeydown"
  >
    <template #title>
      {{ title }}
    </template>
    <template #text>
      <div class="w-full">
        <div class="w-full">
          <p class="font-bold margin-t-0">{{ addressTitle }}</p>
          <p class="margin-t-0">{{ addressPrettyFromStop(flight.stop) }}</p>
          <div
            v-if="dropoffDatetime"
            id="flight-info-modal-stop-dropoff-time"
            class="text-gray-medium-light"
          >
            {{ $t('reservationDetail.tripInfo.ESTIMATED_ARRIVAL') }}
            {{ formatDisplayTime(dropoffDatetime, timeZone) }}
          </div>
          <div
            v-if="pickupDatetime"
            id="flight-info-modal-stop-pickup-time"
            class="text-gray-medium-light"
          >
            {{ $t('reservationDetail.tripInfo.PICKUP_TIME') }}
            {{ formatDisplayTime(pickupDatetime, timeZone) }}
          </div>
        </div>
        <div class="w-full margin-t-4">
          <label for="flight-number-input">
            {{ $t('flightInfo.FLIGHT_NUMBER') }}
          </label>
          <v-text-field
            id="flight-number-input"
            :value="flight.activeFlightNumber"
            outlined
            class="w-full h-50"
            maxlength="10"
            :placeholder="$t('flightInfo.FLIGHT_NUMBER_PLACEHOLDER')"
            @input="flight.setActiveFlightNumber($event)"
          />
        </div>
        <div class="w-full margin-t-n2">
          <label for="airline-input">{{ $t('flightInfo.AIRLINE') }}</label>
          <v-text-field
            id="airline-input"
            :value="flight.activeAirline"
            outlined
            class="w-full h-50"
            maxlength="25"
            :placeholder="$t('flightInfo.AIRLINE_PLACEHOLDER')"
            @input="flight.setActiveAirline($event)"
          />
        </div>
        <v-btn
          id="flight-information-button-save"
          color="primary"
          class="w-full margin-t-2"
          :loading="submitting"
          @click="submitUpdate"
        >
          {{ $t('common.SAVE') }}
        </v-btn>
      </div>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import flight from '@/store/modules/flight'
import stop from '@/services/stop'
import { KeyCode } from '@/utils/enum'
import { FlightInformation, UpdateFlightInfoPayload } from '@/models/dto'
import { addressPrettyFromStop } from '@/utils/string'
import { DateTime } from 'luxon'

@Component({})
export default class FlightInformationDialog extends Vue {
  flight = flight
  addressPrettyFromStop = addressPrettyFromStop
  submitting = false

  get title() {
    if (flight.flightInformation) {
      return this.$t('flightInfo.UPDATE_TITLE')
    }
    return this.$t('flightInfo.ADD_TITLE')
  }

  get addressTitle(): string {
    return flight.stop?.address?.title
  }

  get pickupDatetime(): string {
    return flight.stop?.pickupDatetime
  }

  get dropoffDatetime(): string {
    return flight.stop?.dropoffDatetime
  }

  get timeZone(): string {
    return flight.stop?.address?.timeZone
  }

  close(): void {
    flight.close()
  }

  handleKeydown(event): void {
    if (event.keyCode === KeyCode.Escape) {
      this.close()
    }
  }

  formatDisplayTime(time: string, timeZone: string) {
    return DateTime.fromISO(time, { zone: timeZone }).toFormat(
      'LL/dd/yy - hh:mm a'
    )
  }

  async submitUpdate(): Promise<void> {
    this.submitting = true
    try {
      const flightInformation: FlightInformation = {
        flightInformationId: flight.flightInformation?.flightInformationId,
        flightNumber: flight.activeFlightNumber,
        airline: flight.activeAirline,
      }
      const payload: UpdateFlightInfoPayload = {
        stopId: flight.stop?.stopId,
        flightInformation,
      }
      await stop.updateFlightInformation(payload)
      this.$emit('update-flight-info')
    } catch (error) {
      console.error(error)
    }
    this.submitting = false
    this.$forceUpdate()
    this.close()
  }
}
</script>
