<template>
  <div class="margin-y-6 h-172">
    <v-card flat>
      <v-row
        :no-gutters="$vuetify.breakpoint.smAndUp"
        :dense="$vuetify.breakpoint.xs"
        align="stretch"
      >
        <v-col :cols="4">
          <SkeletonBox
            class="overflow-hidden padding-t-0"
            :style="{ height: '173px', 'border-radius': '4px 0px 0px 4px' }"
          />
        </v-col>

        <v-col class="padding-a-0 margin-t-3">
          <v-row dense>
            <v-col
              cols="6"
              :class="{
                'margin-l-5': $vuetify.breakpoint.smAndUp,
                'margin-l-4': $vuetify.breakpoint.xs,
              }"
              :style="{ 'margin-bottom': '6px' }"
            >
              <SkeletonBox type="text" />
              <br />
              <SkeletonBox type="text" class="w-one-half" />
            </v-col>

            <v-row
              :class="{
                'margin-l-5': $vuetify.breakpoint.smAndUp,
                'margin-l-4': $vuetify.breakpoint.xs,
              }"
              :style="{ 'margin-top': '2px' }"
              justify="end"
            >
              <div class="margin-l-3 margin-r-1 text-right" />
            </v-row>
          </v-row>

          <v-row class="margin-l-3 margin-r-1 h-one-half" align="center" dense>
            <v-col cols="auto">
              <SkeletonBox type="text" />
            </v-col>
            <v-spacer />
            <v-col cols="4" class="margin-r-3">
              <SkeletonBox type="button" block small />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({ components: { SkeletonBox } })
export default class SkeletonBidCard extends Vue {}
</script>
