<template>
  <div>
    <h4>Customer Notes</h4>
    <div class="font-14" v-html="tripNotesText" />
    <button
      v-if="tripNotes && tripNotes.length > 111"
      class="display-inline-block border-none background-transparent cursor-pointer font-bold text-primary"
      @click="customerNotesOpen = !customerNotesOpen"
    >
      {{ customerNotesOpen ? 'Less' : 'More' }}
    </button>
  </div>
</template>

<script lang="ts">
import { truncate } from '@/utils/string'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Note } from '../models/dto'
const CUSTOMER_NOTE_TYPE = 2

@Component
export default class TripNotes extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly notes: Note[]

  @Watch('customerNotesOpen')
  customerNotesOpenChanged(): void {
    this.$emit('open-changed')
  }

  customerNotesOpen = false

  get tripNotesText(): string {
    return this.customerNotesOpen
      ? this.tripNotes
      : truncate(this.tripNotes, 111, true)
  }

  get tripNotes(): string {
    if (!this.notes || !this.notes.length) {
      return ''
    }
    const customerNotes = this.notes.find((note) => {
      return note.noteType === CUSTOMER_NOTE_TYPE
    })
    return customerNotes?.html || customerNotes?.note
  }
}
</script>
