<template>
  <v-app-bar
    id="the-app-bar"
    app
    :height="$vuetify.breakpoint.smAndUp ? 68 : 60"
    class="app-toolbar background-white font-medium z-99"
    elevation="0"
    flat
  >
    <v-toolbar-title
      class="app-toolbar-title"
      :class="{ 'margin-l-n2': $vuetify.breakpoint.xs }"
    >
      <template v-if="auth.user">
        <router-link id="toolbar-button-logo" :to="{ name: 'home' }" tag="a">
          <CharterUPLogo width="110" :style="{ 'margin-top': '3px' }" />
        </router-link>
      </template>
      <template v-else>
        <a href="https://www.charterup.com/">
          <CharterUPLogo width="110" :style="{ 'margin-top': '3px' }" />
        </a>
      </template>
    </v-toolbar-title>

    <template v-if="showMenuItems">
      <AppBarNavigationItem
        v-for="(item, index) in mainItems"
        :key="`item-link-${item.name}-${index}`"
        :item="item"
        :index="index"
      />
      <v-spacer />
      <SupportNumber
        v-if="showSupportNumber"
        :class="{ 'margin-r-n6': $vuetify.breakpoint.smAndDown }"
      />
    </template>
    <v-spacer v-else />

    <v-menu offset-y eager>
      <template #activator="{ on }">
        <div
          v-show="$vuetify.breakpoint.mdAndUp && auth.user"
          id="toolbar-button-drop-down"
          class="font-medium font-14 cursor-pointer"
          v-on="on"
        >
          <span v-if="$vuetify.breakpoint.lgAndUp">{{ userDisplayName }}</span>
          <v-icon>
            arrow_drop_down
          </v-icon>
        </div>
      </template>
      <AccountSupport class="pa-4" />
      <v-list class="dropdown-menu padding-a-0">
        <v-list-item
          v-for="(item, index) in profileItems"
          :id="`toolbar-button-list-${toKebab(item.title.toString())}`"
          :key="`${item.link}-${index}`"
          :to="{ name: item.link }"
          class="profile-list-item"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>

        <v-list-item
          id="toolbar-button-logout"
          class="profile-list-item"
          @click="auth.logout"
        >
          <v-list-item-title>
            {{ $t('accountItems.LOGOUT') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-app-bar-nav-icon
      v-show="$vuetify.breakpoint.smAndDown && auth.user"
      id="toolbar-button-nav"
      class="app-toolbar-nav-icon"
      :style="
        $vuetify.breakpoint.xs ? 'margin-right:-20px;' : 'margin-right: -18px;'
      "
      @click="sidebar.setIsOpen(!sidebar.isOpen)"
    />
  </v-app-bar>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

import SupportNumber from '@/components/SupportNumber.vue'
import AppBarNavigationItem from '@/components/AppBarNavigationItem.vue'
import CharterUPLogo from '@/icons/CharterUPLogo.vue'
import AccountSupport from '@/components/AccountSupport.vue'

import auth from '@/store/modules/auth'
import app from '@/store/modules/app'
import quotes from '@/store/modules/quotes'
import support from '@/store/modules/support'
import { toKebab } from '@/utils/string'
import sidebar from '../store/modules/sidebar'
import { NavigationItem } from '../models/NavigationItem'
import user from '@/store/modules/user'

@Component({
  components: {
    CharterUPLogo,
    SupportNumber,
    AppBarNavigationItem,
    AccountSupport,
  },
  metaInfo() {
    return {
      title: app.title,
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {
          name: 'msapplication-TileColor',
          content: '#ffc40d',
        },
        {
          name: 'theme-color',
          content: '#ffffff',
        },
        {
          name: 'apple-mobile-web-app-title',
          content: app.title,
        },
        {
          name: 'application-name',
          content: app.title,
        },
      ],
      link: [
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/apple-touch-icon.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/favicon-32x32.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/favicon-16x16.png',
        },
        {
          rel: 'manifest',
          href: '/site.webmanifest',
        },
        {
          rel: 'mask-icon',
          color: '#5bbad5',
          href: '/safari-pinned-tab.svg',
        },
        {
          rel: 'favicon',
          href: '/favicon.ico',
        },
      ],
    }
  },
})
export default class TheAppBar extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly menuItems: NavigationItem[]
  @Prop({ type: Boolean, default: false })
  readonly showLoggedOutSupportNumber: boolean

  auth = auth
  support = support
  quotes = quotes
  sidebar = sidebar
  toKebab = toKebab

  get userDisplayName() {
    const name = `${auth.user?.firstName || ''} ${auth.user?.lastName || ''}`

    return name.length > 1 ? name : auth.user?.email
  }

  get mainItems(): NavigationItem[] {
    return this.menuItems.filter((item) => item.location === 'main')
  }

  get profileItems(): NavigationItem[] {
    return this.menuItems.filter((item) => item.location === 'dropdown')
  }

  get showSupportNumber(): boolean {
    // If the user has a customer account support phone number, they should click the expand to get both the support number
    // and the account executive number.
    return this.auth?.customerAccount?.supportPhone === null
  }

  get showMenuItems(): boolean {
    return (
      // True when logged in and on desktop OR
      // when logged out and in specific views (e.g., LiveTracking)
      (this.$vuetify.breakpoint.mdAndUp && this.mainItems.length > 0) ||
      (!auth.user && this.showLoggedOutSupportNumber)
    )
  }

  mounted(): void {
    if (auth.isTokenSet && user.isExistingCustomer) {
      // We call this here for the initial load of the app.
      // This could just as easily happen in the router
      // which is where subsequent calls to this happen.
      quotes.fetchUnreadQuoteCount()
      auth.refreshCustomerAccount()
    }
    this.trackUserId()
  }

  trackUserId() {
    if (auth.userId) {
      this.$ga4.trackEvent('auth_load', {
        user_id: auth.userId,
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';

.app-toolbar {
  box-shadow: none;

  .app-toolbar-title {
    font-size: 1rem;
    margin: 0 45px 0 0;
  }

  a {
    height: 68px;
    font-size: 0.875rem;
  }

  .app-toolbar-nav-icon {
    padding: 0;
  }
}

.profile-list-item {
  color: $gray !important;
  &::before {
    background: none;
  }
}
</style>
