<template>
  <div
    class="d-flex flex-column text-right"
    :class="{
      'margin-r-5': !stacked,
      'margin-r-4': stacked,
      'flex-grow-1': charCountMoreThan7 && !stacked,
      'flex-grow-0': !charCountMoreThan7 || stacked,
    }"
    :style="{
      'margin-top': '2px',
    }"
  >
    <div class="margin-l-3 margin-r-1">
      <h2
        v-if="!isValidBid"
        class="opacity-50"
        :class="{ 'font-16': stacked, 'font-18': !stacked }"
      >
        {{ $t('quoteDetail.bidCard.SOLD_OUT') }}
      </h2>
      <div v-else-if="awaitingBid" class="text-right">
        <h2
          :class="{
            'font-14 ': stacked,
            'font-16': !stacked,
          }"
        >
          {{ $t('quoteDetail.bidCard.REQUESTING_PRICE') }}
        </h2>
        <h6 :class="{ 'font-10': stacked, 'font-12': !stacked }">
          {{ $t('quoteDetail.bidCard.PRICE_WILL_UPDATE') }}
        </h6>
      </div>
      <h2 v-else>
        {{ formattedTotalAmount }}
      </h2>
      <h6
        v-if="!awaitingBid && isValidBid && hasDiscount"
        class="font-14"
      >
        <span class="white-space-nowrap">
          {{ $t('quoteDetail.bidCard.WAS') }}
          <span class="text-decoration-line-through">
            {{ formattedSubtotalAmount }}
          </span>
        </span>
      </h6>
    </div>
  </div>
</template>

<script lang="ts">
import { QuoteDetailV2Bid } from '@/models/dto'
import { roundedCurrencyFilter } from '@/utils/string'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ name: 'BidCardPricing' })
export default class BidCardPricing extends Vue {
  @Prop({ type: Object, required: true }) bid!: QuoteDetailV2Bid
  @Prop({ type: Boolean, default: false }) stacked!: boolean
  @Prop({ type: Boolean, default: false }) isValidBid!: boolean
  @Prop({ type: Boolean, default: false }) charCountMoreThan7!: boolean
  @Prop({ type: Boolean, default: false }) showUsd!: boolean

  get awaitingBid(): boolean {
    return this.bid.awaitngBid
  }

  get isFeatured(): boolean {
    return this.bid.featured
  }

  get hasDiscount(): number {
    return this.bid?.discountPercent
  }

  get formattedTotalAmount(): string {
    const suffix = this.showUsd ? ' USD' : ''
    const amount = roundedCurrencyFilter(Math.ceil(this.bid.totalAmount))
    return `${amount}${suffix}`
  }

  get formattedSubtotalAmount(): string {
    return roundedCurrencyFilter(Math.ceil(this.bid.subtotalAmount))
  }
}


</script>
