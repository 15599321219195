<template>
  <div
    v-if="text"
    class="d-flex align-center padding-y-2 padding-x-2 margin-b-4 border-radius-2x border-1 border-solid border-primary font-14 font-medium"
  >
    <CUIcon view-box="0 0 24 24" color="yellow" class="margin-l-1 margin-r-2">warning</CUIcon>
    {{ text }}
  </div>
</template>

<script lang="ts">
import { ClientCancellationPenaltyInfo, ReservationDetail } from '@/models/dto'
import { currencyFilter } from '@/utils/string'
import { TranslateResult } from 'vue-i18n'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { CancellationRefundPercentage } from '@/utils/enum'
@Component({})
export default class ReservationCancelCostWarning extends Vue {
  @Prop({ type: Object }) readonly reservation: ReservationDetail
  @Prop({ type: Object, required: true })
  readonly penaltyInfo!: ClientCancellationPenaltyInfo

  get text(): TranslateResult {
    if (this.refundPolicyPercent === CancellationRefundPercentage.Full) {
      return ''
    }
    const formattedRefundType =
      this.refundPolicyPercent !== CancellationRefundPercentage.None
        ? 'partially'
        : 'not'
    const formattedCost = currencyFilter(this.penaltyInfo.cancellationFee)
    return this.$t('reservationDetail.cancellationWarning.RESERVATION', {
      formattedRefundType,
      formattedCost,
    })
  }

  get refundPolicyPercent(): number {
    return this.reservation?.refundPolicyPercent
  }
}
</script>
