import { SpecialRequirementsTypeKey } from "./enum"

/**
 * Generates an SVG string for a Sales Bot stop icon.
 *
 * @param {string} color - The color to be used for the icon.
 * @param {boolean} [lastStop=false] - A flag indicating if this is the last stop. If true, a different SVG is generated.
 * @param {string} [height='24px'] - The height of the SVG.
 * @param {string} [width='24px'] - The width of the SVG.
 * @returns {string} The SVG string representing the sales bot stop icon.
 */
export const generateSalesBotStopIconSVG = (color: string, lastStop = false, height: string = '24px', width: string = '24px'): string => {
  if (lastStop) {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 24 24" role="presentation">
        <rect x="2" y="2" fill="${color}" width="20" height="20"/>
      </svg>
    `
  }

  return `
    <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 24 24" role="presentation">
      <g fill="${color}">
        <rect x="5.5" y="4.6" fill="#FFFFFF" width="14.9" height="13.8"/>
        <path d="M7.4,16.6c1.3,1.2,2.8,1.8,4.6,1.8s3.3-0.6,4.5-1.8c1.3-1.3,1.9-2.8,1.9-4.6s-0.6-3.3-1.9-4.5
          c-1.2-1.3-2.7-1.9-4.5-1.9S8.7,6.2,7.4,7.5C6.2,8.7,5.5,10.2,5.5,12S6.2,15.3,7.4,16.6z M4.3,19.7c-2.1-2.2-3.1-4.8-3.1-7.7
          s1.1-5.5,3.1-7.6C6.5,2.3,9,1.2,12,1.2s5.5,1.1,7.6,3.2c2.2,2.1,3.2,4.6,3.2,7.6s-1.1,5.5-3.2,7.7c-2.1,2.1-4.6,3.1-7.6,3.1
          S6.5,21.7,4.3,19.7z"/>
      </g>
    </svg>
  `
}

/**
 * A mapping of special requirements types to their corresponding icon names.
 *
 * @type {Record<SpecialRequirementsTypeKey, string>}
 */
const SPECIAL_REQUIREMENTS_ICON_MAP: Record<SpecialRequirementsTypeKey, string> = {
  [SpecialRequirementsTypeKey.ADA]: 'accessible',
  [SpecialRequirementsTypeKey.SPAB]: 'school',
  [SpecialRequirementsTypeKey.VehicleNeededEntireTrip]: 'vehicle_stays_on_site',
}

/**
 * Retrieves the icon associated with a given special requirement key.
 *
 * @param {SpecialRequirementsTypeKey} key - The key representing the special requirement.
 * @returns {string} The icon associated with the given key. Returns an empty string if the icon is not found.
 */
export const getRequirementIconByKey = (key: SpecialRequirementsTypeKey): string => {
  const icon = SPECIAL_REQUIREMENTS_ICON_MAP[key]
  if (!icon) {
    console.warn('Failed to load icon for requirement', key)
    return ''
  }

  return icon
}
