<template>
  <div class="stop-container">
    <div
      :id="`live-tracking-itinerary-stop-${index}-name`"
      class="live-tracking-itinerary__stop__title"
    >
      {{ stop.address.title || addressPrettyFromStop(stop) }}
      <div
        v-if="showEtaPill"
        class="live-tracking-itinerary__stop__title__eta rounded-pill"
        :style="{ backgroundColor: color }"
      >
        ETA {{ tracking.etaCountdownText }}
      </div>
    </div>
    <div
      v-if="stop.address.title"
      :id="`live-tracking-itinerary-stop-${index}-address`"
      class="live-tracking-itinerary__stop__address"
    >
      {{ addressPrettyFromStop(stop) }}
    </div>
    <div
      v-if="dropoffDatetime"
      :id="`live-tracking-itinerary-stop-${index}-pickup-time`"
      class="live-tracking-itinerary__stop__time"
    >
      {{ $t('liveTracking.itinerary.ARRIVAL') }}:
      {{ formatDisplayTime(dropoffDatetime, stop.address.timeZone, displayFormat) }}
    </div>
    <div
      v-if="pickupDatetime"
      :id="`live-tracking-itinerary-stop-${index}-pickup-time`"
      class="live-tracking-itinerary__stop__time"
    >
      {{ $t('liveTracking.itinerary.PICKUP') }}:
      {{ formatDisplayTime(pickupDatetime, stop.address.timeZone, displayFormat) }}
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { addressPrettyFromStop, formatDisplayTime } from '@/utils/string'
import { TrackingStop } from '../models/dto/TrackingStop'
import tracking from '@/store/modules/tracking'

const SINGLE_DAY_DATETIME_FORMAT = "h:mm a"
const MULTI_DAY_DATETIME_FORMAT = "hh:mm a '–' LLL d"

@Component
export default class LiveTrackingItineraryStop extends Vue {
  @Prop({ type: Object, required: true }) stop!: TrackingStop
  @Prop({ type: Number, required: true }) index!: number
  @Prop({ type: Object, required: true }) statusDetails: any
  @Prop({ type: String, required: true }) color: string
  @Prop({ type: Boolean, required: true }) isMultiDayTrip: boolean


  tracking = tracking
  addressPrettyFromStop = addressPrettyFromStop
  formatDisplayTime = formatDisplayTime
  showDetails = false

  get dropoffDatetime(): string {
    return this.stop.dropoffDatetime
  }

  get pickupDatetime(): string {
    return this.stop.pickupDatetime
  }

  get reservationStatus(): string {
    return tracking.reservation?.reservationStatus
  }

  get showEtaPill(): boolean {
    return (
      tracking.isNext(this.stop) &&
      tracking.isEnRoute &&
      !['upcoming', 'hold'].includes(this.reservationStatus) &&
      !!tracking.etaCountdownText &&
      !tracking.isFinished &&
      !tracking.isComplete(this.stop) &&
      !this.statusDetails?.isAtAnyStop &&
      (this.statusDetails?.isNextStopFirstStop
        ? this.statusDetails?.isPickupEtaOnSchedule
        : true)
    )
  }

  get displayFormat(): string {
    if (this.isMultiDayTrip) {
      return MULTI_DAY_DATETIME_FORMAT
    }
    return SINGLE_DAY_DATETIME_FORMAT
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';

.live-tracking-itinerary {
  &__stop {
    &__title {
      margin-bottom: -2px;
      font-size: 1rem;
      font-family: 'Inter Bold', Arial, sans-serif;
      &__eta {
        position: relative;
        top: -1px;
        display: inline-block;
        padding: 2px 8px;
        margin-left: 4px;
        font-size: 0.925rem;
        color: $white !important;
        font-family: 'Inter Bold', Arial, sans-serif;
      }
    }
    &__address {
      margin-bottom: 2px;
      font-size: 0.925rem;
      color: rgba(0, 0, 0, 0.6) !important;
    }
    &__time {
      color: $gray-medium-light !important;
      line-height: 1.2em;
    }
    &__reached {
      margin-top: 6px;
      font-size: 0.925rem;
      line-height: 1.2em;
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }
}
.stop-container {
  padding: 0 8px 0;
}
@media screen and (min-width: 600px) {
  .stop-container {
    width: 300px;
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 601px) {
  .stop-container {
    width: 100%;
    margin-bottom: 4px;
  }
}

::v-deep .v-timeline-item__body {
  padding-left: 6px;
}
</style>
