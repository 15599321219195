<template>
  <div :class="{ 'd-flex justify-center': $vuetify.breakpoint.xsOnly }">
    <v-card
      v-if="reservation"
      class="border-1 border-solid border-border-gray z-1 display-flex margin-r-0"
      flat
      :class="{
        'outline-none border-1 border-solid border-featured-border': featured,
      }"
      :style="{
        'flex-direction': 'column',
        'box-shadow': featured
          ? `0 0 12px ${$vuetify.theme.themes.light.featuredBorder}`
          : '',
        width: stacked ? `${imgWidth}px` : '',
      }"
    >
      <div
        class="d-flex"
        :style="{ 'flex-direction': stacked ? 'column' : 'row' }"
        :class="{ 'order-0': !stacked, 'order-1': stacked }"
      >
        <div class="padding-a-2">
          <ReservationCardVehicleImage
            class="margin-a-0 z-0 border-radius-regular"
            :class="{
              'object-fit-cover h-full': !stacked,
            }"
            :width="stacked ? `${imgWidth - 20}px` : `${imgWidth - 60}px`"
            :style="{
              height: stacked ? `${(1 / 2.44) * imgWidth}px` : '',
            }"
            :required-vehicles="reservation.requiredVehicles"
            :loading="loading"
          />
        </div>
        <div
          class="w-full display-flex flex-wrap align-center justify-space-between"
          :class="{
            'margin-b-2 padding-x-2': stacked,
            'margin-y-3 padding-r-2': !stacked,
          }"
        >
          <div
            :class="{
              'margin-l-2 margin-y-4': stacked,
              'padding-l-4': !stacked,
            }"
          >
            <h1 class="margin-b-0">{{ reservationCities }}</h1>
            <div class="d-flex align-center">
              <h3 v-if="reservation.routeName" class="text-primary">
                {{ reservation.routeName }}
              </h3>
              <h3
                v-else-if="allowReservationManagement"
                class="text-primary cursor-pointer"
                @click="tripNameDialogIsOpen = true"
              >
                {{
                  $t('reservationDetail.reservationCard.WHATS_THIS_TRIP_CALLED')
                }}
              </h3>
              <div
                v-if="allowReservationManagement"
                class="d-flex align-center justify-center w-20 h-20 background-border-gray border-radius-round margin-l-1"
              >
                <CUIcon
                  class="margin-l-1 margin-t-1"
                  color="primary"
                  x-small
                  view-box="0 0 32 32"
                  @click="tripNameDialogIsOpen = true"
                >
                  edit
                </CUIcon>
              </div>
            </div>
            <div
              class="display-flex"
              :class="{
                'margin-t-7':
                  !reservation.routeName &&
                  !allowReservationManagement &&
                  $vuetify.breakpoint.smAndUp,
              }"
            >
              <div class="font-24">
                {{ $t('common.ID') }} {{ reservation.managedId }}
              </div>
              <SkeletonBox
                v-if="loading"
                class="w-100 h-24 margin-t-1 margin-l-2"
                :style="{
                  'border-radius': '12px !important',
                }"
              />
              <button
                v-else-if="isPending"
                class="border-primary border-solid border-1 rounded-pill margin-l-2 margin-t-1 padding-x-3 display-flex align-center"
                @click="pendingTripDialogIsOpen = true"
              >
                <div class="text-primary margin-r-2 font-14">
                  Pending
                </div>
                <div
                  class="w-20 h-20 background-border-gray border-radius-round text-primary font-14 font-weight-600 margin-r-n2"
                >
                  i
                </div>
              </button>
              <button
                v-else
                class="border-primary border-solid border-1 rounded-pill margin-l-2 margin-y-1 padding-x-3 display-flex align-center text-primary cursor-default font-14 margin--1"
              >
                {{ formattedReservationStatus }}
              </button>
            </div>
          </div>
          <div
            class="d-flex w-full"
            :class="{
              'margin-l-2 margin-b-3': stacked,
              'margin-t-1 padding-l-4': !stacked,
            }"
          >
            <TripLiveTracking :reservation="reservation" />
          </div>
          <div
            v-if="showViewDetailsButton"
            class="d-flex flex-column"
            :class="{
              'margin-y-2 margin-r-3 margin-l-4': $vuetify.breakpoint.smAndUp,
              'margin-a-0 w-full': stacked,
            }"
          >
            <SkeletonBox
              v-if="loading"
              class="h-44 margin-l-auto"
              :class="{
                'w-132': !stacked,
                'w-full': stacked,
              }"
            />

            <v-btn
              id="mobile-reservation-card-button-view-itinerary"
              class="margin-l-auto w-full margin-t-2 margin-b-0"
              :class="{
                'margin-r-3': $vuetify.breakpoint.smAndUp,
                'margin-x-0': stacked,
              }"
              color="primary"
              small
              @click="isItineraryModalOpen = true"
            >
              {{ $t('reservationDetail.reservationCard.VIEW_DETAILS') }}
            </v-btn>
          </div>
        </div>
      </div>
      <div
        v-if="isCancelled"
        class="background-midnight-gray-50 padding-x-2 padding-y-1 font-14 font-medium text-center"
        :class="{
          'order-0 border-radius-bottom-right-none border-radius-bottom-left-none': stacked,
          'order-1': !stacked,
        }"
      >
        This trip was cancelled on {{ formattedCancellationDate }}
      </div>
    </v-card>
    <PendingTripDialog v-model="pendingTripDialogIsOpen" />
    <TripNameDialog
      v-model="tripNameDialogIsOpen"
      :reservation="reservation"
      @refresh="$emit('refresh')"
    />
    <TripInfoItineraryModal
      v-model="isItineraryModalOpen"
      :reservation="reservation"
      :trip="trip"
      title="Details"
      :allow-reservation-management="allowReservationManagement"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import TripNameDialog from '@/components/TripNameDialog.vue'
import PendingTripDialog from '@/components/PendingTripDialog.vue'
import ReservationCardVehicleImage from '@/components/ReservationCardVehicleImage.vue'
import TripInfoItineraryModal from '@/components/TripInfoItineraryModal.vue'
import TripLiveTracking from '@/components/TripLiveTracking.vue'
import {
  toSentence,
  formatReservationPickupDestinationText,
} from '@/utils/string'
import { Stop } from '@/models/dto'
import { DateTime } from 'luxon'
import { ReservationStatusKey } from '@/utils/enum'
import { ReservationDetail } from '../models/dto/ReservationDetail'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({
  components: {
    TripNameDialog,
    PendingTripDialog,
    ReservationCardVehicleImage,
    TripInfoItineraryModal,
    TripLiveTracking,
    SkeletonBox,
  },
})
export default class ReservationCard extends Vue {
  @Prop({ type: String, default: null }) readonly id: string
  @Prop({ type: Boolean }) readonly stacked: boolean
  @Prop({ type: Boolean, default: false }) readonly allowReservationManagement: boolean
  @Prop({ type: Number, default: 300 }) readonly imgWidth: number
  @Prop({ type: Object, required: true }) readonly reservation: ReservationDetail
  @Prop({ type: Boolean }) readonly featured: boolean
  @Prop({ type: Boolean }) readonly loading: boolean
  @Prop({ type: String, default: null }) readonly to: string
  @Prop({ type: Object, default: () => {}, required: true }) readonly trip: any

  pendingTripDialogIsOpen = false
  tripNameDialogIsOpen = false
  isItineraryModalOpen = false

  get isPending(): boolean {
    return (
      !this.wasBookedOver48HoursAgo &&
      !this.isReservationFullyAccepted &&
      this.isReservationUpcomingOrOnHold
    )
  }

  get wasBookedOver48HoursAgo(): boolean {
    const createdOn = this.reservation?.createdOn
    if (!createdOn) {
      return false
    }
    const now = DateTime.local()
    const diff = now.diff(DateTime.fromISO(createdOn), 'hours').toObject()
    return diff?.hours > 48
  }

  get isReservationFullyAccepted(): boolean {
    return this.reservation.referralStatus === 'fully_accepted'
  }

  get isReservationUpcomingOrOnHold(): boolean {
    return (
      this.reservation.reservationStatus === 'upcoming' ||
      this.reservation.reservationStatus === 'hold'
    )
  }

  get isReservationComplete(): boolean {
    return (
      this.reservation.reservationStatus === ReservationStatusKey.Finished ||
      this.reservation.reservationStatus === ReservationStatusKey.Cancelled
    )
  }

  get isCancelled(): boolean {
    return this.reservation.reservationStatus === ReservationStatusKey.Cancelled
  }

  get formattedReservationStatus(): string {
    return toSentence(this.reservation.reservationStatus)
  }

  get reservationCities(): string {
    return formatReservationPickupDestinationText(
      this.reservation,
      this.$t('common.TO')
    )
  }

  get firstPickup(): Stop {
    return this.reservation.stops.find((stop) => stop.orderIndex === 0)
  }

  get isWithin3HoursOfPickup(): boolean {
    if (!this.firstPickup) {
      return false
    }
    const pickupTime = this.$dayjs(this.firstPickup.pickupDatetime).subtract(
      3,
      'hours'
    )
    return pickupTime.isBefore(this.$dayjs())
  }

  get hasLiveTracking(): boolean {
    return this.reservation?.hasLiveTrackingData
  }

  get showViewDetailsButton(): boolean {
    if (!this.$vuetify.breakpoint.mdAndDown) {
      return false
    }
    if (
      this.reservation.reservationStatus === ReservationStatusKey.Started &&
      this.hasLiveTracking
    ) {
      return false
    }
    if (this.reservation.reservationStatus === ReservationStatusKey.Finished) {
      return true
    }
    return true
  }

  get formattedCancellationDate(): string {
    const { cancelledOn } = this.reservation || {}
    if (!cancelledOn) {
      return ''
    }
    return this.$dayjs(cancelledOn).format('MMMM D, h:mm A')
  }

  openNewTrackingWindow(): void {
    const path = {
      name: 'live-tracking',
      params: {
        reservationId: this.reservation.reservationId.toString(),
      },
    }
    const routeData = this.$router.resolve(path)
    window.open(routeData.href, '_blank')
  }
}
</script>
