<template>
  <div class="d-flex align-center">
    <v-checkbox
      :input-value="vehicleNeededEntireTrip"
      label="Keep vehicle on-site"
      hide-details
      class="margin-t-0 padding-t-0"
      @change="emit('vehicle-needed-entire-trip:update', $event)"
    />
    <v-spacer />
    <div
      class="font-12 font-medium text-primary d-flex align-center cursor-pointer padding-y-1"
      @click="isKeepOnSiteDialogOpen = true"
    >
      <CUIcon
        color="primary"
        view-box="0 0 24 24"
        class="w-16 h-16 margin-r-1"
      >
        info_outlined
      </CUIcon>
      What's this?
    </div>
    <SelfServeTripItineraryStopKeepOnSiteDialog v-model="isKeepOnSiteDialogOpen" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import SelfServeTripItineraryStopKeepOnSiteDialog from '@/components/SelfServeTripItineraryStopKeepOnSiteDialog.vue'

const props = defineProps({
  vehicleNeededEntireTrip: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits(['vehicle-needed-entire-trip:update'])

const isKeepOnSiteDialogOpen = ref<boolean>(false)

</script>
