<template>
  <div class="w-full h-full">
    <div
      class="recenter-button"
      :class="{
        disabled: !customPosition,
        public: isModePublic,
        mobile: $vuetify.breakpoint.xs,
      }"
      @click="handleRecenter"
    >
      <CUIcon width="35" height="35" :style="{ margin: '10px' }">
        location
      </CUIcon>
    </div>
    <GmapMap
      ref="gMap"
      class="map"
      :class="{
        'table-view': isTableView,
        public: isModePublic,
      }"
      :zoom="mapConfig.zoom"
      :center="mapConfig.center"
      :options="mapConfig.options"
    >
      <GmapCluster
        v-if="isEnterpriseAdmin && !isModePublic"
        :options="{ maxZoom: 12 }"
        :styles="clusterStyles"
      >
        <GmapMarker
          v-for="marker in vehicleMarkers"
          :key="marker.id"
          :position="marker.position"
          :icon="marker.icon"
          :clickable="true"
          @click="openInfoWindow(marker)"
        />
        <GmapInfoWindow
          :position="infoWindowPosition"
          :opened="infoWindowOpened"
          :closeable="true"
          @closeclick="closeInfoWindow"
        >
          <EnterpriseTrackingAdminInfoWindow
            :vehicle="infoWindowContent"
            :eld-types="eldTypes"
            @close="closeInfoWindow"
          />
          <!-- <span v-html="infoWindowContent" /> -->
        </GmapInfoWindow>
      </GmapCluster>
    </GmapMap>
  </div>
</template>

<script lang="ts">
import { DateTime } from 'luxon'
import { toCamel } from '@/utils/string'
import { enterpriseMapStyles, mapStyles } from '@/components/mapStyles.js'
import pinNumbers from '@/utils/pinNumbers.js'
import { gmapApi } from 'vue2-google-maps'
import auth from '@/store/modules/auth'
import { Address, TypeWithId } from '@/models/dto'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import clusterMarker from '@/assets/images/cluster_gmaps.png'
import EnterpriseTrackingAdminInfoWindow from '@/components/EnterpriseTrackingAdminInfoWindow.vue'

@Component({ components: { GmapCluster, EnterpriseTrackingAdminInfoWindow } })
export default class EnterpriseTrackingMap extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly filteredVehicles: any[]
  @Prop({ type: Object, default: () => {} }) readonly filters: any
  @Prop({ type: Boolean }) readonly isEnterpriseAdmin: boolean
  @Prop({ type: Boolean }) readonly isTableView: boolean
  @Prop({ type: [String, Number], default: '0' }) readonly hoverItem: any
  @Prop({ type: Boolean, required: true }) readonly isModePublic: boolean
  @Prop({ type: Array, default: () => [] })
  readonly reservationItineraries: any[]
  @Prop({ type: Array, default: () => [] }) readonly eldTypes: TypeWithId[]

  @Watch('filteredVehicles', { deep: true })
  async filteredVehiclesChanged(): Promise<void> {
    if (!this.map) {
      return
    }
    await this.updateMap()
  }

  @Watch('filters', { deep: true })
  filtersChanged(): void {
    this.clearStops()
    this.drawStops()
  }

  @Watch('hoverItem')
  hoverItemChanged(value: any, oldValue: any): void {
    if (this.isEnterpriseAdmin) {
      if (value !== '0') {
        const vehicle = this.filteredVehicles.find(
          (vehicle) => vehicle.trak4DeviceId === value
        )
        this.drawHighlight(vehicle)
      } else {
        this.clearHighlights()
      }
    } else if (value !== '0') {
      const vehicle = this.findVehicleById(value)
      const marker = this.vehicleMarkersMap.get(vehicle.vehicleId)
      vehicle.infoWindow.open(this.map, marker)
    } else {
      const vehicle = this.findVehicleById(oldValue)
      vehicle.infoWindow.close()
    }
  }

  directionsService = null
  firstLoad = true
  map = null
  bounds = null
  vehicles = []
  vehicleMarkers = []
  vehicleMarkersMap = new Map()
  highlightMarkersMap = new Map()
  stopMarkers = []
  highlightMarkers = []
  outlinedHighlightMarkers = []
  infoWindows = []
  customPosition = false
  mapConfig = {
    center: { lat: 33.8458485, lng: -84.3716548 },
    zoom: 18,
    options: {
      clickableIcons: false,
      streetViewControl: false,
      fullScreenControl: true,
      mapTypeControl: false,
      styles: [],
      fullscreenControlOptions: {
        position: -1,
      },
      gestureHandling: 'greedy',
    },
  }
  clusterStyles = [
    {
      url: clusterMarker,
      height: 44,
      width: 42,
      textColor: '#ffffff',
      textSize: 20,
      anchorText: [0, 0],
    },
  ]
  infoWindowPosition = null
  infoWindowOpened = false
  infoWindowContent = null

  get google(): any {
    return gmapApi()
  }

  async mounted(): Promise<void> {
    if (this.isModePublic) {
      this.mapConfig.options.styles = mapStyles
    } else {
      this.mapConfig.options.styles = enterpriseMapStyles
    }
    this.map = await (this.$refs.gMap as any).$mapPromise
    this.bounds = new this.google.maps.LatLngBounds()
    this.initializeDirectionsService()
    await this.updateMap()
    setTimeout(() => {
      this.map.addListener('zoom_changed', () => this.handleCenterChanged())
      this.map.addListener('drag', () => this.handleCenterChanged())
    }, 1000)
  }
  async updateMap(): Promise<void> {
    await this.clearMap()
    if (this.firstLoad) {
      this.drawStops()
    }
    if (this.isEnterpriseAdmin) {
      this.vehicleMarkers = []
      for (const vehicle of this.filteredVehicles) {
        this.drawVehicle(vehicle)
      }
      if (!this.customPosition) {
        this.fitVehiclesInBounds(this.bounds)
      }
    } else {
      this.processVehicles()
    }
    if (!this.customPosition) {
      this.handleRecenter()
    }
  }
  clearMap(): void {
    this.clearInfoWindows()
    this.clearUnusedVehicleMarkers()
    this.clearUnusedHighlights()
    // clear animated vehicles
    this.bounds = null
    this.bounds = new this.google.maps.LatLngBounds()
  }
  clearUnusedVehicleMarkers(): void {
    for (const [vehicleId, marker] of this.vehicleMarkersMap.entries()) {
      if (
        !this.filteredVehicles.some(
          (vehicle) => vehicle.vehicleId === vehicleId
        )
      ) {
        marker.setMap(null)
        this.vehicleMarkersMap.delete(vehicleId)
        this.vehicleMarkers.splice(this.vehicleMarkers.indexOf(marker), 1)
      }
    }
  }
  clearUnusedHighlights(): void {
    for (const [vehicleId, marker] of this.highlightMarkersMap.entries()) {
      if (
        !this.filteredVehicles.some(
          (vehicle) => vehicle.vehicleId === vehicleId
        )
      ) {
        marker.setMap(null)
        this.highlightMarkersMap.delete(vehicleId)
        this.highlightMarkers.splice(this.highlightMarkers.indexOf(marker), 1)
      }
    }
  }
  clearStops(): void {
    for (const marker of this.stopMarkers) {
      marker.setMap(null)
    }
    this.stopMarkers = []
  }
  clearInfoWindows(): void {
    for (const infoWindow of this.infoWindows) {
      infoWindow.close()
    }
    for (const infoWindow of this.infoWindows) {
      infoWindow.setMap(null)
    }
    this.infoWindows = []
  }
  clearHighlights(): void {
    for (const marker of this.highlightMarkers) {
      marker.setMap(null)
    }
    this.highlightMarkers = []
  }
  initializeDirectionsService(): void {
    this.directionsService = (() => {
      const ds = new this.google.maps.DirectionsService()
      let counter = 1
      return (dOpts, dc) => {
        counter++
        setTimeout(() => {
          ds.route(dOpts, dc)
          counter--
        }, 80 * counter)
      }
    })()
  }
  processVehicles(): void {
    const vehicles = []
    // TEMP CODE
    const isSomeVehicleOnChicagoContract = this.filteredVehicles.some(
      (vehicle) => vehicle?.contractName === 'Illinois_Emergency_Management'
    )
    if (isSomeVehicleOnChicagoContract && !this.isEnterpriseAdmin) {
      for (const marker of this.vehicleMarkers) {
        marker.setMap(null)
      }
      this.vehicleMarkers = []
      for (const infoWindow of this.infoWindows) {
        infoWindow.close()
      }
      for (const infoWindow of this.infoWindows) {
        infoWindow.setMap(null)
      }
      this.infoWindows = []
    }

    for (const vehicle of this.filteredVehicles) {
      let vehicleData = this.findVehicleById(vehicle.trak4DeviceId)
      const currentPosition = {
        lat: Number(vehicle.lat),
        lng: Number(vehicle.lng),
      }
      const previousPosition = vehicleData?.location

      if (!vehicleData) {
        vehicleData = {
          id: vehicle.trak4DeviceId,
          heading: vehicle.heading,
          direction: this.getVehicleDirection(vehicle.heading),
          infoWindow: null,
          isFirstLoad: true,
          vehicleName: vehicle.vehicleName,
          vehicleType: vehicle.vehicleType,
          speed: vehicle.gpsSpeed,
          color: vehicle.color,
          vehicleId: vehicle.vehicleId,
        }
      }
      vehicleData.location = currentPosition
      vehicleData.previousPosition = previousPosition
      vehicles.push(vehicleData)
      if (vehicleData.infoWindow) {
        vehicleData.infoWindow.close()
        vehicleData.infoWindow.setMap(null)
        vehicleData.infoWindow = null
      }
      vehicleData.infoWindow = this.makeVehicleInfoWindow(vehicle)
      if (this.vehicleMarkersMap.has(vehicle.vehicleId)) {
        this.updateVehicleMarker(vehicle, previousPosition)
      } else {
        this.drawVehicle(vehicle)
      }
      vehicles.push(vehicleData)
    }
    this.vehicles = vehicles
  }
  findVehicleById(id) {
    return this.vehicles.find((vehicle) => vehicle.id === id)
  }
  getVehicleHeading(
    vehicle,
    previousPosition: { lat: number; lng: number }
  ): number {
    if (
      previousPosition.lat === vehicle.lat &&
      previousPosition.lng === vehicle.lng
    ) {
      return 0
    }
    const dLng = vehicle.lng - previousPosition.lng
    const y = Math.sin(dLng) * Math.cos(vehicle.lat)
    const x =
      Math.cos(previousPosition.lat) * Math.sin(vehicle.lat) -
      Math.sin(previousPosition.lat) * Math.cos(vehicle.lat) * Math.cos(dLng)
    let heading = (Math.atan2(y, x) * 180) / Math.PI
    heading = heading % 360
    if (heading < 0) {
      heading += 360
    }
    heading = Math.ceil(heading / 10) * 10
    heading = 360 - heading
    if (heading === 360 || heading == null) {
      return 0
    }
    return heading
  }
  getVehicleDirection(heading: number): string {
    const val = Math.floor(heading / 22.5 + 0.5)
    const directions = [
      'N',
      'NNE',
      'NE',
      'ENE',
      'E',
      'ESE',
      'SE',
      'SSE',
      'S',
      'SSW',
      'SW',
      'WSW',
      'W',
      'WNW',
      'NW',
      'NNW',
    ]
    return directions[val % 16]
  }
  updateVehicleMarker(
    vehicle,
    previousPosition: { lat: number; lng: number }
  ): void {
    if (vehicle.heading == null) {
      vehicle.heading = this.getVehicleHeading(vehicle, previousPosition)
    }
    vehicle.direction = this.getVehicleDirection(vehicle.heading)
    const infoWindow = this.makeVehicleInfoWindow(vehicle)
    const marker = this.vehicleMarkersMap.get(vehicle.vehicleId)
    const icon = this.makeVehicleIcon(vehicle.vehicleType, vehicle.heading)
    marker.setIcon(icon)
    marker.setPosition(new this.google.maps.LatLng(vehicle.lat, vehicle.lng))
    this.google.maps.event.clearInstanceListeners(marker)
    if (vehicle.active) {
      marker.setOpacity(1)
    } else {
      marker.setOpacity(0.35)
    }
    if (!this.isEnterpriseAdmin) {
      if (this.highlightMarkersMap.has(vehicle.vehicleId)) {
        const highlightMarker = this.highlightMarkersMap.get(vehicle.vehicleId)
        highlightMarker.setPosition(
          new this.google.maps.LatLng(vehicle.lat, vehicle.lng)
        )
      } else {
        this.drawHighlight(vehicle)
      }
    }
    if (infoWindow) {
      marker.addListener('mouseover', () => {
        infoWindow.open(this.map, marker)
      })
      marker.addListener('mouseout', () => {
        infoWindow.close(this.map, marker)
      })
    }
  }
  drawVehicle(vehicle): void {
    if (vehicle.heading == null) {
      vehicle.heading = 0
    }
    vehicle.direction = this.getVehicleDirection(vehicle.heading)
    const infoWindow = this.makeVehicleInfoWindow(vehicle)
    const icon = this.makeVehicleIcon(vehicle.vehicleType, vehicle.heading)
    const marker = this.makeVehicleMarker(
      this.isEnterpriseAdmin ? null : this.map,
      vehicle.lat,
      vehicle.lng,
      icon,
      infoWindow
    )
    this.vehicleMarkersMap.set(vehicle.vehicleId, marker)
    marker.trak4DeviceId = vehicle.trak4DeviceId
    marker.vehicle = vehicle
    if (!vehicle.active) {
      marker.setOpacity(0.35)
    }
    if (!this.isEnterpriseAdmin) {
      this.drawHighlight(vehicle)
    }
    if (infoWindow) {
      marker.infoWindow = infoWindow
      marker.addListener('mouseover', () => {
        infoWindow.open(this.map, marker)
      })
      marker.addListener('mouseout', () => {
        infoWindow.close(this.map, marker)
      })
    }
  }
  drawStops(): void {
    if (this.isEnterpriseAdmin && !Object.entries(this.filters).length) {
      return
    }
    for (const itinerary of this.reservationItineraries) {
      if (
        !this.filters.hasOwnProperty('reservationId') ||
        (this.filters.hasOwnProperty('reservationId') &&
          itinerary[0].managedId === this.filters['reservationId'][0])
      ) {
        for (const [index, stop] of itinerary.entries()) {
          const address = stop.address
          const infoWindow = this.makeStopInfoWindow(address)
          const marker = this.makeStopMarker(
            stop.color,
            address.lat,
            address.lng,
            infoWindow,
            index + 1
          )
          this.bounds.extend(marker.getPosition())
          marker.setMap(this.map)
        }
      }
    }
    this.firstLoad = false
  }
  drawHighlight(vehicle, outlined = false): void {
    const marker = this.makeHighlightMarker(vehicle, outlined)
    this.bounds.extend(marker.getPosition())
    marker.setMap(this.map)
    this.highlightMarkersMap.set(vehicle.vehicleId, marker)
  }
  makeStopMarker(
    color: string,
    latitude: number,
    longitude: number,
    infoWindow,
    number: number
  ) {
    const icon = {
      path:
        'M21,0.2c-11.6,0-21,9.4-21,21C0,27.4,2.7,33,7.1,36.9L21,50.8l13.9-13.9C39.3,33,42,27.4,42,21.2 C42,9.6,32.6,0.2,21,0.2z',
      fillColor: color,
      fillOpacity: 1,
      strokeWeight: 0,
      scale: 0.75,
      anchor: new this.google.maps.Point(21, 50.8),
    }
    const pinNumber = {
      path: pinNumbers[number],
      fillColor: '#000000',
      fillOpacity: 0.35,
      strokeWeight: 0,
      scale: 0.75,
      anchor: new this.google.maps.Point(21, 49.4),
    }

    const marker = this.makeMarker(
      this.map,
      latitude,
      longitude,
      icon,
      infoWindow
    )
    marker.setZIndex((number - 1) * 2)
    const markerDot = this.makeMarker(
      this.map,
      latitude,
      longitude,
      pinNumber,
      null
    )
    markerDot.setZIndex((number - 1) * 2 + 1)
    this.stopMarkers.push(marker)
    this.stopMarkers.push(markerDot)
    return marker
  }
  makeHighlightMarker(vehicle, outlined: boolean) {
    const icon = {
      path: 'M 25, 50 a 25,25 0 1,1 50,0 a 25,25 0 1,1 -50,0',
      fillColor: vehicle.color,
      fillOpacity: outlined ? 0 : 0.25,
      strokeWeight: outlined ? 2 : 0,
      strokeColor: vehicle.color,
      rotation: 0,
      scale: 2,
      anchor: new this.google.maps.Point(50, 50),
    }
    const marker = this.makeMarker(this.map, vehicle.lat, vehicle.lng, icon)
    if (outlined) {
      marker.setZIndex(5)
      this.outlinedHighlightMarkers.push(marker)
    } else {
      marker.setZIndex(0)
      this.highlightMarkers.push(marker)
    }
    return marker
  }
  makeVehicleMarker(
    map,
    latitude: number,
    longitude: number,
    icon,
    infoWindow
  ) {
    const vehicleMarker = this.makeMarker(
      map,
      latitude,
      longitude,
      icon,
      infoWindow
    )
    vehicleMarker.setZIndex(99)
    this.vehicleMarkers.push(vehicleMarker)
    return vehicleMarker
  }
  makeMarker(
    map,
    latitude: number,
    longitude: number,
    icon,
    infoWindow = null
  ) {
    const marker = new this.google.maps.Marker({
      map,
      icon,
      position: new this.google.maps.LatLng(latitude, longitude),
      optimized: false,
    })
    if (infoWindow) {
      marker.addListener('mouseover', () => {
        infoWindow.open(map, marker)
      })
      marker.addListener('mouseout', () => {
        infoWindow.close(map, marker)
      })
    }
    return marker
  }
  getIconVehicleType(vehicleType: string): string {
    if (vehicleType) {
      vehicleType = toCamel(vehicleType)
    }
    if (!['charterBus', 'sprinter', 'miniBus'].includes(vehicleType)) {
      if (vehicleType === 'van') {
        return 'sprinter'
      }
      return 'charterBus'
    }
    return vehicleType
  }
  makeVehicleIcon(vehicleType: string, heading: number) {
    if (this.isEnterpriseAdmin) {
      return {
        path: `M12.7,16.6l-5.2-2.3c-0.2-0.1-0.5-0.1-0.8,0l-5.2,2.3c-0.8,0.4-1.7-0.5-1.3-1.3l6-14.7c0.3-0.8,1.5-0.8,1.9,0l6,14.7
C14.3,16.2,13.5,17,12.7,16.6z`,
        fillColor: this.$vuetify.theme.themes.light.primary,
        fillOpacity: 1,
        anchor: new this.google.maps.Point(7, 8.4),
        strokeWeight: 1,
        strokeColor: 'white',
        scale: 1,
        rotation: heading,
      }
    }

    // ALL TEMP CODE BELOW
    vehicleType = this.getIconVehicleType(vehicleType)
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const url = require(`@/assets/images/trackingIcons/${vehicleType}/${heading}.svg`)
    let size = null
    if (vehicleType === 'charterBus') {
      size = [65, 65]
    } else if (vehicleType === 'sprinter') {
      size = [45, 45]
    } else if (vehicleType === 'miniBus') {
      size = [57, 57]
    }
    const anchorPointMap = {
      charterBus: [33, 33],
      miniBus: [28, 28],
      sprinter: [22, 22],
    }
    return {
      url,
      scaledSize: new this.google.maps.Size(...size),
      anchor: new this.google.maps.Point(...anchorPointMap[vehicleType]),
    }
  }
  makeStopInfoWindow(address: Address) {
    const addressTitle = address.title
    let addressString = ''
    if (address.street1 && address.street1 !== ' ') {
      addressString = `${address.street1}, `
    }
    if (address.city) {
      addressString = `${addressString}${address.city}, `
    }
    if (address.state) {
      addressString = `${addressString}${address.state}`
    }

    const infowindow = `<div style="text-align: center;"> <b>${
      addressTitle || address.name || addressString
    }</b><br/>${addressTitle ? address.name || addressString : ''}</div>`
    const infoWindowObj = new this.google.maps.InfoWindow({
      content: infowindow,
    })
    this.infoWindows.push(infoWindowObj)
    return infoWindowObj
  }
  makeVehicleInfoWindow(vehicle) {
    const timestamp = this.formatTimestamp(vehicle.receivedDate)
    const eldTypeLabel =
      vehicle.eldType ??
      (this.eldTypes.find((eldType) => eldType.id === vehicle.eldTypeId)
        ?.label ||
        '')

    const infowindow = `
        <table>
          <tr>
            <td colspan=2>${vehicle.vehicleName}</td>
          </tr>
          <tr>
            <td colspan=2><hr/></td>
          </tr>
          ${
            this.isEnterpriseAdmin || this.isModePublic
              ? `<tr>
              <td>Reservation ID:</td>
              <td>${vehicle.reservationId}</td>
            </tr>`
              : ''
          }
          ${
            this.isEnterpriseAdmin
              ? `<tr>
              <td>Operator:</td>
              <td>${vehicle.operatorName}</td>
            </tr>
            <tr>
              <td>Contract:</td>
              <td>${vehicle.contractName}</td>
            </tr>
            <tr>
              <td>Device ID:</td>
              <td>${vehicle.deviceId}</td>
            </tr>
            <tr>
              <td>ELD:</td>
              <td>${eldTypeLabel}</td>
            </tr>
            <tr>
              <td>Timestamp:</td>
              <td>${timestamp}</td>
            </tr>
          `
              : ''
          }
          <tr>
            <td>Vehicle Type:</td>
            <td>${vehicle.vehicleType}</td>
          </tr>
          <tr>
            <td>Traveling:</td>
            <td>
              ${vehicle.gpsSpeed || 0} mph ${
      vehicle.direction || this.getVehicleDirection(vehicle.heading)
    }
            </td>
          </tr>
          ${
            vehicle.isADACompliant
              ? `<tr>
              <td>ADA Compliant</td>
            </tr>`
              : ''
          }
        </table>
      `
    const infoWindowObj = new this.google.maps.InfoWindow({
      content: infowindow,
    })
    this.infoWindows.push(infoWindowObj)
    return infoWindowObj
  }
  handleCenterChanged(): void {
    this.customPosition = true
  }
  handleRecenter(): void {
    this.fitVehiclesInBounds()
    setTimeout(() => {
      this.customPosition = false
    }, 0)
  }
  fitVehiclesInBounds(bounds = null): void {
    if (bounds === null) {
      bounds = new this.google.maps.LatLngBounds()
      for (const marker of this.vehicleMarkers) {
        bounds.extend(marker.getPosition())
      }

      if (!this.isEnterpriseAdmin) {
        for (const marker of this.stopMarkers) {
          bounds.extend(marker.getPosition())
        }
      }
    }
    if (!this.isEnterpriseAdmin) {
      for (const filteredVehicle of this.filteredVehicles) {
        const vehicle = this.findVehicleById(filteredVehicle.trak4DeviceId)
        const current = new this.google.maps.LatLng(
          vehicle.location.lat,
          vehicle.location.lng
        )
        bounds.extend(current)

        if (vehicle.previousPosition) {
          const previous = new this.google.maps.LatLng(
            vehicle.previousPosition.lat,
            vehicle.previousPosition.lng
          )
          bounds.extend(previous)
        }
      }
    }
    this.bounds = bounds
    this.map.fitBounds(this.bounds)
    this.customPosition = false
  }
  formatTimestamp(timestamp: string): string {
    const datetime = DateTime.fromISO(timestamp)
    return `${datetime.toLocaleString(
      DateTime.DATE_SHORT
    )} • ${datetime.toLocaleString(DateTime.TIME_SIMPLE)}`
  }

  openInfoWindow(marker): void {
    this.infoWindowContent = marker.vehicle
    this.infoWindowPosition = marker.position
    this.infoWindowOpened = true
  }

  closeInfoWindow() {
    this.infoWindowOpened = false
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors';

.map {
  width: 100vw;
  height: 100%;
  z-index: 0;
  &.public {
    height: calc(100vh - 64px);
    min-height: calc(100vh - 64px);
  }
}

.recenter-button {
  z-index: 3;
  position: absolute;
  right: 12px;
  top: 12px;
  background: $white;
  border: 1px solid $border-gray !important;
  width: 48px;
  height: 48px;
  color: $primary !important;
  &.disabled {
    color: $border-gray !important;
    cursor: default !important;
  }
  &.public {
    margin-top: 68px;
    &.mobile {
      margin-top: 58px;
    }
  }
}

::v-deep button.gm-ui-hover-effect {
  display: none !important;
}

#markerLayer img {
  animation: pulse 0.5s infinite alternate;
  -webkit-animation: pulse 0.5s infinite alternate;
  transform-origin: center;
  -webkit-transform-origin: center;
}

@media only screen and (min-width: 600px) {
  .map {
    position: absolute;
    left: 400px;
    width: calc(100vw - 400px);
    &.table-view {
      position: absolute;
      left: 1000px;
      width: calc(100vw - 1000px);
    }
  }
}
@media only screen and (max-width: 599px) {
  .map {
    &.public {
      height: calc(100vh - 180px);
      min-height: calc(100vh - 180px);
    }
  }
}
</style>

<style lang="scss">
@import '@/scss/colors';
@media only screen and (max-width: 599px) {
  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    display: none !important;
  }
}
</style>
