<template>
  <div
    class="border-radius-2x border-2 border-solid padding-a-3 shadow-actionable-card"
    :class="{
      'border-primary': selected,
      'border-border-gray': !selected,
      'cursor-pointer': isSelectable,
      'cursor-default': !isSelectable,
    }"
    @click="select"
  >
    <RecommendedBanner v-if="showRecommendedBanner" />
    <div
      :class="{
        'd-flex flex-column': $vuetify.breakpoint.xsOnly,
        'd-flex': $vuetify.breakpoint.smAndUp,
      }"
    >
      <CURadioButton
        class="margin-r-2 margin-b-2"
        large
        :selected="selected"
      />
      <div
        class="d-flex"
        :class="{
          'flex-row': $vuetify.breakpoint.xsOnly,
          'flex-column min-w-136 w-136 max-w-136 margin-r-4':
            $vuetify.breakpoint.smAndUp,
        }"
      >
        <VehicleOptionVehicleList :vehicles="vehicles" />
        <template v-if="$vuetify.breakpoint.xsOnly" >
          <div class="d-flex grow" />
          <SelfServeVehiclePricingCardPrice v-if="isSelfServe" :quote="quote" />
        </template>
        <VehicleOptionImage v-else class="margin-y-2" :vehicle-type-keys="vehicleTypeKeys" />
      </div>
      <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex grow" />
      <div
        class="d-flex flex-column"
        :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
      >
        <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex margin-t-n1">
          <VehicleOptionPassengers :vehicles="vehicles" />
          <div v-if="isSelfServe" class="d-flex grow" />
          <SelfServeVehiclePricingCardPrice v-if="isSelfServe" :quote="quote" />
        </div>
        <template v-else>
          <VehicleOptionPassengers :vehicles="vehicles" />
          <VehicleOptionImage class="margin-y-2" :vehicle-type-keys="vehicleTypeKeys" />
        </template>
        <VehicleOptionDescription :vehicle-type-keys="vehicleTypeKeys" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import CURadioButton from '@/components/CURadioButton.vue'
import RecommendedBanner from '@/components/RecommendedBanner.vue'
import SelfServeVehiclePricingCardPrice from '@/components/SelfServeVehiclePricingCardPrice.vue'
import VehicleOptionDescription from '@/components/VehicleOptionDescription.vue'
import VehicleOptionImage from '@/components/VehicleOptionImage.vue'
import VehicleOptionPassengers from '@/components/VehicleOptionPassengers.vue'
import VehicleOptionVehicleList from '@/components/VehicleOptionVehicleList.vue'

import selfServe from '@/store/modules/selfServe'
import { QuoteDetailV2, WizardVehicle } from '@/models/dto'
import { PricingMethod, VehicleTypeKey } from '@/utils/enum'
import { isQuoteSelfServeAndNeedsManualAttention } from '@/utils/quote'

@Component({
  components: {
    CURadioButton,
    RecommendedBanner,
    SelfServeVehiclePricingCardPrice,
    VehicleOptionDescription,
    VehicleOptionImage,
    VehicleOptionPassengers,
    VehicleOptionVehicleList,
  },
})
export default class VehicleOptionCard extends Vue {
  @Prop({ type: Object, default: () => {}, required: false }) readonly quote: QuoteDetailV2
  @Prop({ type: Array, default: () => [], required: true }) readonly vehicles: WizardVehicle[]
  @Prop({ type: Boolean, default: false }) readonly selected: boolean

  get isSelfServe(): boolean {
    return !!this.quote?.isSelfServe
  }

  get isSelectable(): boolean {
    if (this.isSelfServe) {
      return (
        !!this.quote?.bids?.[0]?.totalAmount ||
        isQuoteSelfServeAndNeedsManualAttention(this.quote)
      )
    }
    return true
  }

  get showRecommendedBanner(): boolean {
    if (!this.isSelfServe) {
      return false
    }

    const { isRecommended, pricingMethod } = this.quote || {}
    const isIncludedPricingMethod = [PricingMethod.Bids, PricingMethod.Category].includes(pricingMethod)

    return isIncludedPricingMethod && isRecommended
  }

  get vehicleTypeKeys(): VehicleTypeKey[] {
    return this.vehicles.map(({vehicleTypeKey}) => vehicleTypeKey)
  }

  select(): void {
    if (!this.isSelectable) {
      return
    }
    this.$emit('select')
  }
}
</script>
