<template>
  <div class="d-flex w-full align-center">
    <div
      class="d-flex font-13  font-bold align-center justify-center border-radius-round background-primary text-white h-32 w-32 margin-r-2"
    >
      {{ driverInitials(driver) }}
    </div>
    <div>
      {{ driverAbbreviatedName(driver) }}
    </div>
    <v-spacer />
    <div class="d-flex grow" />
    <a
      v-if="!hideNumber"
      :id="`call-driver-dialog-link-${driver.driverId}`"
      :tel="`${driver.phone}`"
      :href="`tel:${driver.phone}`"
      class="d-flex align-center"
    >
      <span class="text-primary">
        {{ phoneFormatFilter(driver.phone) }}
      </span>
    </a>
  </div>
</template>
<script lang="ts">
import { Driver } from '@/models/dto'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { phoneFormatFilter } from '@/utils/phone'

@Component({})
export default class DriverInfoContact extends Vue {
  @Prop({ type: Object, required: true }) readonly driver: Driver
  @Prop({ type: Boolean }) readonly hideNumber: boolean

  phoneFormatFilter = phoneFormatFilter

  driverAbbreviatedName(driver: Driver): string {
    const { firstName, lastName } = driver
    if (!firstName || !lastName) {
      return ''
    }
    return `${firstName} ${lastName[0]}.`
  }

  driverInitials(driver: Driver): string {
    const { firstName, lastName } = driver
    if (!firstName || !lastName) {
      return ''
    }
    return `${firstName[0]}${lastName[0]}`
  }
}
</script>
