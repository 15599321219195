<template>
  <div :class="{ 'w-full padding-b-4': $vuetify.breakpoint.xsOnly }">
    <v-icon
      v-if="$vuetify.breakpoint.xs"
      class="panel-handle w-full color-gray"
      color="grayMediumLight"
      :class="{ flipped: drawerOpen }"
      @click="drawerOpen = !drawerOpen"
    >
      keyboard_arrow_up
    </v-icon>

    <SkeletonBox
      v-if="loading"
      class="h-18 w-200 margin-t-2 margin-x-0 margin-b-4"
    />

    <div v-else class="font-24 font-bold">
      {{ reservationTitle }}
    </div>

    <SkeletonBox v-if="loading" class="h-18 w-200" />
    <h4 class="text-midnight-gray-500 font-16 text-start" v-else>
      ID {{ managedId }} &#x2022; {{ displayDate }}
    </h4>

    <SkeletonBox v-if="loading" class="h-18 w-200 margin-t-2" />
    <div v-else class="d-flex margin-t-2 justify-start">
      <div
        v-if="!isUserSMSVerified"
        class="d-flex cursor-pointer border-l-0 border-t-0 border-b-0 border-r-1 border-solid border-midnight-gray-50 margin-r-2"
        @click="smsVerificationDialogIsOpen = true"
      >
        <CUIcon
          view-box="0 0 16 16"
          color="primary"
          class="w-16 h-16 margin-r-1"
        >
          chat_bubble
        </CUIcon>
        <h2 class="text-primary font-14 padding-r-2">Sign Up for Trip Alerts</h2>
      </div>
      <div class="d-flex cursor-pointer" @click="shareLiveTrackingDialogIsOpen = true">
        <CUIcon
          view-box="0 0 16 16"
          color="primary"
          class="w-16 h-16 margin-r-1"
        >
          link
        </CUIcon>
        <h2 class="text-primary font-14">
          {{ $t('liveTracking.itinerary.SHARE_TRIP') }}
        </h2>
      </div>
    </div>

    <ReservationDetailVerifySMS
      v-if="smsVerificationDialogIsOpen"
      :reservation-hash="reservationHash"
      confirmation-header="Live Trip Alerts"
      confirmation-subheader="Sign up to receive SMS updates on your bus location to ensure a smooth pickup on trip day."
      :show-confirm-checkbox="false"
      :customer="customer"
      :anonymous="!isUserLoggedIn"
      closeable
      @skip="smsVerificationDialogIsOpen = false"
    />

    <ShareLiveTrackingDialog
      v-model="shareLiveTrackingDialogIsOpen"
      :reservation-hash="reservationHash"
    />

    <div
      class="border-t-1 border-r-0 border-l-0 border-b-0 border-solid border-midnight-gray-50 margin-y-4"
    ></div>

    <v-expand-transition>
      <div
        v-show="shouldShowTimeline"
        class="timeline-container"
        :class="{ 'overflow-auto': $vuetify.breakpoint.xsOnly }"
      >
        <LiveTrackingVehicleSelector class="margin-b-3" />
        <CUItinerarySkeletonLoader v-if="loading" />
        <CUItinerary
          v-else-if="tracking.reservation"
          :class="{ 'max-h-one-half-vh': $vuetify.breakpoint.xsOnly }"
        >
          <LiveTrackingItineraryItem
            v-for="(stop, stopIndex) in tracking.orderedStops"
            :key="`${stop.stopId}-${stopUpdateCount}`"
            :status="getItineraryItemStatus(stop)"
            :color="activeVehicleColor"
          >
            <LiveTrackingItineraryStop
              :stop="stop"
              :index="stopIndex"
              :status-details="statusDetails"
              :color="activeVehicleColor"
              :is-multi-day-trip="isMultiDayTrip"
            />
          </LiveTrackingItineraryItem>
        </CUItinerary>
        <div class="w-full d-flex justify-start margin-y-2">
          <h2
            class="text-primary cursor-pointer font-14"
            @click="openReservationDetail"
          >
            {{ $t('liveTracking.itinerary.VIEW_RESERVATION') }}
          </h2>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import LiveTrackingItineraryStop from '@/components/LiveTrackingItineraryStop.vue'
import LiveTrackingVehicleSelector from '@/components/LiveTrackingVehicleSelector.vue'
import LiveTrackingItineraryItem from '@/components/LiveTrackingItineraryItem.vue'
import ShareLiveTrackingDialog from '@/components/ShareLiveTrackingDialog.vue'
import ReservationDetailVerifySMS from '@/components/ReservationDetailVerifySMS.vue'
import CUItinerary from '@/components/CUItinerary.vue'
import CUItinerarySkeletonLoader from '@/components/CUItinerarySkeletonLoader.vue'
import { TrackingStop } from '@/models/dto/TrackingStop'
import auth from '@/store/modules/auth'
import customer from '@/services/customer'
import tracking from '@/store/modules/tracking'
import trackingVehicleStore from '@/store/modules/trackingVehicles'
import { formatReservationPickupDestinationText } from '@/utils/string'
import { LiveTrackingStatus } from '@/utils/enum'
import { DEFAULT_MAP_THEME_COLOR } from '@/views/LiveTracking.vue'
import { Customer } from '@/models/dto'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({
  components: {
    CUItinerary,
    CUItinerarySkeletonLoader,
    LiveTrackingItineraryStop,
    LiveTrackingVehicleSelector,
    LiveTrackingItineraryItem,
    ReservationDetailVerifySMS,
    ShareLiveTrackingDialog,
    SkeletonBox,
  },
})
export default class LiveTrackingItinerary extends Vue {
  @Prop({ type: Object, required: true }) readonly date!: Date
  @Prop({ type: Boolean, required: false }) readonly loading!: boolean
  @Prop({ type: Object, required: true }) readonly statusDetails: any
  @Prop({ type: Boolean, required: true }) readonly refreshMap!: boolean

  drawerOpen = false
  tracking = tracking
  trackingVehicleStore = trackingVehicleStore
  stopUpdateCount = 0
  smsVerificationDialogIsOpen = false
  shareLiveTrackingDialogIsOpen = false

  @Watch('refreshMap')
  onRefreshMapChanged(value): Promise<void> {
    if (!value) {
      return
    }
    this.stopUpdateCount += 1
  }

  mounted(): void {
    this.checkForOpenModals()
  }

  get customer(): Customer {
    return auth.customer
  }

  get isUserLoggedIn(): boolean {
    return !!auth.user || !!auth.customerAccount
  }

  get isUserSMSVerified(): boolean {
    return auth.customer?.smsConfirmed
  }

  get shouldShowTimeline(): boolean {
    return this.drawerOpen || this.$vuetify.breakpoint.smAndUp
  }

  get displayDate(): string {
    return this.date?.toLocaleString(DateTime.DATE_FULL)
  }

  get managedId(): string {
    return tracking.reservation?.managedId
  }

  get reservationHash(): string {
    return tracking.reservation?.hash
  }

  get reservationCities(): string {
    return formatReservationPickupDestinationText(
      tracking.reservation,
      this.$t('common.TO')
    )
  }

  get reservationTitle(): string {
    const routeName = tracking.reservation?.routeName
    return (
      routeName ||
      this.reservationCities ||
      this.$t('liveTracking.itinerary.DEFAULT_RESERVATION_TITLE', {
        managedId: this.managedId,
      })
    )
  }

  get isUpcoming(): boolean {
    return ['upcoming', 'hold'].includes(tracking.reservation.reservationStatus)
  }

  get isInProgress(): boolean {
    return tracking.reservation.reservationStatus === 'started'
  }

  get activeVehicleColor(): string {
    return (
      trackingVehicleStore?.activeTrackingVehicle?.color ||
      DEFAULT_MAP_THEME_COLOR
    )
  }

  get isMultiDayTrip(): boolean {
    const stops = tracking.reservation.stops
    const firstPickupDate = DateTime.fromISO(stops[0]?.pickupDatetime)
    if (!firstPickupDate) {
      return false
    }
    return stops.some((stop) => {
      if (stop.pickupDatetime) {
        return !firstPickupDate.hasSame(
          DateTime.fromISO(stop.pickupDatetime),
          'day'
        )
      } else if (stop.dropoffDatetime) {
        return !firstPickupDate.hasSame(
          DateTime.fromISO(stop.dropoffDatetime),
          'day'
        )
      }
      return false
    })
  }

  openReservationDetail(): void {
    if (tracking.reservation?.reservationId) {
      const route = this.$router.resolve({
        name: 'reservation-detail',
        params: { id: tracking.reservation?.reservationId },
      })
      window.open(route.href, '_blank')
    }
  }

  getItineraryItemStatus(stop: TrackingStop): string {
    if (tracking.isFinished || tracking.isComplete(stop)) {
      return LiveTrackingStatus.Completed
    }
    if (this.isInProgress && tracking.isNext(stop)) {
      return LiveTrackingStatus.InProgress
    }
    return LiveTrackingStatus.Upcoming
  }

  checkForOpenModals(): void {
    const { verifyPhoneNumber, shareLiveTracking } = this.$route?.query

    if (verifyPhoneNumber) {
      this.smsVerificationDialogIsOpen = true
      return
    }

    if (shareLiveTracking) {
      this.shareLiveTrackingDialogIsOpen = true
      return
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors';
.panel-handle {
  transform: rotate(0deg);
  transition: transform 0.2s linear;
  &.flipped {
    transform: rotate(-180deg);
    transition: transform 0.2s linear;
  }
  &.v-icon {
    &::before,
    &::after {
      display: none;
    }
  }
}
</style>
