<template>
  <CUModal v-model="dialog" max-width="518px">
    <template #activator="{ on }">
      <v-list-item :id="`edit-team-${index}`" @click="() => {}" v-on="on">
        <v-list-item-title>
          Edit Team
        </v-list-item-title>
      </v-list-item>
    </template>
    <template #title>Edit Team</template>
    <template #text>
      <div class="d-flex flex-column">
        <div class="d-flex flex-column align-center">
          <v-col cols="auto">
            <img
              width="72"
              class="border-radius-round"
              :src="team.lightLogoUrl"
            />
          </v-col>
          <v-col cols="12" class="text-center">
            <a @click="changeImage = !changeImage">
              {{ changeImage ? 'Cancel' : 'Change Image' }}
            </a>
            <v-expand-transition>
              <FileUploader
                v-if="changeImage"
                id="edit-team-dialog-input-image-upload"
                :value="team.teamLogoImage"
                @input="$emit('upload-image', $event)"
              />
            </v-expand-transition>
          </v-col>
        </div>
        <v-form ref="form" v-model="formIsValid">
          <v-row no-gutters>
            <v-col cols="12">
              <label for="title">Team Name</label>
              <v-text-field
                id="edit-team-dialog-text-team-name"
                ref="teamName"
                v-model="formData.teamName.value"
                v-bind="formData.teamName"
                required
                validate-on-blur
                outlined
                tabindex="3"
              />
            </v-col>
            <v-col cols="12">
              <label for="title">Address</label>
              <AutoCompleteAddress
                v-if="dialog"
                id="edit-team-dialog-text-address"
                :initial-search="formData.address.initialSearch"
                manual-control
                clearable
                clear-icon="replay"
                @place-selected="selectAddress"
                @place-cleared="selectAddress({})"
              />
            </v-col>
            <v-col cols="12">
              <label for="email">Primary Email Address</label>
              <v-text-field
                id="edit-team-dialog-text-email"
                ref="email"
                v-model="formData.email.value"
                v-bind="formData.email"
                required
                validate-on-blur
                outlined
                tabindex="3"
              />
            </v-col>
            <v-col cols="12">
              <label for="title">Phone Number</label>
              <v-text-field
                id="edit-team-dialog-text-phone"
                ref="phoneNumber"
                v-model="formData.phoneNumber.value"
                v-mask="['(###) ###-####', '+## ## #### ####']"
                v-bind="formData.phoneNumber"
                required
                validate-on-blur
                outlined
                tabindex="3"
              />
            </v-col>
          </v-row>
          <AlertBox :active="!!errorMessage" type="error" class="margin-b-3">
            {{ errorMessage }}
          </AlertBox>
        </v-form>
      </div>
    </template>
    <template #actions>
      <div class="d-flex grow" />
      <v-btn
        id="edit-team-dialog-btn-cancel"
        tabindex="6"
        small
        text
        class="margin-r-2"
        @click="dialog = false"
      >
        Cancel
      </v-btn>
      <v-btn
        id="edit-team-dialog-btn-submit"
        class="primary"
        small
        tabindex="5"
        @click="send"
      >
        Update Team
      </v-btn>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import customerAccount from '@/services/customerAccount'
import { Team } from '@/models/dto'
import AutoCompleteAddress from '@/components/AutoCompleteAddress.vue'
import FileUploader from '@/components/FileUploader.vue'
import AlertBox from '@/components/AlertBox.vue'

@Component({
  components: {
    AutoCompleteAddress,
    FileUploader,
    AlertBox,
  },
})
export default class OrganizationEditTeamDialog extends Vue {
  @Prop({ type: Object, default: () => {} }) readonly team: Team
  @Prop({ type: Number }) readonly index: number

  @Watch('dialog')
  dialogChanged(newVar: boolean): void {
    if (!newVar) {
      this.resetValues()
    } else {
      this.setup()
    }
    if (!this.dialog) {
      this.$emit('close')
    }
  }

  formIsValid = false
  dialog = false
  success = false
  changeImage = false
  errorMessage = ''

  formData: any = {
    teamName: {
      value: '',
      rules: [(v) => !!v || 'Team name is required'],
    },
    address: {
      value: '',
      rules: [(v) => !!v || 'Address is required'],
    },
    email: {
      value: '',
      rules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    },
    phoneNumber: {
      value: '',
      rules: [(v) => !!v || 'Phone number is required'],
    },
    teamLogoImage: null,
  }

  mounted(): void {
    this.setup()
  }

  setup(): void {
    this.success = false
    this.changeImage = false
    this.formData.teamLogoImage = null
    this.formData.teamName.value = this.team.name
    this.formData.address.value = this.team.address
    this.formData.address.initialSearch = this.team.address?.addressName
    this.formData.email.value = this.team.email
    this.formData.phoneNumber.value = this.team.phone
  }
  resetValues(): void {
    const form = this.$refs.form as any
    form.reset()
  }
  backToSite(): void {
    this.success = false
    this.setup()
    this.dialog = false
  }
  async send(): Promise<void> {
    const form = this.$refs.form as any
    if (!form.validate()) {
      return
    }
    this.errorMessage = ''
    const payload = {
      name: this.formData.teamName.value,
      address: this.formData.address.value,
      email: this.formData.email.value,
      phone: this.formData.phoneNumber.value,
    }
    try {
      await customerAccount.edit(this.team.customerAccountId, payload)
      if (this.team.teamLogoImage && this.changeImage) {
        const bodyFormData = new FormData()
        bodyFormData.append('files', this.team.teamLogoImage[0])
        await customerAccount.uploadLightLogo(
          this.team.customerAccountId,
          bodyFormData
        )
      }
      this.$emit('success')
      this.dialog = false
      // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const errorMessage = e.response?.data?.message
      this.errorMessage = errorMessage
    }
  }
  selectAddress({ place }): void {
    this.formData.address.value = place
  }
}
</script>
