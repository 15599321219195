import { inject, InjectionKey } from 'vue'
import type { GoogleAnalyticsPlugin } from "@/shims-ga4";

export const injectionKey = Symbol('Plugin for Google Analytics 4, our integration for tracking user actions.') as InjectionKey<GoogleAnalyticsPlugin>

export const useGoogleAnalytics = () => {
  return inject<GoogleAnalyticsPlugin>(injectionKey)
}

export enum Event {
  ViewStart = 'sb_view_start',
  TripType = 'sb_trip_type',
  Itinerary = 'sb_itinerary',
  TripDetails = 'sb_trip_details',
  ViewOptions = 'sb_view_options',
  VehicleType = 'sb_vehicle_type',
  QuoteCreate = 'create_quote',
}
