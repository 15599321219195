<template>
  <div>
    <v-card class="margin-y-3 margin-x-0">
      <div class="margin-y-3">
        <div class="d-flex align-center justify-start">
          <CUIcon
            view-box="0 0 28 28"
            color="yellow"
            width="28px"
            height="28px"
            class="margin-l-3 margin-r-1 cursor-default"
          >
            warning
          </CUIcon>
          <div class="w-full d-flex align-center justify-space-between">
            <p class="font-bold margin-t-0">
              Trip Modification Requested
            </p>
            <TripModificationRequestDialog
              :managed-id="managedId"
            >
              <template #activator="{ on, attrs }">
                <p
                  class="margin-t-0 margin-r-4 text-primary font-bold cursor-pointer"
                  v-bind="attrs"
                  v-on="on"
                >
                  See more
                </p>
              </template>
            </TripModificationRequestDialog>
          </div>
        </div>
      </div>
    </v-card>
    <TripModificationRequestDialog />
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'
import TripModificationRequestDialog from '@/components/TripModificationRequestDialog.vue'

@Component({
  components: {
    TripModificationRequestDialog
  }
})
export default class ReservationDetailModificationRequested extends Vue {
  @Prop({ required: true, type: String }) readonly managedId: string

}
</script>
