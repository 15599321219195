<template>
  <div class="d-flex flex-row jusify-start">
    <CUIcon
      class="cursor-default margin-r-2 min-w-20"
      color="primary"
      viewBox="0 0 24 24"
      width="20px"
      height="20px"
    >
      {{ amenity.icon }}
    </CUIcon>
    <p class="margin-t-0">{{ amenity.title }}</p>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AmenityItem } from '@/models/AmenityItem'
@Component({})
export default class TripAmenityCardItem extends Vue {
  @Prop({ type: Object, required: true }) readonly amenity!: AmenityItem
}
</script>

<style scoped>
div {
  white-space: nowrap;
}
</style>
