<template>
  <a
    :href="`tel:${supportNumber}`"
    class="padding-a-0"
    :style="{ 'margin-top': '46px' }"
  >
    <div
      :class="{
        'w-36 h-36 background-primary border-radius-round position-relative margin-t-n2 margin-r-4':
          $vuetify.breakpoint.mdAndDown,
      }"
    >
      <CUIcon
        :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'primary'"
        view-box="0 0 24 24"
        :class="{
          'position-absolute top-6 left-6': $vuetify.breakpoint.mdAndDown,
        }"
        :style="{ float: 'left' }"
      >
        phone
      </CUIcon>
      <p
        v-if="!$vuetify.breakpoint.mdAndDown"
        class="margin-y-0 margin-r-6 margin-l-2 font-14"
        :style="{ float: 'left' }"
      >
        {{ formattedSupportNumber }}
      </p>
    </div>
  </a>
</template>

<script lang="ts">
import auth from '@/store/modules/auth'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class SupportNumber extends Vue {
  get supportNumber(): string {
    return (
      getSupportNumber(auth.customer?.isElite)
    )
  }
  get formattedSupportNumber(): string {
    return phoneFormatFilterHyphens(this.supportNumber)
  }
}
</script>
