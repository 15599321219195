<template>
  <div class="display-flex flex-wrap align-center justify-start w-full">
    <CUIcon view-box="0 0 24 24" :width="18" :height="18" class="margin-x-2">
      flight
    </CUIcon>
    <p class="margin-t-0 font-14 ">
      {{ formattedFlightInformation }}
    </p>
    <div
      v-if="
        (tripStatus === 'upcoming' || tripStatus === 'started') &&
        allowReservationManagement
      "
      class="margin-l-2"
    >
      <a
        id="risk-item-modal-link"
        class="font-bold margin-t-3 font-14 "
        @click.stop="openFlightInfoModal"
      >
        {{ $t('common.EDIT') }}
      </a>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Stop } from '../models/dto'
import flight from '../store/modules/flight'

@Component({})
export default class TripInfoItineraryStopFlightInfo extends Vue {
  @Prop({ required: true }) stop!: Stop
  @Prop({ required: true }) tripStatus!: string
  @Prop({ required: true }) allowReservationManagement!: boolean

  get formattedFlightInformation(): string {
    const flightInfo = this.stop?.flightInformation
    if (!flightInfo) {
      return ''
    }
    return `${flightInfo?.flightNumber} — ${flightInfo?.airline}`
  }

  openFlightInfoModal(): void {
    flight.open({
      flightInformation: this.stop?.flightInformation,
      stop: this.stop,
    })
  }
}
</script>
