<template>
  <div>
    <h3>
      {{ $t('quoteDetail.operatorInfo.AMENITIES') }}
    </h3>

    <p v-if="amenities && amenities.length">
      <v-row dense>
        <v-col
          v-for="(amenity, amenityIndex) in amenities"
          :key="`${amenity.id}-${amenityIndex}`"
          cols="6"
          sm="4"
        >
          <div class="d-flex align-center">
            <CUIcon
              view-box="0 0 24 24"
              class="margin-r-2"
              :class="{
                'w-16 h-16': amenity.key !== 'spab',
                'w-24 h-24': amenity.key === 'spab',
              }"
              color="gray-medium-dark"
              left
              :style="{
                marginTop: '-1px',
                marginLeft: amenity.key !== 'spab' ? 0 : '-4px',
              }"
            >
              {{ convertAmenityKeyToIconName(amenity.key) }}
            </CUIcon>

            {{ amenity.label }}
          </div>
        </v-col>
      </v-row>
    </p>
    <p v-else>
      {{ $t('quoteDetail.operatorInfo.NO_INFORMATION_PROVIDED') }}
    </p>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AmenityType } from '@/models/dto'
import { convertAmenityKeyToIconName } from '../utils/string'

@Component({})
export default class ProviderInfoVehicleTypeAmenities extends Vue {
  @Prop({ type: Array, required: true })
  readonly amenities: AmenityType[]

  convertAmenityKeyToIconName = convertAmenityKeyToIconName
}
</script>
