import { render, staticRenderFns } from "./OrganizationAccountInfo.vue?vue&type=template&id=06136401"
import script from "./OrganizationAccountInfo.vue?vue&type=script&lang=ts"
export * from "./OrganizationAccountInfo.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports