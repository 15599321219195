<template>
  <div
    v-if="selectedTripRequirements.length > 0"
    class="d-flex align-center justify-start row margin-t-n1 margin-b-2"
  >
    <div
      v-for="(selectedTripRequirement,
      selectedTripRequirementIndex) in selectedTripRequirements"
      :key="`${selectedTripRequirement}-trip-requirement-${selectedTripRequirementIndex}-${$vuetify.breakpoint.xs}`"
      class="padding-y-1"
      :class="{
        'col-6': selectedTripRequirement.key !== SpecialRequirementsTypeKey.VehicleNeededEntireTrip,
        'col-12': selectedTripRequirement.key === SpecialRequirementsTypeKey.VehicleNeededEntireTrip,
      }"
    >
      <div class="d-flex align-center">
        <div
          class="d-flex align-center border-radius-regular padding-x-1"
          :class="{
            'background-modified-trip':
              selectedTripRequirement.modified,
          }"
        >
          <CUIcon
            viewBox="0 0 24 24"
            width="20"
            height="20"
            color="gray-subtle-text-2"
            class="cursor-default margin-r-1"
          >
            {{ selectedTripRequirement.icon }}
          </CUIcon>
          <p class="margin-t-0">
            {{ selectedTripRequirement.title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUIcon from '@/components/CUIcon.vue'
import {
  SpecialRequirementsTypeKey,
  SpecialRequirementsTypeLabel,
} from '@/utils/enum'

@Component({
  components: {
    CUIcon,
  },
})
export default class TripReviewRequirements extends Vue {
  @Prop({ type: Boolean, required: true }) readonly ada: boolean
  @Prop({ type: Boolean, default: false }) readonly adaChanged?: boolean
  @Prop({ type: Boolean, required: true }) readonly spab: boolean
  @Prop({ type: Boolean, default: false }) readonly spabChanged?: boolean
  @Prop({ type: Boolean, required: true })
  readonly vehicleNeededEntireTrip: boolean
  @Prop({ type: Boolean, default: false })
  readonly vehicleNeededEntireTripChanged?: boolean

  SpecialRequirementsTypeKey = SpecialRequirementsTypeKey

  get selectedTripRequirements(): Array<{
    icon: string
    title: string
    key: string
    modified: boolean
  }> {
    return [
      {
        icon: 'bus_alert',
        title: SpecialRequirementsTypeLabel.VehicleNeededEntireTrip,
        condition: this.vehicleNeededEntireTrip,
        key: SpecialRequirementsTypeKey.VehicleNeededEntireTrip,
        modified: this.vehicleNeededEntireTripChanged,
      },
      {
        icon: 'accessible',
        title: SpecialRequirementsTypeLabel.ADA,
        condition: this.ada,
        key: SpecialRequirementsTypeKey.ADA,
        modified: this.adaChanged,
      },
      {
        icon: 'school',
        title: SpecialRequirementsTypeLabel.SPAB,
        condition: this.spab,
        key: SpecialRequirementsTypeKey.SPAB,
        modified: this.spabChanged,
      },
    ]
      .filter(({ condition }) => condition)
      .map(({ icon, title, key, modified }) => {
        return { icon, title, key, modified }
      })
  }
}
</script>
