<template>
  <v-col class="shrink">
    <CUSkeletonLoader v-if="loading" width="101px" type="button" />
    <v-btn v-else color="primary" small @click="$emit('input', true)">
      <v-badge
        :content="filterCount"
        :value="filterCount"
        overlap
        color="red"
        offset-x="20"
      >
        <CUIcon color="white" class="margin-r-2">filter</CUIcon>
      </v-badge>
      Filter
    </v-btn>
  </v-col>
</template>

<script lang="ts">
import { Component, Vue, Model, Prop } from 'vue-property-decorator'
import { EventBus } from '@/utils/eventBus'

@Component
export default class CUDataTableFilterButton extends Vue {
  @Model('input') readonly value!: boolean | null
  @Prop({ required: true }) readonly loading!: boolean

  filterCount = 0

  mounted(): void {
    EventBus.$on('add-filter', (e) => {
      this.filterCount++
    })

    EventBus.$on('remove-filter', (e) => {
      if (this.filterCount > 0) {
        this.filterCount--
      }
    })
  }
}
</script>
