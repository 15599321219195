<template>
  <div
    class="main-container position-relative margin-t-12 margin-b-6 margin-x-0"
    :style="$vuetify.breakpoint.lgAndUp ? 'transform: translate(-30px);' : ''"
  >
    <h1 class="font-30  text-center">
      You haven't booked anything yet!
    </h1>
    <h2 class="font-26  font-book text-center">
      Book now to take advantage of great features like...
    </h2>
    <div
      class="position-relative top-0"
      :style="$vuetify.breakpoint.md ? 'left: -130px;' : ''"
    >
      <v-card
        class="main-card padding-a-8 margin-x-auto margin-t-6 z-1"
        :class="$vuetify.breakpoint.md ? 'main-card--medium' : ''"
      >
        <v-card
          v-if="reservation"
          class="border-1 border-solid border-border-gray z-1 margin-b-8"
          flat
        >
          <v-row no-gutters align="stretch">
            <v-col cols="auto">
              <img
                src="@/assets/images/noReservations/stock_bus.png"
                width="200"
                height="100%"
                class="z-0 margin-a-0 object-fit-cover"
                :style="{ 'border-radius': '4px 0px 0px 4px' }"
              />
            </v-col>

            <v-col
              align-self="center"
              class="margin-y-3 margin-r-6"
              :style="{ 'margin-left': '6px' }"
            >
              <v-row align="stretch" justify="center" dense>
                <v-col>
                  <v-col cols="auto" align-self="center">
                    <h2 class="font-18 ">
                      {{ reservation.company.companyDBAName }}
                    </h2>
                    <v-row
                      class="text-gray margin-t-0 margin-x-0 margin-b-3 padding-a-0 font-12 "
                    >
                      <span v-if="reservation.company.rating">
                        <v-rating
                          v-model="reservation.company.ratingRounded"
                          color="yellow"
                          :style="{ 'margin-top': '-1px' }"
                          half-increments
                          readonly
                          dense
                          size="18"
                        />
                      </span>
                      <span
                        v-if="
                          reservation.company.rating &&
                          reservation.company.rating !== 0
                        "
                      >
                        {{ reservation.company.rating }}
                      </span>
                      <span
                        v-if="
                          reservation.company.reviewCount &&
                          reservation.company.reviewCount !== 0
                        "
                      >
                        {{ `, ${reservation.company.reviewCount} reviews` }}
                      </span>
                    </v-row>
                    <v-row no-gutters class="min-h-22">
                      <div
                        v-for="(amenity, i) in reservation.company.amenities"
                        :key="`${amenity.icon}-${i}`"
                        :style="{ 'margin-right': '3px' }"
                      >
                        <v-icon :color="amenity.color" small>
                          {{ amenity.icon }}
                        </v-icon>
                      </div>
                    </v-row>
                  </v-col>
                </v-col>
                <v-col cols="auto" align-self="center">
                  <v-btn
                    class="primary d-flex center margin-b-5 padding-x-4"
                    block
                    small
                  >
                    Driver Info
                  </v-btn>
                  <v-btn
                    block
                    color="error"
                    class="primary d-flex center text-white padding-x-4"
                    small
                  >
                    Live Tracking
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <div class="margin-b-8">
          <h3>Payment Overview</h3>
          <img
            v-if="$vuetify.breakpoint.lgAndUp"
            src="@/assets/images/noReservations/payment_overview_large.png"
            class="margin-t-3"
          />
          <img
            v-if="$vuetify.breakpoint.md"
            src="@/assets/images/noReservations/payment_overview_medium.png"
          />
        </div>

        <div class="margin-b-6">
          <h3 clas="margin-b-3">Manage Documents</h3>
          <a class="font-bold" :style="{ 'line-height': '2.5em' }">
            View Invoice
          </a>
          <br />
          <a class="font-bold" :style="{ 'line-height': '2.5em' }">
            View Insurance
          </a>
        </div>

        <v-divider class="margin-b-6" />

        <div>
          <h3 class="margin-b-3">Help &amp; Support</h3>
          <p>
            Call us 24/7 at 1-855-920-2287 or contact our support team below.
          </p>
          <a class="font-bold" :style="{ 'line-height': '2.5em' }">
            Contact Support
          </a>
        </div>

        <img
          v-if="$vuetify.breakpoint.lgAndUp"
          class="position-absolute z-0"
          :style="{ top: '-76px', left: '-256px' }"
          src="@/assets/images/noReservations/detail_overlay_large.png"
        />
        <img
          v-else-if="$vuetify.breakpoint.md"
          class="position-absolute z-0"
          :style="{ top: '-45px', left: '135px' }"
          src="@/assets/images/noReservations/detail_overlay_medium.png"
        />
      </v-card>
    </div>
    <div class="text-center">
      <v-btn
        outlined
        color="primary"
        @click="$router.push({ name: 'quote-index' })"
      >
        My Quotes
      </v-btn>
      <p>or Call Us at 1-855-920-2287 to Book Today</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { currencyFilter } from '@/utils/string'

@Component({})
export default class NoReservations extends Vue {
  currencyFilter = currencyFilter

  reservation = {
    company: {
      companyDBAName: 'Bus Company',
      rating: 4.8,
      ratingRounded: 5,
      reviewCount: 177,
      amenities: [
        {
          icon: 'wifi',
          color: 'primary',
        },
        {
          icon: 'air',
          color: 'primary',
        },
        {
          icon: 'volume_up',
          color: 'primary',
        },
        {
          icon: 'power',
          color: 'primary',
        },
        {
          icon: 'airline_seat_recline_normal',
          color: 'primary',
        },
        {
          icon: 'wc',
          color: 'primary',
        },
        {
          icon: 'accessible',
          color: 'primary',
        },
        {
          icon: 'tv',
          color: 'primary',
        },
        {
          icon: 'card_travel',
          color: 'lightGray',
        },
        {
          icon: 'mic',
          color: 'lightGray',
        },
        {
          icon: 'event_seat',
          color: 'lightGray',
        },
      ],
      busPhotoSource: '@/assets/images/stock_bus.png',
    },
    transactions: [
      { notes: 'Grand Total', amount: 799.0 },
      { notes: 'Remaining Balance ', amount: 0 },
    ],
  }
  payment = {
    headers: [
      { text: 'Date', value: 'date', align: 'center' },
      { text: 'Description', value: 'paymentType.label', align: 'left' },
      { text: 'Amount', value: 'amount', align: 'right' },
    ],
  }
  driver = {
    rating: 5,
    tripCount: 458,
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';
$details-shadow: 0 2px 47px rgba(#577285, 0.2);
$main-card-width: 660px;
$main-card-width-medium: 600px;
$details-margin: 24px;

.main-container {
  .main-card {
    width: $main-card-width;
    border-radius: 15px;
    margin-bottom: 42px;
    &--medium {
      width: $main-card-width-medium;
    }
  }
  .live-tracking-sample {
    width: 275px;
    box-shadow: $details-shadow;
    &--medium {
      top: 49px;
      left: 59px + $details-margin;
    }
  }
  .live-chat-sample {
    box-shadow: $details-shadow;
    border-radius: 15px;
    &--medium {
      top: 38px;
      left: $main-card-width-medium + $details-margin - 33px;
    }
  }
  .invoice-sample {
    margin-top: 220px;
    box-shadow: $details-shadow;
    &--medium {
      z-index: 2;
      top: 14px;
      left: $main-card-width-medium + $details-margin - 33px;
    }
  }
  .insurance-sample {
    margin-top: 45px;
    box-shadow: $details-shadow;
    &--medium {
      top: -26px + 16px;
      left: $main-card-width-medium + $details-margin + 150px - 19px - 51px;
    }
  }
}
</style>
