<template>
  <v-footer
    class="w-full background-white justify-center z-100 footer-box-shadow position-sticky bottom-0"
    outlined
  >
    <div
      class="d-flex align-center h-100 w-full"
      :class="{
        'padding-x-8': $vuetify.breakpoint.smAndUp,
        'padding-x-4': $vuetify.breakpoint.xsOnly,
      }"
      :style="styles"
    >
      <v-btn
        v-if="backStepButton"
        class="border-radius-8"
        :width="$vuetify.breakpoint.smAndUp ? 265 : '35%'"
        height="50"
        :small="$vuetify.breakpoint.xsOnly"
        color="primary"
        outlined
        @click="backStepButton.handleClick"
      >
        {{ backStepButton.label }}
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="forwardStepButton"
        class="border-radius-8"
        :width="continueButtonWidth"
        height="50"
        :small="$vuetify.breakpoint.xsOnly"
        :disabled="forwardButtonDisabled"
        :loading="submitting"
        color="primary"
        @click="forwardStepButton.handleClick"
      >
        {{ forwardStepButton.label }}
        <CUIcon
          v-if="forwardStepButton.showArrow"
          view-box="0 0 24 24"
          class="margin-l-2"
        >
          arrow_right
        </CUIcon>
      </v-btn>
    </div>
  </v-footer>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import tripModificationStore from '@/store/modules/tripModification'
import { TripModificationStepKey } from '@/utils/enum'
import { EventBus } from '@/utils/eventBus'

type ButtonProps = {
  showArrow?: boolean
  label: string
  handleClick: () => any
}

@Component
export default class TripModificationFooter extends Vue {
  submitting = false
  error = false

  get stepKey(): TripModificationStepKey {
    return tripModificationStore.step.key
  }

  get forwardStepButton(): ButtonProps {
    return this.forwardButtonMap[this.stepKey]
  }

  get backStepButton(): ButtonProps | null {
    return this.backButtonMap?.[this.stepKey] || null
  }

  get hasPaymentUpdate(): boolean {
    return !!tripModificationStore.paymentUpdate
  }

  get backButtonMap(): Record<string, ButtonProps> {
    return {
      [TripModificationStepKey.Vehicle]: {
        label: 'Back',
        handleClick: () => tripModificationStore.previousStep(),
      },
      [TripModificationStepKey.Review]: {
        label: 'Back',
        handleClick: () => tripModificationStore.previousStep(),
      },
      [TripModificationStepKey.BalanceUpdateReview]: {
        label: 'Back',
        handleClick: () => tripModificationStore.previousStep(),
      },
    }
  }

  get forwardButtonMap(): Record<string, ButtonProps> {
    return {
      [TripModificationStepKey.Itinerary]: {
        label: 'Continue',
        showArrow: true,
        handleClick: () => tripModificationStore.nextStep(),
      },
      [TripModificationStepKey.Vehicle]: {
        label: 'Review',
        showArrow: true,
        handleClick: () => this.submitModificationForReview(),
      },
      [TripModificationStepKey.Review]: {
        label: this.reviewNextButtonName,
        showArrow: this.hasPaymentUpdate,
        handleClick: async () => {
          if (this.hasPaymentUpdate) {
            tripModificationStore.nextStep()
          } else {
            await this.submitReview(true)
            EventBus.$emit('refresh-reservation-detail')
          }
        },
      },
      [TripModificationStepKey.BalanceUpdateReview]: {
        label: 'Submit Request',
        showArrow: false,
        handleClick: () => this.submitReview(),
      },
      [TripModificationStepKey.Confirmation]: {
        label: 'View Booking',
        handleClick: () => this.viewBooking(),
      },
      [TripModificationStepKey.Application]: {
        label: 'View Booking',
        handleClick: () => this.viewBooking(),
      },
    }
  }

  get forwardButtonDisabled(): boolean {
    return tripModificationStore.forwardButtonDisabled
  }

  get styles(): Record<string, string> {
    return {
      'max-width': `${this.$vuetify.breakpoint.thresholds.md}px`,
    }
  }

  get continueButtonWidth(): string | number {
    return this.$vuetify.breakpoint.smAndUp
      ? 265
      : this.backStepButton
      ? '60%'
      : '100%'
  }

  get reviewNextButtonName(): string {
    return this.hasPaymentUpdate ? 'Continue' : 'Submit Request'
  }

  viewBooking(): void {
    tripModificationStore.setIsOpen(false)
    EventBus.$emit('refresh-reservation-detail')
  }

  // Submits the requested modification for review
  async submitModificationForReview(): Promise<void> {
    this.submitting = true
    await tripModificationStore.reviewModification()
    this.submitting = false
    if (!tripModificationStore.forwardButtonDisabled) {
      tripModificationStore.nextStep()
    }
  }

  // Submits the reviewed modification for confirmation
  // If the request can be auto-applied, we will apply them
  // If not, a modification request will be created
  async submitReview(skipStep = false): Promise<void> {
    this.submitting = true
    await tripModificationStore.submitModification()
    this.submitting = false
    tripModificationStore.nextStep()
    if (skipStep) {
      tripModificationStore.nextStep()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors';

.footer-box-shadow {
  box-shadow: 0px -5px 20px 0px rgba(0, 0, 0, 0.1);
}
</style>
