<template>
  <div class="padding-l-4 w-full margin-b-4" :style="{'padding-top': '2px'}">
    <div class="margin-b-1">
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <div
            class="d-flex flex-shrink-0 font-weight-600 font-14 text-white align-center justify-center border-radius-round w-20 h-20 margin-r-2"
            :class="errorMessage ? 'background-red' : 'background-gray'"
            :style="{ lineHeight: '12px' }"
          >
            {{ index + 1 }}
          </div>
          <span
            :id="`sales-bot-itinerary-stop-type-label-${index}`"
            class="font-weight-600 font-20"
            :class="errorMessage ? 'text-error' : 'text-gray'"
          >
            {{ stopTypeLabel }}
          </span>
      <p
        v-if="stop?.address?.title"
        :id="`sales-bot-itinerary-stop-title-${index}`"
        class="font-16 font-medium margin-l-2"
        :class="errorMessage ? 'text-error' : 'text-gray'"
        :style="{'margin-top': '2px'}"
      >
        {{ stop?.address?.title }}
      </p>
        </div>
      </div>

    </div>
    <AutoCompleteAddress
      :id="`sales-bot-itinerary-stop-address-${index}`"
      :key="`salse-bot-itinerary-stop-address-${index}`"
      clearable
      clear-icon="replay"
      :initial-search="addressName"
      placeholder="Address"
      hide-details
      :auto-focus="index === 0 && !stop.address?.lat"
      :bias-coordinate="biasCoordinate"
      limit-countries
      @place-selected="place => onAddressUpdate(place.place)"
      @place-cleared="() => onAddressUpdate(null)"
    />
    <p
      v-if="index !== 0"
      :id="`sales-bot-itinerary-stop-estimated-dropoff-time-${index}`"
      class="font-16 margin-t-1"
    >{{ arrivalText }}</p>
    <SalesBotItineraryStopDatetime
      v-if="!isLastStop"
      :index="index"
      :datetime="stop.pickupDatetime"
      :zone-id="stop.address?.zoneId"
      :minimum-datetime="minimumDatetime"
      class="margin-t-1"
      @datetime:update="onDatetimeUpdate($event)"
    />
    <SalesBotItineraryStopKeepOnSite
      v-if="showKeepOnSite"
      class="margin-t-1"
      :vehicle-needed-entire-trip="vehicleNeededEntireTrip"
      @vehicle-needed-entire-trip:update="emit('vehicle-needed-entire-trip:update', $event)"
    />
    <p v-if="errorMessage" class="font-16 font-medium text-error margin-t-1">
      {{ errorMessage }}
    </p>
    <div v-if="!hideAdd || !hideDelete" class="d-flex margin-t-1 padding-x-2">
      <v-btn
        v-if="!hideAdd"
        color="primary"
        class="padding-x-0"
        small
        plain
        @click="emit('stop:add')"
      >
        + Add Stop
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="!hideDelete"
        color="error"
        class="padding-x-0"
        small
        plain
        @click="emit('stop:delete')"
      >
        Remove Stop
      </v-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { DateTime } from 'luxon'
import { Address, PlaceDetail } from '@/models/dto'
import AutoCompleteAddress from '@/components/AutoCompleteAddress.vue'
import SalesBotItineraryStopKeepOnSite from '@/components/SalesBotItineraryStopKeepOnSite.vue'
import SalesBotItineraryStopDatetime from '@/components/SalesBotItineraryStopDatetime.vue'
import CUIcon from '@/components/CUIcon.vue'
import { secondsToFormattedDuration } from '@/utils/datetime'
import { placeDetailToAddress } from '../utils/address'
import { estimateDropoffDatetime } from '@/utils/stop'
import { PropType } from 'vue/types/v3-component-props'
import { SalesBotV2Address, SalesBotV2Stop } from '@/models/SalesbotV2Quote'
import deepClone from '@/utils/deepClone'
import { LatLng } from '@/models/LatLng'

const props = defineProps({
  stop: {
    type: Object as PropType<SalesBotV2Stop>,
    required: true,
    default: null,
  },
  stopTypeLabel: {
    type: String as PropType<'Stop' | 'Pickup' | 'Dropoff'>,
    required: true,
  },
  errorMessage: {
    type: String as PropType<string>,
    default: '',
  },
  hideDelete: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: false
  },
  hideAdd: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: false
  },
  index: {
    type: Number as PropType<number>,
    required: true,
  },
  isLastStop: {
    type: Boolean as PropType<boolean>,
    required: true,
  },
  showKeepOnSite: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: false
  },
  vehicleNeededEntireTrip: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: false
  },
  minimumDatetime: {
    type: Object as PropType<DateTime>,
    required: true,
  },
  arrivalText: {
    type: String as PropType<string>,
    required: false,
  },
  biasCoordinate: {
    type: Object as PropType<LatLng>,
    required: false,
  },
})

const emit = defineEmits(['stop:delete', 'stop:add', 'stop:update', 'vehicle-needed-entire-trip:update'])

const addressName = computed<string>(() => props.stop?.address?.name ?? '')

const onAddressUpdate = (place: PlaceDetail): void => {
  const address: SalesBotV2Address | null = place ? {
      name: place.addressName,
      city: place.city,
      country: place.country,
      lat: place.lat,
      lng: place.lng,
      postalCode: place.postalCode,
      state: place.state,
      street1: place.street1,
      title: place.title,
      addressTypeKeys: place.types,
      zoneId: place.timeZone,
  } : null

  const updatedStop: SalesBotV2Stop = { ...props.stop, address }
  emit('stop:update', updatedStop)
}

const onDatetimeUpdate = (datetime: DateTime): void => {
  const updatedStop: SalesBotV2Stop = { ...props.stop, pickupDatetime: datetime }
  emit('stop:update', updatedStop)
}

</script>

<style lang="scss" scoped>
* {
  touch-action: manipulation;
}
</style>
