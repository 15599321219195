<template>
  <div>
    <span v-if="error" class="text-error">
      {{ $t('profile.NOTIFICATIONS.ERROR') }}
    </span>
    <div
      v-for="(setting, index) in customerNotificationSettings"
      :key="`list-${setting.customerNotificationSettingId}-${setting.customerNotificationType.key}-${index}`"
      class="d-flex padding-a-3 align-center justify-space-between border-b-1 border-x-0 border-t-0 border-solid border-border-gray"
    >
      {{ setting.customerNotificationType.label }}
      <v-switch
        v-model="setting.isEnabled"
        color="success darken-1"
        class="margin-t-0"
        hide-details
        :loading="loading"
        @change="submitChangeNew(setting)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import customer from '@/services/customer'
import { Customer } from '@/models/dto'
import { CustomerNotificationSetting } from '@/models/dto/CustomerNotificationSetting'

@Component({})
export default class ProfileNotifications extends Vue {
  @Prop({ type: Object, required: true }) readonly customer: Customer

  customerNotificationSettings: CustomerNotificationSetting[] = []
  error = false
  loading = false

  async mounted(): Promise<void> {
    this.getSettings()
  }

  async getSettings(): Promise<void> {
  const settings = await customer.getNotificationSettings()
  this.customerNotificationSettings = settings.data.data
  const keysOrder = ['driver_contact_info', 'driver_on_way', 'driver_nearby', 'driver_arrived', 'trip_review']

  // Order types according to keysOrder
  this.customerNotificationSettings.sort((a, b) => {
    let indexOfA = keysOrder.indexOf(a.customerNotificationType.key)
    let indexOfB = keysOrder.indexOf(b.customerNotificationType.key)

    // If an element is not found in keysOrder, it will be moved to the end of the array
    indexOfA = indexOfA === -1 ? Number.MAX_VALUE : indexOfA
    indexOfB = indexOfB === -1 ? Number.MAX_VALUE : indexOfB

    return indexOfA - indexOfB;
  })
}

  async submitChangeNew(setting: CustomerNotificationSetting): Promise<void> {
    this.loading = true
    const payload = { customerNotificationSettings: [setting] }
    try {
      await customer.updateNotificationSettings(payload)
      await this.getSettings()
      this.error = false
    } catch {
      setting.isEnabled = !setting.isEnabled
      this.error = true
    }
    this.loading = false
  }
}
</script>
