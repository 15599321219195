<template>
  <div v-if="vehicle">
    <div class="d-flex">
      <span class="font-medium">{{ vehicle.vehicleName }}</span>
      <div class="d-flex grow" />
      <CUIcon @click="$emit('close')">close</CUIcon>
    </div>
    <v-divider />
    <div class="d-flex">
      <div class="w-one-half font-medium">Reservation ID:</div>
      <div class="w-one-half">{{ vehicle.reservationId }}</div>
    </div>
    <div class="d-flex">
      <div class="w-one-half font-medium">Operator:</div>
      <div class="w-one-half">{{ vehicle.operatorName }}</div>
    </div>
    <div class="d-flex">
      <div class="w-one-half font-medium">Contract:</div>
      <div class="w-one-half">{{ vehicle.contractName }}</div>
    </div>
    <div class="d-flex">
      <div class="w-one-half font-medium">Device ID:</div>
      <div class="w-one-half">{{ vehicle.deviceId }}</div>
    </div>
    <div class="d-flex">
      <div class="w-one-half font-medium">Vehicle Type:</div>
      <div class="w-one-half">{{ vehicle.vehicleType }}</div>
    </div>
    <div class="d-flex">
      <div class="w-one-half font-medium">Traveling:</div>
      <div class="w-one-half">
        {{ vehicle.gpsSpeed || 0 }} mph {{ vehicle.direction }}
      </div>
    </div>
    <div class="d-flex">
      <div class="w-one-half font-medium">ELD:</div>
      <div class="w-one-half">
        {{ eldTypeLabel }}
      </div>
    </div>
    <div class="d-flex">
      <div class="w-one-half font-medium">Timestamp:</div>
      <div class="w-one-half">
        {{ timestamp }}
      </div>
    </div>
    <div v-if="vehicle.isADACompliant" class="d-flex">
      <div class="w-full font-medium">ADA Compliant:</div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TypeWithId } from '../models/dto'
import { DateTime } from 'luxon'

@Component({})
export default class EnterpriseTrackingAdminInfoWindow extends Vue {
  @Prop({ default: null }) readonly vehicle!: any
  @Prop({ type: Array, default: () => [] }) readonly eldTypes: TypeWithId[]

  get eldTypeLabel(): string {
    return (
      this.eldTypes.find((eldType) => eldType.id === this.vehicle.eldTypeId)
        ?.label || ''
    )
  }

  get timestamp(): string {
    const datetime = DateTime.fromISO(this.vehicle.receivedDate)
    return `${datetime.toLocaleString(
      DateTime.DATE_SHORT
    )} • ${datetime.toLocaleString(DateTime.TIME_SIMPLE)}`
  }
}
</script>
