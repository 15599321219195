<template>
  <div
    class="d-flex align-center"
    :class="{ 'text-error font-medium': isExpired }"
  >
    <CUIcon
      v-if="showClockIcon"
      color="error"
      view-box="0 0 24 24"
      class="w-18 h-18 margin-r-1"
    >
      access_time
    </CUIcon>
    {{ expirationText }}
  </div>
</template>

<script lang="ts">
import { TableViewQuote } from '@/models/dto'
import { expirationDelta } from '@/utils/string'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class QuoteIndexExpiration extends Vue {
  @Prop({ required: true }) readonly row: TableViewQuote

  get expiration(): string {
    return this.row?.expirationDate || ''
  }

  get isExpired(): boolean {
    if (!this.expiration) {
      return true
    }
    const now = new Date()
    const thenDate = new Date(`${this.expiration.split('+')[0]}Z`)
    return now.getTime() - thenDate.getTime() > 0
  }

  get expirationText(): string {
    if (!this.expiration) {
      if (!this.row?.isCharterUpQuote) {
        return ''
      }
      return 'Invalid Date'
    }
    const now = new Date()
    const thenDate = new Date(`${this.expiration.split('+')[0]}Z`)
    return now.getTime() - thenDate.getTime() < 0
      ? expirationDelta(this.expiration)
      : 'Expired'
  }

  get showClockIcon(): boolean {
    if (!this.expirationText) {
      return false
    }
    return this.expirationText.charAt(0) === '0'
  }
}
</script>
