<template>
  <CUModal :value="value" :closeable="false" @input="$emit('input', $event)">
    <template #text>
      <div class="w-full padding-t-8 padding-x-6 padding-b-4 text-center">
        <h1>{{ modalTitle }}</h1>
        <img src="@/assets/images/sit-back-relax.png" class="max-w-360" />
        <p class="margin-t-4">
          {{ modalBody }}
        </p>
        <v-form ref="tripReceivedForm">
          <v-btn
            color="primary"
            class="w-full margin-t-6"
            @click="$emit('confirm')"
          >
            View Reservation
          </v-btn>
        </v-form>
      </div>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

@Component
export default class ReservationDetailNextSteps extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: Boolean }) readonly isElite: boolean

  get modalTitle(): TranslateResult {
    return this.$t('reservationDetailNextSteps.TITLE.STANDARD')
  }

  get modalBody(): TranslateResult {
    return this.$t('reservationDetailNextSteps.DESCRIPTION.STANDARD')
  }
}
</script>
