<template>
  <div class="d-flex flex-column w-full">
    <span class="font-14">ID {{ quoteId }}</span>
    <div class="d-flex w-full align-center font-18 font-medium">
      {{ cities.pickup }}
      <CUIcon view-box="0 0 24 24" class="margin-x-1">arrow_right_alt</CUIcon>
      {{ cities.dropoff }}
    </div>
    <div class="d-flex flex-row">
      <a @click="isItineraryModalOpen = true">View Itinerary</a>
      <CUModal v-model="isItineraryModalOpen">
        <template #title>
          Itinerary
        </template>
        <template #text>
          <TripItinerary v-if="stops" :stops="stops" class="margin-y-4" />
        </template>
      </CUModal>
      <a
        id="quote-detail-link-contact-support"
        class="margin-l-4"
        @click.stop="support.open({ reservationId: null, quoteId })"
      >
        Contact Support
      </a>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Stop } from '../models/dto'
import support from '../store/modules/support'
import { getPickupDestinationCitiesFromAddresses } from '../utils/string'
import TripItinerary from '@/components/TripItinerary.vue'
import { QuoteDetailV2, QuoteDetailV2Trip } from '@/models/dto/QuoteDetailV2'

@Component({ components: { TripItinerary } })
export default class QuoteCardMobileInformation extends Vue {
  @Prop({ type: Object, required: true }) quote!: QuoteDetailV2
  @Prop({ type: Object, required: true }) trip!: QuoteDetailV2Trip

  support = support
  isItineraryModalOpen = false

  get quoteId(): number {
    return this.quote?.quoteId
  }

  get stops(): Stop[] {
    return this.trip?.stops || []
  }

  get cities(): {
    pickup: string
    dropoff: string
  } {
    const firstAddress = this.trip?.stops?.[0]?.address
    const secondAddress = this.trip?.stops?.[1]?.address
    return getPickupDestinationCitiesFromAddresses(firstAddress, secondAddress)
  }
}
</script>
