<template>
  <p
    class="text-center font-12 margin-y-0"
    :style="{ 'margin-bottom': '0.5em' }"
  >
    {{ $t('signup.YOU_AGREE') }}
    <a
      href="https://www.charterup.com/terms-and-conditions/"
      target="_blank"
    >
      {{ $t('common.TERMS_OF_SERVICE') }}
    </a>
    {{ $t('signup.AND') }}

    <a
      href="https://www.charterup.com/privacy-policy/"
      target="_blank"
      class="mr-n1"
    >
      {{ $t('common.PRIVACY_POLICY') }}
    </a>
    .
  </p>
</template>

<script lang="ts">
import {Vue, Component } from 'vue-property-decorator'

@Component
export default class SignUpFormLegalese extends Vue {
}
</script>
