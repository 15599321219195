<template>
  <CUModal
    v-model="value"
    width="550"
    :content-class="$vuetify.breakpoint.xs ? 'fullscreen-dialog' : ''"
    :fullscreen="$vuetify.breakpoint.xs"
    @input="value = $event"
    @keydown.stop="
      (event) => {
        if (event.keyCode === 27) {
          value = false
        }
      }
    "
  >
    <template #activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <v-btn
          v-bind="attrs"
          small
          outlined
          color="primary"
          class="border-radius-8 padding-a-2 h-32 max-h-32"
          v-on="on"
        >
          <span class="d-flex align-center">
            <CUIcon view-box="0 0 24 24" class="w-16 h-16 margin-r-2">
              share
            </CUIcon>
            {{ $t('checkout.SHARE_QUOTE') }}
          </span>
        </v-btn>
      </slot>
    </template>

    <template #title>{{ `${''}` }}</template>
    <template #text>
      <div class="w-full padding-x-4 padding-b-4">
        <img src="@/assets/images/tripContact.svg" class="w-full margin-b-3" />
        <div class="w-full text-center">
          <h2>{{ $t('shareQuote.HEADER') }}</h2>
          <p>{{ $t('shareQuote.SUBHEAD') }}</p>
          <p>
            {{ $t('shareQuote.BODY') }}
          </p>
        </div>
        <div class="w-full margin-t-3">
          <CustomerInviteSearch
            ref="customerInviteSearch"
            :label="$t('shareQuote.FIND_USERS')"
            :placeholder="$t('shareQuote.USER_SEARCH_PLACEHOLDER')"
            :shared-list="sharedList"
            :show-back="showInviteForm"
            :error-messages="inviteErrorMessages"
            @invite="handleInviteNewUser"
            @back="showInviteForm = false"
          />
        </div>
        <template v-if="showInviteForm">
          <div class="w-full">
            <CustomerInviteForm
              :quote-id="quoteId"
              :trigger-submit="submitting"
              :initial-email="inviteEmail"
              @refresh="$emit('refresh-shared-list')"
              @submit="handleSubmit($event)"
            />
          </div>
        </template>
        <div class="w-full">
          <v-btn
            color="primary"
            class="w-full margin-t-2"
            :loading="submitting"
            @click="startSubmit"
          >
            {{ $t('shareQuote.SEND_INVITE') }}
          </v-btn>
        </div>
        <div v-if="sharedList && sharedList.length" class="w-full margin-t-3">
          <template v-for="(customer, customerIndex) in sharedList">
            <div
              :key="`customer-container-${customer.customerId}-${customerIndex}`"
              class="d-flex align-center margin-b-4 last-of-type:margin-b-0"
            >
              <div
                class="d-flex background-black w-40 h-40 border-radius-round margin-r-2 align-center justify-center text-white font-bold"
              >
                {{
                  getInitials(customer.firstName, customer.lastName) ||
                  customer.email[0].toUpperCase()
                }}
              </div>
              <div class="max-w-300 margin-r-2">
                <p
                  class="font-bold margin-y-0 overflow-hidden white-space-nowrap display-block text-overflow-ellipsis"
                >
                  {{ customer.firstName }}
                  {{ customer.lastName }}
                </p>
                <p
                  class="margin-t-n1 overflow-hidden white-space-nowrap display-block text-overflow-ellipsis"
                >
                  {{ customer.email }}
                </p>
              </div>
              <v-spacer />
              <div>
                <CUIcon
                  :id="`unshare-quote-button-${customerIndex}`"
                  color="gray-mid-light"
                  hover-color="error"
                  class="margin-b-n3"
                  width="32"
                  height="32"
                  @click="unshare(customer)"
                >
                  delete
                </CUIcon>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import CustomerInviteSearch from '@/components/CustomerInviteSearch.vue'
import CustomerInviteForm from '@/components/CustomerInviteForm.vue'
import quotes from '@/services/quotes'
import { Customer, InviteCustomerPayload } from '@/models/dto'
import { getInitials, validateEmailAddress } from '@/utils/string'
import customer from '@/services/customer'
import { Page } from '@/utils/enum'

@Component({ components: { CustomerInviteSearch, CustomerInviteForm } })
export default class ShareQuoteDialog extends Vue {
  @Inject({ from: 'isInQuoteDetail', default: false }) readonly isInQuoteDetail: boolean
  @Inject({ from: 'isInProvider', default: false }) readonly isInProvider: boolean
  @Inject({ from: 'isInCheckout', default: false }) readonly isInCheckout: boolean
  @Inject({ from: 'isInGuestCheckout', default: false }) readonly isInGuestCheckout: boolean
  @Prop({ type: Number, required: true }) readonly quoteId: number
  @Prop({ type: String, default: '' }) readonly buttonClass: string
  @Prop({ type: Boolean }) readonly text: boolean

  value = false
  showInviteForm = false
  sharedList = []
  submitting = false
  inviteEmail = ''
  getInitials = getInitials
  validateEmailAddress = validateEmailAddress
  invitingEmailSet = new Set()
  inviteErrorMessages = []

  mounted(): void {
    this.getSharedList()
  }

  get page(): string {
    if (this.isInQuoteDetail) {
      return Page.QuoteDetail
    }
    if (this.isInProvider) {
      return Page.Provider
    }
    if (this.isInCheckout || this.isInGuestCheckout) {
      return Page.Checkout
    }
    return Page.Other
  }

  async getSharedList(): Promise<void> {
    const sharedListResponse = await quotes.sharedList(this.quoteId)
    this.sharedList = sharedListResponse.data.sharedList || []
  }

  async share(customer: Customer): Promise<void> {
    await quotes.share(this.quoteId, customer.customerId)
    this.getSharedList()
  }

  async unshare(customer: Customer): Promise<void> {
    await quotes.unshare(this.quoteId, customer.customerId)
    this.getSharedList()
  }

  async handleInviteNewUser(email: string): Promise<void> {
    if (this.invitingEmailSet.has(email)) {
      return
    }

    this.invitingEmailSet.add(email)
    if (validateEmailAddress(email)) {
      const customer: InviteCustomerPayload = {
        firstName: '',
        lastName: '',
        phone: '',
        email,
      }
      await this.handleSubmit(customer)
      this.invitingEmailSet.delete(email)
      return
    }

    this.showForm(email)
    this.invitingEmailSet.delete(email)
  }

  showForm(email: string): void {
    this.showInviteForm = true
    this.inviteEmail = email
  }

  close(): void {
    this.value = false
  }

  validateEmail(email: string): boolean {
    this.inviteErrorMessages = []
    const valid = validateEmailAddress(email)
    if (!valid) {
      this.inviteErrorMessages.push('Please enter a valid email.')
      return false
    }
    return true
  }

  startSubmit(): void {
    const inviteSearch = this.$refs['customerInviteSearch'] as any
    const inviteEmail = inviteSearch.search

    this.submitting = true
    this.handleSubmit({
      email: inviteEmail,
      firstName: '',
      lastName: '',
      phone: '',
    })
  }

  async handleSubmit(payload: InviteCustomerPayload): Promise<void> {
    const valid = this.validateEmail(payload.email)
    if (!valid) {
      this.submitting = false
      return
    }

    try {
      await customer.inviteByQuoteId(this.quoteId, payload)
      this.trackShareQuote()
      await this.getSharedList()
    } catch (error) {
      console.error(error)
    }
    this.showInviteForm = false
    this.submitting = false
  }

  trackShareQuote(): void {
    this.$ga4.trackEvent('share_quote', {
      page: this.page,
    })
  }
}
</script>
