<template>
  <div
    class="zoom-toggle-group z-3 position-absolute right-12 background-white border-1 border-solid border-border-gray w-48 h-192 top-72"
  >
    <v-tooltip left>
      <template #activator="{ on, attrs }">
        <div
          class="zoom-toggle-group__option"
          v-bind="attrs"
          v-on="on"
          @click="changeMode('full-route')"
        >
          <CUIcon
            class="zoom-toggle-group__option__icon"
            width="35"
            height="35"
            :style="{ margin: '10px' }"
            :class="{
              active: zoomOptions.fitEntireRoute,
            }"
          >
            directions
          </CUIcon>
        </div>
      </template>
      <span class="text-white">
        {{ $t('liveTracking.actions.FIT_ENTIRE_ROUTE') }}
      </span>
    </v-tooltip>
    <v-tooltip left>
      <template #activator="{ on, attrs }">
        <div
          class="zoom-toggle-group__option"
          v-bind="attrs"
          v-on="on"
          @click="changeMode('next-stop')"
        >
          <CUIcon
            class="zoom-toggle-group__option__icon"
            width="35"
            height="35"
            :style="{ margin: '10px' }"
            :class="{
              disabled: disableZoomModes,
              active: zoomOptions.fitNextStop || zoomOptions.fitPreviousStop,
            }"
          >
            bus_to_next_stop
          </CUIcon>
        </div>
      </template>
      <span class="text-white">
        {{ $t('liveTracking.actions.FIT_BUS_AND_NEXT_STOP') }}
      </span>
    </v-tooltip>

    <div class="zoom-toggle-group__option">
      <v-tooltip left>
        <template #activator="{ on, attrs }">
          <div
            class="zoom-toggle-group__option"
            v-bind="attrs"
            v-on="on"
            @click="changeMode('center')"
          >
            <CUIcon
              class="zoom-toggle-group__option__icon"
              width="35"
              height="35"
              :style="{ margin: '10px' }"
              :class="{
                disabled: disableZoomModes,
                active: zoomOptions.centerOnBus,
              }"
            >
              bus
            </CUIcon>
          </div>
        </template>
        <span class="text-white">{{ $t('liveTracking.actions.FIT_BUS') }}</span>
      </v-tooltip>
    </div>

    <div class="zoom-toggle-group__option">
      <v-tooltip left>
        <template #activator="{ on, attrs }">
          <div
            class="zoom-toggle-group__option"
            v-bind="attrs"
            v-on="on"
            @click="resetCustomOptions"
          >
            <CUIcon
              class="zoom-toggle-group__option__icon"
              width="35"
              height="35"
              :style="{ margin: '10px' }"
              :class="{
                disabled: disableZoomModes || !zoomOptions.customOption,
              }"
            >
              sync
            </CUIcon>
          </div>
        </template>
        <span class="text-white">{{ $t('liveTracking.actions.RESET') }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TrackingMapZoomOptions } from '@/models/Tracking'
import tracking from '@/store/modules/tracking'

@Component({ components: {} })
export default class LiveTrackingMapToggleGroup extends Vue {
  @Prop({ type: Object, required: true })
  readonly zoomOptions: TrackingMapZoomOptions
  @Prop({ type: Boolean, required: true }) readonly disableZoomModes: boolean

  changeMode(mode: string): void {
    tracking.setCustomZoomOption(true)
    if (mode === 'full-route') {
      tracking.setZoomToFullRoute()
    } else if (!this.disableZoomModes && mode === 'next-stop') {
      // TODO: consider this and see how to have this handle possibly fitting previous stop
      tracking.setZoomToVehicleAndNextStop()
    } else if (!this.disableZoomModes && mode === 'center') {
      tracking.setZoomToVehicle()
    }
    this.$emit('input')
  }

  resetCustomOptions(): void {
    tracking.setCustomZoomOption(false)
    tracking.setCustomZoomPosition(false)
    this.$emit('input')
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors';
.zoom-toggle-group {
  &__option {
    width: 48px;
    height: 48px;
    &:not(:first-of-type) {
      border-top: 1px solid $border-gray;
    }
    &__icon {
      &.active {
        color: $primary !important;
      }
      &.disabled {
        cursor: default;
        color: $border-gray !important;
      }
    }
  }
}
</style>
