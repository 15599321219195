<template>
  <div class="d-flex justify-center">
    <v-form ref="self-serve-customer-form" class="max-w-600 w-full">
      <v-row dense>
        <v-col cols="12" sm="6">
          <label>Email</label>
          <v-text-field
            id="customer-information-email-text-field"
            ref="email"
            :value="email"
            placeholder="e.g. james@gmail.com"
            :rules="[
              (v) => !!v || 'Email is required',
              (v) =>
                /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,13}$/.test(v) ||
                'E-mail must be valid',
            ]"
            type="email"
            autocomplete="email"
            hide-details="auto"
            required
            validate-on-blur
            outlined
            @input="customer.email = $event"
            @keyup="checkCustomerEmail(false)"
            @blur="checkCustomerEmail(true)"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <label>Create password</label>
          <v-text-field
            id="customer-password-password-text-field"
            ref="password"
            :value="password"
            :rules="[
              (v) => !!v || 'Password is required',
              (v) => !this.shouldValidatePassword || this.asyncPasswordResponse.data.valid || this.asyncPasswordResponse.data.message
            ]"
            :append-icon="passwordHidden ? 'visibility' : 'visibility_off'"
            :type="passwordHidden ? 'password' : 'text'"
            hide-details="auto"
            autocomplete="new-password"
            required
            validate-on-blur
            outlined
            @click:append="() => (passwordHidden = !passwordHidden)"
            @input="customer.password = $event"
            @change="resetPasswordValidation"
          />
        </v-col>
        <v-col v-if="selfServe.isExistingUser" cols="12">
          <v-card
            class="padding-a-6 border-primary background-blue-10 margin-b-2"
          >
            <v-row class="align-center">
              <v-col cols="12" sm="6">
                <img src="@/assets/images/signIn.png" width="100%" />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                :class="{
                  'text-right': $vuetify.breakpoint.smAndUp,
                  'text-center': $vuetify.breakpoint.xs,
                }"
              >
                <h2 ref="signIn">Welcome back!</h2>
                <p>
                  It looks like a user with that email already exists.
                </p>
                <v-btn
                  color="primary"
                  small
                  class="margin-t-2"
                  @click="selfServe.goToSignIn"
                >
                  Sign In
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <label>First Name</label>
          <v-text-field
            id="customer-information-first-name-text-field"
            ref="first-name"
            :value="firstName"
            placeholder="e.g. James Joseph"
            :rules="[(v) => !!v || 'First name is required']"
            hide-details="auto"
            autocomplete="given-name"
            required
            validate-on-blur
            outlined
            @input="customer.firstName = $event"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <label>Last Name</label>
          <v-text-field
            id="customer-information-last-name-text-field"
            :value="lastName"
            placeholder="e.g. Smith"
            :rules="[(v) => !!v || 'Last name is required']"
            hide-details="auto"
            autocomplete="family-name"
            required
            validate-on-blur
            outlined
            @input="customer.lastName = $event"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <label>Phone Number</label>
          <v-text-field
            id="customer-information-phone-text-field"
            v-mask="['(###) ###-####', '+## ## #### ####']"
            :value="phone"
            placeholder="e.g. (123) 456-7890"
            :rules="[
              (v) => !!v || 'Phone number is required',
              phoneRaw.length > 9 || 'Phone number must be valid',
              phoneRaw.length <= 12 || 'Phone number must be valid',
            ]"
            hide-details="auto"
            autocomplete="tel"
            required
            validate-on-blur
            outlined
            @input="customer.phone = $event"
          />
          <div class="d-flex justify-start margin-l-1">
            <span class="font-12 text-gray-subtle-text">
              In case we need to contact you about your booking.
            </span>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <label>
            How many buses do you book in a year?
          </label>
          <v-text-field
            id="customer-information-bookings-text-field"
            :value="bookings"
            type="number"
            placeholder="e.g. 1"
            hide-details="auto"
            :rules="[
              (v) =>
                !v || Number.isInteger(Number(v)) || 'Must be a whole number',
              (v) => !v || parseInt(v) > 0 || 'Must be a positive number',
            ]"
            outlined
            step="1"
            min="1"
            max="999999"
            @input="customer.bookings = $event"
          />
          <div class="d-flex justify-start margin-l-1">
            <span class="font-12 text-gray-subtle-text">Optional</span>
          </div>
        </v-col>
        <SelfServeQuoteSalesSMSOptIn/>
        <v-col cols="12" class="d-flex justify-center margin-t-6">
          <v-btn
            color="primary"
            class="w-full"
            :class="{
              'max-w-320': $vuetify.breakpoint.smAndUp,
            }"
            :disabled="disabled"
            :loading="submitting"
            @click="submit"
          >
            Continue
            <CUIcon view-box="0 0 24 24" class="margin-l-2">
              arrow_right
            </CUIcon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator'
import { phoneRaw } from '@/utils/phone'
import { SalesBotCustomer } from '@/classes/SalesBotCustomer'
import selfServe from '@/store/modules/selfServe'
import customer from '@/services/customer'
import selfServeService from '@/services/selfServe'
import auth from '@/store/modules/auth'
import authService from "@/services/auth";
import SelfServeQuoteSalesSMSOptIn from './SelfServeQuoteSalesSMSOptIn.vue'

@Component({ components: { SelfServeQuoteSalesSMSOptIn } })
export default class SelfServeCustomerInformation extends Vue {
  isLoggingIn = false
  debounce = null
  selfServe = selfServe
  submitting = false
  passwordHidden = true
  asyncPasswordResponse = {
    data: {
      valid: false,
      message: 'Something went wrong'
    }
  }
  shouldValidatePassword = false

  @Watch('selfServe.isExistingUser', { immediate: true })
  scrollToSignIn(): void {
    if (!selfServe.isExistingUser || !this.$vuetify.breakpoint.xsOnly) {
      return
    }

    this.$nextTick(() => {
      const signInElement = this.$refs.signIn as HTMLElement
      if (!signInElement) {
        return
      }

      signInElement.scrollIntoView({
        behavior: 'smooth',
      })
    })
  }

  get customer(): SalesBotCustomer {
    return selfServe.quote.customer
  }

  get firstName(): string {
    return this.customer?.firstName
  }

  get lastName(): string {
    return this.customer?.lastName
  }

  get phone(): string {
    return this.customer?.phone
  }

  get email(): string {
    return this.customer?.email
  }

  get bookings(): number {
    return this.customer?.bookings
  }

  get password(): string {
    return this.customer?.password || ''
  }

  get phoneRaw(): string {
    return this.customer?.phone ? phoneRaw(this.customer.phone) : ''
  }

  get disabled(): boolean {
    return (
      selfServe.isExistingUser ||
      !(!!this.firstName && !!this.lastName && !!this.email && !!this.phoneRaw)
    )
  }

  mounted(): void {
    this.focusEmail()
  }

  focusEmail(): void {
    const emailInput = this.$refs['email'] as any
    emailInput.focus()
  }

  checkCustomerEmail(immediate = false): void {
    const isEmailValid = this.validateEmail()
    if (!isEmailValid) {
      selfServe.setIsExistingUser(false)
      return
    }

    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }
    const delay = immediate ? 0 : 250
    this.debounce = window.setTimeout(async () => {
      const response = await customer.isSignedUpByEmail(this.email)
      selfServe.setIsExistingUser(!!response?.data?.signedUp)
    }, delay)
  }

  validateEmail(): boolean {
    if (!this.email) {
      return false
    }
    return /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,13}$/.test(this.email)
  }

  async submit(): Promise<void> {
    this.asyncPasswordResponse = await authService.validatePassword(this.password)
    this.shouldValidatePassword = true

    const form = this.$refs['self-serve-customer-form'] as any
    const isFormValid = form.validate()
    if (!isFormValid) {
      return
    }
    try {
      this.submitting = true
      await this.createSelfServeQuotes()
    } catch (err) {
      console.error(err)
    }
    this.submitting = false
  }

  async createSelfServeQuotes(): Promise<void> {
    const payload = selfServe.quote.toObject()
    const { data } = await selfServeService.createAsGuest(payload)
    this.trackRegistration()

    if (!data?.data) {
      throw new Error('Failed to create user')
    }

    const { email, password } = payload.customer
    await auth.login({ email, password })

    const quotes = data?.data
    const selfServeId = quotes?.[0]?.selfServeId
    selfServe.setSelfServeId(selfServeId)
    const quoteDetailsResponse = await selfServeService.bySelfServeIdV2(
      selfServeId
    )
    const quoteDetails = quoteDetailsResponse.data?.data
    selfServe.setQuoteDetails(quoteDetails)

    selfServe.getAvailableAmenities()
    selfServe.moveToVehicleStep()
  }

  trackRegistration(): void {
    this.$ga4.trackEvent('registration', {
      isAuth0: this.$auth0.isInitialized,
    })
  }

  resetPasswordValidation(): void {
    this.shouldValidatePassword = false
  }

}
</script>

<style lang="scss" scoped>
@import '@/scss/colors.scss';
* {
  touch-action: manipulation;
}

::v-deep .v-input__append-inner {
  margin-top: 13px !important;
  margin-right: 15px;
}

label {
  color: $gray-text;
}

::v-deep .v-text-field input {
  color: $gray-text !important;
  font-weight: 500 !important;
}
</style>
