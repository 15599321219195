<template>
  <div class="d-flex flex-column omnibus">
    <PaymentProfileV2
      v-for="(paymentProfile, paymentProfileIndex) in paymentProfiles"
      :key="`payment-profile-${paymentProfile.customerPaymentProfileId}-${paymentProfileIndex}`"
      :payment-profile="paymentProfile"
      :is-selected="paymentProfile.customerPaymentProfileId === selectedPaymentProfileId"
      :class="{ 'margin-b-2': paymentProfileIndex !== paymentProfiles.length - 1 }"
      @click="select(paymentProfile.customerPaymentProfileId)"
      @payment-profile-deleted="loadSavedCustomerPaymentProfiles"
    />
  </div>
</template>
<script lang="ts">
import { Vue, Component, Watch, Inject, Prop } from 'vue-property-decorator'
import { ComputedPaymentProfile, TableViewPaymentProfile, AuthPayment } from '../models/dto'
import paymentProfile from '../services/paymentProfile'
import auth from '../store/modules/auth'
import PaymentProfileV2 from '@/components/PaymentProfileV2.vue'
import quotes from '@/services/quotes'

@Component({ components: { PaymentProfileV2 } })
export default class PaymentProfileSelectorV2 extends Vue {
  @Inject({ from: 'isInGuestCheckout', default: false }) readonly isInGuestCheckout: boolean
  @Inject({ from: 'isInBilling', default: false }) readonly isInBilling: boolean
  @Prop({ type: Number, default: null }) readonly quoteId: number | null
  @Prop({ type: String, default: null }) readonly reservationManagedId: string | null

  paymentProfiles: ComputedPaymentProfile[] = []
  selectedPaymentProfileId: number = null

  @Watch('selectedPaymentProfileId')
  onSelectedPaymentProfileIdChange(): void {
    const selectedPaymentProfile =
      this.paymentProfiles.find(
        ({ customerPaymentProfileId }) =>
          customerPaymentProfileId === this.selectedPaymentProfileId
      ) || null
    this.$emit('input', selectedPaymentProfile)
  }

  async setPaymentProfiles(): Promise<void> {
    if (this.isInGuestCheckout && !auth.user) {
      this.$emit('update:has-payment-profiles', false)
      this.$emit('update:loading', false)
      return
    }
    await this.loadSavedCustomerPaymentProfiles()
    this.selectDefaultOrFirstPaymentProfile()
  }

  async mounted(): Promise<void> {
    await this.setPaymentProfiles()
  }

  async loadSavedCustomerPaymentProfiles(): Promise<void> {
    this.$emit('update:loading', true)
    const { data } = await paymentProfile.loadSavedCustomerPaymentProfiles(auth.userId)
    const paymentProfilesResults = data?.resultList || []
    this.paymentProfiles = paymentProfilesResults.map((profile) =>
      this.processPaymentProfile(profile)
    )
    this.$emit('update:has-payment-profiles', !!this.paymentProfiles?.length)
    this.$emit('update:loading', false)
  }

  async getAuthPaymentOnCheckout(quoteId: number): Promise<AuthPayment> {
    const response = await quotes.getAuthPaymentsById(quoteId)
      const result = response?.data?.authPayments || []

      if (result.length) {
        const authPaymentOnCheckout = result.reduce(
        (earliestPayment, currentPayment) =>
          currentPayment.createdOn < earliestPayment.createdOn
            ? currentPayment
            : earliestPayment
          )
        return authPaymentOnCheckout
      } else {
      return null
    }
  }

  convertAuthPaymentToComputedPaymentProfile(authPayment: AuthPayment): ComputedPaymentProfile {
    const data = JSON.parse(authPayment?.meta)
    const computedPaymentProfile = {
      "customerPaymentProfileId": authPayment?.customerPaymentProfileId,
      "mask": data?.mask,
      "typeLabel": data?.type_label,
      "isDefault": false,
      "accountHolderName": data?.cardholderName,
      "expiration": data?.exp_date,
      "address": data?.address
    }
    return computedPaymentProfile
  }

  async selectDefaultOrFirstPaymentProfile(): Promise<void> {
    if (!this.paymentProfiles?.length) {
      return
    } else {
      const defaultProfileId = this.paymentProfiles.find(
        ({ isDefault }) => isDefault
      )?.customerPaymentProfileId
      const firstProfileId = this.paymentProfiles[0]?.customerPaymentProfileId

      this.selectedPaymentProfileId = (defaultProfileId || firstProfileId)
    }

  }

  async select(customerPaymentProfileId: number): Promise<void> {
    if (this.isInBilling) {
      await this.setDefault(customerPaymentProfileId)
      await this.loadSavedCustomerPaymentProfiles()
    }
    this.selectedPaymentProfileId = customerPaymentProfileId
  }

  processPaymentProfile(
    paymentProfile: TableViewPaymentProfile
  ): ComputedPaymentProfile {
    return {
      customerPaymentProfileId: paymentProfile.customerPaymentProfileId,
      mask: paymentProfile.mask,
      typeLabel: paymentProfile.label,
      isDefault: paymentProfile.isDefault,
      accountHolderName: paymentProfile.accountHolderName,
      expiration: paymentProfile.expiration,
      address: {
        street1: paymentProfile.address?.street1,
        street2: paymentProfile.address?.street2,
        city: paymentProfile.address?.city,
        state: paymentProfile.address?.state,
        postalCode: paymentProfile.address?.postalCode,
        timeZone: paymentProfile.address?.timeZone,
        lat: paymentProfile.address?.lat,
        lng: paymentProfile.address?.lng,
        completeAddress: paymentProfile.address?.completeAddress,
        addressName: paymentProfile.address?.addressName,
      },
    }
  }

  async setDefault(customerPaymentProfileId: number): Promise<void> {
    try {
      const payload = {
        customerId: auth.userId,
        customerPaymentProfileId,
      }
      await paymentProfile.setDefault(payload)
    } catch (error) {
      console.error(error)
    }
  }
}
</script>
