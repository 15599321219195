<template>
  <div>
    <template v-if="!quote">
      <SkeletonBox
        class="margin-y-12 margin-x-auto"
        :style="{ height: '38px', width: '75%' }"
      />
      <SkeletonTripCollectionCard
        :map="false"
        class="margin-y-0 margin-x-auto"
        :style="{ 'max-width': '550px' }"
      />
    </template>

    <div v-else :class="{ 'padding-x-8': $vuetify.breakpoint.xsOnly }">
      <h1 class="text-center margin-y-8 margin-x-0 font-32">
        {{ headerText }}
      </h1>
      <QuoteCard
        v-if="quote"
        :quote="quote"
        hide-map
        class="max-w-500 margin-y-0 margin-x-auto"
      />
      <div class="h-120"></div>
    </div>
    <div
      class="d-flex justify-center align-center h-120 w-full background-white position-fixed bottom-0 z-10"
    >
      <SkeletonBox v-if="!quote" class="h-52 w-300 margin-t-12 margin-x-auto" />
      <v-btn
        v-else
        :small="$vuetify.breakpoint.xsOnly"
        :loading="loading"
        color="primary"
        class="text-uppercase"
        @click="handleQuoteConfirm"
      >
        {{ $t('quoteDetail.confirmation.BUTTON_TEXT') }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import QuoteCard from '@/components/QuoteCard.vue'
import SkeletonTripCollectionCard from '@/components/SkeletonTripCollectionCard.vue'
import quote from '@/services/quotes'
import footer from '@/store/modules/footer'
import { TranslateResult } from 'vue-i18n'
import auth from '@/store/modules/auth'
import { QuoteDetailV2 } from '../models/dto/QuoteDetailV2'
import SkeletonBox from '@/components/SkeletonBox.vue'
import { SplitEvents, SplitTrafficType } from '@/utils/enum'

@Component({
  components: {
    QuoteCard,
    SkeletonTripCollectionCard,
    SkeletonBox,
  },
})
export default class QuoteConfirmation extends Vue {
  @Prop({ type: Object, default: () => {} }) readonly quote: QuoteDetailV2

  @Watch('$route', { deep: true })
  routeChanged(): void {
    footer.setShow(false)
  }

  loading = false

  get headerText(): TranslateResult {
    if (!this.quote) {
      return null
    }
    return this.$t('quoteDetail.confirmation.HEADER', {
      firstName: this.quote.customer.firstName,
    })
  }

  mounted() {
    footer.setShow(false)
  }

  async handleQuoteConfirm(): Promise<void> {
    this.loading = true
    const response = await quote.confirm(this.quote.quoteId)
    if (this.quote?.isSelfServe === false) {
      this.trackCreateQuote()
      await this.$split.track(SplitTrafficType.User, SplitEvents.QuoteCreated)
    }
    this.loading = false
    if (response.data.quote.isConfirmed) {
      this.$emit('confirm')
      footer.setShow(true)
    }
  }

  trackCreateQuote(): void {
    this.$ga4.trackEvent('create_quote', {
      isElite: this.quote?.isElite,
      isSelfServe: false,
      isAuth0: this.$auth0.isInitialized,
      hasBookedBefore: auth?.customer?.convertedQuoteCount > 0,
      isLoggedIn: true,
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';

.v-btn {
  ::v-deep .v-btn__loader {
    .v-progress-circular {
      svg {
        color: white !important;
      }
    }
  }
}
</style>
