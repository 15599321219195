<template>
  <v-card
    v-if="accountExecutive || updatesAndSupport"
    @click.native.stop
  >
    <div>
      <div
      class="font-18 font-weight-700 font-medium">
        {{ auth.customerAccount.name }}
      </div>
    </div>
    <div v-if="accountExecutive">
      <div class="px-1 pt-3 pb-1">
        <h5 class="text-uppercase text-midnight-gray-300 font-weight-700 font-size-14">
          <slot name="quotesTitle">
            New Quote
          </slot>
        </h5>
      </div>
      <v-card-text class="px-2 py-0">
        <h5 class="font-weight-700 font-18">
          {{ accountExecutive.firstName }} {{ accountExecutive.lastName }}
        </h5>
        <h5 class="font-16">
          {{ formattedAccountExecutiveNumber }}
        </h5>
        <a class="font-medium font-16" :href="`mailto:${accountExecutive.email}`"">{{ accountExecutive.email }}</a>
      </v-card-text>
    </div>
    <div v-if="updatesAndSupport">
      <div class="px-1 pt-3 pb-1">
        <h5 class="text-uppercase text-midnight-gray-300 font-weight-700 font-size-14">
          <slot name="updatesAndSupportTitle">
            Updates & Support
          </slot>
        </h5>
      </div>
      <v-card-text class="px-2 py-0">
        <h5 class="font-16">
          {{ updatesAndSupport.phone }}
        </h5>
        <a v-if="updatesAndSupport.email" class="font-medium font-16" :href="`mailto:${updatesAndSupport.email}`">
          {{ updatesAndSupport.email }}
        </a>
      </v-card-text>
    </div>
  </v-card>
</template>

<script lang="ts">
import auth from '@/store/modules/auth'

import { Vue, Component, Watch } from 'vue-property-decorator'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'
import CustomerAccountDefaultValueSet from '@/models/dto/CustomerAccountDefaultValueSet'
import AccountExecutive from '@/models/dto/AccountExecutive'
import customerAccount from '@/services/customerAccount'

@Component({})
export default class AccountSupport extends Vue {
  auth = auth
  effectiveDefaultValueSet: CustomerAccountDefaultValueSet = null

  async created(): Promise<void> {
    await this.getEffectiveDefaultValueSet()
  }

  @Watch('auth.customerAccount.customerAccountId')
  customerAccountChanged(): void {
    this.getEffectiveDefaultValueSet()
  }

  get updatesAndSupport() {
    if (
      !this.auth.customerAccount?.supportEmail &&
      !this.auth.customerAccount?.supportPhone
    ) {
      return null
    }

    let formattedPhone = ''
    if (this.auth.customerAccount?.supportPhone) {
      formattedPhone  = this.auth.customerAccount?.supportPhone
    } else {
      formattedPhone = getSupportNumber(auth.customer.isElite)
    }
    formattedPhone = phoneFormatFilterHyphens(formattedPhone)

    return {
      email: this.auth.customerAccount?.supportEmail,
      phone: formattedPhone,
    }
  }

  get formattedAccountExecutiveNumber(): string {
    const accountExecutivePhoneNumber = this.accountExecutive?.phone
    const supportNumber = getSupportNumber(auth.customer.isElite)
    return phoneFormatFilterHyphens(
      accountExecutivePhoneNumber || supportNumber
    )
  }

  get accountExecutive(): AccountExecutive {
    return this.effectiveDefaultValueSet?.accountExecutive
  }

  async getEffectiveDefaultValueSet(): Promise<void> {
    if (!auth.user || !auth.customerAccount) {
      return
    }
    try {
      const { data } = await customerAccount.effectiveDefaultValueSet(
        auth.customerAccount.customerAccountId
      )
      this.effectiveDefaultValueSet = data
    } catch (error) {
      console.error('Error fetching effective default value set', error)
    }
  }
}
</script>
