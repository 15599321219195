import { getCurrentInstance, computed } from 'vue'

/*
 * Vuetify breakpoints
 *
 * Provides a reactive wrapper for accessing Vuetify 2's breakpoint properties (e.g., smAndDown)
 * within the Vue Composition API. This is a temporary workaround to bridge Vuetify 2's lack of
 * native Composition API support. Once we upgrade to Vuetify 3, which includes official composables,
 * this helper should be replaced with the included useDisplay().
 */
export const useDisplay = () => {
  const instance = getCurrentInstance()
  const breakpoint = instance?.proxy?.$vuetify?.breakpoint

  const xs = computed(() => breakpoint?.xs ?? false)
  const sm = computed(() => breakpoint?.sm ?? false)
  const md = computed(() => breakpoint?.md ?? false)
  const lg = computed(() => breakpoint?.lg ?? false)
  const xl = computed(() => breakpoint?.xl ?? false)
  const smAndUp = computed(() => breakpoint?.smAndUp ?? false)
  const mdAndUp = computed(() => breakpoint?.mdAndUp ?? false)
  const lgAndUp = computed(() => breakpoint?.lgAndUp ?? false)
  const smAndDown = computed(() => breakpoint?.smAndDown ?? false)
  const mdAndDown = computed(() => breakpoint?.mdAndDown ?? false)
  const lgAndDown = computed(() => breakpoint?.lgAndDown ?? false)
  const smOnly = computed(() => breakpoint?.smOnly ?? false)
  const mdOnly = computed(() => breakpoint?.mdOnly ?? false)
  const lgOnly = computed(() => breakpoint?.lgOnly ?? false)
  const xlOnly = computed(() => breakpoint?.xlOnly ?? false)

  return { xs, sm, md, lg, xl, smAndUp, mdAndUp, lgAndUp, smAndDown, mdAndDown, lgAndDown, smOnly, mdOnly, lgOnly, xlOnly }
}
