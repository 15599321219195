var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('div',{staticClass:"text-center margin-b-6"},[_c('div',{staticClass:"d-inline-flex align-center"},[_c('h1',{staticClass:"margin-a-0 padding-t-0 padding-x-4"},[_vm._v(" "+_vm._s(_setup.tripTypeStep.header)+" ")])]),_c('h3',{staticClass:"margin-a-0 padding-a-0 subtitle-1 font-weight-400"},[_vm._v(" "+_vm._s(_setup.tripTypeStep.subheader)+" ")])]),_c('v-row',[_vm._l((_setup.tripTypes),function(tripType,tripTypeIndex){return _c('v-col',{key:`trip-type-${tripType.label}-${tripTypeIndex}`,attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"border-radius-2x padding-a-4 border-2 border-solid font-medium text-center cursor-pointer transition-all transition-duration-300 transition-ease h-full d-flex",class:{
          'border-border-gray': !_setup.isTripTypeSelected(tripType.key),
          'border-primary': _setup.isTripTypeSelected(tripType.key),
          'flex-column align-center justify-end': _setup.smAndUp,
        },on:{"click":function($event){return _setup.selectTripType(tripType.key)}}},[(_setup.smAndUp)?_c('img',{staticClass:"min-h-76 max-h-100 margin-b-4",attrs:{"src":tripType.icon}}):_vm._e(),_c('span',{staticClass:"order-1 order-sm-0"},[_vm._v(_vm._s(tripType.label))]),_c(_setup.CURadioButton,{staticClass:"order-0 order-sm-1",class:{
            'margin-r-4': _setup.xs,
            'margin-t-2': _setup.smAndUp,
          },attrs:{"x-large":"","selected":_setup.isTripTypeSelected(tripType.key)}})],1)])}),_c('v-expand-transition',[(_setup.selectedTripType.description)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"align-center d-flex justify-center padding-x-4 text-body-1"},[_c('CUIcon',{staticClass:"margin-r-2",attrs:{"view-box":"0 0 24 24","color":"primary"}},[_vm._v(" info_outlined ")]),_c('p',{staticClass:"margin-t-0"},[_vm._v(_vm._s(_setup.selectedTripType.description))])],1)]):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"w-full",class:{
          'max-w-320': _setup.smAndUp,
        },attrs:{"color":"primary","disabled":!_setup.selectedTripType},on:{"click":_setup.onClickContinue}},[_vm._v(" Continue "),_c('CUIcon',{staticClass:"margin-l-2",attrs:{"view-box":"0 0 24 24"}},[_vm._v(" arrow_right")])],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }