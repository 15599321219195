<template>
  <div>
    <h2 class="margin-l-1 margin-t-9">
      {{ $t('quoteDetail.operatorInfo.MORE_COMPANIES_NEARBY') }}
    </h2>
    <v-slide-group
      v-if="$vuetify.breakpoint.lgAndUp"
      :style="{ 'margin-top': bids.length > 3 ? '6px' : '18px' }"
      multiple
      show-arrows
    >
      <v-slide-item
        v-for="(bid, bidIndex) in bids"
        :key="`${bid.bidIds[0]}-${bidIndex}`"
      >
        <BidCard
          :id="`provider-bidcard-${bidIndex}`"
          stacked
          carousel
          class="padding-x-2 padding-b-0"
          :style="{ 'padding-top': '2px' }"
          :bid="bid"
          :go-to="{
            name: 'provider-detail',
            params: {
              quoteId: quoteId,
              providerId: bid.companyId,
            },
          }"
          :is-elite="isElite"
          :show-usd="isPickupInCanada"
          :is-branded-quote-flow-enabled="isBrandedQuoteFlowEnabled"
        />
      </v-slide-item>
    </v-slide-group>
    <div v-else class="margin-b-20" :style="{ 'margin-top': '18px' }">
      <BidCard
        v-for="(bid, bidIndex) in bids"
        :id="`provider-bidcard-${bidIndex}`"
        :key="`${bid.bidIds[0]}-${bidIndex}`"
        class="margin-b-3"
        :bid="bid"
        :go-to="{
          name: 'provider-detail',
          params: {
            quoteId: quoteId,
            providerId: bid.companyId,
          },
        }"
        :is-elite="isElite"
        :show-usd="isPickupInCanada"
        :is-branded-quote-flow-enabled="isBrandedQuoteFlowEnabled"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Provide } from 'vue-property-decorator'
import BidCard from '@/components/BidCard.vue'
import { QuoteDetailV2, QuoteDetailV2Bid } from '../models/dto/QuoteDetailV2'
import { PartnerTypeId } from '@/utils/enum'

@Component({
  components: {
    BidCard,
  },
})
export default class BidCardCarousel extends Vue {
  @Provide('isInCarousel') isInCarousel = true
  @Prop({ type: Boolean, required: true }) isBrandedQuoteFlowEnabled: boolean

  @Prop({ type: Object, default: null }) readonly quoteDetail: QuoteDetailV2

  carouselIndex = 0

  get selectedCompanyId(): number {
    return parseInt(this.$route?.params?.providerId) || null
  }

  get bids(): QuoteDetailV2Bid[] {
    return (
      this.quoteDetail?.bids.filter(
        (bid) =>
          bid.partnerTypeId !== PartnerTypeId.Bronze &&
          bid.companyId !== this.selectedCompanyId
      ) || []
    )
  }

  get quoteId(): number {
    return this.quoteDetail?.quoteId
  }

  get isPickupInCanada(): boolean {
    return this.quoteDetail?.trips?.[0]?.stops?.[0]?.address?.country === 'CA'
  }

  get isElite(): boolean {
    return !!this.quoteDetail?.isElite
  }
}
</script>
