<template>
  <div
    class="d-flex"
    :class="{
      'flex-row justify-space-between align-start': $vuetify.breakpoint.smAndUp,
      'flex-column': $vuetify.breakpoint.xsOnly,
    }"
  >
    <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex flex-column">
      <div
        v-for="(vehicle, vehicleIndex) in vehicles"
        :id="`trip-info-vehicle-${vehicleIndex}-content`"
        :key="`${vehicle.vehicleTypeId}-${vehicleIndex}`"
      >
        {{ vehiclesPretty(vehicle) }}
      </div>
    </div>
    <div v-else>
      {{ formattedVehiclesList }}
    </div>
    <span id="trip-info-passenger-count">
      {{ passengerCount }} Passengers
    </span>
    <span>
      {{ tripTypeLabel }}
    </span>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TripVehicle } from '@/models/dto'
import { pluralize } from '@/utils/string'
import { vehicleTypeKeyToLabel } from '@/utils/vehicle';

@Component({})
export default class TripMetadata extends Vue {
  @Prop({ type: Array, required: true }) readonly vehicles: TripVehicle[]
  @Prop({ type: Number, required: true }) readonly passengerCount: number
  @Prop({ type: String, required: true }) readonly tripTypeLabel: string

  get formattedVehiclesList(): string {
    if (!this.vehicles) {
      return ''
    }
    return this.vehicles
      .map((vehicle) => this.vehiclesPretty(vehicle))
      .join(', ')
  }

  vehiclesPretty(vehicle): string {
    const label = pluralize(
      vehicle?.quantity,
      vehicle?.vehicleType?.label || vehicleTypeKeyToLabel(vehicle.vehicleType.key)
    )
    return `${vehicle?.quantity} ${label}`
  }
}
</script>
