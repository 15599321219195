<template>
  <CUModal :value="value" width="500px" @input="$emit('input', $event)">
    <template #title>
      Keep Vehicle On-Site
    </template>
    <template #text>
      Your party requires the vehicle to remain with you at the destination
      until it's time to depart. If you do not require the vehicle at your
      destination, we might be able to get you a better price.
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class SelfServeTripItineraryStopKeepOnSiteDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
}
</script>
