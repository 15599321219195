<template>
  <div
    class="position-absolute font-12 top-12 background-white padding-x-2 padding-y-1 rounded-pill font-weight-600"
    :class="{ 'left-8': carousel, 'left-12': !carousel }"
    style="box-shadow: 0px 0px 15px 0px #00000040;"
  >
    <InfoModal
      v-model="isOpen"
      :modal-detail="certifiedPartnerModalDetail"
      max-width="860px"
    >
      <template #activator="{ on, attrs }">
        <span
          class="text-gray font-13 cursor-pointer font-weight-600"
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('quoteDetail.bidCard.CERTIFIED_PARTNER') }}
        </span>
      </template>
    </InfoModal>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import InfoModal from '@/components/InfoModal.vue'
import { certifiedPartnerModalDetail } from '@/utils/infoModals';


@Component({
  components: {
    InfoModal
  },
})
export default class BidCardCertifiedPartnerBadge extends Vue {
  @Prop({ type: Boolean, default: false }) readonly carousel: boolean

  certifiedPartnerModalDetail = certifiedPartnerModalDetail
  isOpen = false
}

</script>
