<template>
  <svg xmlns="http://www.w3.org/2000/svg">
    <g>
      <rect
        x="0"
        y="0"
        :width="45 * scalar"
        :height="20 * scalar"
        :rx="5 * scalar"
        :ry="5 * scalar"
        stroke-linejoin="round"
        fill="white"
        :stroke-width="1 * scalar"
        :stroke="color"
      ></rect>
      <text
        :x="7 * scalar"
        :y="13.2 * scalar"
        :font-size="10 * scalar"
        font-family="Sans,Helvetica"
        :stroke="color"
        :stroke-width="0.5 * scalar"
      >
        Pickup
      </text>
    </g>
  </svg>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class LiveTrackingPickupMarker extends Vue {
  @Prop({ type: String, required: true, default: '#8EA8C0' })
  readonly color: string
  @Prop({ type: Number, required: true, default: 1 })
  readonly scalar: number
}
</script>
