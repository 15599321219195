<template>
  <div class="d-flex align-center">
    <div
      v-if="showAsNew"
      class="w-8 h-8 background-primary margin-l-n4 margin-r-2 border-radius-round"
    />
    {{ quoteId }}
  </div>
</template>

<script lang="ts">
import { TableViewQuote } from '@/models/dto'
import { isExpired } from '@/utils/quote'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class QuoteIndexId extends Vue {
  @Prop({ required: true }) readonly row: TableViewQuote

  get quoteId(): number {
    return this.row?.quoteId
  }

  get showAsNew(): boolean {
    return !this.seen && !this.expired
  }

  get seen(): boolean {
    return !!this.row?.seenByClient
  }

  get expired(): boolean {
    if (!this.row?.expirationDate) {
      return true
    }
    return isExpired(this.row?.expirationDate)
  }
}
</script>
