<template>
  <div :class="{ 'w-full-vw': $vuetify.breakpoint.xsOnly }">
    <v-row
      no-gutters
      align="center"
      class="team-row"
      @click="handleExpandClick"
    >
      <v-col class="shrink">
        <v-row no-gutters align="center" :style="{ width: 'max-content' }">
          <v-col v-if="team.lightLogoUrl" cols="auto">
            <img
              :width="$vuetify.breakpoint.xsOnly ? 32 : 42"
              class="margin-r-2 border-radius-round"
              :class="{
                'mobile-profile-icon margin-y-2 margin-l-3 border-radius-round':
                  $vuetify.breakpoint.xsOnly,
              }"
              :src="team.lightLogoUrl"
            />
          </v-col>
          <v-col class="shrink white-space-nowrap">
            <p
              class="margin-a-0 font-medium font-20"
              :style="
                !team.lightLogoUrl
                  ? $vuetify.breakpoint.smAndUp
                    ? 'margin-left: 17px;'
                    : 'margin-left: 12px;'
                  : ''
              "
            >
              {{ team.name }}
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.xsOnly"
        :style="{ 'margin-bottom': '-6px' }"
      >
        <OrganizationTeamActionsMenu
          :index="index"
          :team="team"
          :teams="teams"
          @upload-image="$emit('upload-image', $event)"
        />
      </v-col>
      <v-col v-if="$vuetify.breakpoint.smAndUp" cols="auto">
        <OrganizationTeamActionsMenu
          :index="index"
          :team="team"
          :teams="teams"
          @upload-image="$emit('upload-image', $event)"
          @success="$emit('success')"
        />
      </v-col>
      <v-col v-if="$vuetify.breakpoint.xsOnly" cols="auto">
        <CUIcon
          view-box="0 0 24 24"
          color="gray"
          class="mobile-expansion-arrow margin-r-2 cursor-pointer"
          :class="{
            flipped: expanded,
          }"
        >
          keyboard_arrow_down
        </CUIcon>
      </v-col>
    </v-row>
    <v-expand-transition>
      <CUDataTable
        v-if="!$vuetify.breakpoint.xsOnly || expanded"
        :columns="columns"
        :items="items"
        :loading="loading"
        item-key="customerId"
        no-data-text="No users found"
        dense
        :options.sync="tableOptions"
        :server-items-length="itemsLength"
        :hide-default-header="$vuetify.breakpoint.xs"
        mobile-view-on-breakpoint="xs"
        v-on="$listeners"
        @refresh="updateTable"
      >
        <template #mobileLayout="{ item }">
          <tr :style="{ height: 'inherit' }">
            <td>
              <div
                class="d-flex flex-column font-16 font-medium margin-y-0 padding-x-2 padding-y-2"
              >
                <p class="margin-t-0">{{ item.name }}</p>
                <p class="font-14 margin-t-0">{{ item.email }}</p>
                <p class="font-14 margin-t-0">
                  {{ item.title ? `${item.title} | ` : '' }}{{ item.role }}
                </p>
                <div v-if="item.isSignedUp" class="font-14">
                  <a
                    v-if="item.sentEmailStatus === 'unsent'"
                    class="font-bold"
                    @click="resendInvite(item)"
                  >
                    Resend Invitation
                  </a>
                  <v-progress-circular
                    v-else-if="item.sentEmailStatus === 'sending'"
                    :size="15"
                    :width="2"
                    indeterminate
                    color="primary"
                  />
                  <p
                    v-else-if="item.sentEmailStatus === 'sent'"
                    class="text-primary margin-a-0"
                  >
                    Sent!
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </CUDataTable>
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import OrganizationTeamActionsMenu from '@/components/OrganizationTeamActionsMenu.vue'
import CUDataTable from '@/components/CUDataTable.vue'
import { filter } from '@/utils/filter'
import customerAccount from '@/services/customerAccount'
import { DataOptions } from 'vuetify'
import { TableViewCustomer, Team, Role } from '@/models/dto'
import { DataTableColumn } from '@/models/DataTableColumn'
import OrganizationTeamMoreActions from '@/components/OrganizationTeamMoreActions.vue'

@Component({
  components: {
    OrganizationTeamActionsMenu,
    OrganizationTeamMoreActions,
    CUDataTable,
  },
})
export default class OrganizationTeam extends Vue {
  @Prop({ type: String, required: true }) readonly search: string

  @Prop({ type: Object, required: true }) readonly team: Team
  @Prop({ type: Number, required: true }) readonly index: number
  @Prop({ type: Array, required: true }) readonly teams: Team[]

  @Watch('search')
  async searchChanged(): Promise<void> {
    await this.updateTable()
    if (this.search !== '') {
      const parentCustomerTable = this.$refs.parentCustomerTable as any
      this.expanded = parentCustomerTable?.serverItemsLength > 0
    } else {
      this.expanded = false
    }

    this.tableOptions.page = 1
  }

  @Watch('team')
  teamChanged(): void {
    this.updateTable()
  }

  expanded = false
  loading = false
  tableOptions: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortBy: [],
    sortDesc: [],
  }

  columns: DataTableColumn[] = [
    {
      _t_id: '3f3a68d2-8feb-41cc-98ff-674eb40fe005',
      elementId: 'name',
      text: 'Name',
      value: 'firstName',
      type: 'text',
      computedText: (row: TableViewCustomer) =>
        `${row.firstName} ${row.lastName}`,
    },
    {
      _t_id: 'edb46ba6-1429-4332-9d74-6f3c1ab258cf',
      elementId: 'title',
      text: 'Title',
      value: 'title',
      type: 'text',
    },
    {
      _t_id: 'cbf63228-804e-41b8-9e57-485f9b5df77a',
      elementId: 'email',
      text: 'Email',
      value: 'email',
      type: 'text',
    },
    {
      _t_id: '9ac992fe-1361-49b8-83d1-9a3d0d7414d9',
      elementId: 'role',
      text: 'Role',
      value: 'role',
      computedText: (row: TableViewCustomer) => {
        return row.roles.find(
          (role: Role) => role.roleName === 'customer_account_admin'
        )
          ? 'Admin'
          : 'User'
      },
      type: 'text',
    },
    {
      _t_id: '0ba7b445-a04e-48a1-b9b4-21da189096cd',
      elementId: 'more-actions',
      text: '',
      value: '',
      type: 'slot',
      component: OrganizationTeamMoreActions,
    },
  ]

  items = []
  itemsLength = 0

  created(): void {
    this.updateTable()
  }

  handleExpandClick(): void {
    if (this.$vuetify.breakpoint.xsOnly) {
      this.expanded = !this.expanded
    }
  }

  async updateTable(): Promise<void> {
    const firstNameSearchFilter = {
      column: {
        _t_id: 'text_search_cust',
        value: 'firstName',
        filterType: 'contains',
      },
      value: this.search,
    }
    const lastNameSearchFilter = {
      column: {
        _t_id: 'text_search_cust_1',
        value: 'lastName',
        filterType: 'contains',
      },
      value: this.search,
    }
    const emailSearchFilter = {
      column: {
        _t_id: 'text_search_cust_2',
        value: 'email',
        filterType: 'contains',
      },
      value: this.search,
    }
    const customerAccountFilter = {
      column: {
        _t_id: 'c6f1ca74',
        value: 'customerAccountId',
        filterType: 'eq',
      },
      value: this.team.customerAccountId,
    }
    const customerDataFilter = filter()
    const filterParentAnd = customerDataFilter.createParent('and')
    const filterParent2And = customerDataFilter.createParent('or')
    customerDataFilter.add(filterParentAnd, customerAccountFilter)

    if (this.search) {
      customerDataFilter.add(filterParent2And, firstNameSearchFilter)
      customerDataFilter.add(filterParent2And, lastNameSearchFilter)
      customerDataFilter.add(filterParent2And, emailSearchFilter)
    }

    const filters = customerDataFilter.asQueryParams()

    this.loading = true
    const {
      data: { resultList, count },
    } = await customerAccount.customersTableView({
      filters,
      page: this.tableOptions.page,
      pageSize: this.tableOptions.itemsPerPage,
    })
    this.itemsLength = count
    this.items = resultList.map((item) => {
      return {
        ...item,
        name: `${item.firstName} ${item.lastName}`,
        sentEmailStatus: 'unsent',
      }
    })
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors.scss';

.customer-data-table::v-deep {
  td,
  th {
    font-size: 0.875rem !important;
  }

  tbody > tr {
    td:nth-child(1) {
      width: 25%;
    }
    td:nth-child(2) {
      width: 20%;
    }
    td:nth-child(3) {
      width: 40%;
    }
    td:nth-child(4) {
      width: 15%;
    }
  }
}

.v-icon {
  &.mobile-expansion-arrow {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
    &.flipped {
      transform: rotate(-180deg);
      transition: transform 0.2s linear;
    }
    &:after {
      display: none;
    }
  }
}

.mobile-profile-icon {
  float: left;
}

.team-row {
  padding: 0 11px;
  height: 49px;
}

h3 {
  margin-top: -8px;
}

@media only screen and (max-width: 599px) {
  .v-data-footer__select {
    display: none;
  }
  h3 {
    margin-top: 1px;
    margin-bottom: -1px;
  }
  .team-row {
    border-bottom: 1px solid $border-gray;
    padding-right: 24px;
    height: 48px;
  }
}
</style>
