<template>
  <div>
    <h3 class="margin-b-4 font-bold">Trip Details</h3>
    <div v-if="passengers" class="d-flex align-center margin-l-5 margin-b-4">
      <CUIcon :color="themes?.light?.midnightGray500" view-box="0 0 24 24">groups</CUIcon>
      <span class="font-medium margin-t-0 margin-l-3">Passengers x{{passengers}} </span>
    </div>
    <div v-if="specialRequirements.length">
      <h4 class="text-midnight-gray-100 margin-b-3">Special requirements</h4>
      <div
        v-for="(specialRequirement, specialRequirementIndex) in specialRequirements"
        :key="`specialRequirement-${specialRequirementIndex}-${specialRequirement.key}`"
        class="d-flex align-start margin-l-5"
      >
        <CUIcon :color="themes?.light?.midnightGray500" view-box="0 0 24 24">{{ getRequirementIconByKey(specialRequirement.key) }}</CUIcon>
        <div class="margin-l-3 margin-b-1">
          <p class="font-medium margin-t-0">{{ specialRequirement.label }}</p>
          <p class="text-gray-light margin-t-0 font-14 text-midnight-gray-300">{{ specialRequirement.description }}</p>
        </div>
      </div>
    </div>

    <div v-if="amenities.length">
      <h4 class="text-midnight-gray-100 margin-b-3">Added Extras</h4>
      <div
        v-for="(amenity, amenityIndex) in amenities"
        :key="`specialRequirement-${amenityIndex}-${amenity.amenityTypeKey}`"
        class="d-flex align-start margin-l-5 margin-b-1"
      >
        <CUIcon :color="themes?.light?.midnightGray500" view-box="0 0 24 24">{{ getAmenityIconByKey(amenity.amenityTypeKey) }}</CUIcon>
        <div class="margin-l-3 margin-b-1">
          <p class="font-medium margin-t-0">{{ amenity.label }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import VehicleOptionVehicleList from "@/components/VehicleOptionVehicleList.vue";
import VehicleOptionImage from "@/components/VehicleOptionImage.vue";
import { computed } from "vue";
import { VehicleTypeKey } from "@/utils/enum";
import { SalesBotAmenityOption } from "@/models/dto/SalesbotV2VehicleOptions";
import { SpecialRequirement } from "@/models/dto/SalesBotRequirements";
import { useTheme } from "@/composables/useTheme";
import { getRequirementIconByKey } from "@/utils/icon";
import { getAmenityIconByKey } from "@/utils/amenity";

const { themes } = useTheme()

const props = defineProps<{
  passengers?: number,
  specialRequirements: SpecialRequirement[],
  amenities: SalesBotAmenityOption[]
}>()

</script>
