<template>
  <div>
    <v-btn
      color="cup500"
      class="w-full text-white font-weight-600 border-radius-8"
      :class="{
        'min-h-48 h-48 max-h-48': isWidthLessThan360,
        'min-h-56 h-56 max-h-56': !isWidthLessThan360,
      }"
      :loading="loading"
      :disabled="disabled"
      @click="$emit('click')"
    >
      {{ text }}
    </v-btn>
    <div class="d-flex justify-center">
      <p class="text-red font-medium font-14" v-if="error">{{ formattedError }}</p>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CheckoutSubmitButton extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: String, default: 'Reserve now' }) text!: string
  @Prop({ type: String }) error!: string

  get isWidthLessThan360(): boolean {
    return this.$vuetify.breakpoint.width < 360
  }

  get formattedError(): string {
    return this.error.replace(/;.*/, '')
  }
}
</script>
