<template>
  <InfoModal v-model="isOpen" :modal-detail="valuePropDetail" max-width="860px">
    <template #activator="{ on, attrs }">
      <div
        class="d-flex align-center h-full font-16 font-medium"
        :class="{
          'padding-x-4 padding-y-2': isSalesBot,
          'padding-a-4': !isSalesBot,
        }"
        v-bind="attrs"
        v-on="on"
        @click="handleOpen"
      >
        <CUIcon
          class="flex-shrink-0"
          :class="{
            'w-40 h-40 text-cup-500': isSalesBot,
            'w-32 h-32': !isSalesBot,
          }"
          view-box="0 0 24 24"
        >
          {{ icon }}
        </CUIcon>
        <span
          class="margin-l-2"
          :class="{ 'text-white font-14 font-weight-600': isSalesBot }"
        >
          {{ label }}
        </span>
      </div>
    </template>
  </InfoModal>
</template>

<script lang="ts">
import { Page, ValuePropKey } from '@/utils/enum'
import { Vue, Component, Prop } from 'vue-property-decorator'
import InfoModal from '@/components/InfoModal.vue'
import {
  bookingProtectionModalDetail,
  vehicleLocationTrackingModalDetail,
  liveSupportModalDetail,
  qualifiedOperatorsModalDetail,
} from '@/utils/infoModals'
import { InfoModalDetail } from '@/models/InfoModalDetail'

@Component({
  name: 'CharterUPValueProp',
  components: {
    InfoModal,
  },
})
export default class CharterUPValueProp extends Vue {
  @Prop({ type: String, required: true }) readonly valuePropKey: ValuePropKey
  @Prop({ type: String, required: true }) readonly page!: string

  isOpen = false

  get label(): string {
    return this.$vuetify.breakpoint.mdAndUp
      ? this.valuePropDetail.label
      : this.valuePropDetail.shortLabel
  }

  get icon(): string {
    return this.valuePropDetail?.icon?.name || ''
  }

  get valuePropDetail(): InfoModalDetail {
    const key = this.valuePropKey

    const map: Record<ValuePropKey, InfoModalDetail> = {
      [ValuePropKey.BookingProtection]: bookingProtectionModalDetail,
      [ValuePropKey.Tracking]: vehicleLocationTrackingModalDetail,
      [ValuePropKey.LiveSupport]: liveSupportModalDetail,
      [ValuePropKey.QualifiedOperators]: qualifiedOperatorsModalDetail,
    }

    return map[key]
  }

  get isSalesBot(): boolean {
    return this.page === Page.SalesBot
  }

  trackOpen(key: ValuePropKey): void {
    const eventMap: Record<ValuePropKey, string> = {
      [ValuePropKey.BookingProtection]: 'open_booking_protection_value_prop',
      [ValuePropKey.Tracking]: 'open_vehicle_tracking_value_prop',
      [ValuePropKey.LiveSupport]: 'open_live_support_value_prop',
      [ValuePropKey.QualifiedOperators]: 'open_qualified_operators_value_prop',
    }

    this.$ga4.trackEvent(eventMap[key], {
      page: this.page,
      isSelfServe: false,
    })
  }

  handleOpen(): void {
    this.trackOpen(this.valuePropKey)
  }
}
</script>
