<template>
  <div
    class="border-radius-2x border-2 border-solid padding-a-3 cursor-pointer"
    :class="{
      'border-primary': selected,
      'border-border-gray': !selected,
    }"
    @click="select"
  >
    <RecommendedBanner v-if="showRecommendedBanner" />
    <div class="d-flex flex-column"
    >
      <div class="d-flex">
        <CURadioButton
          class="margin-r-2 margin-b-2"
          large
          :selected="selected"
        />

        <div class="d-flex flex-column w-full">
          <VehicleOptionVehicleList :vehicles="vehicles" />
          <div class="d-flex align-center text-gray-subtle-text-2 margin-t-2">
            <CUIcon view-box="0 0 24 24" class="h-16 w-16 margin-r-1">
              groups
            </CUIcon>
            <div class="font-14">
              Up to
              <span class="font-medium font-14">{{ passengerCapacity }}</span>
              passengers
            </div>
          </div>
        </div>

        <div class="d-flex flex-column align-end padding-x-2">
          <span v-if="totalAmount" class="font-20 font-medium text-right">
            {{ totalDollarsFormatted }}.
            <span class="font-14 margin-l-n1">{{ totalCents }}</span>
          </span>
          <VehicleOptionImage class="margin-y-2" :vehicle-type-keys="vehicleTypeKeys" />
        </div>
      </div>

      <VehicleOptionDescription :vehicle-type-keys="vehicleTypeKeys" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, defineEmits } from 'vue'
import CURadioButton from '@/components/CURadioButton.vue'
import RecommendedBanner from '@/components/RecommendedBanner.vue'
import VehicleOptionDescription from '@/components/VehicleOptionDescription.vue'
import VehicleOptionImage from '@/components/VehicleOptionImage.vue'
import VehicleOptionVehicleList from '@/components/VehicleOptionVehicleList.vue'

import { roundedCurrencyFilter } from '../utils/string'
import { SalesBotVehicleOption } from "@/models/dto/SalesbotV2VehicleOptions"

const props = defineProps<{ vehicleOption: SalesBotVehicleOption }>()
const emit = defineEmits(['select'])

const selected = computed(() => props.vehicleOption?.selected)
const showRecommendedBanner = computed(() => props.vehicleOption?.recommended)
const vehicles = computed(() => props.vehicleOption.vehicles)
const vehicleTypeKeys = computed(() => vehicles.value.map(({ vehicleTypeKey }) => vehicleTypeKey))
const passengerCapacity = computed(() => props.vehicleOption.maxPassengerCapacity)

const totalAmount = computed(() => props.vehicleOption.amount)
const totalDollars = computed(() => Math.floor(totalAmount.value))
const totalDollarsFormatted = computed(() => totalDollars.value ? roundedCurrencyFilter(totalDollars.value) : '')
const totalCents = computed(() => {
  const cents = totalAmount.value - totalDollars.value
  return cents.toFixed(2).split('.')[1]
})

const select = () => emit('select')
</script>
