<template>
  <div
    :class="{ 'w-full': $vuetify.breakpoint.xsOnly }"
    class="d-flex flex-column"
  >
    <div class="d-flex justify-space-between align-center">
      <h2 class="margin-b-2">Rider Data</h2>
      <div class="d-flex align-center">
        <CUSelect
          v-if="contracts.length > 1"
          v-model="selectedContractId"
          :items="contracts"
          item-text="name"
          item-value="id"
          placeholder="Select Contract"
          outlined
          flat
          dense
          hide-details
          :style="{ 'margin-top': '7px' }"
          class="margin-r-3 w-248"
        />
        <v-menu
          v-model="dateFilterOpen"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          bottom
          right
          origin="top left"
          :nudge-top="4"
        >
          <template #activator="{ on }">
            <v-btn
              color="white"
              outlined
              small
              class="padding-x-4 border-gray-mid-light"
              v-on="on"
            >
              <CUIcon view-box="0 0 24 24" color="primary">
                calendar_today
              </CUIcon>
              <p class="padding-l-2 margin-a-0 text-black">
                {{
                  startDate === endDate
                    ? $dayjs(startDate).format('MM/DD/YY')
                    : `${$dayjs(startDate).format('MM/DD/YY')} - ${$dayjs(
                        endDate
                      ).format('MM/DD/YY')}`
                }}
              </p>
            </v-btn>
          </template>
          <div class="max-w-320 padding-t-3 background-white">
            <div class="padding-a-3">
              <v-chip
                v-for="(predefinedFilter,
                predefinedFilterIndex) in datePredefined"
                :key="`${predefinedFilterIndex}-${predefinedFilter._t_id}`"
                :outlined="!predefinedFilter.active"
                color="primary"
                class="margin-r-2 margin-b-2 cursor-pointer"
                @click.native="selectPredefined(predefinedFilter)"
              >
                {{ predefinedFilter.text }}
              </v-chip>
            </div>
            <div v-if="singleDateSelected" class="padding-a-3">
              <CUDatePicker
                v-model="singleDate"
                label="Date"
                class="max-w-120"
                top
                right
                origin="bottom left"
                hide-details
              />
            </div>
            <div v-else class="d-flex justify-space-between padding-a-3">
              <CUDatePicker
                v-model="startDate"
                label="Start"
                class="max-w-120"
                top
                right
                origin="bottom left"
                hide-details
              />
              <CUDatePicker
                v-model="endDate"
                label="End"
                class="max-w-120"
                top
                left
                origin="bottom right"
                hide-details
              />
            </div>
          </div>
        </v-menu>
        <v-btn
          color="primary"
          outlined
          small
          class="margin-l-2"
          @click="exportRiderData"
        >
          Export
        </v-btn>
      </div>
    </div>

    <OrganizationRiderDataChart
      v-show="!allRoutesEmpty"
      :routes="routes"
      :items-length="itemsLength"
    />
    <NotFoundLayout
      v-show="allRoutesEmpty"
      :img="require('@/assets/images/no-quotes-graphic.png')"
      class="w-600 align-self-center"
    >
      <template #header>
        No rider data here.
      </template>
      <template #content>
        Try a different date to see all routes.
      </template>
    </NotFoundLayout>
    <v-tabs
      v-model="selectedRouteIndex"
      class="rider-route-tabs margin-b-5"
      slider-color="primary"
    >
      <v-tab
        v-for="(route, routeIndex) in routes"
        :key="`route-${route.routeName}-${routeIndex}`"
      >
        {{ route.routeName }}
      </v-tab>
    </v-tabs>
    <div v-if="routes[selectedRouteIndex]">
      <v-chip v-if="showScans" class="font-weight-bold">
        {{ `Total Scans ${routes[selectedRouteIndex].count}` }}
      </v-chip>
      <v-chip v-else class="font-weight-bold">
        {{
          `Total Riders ${
            routes[selectedRouteIndex].boardedCount
          }, Total Scheduled ${
            routes[selectedRouteIndex].scheduledCount
          }, Total Released ${routes[selectedRouteIndex].releasedCount}${
            singleDateSelected
              ? `, Total Available ${Math.max(
                  0,
                  routes[selectedRouteIndex].passengerCapacity +
                    routes[selectedRouteIndex].releasedCount -
                    routes[selectedRouteIndex].boardedCount -
                    routes[selectedRouteIndex].scheduledCount
                )}`
              : ''
          }`
        }}
      </v-chip>
    </div>
    <CUDataTable
      id="rider-index-table"
      ref="parentRiderDataTable"
      :items="items"
      item-key="employeeId"
      :columns="filteredColumns"
      :loading="false"
      :options.sync="tableOptions"
      :server-items-length="itemsLength"
      :hide-default-footer="itemsLength <= tableOptions.itemsPerPage"
      :hide-default-header="$vuetify.breakpoint.xsOnly"
      class="rider-data-table"
      @update:options="getTable"
    >
      <template #mobileLayout="{ item }">
        <tr>
          <td class="padding-t-4 padding-b-6 padding-r-8">
            <v-row class="padding-t-2">
              <b class="margin-r-1">Employee ID:</b>
              {{ item.employeeId }}
            </v-row>
            <v-row>
              <div>
                <b class="margin-r-1">Pickup:</b>
                {{ item.pickupLocation }}
              </div>
            </v-row>
            <v-row>
              <div>
                <b class="margin-r-1">Dropoff:</b>
                {{ item.dropoffLocation }}
              </div>
            </v-row>
          </td>
        </tr>
      </template>
    </CUDataTable>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { DataOptions, DataTableHeader } from 'vuetify'
import OrganizationRiderDataChart from '@/components/OrganizationRiderDataChart.vue'
import rider from '@/services/rider'
import { filter } from '@/utils/filter'
import { saveAs } from 'file-saver'
import { DataTableColumn } from '@/models/DataTableColumn'
import { TableViewRider } from '@/models/dto/TableViewRider'
import { CustomerAccount, SimpleContract } from '@/models/dto'
import NotFoundLayout from '@/layouts/NotFoundLayout.vue'
import { noAbstractDatesPredefined, calculatedValues } from '@/utils/predefined'
import { PredefinedFilter } from '@/models/TableView'
import CUDatePicker from './CUDatePicker.vue'
import quotes from '@/services/quotes'
import auth from '@/store/modules/auth'

@Component({
  components: {
    OrganizationRiderDataChart,
    NotFoundLayout,
    CUDatePicker,
  },
})
export default class OrganizationRiderData extends Vue {
  startDate = this.$dayjs().local().startOf('day').format('YYYY-MM-DD')
  endDate = this.$dayjs().local().startOf('day').format('YYYY-MM-DD')
  singleDate = this.$dayjs().local().startOf('day').format('YYYY-MM-DD')
  dateFilterModalOpen = false
  dateFilterOpen = false
  datePredefined = noAbstractDatesPredefined
  debounce: any = null
  selectedPredefinedName: string = null
  contracts: SimpleContract[] = []
  selectedContractId: number = null

  selectedRouteIndex: number = null
  routes = []

  expanded = false
  tableOptions: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortBy: [],
    sortDesc: [],
  }

  columns: DataTableColumn[] = [
    {
      _t_id: '700c0e62-dc11-4da0-a1b1-f5d2280ff45e',
      elementId: 'employeeId',
      text: 'Employee ID',
      value: 'employeeId',
      type: 'text',
    },
    {
      _t_id: '55b7e3cb-4ab1-4fdc-93d0-a3fa83b62a17',
      elementId: 'riderGroupName',
      text: 'Group Name',
      value: 'riderGroupName',
      type: 'text',
    },
    {
      _t_id: '862633e8-375e-4e27-b41f-aa751ab6cfb2',
      elementId: 'pickupLocation',
      text: 'Pickup',
      value: 'pickupLocation',
      type: 'text',
    },
    {
      _t_id: 'd016afa0-4bc2-4dea-bd23-1b0e330510bd',
      elementId: 'dropoffLocation',
      text: 'Drop-off',
      value: 'dropoffLocation',
      type: 'text',
    },
    {
      _t_id: '75915f2c-4908-48ec-a58a-4101217dce3e',
      elementId: 'createdOn',
      text: 'Created On',
      value: 'createdOn',
      type: 'text',
      computedText: (row: TableViewRider) =>
        this.formattedDatetime(row.createdOn),
    },
    {
      _t_id: 'f66fa1d1-febb-4370-972f-f512b30926ca',
      elementId: 'boardedOn',
      text: 'Boarded On',
      value: 'boardedOn',
      type: 'text',
      computedText: (row: TableViewRider) =>
        this.formattedDatetime(row.boardedOn),
    },
    {
      _t_id: '51133c76-25ed-4e21-b6a3-e7def10d0076',
      elementId: 'releasedOn',
      text: 'Released On',
      value: 'releasedOn',
      type: 'text',
      computedText: (row: TableViewRider) =>
        this.formattedDatetime(row.releasedOn),
    },
    {
      _t_id: 'c8c8e7d7-e0fc-4798-afc0-55065efa1a1e',
      elementId: 'status',
      text: 'Status',
      value: 'status',
      type: 'text',
      classes: 'font-medium',
    },
  ]

  headers: DataTableHeader[] = [
    {
      text: 'Employee ID',
      value: 'employeeId',
      sortable: false,
      class: 'header-box',
    },
    {
      text: 'Group Name',
      value: 'riderGroupName',
      sortable: false,
      class: 'header-box',
    },
    {
      text: 'Pickup',
      value: 'pickupLocation',
      sortable: false,
      class: 'header-box',
    },
    {
      text: 'Drop-off',
      value: 'dropoffLocation',
      sortable: false,
      class: 'header-box',
    },
    {
      text: 'Created On',
      value: 'createdOn',
      sortable: false,
      class: 'header-box',
    },
    {
      text: 'Boarded On',
      value: 'boardedOn',
      sortable: false,
      class: 'header-box',
    },
    {
      text: 'Status',
      value: 'status',
      class: 'header-box',
      sortable: false,
    },
  ]
  exportName = 'RiderScansData'
  items: TableViewRider[] = []
  itemsLength = 0

  @Prop({ type: Object }) readonly customerAccount: CustomerAccount

  async created(): Promise<void> {
    await this.getRouteMetrics()
    this.getTable()
  }

  @Watch('selectedRouteIndex')
  routeSelectionChanged() {
    this.tableOptions.page = 1
    this.getTable()
  }

  @Watch('startDate')
  @Watch('endDate')
  @Watch('selectedContractId')
  reloadRouteMetrics(): void {
    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }
    this.debounce = window.setTimeout(async () => {
      await this.getContracts()
      await this.getRouteMetrics()
      this.getTable()
    }, 50)
  }

  @Watch('singleDate')
  singleDateChanged(): void {
    this.startDate = this.singleDate
    this.endDate = this.singleDate
  }

  formattedDatetime(datetime): string {
    if (!datetime) {
      return ''
    }
    return this.$dayjs(datetime).format('MM/DD/YYYY h:mm A z')
  }

  get filteredColumns(): DataTableColumn[] {
    return this.columns.filter((column) => {
      const isRiderGroup = column.elementId === 'riderGroupName'
      return (
        !isRiderGroup || (isRiderGroup && this.customerAccount?.hasRiderGroups)
      )
    })
  }

  get singleDateSelected(): boolean {
    return (
      this.selectedPredefinedName === 'Today' ||
      this.selectedPredefinedName === 'Tomorrow'
    )
  }

  get allRoutesEmpty(): boolean {
    return this.routes.map((route) => route.count).every((count) => count === 0)
  }

  get showScans(): boolean {
    return this.routes.every(
      (route) =>
        !route.passengerCapacity &&
        !route.boardedCount &&
        !route.releasedCount &&
        !route.scheduledCount
    )
  }

  async mounted(): Promise<void> {
    await this.getContracts()
    this.selectedContractId = this.contracts[0]?.id || null
    const todayFilter = this.datePredefined.find(
      (filter) => filter.text === 'Today'
    )
    todayFilter.active = true
    this.selectedPredefinedName = todayFilter.text
  }

  async exportRiderData(): Promise<void> {
    if (!this.startDate || !this.endDate) {
      return
    }

    let startDate = this.startDate
    let endDate = this.endDate
    endDate = this.$dayjs(endDate).add(1, 'day').format('YYYY-MM-DD') // New endpoint uses end date exclusive

    const payload = {
      customerAccountId: auth.customerAccount.customerAccountId,
      dateRange: { from: startDate, to: endDate },
    }

    try {
      const exportData = await rider.routesTableExport(payload)
      var blob = new Blob([exportData.data], { type: 'text/csv;charset=utf-8' })
      await saveAs(blob, this.exportName)
    } catch (e) {
      console.warn('Export rider data failed.')
    }
  }

  async getContracts(): Promise<void> {
    const result = await quotes.getContractsByCustomerAccountId(
      auth.customerAccount.customerAccountId
    )
    this.contracts = result.data
  }

  async getRouteMetrics(): Promise<void> {
    if (!this.startDate || !this.endDate || !this.selectedContractId) {
      return
    }

    let startDate = this.startDate
    let endDate = this.endDate
    endDate = this.$dayjs(endDate).add(1, 'day').format('YYYY-MM-DD') // New endpoint uses end date exclusive

    const payload = {
      customerAccountId: auth.customerAccount.customerAccountId,
      contractId: this.selectedContractId,
      dateRange: { from: startDate, to: endDate },
    }
    const { data } = await rider.routeMetrics(payload)
    this.routes = data
  }

  async getTable(): Promise<void> {
    const routeFilter = filter()
    const filterParentAnd = routeFilter.createParent('and')
    const filterRouteName = {
      column: {
        _t_id: '0c620ec0-49ad-4c32-b230-1424ad9d9561',
        value: 'routeId',
        filterType: 'eq',
      },
      value: this.routes?.[this.selectedRouteIndex]?.routeId || -1,
    }
    routeFilter.add(filterParentAnd, filterRouteName)

    const dateFilterParentAnd = routeFilter.createParent('and')
    const filterStartDate = {
      column: {
        _t_id: '0e04d478-467f-4fdb-be46-7891ec2e5605',
        value: 'scheduledOn',
        filterType: 'gte',
      },
      value: this.startDate,
    }
    const filterEndDate = {
      column: {
        _t_id: '36361acc-d5ad-4a73-8b4d-3a4d16212d71',
        value: 'scheduledOn',
        filterType: 'lte',
      },
      value: this.endDate,
    }
    routeFilter.add(dateFilterParentAnd, filterStartDate)
    routeFilter.add(dateFilterParentAnd, filterEndDate)

    const filterGrandParentAnd = routeFilter.createParent('and')
    routeFilter.add(filterGrandParentAnd, dateFilterParentAnd)
    routeFilter.add(filterGrandParentAnd, filterParentAnd)

    const {
      data: { resultList, count },
    } = await rider.routesTableView({
      page: this.tableOptions.page,
      pageSize: this.tableOptions.itemsPerPage,
      filters: routeFilter.asQueryParams(),
    })
    this.itemsLength = count
    this.items = resultList
  }

  openDateFilterModal(): void {
    this.dateFilterModalOpen = true
  }

  async selectPredefined(predefined: PredefinedFilter): Promise<void> {
    await this.unselectPredefineds()
    const foundFilter = this.datePredefined.find(
      (filter) => filter._t_id === predefined._t_id
    )
    if (foundFilter) {
      foundFilter.active = true
      this.selectedPredefinedName = foundFilter.text
      this.startDate = await calculatedValues[foundFilter.controls[0].value]()
      this.endDate = await calculatedValues[foundFilter.controls[1].value]()
    }
  }
  unselectPredefineds(): void {
    this.datePredefined = this.datePredefined.map((predefined) => {
      return { ...predefined, active: false }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors.scss';

.rider-route-tabs {
  border-bottom: none !important;

  .v-tab::before,
  .v-tab--active::before,
  .v-tab--active:hover::before {
    opacity: 0;
    transition: none;
  }

  .v-tab--active {
    font-family: 'Inter Bold', Arial, sans-serif;
    color: $primary;
  }
}

.rider-data-table::v-deep {
  td,
  th {
    line-height: 21px;
    height: 60px;
    font-size: 0.875rem !important;
  }

  .last-quote-col {
    text-align: right;
    padding-right: 40px !important;
  }

  .padding-left-40 {
    padding-left: 44px;
  }

  tbody > tr {
    td:nth-child(1) {
      width: 20%;
    }
    td:nth-child(2) {
      width: 15%;
    }
    td:nth-child(3) {
      width: 20%;
    }
    td:nth-child(4) {
      width: 20%;
    }
    td:nth-child(5) {
      width: 15%;
    }
    td:nth-child(6) {
      width: 15%;
    }
  }
}

.v-icon {
  &.mobile-expansion-arrow {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
    &.flipped {
      transform: rotate(-180deg);
      transition: transform 0.2s linear;
    }
    &:after {
      display: none;
    }
  }
}

.v-data-table {
  &.mobile {
    margin-bottom: 0;
    ::v-deep .v-data-table__wrapper {
      border-radius: 0px;
      border-width: 0px 0px 1px 0px !important;
      table {
        tbody {
          tr {
            border: none;
            td {
              border-width: 0px 0px 1px 0px !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .v-data-footer__select {
    display: none;
  }
}
</style>
