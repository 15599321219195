<template>
  <CUIcon
    v-if="!isConverted || isReservation"
    view-box="0 0 24 24"
    class="text-primary w-24 h-24"
    @click="unshare"
  >
    link_off
  </CUIcon>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'
import SharedTrip from '@/models/dto/SharedTrip'
import reservation from '@/services/reservation'
import alert from '@/store/modules/alert'
import quotes from '@/services/quotes'
import { EventBus } from '@/utils/eventBus'

@Component({})
export default class SharedTripsModalUnshareActions extends Vue {
  @Prop({ required: true }) readonly row: SharedTrip

  get isConverted(): boolean {
    return this.row.quoteIsConverted
  }

  get isReservation(): boolean {
    return !!this.row.reservationId
  }

  async unshare(): Promise<void> {
    try {
      if (this.row.reservationId) {
        await reservation.unshare(this.row.reservationId, this.row.userId)
      } else if (this.row.quoteId) {
        await quotes.unshare(this.row.quoteId, this.row.userId)
      }
      alert.add({
        text: 'Successfully unshared trip.',
        color: 'primary',
        title: 'Success',
        dismissible: true,
      })
      EventBus.$emit('update-shared-contacts')
    } catch (e) {
      console.error(e)
    }
  }
}
</script>
