<template>
  <v-row justify="center" class="margin-y-20">
    <v-col cols="auto">
      <v-img width="500" src="@/assets/images/oops@2x.png" class="margin-t-6 margin-b-3" />
      <h1 class="font-30 margin-b-10 margin-x-auto">
        Looks like this sign up link has expired.
      </h1>
      <p class="margin-t-4 margin-b-10">
        Please use the most recent sign-up link sent to your email. If you've already
        <br>
        signed up, you can log in at <router-link tag="a" to="/login">charterup.com/login</router-link>
      </p>
      <p class="margin-t-4 margin-b-0">
        If you need further assistance, please contact us at <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
        <br>
        or call <a :href="`tel:${supportNumber}`">{{ formattedSupportNumber }}</a>.
      </p>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { getSupportNumber, phoneFormatFilter } from '@/utils/phone'
import systemParameter from '@/store/modules/systemParameter'
import auth from '@/store/modules/auth'

// Computed properties for support details
const supportEmail = computed(() => systemParameter.supportEmail)
const supportNumber = computed(() => getSupportNumber(!!auth.customer?.isElite))
const formattedSupportNumber = computed(() => phoneFormatFilter(supportNumber.value))
</script>
