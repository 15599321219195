<template>
  <div>
    <v-tabs
      :value="value"
      slider-color="primary"
      @change="$emit('input', $event)"
    >
      <v-tab
        v-for="(trip, tripIndex) in trips"
        :id="`trip-tab-${tripIndex}`"
        :key="`trip-tab-${tripIndex}-${trip.tripId}`"
      >
        {{ trip.routeName || `Trip ${tripIndex + 1}` }}
      </v-tab>
    </v-tabs>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Trip } from '@/models/dto'

@Component({})
export default class TripTabs extends Vue {
  @Prop({ type: Number, required: true }) value!: number
  @Prop({ type: Array, required: true }) trips!: Trip[]
}
</script>
