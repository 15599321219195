<template>
  <span>
    <v-form ref="card-form" class="margin-x-2">
      <div
        v-show="!disputedCheckout"
        id="finix-form"
      />

      <v-row wrap v-if="disputedCheckout">
        <v-col cols="12">
          <CUTextField
            v-model="nameOnCard"
            :label="$t('creditCardForm.NAME.LABEL')"
            hide-details="auto"
            :rules="[(v) => !!v || $t('creditCardForm.NAME.RULES.REQUIRED')]"
            autocomplete="cc-name"
            :tab-index=1
            validate-on-blur
            required
          />
        </v-col>
        <v-col cols="12">
          <CUTextField
            v-model="cardNumber"
            v-mask="cardNumberMask"
            :label="$t('creditCardForm.NUMBER.LABEL')"
            :rules="[
              (v) => !!v || $t('creditCardForm.NUMBER.RULES.REQUIRED'),
              (v) => v.length >= 15 || $t('creditCardForm.NUMBER.RULES.VALID'),
            ]"
            hide-details="auto"
            autocomplete="cc-number"
            :tab-index=2
            validate-on-blur
            required
          >
            <template #append>
              <CUIcon
                :key="cardIconName"
                view-box="0 0 24 24"
                class="fade-in margin-t-n1 margin-r-4 w-40 h-40 z-20 cursor-default"
              >
                {{ cardIconName }}
              </CUIcon>
            </template>
          </CUTextField>
        </v-col>
        <v-col cols="6">
          <CUTextField
            v-model="expiration"
            v-mask="'##/####'"
            placeholder="MM/YY"
            :label="$t('creditCardForm.EXPIRATION.LABELV2')"
            :rules="[
              (v) => !!v || $t('creditCardForm.EXPIRATION.RULES.REQUIRED'),
              (v) =>
                /^(0[1-9]|1[0-2])\/(20[0-9]{2}|[0-9]{2})$/.test(v) ||
                $t('creditCardForm.EXPIRATION.RULES.VALID'),
              (v) =>
                isDateInFuture(v) || $t('creditCardForm.EXPIRATION.RULES.FUTURE'),
            ]"
            hide-details="auto"
            autocomplete="cc-exp"
            :tab-index=3
            validate-on-blur
            required
          />
        </v-col>
        <v-col cols="6">
          <CUTextField
            v-model="cvv"
            v-mask="'####'"
            :label="$t('creditCardForm.CVV.LABEL')"
            :rules="[
              (v) => !!v || $t('creditCardForm.CVV.RULES.REQUIRED'),
              (v) => v.length >= 3 || $t('creditCardForm.CVV.RULES.VALID'),
            ]"
            hide-details="auto"
            type="password"
            autocomplete="cc-csc"
            :tab-index=4
            validate-on-blur
            required
          />
        </v-col>
      </v-row>
    </v-form>

    <v-form ref="address-form" class="margin-x-2">
      <v-row>
        <!-- Always show the address picker, even for Finix forms -->
        <v-col cols="12" class="pb-0">
          <AddressPicker
            ref="address-picker"
            v-model="address"
            :disputedCheckout="disputedCheckout"
            is-payment-form
            required
            :label="$t('addressPicker.FORM.STREET1.LABEL')"
            :tab-index=5
          />
        </v-col>
        <v-col v-if="!isInGuestCheckout && !disputedCheckout" cols="12" class="pt-0">
          <div class="d-flex justify-space-between align-center">
            <v-checkbox
              v-show="!hideSaveForLater"
              v-model="saveCard"
              :label="$t('creditCardForm.SAVE_FOR_LATER')"
              class="margin-t-n1 text-midnight-gray-900"
              hide-details
            />
            <v-btn v-if="showCancel" text small color="error" @click="cancel">
              {{ $t('common.DISMISS') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </span>

</template>
<script lang='ts'>
import { Vue, Component, Watch, Prop, Inject } from 'vue-property-decorator'
import { detectCardType, cardMaskByType, buildMaskedCardNumber } from '@/utils/creditCard'
import { Address } from '@/models/dto'
import { FormattedPaymentMethod } from '../models/FormattedPaymentMethod'
import AddressPicker from '@/components/AddressPicker.vue'
import { finixConfig } from '@/utils/env'

const finixOptions = {
  showAddress: false,
  showLabels: true,
  labels: {
    name: 'Name',
    security_code: 'Security Code',
    address_line1: 'Address'
  },
  showPlaceholders: false,
  hideFields: [
    'address_line1',
    'address_line2',
    'address_state',
    'address_city',
    'address_postal_code',
    'address_region',
    'address_country',
  ],
  requiredFields: [
    'name',
  ],
  hideErrorMessages: false,
  errorMessages: {
    name: 'Name is required',
    number: 'Card number is required',
    expiration_date: 'Expiration date is required',
    security_code: 'Security code is required',
  },
  styles: {    // default styling for all fields
    default: {
      color: '#3c4853',
      border: '1px solid #a0aeba',
      borderRadius: '5px',
      padding: '12px',
      fontFamily: "'Inter Bold', Arial, sans-serif",
      fontWeight: 'normal',
      fontSize: '16px',
    },
    error: {
      color: '#3c4853',
    }
  },
}

@Component({ components: { AddressPicker } })
export default class CreditCardFormV2 extends Vue {
  @Inject({ from: 'isInGuestCheckout', default: false }) readonly isInGuestCheckout: boolean

  @Prop({ type: Boolean, required: false, default: false }) readonly showCancel!: boolean
  @Prop({ type: Boolean, required: false, default: false }) readonly disputedCheckout!: boolean
  @Prop({ type: Object, required: false, default: () => {}}) readonly value!: FormattedPaymentMethod
  @Prop({ type: Boolean, default: false }) readonly hideSaveForLater!: boolean;

  finixForm: any
  nameOnCard = ''
  cardNumber = ''
  expiration = ''
  cvv = ''
  saveCard = false
  address: Partial<Address> = {}

  @Watch('value', { immediate: true })
  onValueChange(): void {

    if (!this.disputedCheckout) {
      return
    }
    if (!this.value) {
      return
    }

    this.nameOnCard = this.value?.name || this.value?.cardholderName || ''
    this.cardNumber = buildMaskedCardNumber(this.value?.mask, this.value?.type_label) || ''
    this.expiration = this.value?.exp_date || ''
    this.cvv = this.value?.securityCode || ''
    this.address = {
      name: this.value?.address?.name || '',
      street1: this.value?.address?.street1 || '',
      street2: this.value?.address?.street2 || '',
      city: this.value?.address?.city || '',
      state: this.value?.address?.state || '',
      //@ts-ignore
      postalCode: this.value?.address?.postalCode || this.value?.address?.postal_code || '',
      title: this.value?.address?.title || '',
      country: this.value?.address?.country || '',
    }
  }

  @Watch('formattedData')
  onFormattedDataChange(): void {
    if (this.disputedCheckout) {
      return
    }
    this.$emit('input', this.formattedData)
  }

  mounted () {
    this.initFinixForm()
  }

  get cardNumberMask(): string {
    if (this.disputedCheckout) {
      return null
    }
    return cardMaskByType(this.cardType)
  }

  get lastFourDigits(): string {
    return this.rawCardNumber.substr(this.rawCardNumber.length - 4, 4)
  }

  get rawCardNumber(): string {
    return this.cardNumber.replace(/\D/g, '')
  }

  get cardType(): string {
    return detectCardType(this.rawCardNumber)
  }

  get cardIconName(): string {
    if (this.cardType === 'default') {
      return 'default_card'
    }
    return this.cardType
  }

  get month(): string {
    return this.expiration.split('/')[0]
  }

  get year(): string {
    return this.expiration.split('/')[1]
  }

  get formattedData(): FormattedPaymentMethod {
    return this.formatData()
  }

  async initFinixForm(): Promise<void> {
    const windowWithFinix = window as any;
    if (!windowWithFinix.Finix) {
      console.warn('Failed to load Finix. Attempting to reload...')
      await this.$loadScript("https://js.finix.com/v/1/finix.js")
    }

    this.finixForm = windowWithFinix.Finix.CardTokenForm("finix-form", finixOptions);
  }

  async getTokenizedFinixInfo(): Promise<string> {
    const { environment, applicationId } = finixConfig();

    return new Promise((resolve, reject) => {
      this.finixForm.submit(environment, applicationId, function (err, res) {
        if (err) {
          reject(err); // Reject the promise if there's an error
        } else {
          const tokenData = res.data || {};
          const token = tokenData.id;
          resolve(token); // Resolve the promise with the token
        }
      });
    });
  }

  isDateInFuture(expirationDate: string): boolean {
    const [month, year] = expirationDate.split('/').map(Number)
    if (isNaN(month) || isNaN(year)) {
      return false
    }

    let yearString = year.toString()
    if (yearString.length === 2) {
      yearString = `20${year}`
    }

    const lastDayOfMonth = this.$dayjs(
      `${yearString}-${month.toString().padStart(2, '0')}`
    ).endOf('month')
    const currentDate = this.$dayjs().startOf('day')

    return lastDayOfMonth.isSameOrAfter(currentDate)
  }

  cancel(): void {
    this.$emit('cancel')
    this.reset()
  }

  reset(): void {
    this.nameOnCard = ''
    this.cardNumber = ''
    this.expiration = ''
    this.cvv = ''
    this.saveCard = false
    this.address = {}
    const addressPicker = this.$refs['address-picker'] as any
    addressPicker.clearAddress()
  }

  validate(): boolean {
    const cardForm = this.$refs['card-form'] as any
    const cardFormValid = cardForm.validate()

    const addressForm = this.$refs['address-form'] as any
    const addressFormValid = addressForm.validate()

    let firstInvalidInput = null
    cardForm.inputs.some((input) => {
      if (!input.valid && !firstInvalidInput) {
        firstInvalidInput = input
      }
      return !input.valid
    })

    addressForm.inputs.some((input) => {
      if (!input.valid && !firstInvalidInput) {
        firstInvalidInput = input
      }
      return !input.valid
    })

    // If there was an invalid element, scroll to it
    if (firstInvalidInput) {
      this.$vuetify.goTo(firstInvalidInput.$el.offsetTop)
    }

    return addressFormValid
  }

  formatData(): FormattedPaymentMethod {
    const {
      addressName,
      street1,
      street2,
      city,
      state,
      postalCode,
      lat,
      lng,
      title,
      timeZone,
      country,
    } = this.address

    return {
      activeMethod: 'credit_card',
      saveForFuturePayments: this.saveCard,
      name: this.nameOnCard,
      cardholderName: this.nameOnCard,
      cardNumber: this.rawCardNumber,
      mask: this.lastFourDigits,
      securityCode: this.cvv,
      exp_date: this.expiration,
      expirationMonth: this.expiration?.split('/')?.[0] || '',
      expirationYear: this.expiration?.split('/')?.[1] || '',
      type_label: this.cardType,
      address: {
        name: addressName,
        street1,
        street2,
        city,
        state,
        postalCode,
        lat,
        lng,
        title,
        time_zone: timeZone,
        country,
      },
    }
  }
}
</script>

<style lang="scss">
.field-holder {
  margin-top: 10px !important;
}

.field-array {
  margin-bottom: 10px !important;
}

.field {
  font-family: 'Inter Medium', Arial, sans-serif !important;
  font-weight: light !important;
  margin-bottom: -10px !important;

  .input {
    border: 3px solid rgb(160, 174, 186) !important;
  }
}

.validation {
  font-size: 12px !important;
}

</style>
