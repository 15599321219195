<template>
  <div>
    <div
      v-for="(vehicleType, vehicleTypeIndex) in vehicleTypes"
      :key="vehicleTypeIndex"
      class="padding-t-4"
    >
      <h3>{{ vehicleType.label }}</h3>
      <ProviderInfoVehicleTypePhotos
        :photos="vehicleType.photos"
        @open-modal="openModal"
      />
      <ProviderInfoVehicleTypeAmenities :amenities="vehicleType.amenities" />
      <v-divider class="margin-t-4" />
    </div>

    <ProviderInfoVehiclePhotoCarousel
      v-if="companyPhotos && companyPhotos.length > 0"
      v-model="imageModal"
      :photos="companyPhotos"
      :index="carouselIndex"
      @update:index="carouselIndex = $event"
    />
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AmenityType, OperatorDetailCompany, VehiclePhoto } from '../models/dto'
import { hostBaseUrl } from '../utils/env'
import ProviderInfoVehicleTypePhotos from '@/components/ProviderInfoVehicleTypePhotos.vue'
import ProviderInfoVehicleTypeAmenities from '@/components/ProviderInfoVehicleTypeAmenities.vue'
import ProviderInfoVehiclePhotoCarousel from '@/components/ProviderInfoVehiclePhotoCarousel.vue'

@Component({
  components: {
    ProviderInfoVehicleTypePhotos,
    ProviderInfoVehiclePhotoCarousel,
    ProviderInfoVehicleTypeAmenities,
  },
})
export default class ProviderInfoVehicleDetails extends Vue {
  @Prop({ type: Object, required: true })
  readonly company!: OperatorDetailCompany

  carouselIndex = 0
  imageModal = false

  get companyPhotos(): VehiclePhoto[] {
    return this.company?.vehiclePhotos
      .slice()
      .sort((a, b) => {
        if (a.vehicleTypeId === b.vehicleTypeId) {
          return a.orderIndex - b.orderIndex
        }
        return a.vehicleTypeId - b.vehicleTypeId
      })
      .map((vehiclePhoto) => {
        const path = vehiclePhoto?.imagePath ?? ''
        const imagePath = path?.[0] !== '/' ? path : `${hostBaseUrl()}${path}`
        return {
          ...vehiclePhoto,
          imagePath,
        }
      })
  }

  get photoSets(): VehiclePhoto[][] {
    const vehicleTypes: Record<number, VehiclePhoto[]> = {}
    for (const vehiclePhoto of this.companyPhotos) {
      const vehicleTypeId = vehiclePhoto.vehicleTypeId
      if (!vehicleTypes[vehicleTypeId]) {
        vehicleTypes[vehicleTypeId] = []
      }
      vehicleTypes[vehicleTypeId].push(vehiclePhoto)
    }

    return Object.values(vehicleTypes).map((list) =>
      list.sort((a, b) => a.orderIndex - b.orderIndex)
    )
  }

  get vehicleTypes(): {
    label: string
    photos: VehiclePhoto[]
    amenities: AmenityType
  }[] {
    const vehicleTypeMap = {}
    const vehicleTypeAmenities = this.company.vehicleTypeAmenities
    const vehiclePhotoTypes = this.companyPhotos.reduce((acc, photo) => {
        if (photo.vehicleTypeId) {
          acc[photo.vehicleTypeId] = acc[photo.vehicleTypeId] || []
          acc[photo.vehicleTypeId].push(photo)
        }
        return acc
      }, {})

    for (const vehicleTypeId of Object.keys(vehiclePhotoTypes)) {
      if (!vehicleTypeMap[vehicleTypeId]) {
        vehicleTypeMap[vehicleTypeId] = {}
      }
      const amenities = vehicleTypeAmenities[vehicleTypeId]
      vehicleTypeMap[vehicleTypeId].amenities = amenities || []

      const photos = this.companyPhotos
        .filter((photo) => photo.vehicleTypeId === Number(vehicleTypeId))
        .sort((a, b) => a.orderIndex - b.orderIndex)
      vehicleTypeMap[vehicleTypeId].photos = photos || []

      const label = photos?.[0]?.vehicleTypeDTO?.label
      vehicleTypeMap[vehicleTypeId].label = label
    }

    return Object.values(vehicleTypeMap)
  }

  getPhotoSectionKey(
    vehiclePhotoSet: VehiclePhoto[],
    vehiclePhotoSetIndex: number
  ) {
    return `${vehiclePhotoSet?.[0]?.vehiclePhotoCharterUpId}-${vehiclePhotoSetIndex}`
  }

  openModal(image): void {
    this.imageModal = true
    this.carouselIndex = this.companyPhotos.indexOf(image)
  }
}
</script>
