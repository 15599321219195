<template>
  <v-menu
    v-model="menuOpen"
    :open-on-click="false"
    :close-on-click="false"
    :nudge-bottom="disputedCheckout ? 50 : 41"
  >
    <template #activator="{ on }">
      <v-text-field
        ref="autoCompleteTextField"
        class="no-focus-highlight"
        :class="{ 'finix': !disputedCheckout && isPaymentForm }"
        v-bind="$attrs"
        :autofocus="autoFocus"
        :name="uuidv4()"
        :value="search"
        :disabled="disabled"
        :error-messages="errorMessages"
        :rules="rules"
        :clearable="clearable"
        flat
        :append-icon="appendIcon ? appendIcon : ''"
        autocomplete="off"
        outlined
        :tabindex="tabIndex"
        :hint="hint"
        :persistent-hint="!!hint"
        :placeholder="placeholder"
        v-on="on"
        @blur="onBlur"
        @input="addressAutoComplete"
        @keyup.native="handleKeyEvent"
        @focus="onFocus"
        @click:append="appendIconHandler"
      />
      <div v-if="loading">
        <v-progress-linear indeterminate height="1" />
      </div>
    </template>
    <v-card v-if="autocompleteItems.length && !hideOptions && manualToggle">
      <v-list>
        <v-list-item
          v-for="(item, index) in autocompleteItems"
          :id="`address-autocomplete-item-${index}`"
          :key="`address-autocomplete-item${item.placeId}-${index}`"
          class="cursor-pointer"
          :class="arrowPosition === index ? 'hover-auto-complete-item' : ''"
          @mouseover="arrowPosition = index"
          @mousedown="() => selectPlace(item)"
        >
          <CUIcon
            view-box="0 0 24 24"
            color="primary"
            class="w-28 h-28 margin-r-1"
          >
            place
          </CUIcon>
          <v-list-item-title
            :style="{ 'font-size': '1rem', 'letter-spacing': '-0.018rem' }"
          >
            {{ item.description }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import placeService from '@/services/place'
import { v4 } from 'uuid'
import { CountryAbbreviation, KeyCode } from '@/utils/enum'
import { LatLng } from '@/models/LatLng'

@Component({})
export default class AutoCompleteAddress extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly rules: any[]
  @Prop({ type: Boolean }) readonly clearable: boolean
  @Prop({ type: Boolean }) readonly manualControl: boolean
  @Prop({ type: Boolean }) readonly disabled: boolean
  @Prop({ type: Boolean }) readonly hideOptions: boolean
  @Prop({ type: String, default: () => undefined }) readonly identifier: string
  @Prop({ type: String, default: () => undefined }) readonly appendIcon: string
  @Prop({ type: Boolean }) readonly autoFocus: boolean
  @Prop({ type: Function, default: () => true }) readonly appendIconHandler: any
  @Prop({ type: String, default: () => undefined }) readonly initialSearch: string
  @Prop({ type: Number, required: false, default: null }) readonly tabIndex: number
  @Prop({ type: Boolean }) readonly isSplitUpAddress: boolean
  @Prop({ type: Boolean, default: false }) readonly limitCountries: boolean
  @Prop({ type: String, default: () => undefined }) readonly hint: string
  @Prop({ type: String, default: () => undefined }) readonly placeholder: string
  @Prop({ type: Boolean, default: false }) readonly disputedCheckout: boolean
  @Prop({ type: Boolean, default: false }) readonly isPaymentForm: boolean
  @Prop({ type: Object, default: () => undefined }) readonly biasCoordinate: LatLng

  @Watch('search')
  searchChanged(val: string): any {
    if (val === this.initialSearch) {
      return
    }
    return val && this.addressAutoComplete(val)
  }

  @Watch('initialSearch')
  initialSearchChanged(val) {
    this.search = val
  }

  loading = false
  search = ''
  debounce: any = null
  addressSearchText = ''
  autocompleteItems = []
  arrowPosition = null
  manualToggle = true
  errorMessages = []
  menuOpen = false

  get includedCountries(): string[] {
    if (!this.limitCountries) {
      return null
    }
    return [
      CountryAbbreviation.USA,
      CountryAbbreviation.Canada,
      CountryAbbreviation.Mexico,
    ]
  }

  created(): void {
    this.search = this.initialSearch
  }

  handleKeyEvent(event): void {
    if (this.manualControl) {
      this.manualToggle = true
      this.errorMessages = []
    }
    const maxPosition = this.autocompleteItems.length
    if (event.keyCode === KeyCode.UpArrow) {
      if (typeof this.arrowPosition === 'undefined') {
        this.arrowPosition = maxPosition
      } else if (this.arrowPosition >= 0) {
        this.arrowPosition = this.arrowPosition - 1
      }
      return
    }
    if (event.keyCode === KeyCode.DownArrow) {
      if (typeof this.arrowPosition === 'undefined') {
        this.arrowPosition = 0
      } else if (this.arrowPosition + 1 === maxPosition) {
        this.arrowPosition = 0
      } else {
        this.arrowPosition = this.arrowPosition + 1
      }
      return
    }
    if (event.keyCode === KeyCode.Enter) {
      if (typeof this.autocompleteItems[this.arrowPosition] !== 'undefined') {
        this.selectPlace(this.autocompleteItems[this.arrowPosition])
      }
      return
    }
  }
  addressAutoComplete(input): void {
    if (typeof input === 'undefined' || input === null || input.length === 0) {
      this.clearPlace()
      return
    }
    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }
    this.debounce = window.setTimeout(async () => {
      this.loading = true
      this.arrowPosition = undefined
      const addressData = await placeService.search(
        input,
        this.includedCountries,
        this.biasCoordinate
      )
      this.loading = false
      this.autocompleteItems = addressData.data || []
    }, 250)
  }

  async selectPlace(placeItem): Promise<void> {
    if (!placeItem) {
      return
    }

    const title = this.titleFromPlaceItem(placeItem)
    const { description, place_id: placeId } = placeItem
    const placeResponse = await placeService.byId(placeId)
    const place = { ...placeResponse.data, title }
    const { identifier } = this
    this.$nextTick(() => {
      this.$emit('place-selected', { identifier, place, description })
    })

    if (this.manualControl) {
      if (this.isSplitUpAddress) {
        this.search = place.street1
      } else {
        this.search = place.addressName
      }
      this.manualToggle = false
    }
  }

  clearPlace(): void {
    const { identifier } = this
    this.autocompleteItems = []
    this.$emit('place-cleared', identifier)
  }

  onFocus(): void {
    this.menuOpen = true
  }

  onBlur(): void {
    const { identifier } = this
    this.menuOpen = false
    window.setTimeout(() => {
      this.autocompleteItems = []
      this.$emit('exit', identifier)
    }, 500)
  }

  titleFromPlaceItem(placeItem): void {
    const { types } = placeItem
    const isStreetAddress =
      types.includes('street_address') || types.includes('premise')
    return isStreetAddress ? null : placeItem.structured_formatting?.main_text
  }

  uuidv4(): any {
    return v4()
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/colors.scss';

.hover-auto-complete-item {
  background-color: rgba(202, 205, 211, 0.25) !important;
}

.no-focus-highlight .input {
  min-height: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

::v-deep button.v-icon.v-icon--link {
  color: rgba($black, 0.5);
}

::v-deep .theme--light.v-application .v-text-field:not(.v-textarea):not(.v-select) .v-input__control .v-input__slot input {
  min-height: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
}

::v-deep .v-input__append-inner {
  margin-top: 14px !important;
  margin-right: 14px !important;
}
</style>
