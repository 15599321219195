<template>
  <div class="d-flex align-center padding-a-5 border-radius-8 background-header-gray">
    <VehicleOptionImage
      class="max-w-104 margin-r-3"
      :vehicle-type-keys="vehicleTypeKeys"
    />
    <VehicleOptionVehicleList :vehicles="vehicles" />
  </div>
</template>

<script setup lang="ts">
import VehicleOptionVehicleList from "@/components/VehicleOptionVehicleList.vue";
import VehicleOptionImage from "@/components/VehicleOptionImage.vue";
import { computed } from "vue";
import { VehicleTypeKey } from "@/utils/enum";

const props = defineProps<{
  vehicles: { quantity: number; vehicleTypeKey: string }[],
}>()

const vehicleTypeKeys = computed((): string[] =>
  props.vehicles.map(({ vehicleTypeKey }) => vehicleTypeKey)
)
</script>
