import { onMounted, onBeforeUnmount } from 'vue'

/**
 * A composable function that sets up a resize event listener on the window object.
 * The provided callback function is invoked whenever the window is resized.
 *
 * @param {() => void} callback - The function to be called when the window is resized.
 *
 * @example
 * ```typescript
 * import { useWindowResize } from './useWindowResize';
 *
 * export default {
 *   setup() {
 *     useWindowResize(() => {
 *       console.log('Window resized');
 *     });
 *   }
 * }
 * ```
 */
export const useWindowResize = (callback: () => void) => {
  const handleResize = () => callback()

  onMounted(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize)
  })
}
