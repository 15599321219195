<template>
  <CUModal :value="value" max-width="518px" @input="$emit('input', false)">
    <template #title>Import Riders</template>
    <template #text>
      <div class="d-flex flex-column w-inherit">
        <p
          class="text-primary cursor-pointer margin-a-0"
          @click="downloadImportRiderTemplate"
        >
          Download Template
        </p>
        <div class="d-flex align-center padding-t-2">
          <ImportRiderFileUpload ref="importFileUpload" @file-added="addFile" />

          <CUIcon
            v-if="filePresent"
            id="delete-file-icon"
            key="delete-file-icon"
            view-box="0 0 24 24"
            class="cursor-pointor margin-x-2"
            @click="clearFile"
          >
            close
          </CUIcon>
        </div>
        <div class="d-flex padding-t-4 justify-space-between align-center">
          <CUSelect
            v-model="importType"
            :items="importTypeMap"
            item-text="label"
            item-value="value"
            placeholder="Import Type"
            outlined
            hide-details
            class="w-180"
          />
          <v-checkbox
            v-model="sendInviteEmail"
            label="Send Invite Email"
            hide-details
            class="margin-a-0 padding-a-0"
          />
        </div>
        <AlertBox :active="!!errorMessage" type="error" class="margin-t-3">
          {{ errorMessage }}
        </AlertBox>
      </div>
    </template>
    <template #actions>
      <div class="d-flex grow" />
      <v-btn small text class="margin-r-2" @click="close">
        Cancel
      </v-btn>
      <v-btn class="primary" small @click="importSubmit">
        Import
      </v-btn>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import rider from '@/services/rider'
import ImportRiderFileUpload from './ImportRiderFileUpload.vue'
import { CustomerAccount } from '@/models/dto'
import { saveAs } from 'file-saver'
import AlertBox from '@/components/AlertBox.vue'

@Component({ components: { ImportRiderFileUpload, AlertBox } })
export default class ImportRiderModal extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean
  @Prop({ type: Object }) readonly customerAccount: CustomerAccount

  importType = null
  importTypeMap = [
    {
      label: 'Import All',
      value: 'importAll',
    },
    {
      label: 'Import Update',
      value: 'importUpdate',
    },
  ]
  sendInviteEmail = false
  uploadReady = false
  errorMessage = ''
  formData = new FormData()
  filePresent = false

  mounted(): void {}

  close(): void {
    this.$emit('input', false)
  }

  addFile(file) {
    this.formData.set('file', file)
    this.filePresent = true
  }

  clearFile(): void {
    this.formData = new FormData()
    this.filePresent = false
    const importFileComponent = this.$refs.importFileUpload as any
    importFileComponent.clearFile()
  }

  importSubmit(): void {
    if (!this.formData.get('file')) {
      this.errorMessage = 'Must select a file to upload.'
      return
    }

    rider.import(
      this.customerAccount.customerAccountId,
      this.formData,
      this.importType === 'importAll',
      this.sendInviteEmail
    )
    this.uploadReady = false
    this.$nextTick(() => {
      this.uploadReady = true
    })
    this.clearFile()
    this.importType = null
    this.sendInviteEmail = false
    this.$emit('open-import-dialog')
    this.$emit('close')
  }

  async downloadImportRiderTemplate(): Promise<void> {
    try {
      const templateData = await rider.importRiderTemplate()
      await saveAs(templateData.data, 'ImportRiderTemplate')
    } catch (e) {
      console.error('Getting import rider template failed.')
    }
  }
}
</script>
