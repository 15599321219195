<template>
  <CUModal v-model="dialog" max-width="400">
    <template #activator="{ on }">
      <v-list-item v-if="!isParent" :id="`delete-team-${index}`" v-on="on">
        <v-list-item-title>
          Delete Team
        </v-list-item-title>
      </v-list-item>
    </template>
    <template #title>Delete Team</template>
    <template #text>
      Are you sure you want to delete
      <b>{{ team.name }}?</b>
    </template>
    <template #actions>
      <div class="d-flex grow" />
      <v-btn
        id="delete-team-dialog-btn-cancel"
        tabindex="6"
        class="margin-r-2"
        small
        text
        @click="dialog = false"
      >
        Cancel
      </v-btn>
      <v-btn
        id="delete-team-dialog-btn-submit"
        color="error"
        small
        tabindex="5"
        @click="send"
      >
        <span class="text-white">
          Delete Team
        </span>
      </v-btn>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import customerAccount from '@/services/customerAccount'
import axios from 'axios'

@Component({})
export default class OrganizationDeleteTeamDialog extends Vue {
  @Prop({ type: Object, required: true }) readonly team: any
  @Prop({ type: Number }) readonly index: number

  formIsValid = false
  dialog = false
  success = false
  errorMessage = ''

  @Watch('dialog')
  dialogChanged(newVar: boolean): void {
    if (!newVar) {
      this.resetValues()
    } else {
      this.setup()
    }
    if (!this.dialog) {
      this.$emit('close')
    }
  }

  get isParent(): boolean {
    return this.team?.teams?.length
  }

  mounted(): void {
    this.setup()
  }
  setup(): void {
    this.success = false
  }
  resetValues(): void {
    const form = this.$refs.form as any
    form.reset()
  }
  backToSite(): void {
    this.success = false
    this.setup()
    this.dialog = false
  }

  async send(): Promise<void> {
    this.errorMessage = ''
    try {
      await customerAccount.delete(this.team.customerAccountId)
      this.$emit('success')
      this.dialog = false
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errorMessage = e.response?.data?.message
        this.errorMessage = errorMessage
      }
    }
  }
}
</script>
