import { render, staticRenderFns } from "./TripInfoItineraryStopNote.vue?vue&type=template&id=7c7c68c3&scoped=true"
import script from "./TripInfoItineraryStopNote.vue?vue&type=script&lang=ts"
export * from "./TripInfoItineraryStopNote.vue?vue&type=script&lang=ts"
import style0 from "./TripInfoItineraryStopNote.vue?vue&type=style&index=0&id=7c7c68c3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c7c68c3",
  null
  
)

export default component.exports