<template>
  <v-card
    class="position-absolute top-12 left-12 padding-a-2 margin-r-18 border-radius-6 z-2"
    :style="{ border: '1.5px solid #C4CDD4' }"
  >
    <v-card-text class="padding-y-0 padding-l-0 padding-r-3">
      <v-row align="center" class="margin-a-0">
        <v-col class="shrink padding-a-0">
          <v-icon :color="activeVehicleColor">info</v-icon>
        </v-col>
        <v-col class="grow padding-a-0 margin-l-2">
          <span class="font-14 font-bold">
            {{ statusText }}
            <template v-if="showReviewLink">
              Be sure to leave a
              <span
                class="cursor-pointer text-decoration-underline"
                @click="
                  $router.push({
                    name: 'reservation-detail',
                    params: { id: reservationId },
                  })
                "
              >
                review
              </span>
            </template>
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import tracking from '@/store/modules/tracking'
import { Vue, Component } from 'vue-property-decorator'
import trackingVehicleStore from '@/store/modules/trackingVehicles'
import { DEFAULT_MAP_THEME_COLOR } from '@/views/LiveTracking.vue'
import auth from '@/store/modules/auth'

@Component
export default class LiveTrackingMapStatusCard extends Vue {
  tracking = tracking
  trackingVehicleStore = trackingVehicleStore

  get statusText(): string {
    return tracking.statusText
  }
  get reservationId(): string {
    return tracking.reservation?.reservationId?.toString() || ''
  }
  get activeVehicleColor(): string {
    return (
      trackingVehicleStore?.activeTrackingVehicle?.color ||
      DEFAULT_MAP_THEME_COLOR
    )
  }
  get showReviewLink(): boolean {
    return this.reservationId && this.tracking.isFinished && !!auth.user
  }
}
</script>
