<template>
  <div>
    <CharterUPValueProps
      v-if="isMobile"
      class="margin-b-3"
      :value-prop-keys="valuePropKeys"
      :page="page"
    />
    <QuoteDetailBidListExpirationWarning
      v-if="showBidsExpiration"
      :quote="quote"
      class="margin-b-4"
      :class="{ 'margin-b-10': $vuetify.breakpoint.mdAndDown }"
    />
    <div
      class="d-flex margin-b-3"
      v-if="$vuetify.breakpoint.mdAndDown"
    >
      <div
        class="d-flex align-end margin-b-3"
        :class="{
          'justify-space-between': showBidsExpiration,
          'justify-end': !showBidsExpiration,
        }"
      >
        <ShareQuoteDialog
          v-if="showShareQuoteDialog"
          :quote-id="quote.quoteId"
        />

        <DownloadQuoteButton
          v-if="showDownloadQuoteButton"
          :quote-id="quote.quoteId"
          :quote-hash="quote.hash"
          :company-id="featuredCompanyId"
          class="margin-l-2"
        />
      </div>

    </div>

    <QuoteDetailBidListCharterUpChoice
      v-if="charterUPChoiceBid"
      :bid="charterUPChoiceBid"
      :is-elite="isElite"
      :quote="quote"
      :is-branded-quote-flow-enabled="isBrandedQuoteFlowEnabled"
      :is-display-partner-url-enabled="isDisplayPartnerUrlEnabled"
    />
    <BidCard
      v-if="featuredBid && featuredBid.companyId"
      id="quote-detail-bidcard-featured"
      preferred
      featured
      :go-to="{
        name: 'provider-detail',
        params: {
          quoteId: quoteId || null,
          providerId: featuredBid.companyId,
        },
      }"
      class="margin-b-3"
      :bid="featuredBid"
      :is-elite="isElite"
      :show-usd="isPickupInCanada"
      :is-featured-bid-reseller="isFeaturedBidReseller"
      :is-branded-quote-flow-enabled="isBrandedQuoteFlowEnabled"
      :is-display-partner-url-enabled="isDisplayPartnerUrlEnabled"
    />
    <div
      v-if="
        featuredBid && featuredBid.companyId && bestBids && bestBids.length > 0
      "
      class="bid-header font-24"
    >
      {{ otherCompaniesText }}
    </div>
    <v-row class="padding-t-0">
      <template v-if="bestBids">
        <v-col
          v-for="(bid, bidIndex) in bestBids"
          :key="`${bid.companyId}-${bidIndex}`"
          cols="12"
        >
          <BidCard
            :id="`quote-detail-bidcard-${bidIndex}`"
            preferred
            :go-to="{
              name: 'provider-detail',
              params: {
                quoteId: quote ? quote.quoteId : null,
                providerId: bid.companyId,
              },
            }"
            :bid="bid"
            :is-elite="isElite"
            :show-usd="isPickupInCanada"
            :show-amenities="showBidsAmenities"
            :is-featured-bid-reseller="isFeaturedBidReseller"
            :is-branded-quote-flow-enabled="isBrandedQuoteFlowEnabled"
            :is-display-partner-url-enabled="isDisplayPartnerUrlEnabled"
          />
        </v-col>
      </template>
    </v-row>

    <QuoteDetailBidListUnavailableBids
      v-if="bronzeBids && bronzeBids.length"
      :bids="bronzeBids"
      :is-elite="isElite"
      :quote-id="quote.quoteId"
      :is-branded-quote-flow-enabled="isBrandedQuoteFlowEnabled"
      :is-display-partner-url-enabled="isDisplayPartnerUrlEnabled"
    />

    <div v-if="!bidsExist" class="text-center mt-6">
      {{ $t('quoteDetail.bidList.NO_BIDS') }}
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch, Provide } from 'vue-property-decorator'
import BidCard from '@/components/BidCard.vue'
import ShareQuoteDialog from '@/components/ShareQuoteDialog.vue'
import DownloadQuoteButton from '@/components/DownloadQuoteButton.vue'
import QuoteDetailBidListExpirationWarning from '@/components/QuoteDetailBidListExpirationWarning.vue'
import QuoteDetailBidListUnavailableBids from '@/components/QuoteDetailBidListUnavailableBids.vue'
import QuoteDetailBidListCharterUpChoice from '@/components/QuoteDetailBidListCharterUpChoice.vue'
import CharterUPValueProps from '@/components/CharterUPValueProps.vue'
import {
  datetimeToShortLocalizedDateTime,
  datetimeToMilliseconds,
} from '@/utils/datetime'
import ga4 from '@/store/modules/ga4'
import { PartnerTypeId, ViewQuoteSource, Page, ValuePropKey } from '@/utils/enum'
import { QuoteDetailV2, QuoteDetailV2Bid } from '../models/dto/QuoteDetailV2'

@Component({
  components: {
    BidCard,
    ShareQuoteDialog,
    DownloadQuoteButton,
    QuoteDetailBidListExpirationWarning,
    QuoteDetailBidListUnavailableBids,
    QuoteDetailBidListCharterUpChoice,
    CharterUPValueProps,
  },
})
export default class QuoteDetailBidList extends Vue {
  @Provide('isInCarousel') isInCarousel = false
  @Prop({ type: Object, default: null }) readonly quote: QuoteDetailV2
  @Prop({ type: Object, default: null }) readonly featuredBid: QuoteDetailV2Bid
  @Prop({ type: Boolean }) readonly showShareQuoteDialog: boolean
  @Prop({ type: Boolean }) readonly showDownloadQuoteButton: boolean
  @Prop({ type: Array }) readonly valuePropKeys: ValuePropKey[]
  @Prop({ type: Boolean, required: true }) readonly isBrandedQuoteFlowEnabled: boolean
  @Prop({ type: Boolean, required: true }) readonly isDisplayPartnerUrlEnabled: boolean

  page = Page.QuoteDetail

  get featuredCompanyId(): number | undefined {
    return this.featuredBid?.companyId
  }

  get isPickupInCanada(): boolean {
    return this.quote?.trips?.[0]?.stops?.[0]?.address?.country === 'CA'
  }

  get bids(): QuoteDetailV2Bid[] {
    return this.quote?.bids
  }

  get bestBids(): QuoteDetailV2Bid[] {
    return this.quote?.bids
      ?.filter((bid) => {
        return bid.partnerTypeId !== PartnerTypeId.Bronze
      })
      ?.filter((bid) => {
        // We only want to filter the CharterUp Choice option out if we are going
        // to display it separately
        return (!bid.isCharterUPChoice)
      })
      .sort((a, b) => a.totalAmount - b.totalAmount)
      .sort((a, b) => Number(a.soldOut) - Number(b.soldOut))
  }

  get bronzeBids(): QuoteDetailV2Bid[] {
    return this.quote?.bids?.filter((bid) => {
      return bid.partnerTypeId === PartnerTypeId.Bronze
    })
  }

  get charterUPChoiceBid(): QuoteDetailV2Bid {
    return this.quote?.bids?.find((bid) => bid.isCharterUPChoice)
  }

  get bidsExpirationDate(): string {
    return datetimeToShortLocalizedDateTime(this.quote?.expirationDate)
  }

  get bidsExist(): boolean {
    return (
      (this.quote?.bids && this.quote?.bids.length !== 0) || !!this.featuredBid
    )
  }

  // Show bid expiration stamp if there bids that are not all bronze bids and we have a timestamp
  get showBidsExpiration(): boolean {
    return (
      this.bidsExist &&
      this.quote?.bids.length !== this.bronzeBids?.length &&
      !!this.bidsExpirationDate
    )
  }

  get isElite(): boolean {
    return !!this.quote?.isElite
  }

  get quoteId(): number {
    return this.quote?.quoteId
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown
  }

  get isFeaturedBidReseller(): boolean {
    return this.featuredBid && this.featuredBid.reseller
  }

  get showBidsAmenities(): boolean {
      return !this.isFeaturedBidReseller || !this.isBrandedQuoteFlowEnabled
  }

  get otherCompaniesText() {
    if(this.isFeaturedBidReseller && this.isBrandedQuoteFlowEnabled) {
      return this.$t('quoteDetail.bidList.OTHER_AVAILABLE_OPERATORS')
    }
    return this.$t('quoteDetail.bidList.OTHER_COMPANIES')
  }

  async mounted(): Promise<void> {
    this.trackViewQuote()
  }

  trackViewQuote(): void {
    const source = ga4.viewQuoteSource || ViewQuoteSource.Other
    const createdOn = datetimeToMilliseconds(this.quote?.createdOn)
    this.$ga4.trackEvent('view_quote', {
      source: source.toString(),
      createdOn,
      isElite: this.isElite,
      isSelfServe: this.quote?.isSelfServe,
      isAuth0: this.$auth0.isInitialized
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors';

.bid-header {
  padding-top: 15px;
  margin-bottom: -6px;
}
</style>
