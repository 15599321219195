import { ref, computed, Ref, watch } from 'vue'
import { gmapApi } from 'vue2-google-maps'

/**
 * Composable function to use Google Directions API with Vue.js.
 *
 * @param waypoints - A reactive reference to an array of waypoints, each with latitude and longitude.
 * @returns An object containing:
 *  - `directionsResult`: A reactive reference to the directions result.
 *  - `path`: A computed property that returns the overview path of the route.
 *  - `fetchDirections`: A function to fetch directions from the Google Directions API.
 *  - `getBounds`: A function to get the bounds that include both the route and waypoints.
 */
export const useGoogleDirections = (
  waypoints: Ref<{ lat: number; lng: number }[]>
) => {
  /**
   * A reactive reference to the Google Maps DirectionsService instance.
   * This service is used to communicate with the Google Maps API to obtain
   * directions between locations.
   *
   * @type {Ref<google.maps.DirectionsService | null>}
   */
  const directionsService = ref<google.maps.DirectionsService | null>(null)

  /**
   * A reactive reference to store the result of Google Maps Directions API.
   * It can either be a `google.maps.DirectionsResult` object or `null` if no result is available.
   */
  const directionsResult = ref<google.maps.DirectionsResult | null>(null)

  /**
   * A computed property that returns the Google Maps API instance.
   *
   * @returns {any} The Google Maps API instance.
   */
  const google = computed(() => gmapApi())

  /**
   * Computes the path from the directions result.
   *
   * This computed property extracts the first route from the directions result,
   * then maps over the overview path of that route to create an array of objects
   * containing latitude and longitude coordinates.
   *
   * @returns An array of objects, each containing `lat` and `lng` properties representing the coordinates of the path.
   */
  const path = computed(() => {
    const route = directionsResult.value?.routes?.[0]
    return route?.overview_path?.map((point) => ({
      lat: point.lat(),
      lng: point.lng(),
    })) || []
  })

  /**
   * Fetches directions from Google Maps Directions API based on the provided waypoints.
   *
   * @returns {Promise<google.maps.DirectionsResult | null>} A promise that resolves to the directions result or null if the request fails.
   *
   * @throws {Error} If the directions request fails, the promise is rejected with an error containing the status.
   *
   * @remarks
   * - The function checks if the Google Maps API is loaded and if there are at least two waypoints.
   * - If the directions service is not initialized, it initializes it.
   * - The origin is set to the first waypoint, and the destination is set to the last waypoint.
   * - Intermediate waypoints are included as stopovers.
   * - The directions request is made with the travel mode set to driving and waypoints optimization enabled.
   * - On success, the directions result is stored and the promise is resolved.
   * - On failure, an error is logged, the directions result is set to null, and the promise is rejected.
   */
  const fetchDirections = (): Promise<google.maps.DirectionsResult | null> => {
    return new Promise((resolve, reject) => {
      if (!google.value || waypoints.value.length < 2) {
        directionsResult.value = null
        resolve(null)
        return
      }

      if (!directionsService.value) {
        directionsService.value = new google.value.maps.DirectionsService()
      }

      const origin = waypoints.value[0]
      const destination = waypoints.value[waypoints.value.length - 1]
      const points = waypoints.value.slice(1, -1).map((waypoint) => ({
        location: waypoint,
        stopover: true,
      }))
      directionsService.value.route(
        {
          origin,
          destination,
          waypoints: points,
          travelMode: google.value.maps.TravelMode.DRIVING,
          optimizeWaypoints: true,
        },
        (result, status) => {
          if (status === google.value.maps.DirectionsStatus.OK && result) {
            directionsResult.value = result
            resolve(result)
          } else {
            console.error('Directions request failed due to', status)
            directionsResult.value = null
            reject(new Error(`Directions request failed: ${status}`))
          }
        }
      )
    })
  }

  /**
   * Retrieves the bounds of the first route from the directions result.
   *
   * @returns {google.maps.LatLngBounds | null} The bounds of the first route, or null if the Google Maps API or directions result is not available.
   */
  const getRouteBounds = (): google.maps.LatLngBounds | null => {
    if (!google.value || !directionsResult.value) {
      return null
    }
    return directionsResult.value?.routes?.[0]?.bounds ?? null
  }

  /**
   * Calculates and returns the bounds that encompass all waypoints.
   *
   * @returns {google.maps.LatLngBounds | null} The bounds that encompass all waypoints, or null if the Google Maps API is not available or there are no waypoints.
   */
  const getWaypointBounds = (): google.maps.LatLngBounds | null => {
    if (!google.value || waypoints.value.length === 0) {
      return null
    }

    const bounds = new google.value.maps.LatLngBounds()
    waypoints.value.forEach((waypoint) => bounds.extend(waypoint))
    return bounds
  }

  /**
   * Calculates and returns the bounds that encompass the route and waypoints.
   *
   * @returns {google.maps.LatLngBounds | null} The combined bounds of the route and waypoints,
   * or null if the Google Maps API is not available or there are no waypoints.
   */
  const getBounds = (): google.maps.LatLngBounds | null => {
    if (!google.value || waypoints.value.length === 0) {
      return null
    }

    const routeBounds = getRouteBounds()
    const waypointBounds = getWaypointBounds()

    if (routeBounds && waypointBounds) {
      routeBounds.union(waypointBounds)
    }

    return routeBounds ?? waypointBounds ?? null
  }

  watch(google, () => {
    if (google.value) {
      fetchDirections()
    }
  }, { immediate: true, deep: true })

  return { directionsResult, path, fetchDirections, getBounds }
}
