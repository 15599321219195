<template>
  <CUItinerary>
    <CUItineraryItem
      v-for="(stop, stopIndex) in stops"
      :key="`trip-itinerary-review-stop-${stopIndex}`"
      class="w-full"
      color="white"
      small
      upcoming
      :lastStop="stopIndex === stops.length - 1"
    >
      <div
        class="margin-l-3 margin-b-2 w-full margin-b-6 border-radius-regular padding-x-2 padding-y-1"
        :class="{
          'background-modified-trip': isChanged(stopIndex),
        }"
      >
        <TripInfoItineraryAddress
          v-if="stop.address"
          :id="`trip-info-itinerary-stop-${stopIndex}`"
          :title="stop.address.title"
          :street="stop.address.street1"
          :state="stop.address.state"
          :city="stop.address.city"
        />
        <TripInfoItineraryDropoffTime
          v-if="stop.dropoffDatetime && stop.address"
          :id="`trip-info-itinerary-stop-${stopIndex}-dropoff-time`"
          :dropoffDatetime="stop.dropoffDatetime"
          :zoneId="stop.address.zoneId"
        />
        <TripInfoItineraryPickupTime
          v-if="stop.pickupDatetime && stop.address"
          :id="`trip-info-itinerary-stop-${stopIndex}-pickup-time`"
          :pickupDatetime="stop.pickupDatetime"
          :zoneId="stop.address.zoneId"
        />
      </div>
    </CUItineraryItem>
  </CUItinerary>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUItinerary from '@/components/CUItinerary.vue'
import CUItineraryItem from '@/components/CUItineraryItem.vue'
import TripInfoItineraryAddress from '@/components/TripInfoItineraryAddress.vue'
import TripInfoItineraryDropoffTime from '@/components/TripInfoItineraryDropoffTime.vue'
import TripInfoItineraryPickupTime from '@/components/TripInfoItineraryPickupTime.vue'
import { ModifiedStop } from '@/models/dto'

@Component({
  components: {
    CUItinerary,
    CUItineraryItem,
    TripInfoItineraryAddress,
    TripInfoItineraryDropoffTime,
    TripInfoItineraryPickupTime,
  },
})
export default class TripReviewItinerary extends Vue {
  @Prop({ type: Array, required: true }) readonly stops: ModifiedStop[]

  isChanged(stopIndex: number): boolean {
    return this.stops?.[stopIndex].stopChanged
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-timeline-item__body {
  padding-left: 6px;
}
</style>
