<template>
  <div class="d-flex" :style="{ gap: '12px' }">
    <CUDatePicker
      :id="`self-serve-itinerary-date-picker-${index}`"
      placeholder="Date"
      :value="date"
      :display-value="formattedStopDate"
      :min="minimumDate"
      :min-date="minimumDatetime"
      :months="2"
      :timezone="zoneId"
      hide-details
      class="flex-grow-1 font-16"
      @input="onDateUpdate"
    />
    <CUTimePickerList
      :id="`self-serve-itinerary-stop-time-${index}`"
      placeholder="Time"
      :value="time"
      :display-value="formattedStopTime"
      :min-time="date && zoneId ? minTime : undefined"
      hide-details
      class="flex-grow-1 font-16"
      @input="onTimeUpdate"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { DateTime, SystemZone } from 'luxon'
import CUDatePicker from '@/components/CUDatePicker.vue'
import CUTimePickerList from '@/components/CUTimePickerList.vue'
import { PropType } from 'vue/types/v3-component-props'

const DEFAULT_STOP_TIME = '08:00:00'

const props = defineProps({
  index: {
    type: Number as PropType<number>,
    required: true,
  },
  datetime: {
    type: Object as PropType<DateTime | null>,
    required: false, // optional since null is allowed
  },
  zoneId: {
    type: String as PropType<string | null>,
    required: false,
  },
  minimumDatetime: {
    type: Object as PropType<DateTime>,
    required: true,
  },
})

const emit = defineEmits(['datetime:update'])

const date = computed(() => props.datetime ? props.datetime.toFormat('yyyy-LL-dd') : '')
const time = computed(() => props.datetime ? props.datetime.toFormat('HH:mm:ss.SSS') : '')

const minimumDate = computed(() => props.minimumDatetime?.toISODate())

const formattedStopDate = computed(() =>
  props.datetime ? props.datetime.toFormat('LL/dd/yyyy') : ''
)

const formattedStopTime = computed(() =>
  props.datetime ? props.datetime.toFormat('hh:mm a') : ''
)

const minTime = computed(() => {
  if (!minimumDate.value || date.value > minimumDate.value) {
    return undefined
  }
  return props.minimumDatetime.toFormat('HH:mm:ss.SSS')
})

/**
 * Update the date value
 * @param {string} event - The new date value as ISO Date string
 */
const onDateUpdate = (value: string): void => {
  const isoTime = time.value || getDefaultStopTime(value)
  const newDatetime = buildDateTime(value, isoTime)
  emit('datetime:update', newDatetime)
}

const onTimeUpdate = (value: string): void => {
  const newDatetime = buildDateTime(date.value || getDefaultStopDate(), value)
  emit('datetime:update', newDatetime)
}

const buildDateTime = (dateISO: string, timeISO: string): DateTime | null => {
  if (!dateISO || !timeISO) return null
  const iso = `${dateISO}T${timeISO}`
  const zone = props.zoneId || new SystemZone()
  const datetime = DateTime.fromISO(iso, { zone })
  return datetime
}

const getDefaultStopTime = (date: string): string => {
  if (!props.minimumDatetime) {
    return DEFAULT_STOP_TIME
  }

  const zonedDate = DateTime.fromISO(date, { zone: props.zoneId || new SystemZone() })
  if (zonedDate > props.minimumDatetime && props.index === 0) {
    return DEFAULT_STOP_TIME
  }

  const defaultDatetime = props.minimumDatetime
    .plus({ hours: 1 })
    .startOf('hour')

  return defaultDatetime.toFormat('HH:mm:ss.SSS')
}

const getDefaultStopDate = (): string => {
  const timeOnMinimumDate = buildDateTime(minimumDate.value, DEFAULT_STOP_TIME)
  if (timeOnMinimumDate < props.minimumDatetime) {
    // get the next day
    return props.minimumDatetime.plus({ days: 1 }).toISODate()
  }
  return minimumDate.value
}

</script>
