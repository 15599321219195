<template>
  <v-form
    ref="form"
    v-model="valid"
    :class="{
      'w-full': $vuetify.breakpoint.smAndDown,
      'w-308': $vuetify.breakpoint.mdAndUp,
    }"
    @submit.prevent="submit"
  >
    <label for="email">{{ $t('login.EMAIL') }}</label>
    <v-text-field
      id="email"
      ref="focus"
      v-model="email"
      :rules="emailRules"
      autofocus
      required
      outlined
      validate-on-blur
      background-color="white"
      name="email"
      type="email"
      autocomplete="email"
      tabindex="1"
    />
    <v-row no-gutters>
      <label for="password">{{ $t('login.PASSWORD') }}</label>
      <v-spacer />
      <a
        id="login-forgot-password"
        href="forgot-password"
        class="margin-a-0 padding-a-0 text-decoration-none font-14"
        tabindex="3"
      >
        {{ $t('login.FORGOT_PASSWORD') }}
      </a>
    </v-row>
    <v-text-field
      id="login-text-password"
      ref="password"
      v-model="password"
      name="password"
      :rules="passwordRules"
      :append-icon="passwordHidden ? 'visibility' : 'visibility_off'"
      :type="passwordHidden ? 'password' : 'text'"
      autocomplete="current-password"
      hide-details="auto"
      required
      validate-on-blur
      outlined
      tabindex="2"
      @click:append="() => (passwordHidden = !passwordHidden)"
    />

    <AlertBox :active="error" type="error" class="margin-b-4">
      The username or password is not correct.
    </AlertBox>

    <v-btn
      id="login"
      type="submit"
      class="primary"
      :loading="isSubmitting"
      block
    >
      {{ $t('login.LOGIN') }}
      <template #loader>
        <v-progress-circular indeterminate color="white" />
      </template>
    </v-btn>
  </v-form>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import auth from '@/store/modules/auth'
import { AuthPayload } from '@/models/dto'
import { RawLocation } from 'vue-router'
import AlertBox from '@/components/AlertBox.vue'

@Component({
  components: { AlertBox },
})
export default class LoginLegacyForm extends Vue {
  valid = false
  error = false
  isSubmitting = false
  passwordHidden = true
  email = ''
  password = ''
  emailRules = [
    (v) => !!v || this.$t('login.EMAIL_REQUIRED'),
    (v) => /.+@.+/.test(v) || this.$t('login.EMAIL_MUST_BE_VALID'),
  ]
  passwordRules = [(v) => !!v || this.$t('login.PASSWORD_IS_REQUIRED')]

  async submit(): Promise<void> {
    if (this.isSubmitting) {
      return
    }
    this.error = false

    const form = this.$refs.form as any
    const formIsValid = await form.validate()
    if (!formIsValid) {
      return
    }
    this.isSubmitting = true
    const payload: AuthPayload = {
      email: this.email,
      password: this.password,
    }

    const success = await auth.login(payload)
    if (!success) {
      this.error = true
      this.isSubmitting = false
      return
    }

    form.reset()
    this.isSubmitting = false
    this.trackLogin()
    this.redirect()
  }

  trackLogin(): void {
    this.$ga4.trackEvent('login', {
      isAuth0: false
    })
  }

  redirect(): void {
    const to = (this.$route.query.redirectFrom || {
      name: 'quote-index',
    }) as RawLocation
    this.$router.push(to)
  }

}
</script>

<style lang="scss" scoped>
@import '@/scss/colors.scss';

::v-deep .v-input__append-inner {
  margin-top: 13px !important;
  margin-right: 15px;
}

label {
  color: $gray-text;
}

::v-deep .v-text-field input {
  color: $gray-text !important;
  font-weight: 500 !important;
}
</style>
