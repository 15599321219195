<template>
  <v-menu bottom right origin="top left" :nudge-left="5">
    <template #activator="{ on, attrs }">
      <v-row>
        <v-checkbox
          :value="isChecked"
          :indeterminate="isIndeterminate"
          class="margin-l-3"
          :class="{ 'primary-text': isIndeterminate }"
          @change="toggleSelections"
        />
        <CUicon
          small
          class="dropdown position-relative top-15 margin-l-n2 cursor-pointer z-5 h-34 hover:background-black-15 hover:border-radius-regular"
          v-bind="attrs"
          ripple="false"
          v-on="on"
        >
          keyboard_arrow_down
        </CUicon>
      </v-row>
    </template>
    <v-list class="dropdown-menu">
      <v-list-item
        v-for="(option, optionIndex) in selectTypes"
        :id="`${id}-v-list-item-${optionIndex}`"
        :key="`${option.event}-${optionIndex}`"
        @click="checkboxClicked(option.event)"
      >
        <v-list-item-title>{{ option.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CheckboxDropdown extends Vue {
  @Prop({ type: String, default: null }) readonly id: string
  @Prop({ type: String, default: 'not-selected' }) readonly status: string

  selectTypes: { text: string; event: string }[] = [
    {
      text: 'All',
      event: 'selectAll',
    },
    {
      text: 'None',
      event: 'selectNone',
    },
    {
      text: 'Fully Paid',
      event: 'selectPaid',
    },
    {
      text: 'Not Paid',
      event: 'selectUnpaid',
    },
    {
      text: 'Partially Paid',
      event: 'selectPartPaid',
    },
  ]

  get isChecked(): boolean {
    return this.status === 'selected'
  }

  get isIndeterminate(): boolean {
    return this.status === 'indeterminate'
  }

  checkboxClicked(mode): void {
    this.$emit('checkbox-clicked', mode)
  }

  toggleSelections(): void {
    if (this.isChecked) {
      this.$emit('checkbox-clicked', 'selectNone')
    } else {
      this.$emit('checkbox-clicked', 'selectAll')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';

::v-deep .v-input--selection-controls__ripple {
  width: 40px !important;
}

button.v-icon.dropdown::after {
  border-radius: $border-radius-root;
  background-color: rgba($primary, 0.15);
  height: 28px;
  width: 16px;
}

::v-deep .primary-text {
  i.v-icon {
    color: $primary !important;
  }
}

::v-deep i.v-icon.mdi-checkbox-blank-outline {
  color: $gray !important;
}
</style>
