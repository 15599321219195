<template>
  <div class="cu-timeline-item d-flex">
    <div class="cu-timeline-item__divider position-relative top-0">
      <div
        class="cu-timeline-item__line w-0 h-full position-absolute z-0 top-2 border-y-0 border-solid"
        :style="{
          left: '50%',
          transform: 'translateX(-50%)',
          'border-color': itineraryItemColor,
          'border-width': '1px',
        }"
      />
      <slot name="icon">
        <SkeletonBox v-if="loading" class="h-16 w-16 border-radius-round" />
        <div
          class="d-flex w-16 h-16 align-center justify-center position-relative background-white"
        >
          <CUIcon
            class="position-relative top-0 cursor-default"
            height="14"
            width="14"
            viewBox="0 0 24 24"
            :color="color"
          >
            {{ icon }}
          </CUIcon>
        </div>
      </slot>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { classToHexColor, applyOpacity } from '@/utils/color'
import { LiveTrackingStatus } from '@/utils/enum'
import tracking from '@/store/modules/tracking'
import { DEFAULT_MAP_THEME_COLOR } from '@/views/LiveTracking.vue'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({ components: { SkeletonBox } })
export default class LiveTrackingItineraryItem extends Vue {
  @Prop({ type: String, required: true }) readonly status: string
  @Prop({ type: Boolean, required: false }) readonly loading: boolean
  @Prop({ type: String, required: false }) readonly color: string

  get icon(): string {
    switch (this.status) {
      case LiveTrackingStatus.Completed:
        return 'trip_stop'
      case LiveTrackingStatus.InProgress:
        return 'trip_origin'
      case LiveTrackingStatus.Upcoming:
        return 'trip_origin'
      default:
        return 'trip_origin'
    }
  }

  get itineraryItemColor(): string {
    const color = this.color ?? classToHexColor(DEFAULT_MAP_THEME_COLOR)
    if (this.status === LiveTrackingStatus.Completed) {
      return color
    }
    if (tracking.disableTracking) {
      return applyOpacity(color, 0.75)
    }
    return applyOpacity(color, 0.3)
  }
}
</script>

<style lang="scss" scoped>
.cu-timeline-item {
  &:last-of-type {
    .cu-timeline-item__line {
      display: none;
    }
  }
}
</style>
