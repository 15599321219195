<template>
  <div>
    <LiveTrackingVehiclePolyline
      v-if="vehicle"
      :vehicle="vehicle"
      :motion-line-data="motionLineData"
    />
    <template v-if="shouldDrawPath">
      <LiveTrackingNextStopPolyline
        v-for="(directions, i) in directionsList"
        :key="`directions-${i}`"
        :vehicle="vehicle"
        :directions="directions"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Component, Emit } from 'vue-property-decorator'
import { gmapApi } from 'vue2-google-maps'
import LiveTrackingVehiclePolyline from '@/components/LiveTrackingVehiclePolyline.vue'
import LiveTrackingNextStopPolyline from './LiveTrackingNextStopPolyline.vue'
import {
  TrackingVehicle,
  Path,
  MotionLineData,
  Directions,
} from '../models/dto/TrackingVehicle'
import tracking from '@/store/modules/tracking'

@Component({
  components: {
    LiveTrackingVehiclePolyline,
    LiveTrackingNextStopPolyline,
  },
})
export default class LiveTrackingVehicle extends Vue {
  @Prop({ type: Object, required: true }) readonly vehicle: TrackingVehicle

  tracking = tracking
  motionLineData: MotionLineData = {
    legs: [],
  }
  directionsList: Directions[] = []

  @Emit('directions-service-request')
  emitDirectionsServiceRequest(req: object): object {
    return req
  }

  get google(): any {
    return gmapApi()
  }

  get nextStopLocation(): Path {
    const nextStop = tracking.nextStop
    return {
      lat: nextStop?.address?.lat,
      lng: nextStop?.address?.lng,
    }
  }

  get directionsToNextStop(): object {
    return {
      waypoints: [
        { location: this.vehicle.location },
        { location: this.nextStopLocation },
      ],
      origin: this.vehicle.location,
      destination: this.nextStopLocation,
      travelMode: 'DRIVING',
    }
  }

  get directionsFromCurrentLocation(): object {
    return {
      waypoints: this.vehicle.motionPath,
      origin: this.vehicle.motionPath[0],
      destination: this.vehicle.motionPath[this.vehicle.motionPath.length - 1],
      travelMode: 'DRIVING',
    }
  }

  get shouldDrawPath(): boolean {
    const completedStops = tracking.reservation.stops.filter(
      (stop) => stop.isReached
    )
    const allStopsComplete =
      completedStops.length === tracking.reservation.stops.length

    return (
      (this.isVehicleBeingTracked || !allStopsComplete)
    )
  }

  get isVehicleBeingTracked(): boolean {
    const journeyIds = tracking.reservation.reservationIdJourneyIdVehicleIdPairs.flatMap(
      (p1) => p1.idsList.map((id) => id.journeyId)
    )
    return tracking.trackerList && journeyIds.includes(this.vehicle.journeyId)
  }

  mounted() {
    if (this.shouldDrawPath) {
      this.drawPathToNextStop()
    }
    this.getVehicleMotionLine()
  }

  getVehicleMotionLine(): any {
    const directionsCallback = (result, status) => {
      if (status !== 'OK') {
        return
      }
      // check motion line data, if it passes threshold animate, otherwise leave it paused
      this.motionLineData = result.routes[0]
    }

    const req = {
      options: this.directionsFromCurrentLocation,
      callback: directionsCallback,
    }
    this.emitDirectionsServiceRequest(req)
  }

  drawPathToNextStop() {
    const removePreviousLine = this.directionsList.length > 1
    const directionsCurrent = (directionsData, status) => {
      if (status !== 'OK') {
        return
      }
      this.directionsList.push(directionsData)
      if (removePreviousLine) {
        this.directionsList.shift()
      }
    }

    const req = {
      options: this.directionsToNextStop,
      callback: directionsCurrent,
    }
    this.emitDirectionsServiceRequest(req)
  }
}
</script>
