<template>
  <CUModal
    id="quote-expired-dialog"
    :value="value"
    width="500px"
    @input="$emit('input', $event)"
  >
    <template #title>{{ `${' '}` }}</template>
    <template #text>
      <div class="d-flex flex-column text-center margin-x-auto">
        <v-icon class="margin-x-auto margin-b-3" size="70">
          $color-expired
        </v-icon>
        <h2>
          Your quote has expired.
        </h2>
        <p>
          Call us now to get an updated quote.
          <br />
          Agents are available 24/7 to assist you.
        </p>
        <v-btn
          color="primary"
          class="margin-t-6"
          @click="quoteExpiredCallNumber"
        >
          <CUIcon view-box="0 0 24 24" class="margin-r-2">
            phone
          </CUIcon>
          {{ formattedSupportNumber }}
        </v-btn>
      </div>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'
import InfoDialogLayout from '@/layouts/InfoDialogLayout.vue'
import auth from '@/store/modules/auth'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'

@Component({ components: { InfoDialogLayout } })
export default class QuoteExpiredDialog extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean

  quoteExpiredCallNumber(): void {
    window.open(`tel:${this.formattedSupportNumber}`)
  }
  get supportNumber(): string {
    const isElite = !!auth.customer?.isElite
    return getSupportNumber(isElite)
  }
  get formattedSupportNumber(): string {
    return `1-${phoneFormatFilterHyphens(this.supportNumber)}`
  }
}
</script>
