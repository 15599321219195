<template>
  <article>
    <h3>
      {{ $t('quoteDetail.operatorInfo.COMPANY_INFO') }}
    </h3>

    <v-row class="company-facts margin-y-2">
      <v-col
        v-for="(fact, factIndex) in companyFacts"
        :key="`company-fact-${fact.key}-${factIndex}`"
        class="company-facts__item d-flex justify-space-between flex-column"
      >
        <p class="company-facts__item__title font-14 text-gray-light">
          {{ fact.title }}
        </p>
        <p class="font-medium">
          {{ fact.value }}
        </p>
      </v-col>
    </v-row>

    <p>
      {{ companyDescription }}
      <span
        v-if="!showAll"
        class="text-primary font-medium cursor-pointer"
        @click="showAll = true"
      >
        {{ $t('common.SEE_MORE') }}
      </span>
    </p>

    <img
      v-if="showAll && fleetPhoto"
      class="margin-t-4 min-wfull w-full max-w-full"
      :src="fleetPhoto"
    />
    <p
      v-if="showAll"
      class="text-primary font-medium cursor-pointer text-right"
      @click="showAll = false"
    >
      {{ $t('common.LESS') }}
    </p>
    <div class="provider-compliance-list" :style="{ 'margin-top': '2em' }">
      <template v-for="(compliance, complianceIndex) of compliances">
        <v-row
          v-if="checkComplianceByKey(compliance.key)"
          :key="`compliance-${compliance.key}-${complianceIndex}`"
          class="provider-compliance"
        >
          <v-icon size="40px" :style="{ margin: '5px 10px' }">
            {{ compliance.icon }}
          </v-icon>
          <v-col class="padding-a-0">
            <h4 class="margin-a-0">{{ compliance.title }}</h4>
            <p
              class="margin-t-0 font-14 text-gray-medium-light"
              :style="{ 'margin-bottom': '1em' }"
            >
              {{ compliance.description }}
            </p>
          </v-col>
        </v-row>
      </template>
    </div>
  </article>
</template>
<script lang="ts">
import { TranslateResult } from 'vue-i18n'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { OperatorDetailCompany } from '../models/dto'
import { numberWithCommas } from '../utils/string'

@Component({})
export default class ProviderInfoCompanyInfo extends Vue {
  @Prop({ type: Object, required: true })
  readonly company!: OperatorDetailCompany

  showAll = false
  compliances: {
    title: TranslateResult
    description: TranslateResult
    key: string
    icon: string
  }[] = [
    {
      title: this.$t('quoteDetail.operatorInfo.DOD_APPROVED'),
      description: this.$t('quoteDetail.operatorInfo.DOD_DESCRIPTION'),
      key: 'dod',
      icon: '$falcon',
    },
    {
      title: this.$t('quoteDetail.operatorInfo.ADA_COMPLIANT'),
      description: this.$t('quoteDetail.operatorInfo.ADA_DESCRIPTION'),
      key: 'ada',
      icon: '$wheelchair',
    },
    {
      title: this.$t('quoteDetail.operatorInfo.CDC_COMPLIANT'),
      description: this.$t('quoteDetail.operatorInfo.CDC_DESCRIPTION'),
      key: 'cdc',
      icon: '$thermometer',
    },
    {
      title: this.$t('quoteDetail.operatorInfo.SPAB_CERTIFIED'),
      description: this.$t('quoteDetail.operatorInfo.SPAB_DESCRIPTION'),
      key: 'spab',
      icon: '$school',
    },
  ]

  get companyFacts(): any[] {
    return [
      {
        title: this.$t('quoteDetail.operatorInfo.MILES_LAST_YEAR'),
        value: this.formattedMiles,
        key: 'milesLastYear',
      },
      {
        title: this.$t('quoteDetail.operatorInfo.ACCIDENTS_LAST_YEAR'),
        value: this.company.accidentsLastYear,
        key: 'accidentsLastYear',
      },
      {
        title: this.$t('quoteDetail.operatorInfo.ON_TIME_PERFORMANCE'),
        value: this.formattedOnTimePerformance,
        key: 'onTimePerformance',
      },
      {
        title: this.$t('quoteDetail.operatorInfo.YEAR_COMPANY_STARTED'),
        value: this.company.yearFounded,
        key: 'yearFounded',
      },
    ].filter((fact) => {
      return !!fact.value
    })
  }

  get companyDescription(): TranslateResult {
    if (this.company.description) {
      if (this.showAll) {
        return this.company.description
      }
      const wordCount = this.company.description.split(' ').length
      if (wordCount > 25) {
        return `${this.company.description
          .split(' ')
          .slice(0, 25)
          .join(' ')}...`
      }
      return this.company.description
    }
    return this.$t('quoteDetail.operatorInfo.NO_INFORMATION_PROVIDED')
  }

  get fleetPhoto(): string | null {
    return this.company.charterupCompanyPhotos.find(
      ({ isFleetImage }) => isFleetImage)
      ?.imagePath || null
  }

  get formattedMiles(): string {
    const { milesLastYear } = this.company
    if (!milesLastYear) {
      return ''
    }
    return numberWithCommas(milesLastYear)
  }

  get formattedOnTimePerformance(): string {
    const { onTimePerformance } = this.company
    if (!onTimePerformance) {
      return ''
    }
    return `${onTimePerformance}%`
  }

  checkComplianceByKey(key): boolean {
    return !!this.company.companyAmenities.find(
      (amenity) => amenity.amenityType.key === key && amenity.supported
    )
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';

.company-facts {
  &__item {
    flex: 1;
    &:not(:last-child) {
      border-right: 1px solid $border-gray;
    }
    &__title {
      line-height: 1.2em;
    }
  }
}
</style>
