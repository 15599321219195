<template>
  <v-card flat>
    <v-card-text
      class="border-1 border-solid border-border-gray z-1"
      :style="{ 'background-color': 'transparent' }"
    >
      <SkeletonBox :style="{ height: '31px' }" class="margin-b-6" />
      <SkeletonBox :style="{ height: '58px' }" />
      <br />
      <SkeletonBox :style="{ height: '58px' }" />
      <br />
      <SkeletonBox :style="{ height: '58px' }" />
      <br />
      <SkeletonBox type="text" />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({ components: { SkeletonBox } })
export default class SkeletonPaymentCard extends Vue {}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';
.v-card {
  border: none !important;
}
</style>
