<template>
  <CUModal :value="value" max-width="700px" @input="emitInput($event)">
    <template #title>{{ computedTitle }}</template>
    <template #text v-if="src">
      <div
        class="overflow-y-scroll overflow-x-hidden w-full"
        :style="{
          height: $vuetify.breakpoint.xs ? '' : '600px',
        }"
      >
        <vue-pdf-embed :source="src" />
      </div>
    </template>
    <template #actions>
      <v-spacer />
      <v-btn small color="primary" @click="download">
        {{ $t('common.DOWNLOAD') }}
      </v-btn>
      <v-spacer />
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { hostBaseUrl } from '@/utils/env'
import { TranslateResult } from 'vue-i18n'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

@Component({
  components: {
    VuePdfEmbed,
  },
})
export default class ViewPdfModal extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean
  @Prop({ type: String }) readonly title: string
  @Prop({ type: String, default: null }) readonly fileName: string
  @Prop({ type: Boolean }) readonly fullDomain: boolean

  @Watch('fileName', { immediate: true })
  fileNameChanged(newVal: string): void {
    this.src = null
    if (newVal) {
        this.src = this.fileName
    }
  }

  src: any = null

  get computedTitle(): TranslateResult {
    return this.title || this.$t('common.VIEW_PDF')
  }

  download(): void {
    const pdfLink = `${!this.fullDomain ? hostBaseUrl() : ''}${this.fileName}`
    window.open(pdfLink, '_blank')
  }

  emitInput(event: boolean): void {
    if (!event) {
      this.src = null
    }
    this.$emit('input', event)
  }
}
</script>
