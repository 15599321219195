<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    bottom
    left
    origin="top right"
    :nudge-top="4"
    min-width="290px"
  >
    <template #activator="{ on }">
      <v-text-field
        v-model="labelDisplayTime"
        readonly
        outlined
        transparent
        hide-details
        :placeholder="placeholder"
        class="margin-t-0 font-bold font-14  input-text-padding cursor-pointer"
        v-on="on"
      >
        <template slot="prepend-inner">
          <CUIcon
            v-if="!value"
            :key="`append-icon-event-${value}`"
            view-box="0 0 24 24"
            class="cursor-pointer"
          >
            event
          </CUIcon>
          <CUIcon
            v-else
            :id="`${id}-date-selector-button-clear`"
            :key="`append-icon-clear-${value}`"
            class="cursor-pointer"
            view-box="0 0 24 24"
            @click="clear"
          >
            close
          </CUIcon>
        </template>
        <template v-if="value" slot="append">
          <CUIcon
            :id="`${id}-date-selector-decrement-date`"
            view-box="0 0 24 24"
            class="cursor-pointer"
            color="gray-light"
            @click="decrement"
          >
            keyboard_arrow_left
          </CUIcon>
          <CUIcon
            :id="`${id}-date-selector-increment-date`"
            view-box="0 0 24 24"
            class="cursor-pointer"
            color="gray-light"
            @click="increment"
          >
            keyboard_arrow_right
          </CUIcon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      :value="value"
      event-color="primary"
      :events="reservationDates"
      no-title
      @input="handleSelectDate"
    />
  </v-menu>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({ name: 'DateSelector' })
export default class DateSelector extends Vue {
  @Prop({ type: String, required: false, default: '' }) readonly value: string
  @Prop({ type: String, default: '' }) readonly id: string
  @Prop({ type: Array, default: () => [] }) readonly reservationDates: string[]
  @Prop({ type: String, required: false, default: '' })
  readonly placeholder: string

  open = false

  get currentDate(): any {
    if (!this.value) {
      return null
    }
    return this.$dayjs(this.value)
  }

  get labelDisplayTime(): string {
    if (!this.currentDate) {
      return ''
    }
    return this.currentDate.format('MM/DD/YY')
  }

  increment(): void {
    this.$emit('input', this.currentDate.add(1, 'day').format('YYYY-MM-DD'))
  }

  decrement(): void {
    this.$emit(
      'input',
      this.currentDate.subtract(1, 'day').format('YYYY-MM-DD')
    )
  }

  close(): void {
    this.open = false
  }

  clear(): void {
    this.$emit('input', null)
  }

  handleSelectDate(date: string): void {
    this.$emit('input', date)
    this.close()
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';
.v-input,
.v-text-field {
  max-width: 185px;

  ::v-deep .v-input__slot {
    min-height: 40px !important;
    height: 40px !important;
    margin: 0 !important;

    ::v-deep .v-text-field__slot {
      height: 40px !important;
    }
  }

  ::v-deep .v-input__prepend-inner {
    z-index: 1;
    .cu-icon {
      color: $primary !important;
      position: absolute;
      top: 7px;
      left: 8px;
    }
  }

  ::v-deep .v-input__append-inner {
    z-index: 1;
    font-size: 1.375rem;
    position: absolute;
    top: 2px;
    right: 10px;
  }
}

.input-text-padding {
  ::v-deep .v-input__slot {
    input {
      padding-left: 35px !important;
    }
  }
}

.v-picker.v-card.v-picker--date {
  padding-bottom: 5px;
}

::v-deep .v-date-picker-table__events > div {
  height: 5px !important;
  width: 5px !important;
}
</style>
