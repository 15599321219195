<template>
  <div>
    <div class="d-flex">
      <CUIcon view-box="0 0 24 24" class="min-w-24 w-24 h-24 margin-r-3">
        support_agent
      </CUIcon>
      <div>
        <p class="margin-t-0">
          {{ $t('reservationDetail.helpAndSupport.BODY') }}
        </p>
        <div class="d-flex flex-wrap margin-t-2" :class="{'flex-column': $vuetify.breakpoint.xsOnly}">
          <a
            class="font-bold white-space-nowrap margin-b-1 margin-r-3"
            :tel="`${supportNumber}`"
            :href="`tel:${supportNumber}`"
          >
            {{ formattedSupportNumber }}
          </a>
          <v-divider v-if="$vuetify.breakpoint.smAndUp" class="margin-r-3" vertical />
          <a
            class="font-bold white-space-nowrap margin-b-1 margin-r-3"
            :href="`mailto:${formattedSupportEmail}`"
          >
            support@charterup.com
          </a>
          <v-divider
            v-if="$vuetify.breakpoint.smAndUp"
            class="margin-r-3"
            vertical
          />
          <a
            id="reservation-detail-link-contact-support"
            class="font-bold white-space-nowrap margin-b-1="
            @click.stop="
              support.open({ reservationId: reservation.reservationId })
            "
          >
            {{ $t('reservationDetail.helpAndSupport.SUPPORT_REQUEST') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { ReservationDetail } from '@/models/dto'
import support from '@/store/modules/support'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class ReservationDetailSupport extends Vue {
  @Prop({ type: Object, required: true }) reservation!: ReservationDetail

  support = support
  get supportNumber(): string {
    return getSupportNumber(this.reservation.isElite)
  }
  get formattedSupportNumber(): string {
    return `1-${phoneFormatFilterHyphens(this.supportNumber)}`
  }

  get supportEmail(): string {
    return 'support@charterup.com'
  }

  get formattedSupportEmail(): string {
    return `CharterUP Support<${this.supportEmail}>?subject=Reservation ${this.reservation.managedId}`
  }
}
</script>
