<template>
  <div class="d-flex flex-column">
    <span
      v-for="(vehicleType, vehicleTypeIndex) in vehicleTypes"
      :key="`vehicle-type-label-${vehicleType.quantity}-${vehicleTypeIndex}`"
      class="font-16 font-medium white-space-nowrap"
    >
      {{ vehicleType.label }}
      <span class="font-14 margin-r-n1">x</span>
      {{ vehicleType.quantity }}
    </span>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TripVehicle } from '@/models/dto'
import { VehicleTypeKey, VehicleTypeLabel } from '@/utils/enum';
import { vehicleTypeKeyToLabel } from '@/utils/vehicle';

@Component
export default class VehicleOptionVehicleList extends Vue {
  @Prop({ type: Array, required: true }) readonly vehicles: { quantity: number, vehicleTypeKey: VehicleTypeKey }[]

  get vehicleTypes(): { label: VehicleTypeLabel; quantity: number }[] {
    const typeQuantityMap = new Map<VehicleTypeKey, { label: VehicleTypeLabel; quantity: number }>()

    this.vehicles.forEach(({ vehicleTypeKey, quantity }) => {
      if (!typeQuantityMap.has(vehicleTypeKey)) {
        typeQuantityMap.set(vehicleTypeKey, { label: vehicleTypeKeyToLabel(vehicleTypeKey), quantity })
      }
    })

    return Array.from(typeQuantityMap.values())
  }
}
</script>
