<template>
  <div v-if="showMainPanel">
    <template v-if="isValidCustomer">
      <h1
        class="font-30 text-center margin-t-13 margin-x-auto margin-b-0"
        :class="{
          'font-24': $vuetify.breakpoint.xs,
        }"
      >
        {{ $t('signup.HEADER') }}
      </h1>
      <p
        class="margin-t-0 text-center margin-b-9">
        {{ subHeadingMessage }}
      </p>

      <v-form
        ref="form"
        :class="{
          'max-w-540 margin-t-0 margin-x-auto margin-b-12 border-1 border-solid border-border-gray border-radius-regular background-white':
            $vuetify.breakpoint.smAndUp,
        }"
        :style="{
          padding: $vuetify.breakpoint.smAndUp ? '33px 58px' : '',
          'box-shadow': $vuetify.breakpoint.smAndUp
            ? '0 2px 4px 0 rgba(203, 203, 203, 0.5'
            : '',
        }"
        @submit.prevent="submit"
      >
        <v-row v-if="isCustomerInvite">
          <v-col cols="6">
            <label for="first-name">
              {{ $t('signup.FORM.FIRST_NAME.LABEL') }}
            </label>
            <v-text-field
              id="signup-text-first-name"
              ref="firstName"
              v-model="firstName"
              :rules="firstNameRules"
              outlined
              validate-on-blur
              autocomplete="given-name"
              placeholder=""
            />
          </v-col>

          <v-col cols="6">
            <label for="last-name">{{ $t('signup.FORM.LAST_NAME.LABEL') }}</label>
            <v-text-field
              id="signup-text-last-name"
              v-model="lastName"
              :rules="lastNameRules"
              outlined
              validate-on-blur
              autocomplete="family-name"
              placeholder=""
            />
          </v-col>
        </v-row>

        <template v-if="isCustomerInvite">
          <label id="phoneLabel" for="phone">
            {{ $t('signup.FORM.PHONE.LABEL') }}
          </label>
          <v-text-field
            id="signup-text-phone"
            v-model="phone"
            v-mask="['(###) ###-####', '+## ## #### ####']"
            :rules="phoneRules"
            outlined
            validate-on-blur
            autocomplete="tel"
            placeholder=""
            type="tel"
          />
        </template>

        <v-checkbox
          v-if="isReservationInvite"
          id="receiveSMSCheckbox"
          v-model="receiveSMSTripUpdatesCheckbox"
          :label="$t('signup.FORM.SMS.LABEL')"
          class="mt-0 pt-0"
          :class="{ checkboxBuffer: !receiveSMSTripUpdatesCheckbox }"
          hide-details
        />

        <v-expand-transition>
          <p
            v-if="isReservationInvite"
            v-show="receiveSMSTripUpdatesCheckbox"
            class="smsLegalText"
          >
            {{ $t('signup.FORM.SMS.LEGALESE') }}
          </p>
        </v-expand-transition>

        <label for="email">{{ $t('signup.FORM.EMAIL.LABEL') }}</label>
        <v-text-field
          id="signup-text-email"
          v-model="email"
          :rules="emailRules"
          outlined
          validate-on-blur
          autocomplete="email"
          :placeholder="$t('signup.FORM.EMAIL.PLACEHOLDER')"
          disabled
        />

        <label v-if="!isIncompleteCustomer" for="password">
          {{ $t('signup.FORM.PASSWORD.LABEL') }}
        </label>
        <v-text-field
          v-if="!isIncompleteCustomer"
          id="signup-text-password"
          ref="password"
          v-model="password"
          :rules="[
            (v) =>
              !!v || this.$t('signup.FORM.PASSWORD.RULES.REQUIRED').toString(),
            (v) => !this.shouldValidatePassword || this.asyncPasswordResponse.data.valid || this.asyncPasswordResponse.data.message,
          ]"
          :append-icon="passwordHidden ? 'visibility' : 'visibility_off'"
          :type="passwordHidden ? 'password' : 'text'"
          hide-details="auto"
          required
          validate-on-blur
          outlined
          @click:append="() => (passwordHidden = !passwordHidden)"
          @change="shouldValidatePassword = false"
        />

        <AlertBox :active="errorMessage" type="error">
          {{ $t('signup.ERROR_MESSAGE') }}
        </AlertBox>

        <SignUpFormLegalese v-if="!isIncompleteCustomer" class="margin-t-4 margin-b-2" />

        <v-btn
          id="signup-button-submit"
          type="submit"
          class="primary"
          :loading="isSubmitting"
          block
          :style="{ 'margin-bottom': '43px' }"
        >
          {{ $t('common.CONTINUE') }}
          <template #loader>
            <v-progress-circular indeterminate color="white" />
          </template>
        </v-btn>

        <v-divider v-if="!isIncompleteCustomer" />

        <p v-if="!isIncompleteCustomer" class="text-center font-14 margin-t-6">
          {{ $t('signup.ALREADY_HAVE_AN_ACCOUNT') }}

          <router-link id="signup-button-login" to="/login">
            {{ $t('signup.LOGIN') }}
          </router-link>
        </p>
      </v-form>
    </template>

    <SignUpFormExpired v-else />
  </div>
</template>

<script lang="ts">
import {Vue, Component, Prop, Watch } from 'vue-property-decorator'
import router from '@/router'
import { logger } from '@/utils/logger'
import auth from '@/services/auth'
import modules from '@/store/modules'
import customer from '@/services/customer'
import { Customer, CustomerValidatePayload, CustomerValidateResult } from '@/models/dto'
import { RawLocation } from 'vue-router'
import { TranslateResult } from 'vue-i18n'
import AlertBox from '@/components/AlertBox.vue'
import SignUpFormExpired from '@/components/SignUpFormExpired.vue'
import SignUpFormLegalese from '@/components/SignUpFormLegalese.vue'
import { RECEIVE_SMS_QUERY_PARAM } from '@/utils/constants'
import { phoneRaw } from '@/utils/phone'

@Component({
  components: {
    AlertBox,
    SignUpFormExpired,
    SignUpFormLegalese,
  },
})
export default class SignUpFormLegacy extends Vue {
  @Prop({ type: Boolean, required: true }) readonly isValidCustomer: boolean
  @Prop({ type: Object, default: () => {} }) readonly customer: CustomerValidateResult

  firstName = ''
  lastName = ''
  phone = ''
  email = ''
  hash = ''
  password = ''
  passwordHidden = true
  receiveSMSTripUpdatesCheckbox = true
  errorMessage = false
  isSubmitting = false
  asyncPasswordResponse = {
    data: {
      valid: false,
      message: 'Something went wrong'
    }
  }
  shouldValidatePassword = false
  showMainPanel = false

  @Watch('customer', { immediate: true, deep: true })
  onCustomerChange(): void {
    this.populateUserDetails()
    this.autoFocusInput()
  }

  readonly phoneRules: ((v: string) => boolean | string)[] = [
    (v: string): boolean | string =>
      !!v || this.$t('signup.FORM.PHONE.RULES.REQUIRED').toString(),
    (v: string): boolean | string =>
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) ||
      this.$t('signup.FORM.PHONE.RULES.VALID').toString(),
  ]
  readonly firstNameRules: ((v: string) => boolean | string)[] = [
    (v: string): boolean | string =>
      !!v || this.$t('signup.FORM.FIRST_NAME.RULES.REQUIRED').toString(),
  ]
  readonly lastNameRules: ((v: string) => boolean | string)[] = [
    (v: string): boolean | string =>
      !!v || this.$t('signup.FORM.LAST_NAME.RULES.REQUIRED').toString(),
  ]
  readonly emailRules: ((v: string) => boolean | string)[] = [
    (v: string): boolean | string =>
      !!v || this.$t('signup.FORM.EMAIL.RULES.REQUIRED').toString(),
    (v: string): boolean | string =>
      /.+@.+/.test(v) || this.$t('signup.FORM.EMAIL.RULES.VALID').toString(),
  ]

  get isReservationInvite(): boolean {
    return this.$route?.query?.entityType === 'reservation'
  }

  get isCustomerInvite(): boolean {
    return this.$route?.query?.isCustomerInvite === 'true'
  }

  get isIncompleteCustomer(): boolean {
    return this.$route?.query?.isIncompleteCustomer === 'true'
  }

  get quoteHash(): string | null {
    return this.$route?.query?.quoteHash as string
  }

  get userHash(): string | null {
    return this.$route?.query?.userHash as string
  }

  get phoneRaw(): string {
    return phoneRaw(this.phone)
  }

  get hasNoQuoteHash(): boolean {
    return !!this.userHash && !this.quoteHash
  }

  get subHeadingMessage(): TranslateResult {
    if (this.isIncompleteCustomer) {
      return this.$t('signup.SUBHEAD_INCOMPLETE_CUSTOMER')
    }

    if (this.isReservationInvite) {
      return this.$t('signup.SUBHEAD_RESERVATION_INVITE')
    }

    if (this.hasNoQuoteHash) {
      return this.$t('signup.SUBHEAD_USER_HASH_ONLY')
    }

    return this.$t('signup.SUBHEAD')
  }

  get receiveSMSTripUpdates(): boolean {
    return this.isReservationInvite && this.receiveSMSTripUpdatesCheckbox
  }

  created(): void {
    const isAuthenticated = !!modules.auth.isTokenSet
    const isCompleteUser = !this.isIncompleteCustomer
    if (isAuthenticated && isCompleteUser) {
      router.push({ name: 'quotes' })
    }
  }

  autoFocusInput(): void {
    if (!this.isValidCustomer) {
      return
    }

    if (this.isCustomerInvite) {
      this.focusFirstNameInput()
    } else {
      this.focusPasswordInput()
    }
  }

  focusFirstNameInput(): void {
    const firstNameElement = this.$refs.firstName as any
    if (!firstNameElement) {
      return
    }
    firstNameElement.focus()
  }

  focusPasswordInput(): void {
    const passwordElement = this.$refs.password as any
    if (!passwordElement) {
      return
    }
    passwordElement.focus()
  }

  populateUserDetails(): void {
    const { params } = this.$route

    // Route might contain quoteHash or userHash,
    // meaning this is a refresh for a new user signup.
    if (this.quoteHash || this.userHash) {
      const { firstName, lastName, email, phone, hash } = this.customer
      this.firstName = firstName
      this.lastName = lastName
      this.email = email
      this.phone = phone
      this.hash = hash
      this.showMainPanel = true
    } else {
      // Populate details from route params
      const { firstName = '', lastName = '', email = '', phone = '', hash = '' } = params
      this.firstName = firstName
      this.email = email

      if (this.isIncompleteCustomer) {
        // Incomplete customer: populate additional details
        this.lastName = lastName
        this.phone = phone
      } else {
        // Redirect from signup email: populate hash
        this.hash = hash
      }
    }
  }

  async validatePassword(): Promise<void> {
    this.shouldValidatePassword = true
    this.asyncPasswordResponse = await auth.validatePassword(this.password)
  }

  async validateForm(): Promise<boolean> {
    await this.validatePassword()
    const form = this.$refs.form as any
    const formIsValid = await form.validate()
    return formIsValid
  }

  async resetPassword(): Promise<void> {
    await auth.resetPassword({
      hash: this.hash,
      password: this.password,
    })
  }

  async login(): Promise<void> {
    await modules.auth.login({
      email: this.email,
      password: this.password,
    })
  }

  async buildCustomerUpdatePayload(): Promise<Customer> {
    const { data } = await customer.byId(modules.auth.userId)
    const customerPayload = data.customer
    customerPayload.firstName = this.firstName
    customerPayload.lastName = this.lastName
    customerPayload.phone = this.phoneRaw
    return customerPayload
  }

  async updateCustomerSMS(): Promise<void> {
    await customer.updateSMS(modules.auth.userId, {
      phoneNumber: this.phoneRaw,
    })
  }

  async updateCustomerDetails(): Promise<void> {
    try {
      const customerPayload = await this.buildCustomerUpdatePayload()
      const { data } = await customer.edit(customerPayload)
      const { smsConfirmed }  = data.customer || {}

      if (!smsConfirmed && this.receiveSMSTripUpdates) {
        await this.updateCustomerSMS()
      }
    } catch (error) {
      console.error(error)
    }
  }

  redirect(): void {
    const { redirectFrom } = this.$route?.query || {}

    if (!redirectFrom) {
      this.$router.push({ name: 'quote-index' })
      return
    }

    const signupParam = '&fromSignup=true'
    const receiveSMSParam = this.receiveSMSTripUpdates ? `&${RECEIVE_SMS_QUERY_PARAM}=true` : ''
    const to = `${redirectFrom}${signupParam}${receiveSMSParam}` as RawLocation

    this.$router.push(to)
  }

  async submit() {
    if (this.isSubmitting) {
      return
    }
    try {
      const isFormValid = await this.validateForm()
      if (!isFormValid) {
        return
      }

      this.isSubmitting = true

      if (!this.isIncompleteCustomer) {
        await this.resetPassword()
        await this.login()
      }

      if (this.isCustomerInvite) {
        await this.updateCustomerDetails()
      }

      this.redirect()
    } catch (error) {
      logger.error(error)
    } finally {
      this.isSubmitting = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';

p.smsLegalText {
  text-align: left;
  font-size: 0.75rem;
  margin-bottom: 1.5em;
  color: $gray-light;
}

label#phoneLabel + div {
  ::v-deep div.v-text-field__details {
    margin-bottom: 0;
  }
}

::v-deep .v-messages:not(.error--text) {
  .v-messages__wrapper {
    .v-messages__message {
      margin-top: 2px;
      color: $gray-light;
    }
  }
}

.checkboxBuffer {
  margin-bottom: 1.5em;
}

::v-deep .v-input__append-inner {
  margin-top: 13px !important;
  margin-right: 15px;
}

label {
  color: $gray-text;
}

::v-deep .v-text-field input {
  color: $gray-text !important;
  font-weight: 500 !important;
}
</style>
