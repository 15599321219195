<template>
  <CUModal :value="value" :closeable="closeable" persistent @input="$emit('input', $event)" @close="$emit('skip')">
    <template #text>
      <div class="w-full padding-t-3 padding-x-6 padding-b-0">
        <template v-if="!verified">
          <div class="d-flex w-full justify-end">
            <v-btn
              v-if="skippable"
              color="grayLight"
              plain
              small
              class="margin-t-0 font-18 padding-x-0 min-w-0 font-book"
              @click="$emit('skip')"
            >
              Skip
            </v-btn>
          </div>
          <img
            src="@/assets/images/tripReceived.svg"
            class="display-block margin-x-auto max-w-232 margin-b-6"
          />
          <h3 class="font-22 font-bold text-center">Verify Phone Number</h3>
          <p class="text-center margin-b-6">
            Please enter the six digit code sent to {{ formattedPhone }}
          </p>
          <div
            class="d-flex align-center justify-center flex-column text-center"
          >
            <v-otp-input
              v-if="!submitting"
              id="reservation-detail-verify-phone-number"
              v-model="mfaCode"
              type="number"
              length="6"
              :class="{
                shake: shake,
              }"
              @finish="submit"
            />
            <v-progress-circular v-else indeterminate color="primary" />
            <p v-if="errorMessage" class="text-error font-medium font-14">
              {{ errorMessage }}
            </p>
            <div class="d-flex w-full justify-end">
              <v-btn
                plain
                small
                color="primary"
                class="padding-x-0 margin-t-3"
                :loading="loadingRetry"
                @click="initiateRetry"
              >
                I didn't receive an SMS
              </v-btn>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="d-flex flex-column align-center justify-center w-full">
            <img
              src="@/assets/images/tripReceived.svg"
              class="display-block margin-x-auto max-w-232 margin-b-6"
            />
            <div class="d-flex align-center">
              <CUIcon
                key="ready-to-go-icon"
                color="success"
                view-box="0 0 24 24"
                class="w-24 h-24 margin-r-2"
              >
                check_circle_old
              </CUIcon>
              <h3
                class="font-bold"
                :class="$vuetify.breakpoint.xsOnly ? 'font-20' : 'font-22'"
              >
                Phone Number Confirmed!
              </h3>
            </div>

            <p class="text-center margin-t-4 margin-b-12">
              You're all set to receive SMS updates on the day of the trip.
            </p>

            <div class="d-flex w-full justify-end">
              <v-btn
                id="phone-confirmed-ok"
                color="primary"
                class="w-full margin-t-6"
                @click="$emit('skip')"
              >
                Ok!
              </v-btn>
            </div>
          </div>
        </template>
      </div>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { phoneFormatFilter } from '@/utils/phone'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { EventBus } from '@/utils/eventBus'
import auth from '../store/modules/auth'
import customer from '@/services/customer'
import reservation from '@/services/reservation'
import { Customer } from '../models/dto'

@Component
export default class PhoneOTCVerificationDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: String, required: true }) readonly phone: string
  @Prop({ type: Number, required: false }) readonly customerId: number
  @Prop({ type: String, required: false }) readonly reservationHash: string
  @Prop({ type: Boolean, required: false }) readonly anonymous: boolean
  @Prop({ type: Boolean }) readonly skippable: boolean
  @Prop({ type: Boolean }) readonly closeable: boolean

  mfaCode = null
  errorMessage = ''
  submitting = false
  shake = false
  loadingRetry = false
  verified = false

  get formattedPhone(): string {
    if (!this.phone) {
      return ''
    }
    return phoneFormatFilter(this.phone)
  }

  get disabled(): boolean {
    if (!this.mfaCode || this.submitting) {
      return true
    }
    return this.mfaCode.toString().length !== 6
  }

  initiateRetry(): void {
    this.loadingRetry = true
    this.$emit('retry')
    // wait half a second to allow the parent to handle the event
    setTimeout(() => {
      this.loadingRetry = false
    }, 500)
  }

  async submit(): Promise<void> {
    this.submitting = true
    try {
      if (this.anonymous) {
        await reservation.addReservationWatcher(this.reservationHash, {
          phoneNumber: this.phone,
          smsOtp: this.mfaCode.toString()
        })
      } else {
        await customer.verifySMS(this.customerId, {
          phoneNumber: this.phone,
          code: this.mfaCode.toString(),
        })
        await auth.refreshCustomer()
      }
      setTimeout(() => {
        this.$emit('confirm')
      }, 1500)
      this.verified = true
      EventBus.$emit('refresh-contacts')
    } catch (err) {
      this.errorMessage =
        'Could not validate phone number. Please make sure your code is correct and try again.'
      console.error(err)
      this.shake = true
      setTimeout(() => {
        this.shake = false
      }, 1500)
    }
    this.submitting = false
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input.v-text-field.v-text-field--outlined {
  margin-top: 0 !important;
}
</style>
