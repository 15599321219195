import { getCurrentInstance, computed } from 'vue'

export const useTheme = () => {
  const instance = getCurrentInstance()
  const theme = instance?.proxy?.$vuetify?.theme

  const themes = computed(() => theme?.themes ?? {})

  return { themes }
}
