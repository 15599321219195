<template>
  <div
    :id="`${id}-bid-card-${bid.companyId}`"
    :class="{
      'cursor-pointer': clickable,
      'max-w-580': stacked,
      'w-400': carousel,
    }"
    @click="onClick"
  >
    <div
      v-if="bid"
      class="background-transparent border-solid z-1 h-full border-radius-8"
      :class="borderClasses"
    >
      <div class="d-flex" :class="{ 'flex-column': stacked }">
        <div
          class="d-flex flex-column padding-a-0 overflow-hidden background-border-gray"
          :class="{ 'col-12': stacked, 'col-4': !stacked }"
          :style="{
            'border-radius': !stacked ? '4px 0 0 4px' : '4px 4px 0 0',
            'width': stacked ? '100%' : '33.3333%',
            'max-width': stacked ? '100%' : '33.3333%',
          }"
        >
          <div class="position-relative">
            <v-img
              :src="companyPhotoPath || fallBackImage"
              class="object-fit-cover margin-a-0 z-0 border-radius-top-left-4 border-radius-bottom-right-0"
              :class="{
                'border-radius-top-right-4 border-radius-bottom-left-0': stacked,
                'border-radius-top-right-0 border-radius-bottom-left-4': !stacked,
                'opacity-50': !isValidBid,
              }"
              :aspect-ratio="stacked ? aspectRatio : null"
              :height="companyPhotoHeight"
            />
            <BidCardCertifiedPartnerBadge :carousel="carousel" v-if="bid.preferred" />
          </div>
        </div>
        <div
          class="d-flex flex-grow-1 position-relative"
          :style="{ 'width': stacked ? '100%' : '66%' }"
        >
          <div class="d-flex justify-space-between"
            :class="{
                      'padding-t-3': !stacked,
                      'padding-t-2': stacked,
                    }"
          >
            <div
              class="d-flex flex-column w-full"
              :class="{
                'margin-l-5 padding-r-2': !stacked,
                'margin-l-3': stacked,
                'margin-t-1': carousel,
                'justify-space-between': flipRatingAndAmenities && !websiteUrl,
              }"
            >
              <div class="d-flex align-center">
                <div class="w-160" v-if="shouldDisplayCompanyLogo">
                  <v-img
                    :src="companyLogo"
                    class="display-block margin-a-0 z-0 w-full h-auto"
                  />
                </div>
                <h2 v-else
                  class="overflow-hidden text-overflow-ellipsis"
                  :class="{
                    'font-16': stacked,
                    'font-18': !stacked,
                    'opacity-50': !isValidBid,
                  }"
                >
                  {{ bid.companyDBAName }}
                </h2>
                <ResellerTooltip v-if="isReseller" :dba="bid.companyDBAName" />
              </div>
              <div v-if="isValidBid || !stacked" class="d-flex flex-column"
                :class="{'flex-column-reverse': flipRatingAndAmenities}"
              >
                <CharterUPRating
                  :rating="bid.rating"
                  class="d-inline-flex margin-t-1 margin-b-2"
                  style="max-width: fit-content"
                />
                <BidCardAmenities v-if="showAmenities || featured" :bid="bid" :is-bronze="isBronze" />
              </div>
              <div
                id="partnerUrl"
                v-if="websiteUrl" class="position-absolute"
                style="bottom: 5px"
                :style="{ 'right': stacked && '5px' }"
                @click.stop
              >
                <a target="_blank"
                  :href="sanitizedUrl"
                  class="d-flex align-center"
                >
                  <CUIcon
                    class="text-gray margin-t-n1 margin-r-1"
                    view-box="0 0 24 24"
                    :class="{
                      'w-18 h-18': !stacked,
                      'w-14 h-14': stacked,
                    }"
                  >
                    open_in_new
                  </CUIcon>
                  <span class="text-gray child margin-b-1 overflow-hidden text-overflow-ellipsis"
                    :class="{
                      'max-w-100 font-12': stacked,
                      'max-w-200': !stacked
                    }
                  ">
                    {{ displayingUrl }}
                  </span>
                </a>
              </div>
            </div>
          </div>
          <v-spacer />
          <div class="d-flex margin-t-0 flex-column justify-space-between"
            :class="{
              'padding-y-3 padding-l-4': !stacked,
              'padding-y-2 border-none': stacked,
              'border-primary': !stacked && featured,
              'border-border-gray': !stacked && !featured,
            }"

            :style="{
              'border-left': dottedBorderStyle
            }"
          >
            <BidCardPricing
              :bid="bid"
              :stacked="stacked"
              :isValidBid="isValidBid"
              :charCountMoreThan7="priceCharCountMoreThan7"
              :showUsd="showUsd"
            />
            <div v-if="isValidBid">
              <v-btn
                v-if="!stacked"
                :id="`${id}-bid-card-${bid.companyId}`"
                :to="goTo"
                :disabled="!clickable"
                color="primary"
                class="w-140 margin-r-4"
                :outlined="isSecondaryButtonStyle"
                small
              >
                {{ buttonText }}
              </v-btn>
            </div>
            <BidCardSoldOut v-else :stacked="stacked"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Inject, Watch } from 'vue-property-decorator'
import { roundedCurrencyFilter } from '@/utils/string'
import SoldOutDetail from '@/components/SoldOutDetail.vue'
import deepClone from '@/utils/deepClone'
import fallBackImage from '../assets/images/placeholder_bus.jpg'
import { QuoteDetailV2Bid, VehiclePhoto } from '@/models/dto'
import { hostBaseUrl } from '../utils/env'
import { PartnerTypeId } from '@/utils/enum'

import BidCardCertifiedPartnerBadge from '@/components/BidCardCertifiedPartnerBadge.vue'
import BidCardAmenities from '@/components/BidCardAmenities.vue'
import BidCardPricing from '@/components/BidCardPricing.vue'
import BidCardSoldOut from '@/components/BidCardSoldOut.vue'
import CharterUPRating from '@/components/CharterUPRating.vue'
import ResellerTooltip from '@/components/ResellerTooltip.vue'

@Component({
  components: {
    SoldOutDetail,
    BidCardAmenities,
    BidCardCertifiedPartnerBadge,
    BidCardPricing,
    BidCardSoldOut,
    CharterUPRating,
    ResellerTooltip
  },
})
export default class BidCard extends Vue {
  @Inject('isInCarousel') readonly isInCarousel: boolean

  @Prop({ type: String, default: null }) readonly id: string
  @Prop({ type: Object, default: () => {} }) readonly bid: QuoteDetailV2Bid
  @Prop({ type: Boolean }) readonly featured: boolean
  @Prop({ type: Object, default: null }) readonly goTo
  @Prop({ type: Boolean }) readonly carousel: boolean
  @Prop({ type: Boolean }) readonly isElite: boolean
  @Prop({ type: Boolean }) readonly showUsd: boolean
  @Prop({ type: Boolean }) readonly charterUpChoiceStyling: boolean
  @Prop({ type: Boolean }) readonly showAmenities: boolean
  @Prop({ type: Boolean }) readonly isFeaturedBidReseller: boolean
  @Prop({ type: Boolean, required: true }) readonly isBrandedQuoteFlowEnabled: boolean
  @Prop({ type: Boolean, required: true }) readonly isDisplayPartnerUrlEnabled: boolean

  fallBackImage = fallBackImage
  aspectRatio = '1.87'

  get stacked(): boolean {
    return !this.$vuetify.breakpoint.smAndUp || this.isInCarousel
  }

  get isValidBid(): boolean {
    return !this.isSoldOut && !this.isBronze
  }

  get clickable() {
    return this.goTo && !this.bid?.awaitngBid && this.isValidBid
  }

  get vehiclePhotos(): VehiclePhoto[] {
    return deepClone(this.bid?.vehiclePhotos).sort(
      (a, b) => a.vehicleTypeId - b.vehicleTypeId
    )
  }

  get companyPhotoPath(): string | any {
    const path = this.vehiclePhotos?.[0]?.imagePath
    if (path?.[0] !== '/') {
      return path
    }
    return `${hostBaseUrl()}${path}`
  }

  get companyPhotoHeight(): number {
    if (this.stacked) {
      return 155
    } else if ((this.featured && this.isReseller) || !this.isBrandedQuoteFlowEnabled || !this.isFeaturedBidReseller) {
      return 173
    }
    return 120
  }

  get isBronze(): boolean {
    return this.bid?.partnerTypeId === PartnerTypeId.Bronze
  }

  get isSoldOut(): boolean {
    return !!this.bid?.soldOut
  }

  get priceCharCountMoreThan7(): boolean {
    let total = roundedCurrencyFilter(Math.ceil(this.bid.totalAmount))?.length
    if (this.showUsd) {
      total += 4
    }
    return total > 7
  }

  get borderClasses(): string {
    if (this.featured) {
      return 'border-primary outline-none'
    }
    if (this.charterUpChoiceStyling) {
      return 'border-primary border-3'
    }
    return 'border-1 border-border-gray'
  }

  get shouldDisplayCompanyLogo(): boolean {
    return this.featured && this.isReseller && !this.stacked && this.isBrandedQuoteFlowEnabled && !!this.companyLogo
  }

  get companyLogo(): string {
    const path = this.bid?.lightLogoUrl
    if (!path) {
      return ''
    }
    return `${hostBaseUrl()}${path}`
  }

  get websiteUrl(): string {
    const path = this.bid?.customerFacingUrl
    if (!this.isDisplayPartnerUrlEnabled || !path) {
      return ''
    }
    return path
  }

  get isReseller(): boolean {
    return !!this.bid?.reseller
  }

  get isSecondaryButtonStyle(): boolean {
    return this.isBrandedQuoteFlowEnabled && (!this.isReseller || !this.featured) && this.isFeaturedBidReseller
  }

  get dottedBorderStyle(): string {
    if (this.stacked || !this.isBrandedQuoteFlowEnabled) {
      return 'none'
    } else if (this.featured) {
      return '3px dotted'
    }
    return '1px dotted'
  }

  get buttonText() {
    if(this.isSecondaryButtonStyle) {
      return this.$t('common.SELECT')
    }
    return this.$t('common.CONTINUE')
  }

  get flipRatingAndAmenities(): boolean {
    return this.featured && this.isReseller && this.isBrandedQuoteFlowEnabled
  }

  // This appends 'http://' prefix to the urls that miss the protocol
  get sanitizedUrl(): string {
    let url = this.websiteUrl
    if (!url.match(/^https?:\/\//i)) {
      url = `http://${url}`
    }
    return url
  }

  get displayingUrl(): string {
    let url = this.websiteUrl
    return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0]
  }

  onClick(): void {
    if (!this.clickable) {
      return
    }
    this.trackViewBid()
    this.$router.push(this.goTo)
  }

  trackViewBid(): void {
    this.$ga4.trackEvent('view_bid', {
      companyId: this.bid?.companyId,
      isElite: this.isElite,
      isAuth0: this.$auth0.isInitialized
    })
  }
}
</script>
