import '@mdi/font/css/materialdesignicons.css'

import ArrowSignIcon from '@/icons/ArrowSignIcon.vue'
import BusJoinUsColorIcon from '@/icons/BusJoinUsColorIcon.vue'
import CalendarIcon from '@/icons/CalendarIcon.vue'
import CashColorIcon from '@/icons/CashColorIcon.vue'
import CharterUPLogo from '@/icons/CharterUPLogo.vue'
import CreditCardAmericanexpressIcon from '@/icons/CreditCardAmericanexpressIcon.vue'
import CreditCardDefaultIcon from '@/icons/CreditCardDefaultIcon.vue'
import CreditCardDinersIcon from '@/icons/CreditCardDinersIcon.vue'
import CreditCardDiscoverIcon from '@/icons/CreditCardDiscoverIcon.vue'
import CreditCardMastercardIcon from '@/icons/CreditCardMastercardIcon.vue'
// Credit Card Imports
import CreditCardVisaIcon from '@/icons/CreditCardVisaIcon.vue'
import CustomTripIcon from '@/icons/CustomTripIcon.vue'
import EmailIcon from '@/icons/EmailIcon.vue'
import ExpiredColorIcon from '@/icons/ExpiredColorIcon.vue'
import FalconIcon from '@/icons/FalconIcon.vue'
import InstagramFilledIcon from '@/icons/InstagramFilledIcon.vue'
import MarkerColorIcon from '@/icons/MarkerColorIcon.vue'
import PhoneColorIcon from '@/icons/PhoneColorIcon.vue'
import PhoneIcon from '@/icons/PhoneIcon.vue'
import ProgressBlueColorIcon from '@/icons/ProgressBlueColorIcon.vue'
// Color Icon Imports
import ProgressColorIcon from '@/icons/ProgressColorIcon.vue'
import QuoteIcon from '@/icons/QuoteIcon.vue'
import SPABIcon from '@/icons/SpabIcon.vue'
import SchoolIcon from '@/icons/SchoolIcon.vue'
import SentColorIcon from '@/icons/SentColorIcon.vue'
import ShuttleIcon from '@/icons/ShuttleIcon.vue'
import ThermometerIcon from '@/icons/ThermometerIcon.vue'
import TicketIcon from '@/icons/TicketIcon.vue'
import TripTypeIcon from '@/icons/TripTypeIcon.vue'
import TwoArrowSignIcon from '@/icons/TwoArrowSignIcon.vue'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import WheelchairIcon from '@/icons/WheelchairIcon.vue'

import colors from '@/scss/_colors-export.module.scss'
import { toCamel } from '@/utils/string'

Vue.use(Vuetify)

const caseConvertedColors = {}
for (const [key, value] of Object.entries(colors)) {
  const convertedKey = toCamel(`${key}`)
  caseConvertedColors[convertedKey] = value
}

export default new Vuetify({
  theme: {
    themes: {
      light: caseConvertedColors,
    },
  },
  icons: {
    iconfont: 'mdi',
    values: {
      facebook: 'mdi-facebook-box',
      twitter: 'mdi-twitter',
      linkedin: 'mdi-linkedin',
      charterUp: { component: CharterUPLogo },
      ticket: { component: TicketIcon },
      email: { component: EmailIcon },
      calendar: { component: CalendarIcon },
      phone: { component: PhoneIcon },
      arrowSign: { component: ArrowSignIcon },
      twoArrowSign: { component: TwoArrowSignIcon },
      shuttle: { component: ShuttleIcon },
      custom: { component: CustomTripIcon },
      quoteIcon: { component: QuoteIcon },
      instagram: { component: InstagramFilledIcon },
      tripType: { component: TripTypeIcon },
      falcon: { component: FalconIcon },
      wheelchair: { component: WheelchairIcon },
      thermometer: { component: ThermometerIcon },
      school: { component: SchoolIcon },
      spab: { component: SPABIcon },

      // Color Icons
      'color-progress': { component: ProgressColorIcon },
      'color-blue-progress': { component: ProgressBlueColorIcon },
      'color-phone': { component: PhoneColorIcon },
      'color-sent': { component: SentColorIcon },
      'color-cash': { component: CashColorIcon },
      'color-expired': { component: ExpiredColorIcon },

      // Credit Cards
      //
      // Leave in kebob case as these are swapped out dynamically
      // based upon the string after the dash.
      'creditcard-visa': { component: CreditCardVisaIcon },
      'creditcard-mastercard': { component: CreditCardMastercardIcon },
      'creditcard-diners': { component: CreditCardDinersIcon },
      'creditcard-amex': { component: CreditCardAmericanexpressIcon },
      'creditcard-discover': { component: CreditCardDiscoverIcon },
      'creditcard-default': { component: CreditCardDefaultIcon }
    },
  },
})
