<template>
  <v-container fluid class="padding-a-0 fill-height">
    <div no-gutters class="fill-height overflow-hidden">
      <div
        :style="{
          height: $vuetify.breakpoint.smAndUp ? 'calc(100vh - 68px)' : '',
          'box-shadow': $vuetify.breakpoint.smAndUp
            ? 'box-shadow: 12px 0px 10px -5px rgba(0, 0, 0, 0.15)'
            : '0px -8px 6px 0px rgba(0, 0, 0, 0.0375)',
        }"
        class="padding-x-6 bid-panel padding-b-0 overflow-y-auto z-10 background-white"
        :class="{
          'padding-t-6 w-400 max-w-400': $vuetify.breakpoint.smAndUp,
          'padding-t-2 max-h-two-thirds-vh w-full-vw max-w-full-vw position-fixed bottom-0 left-0 border-radius-top-left-2x border-radius-top-right-2x':
            $vuetify.breakpoint.xsOnly,
        }"
      >
        <slot name="sidebar" />
      </div>
      <div
        class="position-absolute top-0 z-0"
        :class="{
          'left-400': $vuetify.breakpoint.smAndUp,
          'left-0': $vuetify.breakpoint.xs,
        }"
        :style="{
          'max-width': $vuetify.breakpoint.smAndUp
            ? 'calc(100vw - 400px)'
            : '100vw',
          width: $vuetify.breakpoint.smAndUp ? 'calc(100vw - 400px)' : '100vw',
          height: 'calc(100vh - 68px)',
          flex: $vuetify.breakpoint.smAndUp ? '' : '1',
        }"
      >
        <slot name="map" />
      </div>
    </div>
  </v-container>
</template>

<script lang="ts">
import footer from '@/store/modules/footer'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class MapWithSidebar extends Vue {
  mounted(): void {
    footer.setShow(false)
  }
}
</script>
