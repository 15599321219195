<template>
  <CUModal v-model="dialog" max-width="518px">
    <template #activator="{ on }">
      <v-btn
        v-if="!displayInList"
        id="invite-user-add"
        class="invite-user-button"
        small
        color="primary"
        v-on="on"
      >
        Add User
      </v-btn>
      <v-list-item v-else id="invite-user-add" v-on="on">
        <v-list-item-title>
          Add User
        </v-list-item-title>
      </v-list-item>
    </template>
    <template #title>Invite</template>
    <template #text>
      <div class="w-full">
        <h4 class="margin-b-3">
          This user will have admin access to all quotes, reservations, and
          payments for your organization.
        </h4>
        <v-form ref="form" v-model="formIsValid">
          <v-row no-gutters>
            <v-col cols="6">
              <label for="firstName">First name</label>
              <v-text-field
                id="invite-user-team-first-name"
                ref="firstName"
                v-model="formData.firstName.value"
                v-bind="formData.firstName"
                :class="{
                  'padding-r-3': $vuetify.breakpoint.xs,
                  'padding-r-4': $vuetify.breakpoint.smAndUp,
                }"
                required
                validate-on-blur
                outlined
              />
            </v-col>
            <v-col cols="6">
              <div
                :class="{
                  'padding-l-3': $vuetify.breakpoint.xs,
                  'padding-l-4': $vuetify.breakpoint.smAndUp,
                }"
              >
                <label for="lastName">Last name</label>
                <v-text-field
                  id="invite-user-team-last-name"
                  ref="lastName"
                  v-model="formData.lastName.value"
                  v-bind="formData.lastName"
                  required
                  validate-on-blur
                  outlined
                />
              </div>
            </v-col>
            <v-col cols="12">
              <label for="email">Email</label>
              <v-text-field
                id="invite-user-team-email"
                ref="email"
                v-model="formData.email.value"
                v-bind="formData.email"
                required
                validate-on-blur
                outlined
              />
            </v-col>
            <v-col cols="12">
              <label for="title">Title</label>
              <v-text-field
                id="invite-user-team-title"
                ref="title"
                v-model="formData.title.value"
                v-bind="formData.title"
                required
                validate-on-blur
                outlined
              />
            </v-col>
            <v-col cols="12">
              <label for="team">Team</label>
              <v-select
                id="invite-user-team-team"
                ref="team"
                v-model="formData.team.value"
                v-bind="formData.team"
                :items="teams"
                item-text="name"
                item-value="customerAccountId"
                required
                outlined
                validate-on-blur
              />
            </v-col>
          </v-row>
          <AlertBox :active="!!errorMessage" type="error" class="margin-b-3">
            {{ errorMessage }}
          </AlertBox>
        </v-form>
      </div>
    </template>
    <template #actions>
      <div class="d-flex grow" />
      <v-btn
        id="invite-user-team-cancel"
        small
        text
        class="margin-r-2"
        @click="dialog = false"
      >
        Cancel
      </v-btn>
      <v-btn id="invite-user-team-submit" class="primary" small @click="send">
        Send Invite
      </v-btn>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import customerAccount from '@/services/customerAccount'
import { Team } from '@/models/dto'
import axios from 'axios'
import AlertBox from '@/components/AlertBox.vue'

@Component({ components: { AlertBox } })
export default class OrganizationInviteUserTeamDialog extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly teams: Team[]
  @Prop({ type: Object, default: () => {} }) readonly startingTeam: Team
  @Prop({ type: Boolean }) readonly displayInList: boolean

  @Watch('dialog')
  dialogChanged() {
    this.resetValues()
    this.setup()
  }

  formIsValid = false
  dialog = false
  success = false
  errorMessage = ''
  formData: Record<string, { value: string; rules: any[] }> = {
    firstName: {
      value: '',
      rules: [(v) => !!v || 'First name is required'],
    },
    lastName: {
      value: '',
      rules: [(v) => !!v || 'Last name is required'],
    },
    email: {
      value: '',
      rules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    },
    title: {
      value: '',
      rules: [(v) => !!v || 'Title name is required'],
    },
    team: {
      value: '',
      rules: [(v) => !!v || 'Title name is required'],
    },
  }

  mounted(): void {
    this.setup()
  }
  setup(): void {
    if (this.startingTeam) {
      this.success = false
      this.formData.team.value = this.startingTeam.customerAccountId.toString()
    }
  }
  resetValues(): void {
    const form = this.$refs.form as any
    form.reset()
  }

  backToSite(): void {
    this.success = false
    this.setup()
    this.dialog = false
  }
  async send(): Promise<void> {
    const form = this.$refs.form as any
    if (!form.validate()) {
      return
    }
    this.errorMessage = ''
    const payload = {
      firstName: this.formData.firstName.value,
      lastName: this.formData.lastName.value,
      email: this.formData.email.value,
      title: this.formData.title.value,
      customerAccountId: parseInt(this.formData.team.value),
      role: 1,
    }
    try {
      await customerAccount.invite(payload)
      this.success = true
      this.dialog = false
      this.$emit('success')
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errorMessage = e.response?.data?.message
        this.errorMessage = errorMessage
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';

.invite-user-button {
  padding: 0 10px !important;
}

::v-deep .v-select {
  .v-input__control {
    .v-input__slot {
      .v-select__slot {
        .v-select__selections {
          padding: 0;
          .v-select__selection {
            padding-left: 14px !important;
          }
        }
        .v-input__append-inner {
          .v-input__icon--append {
            margin-top: 6px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>
