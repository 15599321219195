<template>
  <CUModal :value="value" width="500px" closeable @input="$emit('input', $event)">
    <template #text>
      <div class="d-flex flex-column align-center justify-end">
        <img src="@/assets/images/share-tracking-map.png" class="max-w-300" />
        <h2 class="text-center w-full margin-t-6">{{ $t('reservationDetail.shareDialog.LIVE_TRACKING') }}</h2>
        <h2 class="text-center w-full padding-x-10 margin-t-2 font-medium font-14 text-midnight-gray-500">{{ $t('reservationDetail.shareDialog.YOU_CAN_SHARE') }}</h2>
      </div>
    </template>
    <template #actions>
      <div
        class="d-flex flex-column w-full h-full justify-end align-center padding-x-4 padding-b-4"
      >
        <div class="d-flex w-full margin-y-6">
          <h2
            class="w-full padding-a-4 font-medium font-14 text-midnight-gray-500 border-midnight-gray-500 border-solid border-1 border-radius-5 text-wrap"
            style="word-wrap: break-word;"
          >
            {{ trackingLink }}
          </h2>
        </div>
        <div class="d-flex w-full">
          <div v-if="$vuetify.breakpoint.xs" class="w-one-half padding-r-1">
            <v-btn class="w-full" color="primary" small @click="shareURL">
              {{ $t('common.SHARE') }}
            </v-btn>
          </div>
          <div
            :class="
              $vuetify.breakpoint.xs ? 'w-one-half padding-l-1' : 'w-full'
            "
          >
            <v-btn
              class="w-full"
              color="primary"
              small
              outlined
              @click="copyURL"
            >
              {{ $t('common.COPY_LINK') }}
            </v-btn>
          </div>
        </div>
      </div>
    </template>
  </CUModal>
</template>

<script lang="ts">
import { Vue, Component, Prop, InjectReactive } from 'vue-property-decorator'
import alert from '@/store/modules/alert'

@Component
export default class ShareLiveTrackingDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: String }) readonly reservationHash!: string

  @InjectReactive('trackingLink') trackingLink

  async copyURL(): Promise<void> {
    await navigator.clipboard.writeText(this.trackingLink)
    alert.add({
      text: '',
      color: 'green',
      title: 'Link copied',
      dismissible: true,
    })
  }

  async shareURL(): Promise<void> {
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'CharterUP Live Tracking',
          url: location.href,
          text: 'View your CharterUP itinerary and track your vehicle live.'
        })
      } else {
        this.copyURL()
      }
    } catch (error) {
      console.error('Error sharing link', error)
    }
  }
}
</script>
